(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ProductXmlPurchases', ProductXmlPurchases);

    ProductXmlPurchases.$inject = ['$scope', '$state', 'ProductXmlPurchase', 'ParseLinks', 'AlertService', 'paginationConstants',
     'pagingParams', 'MessageService', '$timeout'];

    function ProductXmlPurchases ($scope, $state, ProductXmlPurchase, ParseLinks, AlertService, paginationConstants,
     pagingParams, MessageService, $timeout) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.exitSure = false;

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                ProductXmlPurchase.byCondition2({
                    items: [{key: "number,productXml.name", op: "like", value: pagingParams.search},{key: "paid", op: "=", value: "true"}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ProductXmlPurchase.byCondition2({
                    items: [{key: "paid", op: "=", value: "true"}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productXmlPurchases = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
            focus("search");
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function focus(element) {
            var elementId = element;
            $timeout(function (){
                var element = document.getElementById(elementId);
                if (element) {
                    element.focus();
                }
            }, 100);
        }

        function search(searchQuery) {
            if(event.keyCode === 13){
                if (!searchQuery){
                    return vm.clear();
                }
                vm.links = null;
                vm.page = 1;
                vm.predicate = 'id';
                vm.reverse = false;
                vm.currentSearch = searchQuery;
                vm.transition();
            }

        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }
    }
})();

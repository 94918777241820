(function () {
    'use strict';

    var styleSettingControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/calendar/controls/style-setting.control.html',
        controllerAs: 'vm',
        bindings: {
            style:'<',
            options:'<',
            obj:'<',
            settings:'<',
            config:'<',
            fontList:'<',
            themeColors:'<',
            title:'<',
            onChange:'&'
        },

        controller: ['$scope','$timeout','FontManagement', function ($scope,$timeout,FontManagement) {
            var vm = this;
            vm.data = {};
            $scope.$watch(function() {
                return vm.themeColors;
            }, function(newlist,oldList) {
                vm.themeColors = newlist;
            });
            vm.textAligns=[
                {id:'left',value:'左对齐'},
                {id:'center',value:'居中'},
                {id:'right',value:'右对齐'},
            ]
            vm.lineWidths=[
                {id:0,value:'0'},
                {id:1,value:'1'},
                {id:2,value:'2'},
                {id:3,value:'3'},
            ]
            vm.$onInit = function() {
                if(vm.data&&vm.data.settings&&vm.data.settings.items){
                    vm.data.settings.items.forEach(function (item) {
                        fontStyle(item,true)
                    })
                }
            }
            // 每當 one-way binding 更新時，changesObj 就是改變前後的差異
            vm.$onChanges = function(data) {
                if(data.settings&&data.settings.currentValue){
                    vm.data.settings = data.settings.currentValue;
                    if(!vm.data.settings.border.lineWidth){
                        vm.data.settings.border.lineWidth = 0;
                    }
                }
                if (vm.data.settings && !vm.data.settings.border.lineType) {
                    vm.data.settings.border.lineType = 'solid';
                }
            }
            vm.$postLink = function () {

            }
            vm.$doCheck = function() {
                // deep watch
            }
            vm.$onDestroy = function () {

            }

            $scope.onCheckboxChange = function() {
                onSettingsChange();
            }

            $scope.onBorderInputChange = function(num) {
                onSettingsChange();
            }

            $scope.onColorChange = function(text) {
                $scope.$apply();
                onSettingsChange();
            }
            var a = null;
            $scope.onInputChange = function(num, index) {
                if (a !== null) {
                    clearTimeout(a)
                }
                a = setTimeout(function () {
                    if(!num || num == 'undefined'){
                        vm.data.settings.items[index].textSize = 12;
                    }
                    $scope.onItemChange();
                },800)
            }
            $scope.onFontChange = function(text,item){
                $timeout(function () {
                    fontStyle(item)
                    onSettingsChange();
                })
            }
            function fontStyle(font,initialize) {
                var _fontStyle = [];
                for (var i = 0; i < vm.fontList.length; i++) {
                    if (vm.fontList[i].uuid == font.textFontUuid) {
                        for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                            _fontStyle.push(vm.fontList[i].fontStyles[j].style)
                        }
                    }
                }
                font.fontStyle = _fontStyle;
                font.textBold1 = false;
                font.textItalic1 = false;
                if(!initialize){
                    if (_fontStyle.indexOf('NORMAL') != '-1') {
                        font.textBold = false;
                        font.textItalic = false;
                    } else {
                        if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                            font.textBold = true;
                            font.textItalic = true;
                        } else {
                            if (_fontStyle.indexOf('ITALIC') != '-1') {
                                font.textBold = false;
                                font.textItalic = true;
                            }
                            if (_fontStyle.indexOf('BOLD') != '-1') {
                                font.textBold = true;
                                font.textItalic = false;
                            }
                        }
                    }
                }
                if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    font.textBold1 = true;
                    font.textItalic1 = true;
                } else {
                    if (_fontStyle.indexOf('ITALIC') != '-1') {
                        font.textItalic1 = true;
                    }
                    if (_fontStyle.indexOf('BOLD') != '-1') {
                        font.textBold1 = true;
                    }
                }
            }

            //斜体
            $scope.operationItalic = function (e,font) {
                e.preventDefault();
                $timeout(function () {
                    if(font.textItalic){
                        if(font.fontStyle.indexOf('NORMAL') > -1){
                            font.textItalic = false;
                            if (font.fontStyle.indexOf('BOLD') == '-1') {
                                font.textBold = false;
                            }
                        }else{
                            if(font.textBold){
                                font.textItalic = false;
                            }
                        }
                    }else{
                        if(font.fontStyle.indexOf('ITALIC') > -1){
                            font.textItalic = true;
                            if(font.textBold){
                                if(font.fontStyle.indexOf('BOLD_ITALIC') == -1){
                                    font.textBold = false;
                                }
                            }else{
                                // if( font.fontStyle.indexOf('BOLD_ITALIC') > -1){
                                //     font.textBold = true;
                                // }
                            }
                        }else if (font.fontStyle.indexOf('BOLD_ITALIC') > -1) {
                            font.textBold = true;
                            font.textItalic = true;
                        }
                    }
                    $scope.onItemChange()
                })
            }
            //粗体
            $scope.operationBold = function (e,font) {
                e.preventDefault();
                $timeout(function () {
                    if(font.textBold){
                        if(font.fontStyle.indexOf('NORMAL') > -1){
                            font.textBold = !font.textBold;
                            if (font.fontStyle.indexOf('ITALIC') == '-1') {
                                font.textItalic = font.textBold;
                            }
                        }else{
                            if(font.textItalic){
                                font.textBold = !font.textBold;
                            }
                        }
                    }else{
                        if(font.fontStyle.indexOf('BOLD') > -1){
                            font.textBold = true;
                            if(font.textItalic){
                                if(font.fontStyle.indexOf('BOLD_ITALIC') == -1){
                                    font.textItalic = false;
                                }
                            }else{
                                // if( font.fontStyle.indexOf('BOLD_ITALIC') > -1){
                                //     font.textItalic = true;
                                // }
                            }
                        }else if (font.fontStyle.indexOf('BOLD_ITALIC') > -1) {
                            font.textBold = true;
                            font.textItalic = true;
                        }
                    }
                    $scope.onItemChange();
                })
            }
            $scope.onItemChange = function() {
                $timeout(function () {
                    onSettingsChange();
                })
            }

            function onSettingsChange() {
                if (vm.onChange) {
                    vm.data.settings.items.forEach(function (item){
                        item.geometry={
                            width:item.w,
                            height:item.h,
                            x:item.x,
                            y:item.y,
                        }
                    })
                    vm.onChange({text: JSON.stringify(vm.data.settings)});
                }
            }

        }]
    };
    angular.module('platformApp')
        .component('styleSetting', styleSettingControl);

})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PictureEditController', PictureEditController);

        PictureEditController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService','Catalog','PicLayoutXmls'];

    function PictureEditController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService,Catalog, PicLayoutXmls) {
        var vm = this;
        vm.save = save;
        vm.cancel = cancel;
        vm.data = entity;
        console.log(vm.data);
        Catalog.getAll({items:[{key:"usable",op:"=",value:"true"},{key:"catalogType",op:"=",value:"LAYOUT"}]}, function (data) {
            vm.catalogs = data;
        })
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }

        function save() {
            PicLayoutXmls.update(vm.data, function (res) {
                MessageService.success("保存成功");
                $uibModalInstance.close(true);
            })
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarModalController', CalendarModalController);

    CalendarModalController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity'];

    function CalendarModalController ($timeout, $scope, $stateParams, $uibModalInstance, entity) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.numbers = entity.numbers;
        vm.templateConfig = entity.templateConfig;
        var result = findMissingNumbers(vm.numbers);
        var max = Math.max.apply(null, vm.numbers);
        var min = Math.min.apply(null, vm.numbers);
        vm.startYear = getDay('year',vm.templateConfig.startYear,vm.templateConfig.startMonth,min);
        vm.startMonth = getDay('month',vm.templateConfig.startYear,vm.templateConfig.startMonth,min);
        vm.endYear = getDay('year',vm.templateConfig.startYear,vm.templateConfig.startMonth,max);
        vm.endMonth = getDay('month',vm.templateConfig.startYear,vm.templateConfig.startMonth,max);
        vm.defaultDate = [];
        result.forEach(function (item) {
            var year = getDay('year',vm.templateConfig.startYear,vm.templateConfig.startMonth,item);
            var month = getDay('month',vm.templateConfig.startYear,vm.templateConfig.startMonth,item);
            if(vm.defaultDate.length == 0){
                vm.defaultDate.push({
                    year:year,
                    months:[month]
                })
            }else{
                var exist = false;
                for(var i=0; i<vm.defaultDate.length; i++){
                    if(vm.defaultDate[i].year == year){
                        vm.defaultDate[i].months.push(month);
                        exist = true;
                        break
                    }
                }
                if(!exist){
                    vm.defaultDate.push({
                        year:year,
                        months:[month]
                    })
                }
            }
        })

        //保存
        function save() {
            $uibModalInstance.close(true);
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
    }
})();

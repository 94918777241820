(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('Verify', Verify)
        .factory('FrontUser', FrontUser)
        .factory('Notice', Notice)
        .factory('ProductXmlPurchase', ProductXmlPurchase)
        .factory('PaymentSettings', PaymentSettings)
        .factory('ThirdPartySetting', ThirdPartySetting)
        .factory('ProductXml', ProductXml);

    ProductXml.$inject = ['$resource'];

    ThirdPartySetting.$inject = ['$resource'];

    Verify.$inject = ['$resource'];

    Notice.$inject = ['$resource'];
    FrontUser.$inject = ['$resource'];
    ProductXmlPurchase.$inject = ['$resource'];
    PaymentSettings.$inject = ['$resource'];

    function Notice($resource) {
        var resourceUrl = 'bunny/' + 'api/notices/:path/:id';
        return $resource(resourceUrl, {}, {
            "byCondition1":{
                method: 'POST',
                params:{
                    path:"byCondition1"
                },
            },
            "getAllByCondition1": {
                method: 'POST',
                params: {
                    path: "getAllByCondition1"
                },
            },
            "readAll": {
                method: 'GET',
                params: {
                    path: "readAll"
                }
            },
            "read": {
                method: 'GET',
                params: {
                    path: "read"
                }
            },
        });
    }


    function ProductXmlPurchase($resource) {
        var resourceUrl = 'bunny/' + 'api/product-xml-purchases/:path/:id';

        return $resource(resourceUrl, {}, {
            "create": {
                method: 'POST',
                params: {
                    path: "create"
                }
            },
            "byCondition": {
                method: 'POST',
                params: {
                    path: "byCondition"
                },
                isArray:true
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray:true
            },
            "getAllByCondition": {
                method: 'POST',
                params: {
                    path: "getAllByCondition"
                },
                isArray:true
            },
            "createQRCode": {
                method: 'POST',
                params: {
                    path: "createQRCode"
                }
            },
            "aliPay": {
                method: 'GET',
                params: {
                    path: "aliPay"
                }
            },
            "alipayNotify": {
                method: 'POST',
                params: {
                    path: "alipayNotify"
                }
            },
            "weChatNotify": {
                method: 'POST',
                params: {
                    path: "weChatNotify"
                }
            }
        });
    }

    function PaymentSettings($resource) {
        var resourceUrl = 'bunny/' + 'api/payment-settings/:path/:id';
        return $resource(resourceUrl, {}, {
            "get": {
                method: 'GET',
                isArray: true
            }
        });
    }


    function FrontUser($resource) {
        var resourceUrl = 'bunny/' + 'api/front-users/:path/:id';

        return $resource(resourceUrl, {}, {
            "registerByThird": {
                method: 'POST',
                params: {
                    path: "registerByThird"
                }
            },
            "bindByThird": {
                method: 'GET',
                params: {
                    path: "bindByThird"
                }
            },
            "authByThird": {
                method: 'GET',
                params: {
                    path: "authByThird"
                }
            },
            "checkPassword": {
                method: 'GET',
                params: {
                    path: "checkPassword"
                }
            },
            "changePassword": {
                method: 'POST',
                params: {
                    path: "changePassword"
                }
            },
            "forgetPassword": {
                method: 'POST',
                params: {
                    path: "forgetPassword"
                }
            },
            "checkUser": {
                method: 'POST',
                params: {
                    path: "checkUser"
                },
                isArray:true
            },
            "resetPassword": {
                method: 'GET',
                params: {
                    path: "resetPassword"
                }
            },
            "upgrade":{//确认升级
                method: 'GET',
                params: {
                    path: "upgrade"
                }
            },
            "original":{//同意远程协议声明
                method: 'GET',
                params: {
                    path: "original"
                }
            }
        });
    }

    function ThirdPartySetting($resource) {
        var resourceUrl = 'bunny/' + 'api/third-party-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            "getByKey": {
                method: 'GET',
                params: {
                    path: "getByKey"
                }
            }

        });
    }

    function Verify($resource) {
        var resourceUrl = 'bunny/' + 'api/verify-code/:path';

        return $resource(resourceUrl, {}, {
            "checkVerifyCode": {
                method: 'GET',
                params: {
                    path: "checkVerifyCode"
                }
            },
            "getVerifyCode": {
                method: 'GET',
                params: {
                    path: "getVerifyCode"
                }
            }
        });
    }

    function ProductXml($resource) {
        var resourceUrl = 'bunny/' + 'api/product-xmls/:path/:id';

        return $resource(resourceUrl, {}, {
            "batchDelete": {
                method:"POST",
                params:{
                    path:"batchDelete"
                }
            },
            "upgradeProductXml": {
                method:"POST",
                params:{
                    path:"upgradeProductXml"
                }
            },
            "documentToImg": {
                method:"POST",
                params:{
                    path:"documentToImg"
                },
                isArray:true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "getAll": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'getXml': {
                method: 'GET'
            },
            "getAll2": {
                method: "POST",
                params: {
                    path: "getAllByCondition2"
                },
                isArray: true
            },
            "updateSizeInfo": {
                method: "GET",
                params: {
                    path: "updateSizeInfo"
                }
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray:true
            },
            "release": {
                method: "POST",
                params: {
                    path: "release"
                }
            },
             "updateImageArt": {
                method: "GET",
                params: {
                    path: "updateImageArt"
                }
            },
            "updateHashtags": {
                method: "GET",
                params: {
                    path: "updateHashtags"
                }
            },
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'getDocumentByKey':{
                method: "GET",
                params: {
                    path: "uploadDocumentByKey"
                }
            },
            'thirdXmlSave': {
                method: "POST",
                params: {
                    path: "thirdXmlSave"
                },
            },

            'getThirdData': {
                method: "GET",
                params: {
                    path: "getThirdData"
                },
            },
            'onlyRelease': {
                method: "GET",
                params: {
                    path: "onlyRelease"
                },
            },
            'share': {
                method: "GET",
                params: {
                    path: "share"
                },
            },
            'checkAndFixDocument': {
                method: "GET",
                params: {
                    path: 'checkAndFixDocument'
                }
            },
            'rename': {
                method: 'POST',
                params: {
                    path: 'rename'
                }
            },
            "setPrice":{
                method: "GET",
                params: {
                    path: "setPrice"
                }
            },
            "auditing": {
                method: "GET",
                params: {
                    path: "auditing"
                }
            },
            "hotspot": {
                method: "GET",
                params: {
                    path: "hotspot"
                }
            },
            'getPurchasedList':{
                method: 'GET',
                params:{
                    path:'purchasedList',
                },
                isArray: true
            },
            'delete': { method:'DELETE' },
            'cancelShare': {
                method: "GET",
                params: {
                    path: "cancelShare"
                }
            },
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('TextController', TextController);

    TextController.$inject = ['$state', '$scope', 'ProductXml', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', 'FontXmls', '$localStorage', 'Qinius', '$uibModal', '$timeout'];

    function TextController($state, $scope, ProductXml, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, FontXmls, $localStorage, Qinius, $uibModal, $timeout) {
        var vm = this;
        //分页信息
        vm.pageInfo = {
            page: 1,
            size: 20,
            total: 0,
        }
        //批量操作状态
        vm.batchState = false;
        vm.statusList = [
            {label: '全部', value: '', op: '='},
            {label: '未分享', value: null, op: 'isnull'},
            {label: '审核中', value: 'Created', op: '='},
            {label: '已分享', value: 'Approved', op: '='},
            {label: '已驳回', value: 'Rejected', op: '='}
        ]
        vm.selectStatus = '';
        // 批量操作选中的数量
        vm.selectCount = 0;
        //分享按钮Loading
        vm.shareLoading = false;
        //批量分享Loading
        vm.batchShareLoading = false;

        vm.loadPage = loadPage;
        vm.predicate = "lastModifiedDate";
        vm.reverse = false;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.account = $localStorage.user;
        $scope.domains = Qinius.getDomain();
        vm.status = status;
        loadAll();
        //单个删除
        $scope.delete = function (id) {
            MessageService.confirm({
                title: '提示',
                cancel: '取消',
                confirm: '确认',
                msg: '确定要删除该数据？'
            }, function () {
                FontXmls.delete({id: id}, function () {
                    MessageService.success("删除成功");
                    loadAll();
                });
            }, function () {
            });
        };
        //批量删除
        $scope.batchDelete = function () {
            var selectList = vm.textList.filter(function (item) {
                return item.selected === true;
            });
            if (selectList.length === 0) return MessageService.error("至少选择一条数据");
            MessageService.confirm({
                title: '提示',
                cancel: '取消',
                confirm: '确认',
                msg: '确定要删除选中的数据？'
            }, function () {
                var ids = selectList.map(function (item) {
                    return item.id;
                })
                FontXmls.batchDelete(ids, function () {
                    MessageService.success("删除成功");
                    vm.loadAll();
                }, function () {
                    MessageService.error("操作失败，请重试");
                })
            })
        }
        //分页变更
        vm.currentChange = function () {
            $timeout(function () {
                vm.loadAll();
            })
        }

        function loadAll() {
            if (!vm.account) {
                return $state.go('home');
            }
            var items = [];
            var status = vm.statusList.find(function (item) {
                return item.value === vm.selectStatus;
            })
            if (status.value !== "") {
                items.push({key: 'status', value: status.value, op: status.op})
            }
            if (vm.searchQuery) {
                items.push({key: "name", value: vm.searchQuery, op: "like"});
            }
            FontXmls.byCondition({
                items: items,
                page: vm.pageInfo.page - 1,
                size: vm.pageInfo.size,
                sort: ["lastModifiedDate,desc"]
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.pageInfo.total = headers('X-Total-Count');
                vm.textList = data;
                //退出批量操作状态
                vm.batchState = false;
                vm.selectCount = 0;
            }

            function onError(error) {
                MessageService.error("文字模板获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search() {
            if(event.keyCode === 13){
                vm.links = null;
                vm.pageInfo.page = 1;
                vm.loadAll();
            }
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        //发布
        $scope.release = function (data) {
            FontXmls.get({id: data.id}, function (res) {
                $uibModal.open({
                    templateUrl: 'app/entities/text/modal/text-detection.html',
                    controller: 'TextDetectionController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: [function () {
                            return {
                                textDocument: res.textDocument,
                                id: res.id,
                                storeIds: [],
                            };
                        }]
                    }
                }).result.then(function (isReturn) {
                })
            })


            // FontXmls.onlyRelease({id: data.id}, function (res) {
            //     MessageService.success("发布成功");
            //     loadAll();
            // }, function (error) {
            //     MessageService.error("发布失败，请稍后重试！");
            // })
        }
        //状态点击
        $scope.statusCheck = function (e) {
            if (e === vm.selectStatus) return;
            vm.selectStatus = e;
            vm.pageInfo.page = 1;
            vm.loadAll();
        }
        //批量操作选择
        $scope.checkItem = function (item) {
            if (!vm.batchState) {
                return
            }
            if (item.selected) {
                item.selected = false;
            } else {
                item.selected = true;
            }
            //获取选中的数量
            var selectList = vm.textList.filter(function (item) {
                return item.selected === true;
            })
            vm.selectCount = selectList.length;
        }
        //单个分享
        $scope.share = function (data) {
            if (vm.shareLoading) return;
            vm.shareLoading = true;
            FontXmls.share({id: data.id}, function (res) {
                vm.shareLoading = false;
                MessageService.success("分享成功");
                loadAll();
            }, function () {
                vm.shareLoading = false;
                MessageService.error("请求失败，请重试");
            })
        }
        //批量分享
        $scope.batchShare = function () {
            if (vm.batchShareLoading) return;
            var selectList = vm.textList.filter(function (item) {
                return item.selected === true;
            });
            if (selectList.length === 0) return MessageService.error("至少选择一条数据");
            selectList = selectList.filter(function (item) {
                return !item.status || item.status == 'Rejected';
            });
            var ids = selectList.map(function (item) {
                return item.id;
            });
            vm.batchShareLoading = true;
            if (ids.length > 0) {
                FontXmls.batchShare(ids, function () {
                    MessageService.success("分享成功");
                    vm.batchShareLoading = false;
                    vm.loadAll();
                }, function () {
                    vm.batchShareLoading = false;
                    MessageService.error("操作失败，请重试");
                })
            } else {
                vm.batchShareLoading = false;
                vm.loadAll();
            }

        }
        $scope.delete = function (data) {
            MessageService.confirm({
                title: '提示',
                cancel: '取消',
                confirm: '确认',
                msg: '确定要删除该数据？'
            }, function () {
                FontXmls.delete({id: data.id}, function (res) {
                    MessageService.success("删除成功");
                    loadAll();
                })
            }, function () {
            });
        }
        //查看
        $scope.look = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/text/modal/text-xml-look.html',
                controller: 'TextXmlLookController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function () {
                        return {
                            url: data.cover,
                            domain: $scope.domains.domain
                        };
                    }]
                }
            });
        }
        //新增
        $scope.add = function () {
            $uibModal.open({
                templateUrl: 'app/entities/text/modal/text-add.html',
                controller: 'TextAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function () {
                        return {};
                    }]
                }
            });
        }

        $scope.copy = function(item){
            FontXmls.get({id: item.id}, function (res) {
                res.id = "";
                res.opened = false;
                res.status = null;
                FontXmls.save(res, function (res) {
                    MessageService.success("复制成功");
                    vm.loadAll();
                })
            })
        }
        //查看驳回原因
        $scope.showReason = function (item) {
            MessageService.confirm({
                title:'驳回理由',
                hideCancel:true,
                confirm:'知道了',
                msg:'理由： '+ (item.rejectReason || '')
            },function(){

            })
        }

        function status(item) {
            switch (item) {
                case 'Created':
                    return '已分享';
                    break;
                case 'Approved':
                    return '已通过';
                    break;
                case 'Rejected':
                    return '已拒绝';
                    break;
                default:
                    return '未分享'
            }
        }
    }
})();

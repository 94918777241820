(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('mycenter', {
                parent: 'app',
                url: '/mycenter?page&sort&search&modelType',
                data: {
                    authorities: [],
                    pageTitle: 'platformAppTitle.mycenter'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/mycenter/mycenter.html',
                        controller: 'MycenterController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            modelType: $stateParams.modelType,
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productXml');
                        $translatePartialLoader.addPart('reprintType');
                        $translatePartialLoader.addPart('frontStatus');
                        $translatePartialLoader.addPart('patternType');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('title');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();

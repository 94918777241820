(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FontManagementController', FontManagementController);

    FontManagementController.$inject = ['$scope', '$state', 'FontManagement', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', "$rootScope",
    '$localStorage', 'Qinius', '$q', '$timeout'];

    function FontManagementController ($scope, $state, FontManagement, ParseLinks, paginationConstants, pagingParams, MessageService, $rootScope,
        $localStorage, Qinius, $q, $timeout) {
        var vm = this;


        vm.tabs = [{key:"background", value:"背景", url:'background'},
        {key:"imageBox", value:"相框", url:'frame-control'},
        {key:"borderBox", value:"边框", url:'frame-control'},
        {key:"font", value:"字体", url:'font-management'},
        {key:"clipArt", value:"素材", url:'clip-art'},
        {key:"image", value:"图片", url:'pic'}];
        vm.currentName = "font";


        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.account = $localStorage.user;

        var unsubscribe = $rootScope.$on('platformApp:bFontManagementUpdate', function(event) {
            loadAll();
        });
        $scope.$on('$destroy', unsubscribe);

        loadAll();

        // 当前页变更
        vm.currentChange = function(){
            $timeout(function(){
                loadAll();
            })
        }

        $scope.exitBatch = function(){
            vm.fontManagements.forEach(function(it){
                it.selected = false;
            })
            vm.batch = false;
            vm.count = 0;
            vm.selecteAll = false;
        }



        $scope.batchDelet = function(){
            var ids = [];
            vm.fontManagements.forEach(function(it){
                if (it.selected) {
                    ids.push(it.id);
                }
            });
            if (ids.length>0) {
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'确定删除所选字体？',
                }, function(){
                    FontManagement.batchDelete({ids_:ids}, function(res){
                        MessageService.success("删除成功");
                        loadAll();
                        vm.batch = false;
                        vm.count = 0;
                        vm.selecteAll = false;
                    }, function(va){
                        MessageService.error("删除失败，请稍后再试");
                    })
                })
            }else{
                MessageService.error("未勾选任何有效的背景，请确认后再操作");
            }
        }


        $scope.selecteAll = function(){
            vm.count = 0;
            if (vm.selecteAll) {
                vm.fontManagements.forEach(function(it){
                    it.selected = false;
                })
                vm.selecteAll = false;
            }else{
                vm.fontManagements.forEach(function(it){
                    it.selected = true;
                    vm.count ++;
                })
                vm.selecteAll = true;
            }
        }

        $scope.itemSelect = function(item){
            if (!vm.batch) {
                return
            }
            item.selected = !item.selected;
            vm.count = 0;
            vm.fontManagements.forEach(function(it){
                if (it.selected) {
                    vm.count ++;
                }
            });
            vm.selecteAll = vm.count == vm.fontManagements;
        }

        vm.domain = Qinius.getDomain();
        $q.all([ vm.domain.$promise]).then(function(){
            vm.domainQ =   vm.domain.domain;
        })

        $scope.delete = function(id){
            if (vm.batch) {
                MessageService.info('请先退出批量操作')
                return
            }
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定删除该字体？'
            },function(){
                FontManagement.delete({id: id},function () {
                    loadAll();
                });
            },function(){});
        };

        function loadAll () {
            if (vm.account == undefined || vm.account == null) {
                $state.go('home');
                return;
            }
            var items = [];
            if (pagingParams.search) {
                items.push({key:"name",value:pagingParams.search, op:"like"});
            }
            FontManagement.byCondition({
                items:items,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.fontManagements = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                console.log(2);
                MessageService.error("字体获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        $scope.viewFont = function(fontManagement){
            if (vm.batch) {
                MessageService.info('请先退出批量操作')
                return
            }
            $state.go('font-management-detail',{id:fontManagement.id})
        }
    }
})();

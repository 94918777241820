(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TextShareController', TextShareController);

    TextShareController.$inject = ['$state', '$scope', 'ProductXml', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', 'FontXmls', '$localStorage', 'Qinius', '$uibModal','$timeout'];

    function TextShareController($state, $scope, ProductXml, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, FontXmls, $localStorage, Qinius, $uibModal,$timeout) {
        var vm = this;
        //搜索防抖定时器
        vm.timer = null;
        //分页信息
        vm.pageInfo = {
            page:1,
            size:20,
            total:0,
        }
        //批量操作状态
        vm.batchState = false;
        vm.batchLoading = false;
        //批量操作选中的条数
        vm.selectCount = 0;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = "";
        vm.account = $localStorage.user;
        $scope.domains = Qinius.getDomain();

        loadAll();

        $scope.delete = function(id){
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除该数据？'
            },function(){
                FontXmls.delete({id: id},function () {
                    loadAll();
                });
            },function(){});
        };
        //当前页变更
        vm.currentChange = function(){
            $timeout(function(){
                vm.loadAll();
            })
        }
        function loadAll () {
            if (vm.account == undefined || vm.account == null) {
                $state.go('home');
                return;
            }
            if (vm.searchQuery != "") {
                FontXmls.byCondition2({
                    items:[{key:"name",value:vm.searchQuery, op:"like"},{key:"opened", op:"=", value:'true'},{key:"status", op:"=", value:'Approved'}],
                    page: vm.pageInfo.page - 1,
                    size: vm.pageInfo.size,
                    sort: ["lastModifiedDate,desc"]
                }, onSuccess, onError);
            } else {
                FontXmls.byCondition2({
                        items:[{key:"opened", op:"=", value:'true'},{key:"status", op:"=", value:'Approved'}],
                        page: vm.pageInfo.page - 1,
                        size: vm.pageInfo.size,
                        sort: ["lastModifiedDate,desc"]
                    },
                    onSuccess, onError);

            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.pageInfo.total = headers('X-Total-Count');
                vm.textList = data;
                //退出批量编辑模式
                vm.selectCount = 0;
                vm.batchState = false;
            }
            function onError(error) {
                MessageService.error("字体获取失败");
            }
        }
        function search() {
            if(event.keyCode === 13){
                vm.links = null;
                vm.pageInfo.page = 1;
                vm.predicate = 'id';
                vm.reverse = false;
                if(vm.searchQuery == ""){
                    vm.reverse = true;
                }
                vm.loadAll();
            }
        }

        $scope.release = function (data) {
            FontXmls.onlyRelease({id: data.id}, function (res) {

            })
        }
        //查看
        $scope.look = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/text/modal/text-xml-look.html',
                controller: 'TextXmlLookController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: "md",
                resolve: {
                    entity: [function() {
                        return {
                            url: data.cover,
                            domain: $scope.domains.domain
                        };
                    }]
                }
            });
        }
        // 批量操作点击选择
        vm.checkItem = function (item) {
            if(!vm.batchState) return;
            item.checked = !item.checked;
            var checkedList = vm.textList.filter(function(item){
                return item.checked === true
            });
            vm.selectCount = checkedList.length;
        }
        //批量使用
        $scope.batchUse = function(){
            if(vm.batchLoading) return;
            var checkedList = vm.textList.filter(function(item){
                return item.checked === true
            });
            var ids = checkedList.map(function(item){
                return item.id;
            })
            vm.batchLoading = true;
            FontXmls.batchUse(ids,function(res){
                MessageService.success("使用成功");
                vm.batchLoading = false;
                vm.loadAll();
            },function(){
                vm.batchLoading = false;
                MessageService.error("请求失败，请重试");
            });

        }
        $scope.save = function(data){
            FontXmls.get({id: data.id}, function (res) {
                console.log(res)
                res.id = "";
                res.opened = false;
                res.status = null;
                FontXmls.save(res, function (res) {
                    MessageService.success("使用成功");
                })
            })
        }
    }
})();

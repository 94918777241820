(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('BackGroundViewController', BackGroundViewController);

    BackGroundViewController.$inject = ['$scope', '$uibModalInstance', 'MessageService','entity'];

    function BackGroundViewController( $scope, $uibModalInstance, MessageService,entity) {
        var vm = this;
        vm.data = entity;

        $scope.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService', 'errorConstants', 'Verify',
    'MessageService', '$scope', '$interval', '$state', 'FrontUser'];

    function RegisterController ($translate, $timeout, Auth, LoginService, errorConstants, Verify,
        MessageService, $scope, $interval, $state, FrontUser) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerAccount = {};
        vm.success = null;
        vm.checkCode = false;
        vm.disabled = false;
        //密码框类型
        vm.passType = "password";
        vm.confirmPassType = "password";
        //手机号校验错误状态
        vm.checkPhoneErr = false;
        $scope.paracont = "获取验证码";
        var timer = null;
        $(".navbar").hide();
        $(".wrap").css({margin:0})
        vm.bottom =(window.innerHeight-parseFloat($(".register-content").css('height'))-parseFloat($(".register-all").css('top')))/2;
        console.log($scope,111)
        $scope.getVerifyCode = function () {
            if (vm.disabled) {
                return;
            }
            if (vm.registerAccount.phone == null || vm.registerAccount.phone == "") {
                vm.checkPhoneErr = true;
                MessageService.error("请先输入手机号码");
                return;
            }

            var flag = checkPhone(vm.registerAccount.phone);
            if(!flag){
                vm.checkPhoneErr = true;
                return MessageService.error('您的手机号不合规，请检查重新输入');
            }
            $scope.checkPhone(function(){
                vm.countdown = 120;
                // 发送验证码到手机
                Verify.getVerifyCode({
                    tel: vm.registerAccount.phone,
                    incident: "用户注册"
                }, function (msg) {
                    clearInterval(timer);
                    timer = $interval(function () {
                        if (vm.countdown > 0) {
                            vm.disabled = true;
                            vm.countdown -- ;
                            $scope.paracont = vm.countdown + 's';
                        } else {
                            vm.disabled = false;
                            $interval.cancel(timer);
                            $scope.paracont = "重发验证码";
                        }
                    }, 1000);
                });
            })
        };
        $scope.changePhone = function(){
            vm.checkPhoneErr = false;
        }
        $scope.checkVerifyCode = function(){
            if (vm.registerAccount.code.length<=5) {
                return;
            }
            Verify.checkVerifyCode({
                tel: vm.registerAccount.phone,
                code: vm.registerAccount.code,
                incident: "用户注册"
            }, function (msg) {
                if (msg.status == 200 && msg.message==true) {
                    vm.checkCode = true;
                }else{
                   MessageService.error("手机验证失败");
                }
            },function(){
                MessageService.error("手机验证失败");
            });
        }


        $timeout(function (){angular.element('#login').focus();});

        $scope.checkUser = function(){
            if (vm.registerAccount.name == null || vm.registerAccount.name == "") {
                return;
            }
            FrontUser.checkUser({
                items: [{ key: "name", op: "=", value:vm.registerAccount.name}]
            }, function(data) {
                if (data.length > 0) {
                    MessageService.error("该账号已经存在，请选择其他用户名");
                } else {
                    MessageService.info("账号可用");
                }
            });
        }

        $scope.checkPhone = function(callback){
            if (vm.registerAccount.phone == null || vm.registerAccount.phone == "") {
                return;
            }
            FrontUser.checkUser({
                items: [{ key: "phone", op: "=", value:vm.registerAccount.phone}, { key: "cancelled", op: "=", value:"false"}, { key: "status", op: "!=", value:"Rejected"}]
            }, function(data) {
                if (data.length > 0) {
                    MessageService.error("该手机号已经存在，请选择其他手机号");
                } else {
                    // MessageService.info("手机号可用");
                    // vm.disabled = false;
                    if(typeof callback === 'function'){
                        callback();
                    }
                }
            })
        }
        //更改密码框类型
        $scope.changePassType = function(passType,type){
            vm[passType] = type;
        }
        function register () {
            if(vm.registerAccount.name == null || vm.registerAccount.name == ""){
                return MessageService.error("账号不能为空");
            }
            if(!checkUserName(vm.registerAccount.name)){
                return MessageService.error("账号只能使用字母以及数字");
            }
            if(vm.registerAccount.password && vm.registerAccount.password.length < 5){
                return MessageService.error('密码至少有五个字符');
            }
            if (vm.registerAccount.password !== vm.confirmPassword) {
                return MessageService.error("两次输入的密码不一致，请重新输入");
            }
            vm.registerAccount.langKey = $translate.use();
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;
            Verify.checkVerifyCode({
                tel: vm.registerAccount.phone,
                code: vm.registerAccount.code,
                incident: "用户注册"
            }, function (msg) {
                if (msg.status == 200 && msg.message==true) {
                    Auth.createAccount(vm.registerAccount).then(function () {
                        vm.success = 'OK';
                        MessageService.success("注册成功，管理员审核后，将短信通知您");
                        $state.go("home");
                    }).catch(function (response) {
                        vm.success = null;
                        if (response.status === 400 &&  response.data === "name or phone already in use") {
                            MessageService.error("注册失败，用户名或手机号已经被使用，请更换后重新注册");
                        } else if (response.status === 400 && angular.fromJson(response.data).type === errorConstants.EMAIL_ALREADY_USED_TYPE) {
                            vm.errorEmailExists = 'ERROR';
                        } else {
                            vm.error = 'ERROR';
                        }
                    });
                }else{
                    MessageService.error("手机验证失败");
                }
            },function(){
                MessageService.error("手机验证失败");
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PageStandardController', PageStandardController);

    PageStandardController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'items', '$state', 'MessageService', 'Catalog','Qinius', '$q', 'UidService','ProductXml'];

    function PageStandardController ($timeout, $scope, $stateParams, $uibModalInstance, items, $state, MessageService, Catalog,Qinius, $q, UidService, ProductXml) {
        var vm = this;
        console.log(items)
        $scope.domains = Qinius.getDomain();
        $q.all([$scope.domains.$promise]).then(function () {
            vm.url = $scope.domains.domain;
        })
        vm.cancel = cancel;
        vm.save = save;
        vm.itemType = items.type;
        vm.hideModal = items.hideModal || false;
        vm.catalogId = null;
        $scope.changeCatalogId = function () {
            var Item = vm.catalogs.find(function(item){
                return item.id === vm.catalogId;
            })
            if(Item){
                vm.data.catalog = Item;
            }
        }
        vm.title = function () {
            if(items.type == 'add'){return '新建'};
            if(items.type == 'edit'){return '编辑'};
            if(items.type == 'copy'){return '复制'};
        }
        vm.isMonth = false;
        vm.dateformat = 'yyyy/MM';
        $scope.options = {minMode:'month'};
        vm.month = "";
        if(items.type == 'add'){
            if(items.custom){
                vm.type = false;
                vm.data = {
                    pageSizeH:'',
                    pageSizeW:'',
                    pageBloodT:'',
                    pageBloodB:'',
                    pageBloodL:'',
                    pageBloodR:'',
                    pageEdits:[{
                        pageEditX:'',
                        pageEditY:'',
                        pageEditW:'',
                        pageEditH:'',
                    }],
                    pageEditUnit: 'mm',
                    pageSizeUnit: 'mm',
                    pageBloodUnit: 'mm',
                    pageEditUnit1: 'mm',
                    pageSizeUnit1: 'mm',
                    pageBloodUnit1: 'mm',
                    pageCount: 1,
                    bindingType: '',
                    pattern: '',
                    replace: '',
                    addpage: false,
                }
            }else {
                vm.type = true;
                vm.data = items.data;
            }
            getCatalog()
        }else {
            ProductXml.get({id: items.id}, function (res) {
                if(items.type == 'copy' && !vm.hideModal){
                    res.name+='_副本'
                }
                vm.data = res;
                vm.data.catalog = {
                    id: res.catalogId,
                    name: res.catalogName,
                }
                vm.data.bindingType = res.document.bindingtype;
                vm.data.pattern = res.document.pattern;
                vm.data.replace = res.document.replace;
                vm.data.addpage = res.document.addpage;
                vm.data.minpage = res.document.minpage;
                vm.data.maxpage = res.document.maxpage;
                vm.data.addunit = res.document.addunit;
                vm.data.productXmlId = items.id;
                if(res.document.config.dynamic){
                    $timeout(function () {
                        vm.month = res.document.config.startYear + '-' + (res.document.config.startMonth>9?res.document.config.startMonth:'0'+res.document.config.startMonth);
                        $("#startTime").val(vm.month)
                    },200)
                }
                getCatalog()
                vm.catalogId = vm.data.catalogId;
                //隐藏弹窗，直接调用提交接口
                if(vm.itemType === 'copy' && vm.hideModal){
                    vm.save();
                }
            })
            // if(items.type == 'copy'&&!vm.data.id){
            //     vm.type = false;
            // }else{
            //     vm.type = true;
            // }
        }
        $scope.template = {
            transparency: 1,
            cover: null,
            scene: null,
            pageI: 0,
            angle: 0,
            backup: false,
            spread: false,
            unreplace: false,
            letterspacing: 0,
            lineheight: 0,
            text: '',
            bgimgs: {bgimg: []},
            bgimgs2: {bgimg: []},
            masks: {mask: []},
            cliparts: {clipart: []},
            frameConfigs: {frameConfig: []},
            documentpics: {documentpic: []},
            pages: {
                page: []
            },
            showPic: false,
            psdFiles: {psdFile: []}
        };
        vm.units = [
            {name:'px', id:'px'},
            {name:'mm', id:'mm'},
            {name:'英寸', id:'in'},
        ]
        function getCatalog(){
            Catalog.getAll({items:[{key:"usable", op:"=", value:"true"},{key:"catalogType", op:"=", value:"XML"}]}, function (res) {
                vm.catalogs = res;
                if(items.type != 'add'){
                    for(var i=0; i<res.length; i++){
                        if(res[i].id == vm.data.catalog.id){
                            vm.data.catalog = res[i];
                        }
                    }
                }
            });
        }
        vm.replaces = [
            {id: true, value: '可替换'},
            {id: false, value: '不可替换'}
        ]
        vm.addpages = [
            {id: true, value: '可加页'},
            {id: false, value: '不可加页'}
        ];
        $scope.addPageEdit = function () {
            vm.data.pageEdits.push({
                pageEditX:'',
                pageEditY:'',
                pageEditW:'',
                pageEditH:''
            })
        }
        $scope.removePageEdit = function(index){
            vm.data.pageEdits.splice(index, 1);
        };

        function checkFloatNum(str){
            var reg_zs = /^[0-9]\d*(\.\d+)?$/i;
            if (!reg_zs.test(str)) {
                return false;
            }
            return true;
        }
        //保存
        function save(whether) {
            if(vm.isSaving){
                return
            }
            if(items.type == 'add') {
                if(!vm.data.name){
                    MessageService.error("模板名称不能为空");
                    return
                }
                if(!checkFloatNum(vm.data.pageSizeW) || !checkFloatNum(vm.data.pageSizeH)){
                    MessageService.error("请填写正确的画布尺寸");
                    return
                }
                if(!vm.data.catalog){
                    MessageService.error("请选择产品");
                    return
                }
                if(!checkFloatNum(vm.data.pageBloodT) || !checkFloatNum(vm.data.pageBloodB) || !checkFloatNum(vm.data.pageBloodL) || !checkFloatNum(vm.data.pageBloodR)){
                    MessageService.error("请填写正确的出血尺寸");
                    return
                }
                for(var i=0; i<vm.data.pageEdits.length; i++){
                    var pageEdit = vm.data.pageEdits[i];
                    if(!checkFloatNum(pageEdit.pageEditX) || !checkFloatNum(pageEdit.pageEditY) || !checkFloatNum(pageEdit.pageEditW) || !checkFloatNum(pageEdit.pageEditH)){
                        MessageService.error("请填写正确的编辑区域");
                        return false
                    }
                }
                if(vm.data.pageCount<1){
                    MessageService.error("页数必须大于0");
                    return
                }
                if(typeof vm.data.replace != 'boolean'){
                    MessageService.error("请选择模板替换");
                    return
                }
                if(items.custom){
                    if(vm.data.pageSizeUnit != 'mm'){
                        unit('mm')
                    }
                    vm.data.pageTemplates = [];
                    for(var i=0; i<vm.data.pageCount; i++){
                        var _data = {
                            id:'',
                            seq:i+1,
                            added:true,
                            deleted:false,
                            pageSizeH:vm.data.pageSizeH,
                            pageSizeW:vm.data.pageSizeW,
                            pageBloodT:vm.data.pageBloodT,
                            pageBloodB:vm.data.pageBloodB,
                            pageBloodL:vm.data.pageBloodL,
                            pageBloodR:vm.data.pageBloodR,
                            pageEdits:vm.data.pageEdits,
                            pageEditUnit: vm.data.pageEditUnit,
                            pageSizeUnit: vm.data.pageSizeUnit,
                            pageBloodUnit: vm.data.pageBloodUnit,
                            cancelled:false,
                            productTemplateId:vm.data.id,
                            mould:vm.data.mould
                        }
                        vm.data.pageTemplates.push(_data)
                    }
                }else{
                    for(var i=0; i<vm.data.pageTemplates.length; i++){
                        vm.data.pageTemplates[i].pageEdits = vm.data.pageEdits;
                    }
                }
                for(var j=vm.data.pageTemplates.length-1; j>=0; j--){
                    if(vm.data.pageTemplates[j].cancelled){
                        vm.data.pageTemplates.splice(j, 1)
                    }
                }
                addSave();
            }
            if (items.type == 'edit' || items.type == 'copy') {
                if(!vm.data.name){
                    MessageService.error("模板名称不能为空");
                    return
                }
                if(!vm.data.catalog){
                    MessageService.error("请选择产品");
                    return
                }
                if(typeof vm.data.replace != 'boolean'){
                    MessageService.error("请选择模板替换");
                    return
                }
                if(vm.data.productType == 'BOOK' || (vm.data.productType == 'CALENDAR'&&!vm.data.document.config.dynamic)){

                    if(vm.data.addpage){
                        if(!vm.data.minpage || !vm.data.maxpage){
                            MessageService.error("页数范围必须大于0");
                            return
                        }
                        if(vm.data.minpage > vm.data.maxpage){
                            MessageService.error("结束页数不能小于开始页数，请重新设置页数范围");
                            return
                        }
                        if(!vm.data.addunit){
                            MessageService.error("加页单位必须大于0");
                            return
                        }
                    }
                }
                vm.isSaving = true;
                vm.data.document.bindingtype = vm.data.bindingType;
                vm.data.document.pattern = vm.data.pattern;
                vm.data.document.replace = vm.data.replace;
                vm.data.document.addpage = vm.data.addpage;
                vm.data.document.minpage = vm.data.minpage;
                vm.data.document.maxpage = vm.data.maxpage;
                vm.data.document.addunit = vm.data.addunit;
                vm.data.catalogId = vm.data.catalog.id;
                vm.data.catalogName = vm.data.catalog.name;
                if(vm.data.document.config.dynamic){
                    vm.month = $("#startTime").val();
                    if (!!vm.month) {
                        vm.data.document.config.startYear = (vm.month.split("-"))[0];
                        vm.data.document.config.startMonth = (vm.month.split("-"))[1];
                    }
                }
                if(items.type == 'edit'){
                    ProductXml.update(vm.data, function (res) {
                        vm.isSaving = false;
                        $uibModalInstance.close(true);
                        if(whether){
                            $state.go('page-my-suffix-edit', {id: res.id}, {reload: true})
                        }else{
                            MessageService.success("保存成功");
                        }
                    }, function () {
                        vm.isSaving = false;
                    });
                }else{
                    vm.data.id = null;
                    ProductXml.save(vm.data, function (res) {
                        vm.isSaving = false;
                        $uibModalInstance.close();
                        $state.go('page-my-suffix-edit', {id: res.id}, {reload: true})
                    }, function () {
                        vm.isSaving = false;
                    });
                }
            }
        }
        function addSave() {
            vm.isSaving = true;
            vm.hashtags = '';
            if(vm.data.catalog.hashtags){
                for (var i = 0; i < vm.data.catalog.hashtags.length; i++) {
                    vm.hashtags += vm.data.catalog.hashtags[i].name + ','
                }
            }
            $scope.template.pages.page=[];
            for (var i = 0; i < vm.data.pageTemplates.length; i++) {
                var _page = vm.data.pageTemplates[i];
                var editSize = [];
                _page.pageEdits.forEach(function (editbox) {
                    editSize.push({
                        height: editbox.pageEditH,
                        width: editbox.pageEditW,
                        x: editbox.pageEditX,
                        y: editbox.pageEditY
                    })
                });
                var _page1 = createPage(_page, editSize, i);
                if (_page.mould) {
                    _page1.scene = {
                        background: {
                            resource: {
                                identifier: _page.mould.modelPic.indexOf('http') > -1 ? _page.mould.modelPic : vm.url + _page.mould.modelPic,
                                provider: "qiniu"
                            }
                        },
                        geometry: {
                            width: _page.mould.gWidth,
                            height: _page.mould.gHeight,
                            x: 0,
                            y: 0
                        },
                        imagebox: {
                            geometry: {
                                width: _page.mould.width,
                                height: _page.mould.height,
                                x: _page.mould.x,
                                y: _page.mould.y
                            },
                            rotation: {angle: 0},
                            transparency: 1,
                        },
                        hierarchy: _page.mould.hierarchy,
                        isScene: true,
                        scale: 1,
                        unit: "mm",
                    }
                }
                $scope.template.pages.page.push(_page1);
            }
            $scope.template.bindingtype = vm.data.bindingType;
            $scope.template.pattern = vm.data.pattern;
            $scope.template.cover = angular.copy(vm.data.cover);
            $scope.template.replace = vm.data.replace;
            $scope.template.version = "2.0";
            $scope.template.addpage = vm.data.addpage;
            $scope.template.minpage = vm.data.minpage;
            $scope.template.maxpage = vm.data.maxpage;
            $scope.template.addunit = vm.data.addunit;
            ProductXml.save({
                document: $scope.template,
                bindingType: vm.data.bindingType,
                pattern: vm.data.pattern,
                replace: vm.data.replace,
                name: vm.data.name,
                catalogId: vm.data.catalog.id,
                catalogName: vm.data.catalog.name,
                version: '2.0',
                type: 'XML',
                productTemplateId: '',
                hashtags: vm.hashtags,
                modelBlendent: vm.data.modelBlendent,
                modelData: vm.data.modelData,
                modelPath: vm.data.modelPath,
                modelType: vm.data.modelType
            }, function (res) {
                vm.isSaving = false;
                $uibModalInstance.close();
                $state.go('page-my-suffix-edit', {id: res.id}, {reload: true})
            }, function () {
                vm.isSaving = false;
            });
        }
        function createPage(_page, editSize, i) {
            var _page1 = {
                uuid: UidService.get(),
                isScene: false,
                bgImg: '',
                background: {resource: {},color:'#ffffff', type: "Color"},
                pagecover: {},
                levels: {},
                barcode: {},
                qrcode: {},
                editbox: editSize[0],
                editboxes: {
                    editbox: editSize
                },
                mediabox: {
                    height: _page.pageSizeH,
                    width: _page.pageSizeW,
                },
                trimbox: {
                    height: _page.pageSizeH - Number(_page.pageBloodB) - Number(_page.pageBloodT),
                    width: _page.pageSizeW - Number(_page.pageBloodL) - Number(_page.pageBloodR),
                    x: _page.pageBloodL,
                    y: _page.pageBloodT
                },
                scale: 1,
                proportion: 1,
                seq: i + 1,
                spread: false,
                backup: false,
                unreplace: !vm.data.replace
            }
            return _page1;
        }
        //返回
        function cancel() {
            $uibModalInstance.close();
        }
        //单位换算
        $scope.unitConversion = function (item) {
            unit(item)
        }
        function unit(item){
            if(vm.data.pageSizeUnit == 'mm'){
                vm.data.pageSizeW = MmTurnPx(vm.data.pageSizeW).toFixed(2) - 0;
                vm.data.pageSizeH = MmTurnPx(vm.data.pageSizeH).toFixed(2) - 0;
                vm.data.pageBloodT = MmTurnPx(vm.data.pageBloodT).toFixed(2) - 0;
                vm.data.pageBloodB = MmTurnPx(vm.data.pageBloodB).toFixed(2) - 0;
                vm.data.pageBloodL = MmTurnPx(vm.data.pageBloodL).toFixed(2) - 0;
                vm.data.pageBloodR = MmTurnPx(vm.data.pageBloodR).toFixed(2) - 0;
                vm.data.pageEdits.forEach(function (pageEdit) {
                    pageEdit.pageEditX = MmTurnPx(pageEdit.pageEditX).toFixed(2) - 0;
                    pageEdit.pageEditY = MmTurnPx(pageEdit.pageEditY).toFixed(2) - 0;
                    pageEdit.pageEditW = MmTurnPx(pageEdit.pageEditW).toFixed(2) - 0;
                    pageEdit.pageEditH = MmTurnPx(pageEdit.pageEditH).toFixed(2) - 0;
                })
            }
            if (vm.data.pageSizeUnit == 'in'){
                vm.data.pageSizeW = InTurnPx(vm.data.pageSizeW).toFixed(2) - 0;
                vm.data.pageSizeH = InTurnPx(vm.data.pageSizeH).toFixed(2) - 0;
                vm.data.pageBloodT = InTurnPx(vm.data.pageBloodT).toFixed(2) - 0;
                vm.data.pageBloodB = InTurnPx(vm.data.pageBloodB).toFixed(2) - 0;
                vm.data.pageBloodL = InTurnPx(vm.data.pageBloodL).toFixed(2) - 0;
                vm.data.pageBloodR = InTurnPx(vm.data.pageBloodR).toFixed(2) - 0;
                vm.data.pageEdits.forEach(function (pageEdit) {
                    pageEdit.pageEditX = InTurnPx(pageEdit.pageEditX).toFixed(2) - 0;
                    pageEdit.pageEditY = InTurnPx(pageEdit.pageEditY).toFixed(2) - 0;
                    pageEdit.pageEditW = InTurnPx(pageEdit.pageEditW).toFixed(2) - 0;
                    pageEdit.pageEditH = InTurnPx(pageEdit.pageEditH).toFixed(2) - 0;
                })
            }
            if(item == 'mm'){
                vm.data.pageSizeW = PxTurnMm(vm.data.pageSizeW).toFixed(2) - 0;
                vm.data.pageSizeH = PxTurnMm(vm.data.pageSizeH).toFixed(2) - 0;
                vm.data.pageBloodT = PxTurnMm(vm.data.pageBloodT).toFixed(2) - 0;
                vm.data.pageBloodB = PxTurnMm(vm.data.pageBloodB).toFixed(2) - 0;
                vm.data.pageBloodL = PxTurnMm(vm.data.pageBloodL).toFixed(2) - 0;
                vm.data.pageBloodR = PxTurnMm(vm.data.pageBloodR).toFixed(2) - 0;
                vm.data.pageEdits.forEach(function (pageEdit) {
                    pageEdit.pageEditX = PxTurnMm(pageEdit.pageEditX).toFixed(2) - 0;
                    pageEdit.pageEditY = PxTurnMm(pageEdit.pageEditY).toFixed(2) - 0;
                    pageEdit.pageEditW = PxTurnMm(pageEdit.pageEditW).toFixed(2) - 0;
                    pageEdit.pageEditH = PxTurnMm(pageEdit.pageEditH).toFixed(2) - 0;
                })
            }
            if(item == 'in'){
                vm.data.pageSizeW = PxTurnIn(vm.data.pageSizeW).toFixed(2) - 0;
                vm.data.pageSizeH = PxTurnIn(vm.data.pageSizeH).toFixed(2) - 0;
                vm.data.pageBloodT = PxTurnIn(vm.data.pageBloodT).toFixed(2) - 0;
                vm.data.pageBloodB = PxTurnIn(vm.data.pageBloodB).toFixed(2) - 0;
                vm.data.pageBloodL = PxTurnIn(vm.data.pageBloodL).toFixed(2) - 0;
                vm.data.pageBloodR = PxTurnIn(vm.data.pageBloodR).toFixed(2) - 0;
                vm.data.pageEdits.forEach(function (pageEdit) {
                    pageEdit.pageEditX = PxTurnIn(pageEdit.pageEditX).toFixed(2) - 0;
                    pageEdit.pageEditY = PxTurnIn(pageEdit.pageEditY).toFixed(2) - 0;
                    pageEdit.pageEditW = PxTurnIn(pageEdit.pageEditW).toFixed(2) - 0;
                    pageEdit.pageEditH = PxTurnIn(pageEdit.pageEditH).toFixed(2) - 0;
                })
            }
            vm.data.pageSizeUnit = item;
            vm.data.pageBloodUnit = item;
            vm.data.pageEditUnit = item;
            vm.data.pageSizeUnit1 = item;
            vm.data.pageBloodUnit1 = item;
            vm.data.pageEditUnit1 = item;
        }
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[0];
            return arrDPI;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TemplateUpgradeController', TemplateUpgradeController);

    TemplateUpgradeController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProductXml','MessageService'];

    function TemplateUpgradeController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProductXml,MessageService) {
        var vm = this;
        vm.template = entity;
        vm.isCover = true;
        vm.pages = [];
        init()
        function init(){
            ProductXml.get({id: vm.template.id}, function (resp) {
                vm.pages = resp.document.pages.page;
                $timeout(function(){
                    swiper();
                })
            })
        }
        vm.productList = [
            {name:'照片书', id:'BOOK',},
            {name:'冲印', id:'PHOTO',},
            {name:'台历', id:'CALENDAR'},
            {name:'万物',id:'THING'},
        ];
        vm.productSeq=0;
        $scope.productCb = function(index){
            vm.productSeq=index;
        };
        vm.cancel = function () {
            $uibModalInstance.close(false);
        };
        vm.save = function () {
            console.log(vm.template)
            console.log(vm.productList[vm.productSeq])
            var data ={id:vm.template.id,productType:vm.productList[vm.productSeq].id};
            if (vm.productList[vm.productSeq].id == 'BOOK' && vm.isCover) {
                data.patternType = 'BOOK';
            }
            ProductXml.upgradeProductXml(data,function (res) {
                if(res.status == 200){
                    $uibModalInstance.close(true);
                }else{
                    MessageService.error(res.message || "模板升级失败，请稍后重试");
                    $uibModalInstance.close(false);
                }
            },function (error) {
                MessageService.error(error.data.message || "模板升级失败，请稍后重试");
                $uibModalInstance.close(false);
            })
        }
        function swiper() {
            var swiper = new Swiper('.swiper-container-modal', {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }
    }
})();

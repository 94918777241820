(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TemplateAddController', TemplateAddController);

    TemplateAddController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'items', '$state', 'MessageService', 'Catalog','Qinius', '$q', 'UidService',
        '$uibModal','TitleWidget','MonthWidget','ProductXml','$sce'];

    function TemplateAddController ($timeout, $scope, $stateParams, $uibModalInstance, items, $state, MessageService, Catalog,Qinius, $q, UidService,
                                    $uibModal,TitleWidget,MonthWidget,ProductXml,$sce) {
        var vm = this;

        $scope.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        $q.all([$scope.domains.$promise, vm.tokens.$promise]).then(function () {
            vm.url = $scope.domains.domain;
            vm.token = vm.tokens.token;
        })
        console.log(items)
        vm.productType = items.productType;
        vm.data={
            modelType:"NONE",
            productType: vm.productType || "BOOK",
            pictureType:"P0",
            parts:[],
            photoPage:'14',
            photoType:'ordinary',
            catalog:{},
            innerOffset:0,
            unit:'mm',
            unit1:'mm',
            pageEdits:[{
                pageEditX:'',
                pageEditY:'',
                pageEditW:'',
                pageEditH:'',
            }],
            cover:"",
            addpage:false,
            replace:false,
            spread:false
        };
        vm.modelData = [];
        vm.step = 1;
        vm.titleWidget = "";
        vm.monthWidget = "";
        getData();
        function getData(){
            Catalog.getAll({items:[{key:"usable", op:"=", value:"true"},{key:"catalogType", op:"=", value:"XML"}]}, function (res) {
                vm.catalogs = res;
            });

            TitleWidget.byCondition({
                items:[{key:"enabled", op:"=", value:"true"},{key:"enabled", op:"=", value:"true"}],
                page: 0,
                size: 100,
                sort: ["id,desc"]
            }, function (res) {
                vm.titleWidgets = res;
            });
            MonthWidget.byCondition({
                items:[{key:"enabled", op:"=", value:"true"},{key:"enabled", op:"=", value:"true"}],
                page: 0,
                size: 100,
                sort: ["id,desc"]
            }, function (res) {
                vm.monthWidgets = res;
            });
        }

        $scope.getTilte = function () {
            if (vm.step == 1) {
                return vm.productType == 'THING' ? '创建万物模板' : '创建标准模板'
            }
            if (vm.step == 2) {
                return vm.productList[vm.productSeq].id == 'BOOK' ? '创建照片书模板' : $scope.typeReturn() == 'P0' ? '创建单面冲印模板' : $scope.typeReturn() == 'P1' ? '创建双面冲印模板' : $scope.typeReturn() == 'ordinary' ? '创建普通台历模板' : $scope.typeReturn() == 'dynamic' ? '创建动态台历模板' : $scope.typeReturn() == 'TWO' ? '创建2D模型模板' : $scope.typeReturn() == 'THREE' ? '创建3D模型模板' : '创建模板';
            }
        }

        vm.productList = [
            {
                name:'照片书',
                id:'BOOK',
                describe:'支持腰封/护封/封面/封底/内页多部件',
                icon:'content/images/page-icon5.png',
                parts:[
                    {name:'腰封',id:'B4',seq:0,required:false,counter:1},
                    {name:'护封',id:'B3',seq:1,required:false,counter:1},
                    {name:'封面',id:'B1',seq:2,required:false,counter:1},
                    {name:'内页',id:'P',seq:3,required:true,selected:true},
                    {name:'封底',id:'B2',seq:4,required:false,counter:1},
                ]
            },
            {
                name:'冲印',
                id:'PHOTO',
                describe:'支持制作单面/双面冲印模版',
                icon:'content/images/page-icon4.png',
                types:[
                    {name:'单面',id:'P0',seq:0,required:false,counter:1},
                    {name:'双面',id:'P1',seq:0,required:false,counter:2},
                ]
            },
            {
                name:'台历',
                id:'CALENDAR',
                describe:'支持静态台历和动态日期台历',
                icon:'content/images/page-icon3.png',
                pages:[
                    {name:'7张14面',id:'14',seq:0,required:false,counter:14},
                    {name:'13张26面',id:'26',seq:0,required:false,counter:26},
                ],
                types:[
                    {name:'普通台历',id:'ordinary'},
                    {name:'动态台历',id:'dynamic'},
                ]
            },
            {
                name:'万物',
                id:'THING',
                describe:'支持2D模型和3D模型',
                types:[
                    {name:'2D模型',id:'TWO', icon:'content/images/page-icon6.png',},
                    {name:'3D模型',id:'THREE', icon:'content/images/page-icon7.png'},
                    {name:'无模型',id:'NONE', icon:'content/images/page-icon8.png'},
                ],
                parts:[]
            },
        ];
        vm.productSeq=0;
        if(items.productType == 'THING'){
            vm.productSeq=3;
        }
        $scope.productCb = function(index){
            vm.productSeq=index;
            if(vm.productList[vm.productSeq].id == "THING"){
                vm.data.modelType = "TWO";
            }else{
                vm.data.modelType = "NONE";
            }
        }
        $scope.typeCb = function(item){
            if(vm.productList[vm.productSeq].id == 'PHOTO'){
                vm.data.pictureType = item.id;
            }
            if(vm.productList[vm.productSeq].id == 'CALENDAR'){
                vm.data.photoType = item.id
            }
            if(vm.productList[vm.productSeq].id == 'THING'){
                vm.data.modelType = item.id
            }
        }
        $scope.typeReturn = function(){
            if(vm.productList[vm.productSeq].id == 'PHOTO'){
                return vm.data.pictureType
            }
            if(vm.productList[vm.productSeq].id == 'CALENDAR'){
                return vm.data.photoType
            }
            if(vm.productList[vm.productSeq].id == 'THING'){
                return vm.data.modelType
            }
        }
        $scope.deleteWidget = function (type){
            if(!type) return;
            vm[type] = null;
        }
        //显示标题预选、布局预选选择弹窗
        $scope.showWidgetModal = function (type) {
            $uibModal.open({
                templateUrl: "app/entities/page/modal/widget-select.html",
                controller: "WidgetSelectController",
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size: 'md',
                resolve:{
                    items:function (){
                        return {
                            type:type
                        }
                    }
                }
            }).result.then(function(res){
                if(!res) return;
                if(type === "titleWidget"){
                    vm.titleWidget = res;
                }else if(type === "monthWidget"){
                    vm.monthWidget = res;
                }
            })
        }
        vm.isPart = false;
        $scope.addPart = function(){
            vm.part = "";
            vm.isPart = true;
            $timeout(function () {
                $("#partInput").focus();
            })
        }
        $scope.partBlur = function(){
            if(vm.part){
                vm.productList[vm.productSeq].parts.push({
                    seq:vm.productList[vm.productSeq].parts.length || 0,
                    name: vm.part,
                    id:UidService.get(),
                    pageCount:1,
                    page:[{
                        pageEdits:[{
                            pageEditX:'',
                            pageEditY:'',
                            pageEditW:'',
                            pageEditH:''
                        }]
                    }],
                    pageIndex:0,
                    partIndex:0,
                })
            }
            vm.part = "";
            vm.isPart = false;
        }
        $scope.deletePart = function(index){
            vm.productList[vm.productSeq].parts.splice(index,1);
        }
        vm.next = function () {
            if(vm.productList[vm.productSeq].id == 'THING'){
                if(!vm.productList[vm.productSeq].parts || vm.productList[vm.productSeq].parts.length == 0){
                    MessageService.error("请添加部件");
                    return
                }
                vm.productList[vm.productSeq].parts[0].pageIndex = 0;
                vm.productList[vm.productSeq].parts[0].partIndex = 0;
                vm.thingPart = vm.productList[vm.productSeq].parts[0];
            }
            vm.data.addpage = false;
            vm.data.minpage = 1;
            vm.data.maxpage = 1;
            vm.data.addunit = 1;
            vm.step = 2;
        }
        $scope.modelTypeInfo = function () {
            switch (vm.data.modelType)
            {
                case "TWO":
                    return "2D模型";
                    break;
                case "THREE":
                    return "3D模型";
                    break;
                case "NONE":
                    return "自定义模板";
                    break;
                default:
                    return "自定义模板"
            }
        }

        //单位换算
        vm.units = [
            {name:'px', id:'px'},
            {name:'mm', id:'mm'},
            {name:'英寸', id:'in'},
        ]
        vm.catalogId = null;
        $scope.changeCatalog = function () {
            for (var i = 0;i < vm.catalogs.length;i++){
                if(vm.catalogs[i].id === vm.catalogId){
                    vm.data.catalog = angular.copy(vm.catalogs[i]);
                    return;
                }
            }
        }
        $scope.unitConversion = unit;
        function unit(item){
            if(vm.data.unit == 'mm'){
                vm.data.pageSizeW = MmTurnPx(vm.data.pageSizeW).toFixed(2) - 0;
                vm.data.pageSizeH = MmTurnPx(vm.data.pageSizeH).toFixed(2) - 0;
                vm.data.pageBloodT = MmTurnPx(vm.data.pageBloodT).toFixed(2) - 0;
                vm.data.pageBloodB = MmTurnPx(vm.data.pageBloodB).toFixed(2) - 0;
                vm.data.pageBloodL = MmTurnPx(vm.data.pageBloodL).toFixed(2) - 0;
                vm.data.pageBloodR = MmTurnPx(vm.data.pageBloodR).toFixed(2) - 0;
                vm.data.pageEdits.forEach(function (pageEdit) {
                    pageEdit.pageEditX = MmTurnPx(pageEdit.pageEditX).toFixed(2) - 0;
                    pageEdit.pageEditY = MmTurnPx(pageEdit.pageEditY).toFixed(2) - 0;
                    pageEdit.pageEditW = MmTurnPx(pageEdit.pageEditW).toFixed(2) - 0;
                    pageEdit.pageEditH = MmTurnPx(pageEdit.pageEditH).toFixed(2) - 0;
                })
            }
            if (vm.data.unit == 'in'){
                vm.data.pageSizeW = InTurnPx(vm.data.pageSizeW).toFixed(2) - 0;
                vm.data.pageSizeH = InTurnPx(vm.data.pageSizeH).toFixed(2) - 0;
                vm.data.pageBloodT = InTurnPx(vm.data.pageBloodT).toFixed(2) - 0;
                vm.data.pageBloodB = InTurnPx(vm.data.pageBloodB).toFixed(2) - 0;
                vm.data.pageBloodL = InTurnPx(vm.data.pageBloodL).toFixed(2) - 0;
                vm.data.pageBloodR = InTurnPx(vm.data.pageBloodR).toFixed(2) - 0;
                vm.data.pageEdits.forEach(function (pageEdit) {
                    pageEdit.pageEditX = InTurnPx(pageEdit.pageEditX).toFixed(2) - 0;
                    pageEdit.pageEditY = InTurnPx(pageEdit.pageEditY).toFixed(2) - 0;
                    pageEdit.pageEditW = InTurnPx(pageEdit.pageEditW).toFixed(2) - 0;
                    pageEdit.pageEditH = InTurnPx(pageEdit.pageEditH).toFixed(2) - 0;
                })
            }
            if(item == 'mm'){
                vm.data.pageSizeW = Math.round(PxTurnMm(vm.data.pageSizeW) - 0);
                vm.data.pageSizeH = Math.round(PxTurnMm(vm.data.pageSizeH) - 0);
                vm.data.pageBloodT = Math.round(PxTurnMm(vm.data.pageBloodT) - 0);
                vm.data.pageBloodB = Math.round(PxTurnMm(vm.data.pageBloodB) - 0);
                vm.data.pageBloodL = Math.round(PxTurnMm(vm.data.pageBloodL) - 0);
                vm.data.pageBloodR = Math.round(PxTurnMm(vm.data.pageBloodR) - 0);
                vm.data.pageEdits.forEach(function (pageEdit) {
                    pageEdit.pageEditX = Math.round(PxTurnMm(pageEdit.pageEditX) - 0);
                    pageEdit.pageEditY = Math.round(PxTurnMm(pageEdit.pageEditY) - 0);
                    pageEdit.pageEditW = Math.round(PxTurnMm(pageEdit.pageEditW) - 0);
                    pageEdit.pageEditH = Math.round(PxTurnMm(pageEdit.pageEditH) - 0);
                })
            }
            if(item == 'in'){
                vm.data.pageSizeW = PxTurnIn(vm.data.pageSizeW).toFixed(2) - 0;
                vm.data.pageSizeH = PxTurnIn(vm.data.pageSizeH).toFixed(2) - 0;
                vm.data.pageBloodT = PxTurnIn(vm.data.pageBloodT).toFixed(2) - 0;
                vm.data.pageBloodB = PxTurnIn(vm.data.pageBloodB).toFixed(2) - 0;
                vm.data.pageBloodL = PxTurnIn(vm.data.pageBloodL).toFixed(2) - 0;
                vm.data.pageBloodR = PxTurnIn(vm.data.pageBloodR).toFixed(2) - 0;
                vm.data.pageEdits.forEach(function (pageEdit) {
                    pageEdit.pageEditX = PxTurnIn(pageEdit.pageEditX).toFixed(2) - 0;
                    pageEdit.pageEditY = PxTurnIn(pageEdit.pageEditY).toFixed(2) - 0;
                    pageEdit.pageEditW = PxTurnIn(pageEdit.pageEditW).toFixed(2) - 0;
                    pageEdit.pageEditH = PxTurnIn(pageEdit.pageEditH).toFixed(2) - 0;
                })
            }
            vm.data.unit = item;
            vm.data.unit1 = item;
        }
        $scope.unitConversion1 = unit1;
        function unit1(item){
            vm.productList[vm.productSeq].parts.forEach(function (part) {
                for(var i=0; i<part.page.length; i++){
                    var _data = part.page[i];
                    if(vm.data.unit == 'mm'){
                        _data.pageSizeW = MmTurnPx(_data.pageSizeW).toFixed(2) - 0;
                        _data.pageSizeH = MmTurnPx(_data.pageSizeH).toFixed(2) - 0;
                        _data.pageBloodT = MmTurnPx(_data.pageBloodT).toFixed(2) - 0;
                        _data.pageBloodB = MmTurnPx(_data.pageBloodB).toFixed(2) - 0;
                        _data.pageBloodL = MmTurnPx(_data.pageBloodL).toFixed(2) - 0;
                        _data.pageBloodR = MmTurnPx(_data.pageBloodR).toFixed(2) - 0;
                        _data.pageEdits.forEach(function (pageEdit) {
                            pageEdit.pageEditX = MmTurnPx(pageEdit.pageEditX).toFixed(2) - 0;
                            pageEdit.pageEditY = MmTurnPx(pageEdit.pageEditY).toFixed(2) - 0;
                            pageEdit.pageEditW = MmTurnPx(pageEdit.pageEditW).toFixed(2) - 0;
                            pageEdit.pageEditH = MmTurnPx(pageEdit.pageEditH).toFixed(2) - 0;
                        })
                    }
                    if (vm.data.unit == 'in'){
                        _data.pageSizeW = InTurnPx(_data.pageSizeW).toFixed(2) - 0;
                        _data.pageSizeH = InTurnPx(_data.pageSizeH).toFixed(2) - 0;
                        _data.pageBloodT = InTurnPx(_data.pageBloodT).toFixed(2) - 0;
                        _data.pageBloodB = InTurnPx(_data.pageBloodB).toFixed(2) - 0;
                        _data.pageBloodL = InTurnPx(_data.pageBloodL).toFixed(2) - 0;
                        _data.pageBloodR = InTurnPx(_data.pageBloodR).toFixed(2) - 0;
                        _data.pageEdits.forEach(function (pageEdit) {
                            pageEdit.pageEditX = InTurnPx(pageEdit.pageEditX).toFixed(2) - 0;
                            pageEdit.pageEditY = InTurnPx(pageEdit.pageEditY).toFixed(2) - 0;
                            pageEdit.pageEditW = InTurnPx(pageEdit.pageEditW).toFixed(2) - 0;
                            pageEdit.pageEditH = InTurnPx(pageEdit.pageEditH).toFixed(2) - 0;
                        })
                    }
                    if(item == 'mm'){
                        _data.pageSizeW = Math.round(PxTurnMm(_data.pageSizeW) - 0);
                        _data.pageSizeH = Math.round(PxTurnMm(_data.pageSizeH) - 0);
                        _data.pageBloodT = Math.round(PxTurnMm(_data.pageBloodT) - 0);
                        _data.pageBloodB = Math.round(PxTurnMm(_data.pageBloodB) - 0);
                        _data.pageBloodL = Math.round(PxTurnMm(_data.pageBloodL) - 0);
                        _data.pageBloodR = Math.round(PxTurnMm(_data.pageBloodR) - 0);
                        _data.pageEdits.forEach(function (pageEdit) {
                            pageEdit.pageEditX = Math.round(PxTurnMm(pageEdit.pageEditX) - 0);
                            pageEdit.pageEditY = Math.round(PxTurnMm(pageEdit.pageEditY) - 0);
                            pageEdit.pageEditW = Math.round(PxTurnMm(pageEdit.pageEditW) - 0);
                            pageEdit.pageEditH = Math.round(PxTurnMm(pageEdit.pageEditH) - 0);
                        })
                    }
                    if(item == 'in'){
                        _data.pageSizeW = PxTurnIn(_data.pageSizeW).toFixed(2) - 0;
                        _data.pageSizeH = PxTurnIn(_data.pageSizeH).toFixed(2) - 0;
                        _data.pageBloodT = PxTurnIn(_data.pageBloodT).toFixed(2) - 0;
                        _data.pageBloodB = PxTurnIn(_data.pageBloodB).toFixed(2) - 0;
                        _data.pageBloodL = PxTurnIn(_data.pageBloodL).toFixed(2) - 0;
                        _data.pageBloodR = PxTurnIn(_data.pageBloodR).toFixed(2) - 0;
                        _data.pageEdits.forEach(function (pageEdit) {
                            pageEdit.pageEditX = PxTurnIn(pageEdit.pageEditX).toFixed(2) - 0;
                            pageEdit.pageEditY = PxTurnIn(pageEdit.pageEditY).toFixed(2) - 0;
                            pageEdit.pageEditW = PxTurnIn(pageEdit.pageEditW).toFixed(2) - 0;
                            pageEdit.pageEditH = PxTurnIn(pageEdit.pageEditH).toFixed(2) - 0;
                        })
                    }
                }
            })
            vm.data.unit = item;
            vm.data.unit1 = item;
        }
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[0];
            return arrDPI;
        };

        $scope.addPageEdit = function (pageEdits) {
            pageEdits.push({
                pageEditX:'',
                pageEditY:'',
                pageEditW:'',
                pageEditH:''
            })
        }
        $scope.removePageEdit = function(index,pageEdits){
            pageEdits.splice(index, 1);
        };
        vm.replaces = [
            {id: true, value: '可替换'},
            {id: false, value: '不可替换'}
        ]
        vm.addpages = [
            {id: true, value: '可加页'},
            {id: false, value: '不可加页'}
        ];
        vm.isMonth = false;
        vm.dateformat = 'yyyy/MM';
        $scope.options = {minMode:'month'};

        //预览图
        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }
        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'template-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.data.cover = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        $scope.removeFile = function () {
            vm.data.cover = "";
        }
        $scope.thingPartCb = function(index){
            vm.thingPart = vm.productList[vm.productSeq].parts[index];
            vm.thingPart.pageIndex = 0;
            vm.thingPart.partIndex = index;
        }
        vm.reuse = function(){
            var _page = angular.copy(vm.productList[vm.productSeq].parts[vm.thingPart.partIndex].page[vm.thingPart.pageIndex]);
            vm.productList[vm.productSeq].parts.forEach(function (part) {
                part.page.forEach(function (page) {
                    page.pageSizeH = angular.copy(_page.pageSizeH);
                    page.pageSizeW = angular.copy(_page.pageSizeW);
                    page.pageBloodT = angular.copy(_page.pageBloodT);
                    page.pageBloodB = angular.copy(_page.pageBloodB);
                    page.pageBloodL = angular.copy(_page.pageBloodL);
                    page.pageBloodR = angular.copy(_page.pageBloodR);
                    page.pageEdits = angular.copy(_page.pageEdits);
                    page.mould = angular.copy(_page.mould);
                })
            })
        }
        //关联模型
        vm.selectModel = selectModel;
        function selectModel(data){
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'add',
                            data: data,
                            modelType:'TWO'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    res.modelPic = vm.url + res.modelPic;
                    data.mould = res;
                }
            });
        }
        //编辑模型
        vm.selectModelEdit = selectModelEdit;
        function selectModelEdit(data){
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'edit',
                            data: data,
                            modelType:'TWO'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    res.modelPic = vm.url + res.modelPic;
                    data.mould = res;
                }
            });
        }
        //3D模型
        vm.modelPathTab = modelPathTab;
        vm.modelPathTabEdit = modelPathTabEdit;
        function modelPathTab() {
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'add',
                            data: vm.data,
                            modelType:'THREE'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    vm.data.modelName= res.name;
                    vm.data.modelPic= res.modelPic;
                    vm.data.modelData= res.modelData;
                    vm.data.modelPath= res.modelPath;
                    vm.data.mould= res;
                    modelDataInfo(vm.data.modelData);
                }
            });
        }
        function modelPathTabEdit() {
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'edit',
                            data: vm.data,
                            modelType:'THREE'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    vm.data.modelName= res.name;
                    vm.data.modelPic= res.modelPic;
                    vm.data.modelData= res.modelData;
                    vm.data.modelPath= res.modelPath;
                    vm.data.mould= res;
                    modelDataInfo(vm.data.modelData);
                }
            });
        }
        function modelDataInfo(item) {
            vm.modelData = JSON.parse(item);
        }
        //配色管理
        $scope.modelBlendentAdd = function () {
            if(!vm.data.modelPath) return MessageService.error("请先关联模型");
            if(!vm.data.modelBlendent){
                vm.data.modelBlendent = [];
            }
            vm.data.modelBlendent.push({
                name:'',
                modelData:angular.copy(vm.modelData),
                uuid:getUuid(16, 16),
                cancelled: false,
            })
        }
        $scope.modelBlendentSet = function (data) {
            vm.modelData.forEach(function (modelData2){
                data.modelData.forEach(function (modelData1){
                    if(modelData1.materialName == modelData2.materialName){
                        modelData1.page = modelData2.page;
                        if(modelData1.page>0 || modelData1.page === 0){
                            modelData1.color = null;
                        }
                    }
                })
            })
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/blendent-modal.html',
                controller: 'BlendentModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            modelData: data.modelData,
                            modelPath: vm.data.modelPath
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    data.modelData = res;
                }
            });
        }
        $scope.removeModelBlendent = function (index) {
            vm.data.modelBlendent.splice(index, 1)
        }
        vm.cancel = function () {
            $uibModalInstance.close();
        }
        vm.save = function () {
            var _data = angular.copy(vm.data);
            if(!_data.name){
                MessageService.error("模板名称不能为空");
                return
            }
            if(_data.addpage){
                if(!_data.minpage || !_data.maxpage){
                    MessageService.error("页数范围必须大于0");
                    return
                }
                if(_data.minpage > _data.maxpage){
                    MessageService.error("结束页数不能小于开始页数，请重新设置页数范围");
                    return
                }
                if(!_data.addunit){
                    MessageService.error("加页单位必须大于0");
                    return
                }
            }
            $scope.template.pages.page=[];
            $scope.template.config.parts.list=[];
            var _seq = 0;
            if(vm.productList[vm.productSeq].id == 'BOOK' || vm.productList[vm.productSeq].id == 'CALENDAR' || vm.productList[vm.productSeq].id == 'PHOTO'){
                if(vm.productList[vm.productSeq].id == 'CALENDAR' && _data.photoType == 'dynamic'){
                    _data.month = $("#startTime").val();
                    if(!_data.month){
                        MessageService.error("开始月份不能为空");
                        return;
                    }
                }
                if (!_data.pageSizeW || !_data.pageSizeH) {
                    return MessageService.error("请填写画布尺寸");
                }
                if(!checkFloatNum(_data.pageSizeW) || !checkFloatNum(_data.pageSizeH)){
                    MessageService.error("请填写正确的画布尺寸");
                    return
                }
                if(!_data.catalog){
                    MessageService.error("请选择模板分类");
                    return
                }
                if (!_data.pageBloodT || !_data.pageBloodB || !_data.pageBloodL || !_data.pageBloodR) {
                    MessageService.error("请填写出血尺寸");
                    return
                }
                if(!checkFloatNum(_data.pageBloodT) || !checkFloatNum(_data.pageBloodB) || !checkFloatNum(_data.pageBloodL) || !checkFloatNum(_data.pageBloodR)){
                    MessageService.error("请填写正确的出血尺寸");
                    return
                }
                // for(var i=0; i<_data.pageEdits.length; i++){
                //     var pageEdit = _data.pageEdits[i];
                //     if(!checkFloatNum(pageEdit.pageEditX) || !checkFloatNum(pageEdit.pageEditY) || !checkFloatNum(pageEdit.pageEditW) || !checkFloatNum(pageEdit.pageEditH)){
                //         MessageService.error("请填写正确的编辑区域");
                //         return false
                //     }
                // }
                if(vm.productList[vm.productSeq].id == 'BOOK' || (vm.productList[vm.productSeq].id == 'CALENDAR' && _data.photoType == 'ordinary')){
                    if(_data.replace === "" || _data.replace === null){
                        MessageService.error("请选择模板替换");
                        return false
                    }
                }
                if(vm.productList[vm.productSeq].id == 'BOOK'){
                    if(!_data.pageCount){
                        MessageService.error("内页页数不能为空");
                        return
                    }
                    if(!checkFloatNum(_data.pageCount)){
                        MessageService.error("请填写正确的内页页数");
                        return
                    }
                }
                if(_data.unit1 == 'px'){
                    unit('mm');
                }
                var editSize = [];
                _data.pageEdits.forEach(function (editbox) {
                    editSize.push({
                        height: editbox.pageEditH,
                        width: editbox.pageEditW,
                        x: editbox.pageEditX,
                        y: editbox.pageEditY
                    })
                });
                var _page1 = createPage(_data, editSize);
                if(vm.productList[vm.productSeq].id == 'BOOK'){
                    vm.productList[vm.productSeq].parts.forEach(function (part) {
                        if(part.selected){
                            $scope.template.config.parts.list.push({
                                "bleed":{
                                    "bottom":_data.pageBloodB,
                                    "left":_data.pageBloodL,
                                    "right":_data.pageBloodR,
                                    "top":_data.pageBloodT
                                },
                                "editboxes":_page1.editboxes,
                                "mediabox":_page1.mediabox,
                                "counter":part.id == 'P' ? _data.pageCount : part.counter,
                                "id":part.id,
                                "name":part.name,
                                "required":part.required,
                                "seq":part.seq,
                                "spread":_data.spread
                            })
                            if(part.id == 'P'){
                                for(var i=0; i<_data.pageCount; i++){
                                    _page1.seq = _seq;
                                    _page1.uuid= UidService.get();
                                    _page1.config={
                                        "dynamic":false,
                                        "monthIndex":0,
                                        "monthMultiple":false,
                                        "partid":part.id
                                    }
                                    $scope.template.pages.page.push(angular.copy(_page1));
                                    _seq++
                                }
                            }else{
                                _page1.seq = _seq;
                                _page1.uuid= UidService.get();
                                _page1.config={
                                    "dynamic":false,
                                    "monthIndex":0,
                                    "monthMultiple":false,
                                    "partid":part.id
                                }
                                $scope.template.pages.page.push(angular.copy(_page1));
                                _seq++
                            }
                        }
                    })
                }
                if(vm.productList[vm.productSeq].id == 'PHOTO'){
                    $scope.template.config.parts.list.push({
                        "bleed":{
                            "bottom":_data.pageBloodB,
                            "left":_data.pageBloodL,
                            "right":_data.pageBloodR,
                            "top":_data.pageBloodT
                        },
                        "editboxes":_page1.editboxes,
                        "mediabox":_page1.mediabox,
                        "counter":1,
                        "id":"P0",
                        "name":"正面",
                        "required":true,
                        "seq":0
                    })
                    _page1.seq = _seq;
                    _page1.uuid= UidService.get();
                    _page1.config={
                        "dynamic":false,
                        "monthIndex":0,
                        "monthMultiple":false,
                        "partid":"P0"
                    }
                    $scope.template.pages.page.push(angular.copy(_page1));
                    _seq++
                    if($scope.typeReturn() == "P1"){
                        $scope.template.config.parts.list.push({
                            "bleed":{
                                "bottom":_data.pageBloodB,
                                "left":_data.pageBloodL,
                                "right":_data.pageBloodR,
                                "top":_data.pageBloodT
                            },
                            "editboxes":_page1.editboxes,
                            "mediabox":_page1.mediabox,
                            "counter":1,
                            "id":"P1",
                            "name":"反面",
                            "required":false,
                            "seq":1
                        })
                        _page1.seq = _seq;
                        _page1.uuid= UidService.get();
                        _page1.config={
                            "dynamic":false,
                            "monthIndex":0,
                            "monthMultiple":false,
                            "partid":"P1"
                        }
                        $scope.template.pages.page.push(angular.copy(_page1));
                        _seq++
                    }
                }
                if(vm.productList[vm.productSeq].id == 'CALENDAR'){
                    var _list = {
                        "bleed":{
                            "bottom":_data.pageBloodB,
                            "left":_data.pageBloodL,
                            "right":_data.pageBloodR,
                            "top":_data.pageBloodT
                        },
                        "editboxes":_page1.editboxes,
                        "mediabox":_page1.mediabox,
                        "counter":1,
                        "id":"B1",
                        "name":"封面",
                        "required":false,
                        "seq":0
                    }
                    $scope.template.config.parts.list.push(angular.copy(_list));
                    _page1.seq = _seq;
                    _page1.uuid= UidService.get();
                    _page1.config={
                        "dynamic":false,
                        "monthIndex":0,
                        "monthMultiple":false,
                        "partid":"B1"
                    }
                    $scope.template.pages.page.push(angular.copy(_page1));
                    _seq++;
                    if(_data.photoPage == "14"){
                        _list.name = "内页";
                        _list.counter=_data.photoPage-1;
                        _list.id = "P";
                        _list.seq = 1;
                        _list.required = true;
                        $scope.template.config.parts.list.push(angular.copy(_list));
                        for(var i=0; i<(_data.photoPage-1); i++){
                            var __page1 = angular.copy(_page1);
                            __page1.seq = _seq;
                            __page1.uuid= UidService.get();
                            __page1.config={
                                "dynamic":$scope.typeReturn() == 'dynamic' ? true : false,
                                "monthIndex":i,
                                "monthMultiple":false,
                                "partid":"P"
                            }
                            if(__page1.config.monthIndex>0&&$scope.typeReturn() == 'dynamic'){
                                __page1.levels.level=[];
                                if(vm.titleWidget){
                                    __page1.levels.level.push(getTitleWidget(1,vm.titleWidget,false,MmTurnPx(vm.data.pageBloodL),MmTurnPx(vm.data.pageBloodT)));
                                }
                                if(vm.monthWidget){
                                    var _y = vm.titleWidget && vm.titleWidget.height ? vm.titleWidget.height : 0;
                                    _y = Number(_y)+MmTurnPx(vm.data.pageBloodT);
                                    __page1.levels.level.push(getMonthWidget(2,vm.monthWidget,false,MmTurnPx(vm.data.pageBloodL),_y));
                                }
                            }
                            $scope.template.pages.page.push(angular.copy(__page1));
                            _seq++
                        }
                    }
                    if(_data.photoPage == "26"){
                        _list.name = "内页";
                        _list.counter=_data.photoPage-1;
                        _list.id = "P";
                        _list.seq = 1;
                        _list.required = true;
                        $scope.template.config.parts.list.push(angular.copy(_list));
                        for(var i=0; i<(_data.photoPage-1); i++){
                            var __page1 = angular.copy(_page1);
                            __page1.seq = _seq;
                            __page1.uuid= UidService.get();
                            __page1.config={
                                "dynamic":($scope.typeReturn() == 'dynamic' && i%2 === 0) ? true : false,
                                "monthIndex":i%2 === 1 ? Math.ceil(i/2) : 0,
                                "monthMultiple":false,
                                "partid":"P"
                            }
                            if(__page1.config.monthIndex>0&&$scope.typeReturn() == 'dynamic'){
                                __page1.levels.level=[];
                                if(vm.titleWidget){
                                    __page1.levels.level.push(getTitleWidget(1,vm.titleWidget,false,MmTurnPx(vm.data.pageBloodL),MmTurnPx(vm.data.pageBloodT)));
                                }
                                if(vm.monthWidget){
                                    var _y = vm.titleWidget && vm.titleWidget.height ? vm.titleWidget.height : 0;
                                    _y = Number(_y)+MmTurnPx(vm.data.pageBloodT);
                                    __page1.levels.level.push(getMonthWidget(2,vm.monthWidget,false,MmTurnPx(vm.data.pageBloodL),_y));
                                }
                            }
                            $scope.template.pages.page.push(angular.copy(__page1));
                            _seq++
                        }
                        // _list.name = "封底";
                        // _list.counter=1;
                        // _list.id = "B2";
                        // _list.seq = 2;
                        // _list.required = false;
                        // $scope.template.config.parts.list.push(angular.copy(_list));
                        // _page1.seq = _seq;
                        // _page1.uuid= UidService.get();
                        // _page1.config={
                        //     "dynamic":false,
                        //     "monthIndex":0,
                        //     "monthMultiple":false,
                        //     "partid":"B2"
                        // }
                        // $scope.template.pages.page.push(angular.copy(_page1));
                        // _seq++;
                    }
                }
            }
            if(vm.productList[vm.productSeq].id == 'THING'){
                if(!_data.cover){
                    MessageService.error("请上传预览图");
                    return
                }
                if(!_data.catalog){
                    MessageService.error("请选择模板分类");
                    return
                }
                if(_data.replace === "" || _data.replace === null){
                    MessageService.error("请选择模板替换");
                    return false
                }
                if(_data.modelType == 'THREE'){
                    if(!_data.modelPath){
                        MessageService.error("请关联模型");
                        return
                    }
                }
                for(var i=0; i<vm.productList[vm.productSeq].parts.length; i++){
                    var _part = vm.productList[vm.productSeq].parts[i];
                    for(var j=0; j<_part.page.length; j++){
                        var _page = _part.page[j];
                        if (!_page.pageSizeW || !_page.pageSizeH) {
                            return MessageService.error(_part.name + "的第" + (j+1) + "页的尺寸不能为空");
                        }
                        if(!checkFloatNum(_page.pageSizeW) || !checkFloatNum(_page.pageSizeH)){
                            MessageService.error(_part.name + "的第" + (j+1) + "页的尺寸必须大于等于0");
                            return false
                        }
                        if (!_page.pageBloodT || !_page.pageBloodB || !_page.pageBloodL || !_page.pageBloodR) {
                            return MessageService.error(_part.name + "的第" + (j+1) + "页的出血尺寸不能为空");
                        }
                        if(!checkFloatNum(_page.pageBloodT) || !checkFloatNum(_page.pageBloodB) || !checkFloatNum(_page.pageBloodL) || !checkFloatNum(_page.pageBloodR)){
                            MessageService.error(_part.name + "的第" + (j+1) + "页的出血尺寸必须大于等于0");
                            return false
                        }
                        // if(!_page.pageEdits || _page.pageEdits.length == 0){
                        //     MessageService.error(_part.name + "的第" + (j+1) + "页的编辑区域不能为空");
                        //     return false
                        // }
                        // for(var k=0; k<_page.pageEdits.length; k++){
                        //     var _pageEdit = _page.pageEdits[k];
                        //     if(!checkFloatNum(_pageEdit.pageEditX) || !checkFloatNum(_pageEdit.pageEditY) || !checkFloatNum(_pageEdit.pageEditW) || !checkFloatNum(_pageEdit.pageEditH)){
                        //         MessageService.error(_part.name + "的第" + (j+1) + "页的编辑区域必须大于等于0");
                        //         return false
                        //     }
                        // }
                        if(_data.modelType == 'TWO' && !_page.mould){
                            MessageService.error(_part.name + "的第" + (j+1) + "页的模型不能为空");
                            return false
                        }
                    }
                }
                if(_data.unit1 == 'px'){
                    unit1('mm');
                }
                vm.productList[vm.productSeq].parts.forEach(function (part,partIndex) {
                    part.page.forEach(function (_page) {
                        var editSize = [];
                        _page.pageEdits.forEach(function (editbox) {
                            editSize.push({
                                height: editbox.pageEditH,
                                width: editbox.pageEditW,
                                x: editbox.pageEditX,
                                y: editbox.pageEditY
                            })
                        });
                        var _page1 = createPage(_page, editSize);
                        if (_page.mould) {
                            _page1.scene = {
                                background: {
                                    resource: {
                                        identifier: _page.mould.modelPic.indexOf('http') > -1 ? _page.mould.modelPic : vm.url + _page.mould.modelPic,
                                        provider: "qiniu"
                                    }
                                },
                                geometry: {
                                    width: _page.mould.gWidth,
                                    height: _page.mould.gHeight,
                                    x: 0,
                                    y: 0
                                },
                                imagebox: {
                                    geometry: {
                                        width: _page.mould.width,
                                        height: _page.mould.height,
                                        x: _page.mould.x,
                                        y: _page.mould.y
                                    },
                                    rotation: {angle: 0},
                                    transparency: 1,
                                },
                                hierarchy: _page.mould.hierarchy,
                                mouldid: _page.mould.id,
                                isScene: true,
                                scale: 1,
                                unit: "mm",
                                resource:{
                                    identifier: _page.mould.modelPic.indexOf('http') > -1 ? _page.mould.modelPic : vm.url + _page.mould.modelPic,
                                    provider: "qiniu"
                                }
                            }
                        }
                        $scope.template.config.parts.list.push({
                            "bleed":{
                                "bottom":_page.pageBloodB,
                                "left":_page.pageBloodL,
                                "right":_page.pageBloodR,
                                "top":_page.pageBloodT
                            },
                            "editboxes":_page1.editboxes,
                            "mediabox":_page1.mediabox,
                            "counter":part.page.length,
                            "id":part.id,
                            "name":part.name,
                            "required":false,
                            "seq":partIndex
                        })
                        _page1.seq = _seq;
                        _page1.uuid= UidService.get();
                        _page1.config={
                            partid:part.id,
                            "dynamic":false,
                            "monthIndex":0,
                            "monthMultiple":false,
                        }
                        $scope.template.pages.page.push(angular.copy(_page1));
                        _seq++
                    })
                })
            }
            vm.hashtags = '';
            if(_data.catalog.hashtags){
                for (var i = 0; i < _data.catalog.hashtags.length; i++) {
                    vm.hashtags += _data.catalog.hashtags[i].name + ','
                }
            }
            $scope.template.config.innerOffset = _data.innerOffset;
            $scope.template.config.productType = vm.productList[vm.productSeq].id;
            $scope.template.config.dynamic = $scope.typeReturn() == 'dynamic' ? true : false;
            if($scope.template.config.dynamic){
                $scope.template.config.startYear = (_data.month.split("-"))[0];
                $scope.template.config.startMonth = (_data.month.split("-"))[1];
            }
            $scope.template.replace = _data.replace;
            $scope.template.addpage = _data.addpage;
            $scope.template.minpage = _data.minpage;
            $scope.template.maxpage = _data.maxpage;
            $scope.template.addunit = _data.addunit;
            ProductXml.save({
                cover: _data.cover,
                document: $scope.template,
                replace: _data.replace,
                name: _data.name,
                catalogId: _data.catalog.id,
                catalogName: _data.catalog.name,
                version: '4.0',
                type: 'XML',
                productTemplateId: '',
                hashtags: vm.hashtags,
                modelBlendent: JSON.stringify(_data.modelBlendent),
                modelData: _data.modelType != "NONE" ? JSON.stringify(vm.modelData) : "",
                modelPath: _data.modelPath,
                modelType: _data.modelType,
                modelName: _data.modelName,
                modelPic: _data.modelPic,
                productType: vm.productList[vm.productSeq].id
            }, function (res) {
                vm.isSaving = false;
                $uibModalInstance.close();
                $state.go('page-my-suffix-edit', {id: res.id,new:1}, {reload: true})
            }, function () {
                vm.isSaving = false;
            });
        }
        $scope.template = {
            "addpage":false,
            "addunit":0,
            "maxpage":0,
            "minpage":0,
            "replace":"",
            "cover":"",
            backupPages:{
                list:[]
            },
            config:{
                innerOffset:0,
                parts:{
                    list:[]
                },
                productType:''
            },
            deletedPages:{
                page:[]
            },
            "frameConfigs":{
                "frameConfig":[]
            },
            pages: {
                page: []
            },
            "version":"4.0",
            transparency: 1,
            scene: null,
            pageI: 0,
            angle: 0,
            backup: false,
            spread: false,
            unreplace: false,
            letterspacing: 0,
            lineheight: 0,
            text: '',
            bgimgs: {bgimg: []},
            bgimgs2: {bgimg: []},
            masks: {mask: []},
            cliparts: {clipart: []},
            documentpics: {documentpic: []},
            showPic: false,
            psdFiles: {psdFile: []}
        };
        function createPage(_page, editSize) {
            var _page1 = {
                uuid: UidService.get(),
                isScene: false,
                bgImg: '',
                background: {resource: {},color:'#ffffff', type: "Color"},
                pagecover: {},
                levels: {
                    level:[]
                },
                barcode: {},
                qrcode: {},
                editbox: {
                        x:0,
                        y:0,
                        width: _page.pageSizeW,
                        height: _page.pageSizeH,
                    },
                editboxes: {
                    editbox: [
                        {
                            x:0,
                            y:0,
                            width: _page.pageSizeW,
                            height: _page.pageSizeH,
                        }
                    ]
                },
                mediabox: {
                    height: _page.pageSizeH,
                    width: _page.pageSizeW,
                },
                trimbox: {
                    height: _page.pageSizeH - Number(_page.pageBloodB) - Number(_page.pageBloodT),
                    width: _page.pageSizeW - Number(_page.pageBloodL) - Number(_page.pageBloodR),
                    x: _page.pageBloodL,
                    y: _page.pageBloodT
                },
                scale: 1,
                proportion: 1,
                seq: 0,
                spread: _page.spread,
                backup: false,
                unreplace: !vm.data.replace
            }
            return _page1;
        }

        $scope.removeMould = function(event){
            fn(event);
            vm.productList[vm.productSeq].parts[vm.thingPart.partIndex].page[vm.thingPart.pageIndex].mould = null;
        }


        $scope.addPageTppltip = $sce.trustAsHtml('<div style="text-align:left;"><span>决定用户使用模板时，内页<br>部件是否可加页；加页功能<br>仅限API商户使用。</span></div>');
        $scope.replaceTppltip = $sce.trustAsHtml('<div style="text-align:left;"><span style="text-align:left;">决定用户使用模板时，内页<br>是否可替换以及排序。</span></div>');
    }
})();

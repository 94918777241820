(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PageMySuffixController', PageMySuffixController)
        .filter('reverse', function () {
            return function (items) {
                if (items) {
                    return items.slice().reverse();
                }
            };
        });

    PageMySuffixController.$inject = ['$state', '$scope', 'Qinius', '$uibModal', '$http', '$q', '$timeout', '$stateParams', 'ImageService', 'MessageService', 'PicLayoutXmls', 'ModulesService',
        'FontManagement', 'ProductXml', 'UidService', 'ClipArts', 'SourceMaterial', 'Backgrounds', 'FontXmls', 'Galleries', 'ThemeClassify', 'Catalog', 'ListService', 'PsdTemplateLoader',
        'ProductTemplate', 'GetSetDpi', 'FrameConfig','ThirdPartySetting','$localStorage','CalendarLayout','TitleWidget','MonthWidget'];

    function PageMySuffixController($state, $scope, Qinius, $uibModal, $http, $q, $timeout, $stateParams, ImageService, MessageService, PicLayoutXmls, ModulesService,
                                    FontManagement, ProductXml, UidService, ClipArts, SourceMaterial, Backgrounds, FontXmls, Galleries, ThemeClassify, Catalog, ListService, PsdTemplateLoader,
                                    ProductTemplate, GetSetDpi, FrameConfig, ThirdPartySetting,$localStorage,CalendarLayout,TitleWidget,MonthWidget) {
        var vm = this;

        vm.selectElementCopy = null;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").css({margin: 0});
        localStorage.removeItem('ctrlKeyArray');
        vm.isEditorTitle = false;
        $scope.thumbnailPic = false;
        vm.thirdparty = $stateParams.identifier ? true : false;
        vm.exitSure = false;
        var arrDPI = ModulesService.getDPI();
        vm.windowDpiW = arrDPI[0];
        vm.windowDpiH = arrDPI[1];
        if(!vm.thirdparty){
            GetSetDpi.getCurrent({}, function (res) {
                vm.gitDpi = res.message;
            });
        }
        vm.getAllCtrlKeyArray = getAllCtrlKeyArray;
        vm.menuImageView = menuImageView;
        vm.pageImageView = pageImageView;
        vm.pageImageViewOrient = pageImageViewOrient;

        var bigSizePics = [];
        vm.uploadType = "";

        // 监听顶部操作栏
        vm.moreDisplay = false;
        vm.moreIcon = false;
        vm.moreDisplayFont = false;
        vm.loadingImg = true;
        $(window).resize(function () {
            if ($('#nav-header').outerWidth() <= 1200) {
                vm.moreIcon = true;
            } else {
                vm.moreIcon = false;
                vm.moreDisplay = false;
                vm.moreDisplayFont = false;
            }
        })


        $scope.moreDisplay = function () {
            vm.moreDisplay = !vm.moreDisplay;
        }

        $scope.moreDisplayFont = function () {
            vm.moreDisplayFont = !vm.moreDisplayFont;
        }

        //键盘操作array
        vm.CtrlKeyArray = {imageboxes: [], textboxes: [], imagearts: [], barcode: [], qrcode: [], textdocuments: [], calMonthBoxs: [], calTitleBoxs: []};
        vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, textdocument: {}, calMonthBox: {}, calTitleBox: {}};
        vm.windowH = window.innerHeight - 90;
        vm.bgimgs2Tab = 'all';
        vm.clipartTab = 'SYSTEM';
        vm.maskTab = 'all';
        vm.materialTab = 'SYSTEM';
        vm.borderTab = 'SYSTEM';
        vm.showClipartlabels = [];
        vm.colorArr1 = ['#ffffff', '#666666', '#7babaf', '#e69594', '#93a9da', '#eac8fb'];
        vm.colorArr2 = ['#000000', '#666666', '#a8a8a8', '#d9d9d9', '#ffffff', '#647c6e', '#ff5b5c', '#ffbd4a', '#fef952', '#99e264', '#36b72a', '#44d9e5', '#c27171', '#2fb3ff', '#b760a6'];

        vm.sizeList = [
            {name: '12', id: '12'},
            {name: '13', id: '13'},
            {name: '14', id: '14'},
            {name: '15', id: '15'},
            {name: '16', id: '16'},
            {name: '17', id: '17'},
            {name: '18', id: '18'},
            {name: '19', id: '19'},
            {name: '20', id: '20'},
            {name: '22', id: '22'},
            {name: '24', id: '24'},
            {name: '26', id: '26'},
            {name: '28', id: '28'},
            {name: '30', id: '30'},
        ]
        vm.colorList = [
            {name: '#000000', id: '#000000'},
            {name: '#666666', id: '#666666'},
            {name: '#a8a8a8', id: '#a8a8a8'},
            {name: '#d9d9d9', id: '#d9d9d9'},
            {name: '#ffffff', id: '#ffffff'},
            {name: '#647c6e', id: '#647c6e'},
            {name: '#ffbd4a', id: '#ffbd4a'},
            {name: '#fef952', id: '#fef952'},
            {name: '#99e264', id: '#99e264'},
            {name: '#36b72a', id: '#36b72a'},
            {name: '#44d9e5', id: '#44d9e5'},
            {name: '#c27171', id: '#c27171'},
            {name: '#2fb3ff', id: '#2fb3ff'},
            {name: '#b760a6', id: '#b760a6'},
        ]
        vm.navList = [
            {icon:'icon-bianjiqi-beijing', name: '背景', id:'page'},
            {icon:'icon-bianjiqi-xiangkuang', name: '相框', id:'mask'},
            {icon:'icon-bianjiqi-biankuang', name: '边框', id:'border'},
            {icon:'icon-bianjiqi-sucai', name: '素材', id:'material'},
            {icon:'icon-bianjiqi-wenben', name: '文字', id:'text',isShow:vm.thirdparty},
            {icon:'icon-bianjiqi-tupian', name: '图片', id:'img',isShow:vm.thirdparty},
            {icon:'icon-bianjiqi-erweima', name: '二维码', id:'barcode'},
            {icon:'icon-rili', name: '标题', id:'widgetTitle',isShow:true},
            {icon:'icon-rili', name: '日历', id:'widgetMonth',isShow:true},
            {icon:'icon-bianjiqi-buju', name: '布局库', id:'layout',isShow:vm.thirdparty},
            {icon:'icon-bianjiqi-beiyongye', name: '备用页', id:'backup'},
            {icon:'icon-bianjiqi-PSDdaoru', name: 'PSD导入', id:'psdimportor',isShow:vm.thirdparty},
            {icon:'icon-bianjiqi-biaozhunmoban', name: '标准模板', id:'productTemplate',isShow:vm.thirdparty},
            {icon:'icon-bianjiqi-huishouzhan', name: '回收站', id:'recycle',isShow:vm.thirdparty},
        ]

        /* ===============================================
         页面
        ================================================= */
        vm.page = {
            pageEdits: [],
            editboxes: [],
            pageTemplates: []
        };

        // 使用 $watch 监听 vm.page.name 的变化
        $scope.$watch(function() {
            return vm.page.name;
        }, function(newName, oldName) {
            if (newName!==undefined) {
                document.title = newName + '-设计页'
            }
        });
        vm.pageStoragePredefined = false;
        vm.units = [
            {name: 'px', id: 'px'},
            {name: 'mm', id: 'mm'},
            {name: '英寸', id: 'in'}
        ];
        vm.unit = 'mm';

        $scope.dWidth = document.documentElement.clientWidth || document.body.clientWidth;
        $scope.dHeight = document.documentElement.clientHeight || document.body.clientHeight;
        // 左边操作栏 显示规则
        $scope.selPanel = 'page';
        // 右侧边栏 显示规则
        $scope.RightPanel = '';

        $scope.template = {
            transparency: 1,
            cover: angular.copy(vm.page.cover),
            scene: null,
            pageI: 0,
            angle: 0,
            backup: false,
            spread: false,
            unreplace: false,
            letterspacing: 0,
            lineheight: 0,
            text: '',
            bgimgs: {bgimg: []},
            bgimgs2: {bgimg: []},
            masks: {mask: []},
            cliparts: {clipart: []},
            frameConfigs: {frameConfig: []},
            documentpics: {documentpic: []},
            pages: {
                page: []
            },
            backupPages: {
                "list": []
            },
            showPic: true,
            psdFiles: {psdFile: []}
        };
        $scope.selectBox = {
            current: 'page'
        }
        vm.count = 0;

        var mb = ModulesService.myBrowser();
        vm.productTemplates = [];
        vm.proTempIndexNum = 0;
        vm.tempCatalogId = 0;
        vm.backupPageSize = 0;


        /* ===============================================
         出血值
         ================================================= */
        var oldSizePx = 0, oldSizeMm = 0;

        function getBloodSize(page, i) {
            if (vm.unit == 'mm') {
                // mm
                page.blood = {
                    bottom: vm.page.pageBloodB,
                    left: vm.page.pageBloodL,
                    right: vm.page.pageBloodR,
                    top: vm.page.pageBloodT
                };
                page.bloodPx = {
                    top: MmTurnPx(vm.page.pageBloodT),
                    right: MmTurnPx(vm.page.pageBloodR),
                    bottom: MmTurnPx(vm.page.pageBloodB),
                    left: MmTurnPx(vm.page.pageBloodL),
                };
            } else if (vm.unit == 'px') {
                // px
                var tab = ThreeHundredDpiToMm(vm.page.pageBloodT, vm.page.pageBloodB);
                var lar = ThreeHundredDpiToMm(vm.page.pageBloodL, vm.page.pageBloodR);
                page.blood = {
                    top: tab.mm1,
                    bottom: tab.mm2,
                    left: lar.mm1,
                    right: lar.mm2,
                }
                page.bloodPx = {
                    top: MmTurnPx(tab.mm1),
                    bottom: MmTurnPx(tab.mm2),
                    left: MmTurnPx(lar.mm1),
                    right: MmTurnPx(lar.mm2),
                }
            } else if (vm.unit == 'in') {
                // in
                var tab = InTurnMm(vm.page.pageBloodT, vm.page.pageBloodB);
                var lar = InTurnMm(vm.page.pageBloodL, vm.page.pageBloodR);
                page.blood = {
                    top: tab.mm1,
                    bottom: tab.mm2,
                    left: lar.mm1,
                    right: lar.mm2,
                }
                page.bloodPx = {
                    top: MmTurnPx(tab.mm1),
                    bottom: MmTurnPx(tab.mm2),
                    left: MmTurnPx(lar.mm1),
                    right: MmTurnPx(lar.mm2),
                }
            }

            page.xayBorder = page.bloodPx.top + page.bloodPx.bottom;
            // page.mediabox.height = page.bloodPx.top + page.bloodPx.bottom + page.trimbox.height;
            // page.mediabox.width = page.bloodPx.left + page.bloodPx.right + page.trimbox.width;
            page.trimbox.height = page.mediabox.height - page.bloodPx.top - page.bloodPx.bottom;
            page.trimbox.width = page.mediabox.width - page.bloodPx.left - page.bloodPx.right;

            var mmL = page.blood.left - oldSizeMm.left;
            var mmT = page.blood.top - oldSizeMm.top;
            if (page.barcode && page.barcode.geometry) {
                page.barcode.geometry.x += MmTurnPx(mmL);
                page.barcode.geometry.y += MmTurnPx(mmT);
            }
            if (page.qrcode && page.qrcode.geometry) {
                page.qrcode.geometry.x += MmTurnPx(mmL);
                page.qrcode.geometry.y += MmTurnPx(mmT);
            }

            angular.forEach(page.levels.level, function (level) {
                if (level.imageart && level.imageart.current) {
                    level.imageart.geometry.x += MmTurnPx(mmL);
                    level.imageart.geometry.y += MmTurnPx(mmT);
                }
                if (level.imagebox && level.imagebox.current) {
                    level.imagebox.geometry.x += MmTurnPx(mmL);
                    level.imagebox.geometry.y += MmTurnPx(mmT);
                }
                if (level.textbox && level.textbox.current) {
                    level.textbox.geometry.x += MmTurnPx(mmL);
                    level.textbox.geometry.y += MmTurnPx(mmT);
                }
            })
            setOriginalWaH(page.trimbox.width, page.trimbox.height, i);
            vm.operation = true;
        }

        /* ===============================================
         文字
         ================================================= */
        vm.fontList = [];

        function fontSizeInit(size) {
            var _size = 101;
            var _size1 = Math.floor(size / 2);
            $scope.fontSizes = [];
            for (var f = 6; f < _size; f++) {
                $scope.fontSizes.push(f);
            }
            if (_size1 > _size) {
                $scope.fontSizes.push(_size1);
            }
            $scope.size = $scope.fontSizes[6];
        }

        vm.indexNum = 0;
        vm.clipartIndexNum = 0;
        vm.maskIndexNum = 0;
        vm.textIndexNum = 0;
        vm.itemsPerPage = 20;
        vm.allCliparts = {clipart: []};
        vm.showMasks = {mask: []};
        vm.picLayoutXmlsList = [];
        vm.picLayoutXmlGroup = [];
        var oldPage = null;


        //得到原始模板框中最大宽高比例
        var $con = $("#pageCenterSize");
        var $contain = $(".content-page-main");
        var originalScale = $con.width() * 0.8 / ($con.height() * 0.8);
        vm.sublinesX=[];
        vm.sublinesY=[];


        if(!vm.thirdparty){
            vm.tokenTime = 360000;
            Qinius.get(function (msg) {
                vm.token = msg.token;
                vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
            });
            $scope.domains = Qinius.getDomain();
            $q.all([$scope.domains.$promise]).then(function () {
                // token过期
                setTimeout(function fn() {
                    Qinius.get(function (msg) {
                        vm.token = msg.token;
                        vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                    });
                    setTimeout(fn, vm.tokenTime);
                }, vm.tokenTime);
                vm.url = $scope.domains.domain;
                vm.bgimgs2 = {bgimg: []};
                // 背景的分类
                getBackgroundLabel();

                // 平台素材label
                ThemeClassify.getAllByCondition2({
                    items: [{key: "type", op: "=", value: 'CLIPART'}, {
                        key: "used",
                        op: "=",
                        value: true
                    }], sort: ["seq,asc"]
                }, function (res) {
                    vm.clipartIndexNum = 0;
                    vm.platformMaterialLabels = angular.copy(res);
                });
                // 设计师素材
                ThemeClassify.getAllByCondition1({
                    items: [{key: "type", op: "=", value: 'CLIPART'}, {
                        key: "used",
                        op: "=",
                        value: true
                    }], sort: ["seq,asc"]
                }, function (data) {
                    vm.designerMaterialLabels = angular.copy(data) || [];
                    vm.designerMaterialLabels.unshift({id: '', name: '全部', type: 'oldMaterials', themeClassifyId: ''})
                })

                vm.systemBorderClass = []
                vm.myBorderClass = []
                $scope.borderLabels = []
                // 平台边框label
                ThemeClassify.getAllByCondition2({
                    items: [{key: "type", op: "=", value: 'BORDER'}, {
                        key: "used",
                        op: "=",
                        value: true
                    }], sort: ["seq,asc"]
                }, function (res) {
                    vm.systemBorderClass = angular.copy(res)
                    $scope.borderLabels = angular.copy(res)
                });
                // 设计师边框
                ThemeClassify.getAllByCondition1({
                    items: [{key: "type", op: "=", value: 'BORDER'}, {
                        key: "used",
                        op: "=",
                        value: true
                    }], sort: ["seq,asc"]
                }, function (data) {
                    vm.myBorderClass = angular.copy(data)
                })

                FontXmls.byCondition({
                    items: [],
                    page: vm.textIndexNum,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"]
                }, function (resp, headers) {
                    vm.totalTextItems = headers('X-Total-Count');
                    vm.fontDoc = angular.copy(resp);
                })

                vm.defaultFont = FontManagement.getDefaultShow();


                //标准模板
                Catalog.getAll3({items: [{key: "usable", op: "=", value: "true"}], sort: ["id,asc"]}, function (res) {
                    vm.catalogs = res;
                    if (vm.catalogs.length) {
                        vm.tempCatalogId = vm.catalogs[0].id;
                        loadProductTemplate(vm.tempCatalogId, 0);
                    }
                });

                vm.windowH = window.innerHeight - 50 - 42 - 110;
                vm.windowW = window.innerWidth - 500;
                vm.windowH1 = 100;
                vm.windowW1 = 100;
                if ($stateParams.id) {
                    var xmlId = $stateParams.id || $stateParams.copy;
                    ProductXml.get({id: xmlId}, function (resp) {
                        if(!resp.document.bgimgs2){resp.document.bgimgs2={}}
                        if(!resp.document.masks){resp.document.masks={}}
                        if(!resp.document.cliparts){resp.document.cliparts={}}
                        if(!resp.document.frameConfigs){resp.document.frameConfigs={}}
                        if(!resp.document.documentpics){resp.document.documentpics={}}
                        getDocumentXml(resp)
                    });
                }
            });
        }else{
            ThirdPartySetting.getByKey({key: $stateParams.key}, function (data) {
                vm.thirds = data;
            }, function () {
                MessageService.error("配置信息 获取失败");
            });
            ProductXml.getThirdData({key: $stateParams.key}, function (res) {
                vm.token = res.token;
                vm.url = res.domain + '/';
                $timeout(function () {
                    ProductXml.getDocumentByKey({
                        key: $stateParams.identifier,
                        domain: res.domain
                    }, function (res1) {
                        if(!res1.bgimgs2){res1.bgimgs2={}}
                        if(!res1.masks){res1.masks={}}
                        if(!res1.cliparts){res1.cliparts={}}
                        if(!res1.frameConfigs){res1.frameConfigs={}}
                        if(!res1.documentpics){res1.documentpics={}}
                        var resp = {
                            document: res1,
                            modelType: $stateParams.modelType,
                            modelData:decodeURIComponent($stateParams.modelData),
                            modelPath: decodeURIComponent($stateParams.modelPath),
                            name: $stateParams.xmlName,
                        }
                        if(res1.xLines && res1.xLines.line){
                            vm.sublinesX = res1.xLines.line
                        }
                        if(res1.yLines && res1.yLines.line){
                            vm.sublinesY = res1.yLines.line
                        }
                        vm.windowH = window.innerHeight - 50 - 42 - 110;
                        vm.windowW = window.innerWidth - 500;
                        vm.windowH1 = 100;
                        vm.windowW1 = 100;

                        vm.page = getPageFromProductXml(resp);
                        preparePageInfo();
                        vm.page.name = resp.name;
                        resp.document.pages.page.forEach(function (page) {
                            if (page.levels && page.levels.level && page.levels.level.length > 0) {
                                page.levels.level.sort(compare('seq', true));
                            }
                        })
                        vm.page.provider = 'qiniu';
                        vm.page.identifier = $stateParams.identifier;

                        vm.page.modelData = resp.modelData;
                        vm.page.modelBlendent = resp.modelBlendent;
                        vm.page.modelPath = resp.modelPath;
                        vm.page.modelType = resp.modelType;
                        // vm.hashtags = resp.hashtags;
                        // if (resp.productTemplateHasModel) {
                        //     vm.hasModel = true;
                        // } else {
                        //     vm.hasModel = false;
                        // }
                        if (resp.document.bgimgs) {
                            $scope.template.bgimgs.bgimg = angular.copy(resp.document.bgimgs.bgimg) || [];
                        }
                        if (resp.document.psdFiles) {
                            $scope.template.psdFiles.psdFile = angular.copy(resp.document.psdFiles.psdFile) || [];
                        }
                        $scope.template.bgimgs2.bgimg = angular.copy(resp.document.bgimgs2.bgimg) || [];
                        $scope.template.masks.mask = angular.copy(resp.document.masks.mask) || [];
                        $scope.template.cliparts.clipart = angular.copy(resp.document.cliparts.clipart) || [];
                        $scope.template.frameConfigs.frameConfig = angular.copy(resp.document.frameConfigs.frameConfig) || [];
                        $scope.template.documentpics.documentpic = angular.copy(resp.document.documentpics.documentpic) || [];
                        $scope.template.piclayouts = {piclayout: []};
                        if (resp.document.piclayouts && resp.document.piclayouts.piclayout) {
                            $scope.template.piclayouts.piclayout = angular.copy(resp.document.piclayouts.piclayout)
                        }

                        $scope.template.deletedPages = angular.copy(resp.document.deletedPages);
                        $scope.template.pages = angular.copy(resp.document.pages);
                        $scope.template.config = angular.copy(resp.document.config);

                        $scope.template.piclayouts.piclayout.some(function (data, i) {
                            data.identifier = data.resource.identifier
                        })
                        angular.forEach($scope.template.bgimgs2.bgimg, function (bgImg, i) {
                            bgImg.isRecommend = true;
                            if(vm.bgimgs2&&vm.bgimgs2.bgimg){
                                angular.forEach(vm.bgimgs2.bgimg, function (b) {
                                    if (b.identifier == bgImg.identifier) {
                                        b.isRecommend = true;
                                        bgImg.id = b.id;
                                    }
                                })
                            }
                        });
                        angular.forEach($scope.template.masks.mask, function (mask, i) {
                            mask.isRecommend = true;
                            angular.forEach(vm.showMasks.mask, function (m) {
                                if (m.identifier == mask.resource.identifier) {
                                    m.isRecommend = true;
                                    mask.id = m.id;
                                }
                            })
                        });
                        angular.forEach($scope.template.cliparts.clipart, function (clipart, i) {
                            clipart.isRecommend = true;
                        });
                        angular.forEach($scope.template.frameConfigs.frameConfig, function (frameConfig, i) {
                            frameConfig.isRecommend = true;
                        });

                        var page0 = resp.document.pages.page[0];
                        if (!page0.editbox) {
                            page0.editbox = page0.trimbox;
                        }
                        angular.forEach(resp.document.pages.page, function (page, i) {
                            var _pageItem = vm.page;
                            if(vm.page.pageTemplates[i]){
                                _pageItem = vm.page.pageTemplates[i];
                            }
                            var mediaboxW = angular.copy(resp.document.pages.page[i].mediabox.width);
                            var newPageScale = _pageItem.pageSizeW / mediaboxW;

                            page.blood = {
                                top: _pageItem.pageBloodT,
                                bottom: _pageItem.pageBloodB,
                                left: _pageItem.pageBloodL,
                                right: _pageItem.pageBloodR,
                            };


                            if (newPageScale != 1) {
                                if (page.background.resource && page.background.resource.identifier) {
                                    page.background.offsetx = page.background.offsetx * newPageScale;
                                    page.background.offsety = page.background.offsety * newPageScale;
                                }
                                if (page.barcode && page.barcode.geometry) {
                                    var barcode = angular.copy(page.barcode.geometry);
                                    page.barcode.geometry = {
                                        width: barcode.width * newPageScale,
                                        height: barcode.height * newPageScale,
                                        x: barcode.x * newPageScale,
                                        y: barcode.y * newPageScale,
                                    }

                                }
                                if (page.qrcode && page.qrcode.geometry) {
                                    var qrcode = angular.copy(page.qrcode.geometry);
                                    page.qrcode.geometry = {
                                        width: qrcode.width * newPageScale,
                                        height: qrcode.height * newPageScale,
                                        x: qrcode.x * newPageScale,
                                        y: qrcode.y * newPageScale,
                                    }

                                }
                                angular.forEach(page.levels.level, function (level, n) {
                                    if (level.imageart && level.imageart.geometry) {
                                        var art = angular.copy(level.imageart);
                                        level.imageart.geometry = {
                                            width: art.geometry.width * newPageScale,
                                            height: art.geometry.height * newPageScale,
                                            x: art.geometry.x * newPageScale,
                                            y: art.geometry.y * newPageScale,
                                        }
                                        level.imageart.offsetx = art.offsetx * newPageScale;
                                        level.imageart.offsety = art.offsety * newPageScale;
                                    }

                                    if (level.imagebox && level.imagebox.geometry) {
                                        var image = angular.copy(level.imagebox);
                                        level.imagebox.transparency = level.imagebox.transparency === null ? 1 : level.imagebox.transparency;
                                        level.imagebox.geometry = {
                                            width: image.geometry.width * newPageScale,
                                            height: image.geometry.height * newPageScale,
                                            x: image.geometry.x * newPageScale,
                                            y: image.geometry.y * newPageScale,
                                        };
                                        if (image.image && image.image.resource && image.image.resource.identifier) {
                                            level.imagebox.image.offsetx = image.image.offsetx * newPageScale;
                                            level.imagebox.image.offsety = image.image.offsety * newPageScale;
                                            level.imagebox.image.resource.width = image.image.resource.width * newPageScale;
                                            level.imagebox.image.resource.height = image.image.resource.height * newPageScale;
                                            level.imagebox.image.width = image.image.width * newPageScale;
                                            level.imagebox.image.height = image.image.height * newPageScale;
                                        }
                                    }
                                    if (level.textbox && level.textbox.geometry) {
                                        var text = angular.copy(level.textbox);
                                        level.textbox.geometry = {
                                            width: text.geometry.width * newPageScale,
                                            height: text.geometry.height * newPageScale,
                                            x: text.geometry.x * newPageScale,
                                            y: text.geometry.y * newPageScale,
                                        }
                                    }

                                    if (level.textdocument && level.textdocument.geometry) {
                                        var textdocument = angular.copy(level.textdocument);
                                        level.textdocument.geometry = {
                                            width: textdocument.geometry.width * newPageScale,
                                            height: textdocument.geometry.height * newPageScale,
                                            x: textdocument.geometry.x * newPageScale,
                                            y: textdocument.geometry.y * newPageScale,
                                        }
                                        var docBackground = level.textdocument.background;
                                        if (docBackground.resource && docBackground.resource.identifier) {
                                            docBackground.offsetx = 0;
                                            docBackground.offsety = 0;
                                        }

                                        angular.forEach(level.textdocument.levels.level, function (lev, l) {
                                            if (lev.imageart && lev.imageart.geometry) {
                                                var docArt = angular.copy(lev.imageart);
                                                lev.imageart.geometry = {
                                                    width: docArt.geometry.width * newPageScale,
                                                    height: docArt.geometry.height * newPageScale,
                                                    x: docArt.geometry.x * newPageScale,
                                                    y: docArt.geometry.y * newPageScale,
                                                }
                                                lev.imageart.offsetx = docArt.offsetx * newPageScale;
                                                lev.imageart.offsety = docArt.offsety * newPageScale;
                                            }
                                            if (lev.textbox && lev.textbox.geometry) {
                                                var docText = angular.copy(lev.textbox);
                                                lev.textbox.geometry = {
                                                    width: docText.geometry.width * newPageScale,
                                                    height: docText.geometry.height * newPageScale,
                                                    x: docText.geometry.x * newPageScale,
                                                    y: docText.geometry.y * newPageScale,
                                                }
                                            }
                                        })
                                    }
                                    if (level.calMonthBox && level.calMonthBox.geometry) {
                                        var calMonthBox = angular.copy(level.calMonthBox);
                                        level.calMonthBox.geometry = {
                                            width: calMonthBox.geometry.width * newPageScale,
                                            height: calMonthBox.geometry.height * newPageScale,
                                            x: calMonthBox.geometry.x * newPageScale,
                                            y: calMonthBox.geometry.y * newPageScale,
                                        }
                                    }
                                    if (level.calTitleBox && level.calTitleBox.geometry) {
                                        var calTitleBox = angular.copy(level.calTitleBox);
                                        level.calTitleBox.geometry = {
                                            width: calTitleBox.geometry.width * newPageScale,
                                            height: calTitleBox.geometry.height * newPageScale,
                                            x: calTitleBox.geometry.x * newPageScale,
                                            y: calTitleBox.geometry.y * newPageScale,
                                        }
                                    }
                                })
                            }
                        })

                        var trimbox = resp.document.pages.page[0].trimbox;

                        pagesToPx(resp.document.pages.page, $scope.template.pages, 'pages');
                        if (resp.document.deletedPages && resp.document.deletedPages.page && resp.document.deletedPages.page.length) {
                            pagesToPx(resp.document.deletedPages.page, $scope.template.deletedPages)
                        }

                        $scope.template.backupPages = angular.copy(resp.document.backupPages);
                        if (resp.document.backupPages && resp.document.backupPages.list && resp.document.backupPages.list.length) {
                            $scope.template.backupPages.page = $scope.template.backupPages.list;
                            pagesToPx(resp.document.backupPages.list, $scope.template.backupPages)
                        }
                        vm.productTemplatePageCount = $scope.template.pages.page.length-1;

                        $scope.template.width = MmTurnPx(trimbox.width);
                        $scope.template.height = MmTurnPx(trimbox.height);
                        $scope.template.pages.page.forEach(function (page) {
                            ratioInfo(page)
                        })
                        $scope.visibleRight = $scope.template.pages.page.length>1 ? true : false;
                        var pages = $scope.template.pages.page;
                        setMargin();
                        $scope.currentPage = pages[0];
                        $scope.lastPage = pages[0];
                        $scope.initializePage = angular.copy(pages[0]);
                        loadAttribute();
                        $scope.currentPage.isScene = $scope.currentPage.scene ? true : false;
                        fontSizeInit($scope.currentPage.editbox.height);
                        getImageboxLen();
                        oldSizePx = angular.copy(pages[0].bloodPx);
                        oldSizeMm = angular.copy(pages[0].blood);
                        oldSizeMm.left = trimbox.x;
                        oldSizeMm.top = trimbox.y;

                        if (vm.isCustom) {
                            vm.tempType = 'customize';
                        } else if (vm.hasModel) {
                            vm.tempType = 'hasModel';
                        } else {
                            vm.tempType = 'noModel';
                            pageValueAccount(resp.document.pages.page);
                        }

                        $scope.template.pageI = 0;
                        $scope.template.levelI = 0;
                        setTimeout(function () {
                            oldPage = angular.copy(pages[0]);
                            currentPageElement();
                            pageListDrag();
                            vm.templateCopy = angular.copy($scope.template);
                            vm.loadingImg = false;
                            partListDrag();
                            measureRulerInit();
                        }, 2000)
                        var tempPageCount = 0;
                        $scope.template.pages.page.forEach(function (page) {
                            if (!page.backup) {
                                tempPageCount++;
                            }
                        })
                        // if (tempPageCount < vm.productTemplatePageCount) {
                        //     var _page = vm.page.pageTemplates[0];
                        //     var pageSize = {
                        //         height: _page.pageSizeH,
                        //         width: _page.pageSizeW
                        //     };
                        //     var editSize = [];
                        //     _page.pageEdits.forEach(function (editbox) {
                        //         editSize.push({
                        //             height: MmTurnPx(editbox.pageEditH),
                        //             width: MmTurnPx(editbox.pageEditW),
                        //             x: MmTurnPx(editbox.pageEditX),
                        //             y: MmTurnPx(editbox.pageEditY)
                        //         })
                        //     });
                        //     for (var i = tempPageCount; i < vm.productTemplatePageCount; i++) {
                        //         $scope.template.pages.page.push(createPage(_page, editSize, i));
                        //     }
                        // }
                        //推荐素材
                        vm.showCliparts = $scope.template.cliparts;
                        vm.materialTab=='USER';
                        //推荐背景
                        vm.showBackgrounds = $scope.template.bgimgs2;
                        //推荐相框
                        vm.showMasks = $scope.template.masks;
                        //推荐边框
                        vm.frameConfigs = $scope.template.frameConfigs.frameConfig;
                        vm.borderTab=='RECOMMEND';
                    })
                },400)
            })
        }

        function getDocumentXml(resp,callback) {
            if(resp.document.xLines && resp.document.xLines.line){
                vm.sublinesX = resp.document.xLines.line
            }
            if(resp.document.yLines && resp.document.yLines.line){
                vm.sublinesY = resp.document.yLines.line
            }
            vm.page = getPageFromProductXml(resp);
            if(!vm.page.id){
                vm.navList[12].isShow = true;
            }
            preparePageInfo();
            vm.page.name = resp.name;
            vm.productTemplatePageCount = resp.pageCount;
            resp.document.pages.page.forEach(function (page) {
                if (page.levels && page.levels.level && page.levels.level.length > 0) {
                    page.levels.level.sort(compare('seq', true));
                }
            })
            vm.page.provider = resp.provider;
            vm.page.identifier = resp.identifier;

            vm.page.productType = resp.productType;
            vm.page.modelData = resp.modelData;
            vm.page.modelBlendent = resp.modelBlendent;
            vm.page.modelPath = resp.modelPath;
            vm.page.modelType = resp.modelType;
            vm.page.modelName = resp.modelName;
            vm.page.modelPic = resp.modelPic;
            vm.hashtags = resp.hashtags;
            if (resp.productTemplateHasModel) {
                vm.hasModel = true;
            } else {
                vm.hasModel = false;
            }
            if (resp.document.bgimgs) {
                $scope.template.bgimgs.bgimg = angular.copy(resp.document.bgimgs.bgimg) || [];
            }
            if (resp.document.psdFiles) {
                $scope.template.psdFiles.psdFile = angular.copy(resp.document.psdFiles.psdFile) || [];
            }
            $scope.template.bgimgs2.bgimg = angular.copy(resp.document.bgimgs2.bgimg) || [];
            $scope.template.masks.mask = angular.copy(resp.document.masks.mask) || [];
            $scope.template.cliparts.clipart = angular.copy(resp.document.cliparts.clipart) || [];
            $scope.template.frameConfigs.frameConfig = angular.copy(resp.document.frameConfigs.frameConfig) || [];
            $scope.template.documentpics.documentpic = angular.copy(resp.document.documentpics.documentpic) || [];
            $scope.template.piclayouts = {piclayout: []};
            if (resp.document.piclayouts && resp.document.piclayouts.piclayout) {
                $scope.template.piclayouts.piclayout = angular.copy(resp.document.piclayouts.piclayout)
            }

            $scope.template.deletedPages = angular.copy(resp.document.deletedPages);
            $scope.template.backupPages = angular.copy(resp.document.backupPages);
            $scope.template.pages = angular.copy(resp.document.pages);
            if(resp.document.config.parts.list){
                resp.document.config.parts.list.forEach(function (part) {
                    if(!part.editboxes){
                        part.editboxes={
                            editbox:[
                                angular.copy(part.mediabox)
                            ]
                        }
                    }
                })
            }
            $scope.template.config = angular.copy(resp.document.config);
            vm.backupPartId = $scope.template.config.parts.list[0].id;

            $scope.template.piclayouts.piclayout.some(function (data, i) {
                data.identifier = data.resource.identifier
            })
            angular.forEach($scope.template.bgimgs2.bgimg, function (bgImg, i) {
                bgImg.isRecommend = true;
                angular.forEach(vm.bgimgs2.bgimg, function (b) {
                    if (b.identifier == bgImg.identifier) {
                        b.isRecommend = true;
                        bgImg.id = b.id;
                    }
                })
            });
            angular.forEach($scope.template.masks.mask, function (mask, i) {
                mask.isRecommend = true;
                angular.forEach(vm.showMasks.mask, function (m) {
                    if (m.identifier == mask.resource.identifier) {
                        m.isRecommend = true;
                        mask.id = m.id;
                    }
                })
            });
            angular.forEach($scope.template.cliparts.clipart, function (clipart, i) {
                clipart.isRecommend = true;
            });
            angular.forEach($scope.template.frameConfigs.frameConfig, function (frameConfig, i) {
                frameConfig.isRecommend = true;
            });

            var page0 = resp.document.pages.page[0];
            if (!page0.editbox) {
                page0.editbox = page0.trimbox;
            }
            angular.forEach(resp.document.pages.page, function (page, i) {
                var _pageItem = vm.page;
                if(vm.page.pageTemplates[i]){
                    _pageItem = vm.page.pageTemplates[i];
                }
                var mediaboxW = angular.copy(resp.document.pages.page[i].mediabox.width);
                var newPageScale = _pageItem.pageSizeW / mediaboxW;

                page.blood = {
                    top: _pageItem.pageBloodT,
                    bottom: _pageItem.pageBloodB,
                    left: _pageItem.pageBloodL,
                    right: _pageItem.pageBloodR,
                };


                if (newPageScale != 1) {
                    if (page.background.resource && page.background.resource.identifier) {
                        page.background.offsetx = page.background.offsetx * newPageScale;
                        page.background.offsety = page.background.offsety * newPageScale;
                    }
                    if (page.barcode && page.barcode.geometry) {
                        var barcode = angular.copy(page.barcode.geometry);
                        page.barcode.geometry = {
                            width: barcode.width * newPageScale,
                            height: barcode.height * newPageScale,
                            x: barcode.x * newPageScale,
                            y: barcode.y * newPageScale,
                        }

                    }
                    if (page.qrcode && page.qrcode.geometry) {
                        var qrcode = angular.copy(page.qrcode.geometry);
                        page.qrcode.geometry = {
                            width: qrcode.width * newPageScale,
                            height: qrcode.height * newPageScale,
                            x: qrcode.x * newPageScale,
                            y: qrcode.y * newPageScale,
                        }

                    }
                    angular.forEach(page.levels.level, function (level, n) {
                        if (level.imageart && level.imageart.geometry) {
                            var art = angular.copy(level.imageart);
                            level.imageart.geometry = {
                                width: art.geometry.width * newPageScale,
                                height: art.geometry.height * newPageScale,
                                x: art.geometry.x * newPageScale,
                                y: art.geometry.y * newPageScale,
                            }
                            level.imageart.offsetx = art.offsetx * newPageScale;
                            level.imageart.offsety = art.offsety * newPageScale;
                        }

                        if (level.imagebox && level.imagebox.geometry) {
                            var image = angular.copy(level.imagebox);
                            level.imagebox.transparency = level.imagebox.transparency === null ? 1 : level.imagebox.transparency;
                            level.imagebox.geometry = {
                                width: image.geometry.width * newPageScale,
                                height: image.geometry.height * newPageScale,
                                x: image.geometry.x * newPageScale,
                                y: image.geometry.y * newPageScale,
                            };
                            if (image.image && image.image.resource && image.image.resource.identifier) {
                                level.imagebox.image.offsetx = image.image.offsetx * newPageScale;
                                level.imagebox.image.offsety = image.image.offsety * newPageScale;
                                level.imagebox.image.resource.width = image.image.resource.width * newPageScale;
                                level.imagebox.image.resource.height = image.image.resource.height * newPageScale;
                                level.imagebox.image.width = image.image.width * newPageScale;
                                level.imagebox.image.height = image.image.height * newPageScale;
                            }
                        }
                        if (level.textbox && level.textbox.geometry) {
                            var text = angular.copy(level.textbox);
                            level.textbox.geometry = {
                                width: text.geometry.width * newPageScale,
                                height: text.geometry.height * newPageScale,
                                x: text.geometry.x * newPageScale,
                                y: text.geometry.y * newPageScale,
                            }
                        }

                        if (level.textdocument && level.textdocument.geometry) {
                            var textdocument = angular.copy(level.textdocument);
                            level.textdocument.geometry = {
                                width: textdocument.geometry.width * newPageScale,
                                height: textdocument.geometry.height * newPageScale,
                                x: textdocument.geometry.x * newPageScale,
                                y: textdocument.geometry.y * newPageScale,
                            }
                            var docBackground = level.textdocument.background;
                            if (docBackground.resource && docBackground.resource.identifier) {
                                docBackground.offsetx = 0;
                                docBackground.offsety = 0;
                            }

                            angular.forEach(level.textdocument.levels.level, function (lev, l) {
                                if (lev.imageart && lev.imageart.geometry) {
                                    var docArt = angular.copy(lev.imageart);
                                    lev.imageart.geometry = {
                                        width: docArt.geometry.width * newPageScale,
                                        height: docArt.geometry.height * newPageScale,
                                        x: docArt.geometry.x * newPageScale,
                                        y: docArt.geometry.y * newPageScale,
                                    }
                                    lev.imageart.offsetx = docArt.offsetx * newPageScale;
                                    lev.imageart.offsety = docArt.offsety * newPageScale;
                                }
                                if (lev.textbox && lev.textbox.geometry) {
                                    var docText = angular.copy(lev.textbox);
                                    lev.textbox.geometry = {
                                        width: docText.geometry.width * newPageScale,
                                        height: docText.geometry.height * newPageScale,
                                        x: docText.geometry.x * newPageScale,
                                        y: docText.geometry.y * newPageScale,
                                    }
                                }
                            })
                        }
                        if (level.calMonthBox && level.calMonthBox.geometry) {
                            var calMonthBox = angular.copy(level.calMonthBox);
                            level.calMonthBox.geometry = {
                                width: calMonthBox.geometry.width * newPageScale,
                                height: calMonthBox.geometry.height * newPageScale,
                                x: calMonthBox.geometry.x * newPageScale,
                                y: calMonthBox.geometry.y * newPageScale,
                            }
                        }
                        if (level.calTitleBox && level.calTitleBox.geometry) {
                            var calTitleBox = angular.copy(level.calTitleBox);
                            level.calTitleBox.geometry = {
                                width: calTitleBox.geometry.width * newPageScale,
                                height: calTitleBox.geometry.height * newPageScale,
                                x: calTitleBox.geometry.x * newPageScale,
                                y: calTitleBox.geometry.y * newPageScale,
                            }
                        }
                    })
                }
            })

            var trimbox = resp.document.pages.page[0].trimbox;

            pagesToPx(resp.document.pages.page, $scope.template.pages, 'pages');
            if (resp.document.deletedPages && resp.document.deletedPages.page && resp.document.deletedPages.page.length) {
                pagesToPx(resp.document.deletedPages.page, $scope.template.deletedPages)
            }
            $scope.template.backupPages = angular.copy(resp.document.backupPages);
            if (resp.document.backupPages && resp.document.backupPages.list && resp.document.backupPages.list.length) {
                $scope.template.backupPages.page = $scope.template.backupPages.list;
                pagesToPx(resp.document.backupPages.list, $scope.template.backupPages)
            }

            $scope.template.width = MmTurnPx(trimbox.width);
            $scope.template.height = MmTurnPx(trimbox.height);
            $scope.template.pages.page.forEach(function (page) {
                ratioInfo(page)
            })
            $scope.visibleRight = $scope.template.pages.page.length>1 ? true : false;
            var pages = $scope.template.pages.page;
            setMargin();
            $scope.currentPage = pages[0];
            $scope.lastPage = pages[0];
            $scope.initializePage = angular.copy(pages[0]);
            loadAttribute();
            $scope.currentPage.isScene = $scope.currentPage.scene ? true : false;
            fontSizeInit($scope.currentPage.editbox.height);
            getImageboxLen();
            oldSizePx = angular.copy(pages[0].bloodPx);
            oldSizeMm = angular.copy(pages[0].blood);
            oldSizeMm.left = trimbox.x;
            oldSizeMm.top = trimbox.y;

            if (vm.isCustom) {
                vm.tempType = 'customize';
            } else if (vm.hasModel) {
                vm.tempType = 'hasModel';
            } else {
                vm.tempType = 'noModel';
                pageValueAccount(resp.document.pages.page);
            }

            $scope.template.pageI = 0;
            $scope.template.levelI = 0;
            setTimeout(function () {
                pageCoverInit(function () {
                    oldPage = angular.copy(pages[0]);
                    currentPageElement();
                    pageListDrag();
                    vm.templateCopy = angular.copy($scope.template);
                    partListDrag();
                    measureRulerInit();
                    vm.loadingImg = false;
                    if(callback){callback()}
                });
            }, 2000)
            var tempPageCount = 0;
            $scope.template.pages.page.forEach(function (page) {
                if (!page.backup) {
                    tempPageCount++;
                }
            })
            // if (tempPageCount < vm.productTemplatePageCount) {
            //     var _page = vm.page.pageTemplates[0];
            //     var pageSize = {
            //         height: _page.pageSizeH,
            //         width: _page.pageSizeW
            //     };
            //     var editSize = [];
            //     _page.pageEdits.forEach(function (editbox) {
            //         editSize.push({
            //             height: MmTurnPx(editbox.pageEditH),
            //             width: MmTurnPx(editbox.pageEditW),
            //             x: MmTurnPx(editbox.pageEditX),
            //             y: MmTurnPx(editbox.pageEditY)
            //         })
            //     });
            //     for (var i = tempPageCount; i < vm.productTemplatePageCount; i++) {
            //         $scope.template.pages.page.push(createPage(_page, editSize, i));
            //     }
            // }
        }
        function pageCoverInit(callback) {
            var _pageIndex = -1;
            for(var i=0; i<$scope.template.pages.page.length; i++){
                var page = $scope.template.pages.page[i];
                if(page.levels && page.levels.level && (!page.pagecover || !page.pagecover.identifier)){
                    page.levels.level.forEach(function (level) {
                        if(level.calMonthBox || level.calTitleBox){
                            _pageIndex = i;
                        }
                    })
                }
                if(_pageIndex > -1){
                    break
                }
            }
            if(_pageIndex>-1){
                $scope.thisThumbnailUpdate(_pageIndex,function () {
                    if($scope.template.pages.page[_pageIndex]&&$scope.template.pages.page[_pageIndex].pagecover&&$scope.template.pages.page[_pageIndex].pagecover.identifier){
                        var _pageCover = $scope.template.pages.page[_pageIndex].pagecover.identifier;
                        $scope.template.pages.page.forEach(function (page) {
                            var isUpdate = false;
                            if(page.levels && page.levels.level && (!page.pagecover || !page.pagecover.identifier)){
                                page.levels.level.forEach(function (level) {
                                    if(level.calMonthBox || level.calTitleBox){
                                        isUpdate = true;
                                    }
                                })
                            }
                            if(isUpdate){
                                page.updateThumbnail = true;
                                page.pagecover={
                                    identifier:_pageCover,
                                    provider:'qiniu'
                                }
                            }
                        })
                        callback()
                    }else{
                        callback()
                    }
                })
            }else{
                callback()
            }
        }

        function getPageFromProductXml(res) {
            var item = {
                id: res.productTemplateId,
                name: res.name,
                seq: 1,
                modelPath: res.modelPath,
                modelType: res.modelType,
                pageSizeH: '',
                pageSizeW: '',
                pageBloodT: '',
                pageBloodB: '',
                pageBloodL: '',
                pageBloodR: '',
                pageEdits: [],
                pageEditUnit: 'mm',
                pageSizeUnit: 'mm',
                pageBloodUnit: 'mm',
                pageEditUnit1: 'mm',
                pageSizeUnit1: 'mm',
                pageBloodUnit1: 'mm',
                cover: res.cover,
                status: true,
                pageCount: res.pageCount,
                catalog: {
                    id: res.catalogId,
                    name: res.catalogName
                },
                reuse: true,
                bindingType: res.bindingType,
                pattern: res.pattern,
                replace: res.replace,
                pageTemplates: [],
                addpage: res.document.addpage,
                minpage: res.document.minpage,
                maxpage: res.document.maxpage,
                addunit: res.document.addunit
            };
            var _page = res.document.pages.page;
            for(var i=0; i<_page.length; i++){
                var _data = {
                    seq:_page[i].seq,
                    pageSizeH:_page[i].mediabox.height,
                    pageSizeW:_page[i].mediabox.width,
                    pageBloodT:_page[i].trimbox.y,
                    pageBloodB:_page[i].mediabox.height - _page[i].trimbox.height - _page[i].trimbox.y,
                    pageBloodL:_page[i].trimbox.x,
                    pageBloodR:_page[i].mediabox.width - _page[i].trimbox.width - _page[i].trimbox.x,
                    pageEdits:[],
                    pageEditUnit: 'mm',
                    pageSizeUnit: 'mm',
                    pageBloodUnit: 'mm',
                    pageEditUnit1: 'mm',
                    pageSizeUnit1: 'mm',
                    pageBloodUnit1: 'mm',
                    cancelled:false,
                    productTemplateId:_page[i].productTemplateId,
                    mould:null,
                };
                if(_page[i].editboxes && _page[i].editboxes.editbox){
                    _page[i].editboxes.editbox.forEach(function (editbox) {
                        _data.pageEdits.push({
                            pageEditX:editbox.x,
                            pageEditY:editbox.y,
                            pageEditW:editbox.width,
                            pageEditH:editbox.height,
                        })
                    })
                }
                if(_page[i].scene){
                    _data.mould = {
                        cancelled: false,
                        gHeight: _page[i].scene.geometry.height,
                        gWidth: _page[i].scene.geometry.width,
                        height: _page[i].scene.imagebox.geometry.height,
                        hierarchy: _page[i].scene.hierarchy,
                        modelPic:  _page[i].scene.resource.identifier,
                        number: _page[i].scene.number,
                        sizeUnit: _page[i].scene.unit,
                        width: _page[i].scene.imagebox.geometry.width,
                        x: _page[i].scene.imagebox.geometry.x,
                        y: _page[i].scene.imagebox.geometry.y
                    }
                }
                item.pageTemplates.push(_data)
            }
            item.pageSizeH = item.pageTemplates[0].pageSizeH;
            item.pageSizeW = item.pageTemplates[0].pageSizeW;
            item.pageBloodT = item.pageTemplates[0].pageBloodT;
            item.pageBloodB = item.pageTemplates[0].pageBloodB;
            item.pageBloodL = item.pageTemplates[0].pageBloodL;
            item.pageBloodR = item.pageTemplates[0].pageBloodR;
            item.pageEdits = item.pageTemplates[0].pageEdits;

            return item;
        }

        function preparePageInfo() {
            vm.page.editboxs = [];
            vm.page.pageEdits.forEach(function (pageEdit) {
                vm.page.editboxs.push({
                    width: pageEdit.pageEditW,
                    height: pageEdit.pageEditH,
                    x: pageEdit.pageEditX,
                    y: pageEdit.pageEditY
                })
            });
            vm.page.pageSize = {
                width: vm.page.pageSizeW,
                height: vm.page.pageSizeH
            };
            vm.productTemplatePageCount = vm.page.pageCount;

            // 判断是否是标准模板
            if (vm.page.id) {
                vm.isCustom = false;
            } else {
                vm.isCustom = true;
            }
            if (!vm.page.pageTemplates || !vm.page.pageTemplates[0] || !vm.page.pageTemplates[0].mould) {
                vm.isMould = false;
            } else {
                vm.isMould = true;
            }
            // 判断标准模板是否选择模型
            if (vm.page.hasModel) {
                vm.hasModel = true;
            } else {
                vm.hasModel = false;
            }
        }

        function createPage(_page, editSize, i) {
            var _page1 = {
                uuid: UidService.get(),
                isScene: false,
                bgImg: '',
                background: {resource: {},color:'#ffffff', type: "Color"},
                pagecover: {},
                levels: {},
                barcode: {},
                qrcode: {},
                editbox: editSize[0],
                editboxes: {
                    editbox: editSize
                },
                mediabox: {
                    height: MmTurnPx(_page.pageSizeH),
                    width: MmTurnPx(_page.pageSizeW),
                },
                trimbox: {
                    height: MmTurnPx(_page.pageSizeH) - Number(MmTurnPx(_page.pageBloodB)) - Number(MmTurnPx(_page.pageBloodT)),
                    width: MmTurnPx(_page.pageSizeW) - Number(MmTurnPx(_page.pageBloodL)) - Number(MmTurnPx(_page.pageBloodR)),
                    x: MmTurnPx(_page.pageBloodL),
                    y: MmTurnPx(_page.pageBloodT)
                },
                blood: {
                    top: _page.pageBloodT,
                    bottom: _page.pageBloodB,
                    left: _page.pageBloodL,
                    right: _page.pageBloodR,
                },
                bloodPx: {
                    top: MmTurnPx(_page.pageBloodT),
                    bottom: MmTurnPx(_page.pageBloodB),
                    left: MmTurnPx(_page.pageBloodL),
                    right: MmTurnPx(_page.pageBloodR),
                },
                scale: 1,
                proportion: 1,
                seq: i + 1,
                spread: false,
                backup: false,
                unreplace: !vm.page.replace
            }
            return _page1;
        }

        function ratioInfo(page) {
            vm.windowWidth = 400;
            vm.windowHeight = 400;
            if (!page.isScene) {
                var ratio = page.mediabox.width / page.mediabox.height;
                var ratio1 = vm.windowWidth / vm.windowHeight;
                if (ratio >= ratio1) {
                    page.ratio = vm.windowWidth / page.mediabox.width;
                } else {
                    page.ratio = vm.windowHeight / page.mediabox.height;
                }
            } else {
                var ratio = page.scene.geometry.width / page.scene.geometry.height;
                var ratio1 = vm.windowWidth / vm.windowHeight;
                if (ratio >= ratio1) {
                    page.ratio = vm.windowWidth / page.scene.geometry.width;
                } else {
                    page.ratio = vm.windowHeight / page.scene.geometry.height;
                }
            }
        }

        function pageValueAccount(pages) {
            var list = [];
            if (pages) {
                for (var i = 0; i < pages.length; i++) {
                    var _page = pages[i];
                    var arr = {};
                    arr.id = i;
                    arr.value = '宽：' + _page.mediabox.width + 'mm 高：' + _page.mediabox.height + 'mm';
                    arr.mediabox = {
                        height: _page.mediabox.height,
                        width: _page.mediabox.width,
                        // name:'画布尺寸'
                    };
                    arr.editbox = {
                        height: _page.editbox.height,
                        width: _page.editbox.width,
                        x: _page.editbox.x,
                        y: _page.editbox.y,
                        // name:'编辑区域'
                    }
                    arr.blood = {
                        top: _page.trimbox.y,
                        bottom: _page.mediabox.height - _page.trimbox.y - _page.trimbox.height,
                        left: _page.trimbox.x,
                        right: _page.mediabox.width - _page.trimbox.x - _page.trimbox.width,
                        // name:'出血尺寸'
                    }
                    list.push(arr);

                }
            } else {
                for (var i = 0; i < vm.page.pageTemplates.length; i++) {
                    var _page = vm.page.pageTemplates[i];
                    var arr = {};
                    arr.id = i;
                    arr.value = '宽：' + _page.pageSizeW + 'mm 高：' + _page.pageSizeH + 'mm';
                    arr.mediabox = {
                        height: _page.pageSizeH,
                        width: _page.pageSizeW,
                        // name:'画布尺寸'
                    };
                    arr.editbox = {
                        height: _page.pageEditH,
                        width: _page.pageEditW,
                        x: _page.pageEditX,
                        y: _page.pageEditY,
                        // name:'编辑区域'
                    };
                    arr.blood = {
                        top: _page.pageBloodT,
                        bottom: _page.pageBloodB,
                        left: _page.pageBloodL,
                        right: _page.pageBloodR,
                        // name:'出血尺寸'
                    }
                    list.push(arr);
                }
            }

            //封装成方法，通过某个属性去重
            function filterRepeat(arr, prop) {
                if (arr == null || !(arr instanceof Array)) {
                    return arr;
                }
                return arr.filter(function (element, index, self) {
                    // return self.findIndex(el=>el[prop]==element[prop])===index
                    return self.findIndex(function (el) {
                        return el[prop] == element[prop]
                    }) === index
                })
            }

            //自定义去重方法
            function filterRepeat2(arr, compareFn) {
                return arr.filter(function (element, index, self) {
                    // return self.findIndex(el=>compareFn(el,element))===index
                    return self.findIndex(function (el) {
                        return compareFn(el, element)
                    }) === index
                })
            }

            //加载到数组原型上
            Array.prototype.filterRepeat = function () {
                if (typeof arguments[0] == 'string') return filterRepeat(this, arguments[0])
                return filterRepeat2(this, arguments[0])
            }

            vm.addPageValue = list.filterRepeat(function (a, b) {
                return a.mediabox.width == b.mediabox.width && a.mediabox.height == b.mediabox.height &&
                    a.editbox.width == b.editbox.width && a.editbox.height == b.editbox.height && a.editbox.x == b.editbox.x && a.editbox.y == b.editbox.y &&
                    a.blood.width == b.blood.width && a.blood.height == b.blood.height && a.blood.x == b.blood.x && a.blood.y == b.blood.y
            });
        }

        $scope.changeCatalog = function () {
            groupPicLayoutXmls();
        }

        function groupPicLayoutXmls() {
            vm.picLayoutXmlGroup = {};
            PicLayoutXmls.getAll({
                items: [{key: "catalog.id", op: "=", value: vm.catalogId}], sort: ["id,asc"]
            }, function (res) {
                vm.picLayoutXmlsList = [];
                var map = {}
                for (var i = 0; i < res.length; i++) {
                    var ai = res[i];
                    var quantity = ai.quantity;
                    if (ai.quantity > 8) {
                        quantity = 9;
                    }
                    if (!map[quantity]) {
                        map[quantity] = [ai]
                    } else {
                        map[quantity].push(ai)
                    }
                }

                Object.keys(map).forEach(function (key) {
                    var name = key + "张照片";
                    if (key == 9) {
                        name = key + "张及以上照片";
                    }
                    vm.picLayoutXmlsList.push({
                        name: name,
                        data: map[key],
                    })
                })
                if (vm.picLayoutXmlsList.length > 0) {
                    vm.picLayoutXmlGroup = vm.picLayoutXmlsList[0];
                    var len = 0;
                    angular.forEach(vm.picLayoutXmlGroup.data, function (data) {
                        var result = ListService.inList(data, $scope.template.piclayouts.piclayout, ["identifier"]);
                        if (result) {
                            data.isRecommend = true;
                            len++;
                        }
                        if (len == vm.picLayoutXmlGroup.data.length) {
                            vm.picLayoutXmlGroup.layoutChooseAll = true;
                        } else {
                            vm.picLayoutXmlGroup.layoutChooseAll = false;
                        }
                    })
                }

            });
        }

        $scope.selectLayoutLabel = function (label, index) {
            vm.picLayoutXmlGroup = label;
        };

        $scope.addLayout = function (data, index) {
            if(vm.textEditor){
                return
            }
            PicLayoutXmls.covert({key: data.identifier}, function (res) {
                var arr = getGeometry(res.mediabox);
                $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
                var scale1 = $scope.currentPage.mediabox.width / $scope.currentPage.mediabox.height;
                var scale2 = arr.width / arr.height;
                var newH = null, newW = null, pageScale = null;
                if (scale1 >= scale2) {
                    newH = $scope.currentPage.mediabox.height;
                    // newW = $scope.currentPage.mediabox.height / arr.height * arr.width;
                    pageScale = newH / arr.height;
                } else {
                    newW = $scope.currentPage.mediabox.width;
                    // newH = $scope.currentPage.mediabox.width / arr.width * arr.height;
                    pageScale = newW / arr.width;
                }

                var levels = res.levels.level;
                angular.forEach(levels, function (level, i) {
                    var imagebox = level.imagebox;
                    imagebox.geometry = getGeometry(imagebox.geometry);
                    if (imagebox.shadow == null) {
                        imagebox.shadow = {
                            color: '#777777',
                            x: 0,
                            y: 0,
                            blur: 0
                        }
                    }
                    if (imagebox.border == null) {
                        imagebox.border = {
                            shape: 'Rectangle',
                            radius: {
                                lt: 0,
                                rt: 0,
                                rb: 0,
                                lb: 0,
                                all: 0,
                                filletType: 'Fixed'
                            },
                            "edge": {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                            lineWidth: 0,
                            type: "Color",
                            lineType: "solid",
                            resource: {
                                "identifier": ''
                            },
                            color: '#000000',
                        }
                    }
                    level.imagebox = ({
                        geometry: {
                            width: imagebox.geometry.width * pageScale,
                            height: imagebox.geometry.height * pageScale,
                            x: imagebox.geometry.x * pageScale,
                            y: imagebox.geometry.y * pageScale,
                        },
                        rotation: {
                            angle:360+Number(imagebox.rotation.angle)
                        },
                        mask: imagebox.mask,
                        imgUrl: 'content/images/mask_souce2.png',
                        maskUrl: imagebox.mask.resource.identifier || 'content/images/mask_souce2.png',
                        bgImg: '',
                        unit: 'mm',
                        levelI: $scope.template.levelI,
                        pageI: $scope.template.pageI,
                        current: 'imagebox',
                        lock: false,
                        border:angular.copy(imagebox.border),
                        shadow:angular.copy(imagebox.shadow),
                        transparency:isNaN(imagebox.transparency) ? 1 : imagebox.transparency
                    })
                    if (level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {       //qiniu
                    } else {
                        var imagecanvas = document.createElement('canvas');
                        var imagecontext = imagecanvas.getContext('2d');
                        var newImg = document.createElement('img');
                        newImg.crossOrigin = "Anonymous";
                        newImg.src = 'content/images/mask_souce2.png';
                        newImg.onload = function () {
                            newImg.onload = null;
                            var width = newImg.width;
                            var height = newImg.height;
                            var masks = document.createElement('img');
                            masks.crossOrigin = "anonymous";
                            masks.onload = function () {
                                masks.onload = null;
                                imagecanvas.width = width;
                                imagecanvas.height = height;
                                imagecontext.drawImage(masks, 0, 0, width, height);
                                imagecontext.globalCompositeOperation = 'source-in';
                                imagecontext.drawImage(newImg, 0, 0);
                                level.imagebox.bgImg = ImageService.dataURLtoBlob(imagecanvas.toDataURL());
                                $scope.$apply();
                            };
                            masks.src = level.imagebox.mask.resource.identifier;
                        };
                        newImg.src = 'content/images/mask_souce2.png';
                    }
                })


                if ($scope.currentPage.levels.level && $scope.currentPage.levels.level.length) {
                    for (var i = $scope.currentPage.levels.level.length - 1; i >= 0; i--) {
                        var level = $scope.currentPage.levels.level[i];
                        if (level.imagebox && level.imagebox.geometry) {
                            $scope.currentPage.levels.level.splice(i, 1)
                        }
                    }
                }

                angular.forEach(levels, function (level, i) {
                    if ($scope.currentPage.levels.level && $scope.currentPage.levels.level.length) {
                        level.imagebox.levelI = $scope.currentPage.levels.level.length;
                        level.seq = $scope.currentPage.levels.level.length - 0 + 1;
                    } else {
                        $scope.currentPage.levels.level = [];
                        level.levelI = 0;
                    }

                    $scope.currentPage.levels.level.push(level);
                })
                updateHistorys();
            })
            return
        }

        $scope.recommendLayout = function (data) {
            data.isRecommend = !data.isRecommend;
            piclayoutToDoc(data);
            layoutChooseAll()
        };
        function layoutChooseAll(){
            vm.picLayoutXmlGroup.layoutChooseAll = true;
            vm.picLayoutXmlGroup.data.forEach(function (item) {
                if(!item.isRecommend){
                    vm.picLayoutXmlGroup.layoutChooseAll = false;
                }
            })
        }

        $scope.recommendAllLayout = function (e) {
            fn(e)
            vm.picLayoutXmlGroup.layoutChooseAll = !vm.picLayoutXmlGroup.layoutChooseAll
            vm.picLayoutXmlGroup.data.some(function (data) {
                data.isRecommend = vm.picLayoutXmlGroup.layoutChooseAll;
                var result = ListService.inList(data, $scope.template.piclayouts.piclayout, ["identifier"]);
                if (data.isRecommend) {
                    if (result) {

                    } else {
                        $scope.template.piclayouts.piclayout.push({
                            identifier: data.identifier,
                            resource: {
                                provider: 'qiniu',
                                identifier: data.identifier,
                            },
                            thumbnailresource: {
                                provider: 'qiniu',
                                identifier: data.cover,
                            },
                            quantity: data.quantity
                        })
                    }
                } else {
                    if (result) {
                        for (var i = $scope.template.piclayouts.piclayout.length - 1; i >= 0; i--) {
                            if ($scope.template.piclayouts.piclayout[i].identifier == result.identifier) {
                                $scope.template.piclayouts.piclayout.splice(i, 1);
                            }
                        }
                    } else {

                    }
                }
            })
        }

        function piclayoutToDoc(data) {
            var result = ListService.inList(data, $scope.template.piclayouts.piclayout, ["identifier"]);
            if (result) {
                for (var i = $scope.template.piclayouts.piclayout.length - 1; i >= 0; i--) {
                    if ($scope.template.piclayouts.piclayout[i].identifier == result.identifier) {
                        $scope.template.piclayouts.piclayout.splice(i, 1);
                    }
                }
            } else {
                $scope.template.piclayouts.piclayout.push({
                    identifier: data.identifier,
                    resource: {
                        provider: 'qiniu',
                        identifier: data.identifier,
                    },
                    thumbnailresource: {
                        provider: 'qiniu',
                        identifier: data.cover,
                    },
                    quantity: data.quantity
                })
            }
        }

        function imageboxResourceCanvas(item, callback) {

            var image = angular.copy(item);
            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = image.imgUrl;
            newImg.onload = function () {
                newImg.onload = null;
                var _image = getImageSource(newImg, image, image.image.resource.identifier);
                var scaleW = _image.width / newImg.width;
                var scaleH = _image.height / newImg.height;
                var masks = document.createElement('img');
                var maskUrl = 'content/images/mask_souce2.png';
                if (image.mask && image.mask.resource && image.mask.resource.identifier) {
                    maskUrl = image.mask.resource.identifier
                }
                image.maskUrl = maskUrl;
                masks.src = maskUrl;
                masks.crossOrigin = "anonymous";
                masks.onload = function () {
                    masks.onload = null;
                    masks.width = image.geometry.width;
                    masks.height = image.geometry.height;
                    imagecanvas.width = masks.width;
                    imagecanvas.height = masks.height;
                    imagecontext.drawImage(masks, 0, 0, masks.width, masks.height);
                    imagecontext.globalCompositeOperation = 'source-in';

                    imagecontext.scale(scaleW, scaleH);
                    imagecontext.scale(item.image.scale, item.image.scale);
                    imagecontext.drawImage(newImg, item.image.offsetx / scaleW, item.image.offsety / scaleH);
                    var pic = imagecanvas.toDataURL();
                    image.bgImg = ImageService.dataURLtoBlob(pic);
                    item.bgImg = ImageService.dataURLtoBlob(pic);
                    updateHistorys();
                    $scope.$digest();
                };
                masks.src = maskUrl;
                if (callback) {
                    callback()
                }
            };
            newImg.src = image.imgUrl;
        }

        /**
         * 宽高坐标从 mm 换算成 px
         * @param obj
         * @param index
         * @returns {{height: number, width: number, x: number, y: number}}
         */
        function getGeometry(obj) {
            return {
                height: MmTurnPx(obj.height),
                width: MmTurnPx(obj.width),
                x: MmTurnPx(obj.x),
                y: MmTurnPx(obj.y)
            }
        }

        var elem = document.querySelector('input[type="range"]');

        var rangeValue = function () {
            $(".transparency-num").val(elem.value);
            $scope.selectBox.transparency = parseFloat(elem.value);
            $scope.template.transparency = elem.value * 100;
            $scope.$digest();
        };

        elem.addEventListener("input", rangeValue);

        $scope.transparencyChange = function () {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if ($scope.template.transparency > 100) {
                MessageService.error("透明度有效范围：0~100");
                $scope.template.transparency = 100;
            }
            $(elem).val($scope.template.transparency / 100);
            level[$scope.selectBox.current].transparency = $scope.template.transparency / 100;
            updateHistorys();
        }

        // 左边侧边栏点击切换
        $scope.selectPanel = function (attr) {
            $scope.selPanel = attr;
            vm.leftShow = true;
            $(".leftPanel-mainContent").removeClass('hide1');
            $(".rightPanel").removeClass('rightPanel-big rightPanel-big1 rightPanel-big2 rightPanel-big3');
            if (attr == 'page') {
                if (vm.unit == 'mm') {
                    $scope.pageValue.mediabox.width = PxTurnMm($scope.currentPage.mediabox.width);
                    $scope.pageValue.mediabox.height = PxTurnMm($scope.currentPage.mediabox.height);
                } else if (vm.unit == 'px') {
                    $scope.pageValue.mediabox.width = $scope.currentPage.mediabox.width;
                    $scope.pageValue.mediabox.height = $scope.currentPage.mediabox.height;
                } else if (vm.unit == 'in') {
                    $scope.pageValue.mediabox.width = InTurnPx($scope.currentPage.mediabox.width);
                    $scope.pageValue.mediabox.height = InTurnPx($scope.currentPage.mediabox.height);
                }
                $scope.selPanel = attr;
            }

            if (attr == 'design') {
                if (vm.unit == 'mm') {
                    $scope.pageValue.mediabox.width = PxTurnMm($scope.currentPage.mediabox.width);
                    $scope.pageValue.mediabox.height = PxTurnMm($scope.currentPage.mediabox.height);
                } else if (vm.unit == 'px') {
                    $scope.pageValue.mediabox.width = $scope.currentPage.mediabox.width;
                    $scope.pageValue.mediabox.height = $scope.currentPage.mediabox.height;
                } else if (vm.unit == 'in') {
                    $scope.pageValue.mediabox.width = InTurnPx($scope.currentPage.mediabox.width);
                    $scope.pageValue.mediabox.height = InTurnPx($scope.currentPage.mediabox.height);
                }
                $scope.selPanel = attr;
            }

            if (attr == 'placement') {
                addPlacement();
            }

            if (attr == 'barcode') {
                $scope.selPanel = 'barcode';
            }
            if (attr == 'qrcode') {
                $scope.selPanel = 'qrcode';
            }

            if (attr == 'text') {
                $scope.selPanel = 'text';
            }

            if (attr == 'mask') {
                $scope.switchMaskTab('all');
            }

            if (attr == 'material') {
                vm.showCliparts = vm.allCliparts;
                vm.clipartTab = 'SYSTEM';
                $scope.selPanel = attr;
                $scope.chooseMaterialTab('SYSTEM');
            }
            if (attr == 'img') {
                $scope.selPanel = attr;
                setTimeout(function () {
                    userImg();
                }, 200);
            }
            if (attr == 'border') {
                if (!vm.frameConfigs || vm.frameConfigs.length == 0) {
                    getSystemBorders()
                }
            }
            if (attr == 'backup' && 0 == vm.backupPageSize) {
                vm.backupPageSize = 20;
            }
            if (attr == 'widgetTitle') {
                $scope.widgetTitleInit('SYSTEM');
            }
            if (attr == 'widgetMonth') {
                $scope.widgetMonthInit('SYSTEM');
            }
            if (attr == 'productTemplate') {
                if(vm.catalogs.length>0){
                    $scope.selectTempLabel(vm.catalogs[0],0)
                }
            }
            $timeout(function () {
                updateMeasureRuler();
            })
        }

        $scope.fillImgCb = function () {
            vm.imgIndexNum++;
            galleriesByCondition();
        }
        //删除图片
        $scope.fillImgDelete = function (data, index) {
            Galleries.delete({id: data.id}, function () {
                vm.userImgAll.splice(index, 1);
            })
        }

        //用户上传的图片
        vm.showImgLabel = false;
        function userImg() {
            ThemeClassify.getAllByCondition1({
                items: [{key: "type", op: "=", value: 'GALLERY'}, {
                    key: "used",
                    op: "=",
                    value: true
                }], sort: ["seq,asc"]
            }, function (res) {
                vm.imgLabels = angular.copy(res);
                vm.imgLabels.unshift({
                    id:'',
                    name:'全部'
                });
                $scope.setImgLabel(vm.imgLabels[0].id)
            });
        }
        $scope.setImgLabel = function(id){
            vm.imgLabel = id;
            vm.imgIndexNum = 0;
            vm.imgSum = 20;
            vm.isUserImg = true;
            vm.userImgAll = [];
            galleriesByCondition();
        }
        function galleriesByCondition(){
            Galleries.byConditionNew({
                items: [{key: "themeClassify.id", op: "=", value: vm.imgLabel}],
                page: vm.imgIndexNum,
                size: vm.imgSum,
                sort: ["id,desc"]
            }, function (res) {
                for (var i = 0; i < res.length; i++) {
                    res[i].identifier = vm.url + res[i].identifier;
                    vm.userImgAll.push(res[i])
                }
                if (res.length < vm.imgSum) {
                    vm.isUserImg = false;
                }
            })
        }

        //填充图片
        $scope.fillImg = function (item) {
            if (vm.selectItem) {
                $http({url: item.identifier + '?imageInfo'}).success(function (data) {
                    var _w = vm.selectItem.geometry.width;
                    var _h = vm.selectItem.geometry.height;
                    var bgImgW = data.width;
                    var bgImgH = data.height;
                    if (data.orientation == "Left-bottom" || data.orientation == "Right-top" || data.orientation == "Left-top" || data.orientation == "Right-bottom") {
                        bgImgW = data.height;
                        bgImgH = data.width;
                    }
                    var bgImgR = bgImgW / bgImgH;
                    var bgImgR1 = _w / _h;
                    var _image = {
                        resource: {
                            provider: 'qiniu',
                            identifier: item.identifier,
                        },
                        offsetx: 0,
                        offsety: 0,
                        angle: 0,
                        scale: 1
                    }
                    if (bgImgR >= bgImgR1) {
                        _image.adaptation = 'Height';
                        _image.height = _h;
                        _image.width = _h / bgImgH * bgImgW;
                    } else {
                        _image.adaptation = 'Width';
                        _image.width = _w;
                        _image.height = _w / bgImgW * bgImgH;
                    }
                    _image.imgWidth = bgImgW;
                    _image.imgHeight = bgImgH;
                    vm.selectItem.image = _image;
                }).error(function (error) {
                });
            } else {

            }
        }

        /* ==========================================================================
         模板处理
         ========================================================================== */
        //删除模板背景图片
        $scope.deleteBgImg = function () {
            if ($scope.template.pages.page[$scope.template.pageI].bgImg == '') {
                return;
            }
            $scope.template.pages.page[$scope.template.pageI].bgImg = '';
            $scope.template.pages.page[$scope.template.pageI].background.resource.identifier = '';
            $scope.template.pages.page[$scope.template.pageI].hasBgImg = false;
            updateHistorys();
        };

        //切换部件
        $scope.partCb = function(part, e){
            if(vm.textEditor){
                return;
            }
            var _index = 0;
            for(var i=0; i<$scope.template.pages.page.length; i++){
                var _page = $scope.template.pages.page[i];
                if(_page.config.partid == part.id){
                    _index = i;
                    break
                }
            }
            $scope.selectPages(_index, e,function(){
                pageListScroll();
            });
        }
        //部件顺序调整
        var sort2 = null;
        var sortArray2 = [];
        function partListDrag() {
            $timeout(function () {
                sort2 = new Sortable(document.getElementById('pageList2'), {
                    swap: false,
                    filter: '.filtered',
                    cancel: ".notDrag",
                    preventOnFilter: true,
                    animation: 150,
                    scroll:true,
                    onMove: function (/**Event*/evt, /**Event*/originalEvent) {
                        if($scope.template.config.productType == 'CALENDAR'){
                            $timeout(function () {
                                var order = sort2.toArray();
                                $scope.template.config.parts.list.forEach(function (part, index) {
                                    if (part.id =='P' || part.id == 'B1') {
                                        var _index = order.indexOf((index + 1).toString());
                                        order.splice(_index, 1)
                                    }
                                })
                                $scope.template.config.parts.list.forEach(function (part, index) {
                                    if (part.id =='P' || part.id == 'B1') {
                                        order.splice(index, 0, (index + 1).toString())
                                    }
                                })
                                sort.sort(order)
                            },150)
                            var _part = JSON.parse($(evt.related).attr("data-page"));
                            if(_part.id =='P' || _part.id == 'B1'){
                                return false
                            }
                        }
                    },
                    onEnd: function (/**Event*/evt) {
                        $timeout(function () {
                            vm.isPageList2 = true;
                            sortArray2 = [];
                            angular.forEach($('#pageList2').find(".sortingPageView"), function (item, index) {
                                var page = item.getAttribute("data-page");
                                sortArray2.push(JSON.parse(page));
                            });
                            if (sortArray2 && sortArray2.length > 0) {
                                $scope.template.config.parts.list = [];
                                $timeout(function () {
                                    $scope.template.config.parts.list = angular.copy(sortArray2);
                                    $scope.template.config.parts.list.forEach(function (value, index) {
                                        value.seq = index;
                                    })
                                    sortArray2 = [];
                                    pagesSort(function () {
                                        $scope.selectPages($scope.template.pageI);
                                        $scope.pageHistory = [];
                                        vm.isPageList2 = false;
                                        vm.operation = true;
                                        partListDrag();
                                    });
                                })
                            }
                        })
                    }
                });
            })
        }
        //获取部件名
        $scope.partName = function(partid){
            var _name = 0;
            $scope.template.config.parts.list.forEach(function (part) {
                if(part.id == partid){
                    _name = part.name;
                }
            })
            return _name
        }
        //新增部件
        $scope.addPart = function(){
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/part-add.html',
                controller: 'PartAddController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size:'lg',
                resolve: {
                    entity: [function () {
                        return {
                            productType: vm.page.productType,
                            modelData: vm.page.modelData,
                            modelType: vm.page.modelType,
                            modelName: vm.page.modelName,
                            modelPic: vm.page.modelPic,
                            parts:$scope.template.config.parts.list
                        };
                    }]
                }
            }).result.then(function (data) {
                if (data) {
                    vm.operation = true;
                    var _isPart = false;
                    $scope.template.config.parts.list.forEach(function (part) {
                        if(part.id == data.part.id){
                            part.counter = 1;
                            part.bleed = data.part.bleed;
                            part.editboxes = data.part.editboxes;
                            part.mediabox = data.part.mediabox;
                            _isPart = true;
                        }
                    })
                    if(!_isPart){
                        $scope.template.config.parts.list.push(data.part);
                    }
                    $scope.template.config.parts.list.sort(compare('seq', true));
                    //手动触发选中
                    $timeout(function(){
                        $scope.partCb(data.part,event);
                    })
                    var page = angular.copy(data.page);
                    page.seq = $scope.template.pages.page.length;
                    if(vm.page.modelType == 'THREE'){
                        var _modelData = JSON.parse(vm.page.modelData);
                        _modelData.forEach(function (modelItem) {
                            if(modelItem.materialName == data.materialName){
                                modelItem.page = page.seq;
                            }
                        })
                        vm.page.modelData = JSON.stringify(_modelData);
                    }
                    tempPageInfo(page, $scope.template.pages, "pages", page.seq,function () {
                        pagesSort(function () {
                            for(var i=0; i<$scope.template.pages.page.length; i++){
                                if($scope.currentPage.uuid == $scope.template.pages.page[i].uuid){
                                    $scope.selectPages(i);
                                    break;
                                }
                            }
                        })
                    });
                }
            });
        }
        function pagesSort(callback){
            var _pages = [];
            $scope.template.config.parts.list.forEach(function (part) {
                $scope.template.pages.page.forEach(function (page) {
                    if(part.id == page.config.partid){
                        page.seq = _pages.length+1;
                        ratioInfo(page)
                        _pages.push(page)
                    }
                })
            })
            $scope.template.pages.page = _pages;
            if(callback){callback()}
        }
        //编辑部件
        $scope.editorPart = function(){
            var part = angular.copy($scope.template.config.parts.list[getPartIndex()]);
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/part-edit.html',
                controller: 'PartEditController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size:'lg',
                resolve: {
                    entity: [function () {
                        return {
                            productType: vm.page.productType,
                            modelData: vm.page.modelData,
                            modelType: vm.page.modelType,
                            modelName: vm.page.modelName,
                            modelPic: vm.page.modelPic,
                            mouldid: $scope.currentPage.scene ? $scope.currentPage.scene.mouldid : "",
                            part:part,
                            pageIndex:$scope.template.pageI,
                            parts:$scope.template.config.parts.list
                        };
                    }]
                }
            }).result.then(function (callbackData) {
                if(callbackData){
                    var uuid = angular.copy($scope.currentPage.uuid);
                    var data = callbackData.data;
                    var _parts = callbackData.parts;
                    var _isisDeleteBackupPages = callbackData.isDeleteBackupPages;
                    if(callbackData.modelData){
                        vm.page.modelData = callbackData.modelData;
                    }
                    if (data) {
                        vm.operation = true;
                        var _part = angular.copy($scope.template.config.parts.list[getPartIndex()]);
                        if(vm.page.productType == 'PHOTO'){
                            $scope.template.config.parts.list.forEach(function (partItem) {
                                partItem.mediabox = data.mediabox;
                                partItem.bleed = data.bleed;
                                partItem.editboxes = data.editboxes;
                            })
                        }else{
                            // $scope.template.config.parts.list[getPartIndex()] = data;
                            $scope.template.config.parts.list.forEach(function (list) {
                                var _partItem = getParts(_parts,list.id);
                                if(_partItem.selected){
                                    list.mediabox = data.mediabox;
                                    list.bleed = data.bleed;
                                    list.editboxes = data.editboxes;
                                }
                            })
                        }
                        var pageIndex = 0;
                        $scope.template.pages.page.forEach(function (page, i) {
                            (function (item, index) {
                                var _partItem = getParts(_parts,item.config.partid);
                                if(_partItem.selected || vm.page.productType == 'PHOTO'){
                                    var scaleW = angular.copy(data.mediabox.width / _partItem.mediabox.width);
                                    if(page.spread){
                                        scaleW = angular.copy(data.mediabox.width*2 / _partItem.mediabox.width*2);
                                    }
                                    var scaleH = angular.copy(data.mediabox.height / _partItem.mediabox.height);
                                    var _data = angular.copy(data);
                                    if(vm.page.productType == 'PHOTO' && part.id != item.config.partid){
                                        _data.scene = null;
                                    }
                                    tempPageInfo1(_data, item,index,scaleW,scaleH,function () {
                                        if (!page.backup) {
                                            loadPageToPx(page, index, function () {
                                                pageIndex++
                                                if(pageIndex == $scope.template.pages.page.length){
                                                    for(var i=0; i<$scope.template.pages.page.length; i++){
                                                        if(uuid == $scope.template.pages.page[i].uuid){
                                                            $scope.selectPages(i);
                                                            break;
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                    },false,true)
                                }else{
                                    pageIndex++
                                    if(pageIndex == $scope.template.pages.page.length){
                                        for(var i=0; i<$scope.template.pages.page.length; i++){
                                            if(uuid == $scope.template.pages.page[i].uuid){
                                                $scope.selectPages(i);
                                                break;
                                            }
                                        }
                                    }
                                }
                            })(page, i)
                        })
                        if (_isisDeleteBackupPages) {
                            for(var j=$scope.template.backupPages.list.length-1; j>=0; j--){
                                var _backupPage = $scope.template.backupPages.list[j];
                                var _partItem = getParts(_parts,_backupPage.config.partid);
                                if(_partItem.selected){
                                    $scope.template.backupPages.list.splice(j,1);
                                }
                            }
                        }

                    }
                }
            });
        }
        function getParts(parts,id){
            var _part = null;
            parts.forEach(function (part) {
                if(part.id == id){
                    _part = part;
                }
            })
            return _part
        }
        $scope.getPartIndex = getPartIndex;
        function getPartIndex(){
            if(!$scope.currentPage || !$scope.currentPage.config){
                return
            }
            var _partIndex = 0;
            for(var i=0; i<$scope.template.config.parts.list.length; i++){
                if($scope.template.config.parts.list[i].id == $scope.currentPage.config.partid){
                    _partIndex = i;
                    break
                }
            }
            return _partIndex
        }
        //根据每页的页面信息获取页面配置列表索引
        $scope.getItemPartIndex = function(page){
            if(page && page.config){
                var itemIndex = $scope.template.config.parts.list.findIndex(function(item){
                    return item.id === page.config.partid;
                })
                return itemIndex;
            }
        }
        //点击部件横向滚动条滚动
        function pageListScroll(){
            $timeout(function () {
                //获取当前选中页距离最左侧的距离
                var scrollLeft = $("#pageList").find(".selected").offset().left - $("#pageList").offset().left;
                //减去外边距
                scrollLeft = scrollLeft - 10;
                $(".c-editor-canvas-pagelist").animate({
                    scrollLeft:scrollLeft
                },200)
            })
        }
        //模板预览点击时
        $scope.selectPages = function (index, e, callback) {
            if(vm.textEditor){
                return;
            }
            if(vm.isSkip || $scope.lastPage.loading){
                // MessageService.error("其他页正在更新缩略图中，请稍后再试");
                setTimeout(function(){
                    //延迟递归调用
                    $scope.selectPages(index,e,callback);
                },100)
                return
            }
            $scope.canvasCb();
            if(e){
                fn(e)
            }
            if (index == null) {
                return;
            }
            vm.isSkip = true;
            $scope.selectBox = {
                current: 'page'
            }
            vm.loadingImg = true;
            $timeout(function () {
                checkPageCover(index,function () {
                    $scope.currentPage = null;
                    $timeout(function (){
                        $scope.template.pageI = index;
                        $scope.RightPanel = 'page';
                        $scope.lastPage = $scope.template.pages.page[$scope.template.pageI];
                        $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
                        isCanLeftAndRightPage();
                        getPagePx();
                        if ($scope.template.pages.page[index].scene) {
                            $scope.currentPage.isScene = true;
                            $scope.selectScene()
                        } else {
                            $scope.currentPage.isScene = false;
                        }
                        // var _page = angular.copy($scope.template.pages.page[$scope.template.pageI]);
                        // $scope.currentPage = _page;
                        vm.isSkip = false;
                        $scope.changeShirnk(0);
                        resetCtrlKeyArray();
                        vm.loadingImg = false;
                        $scope.pageHistory=[];
                        if(callback){callback()}
                    })
                });
            })

            canvasInfo();
            vm.isTransp = false;
            vm.isShadowSet = false;

        };
        //拖拽排序
        var sort = null;
        var sortArray = [];
        function pageListDrag() {
            $timeout(function () {
                vm.sortingPage = angular.copy($scope.template.pages.page);
                sort = new Sortable(document.getElementById('pageList'), {
                    swap: false,
                    filter: '.filtered,.add-page-menu',
                    cancel: ".notDrag",
                    preventOnFilter: true,
                    animation: 150,
                    scroll:true,
                    draggable:'.sortingPageView',
                    onStart: function(evt){
                    },
                    onMove: function (/**Event*/evt, /**Event*/originalEvent) {
                        var currentPage = JSON.parse(evt.dragged.dataset.page);
                        var originalPage = JSON.parse(evt.related.dataset.page);
                        //不同部件不允许排序
                        if(currentPage.config.partid !== originalPage.config.partid) return false;
                    },
                    onEnd: function (/**Event*/evt) {
                        vm.operation = true;
                        $timeout(function () {
                            sortArray = [];
                            angular.forEach($('#pageList').find(".sortingPageView"), function (item, index) {
                                var page = item.getAttribute("data-page");
                                sortArray.push(JSON.parse(page));
                            });
                            if(sortArray && sortArray.length>0){
                                sortArray.forEach(function (value, index) {
                                    value.seq = index+1;
                                })
                                $timeout(function () {
                                    $scope.template.pages.page = angular.copy(sortArray);
                                    if($scope.template.pages.page[$scope.template.pageI].uuid != $scope.currentPage.uuid){
                                        $scope.selectPages($scope.template.pageI);
                                    }
                                    sortArray = [];
                                    vm.operation = true;
                                })
                            }
                        })
                    }
                });
            })
        }
        $scope.partPageLast = function(page, index){
            var _last = false;
            if(page.config.partid != 'P'){
                _last = true;
            }else{
                var _index = -1;
                var _isE = false;
                $scope.template.pages.page.forEach(function (page1) {
                    if(page1.config.partid == 'P'){
                        _index++;
                        _isE = true;
                    }else{
                        if(!_isE){
                            _index++;
                        }
                    }
                })
                if(index == _index){
                    _last = true;
                }
            }
            return _last
        }
        $scope.addNoModelPage = function () {
            $scope.value = vm.addPageValue[0]
            vm.isChoosePage = true;
        }

        $scope.updatePageValue = function (item) {
            $scope.value = item;
        }

        $scope.saveAddPageValue = function () {
            vm.isChoosePage = false;
            $scope.addPage($scope.value);
        }

        //增加模板
        var _addPage = false;
        $scope.addPage = function (item) {
            if(_addPage){
                return
            }
            var page = $scope.template.pages.page;
            var prevP = null;
            if (item) {
                prevP = angular.copy(page[item.id]);
            } else {
                prevP = angular.copy(page[$scope.template.pageI]);
            }
            if(!prevP){
                prevP = angular.copy($scope.initializePage);
            }
            prevP.config.monthIndex = "";
            prevP.config.partid = "P";
            vm.operation = true;
                _addPage = true;
                vm.loadingImg = true;
                var seq = 1;
                angular.forEach($scope.template.pages.page, function (p) {
                    if (!p.backup) {
                        seq++;
                    }
                })
                $timeout(function () {
                    var nodes = document.getElementsByClassName('page-item2');
                    setThumbnailPic(nodes[0], $scope.lastPage, null, function (pagecover) {
                        var standardAddPage = false;
                        if (!vm.isCustom && !vm.hasModel) {
                            standardAddPage = true;
                        }
                        //查找内页的索引
                        var pIndex = $scope.template.config.parts.list.findIndex(function(item){
                            return item.id === "P";
                        })
                        var part = $scope.template.config.parts.list[pIndex];
                        part.counter = Number(part.counter) + 1;
                        var mediabox = angular.copy(part.mediabox);
                        var trimbox = angular.copy({
                            height: part.mediabox.height - Number(part.bleed.bottom) - Number(part.bleed.top),
                            width: part.mediabox.width - Number(part.bleed.left) - Number(part.bleed.right),
                            x: part.bleed.left,
                            y: part.bleed.top
                        });
                        var editboxes = {
                            editbox:[]
                        };

                        part.editboxes.editbox.forEach(function (editbox) {
                            editboxes.editbox.push(getGeometry(angular.copy(editbox)))
                        })
                        var objData = {
                            isScene: false,
                            bgImg: null,
                            seq: seq,
                            background: {
                                resource: {},
                                color: "#ffffff",
                                type: "Color"
                            },
                            pagecover: {
                                identifier: null,
                                provider: 'qiniu'
                            },
                            levels: {
                                level:[]
                            },
                            barcode: {},
                            qrcode: {},
                            scale: 1,
                            uuid: UidService.get(),
                            width: MmTurnPx(trimbox.width),
                            height: MmTurnPx(trimbox.height),
                            editbox: editboxes.editbox[0],
                            editboxes: editboxes,
                            trimbox: getGeometry(trimbox),
                            mediabox: getGeometry(mediabox),
                            blood: part.bleed,
                            bloodPx: {
                                top: MmTurnPx(trimbox.y),
                                bottom: MmTurnPx(mediabox.height - trimbox.y - trimbox.height),
                                left: MmTurnPx(trimbox.x),
                                right: MmTurnPx(mediabox.width - trimbox.x - trimbox.width),
                            },
                            spread: part.spread,
                            backup: false,
                            unreplace: !vm.page.replace,
                            proportion: prevP.proportion,
                            ratio: prevP.ratio,
                            standardAddPage: standardAddPage,
                            config: prevP.config,
                        };
                        var obj = angular.copy(objData);
                        obj.custom = obj.mediabox;
                        ratioInfo(obj);
                        var pageI = page.length - 1;
                        for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                            var pa = $scope.template.pages.page[i];
                            if (!pa.backup && pa.type != 'BackCover') {
                                $scope.template.pages.page.splice(i + 1, 0, obj);
                                pageI = i + 1
                                break;
                            }
                        }
                        $scope.template.pageI = pageI;
                        $scope.template.levelI = 0;
                        $scope.selPanel = 'page';
                        $scope.visibleLeft = true;
                        $scope.visibleRight = false;
                        $scope.pageHistory = [];
                        // $scope.currentPage = prevP;
                        getPagePx();

                        $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
                        $scope.lastPage = $scope.template.pages.page[$scope.template.pageI];
                        setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, $scope.template.pageI);
                        // updateHistorys();
                        getImageboxLen();
                        $timeout(function () {
                            var nodes = document.getElementsByClassName('page-item2');
                            setThumbnailPic(nodes[0], $scope.lastPage, null, function (pagecover) {
                                _addPage = false;
                                pagesSort(function () {
                                    for(var i=0; i<$scope.template.pages.page.length; i++){
                                        if($scope.currentPage.uuid == $scope.template.pages.page[i].uuid){
                                            $scope.selectPages(i);
                                            break;
                                        }
                                    }
                                    $timeout(function () {
                                        vm.loadingImg = false;
                                    },500)
                                })
                            });
                        },200)
                    });
                })

        };

        //模板宽高onchange
        $scope.updateTemplateHeightAndWidth = function () {
            if ($scope.pageValue.mediabox.width < 10) {
                $scope.pageValue.mediabox.width = 10
            }
            if ($scope.pageValue.mediabox.height < 10) {
                $scope.pageValue.mediabox.height = 10
            }
            var i = $scope.template.pageI;
            var currentPage = $scope.currentPage = $scope.template.pages.page[i];
            if (!currentPage) {
                return
            }
            if (vm.unit == 'px') {
                // px---mm
                vm.page.pageSize.width = PxTurnMm($scope.pageValue.mediabox.width);
                vm.page.pageSize.height = PxTurnMm($scope.pageValue.mediabox.height);
                currentPage.mediabox.width = $scope.pageValue.mediabox.width;
                currentPage.mediabox.height = $scope.pageValue.mediabox.height;

            } else if (vm.unit == 'mm') {

                vm.page.pageSize.width = $scope.pageValue.mediabox.width;
                vm.page.pageSize.height = $scope.pageValue.mediabox.height;
                currentPage.mediabox.width = MmTurnPx($scope.pageValue.mediabox.width);
                currentPage.mediabox.height = MmTurnPx($scope.pageValue.mediabox.height);
            } else if (vm.unit == 'in') {

                vm.page.pageSize.width = InTurnMm($scope.pageValue.mediabox.width);
                vm.page.pageSize.height = InTurnMm($scope.pageValue.mediabox.height);
                currentPage.mediabox.width = InTurnPx($scope.pageValue.mediabox.width);
                currentPage.mediabox.height = InTurnPx($scope.pageValue.mediabox.height);
            }
            currentPage.trimbox.width = currentPage.mediabox.width - currentPage.bloodPx.left - currentPage.bloodPx.right;
            currentPage.trimbox.height = currentPage.mediabox.height - currentPage.bloodPx.top - currentPage.bloodPx.bottom;
            setOriginalWaH(currentPage.trimbox.width, currentPage.trimbox.height, i);
            resetBackground();
        };

        //初始化像素
        function loadAttribute() {
            if (vm.unit == 'mm') {
                //属性栏
                $scope.pageValue = {
                    mediabox: {
                        width: PxTurnMm($scope.currentPage.mediabox.width),
                        height: PxTurnMm($scope.currentPage.mediabox.height)
                    },
                    editbox: {
                        width: PxTurnMm($scope.currentPage.editbox.width),
                        height: PxTurnMm($scope.currentPage.editbox.height),
                        x: PxTurnMm($scope.currentPage.editbox.x),
                        y: PxTurnMm($scope.currentPage.editbox.y)
                    },
                    barcode: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    qrcode: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    imagebox: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    imageart: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    textbox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    textdocument: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    calMonthBox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    calTitleBox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    }
                }
            }
            if (vm.unit == 'in') {
                //属性栏
                $scope.pageValue = {
                    mediabox: {
                        width: PxTurnIn($scope.currentPage.mediabox.width),
                        height: PxTurnIn($scope.currentPage.mediabox.height)
                    },
                    editbox: {
                        width: PxTurnIn($scope.currentPage.editbox.width),
                        height: PxTurnIn($scope.currentPage.editbox.height),
                        x: PxTurnIn($scope.currentPage.editbox.x),
                        y: PxTurnIn($scope.currentPage.editbox.y)
                    },
                    barcode: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    qrcode: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    imagebox: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    imageart: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    textbox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    textdocument: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    calMonthBox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    calTitleBox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    }
                }
            }
            if (vm.unit == 'px') {
                //属性栏
                $scope.pageValue = {
                    mediabox: {
                        width: $scope.currentPage.mediabox.width,
                        height: $scope.currentPage.mediabox.height
                    },
                    editbox: {
                        width: $scope.currentPage.editbox.width,
                        height: $scope.currentPage.editbox.height,
                        x: $scope.currentPage.editbox.x,
                        y: $scope.currentPage.editbox.y
                    },
                    barcode: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    qrcode: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    imagebox: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    imageart: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    textbox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    textdocument: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    calMonthBox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    calTitleBox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    }
                }
            }
        }

        //单位换算
        $scope.unitConversion = function () {
            $scope.pageValue = "";
            $timeout(function () {
                unit()
            })
        }

        function unit() {
            if (vm.unit == 'mm') {
                $scope.pageValue = {
                    mediabox: {
                        width: PxTurnMm($scope.currentPage.mediabox.width),
                        height: PxTurnMm($scope.currentPage.mediabox.height)
                    },
                    editbox: {
                        width: PxTurnMm($scope.currentPage.editbox.width),
                        height: PxTurnMm($scope.currentPage.editbox.height),
                        x: PxTurnMm($scope.currentPage.editbox.x),
                        y: PxTurnMm($scope.currentPage.editbox.y)
                    },
                    barcode: (!$scope.currentPage.barcode || !$scope.currentPage.barcode.geometry) ? null : {
                        width: PxTurnMm($scope.currentPage.barcode.geometry.width),
                        height: PxTurnMm($scope.currentPage.barcode.geometry.height),
                        x: PxTurnMm($scope.currentPage.barcode.geometry.x),
                        y: PxTurnMm($scope.currentPage.barcode.geometry.y)
                    },
                    qrcode: (!$scope.currentPage.qrcode || !$scope.currentPage.qrcode.geometry) ? null : {
                        width: PxTurnMm($scope.currentPage.qrcode.geometry.width),
                        height: PxTurnMm($scope.currentPage.qrcode.geometry.height),
                        x: PxTurnMm($scope.currentPage.qrcode.geometry.x),
                        y: PxTurnMm($scope.currentPage.qrcode.geometry.y)
                    },
                    imagebox: (!$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].imagebox || !$scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry) ? null : {
                        width: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.width),
                        height: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.height),
                        x: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.x),
                        y: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.y)
                    },
                    imageart: (!$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].imageart || !$scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry) ? null : {
                        width: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.width),
                        height: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.height),
                        x: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.x),
                        y: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.y)
                    },
                    textbox: (!$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].textbox || !$scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry) ? null : {
                        width: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.width),
                        height: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.height),
                        x: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.x),
                        y: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.y)
                    },
                    textdocument: (!$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].textdocument || !$scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry) ? null : {
                        width: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.width),
                        height: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.height),
                        x: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.x),
                        y: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.y)
                    },
                    calTitleBox: (!$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].calTitleBox || !$scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry) ? null : {
                        width: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.width),
                        height: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.height),
                        x: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.x),
                        y: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.y)
                    },
                    calMonthBox: (!$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].calMonthBox || !$scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry) ? null : {
                        width: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.width),
                        height: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.height),
                        x: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.x),
                        y: PxTurnMm($scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.y)
                    },
                }
            }
            if (vm.unit == 'in') {
                $scope.pageValue = {
                    mediabox: {
                        width: PxTurnIn($scope.currentPage.mediabox.width),
                        height: PxTurnIn($scope.currentPage.mediabox.height)
                    },
                    editbox: {
                        width: PxTurnIn($scope.currentPage.editbox.width),
                        height: PxTurnIn($scope.currentPage.editbox.height),
                        x: PxTurnIn($scope.currentPage.editbox.x),
                        y: PxTurnIn($scope.currentPage.editbox.y)
                    },
                    barcode: (!$scope.currentPage.barcode || !$scope.currentPage.barcode.geometry) ? null : {
                        width: PxTurnIn($scope.currentPage.barcode.geometry.width),
                        height: PxTurnIn($scope.currentPage.barcode.geometry.height),
                        x: PxTurnIn($scope.currentPage.barcode.geometry.x),
                        y: PxTurnIn($scope.currentPage.barcode.geometry.y)
                    },
                    qrcode: (!$scope.currentPage.qrcode || !$scope.currentPage.qrcode.geometry) ? null : {
                        width: PxTurnIn($scope.currentPage.qrcode.geometry.width),
                        height: PxTurnIn($scope.currentPage.qrcode.geometry.height),
                        x: PxTurnIn($scope.currentPage.qrcode.geometry.x),
                        y: PxTurnIn($scope.currentPage.qrcode.geometry.y)
                    },
                    imagebox: (!$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].imagebox || !$scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry) ? null : {
                        width: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.width),
                        height: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.height),
                        x: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.x),
                        y: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.y)
                    },
                    imageart: (!$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].imageart || !$scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry) ? null : {
                        width: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.width),
                        height: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.height),
                        x: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.x),
                        y: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.y)
                    },
                    textbox: (!$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].textbox || !$scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry) ? null : {
                        width: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.width),
                        height: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.height),
                        x: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.x),
                        y: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.y)
                    },
                    textdocument: !$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].textdocument || !$scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry ? null : {
                        width: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.width),
                        height: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.height),
                        x: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.x),
                        y: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.y)
                    },
                    calTitleBox: !$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].calTitleBox || !$scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry ? null : {
                        width: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.width),
                        height: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.height),
                        x: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.x),
                        y: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.y)
                    },
                    calMonthBox: !$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].calMonthBox || !$scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry ? null : {
                        width: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.width),
                        height: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.height),
                        x: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.x),
                        y: PxTurnIn($scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.y)
                    },
                }
            }
            if (vm.unit == 'px') {
                $scope.pageValue = {
                    mediabox: {
                        width: $scope.currentPage.mediabox.width,
                        height: $scope.currentPage.mediabox.height
                    },
                    editbox: {
                        width: $scope.currentPage.editbox.width,
                        height: $scope.currentPage.editbox.height,
                        x: $scope.currentPage.editbox.x,
                        y: $scope.currentPage.editbox.y
                    },
                    barcode: !$scope.currentPage.barcode || !$scope.currentPage.barcode.geometry ? null : {
                        width: $scope.currentPage.barcode.geometry.width,
                        height: $scope.currentPage.barcode.geometry.height,
                        x: $scope.currentPage.barcode.geometry.x,
                        y: $scope.currentPage.barcode.geometry.y
                    },
                    qrcode: !$scope.currentPage.qrcode || !$scope.currentPage.qrcode.geometry ? null : {
                        width: $scope.currentPage.qrcode.geometry.width,
                        height: $scope.currentPage.qrcode.geometry.height,
                        x: $scope.currentPage.qrcode.geometry.x,
                        y: $scope.currentPage.qrcode.geometry.y
                    },
                    imagebox: !$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].imagebox || !$scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry ? null : {
                        width: $scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.width,
                        height: $scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.height,
                        x: $scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.x,
                        y: $scope.currentPage.levels.level[$scope.template.levelI].imagebox.geometry.y
                    },
                    imageart: !$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].imageart || !$scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry ? null : {
                        width: $scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.width,
                        height: $scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.height,
                        x: $scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.x,
                        y: $scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.y
                    },
                    textbox: !$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].textbox || !$scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry ? null : {
                        width: $scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.width,
                        height: $scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.height,
                        x: $scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.x,
                        y: $scope.currentPage.levels.level[$scope.template.levelI].textbox.geometry.y
                    },
                    textdocument: !$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].textdocument || !$scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry ? null : {
                        width: $scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.width,
                        height: $scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.height,
                        x: $scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.x,
                        y: $scope.currentPage.levels.level[$scope.template.levelI].textdocument.geometry.y
                    },
                    calTitleBox: !$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].calTitleBox || !$scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry ? null : {
                        width: $scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.width,
                        height: $scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.height,
                        x: $scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.x,
                        y: $scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.geometry.y
                    },
                    calMonthBox: !$scope.currentPage.levels || !$scope.currentPage.levels.level || !$scope.currentPage.levels.level[$scope.template.levelI] || !$scope.currentPage.levels.level[$scope.template.levelI].calMonthBox || !$scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry ? null : {
                        width: $scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.width,
                        height: $scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.height,
                        x: $scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.x,
                        y: $scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.geometry.y
                    }
                }
            }
        }

        function resetBackground() {
            var background = $scope.currentPage.background;
            if (background.resource && background.resource.identifier) {
                var pageW = $scope.currentPage.mediabox.width / $scope.currentPage.proportion;
                var pageH = $scope.currentPage.mediabox.height / $scope.currentPage.proportion;

                var bgW = background.resource.width;
                var bgH = background.resource.height;
                // 高适配
                var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                // 宽适配
                if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                    w = pageW;
                    h = pageW * bgH / bgW;
                    adaptation = 'Width';
                }
                var pro = $scope.currentPage.scale / $scope.currentPage.proportion
                background.offsetx = 0;
                background.offsety = 0;
                background.width = w / pro;
                background.height = h / pro;
                background.resource.width = bgW;
                background.resource.height = bgH;
                background.adaptation = adaptation;
                background.scale = 1;
            }
        }

        //模板宽高onchange
        $scope.updateEditbox = function () {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if (vm.unit == 'mm') {
                currentPage.editbox = {
                    width: MmTurnPx($scope.pageValue.editbox.width),
                    height: MmTurnPx($scope.pageValue.editbox.height),
                    x: MmTurnPx($scope.pageValue.editbox.x),
                    y: MmTurnPx($scope.pageValue.editbox.y)
                }
            }
            if (vm.unit == 'px') {
                currentPage.editbox = {
                    width: $scope.pageValue.editbox.width,
                    height: $scope.pageValue.editbox.height,
                    x: $scope.pageValue.editbox.x,
                    y: $scope.pageValue.editbox.y
                }
            }
            if (vm.unit == 'in') {
                currentPage.editbox = {
                    width: InTurnPx($scope.pageValue.editbox.width),
                    height: InTurnPx($scope.pageValue.editbox.height),
                    x: InTurnPx($scope.pageValue.editbox.x),
                    y: InTurnPx($scope.pageValue.editbox.y)
                }
            }

            $scope.currentPage = currentPage;
        };

        // 背景图和素材的上传
        window.updatePic = function ($event) {
            $scope.$digest();
            var files = $event.target.files;
            var len = $scope.template.bgimgs.bgimg.length;
            vm.uploadType = "BgImg";
            bigSizePics = [];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.size >= 20 * 1024 * 1024) {
                    file.imageSize = Math.ceil(file.size / 1024 / 1024);
                    bigSizePics.push(file);
                } else {
                    getBgImg(files, i, len);
                    if (bigSizePics.length == 0 && i == (files.length - 1)) {
                        vm.picLoading = false;
                    }
                }
            }
            if (bigSizePics.length > 0) {
                thumbnailatorPic();
            }
            // $event.srcElement.value = ""
        };

        function getBgImg(files, i, len) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(files[i]);//创建Image的对象的url
            img.crossOrigin = "Anonymous";
            var arr = [];

            arr = $scope.template.bgimgs.bgimg;
            arr.push({
                'identifier': 'https://pic-modele-dev.momentsgocdn.com/material-9627c267-5653-4ad2-a539-1d5cbe13e820', // 默认七牛占位
                'provider': 'qiniu'
            });

            //$scope.$digest();
            var formData = new FormData();
            formData.append('file', files[i]);
            formData.append('key', 'background-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    arr[parseInt(len + i)].identifier = vm.url + data.key;
                    i++;
                    if (i < files.length) {
                        //getBgImg(files, i, len);
                    }
                    $scope.$apply();
                },
                error: function (request) {
                }
            });

        }

        // 图片上传
        window.updatePic1 = function ($event) {
            $scope.$digest();
            var files = $event.target.files;
            vm.uploadType = "UserImg";
            bigSizePics = [];
            var _index = 0;
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.size >= 20 * 1024 * 1024) {
                    file.imageSize = Math.ceil(file.size / 1024 / 1024);
                    bigSizePics.push(file);
                    _index++
                    if(_index == files.length){
                        $timeout(function () {
                            $event.srcElement.value = ""
                        })
                    }
                } else {
                    getUserImg(files, i,function () {
                        _index++
                        if(_index == files.length){
                            $timeout(function () {
                                $event.srcElement.value = ""
                            })
                        }
                    });
                    if (bigSizePics.length == 0 && i == (files.length - 1)) {
                        vm.picLoading = false;
                    }
                }
            }
            if (bigSizePics.length > 0) {
                thumbnailatorPic();
            }
        };

        function getUserImg(files, i,callback) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(files[i]);//创建Image的对象的url
            img.crossOrigin = "Anonymous";
            var arr = {
                'identifier': '',
                'provider': 'qiniu',
                'fileName': files[i].name,
                'imgSize': '',
                'width': '',
                'height': '',
                'userId': '',
            };
            var formData = new FormData();
            formData.append('file', files[i]);
            formData.append('key', 'gallery-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    arr.identifier = data.key;
                    $.ajax({
                        type: "GET",
                        url: vm.url + data.key + "?imageInfo",
                        success: function (img) {
                            arr.width = img.width;
                            arr.height = img.height;
                            arr.imgSize = img.size;
                            arr.themeClassifyId = vm.imgLabel;
                            Galleries.upload(arr, function (res) {
                                res.identifier = vm.url + res.identifier;
                                vm.userImgAll.unshift(res)
                                i++;
                                if (i < files.length) {
                                    //getUserImg(files, i);
                                } else {
                                    //$scope.$digest();
                                }
                                if(callback){callback()}
                            });
                        },
                        error: function (request) {
                            if(callback){callback()}
                        }
                    });
                },
                error: function (request) {
                    if(callback){callback()}
                }
            });

        }

        vm.showBackgroundsLabel = false;
        function getBackgroundLabel(){
            ThemeClassify.getAllByCondition1({
                items: [{key: "type", op: "=", value: 'BACKGROUND'}, {
                    key: "used",
                    op: "=",
                    value: true
                }], sort: ["seq,asc"]
            }, function (res) {
                vm.backgroundLabels = angular.copy(res);
                vm.backgroundLabels.unshift({
                    id:'',
                    name:'全部'
                });
                $scope.backgroundData(vm.backgroundLabels[0].id)
            });
        }

        $scope.backgroundData = function(id){
            vm.backgroundLabel = id;
            vm.indexNum = 0;
            vm.bgimgs2.bgimg=[];
            backgroundsByCondition();
        }
        function backgroundsByCondition(){
            Backgrounds.byCondition({
                items: [{key: "themeClassify.id", op: "=", value: vm.backgroundLabel}],
                page: vm.indexNum,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (res, headers) {
                vm.totalBgItems = headers('X-Total-Count');
                res.some(function (item, i) {
                    item.identifier = vm.url + item.identifier
                    vm.bgimgs2.bgimg.push(item);
                })
                vm.showBackgrounds = vm.bgimgs2;

                if($scope.template && $scope.template.bgimgs2 && $scope.template.bgimgs2.bgimg){
                    angular.forEach(vm.bgimgs2.bgimg, function (data) {
                        var result = ListService.inList(data, $scope.template.bgimgs2.bgimg, ["identifier"]);
                        if (result) {
                            data.isRecommend = true;
                        }
                    })
                }
            })
        }

        window.updateBackground = function ($event) {
            $scope.$digest();
            vm.uploadType = "BG";
            vm.bgimgs2Tab = 'all';
            var files = $event.target.files;
            var len = 0;
            bigSizePics = [];
            if (vm.showBackgrounds && vm.showBackgrounds.length) {
                len = vm.showBackgrounds.length;
            }
            var _index = 0;
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.size >= 20 * 1024 * 1024) {
                    file.imageSize = Math.ceil(file.size / 1024 / 1024);
                    bigSizePics.push(file);
                    _index++
                    if(_index == files.length){
                        $timeout(function () {
                            $event.srcElement.value = ""
                        })
                    }
                } else {
                    getBackground(files, i, len,function () {
                        _index++
                        if(_index == files.length){
                            $timeout(function () {
                                $event.srcElement.value = ""
                            })
                        }
                    });
                    if (bigSizePics.length == 0 && i == (files.length - 1)) {
                        vm.picLoading = false;
                    }
                }
            }
            if (bigSizePics.length > 0) {
                thumbnailatorPic();
            }
        };

        function getBackground(files, i, len,callback) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(files[i]);//创建Image的对象的url
            img.crossOrigin = "Anonymous";
            var fileName = files[i].name;
            var formData = new FormData();
            formData.append('file', files[i]);
            formData.append('key', 'background-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    Backgrounds.save({
                        identifier: data.key,
                        provider: 'qiniu',
                        name: fileName,
                        type: 'ProductXml',
                        themeClassifyId:vm.backgroundLabel
                    }, function (resp) {
                        resp.identifier = vm.url + resp.identifier;
                        vm.bgimgs2.bgimg.unshift(resp)
                        $("#dragtarget" + parseInt(len + i)).attr('data-mask', data.key);
                        //i++;
                        //if (i < files.length) {
                        //    getBackground(files, i, len);
                        //}
                        vm.showBackgrounds = vm.bgimgs2;
                        if(callback){callback()}
                    },function () {
                        if(callback){callback()}
                    });
                },
                error: function (request) {
                    if(callback){callback()}
                }
            });

        }

        window.doubleClickbgImg = function (e) {
            fn(e);
            var currentPage = $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.background && currentPage.background.resource && currentPage.background.resource.identifier) {
                $scope.cutImage(currentPage, $scope.template.pageI);
            }
            return
        }

        /**
         * 进入图片编辑模式
         */
        $scope.cutImage = function (page, pageI) {
            $scope.w = $('.bg-image' + pageI).width();
            $scope.h = $('.bg-image' + pageI).height();
            var pro = $scope.currentPage.scale / $scope.currentPage.proportion;

            $scope.pageW = $scope.currentPage.mediabox.width / $scope.currentPage.proportion * $scope.currentPage.scale;
            $scope.pageH = $scope.currentPage.mediabox.height / $scope.currentPage.proportion * $scope.currentPage.scale;
            $scope.showCut = true;
            $scope.showImageMenu = false;
            $("#cut-mask").attr('src', '');

            var background = $scope.currentPage.background;
            background.width = $scope.w / pro;
            background.height = $scope.h / pro;

            // 显示区域（包含上/下页按钮）
            var bodyW = $(".c-editor-canvas").width();
            var bodyH = $(".c-editor-canvas").height();

            // 裁切框显示位置
            var left = $scope.l = parseFloat($(".page-item").css('marginLeft'));
            var top = $scope.t = parseFloat($(".page-item").css('marginTop'));
            $("#cut-img").css({left: left + background.offsetx * pro, top: top + background.offsety * pro});

            ImageService.convertImgToBase64('content/images/white.png', function (s) {
                var imagecanvas = $("#mask-canvas")[0];
                var imagecontext = imagecanvas.getContext('2d');
                var newImg = document.createElement('img');
                // newImg.crossOrigin = "Anonymous";
                newImg.src = 'content/images/zhezhao1.png';
                newImg.onload = function () {
                    newImg.onload = null;
                    var masks = document.createElement('img');
                    masks.src = 'content/images/white.png';
                    // masks.crossOrigin = "Anonymous";
                    masks.onload = function () {
                        masks.onload = null;
                        imagecanvas.width = bodyW;
                        imagecanvas.height = bodyH;
                        imagecontext.drawImage(masks, left, top, $scope.pageW, $scope.pageH);
                        imagecontext.globalCompositeOperation = 'source-out';
                        imagecontext.drawImage(newImg, 0, 0, bodyW, bodyH);

                        $("#cut-mask").attr('src', ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                        $scope.$digest();
                    };
                    // masks.src = box.maskUrl;
                };
                newImg.src = 'content/images/zhezhao1.png';
            });
        };

        var drag = false;
        var dragBox = {};
        /**
         * 拖拽编辑的图片
         */
        window.startEdit = function (e) {
            fn(e);

            dragBox.clientX = e.clientX ? e.clientX : 0;
            dragBox.clientY = e.clientY ? e.clientY : 0;
            dragBox.picW = $("#cut-img").width();
            dragBox.picH = $("#cut-img").height();
            var background = $scope.currentPage.background;
            var pro = $scope.currentPage.scale / $scope.currentPage.proportion
            dragBox.coordX = background.offsetx * pro;
            dragBox.coordY = background.offsety * pro;

            drag = true;
            document.addEventListener("mousemove", dragEdit, true);
            document.addEventListener("mouseup", stopEdit, true);
        };

        function dragEdit(e) {
            if (drag) {
                var background = $scope.currentPage.background;
                var x = 0, y = 0;

                //手指离开屏幕
                if (background.adaptation == 'Height') {    //宽图
                    x = (dragBox.coordX + (e.clientX - dragBox.clientX));
                    //向左滑动;        <-----
                    if (-x >= ($scope.w - $scope.pageW)) {
                        x = -($scope.w - $scope.pageW);
                    }
                    // 向右滑动;    ---->
                    if (x >= 0) {
                        x = 0;
                    }
                } else {     //高图
                    y = (dragBox.coordY + (e.clientY - dragBox.clientY));
                    //向上滑动;
                    if (-y >= ($scope.h - $scope.pageH)) {
                        y = -($scope.h - $scope.pageH);
                    }
                    //向下滑动;
                    if (y >= 0) {
                        y = 0;
                    }
                }

                $("#cut-img").css({left: x + $scope.l, top: y + $scope.t});

                var prp = $scope.currentPage.scale / $scope.currentPage.proportion
                background.offsetx = x / prp;
                background.offsety = y / prp;

                $scope.$digest();
                e.stopPropagation();
                e.preventDefault();
                return false;
            }

        };

        function stopEdit() {
            drag = false;
            dragBox = {};
            document.removeEventListener("mousemove", dragEdit, true);
            document.removeEventListener("mouseup", stopEdit, true);
        };

        $scope.chooseColor = function () {
            $scope.chooseBgColor($scope.template.pages.page[$scope.template.pageI].background.color);
        }

        $scope.chooseBgColor = function (color) {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            currentPage.background = {
                resource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                offsetx: 0,
                offsety: 0,
                type: 'Color',
                color: color,
                width: 0,
                height: 0,
                adaptation: null
            }
            $scope.currentPage.lowAccuracy=false;
            $scope.currentPage = currentPage;

            updateHistorys();
        }

        $scope.loadMoreBackgrounds = function () {
            if (vm.bgimgs2.bgimg.length == vm.totalBgItems) {
                MessageService.error("已全部加载完成");
                return
            }
            vm.indexNum++
            backgroundsByCondition();
        }

        $scope.loadMoreClipart = function () {
            if (vm.allCliparts.clipart.length >= vm.totalClipartItems) {
                MessageService.error("已全部加载完成");
                return
            } else if (vm.allCliparts.clipart.length == 0) {
                vm.clipartIndexNum = -1;
            }
            ++vm.clipartIndexNum;
            clickClipArtLabels(vm.allCliparts);
        }


        $scope.recommendBgImg2 = function (attr, arr) {
            var bgimgs = $scope.template.bgimgs2.bgimg;
            // 全部素材nav
            if (vm.bgimgs2Tab == 'all') {
                if (arr.isRecommend) {
                    bgimgs.some(function (item, i) {
                        if (item.identifier == arr.identifier) {
                            arr.isRecommend = false;
                            bgimgs.splice(i, 1);
                            return
                        }
                    })
                    return
                }
                arr.isRecommend = true;
                bgimgs.push(arr);
            }else{
                for(var j=0; j<vm.bgimgs2.bgimg.length; j++){
                    if(vm.bgimgs2.bgimg[j].identifier == arr.identifier){
                        vm.bgimgs2.bgimg[j].isRecommend = false;
                        break
                    }
                }
                for(var i=0; i<bgimgs.length; i++){
                    if(bgimgs[i].identifier == arr.identifier){
                        arr.isRecommend = false;
                        bgimgs.splice(i, 1);
                        break
                    }
                }
                $scope.switchBgTab();
            }
        }

        $scope.switchBgTab = function (attr) {
            vm.bgimgs2Tab = attr;
            if (attr == 'all') {
                getBackgroundLabel();
            } else {
                vm.showBackgrounds = $scope.template.bgimgs2;
            }
        }

        $scope.switchMaskTab = function (attr) {
            vm.maskTab = attr;
            vm.showMasks = {mask: []};
            $scope.masklabels = [];
            $scope.masklabelChooseId = null;
            vm.maskIndexNum = 0;
            if (attr == 'all') {
                ThemeClassify.getAllByCondition4({
                    type: "MASK"
                }, function (res) {
                    if (res.status == 200) {
                        vm.maskIndexNum = 0;
                        $scope.masklabels = angular.copy(res.message);
                        if (!$scope.masklabels.length) {
                            return
                        }
                        $scope.masklabelChooseId = $scope.masklabels[0].id || null;
                        var items = [
                            {key: "themeClassify.id", op: "=", value: $scope.masklabelChooseId},
                            {key: "opened", op: "=", value: "true"},
                            {key: "type", op: "=", value: "SYSTEM"}
                            ];
                        clickMaskLabels(items, vm.showMasks);
                    } else {
                        MessageService.error(res.message)
                    }
                });
            } else if(attr == 'recommend') {
                vm.showMasks = $scope.template.masks;
            } else{
                ThemeClassify.getAllByCondition1({
                    items:[{key: "type", value: "MASK", op: "="}, {key: "used", value: "true", op: "="}]
                }, function (res) {
                        vm.maskIndexNum = 0;
                        $scope.masklabels = angular.copy(res);
                        $scope.masklabels.unshift({
                            name:'全部',
                            id:null
                        })
                        $scope.masklabelChooseId = $scope.masklabels[0].id || null;
                        var items = [
                            {key: "themeClassify.id", op: "=", value: $scope.masklabelChooseId},
                            {key: "type", op: "=", value: "User"}
                        ];
                        clickMaskLabels(items, vm.showMasks);
                });
            }
        }
        $scope.loadMoreMask = function () {
            if (vm.maskTab == 'all') {
                if (vm.showMasks.mask.length == vm.totalMaskItems) {
                    MessageService.error("已全部加载完成");
                    return
                }
                vm.maskIndexNum++;
                var items = [{key: "themeClassify.id", op: "=", value: $scope.masklabelChooseId}, {
                    key: "opened",
                    op: "=",
                    value: "true"
                }];
                clickMaskLabels(items, vm.showMasks);
            } else if (vm.maskTab == 'my') {
                if (vm.showMasks.mask.length == vm.totalMaskItems) {
                    MessageService.error("已全部加载完成");
                    return
                }

                var items = [
                    {key: "themeClassify.id", op: "=", value: $scope.masklabelChooseId},
                    {key: "opened", op: "=", value: "true"},
                    {key: "type", op: "=", value: "USER"}
                ];
                ++vm.maskIndexNum;
                clickMaskLabels(items, vm.showMasks);
            }
        }

        $scope.selectMaskLabel = function (label, index) {
            $scope.masklabelChooseId = label.id;
            vm.maskIndexNum=0;
            vm.showMasks = {mask: []};
            if (vm.maskTab == 'all') {
                var items = [
                    {key: "themeClassify.id", op: "=", value: $scope.masklabelChooseId},
                    {key: "opened", op: "=", value: "true"},
                    {key: "type", op: "=", value: "SYSTEM"}];
                clickMaskLabels(items, vm.showMasks);
            } else if (vm.maskTab == 'my') {
                var items = [
                    {key: "themeClassify.id", op: "=", value: $scope.masklabelChooseId},
                    {key: "type", op: "=", value: "User"}
                ];
                clickMaskLabels(items, vm.showMasks);
            }
        };

        function clickMaskLabels(items, masks, attr) {
            SourceMaterial.byCondition({
                items: items,
                page: vm.maskIndexNum,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (resp, headers) {
                vm.totalMaskItems = headers('X-Total-Count');
                angular.forEach(resp, function (data, i) {
                    var arr = {
                        resource: {
                            identifier: vm.url + data.identifier,
                            provider: data.provider,
                        },
                        type: data.type,
                        id: data.id,
                        isRecommend: false,
                        identifier: vm.url + data.identifier,
                        provider: 'qiniu',
                        bgImg: '',
                    }
                    if($scope.template&&$scope.template.masks&&$scope.template.masks.mask.length>0){
                        for(var i=0; i<$scope.template.masks.mask.length; i++){
                            if($scope.template.masks.mask[i].id == arr.id){
                                arr.isRecommend = true;
                                break
                            }
                        }
                    }
                    masks.mask.push(arr);
                })
            })
        }

        $scope.recommendMask = function (attr, arr) {
            var masks = $scope.template.masks.mask;
            if (arr.isRecommend) {
                var _index = -1;
                angular.forEach(masks, function (item, i) {
                    if (item.id == arr.id) {
                        arr.isRecommend = false;
                        _index = i;
                    }
                })
                if(_index>-1){
                    masks.splice(_index, 1);
                }
                return
            }
            arr.isRecommend = true;
            masks.push(arr);
        }

        window.updateArts = function ($event) {
            $scope.$digest();
            var files = $event.target.files;
            var _files = [];
            bigSizePics = [];
            vm.uploadType = "CLIPART";
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.size >= 20 * 1024 * 1024) {
                    file.imageSize = Math.ceil(file.size / 1024 / 1024);
                    bigSizePics.push(file);
                }else {
                    _files.push(file)
                }
            }
            if(_files.length == 0){
                MessageService.error("请选择正确的图片");
                return
            }
            if (bigSizePics.length > 0) {
                thumbnailatorPic();
            }
            var len = vm.showCliparts.clipart.length;
            getArtImg(_files, 0, len,$event);

            vm.clipartTab = 'all';
        };

        function getArtImg(files, i, len,$event) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(files[i]);//创建Image的对象的url
            img.crossOrigin = "Anonymous";
            var formData = new FormData();
            formData.append('file', files[i]);
            formData.append('key', 'clipArt-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    ClipArts.createOne({
                        identifier: data.key,
                        name:files[i].name,
                        provider: 'qiniu',
                        themeClassifyId: $scope.artlabelChooseId
                    }, function (resp) {
                        vm.showCliparts.clipart.unshift({
                            'bgImg': img.src,
                            resource: {
                                identifier: vm.url + data.key,
                                provider: 'qiniu'
                            },
                            type: resp.type,
                            id: resp.id
                        })
                        $("#dragtarget" + parseInt(len + i)).attr('data-mask', data.key);
                        i++;
                        if (i < files.length) {
                            getArtImg(files, i, len,$event);
                        } else {
                            vm.totalClipartItems = vm.totalClipartItems - 0 + i;
                            $timeout(function () {
                                $event.srcElement.value = ""
                            })
                        }
                    });
                },
                error: function (request) {
                }
            });

        }

        $scope.recommendArt = function (attr, arr) {
            var cliparts = $scope.template.cliparts.clipart || [];

            // 全部素材nav
            if (vm.clipartTab == 'all') {
                if (arr.isRecommend) {
                    cliparts.some(function (item, i) {
                        if (item.resource.identifier == arr.resource.identifier) {
                            arr.isRecommend = false;
                            cliparts.splice(i, 1);
                            return
                        }
                    })
                    return
                }
                arr.isRecommend = true;
                cliparts.push(arr);
                return
            }

            // 推荐素材nav
            cliparts.some(function (item, i) {
                if (item.resource.identifier == arr.resource.identifier) {
                    arr.isRecommend = false;
                    cliparts.splice(i, 1);
                    return
                }
            })
            vm.allCliparts.clipart.some(function (item) {
                if (item.resource.identifier == arr.resource.identifier) {
                    item.isRecommend = false;
                    return
                }
            })

        }

        $scope.deleteLeftPanelPic = function (index, e) {
            fn(e);
            $(".art-click").hide();
            if ($scope.selPanel == 'page') {
                var id = vm.bgimgs2.bgimg[index].id;
                vm.bgimgs2.bgimg.splice(index, 1);
                vm.showBackgrounds = vm.bgimgs2;
                Backgrounds.delete({id: id});
                if($scope.template.bgimgs2&&$scope.template.bgimgs2.bgimg){
                    var _index = -1;
                    for(var i=0; i<$scope.template.bgimgs2.bgimg.length; i++){
                        if($scope.template.bgimgs2.bgimg[i].id == id){
                            _index = i;
                            break
                        }
                    }
                    if(_index > -1){
                        $scope.template.bgimgs2.bgimg.splice(_index,1)
                    }
                }
                return

            }
            if ($scope.selPanel == 'design') {
                $scope.template.bgimgs.bgimg.splice(index, 1);
                return

            } else if ($scope.selPanel == 'mask') {
                if(vm.showMasks.mask[index].isRecommend){
                    $scope.recommendMask('',vm.showMasks.mask[index])
                }
                var id = vm.showMasks.mask[index].id;
                vm.showMasks.mask.splice(index, 1);
                SourceMaterial.delete({id: id});
                return
            } else {
                if(vm.showCliparts.clipart[index].isRecommend){
                    $scope.recommendArt('clipArts.clipArt',vm.showCliparts.clipart[index])
                }
                var id = vm.showCliparts.clipart[index].id;
                vm.showCliparts.clipart.splice(index, 1);
                ClipArts.delete({id: id});
            }
        };

        function setOriginalWaH(width, height, i) {
            var currentPage = $scope.template.pages.page[i];
            var w = width;
            var h = height;

            originalScale = $con.width() * 0.8 / ($con.height() * 0.8);

            if (originalScale < width / height) {
                //宽定，高不定
                width = $con.width() * 0.8;
                height = $con.width() * 0.8 * h / w;
            } else {
                //高定，宽不动
                height = $con.height() * 0.8;
                width = $con.height() * 0.8 * w / h;
            }
            currentPage.width = width;
            currentPage.height = height;
            currentPage.proportion = (w / width);
            currentPage.proportionText = width / (PxTurnMm(w) / 25.4 * vm.windowDpiW);

            var pageNodeWidth = currentPage.mediabox.width / currentPage.proportion * 98 / (currentPage.mediabox.height / currentPage.proportion)

            currentPage.proportionTextSmalll = pageNodeWidth / (PxTurnMm(w) / 25.4 * vm.windowDpiW);
            currentPage.proportion1 = currentPage.width*currentPage.scale/currentPage.custom.width;
            if(currentPage.scene){
                currentPage.minScale = 100/currentPage.scene.geometry.height;
            }else{
                currentPage.minScale = 100/currentPage.mediabox.height;
            }
            $scope.currentPage = currentPage;
            updateMeasureRuler();
        }

        // 跨页
        vm.spreadSize = false;
        $scope.pageIsSpread = function (flag) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if (flag) {
                if(currentPage.spread){
                    return
                }
                currentPage.spread = true;
                if (currentPage.type == 'Cover' || currentPage.type == 'BackCover') {
                    currentPage.type = undefined;
                }
            } else {
                if(!currentPage.spread){
                    return
                }
                currentPage.spread = false;
                if (currentPage.type == 'FrontBack') {
                    currentPage.type = undefined;
                }
            }
            if(vm.page.productType == 'BOOK' && $scope.currentPage.config.partid == 'P' && $scope.pageSeq($scope.currentPage) == 1 && !$scope.currentPage.spread){
                $scope.template.config.innerOffset = 0;vm.operation = true;
            }
            if(vm.spreadSize){
                var part = angular.copy($scope.template.config.parts.list[getPartIndex()]);
                var scaleW = 0.5;
                if(currentPage.spread){
                    scaleW = 2;
                }
                var scaleH = 1;
                tempPageInfo1(part, currentPage,i,scaleW,scaleH,function () {
                    if (!currentPage.backup) {
                        loadPageToPx(currentPage, $scope.template.pageI, function () {
                            for(var i=0; i<$scope.template.pages.page.length; i++){
                                if($scope.currentPage.uuid == $scope.template.pages.page[i].uuid){
                                    $scope.selectPages(i);
                                    break;
                                }
                            }
                            updateHistorys();
                        });
                    }
                },true,false)
            }
        };

        // 可变数据
        $scope.setVariable = function (flag) {
            var i = $scope.template.pageI;
            var level = $scope.template.pages.page[i].levels.level[$scope.template.levelI]
            if (flag) {
                level.variable = true;
            } else {
                level.variable = false;
            }
        };

        $scope.pageIsBackup = function (flag) {
            var i = $scope.template.pageI;
            $scope.lastPage = $scope.template.pages.page[i];
            var backupPage = angular.copy($scope.template.pages.page[i]);
            if(backupPage.scene){
                backupPage.isScene = true;
                $scope.lastPage.isScene = true;
            }
            backupPage.backup = true;
            backupPage.uuid = UidService.get();
            backupPage.type = null;
            $scope.template.backupPages.list.push(backupPage);


            $timeout(function () {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], backupPage, 'cover', function () {
                    updateHistorys();
                });
                updateMeasureRuler();
            })

        };

        function pageAnimate() {
            $(".page-item-cover").show()
            setTimeout(function () {
                $(".page-item-cover").addClass('cover-move')
                $(".content-page-cover").addClass('cover-scale')
            })

            setTimeout(function () {
                $(".page-item-cover").hide().removeClass('cover-move')
                $(".content-page-cover").removeClass('cover-scale')
            }, 1200)
        }

        $scope.deleteBackup = function (index) {
            var _list = angular.copy($scope.template.backupPages.list);
            $scope.template.backupPages.list = [];
            $timeout(function () {
                _list.splice(index, 1);
                $scope.template.backupPages.list = _list;
            },300)
        }

        // 备用页替换
        $scope.replaceBackupPage = function (e, item) {
            if(vm.isSkip || vm.textEditor){return}
            if(item.config.partid != $scope.currentPage.config.partid){
                MessageService.error("请选择当前部件的备用页");
                return;
            }
            monthIndexSet($scope.template.pages.page[$scope.template.pageI],function () {
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'应用本页后，将会覆盖原设计本页的所有现有内容哦。'
                },function(){
                    vm.isSkip = true;
                    fn(e);
                    var i = $scope.template.pageI;
                    var seq = $scope.currentPage.seq;
                    var page = angular.copy(item);
                    if(page.scene){
                        page.isScene = true;
                    }
                    page.uuid = UidService.get();
                    page.config.monthIndex = $scope.template.pages.page[i].config.monthIndex;
                    page.levels.level.forEach(function (level) {
                        if (level.calMonthBox && level.calMonthBox.geometry) {
                            var calMonthBox = level.calMonthBox;
                            calMonthBox.current = 'calMonthBox';
                            calMonthBox.obj = null;
                            calMonthBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                            calMonthBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                        }
                        if (level.calTitleBox && level.calTitleBox.geometry) {
                            var calTitleBox = level.calTitleBox;
                            calTitleBox.current = 'calTitleBox';
                            calTitleBox.obj = null;
                            calTitleBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                            calTitleBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                        }
                    })
                    $scope.currentPage = null;
                    $timeout(function () {
                        if (!page.loaded) {
                            loadPageToPx(page, i, function (_page) {
                                var p = _page;
                                p.seq = seq;
                                p.backup = false;
                                $scope.currentPage = $scope.template.pages.page[i] = p;
                                $scope.lastPage = $scope.template.pages.page[i];
                                setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, i);
                                updateHistorys();
                                loadAttribute();
                                pageSeq($scope.template.pages.page, UidService, function (page) {
                                    $timeout(function () {
                                        $scope.template.pages.page = page;
                                    }, 100);
                                });
                                // 检查素材精度
                                $timeout(function () {
                                    currentPageElement();
                                }, 2000);
                                vm.isSkip = false;
                            });
                        } else {
                            var p = angular.copy(page);
                            p.seq = seq
                            p.backup = false;
                            $scope.currentPage = $scope.template.pages.page[i] = p;
                            $scope.lastPage = $scope.template.pages.page[i];
                            setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, i);
                            updateHistorys();
                            loadAttribute();
                            pageSeq($scope.template.pages.page, UidService, function (page) {
                                $timeout(function () {
                                    $scope.template.pages.page = page;
                                }, 100);
                            });
                            $timeout(function () {
                                $scope.$digest();
                            }, 100);
                            // 检查素材精度
                            $timeout(function () {
                                currentPageElement();
                            }, 2000);
                            vm.isSkip = false;
                        }
                    })
                });
            })
        };

        $scope.pageIsReplace = function (flag) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if (flag) {
                currentPage.unreplace = false;
            } else {
                currentPage.unreplace = true;
            }
            updateHistorys();
        };
        $scope.onDragComplete = function (index, obj, e) {
            vm.dragIndex = index;
        };
        $scope.onDropComplete = function (index, obj, e) {
            // var otherObj = angular.copy($scope.template.pages.page[index]);
            var otherObj1 = angular.copy($scope.template.pages.page[vm.dragIndex]);
            // $scope.template.pages.page[index] = angular.copy(otherObj1);
            // $scope.template.pages.page[vm.dragIndex] = angular.copy(otherObj);
            var pages = angular.copy($scope.template.pages.page)
            pages.splice(vm.dragIndex,1)
            pages.splice(index,0,otherObj1)
            $scope.template.pages.page = angular.copy(pages);
            pageSeq($scope.template.pages.page, UidService, function (page) {
                $scope.template.pages.page = page;
                $scope.$apply()
            })
        }
        $scope.attributeTab = function (item) {
            vm.attributeTab = item;
            if(item == 'coverage'){
                pageListDrag1()
            }
        }
        //页面元素拖拽
        var sort1 = null;
        var sortArray1 = [];
        function pageListDrag1() {
            $timeout(function () {
                vm.sortingLevel = angular.copy($scope.currentPage.levels.level);
                sort1 = new Sortable(document.getElementById('pageList1'), {
                    swap: false,
                    filter: '.filtered',
                    cancel: ".notDrag",
                    preventOnFilter: true,
                    animation: 150,
                    onEnd: function (/**Event*/evt) {
                        vm.operation = true;
                        sortArray1 = [];
                        angular.forEach($('#pageList1').find(".sortingPageView"), function (item, index) {
                            var page = item.getAttribute("data-page");
                            sortArray1.push(JSON.parse(page));
                        });
                        if (sortArray1 && sortArray1.length > 0) {
                            $scope.currentPage.levels.level = [];
                            $timeout(function () {
                                $scope.currentPage.levels.level = angular.copy(sortArray1.reverse());
                                $scope.currentPage.levels.level.forEach(function (value, index) {
                                    value.seq = index + 1;
                                })
                                sortArray1 = [];
                                $scope.template.levelI = null;
                            })
                        }
                    }
                });
            })
        }

        // 设置封面封底
        $scope.pageIsCover = function (attr) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if (currentPage.type && currentPage.type == attr) {
                currentPage.type = null;
            } else {
                angular.forEach($scope.template.pages.page, function (p) {
                    if (attr == 'Cover') {
                        if (p.type == 'Cover' || p.type == 'FrontBack') {
                            p.type = null;
                        }
                    }
                    if (attr == 'BackCover') {
                        if (p.type == 'BackCover' || p.type == 'FrontBack') {
                            p.type = null;
                        }
                    }
                    if (attr == 'FrontBack') {
                        p.type = null;
                    }
                })
                if (attr == 'Cover') {
                    currentPage.type = attr;
                    var page = $scope.template.pages.page.splice(i, 1);
                    $scope.template.pages.page.unshift(page[0]);
                    $scope.template.pageI = 0;
                } else if (attr == 'BackCover') {
                    currentPage.type = attr;
                    var page = $scope.template.pages.page.splice(i, 1);
                    $scope.template.pages.page.push(page[0]);
                    $scope.template.pageI = $scope.template.pages.page.length - 1;
                } else if (attr == 'FrontBack') {
                    currentPage.type = attr;
                    var page = $scope.template.pages.page.splice(i, 1);
                    $scope.template.pages.page.unshift(page[0]);
                    $scope.template.pageI = 0;
                    $scope.pageIsSpread(1)
                }
            }

            $scope.template.pages.page.forEach(function (page, index) {
                page.seq = index + 1
            });
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];

            updateHistorys();
        }

        $scope.selectSceneImgDblclick = function (e) {
            $scope.currentPage.isScene = $scope.template.pages.page[$scope.template.pageI].isScene = false;
            updateMeasureRuler();
        }

        // 选择场景
        $scope.selectScene = function () {
            var _page = $scope.template.pages.page[$scope.template.pageI];
            $scope.currentPage = _page;
        }

        function setMargin() {
            var page0 = angular.copy($scope.template.pages.page[$scope.template.pageI]);
            if (page0.scene && page0.scene.imagebox) {
                var w0 = page0.mediabox.width / page0.proportion * page0.scale,
                    h0 = page0.mediabox.height / page0.proportion * page0.scale,
                    w1 = $scope.currentPage.scene.imagebox.geometry.width * $scope.currentPage.scene.scale / $scope.currentPage.scene.proportion,
                    h1 = $scope.currentPage.scene.imagebox.geometry.height * $scope.currentPage.scene.scale / $scope.currentPage.scene.proportion;

                if ((w0 <= h0 || w1 <= h1) && (w0 / h0 < w1 / h1) || w0 >= h0 && w1 >= h1 && (w0 / h0 < w1 / h1)) {
                    $scope.imageboxConH = h1;
                    $scope.imageboxConW = h1 * w0 / h0;
                    $scope.proportion = $scope.imageboxConW / w0;
                } else {
                    $scope.imageboxConW = w1;
                    $scope.imageboxConH = w1 * h0 / w0;
                    $scope.proportion = $scope.imageboxConH / h0;
                }

                $scope.marginLeft = (w1 - $scope.imageboxConW) / 2;
                $scope.marginTop = (h1 - $scope.imageboxConH) / 2;
            }


            if(!vm.thirdparty){
                Catalog.getAll({
                    items: [{key: "type", op: "=", value: "User"}, {
                        key: "catalogType",
                        op: "=",
                        value: "LAYOUT"
                    }, {key: "usable", op: "=", value: true}],
                    sort: ["seq,asc"]
                }, function (res) {
                    vm.catalogList = angular.copy(res);
                    if (vm.catalogList.length > 0) {
                        vm.catalogId = vm.catalogList[0].id;
                    }
                    groupPicLayoutXmls();
                });
            }
        }

        // 增加样例图
        $scope.addSimple = function () {
            var i = $scope.template.pageI;
            $scope.currentPage = $scope.template.pages.page[i];
            $scope.lastPage = $scope.template.pages.page[i];

            setTimeout(function () {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage, 'addSimple');
            })

        }

        /* ==========================================================================
         左边侧边栏拖动事件
         ========================================================================== */
        $timeout(function () {
            var content = $(".content-container")[0];
            content.ondragenter = ignoreDrag;
            // 拖动文件的鼠标指针位置放置区之上时发生
            content.ondragover = ignoreDrag;
            content.ondrop = drop;


            // 场景
            var content2 = $(".editing1")[0];
            content2.ondragenter = ignoreDrag;
            // 拖动文件的鼠标指针位置放置区之上时发生
            content2.ondragover = ignoreDrag;
            content2.ondrop = drop;
        })

        function ignoreDrag(e) {
            // 确保其他元素不会取得该事件
            e.stopPropagation();
            e.preventDefault();
        }

        function drop(e) {
            ignoreDrag(e);
            var currentPage = $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            var data = e.dataTransfer.getData("Text");
            var s = $('#' + data)[0].src;
            var mask = $('#' + data)[0].getAttribute('data-mask');
            var dataIndex = $('#' + data)[0].getAttribute('data-index');
            var s1 = s.split(/\//)[3];


            var offsetX = e.clientX - $("#pageCenterSize").offset().left;
            var offsetY = e.clientY - $("#pageCenterSize").offset().top;
            // 场景
            if (currentPage.isScene) {
                offsetX -= parseFloat($($(".page-item")[1]).css('margin-left'));
                offsetY -= parseFloat($($(".page-item")[1]).css('margin-top'))
            } else {
                offsetX -= parseFloat($($(".page-item")[0]).css('margin-left'));
                offsetY -= parseFloat($($(".page-item")[0]).css('margin-top'))
            }
            if (data.indexOf('dragtargetPhoto') != '-1') {
                var index = null;
                if (e.target.dataset.index) {
                    index = e.target.dataset.index;
                } else {
                    // 判读是否是照片
                    var mask = {
                        identifier: null,
                        provider: 'qiniu',
                        resource: {
                            identifier: '',
                            provider: 'qiniu'
                        }
                    }
                    addPlacement(e, mask, false, offsetX, offsetY);
                    index = $scope.template.levelI;
                }
                var imagebox = currentPage.levels.level[index].imagebox;
                s1 = $('#' + data).attr('data-mask');
                if (imagebox && imagebox.current) {
                    var img = imagebox.imgUrl = s1 + '?imageMogr2/auto-orient/thumbnail/!40p';
                    var newImg = document.createElement('img');
                    newImg.crossOrigin = "Anonymous";
                    newImg.src = img;
                    newImg.onload = function () {
                        newImg.onload = null;
                        var _image = getImageSource(newImg, imagebox, s1, currentPage);
                        imagebox.image = _image;
                        // imageboxResourceCanvas(imagebox);
                        $scope.selectImg(e, index, imagebox);
                        $scope.pxInsufficient(imagebox, 'imagebox');
                        updateHistorys();
                        $scope.$digest();
                    };
                }else{
                    updateHistorys();
                }
            } else if (data.indexOf('dragtargetMask') != '-1') {
                if (data.indexOf('Round') > '-1') {
                    addPlacement1(e, mask, false, offsetX, offsetY, 'Ellipse');
                } else if (data.indexOf('Rectangle') > '-1') {
                    addPlacement1(e, mask, false, offsetX, offsetY, 'Rectangle');
                } else if (data.indexOf('FrameConfig') > '-1') {
                    var index = null;
                    if (e.target.dataset.index) {
                        index = e.target.dataset.index;
                        var imagebox = currentPage.levels.level[index].imagebox;
                        var borderList = vm.frameConfigs[$('#' + data).attr('data-index')];
                        imagebox.border = {
                            shape: borderList.shape || 'Rectangle',
                            radius: {
                                lt: 0,
                                rt: 0,
                                rb: 0,
                                lb: 0,
                                all: 0,
                                filletType: 'Fixed'
                            },
                            "edge": {
                                top: borderList.marginTop,
                                right: borderList.marginRight,
                                bottom: borderList.marginBottom,
                                left: borderList.marginLeft
                            },
                            lineWidth: 0,
                            type: "Pic",
                            resource: {
                                "identifier": borderList.img
                            },
                            color: '',
                        }
                        $scope.$apply();
                    } else {
                        addPlacement1(e, mask, false, offsetX, offsetY, 'FrameConfig', data, function () {
                            var index = $scope.template.levelI;
                            var imagebox = currentPage.levels.level[index].imagebox;
                            if (imagebox.current) {
                                if (imagebox.mask && imagebox.mask.resource && imagebox.mask.resource.identifier && s1) {
                                    imagebox.mask.resource.identifier = s1;
                                    imagebox.mask.resource.provider = 'qiniu';
                                    // maskScale(e, imagebox, index, s1);
                                }
                            }
                        });
                    }
                    return
                } else {
                    // 判读是否是占位框
                    s1 = $('#' + data).attr('data-mask');
                    var mask = {
                        identifier: s1,
                        provider: 'qiniu',
                        resource: {
                            identifier: s1,
                            provider: 'qiniu'
                        }
                    }
                    addPlacement(e, mask, false, offsetX, offsetY);
                }
                var index = $scope.template.levelI;
                var imagebox = currentPage.levels.level[index].imagebox;
                if (imagebox.current) {
                    if (imagebox.mask && imagebox.mask.resource && imagebox.mask.resource.identifier && s1) {
                        imagebox.mask.resource.identifier = s1;
                        imagebox.mask.resource.provider = 'qiniu';
                        // maskScale(e, imagebox, index, s1);
                    }
                    updateHistorys();
                    $scope.$digest();
                }else{
                    updateHistorys();
                }
            } else if (data.indexOf('dragtargetClipArt') != '-1') {
                //判读是否是素材
                var identifier = $('#' + data).attr('data-art');
                $scope.addArt(identifier, offsetX, offsetY);

            } else {
                var bgimg2Arr = vm.showBackgrounds.bgimg[dataIndex];

                var pageW = currentPage.mediabox.width / currentPage.proportion;
                var pageH = currentPage.mediabox.height / currentPage.proportion;
                currentPage.hasBgImg = true;

                $http({url: bgimg2Arr.identifier + '?imageInfo'}).success(function (data) {
                    var bgW = data.width;
                    var bgH = data.height;
                    if (data.orientation == "Left-bottom" || data.orientation == "Right-top" || data.orientation == "Left-top" || data.orientation == "Right-bottom") {
                        bgW = data.height;
                        bgH = data.width;
                    }

                    // 高适配
                    var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                    // 宽适配
                    if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                        w = pageW;
                        h = pageW * bgH / bgW;
                        adaptation = 'Width';
                    }
                    var pro = $scope.currentPage.scale / $scope.currentPage.proportion
                    $scope.currentPage.background = {
                        resource: {
                            identifier: bgimg2Arr.identifier,
                            provider: 'qiniu',
                            width: bgW,
                            height: bgH
                        },
                        offsetx: 0,
                        offsety: 0,
                        type: 'Pic',
                        color: '',
                        width: w / pro,
                        height: h / pro,
                        adaptation: adaptation,
                        scale: 1
                    }
                    updateHistorys();

                    $scope.pxInsufficient($scope.currentPage, 'background');
                }).error(function (error) {
                    MessageService.error("获取图片信息失败");
                });
            }
        }


        function getImageSource(newImg, imagebox, s1, currentPage) {
            var width = newImg.width;
            var height = newImg.height;

            var _w = imagebox.geometry.width;
            var _h = imagebox.geometry.height;
            var bgImgR = width / height;
            var bgImgR1 = _w / _h;
            var _image = {
                resource: {
                    provider: 'qiniu',
                    identifier: s1,
                },
                angle: 0,
                scale: 1
            }
            if (bgImgR >= bgImgR1) {
                _image.adaptation = 'Height';
                _image.height = _h;
                _image.width = _h / height * width;
                _image.offsetx = -(_image.width - imagebox.geometry.width) / 2;
                _image.offsety = 0;
            } else {
                _image.adaptation = 'Width';
                _image.width = _w;
                _image.height = _w / width * height;
                _image.offsetx = 0;
                _image.offsety = -(_image.height - imagebox.geometry.height) / 2;
            }
            _image.imgWidth = width;
            _image.imgHeight = height;

            return _image
        }

        function getBackgroundSource(res, pageW, pageH, arr) {
            var deferred = $q.defer();
            $http({url: res.identifier + '?imageInfo'}).success(function (data) {
                var bgW = data.width,
                    bgH = data.height;
                if (data.orientation == "Left-bottom" || data.orientation == "Right-top" || data.orientation == "Left-top" || data.orientation == "Right-bottom") {
                    bgW = data.height;
                    bgH = data.width;
                }
                // 高适配
                var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                // 宽适配
                if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                    w = pageW;
                    h = pageW * bgH / bgW;
                    adaptation = 'Width';
                }
                arr.resource.width = bgW;
                arr.resource.height = bgH;
                arr.width = w;
                arr.height = h;
                arr.type = arr.type;
                arr.offsetx = arr.offsetx
                arr.offsety = arr.offsety
                arr.adaptation = adaptation;
                deferred.resolve("ready");
            }).error(function (error) {
                MessageService.error("获取图片信息失败");
                deferred.resolve("error");
            });
            return deferred.promise;
        }

        function getBackgroundSource1(res, pageW, pageH, arr) {
            var deferred = $q.defer();
            var img = new Image();
            img.src = res.identifier + '?imageMogr2/auto-orient';
            img.onload = function () {
                var bgW = img.width,
                    bgH = img.height;

                // 高适配
                var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                // 宽适配
                if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                    w = pageW;
                    h = pageW * bgH / bgW;
                    adaptation = 'Width';
                }
                arr.resource.width = bgW;
                arr.resource.height = bgH;
                arr.width = w;
                arr.height = h;
                arr.type = 'Pic';
                arr.adaptation = adaptation;
                $scope.$digest();
                deferred.resolve("ready");
            }
            return deferred.promise;
        }

        function maskScale(e, imageboxs, index, identifier, callback) {
            var imagebox = angular.copy(imageboxs);
            imagebox.mask.resource.identifier = identifier;

            var img = document.getElementsByClassName('page-item')[0].getElementsByClassName('mask')[index];
            var imgUrl = 'content/images/mask_souce2.png';
            if (imagebox.image && imagebox.image.resource && imagebox.image.resource.identifier) {
                imgUrl = imagebox.image.resource.identifier
            }

            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = imgUrl;
            newImg.onload = function () {
                newImg.onload = null;
                var width = newImg.width;
                var height = newImg.height;
                var scaleW = 1, scaleH = 1;
                if (imagebox.image && imagebox.image.resource && imagebox.image.resource.identifier) {
                    var _image = getImageSource(newImg, imagebox, identifier);
                    width = imagebox.geometry.width;
                    height = imagebox.geometry.height;

                    scaleW = _image.width / newImg.width;
                    scaleH = _image.height / newImg.height;
                    imagebox.image.offsetx = 0;
                    imagebox.image.offsety = 0;
                }
                if (img) {
                    img.setAttribute('data-mask', identifier);
                }
                var masks = document.createElement('img');
                masks.src = identifier;
                masks.crossOrigin = "anonymous";
                masks.onload = function () {
                    masks.onload = null;
                    imagecanvas.width = width;
                    imagecanvas.height = height;
                    imagecontext.drawImage(masks, 0, 0, width, height);
                    imagecontext.globalCompositeOperation = 'source-in';
                    imagecontext.scale(scaleW, scaleH);
                    imagecontext.drawImage(newImg, 0, 0);
                    var pic = imagecanvas.toDataURL();
                    imagebox.bgImg = ImageService.dataURLtoBlob(pic);
                    imageboxs.bgImg = ImageService.dataURLtoBlob(pic);
                    imagebox.mask.resource.identifier = identifier;
                    imagebox.mask.resource.provider = "qiniu";
                    imagebox.maskUrl = identifier;
                    $scope.selectImg(e, index, imagebox);
                    updateHistorys();
                    $scope.$digest();

                    if (callback) {
                        callback();
                    }
                };
                masks.src = identifier;
            };

        }

        window.doubleClickImg = function (e) {
            fn(e);
            $scope.hasText = false;
            $scope.hasImgUrl = true;
            var targ = e.target ? e.target : e.srcElement;
            var imgIndex = targ.dataset.index || $scope.template.levelI;
            $scope.template.levelI = imgIndex;
            var currentImg = $scope.currentPage.levels.level[imgIndex].imagebox;
            if (currentImg.image && currentImg.image.resource && currentImg.image.resource.identifier) {
                $scope.cutImagebox();
            }
        }


        /**
         * 进入图片编辑模式
         */
        $scope.cutImagebox = function () {
            var pro = $scope.currentPage.scale / $scope.currentPage.proportion;
            var box = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;
            $scope.hasText = false;
            $scope.hasImgUrl = true;
            $scope.angle = box.image.angle;
            $scope.scale = box.image.scale;
            $scope.currentImage = box;
            if (!(box.image && box.image.resource && box.image.resource.identifier)) {
                return
            }

            $scope.imgUrl = box.imgUrl = box.image.resource.identifier + '?imageMogr2/auto-orient/thumbnail/!40p';
            $scope.imgBokehtype = box.bokehtype;
            $scope.showImageCut = true;
            $scope.showImageMenu = false;
            $("#cutImagebox-mask").attr('src', '');

            // 显示区域（包含上/下页按钮）
            var pageW = $(".body-content").width();
            var pageH = $(".body-content").height();
            // 选中的占位框位置
            var x = box.geometry.x * pro;
            var y = box.geometry.y * pro;
            // 裁切框显示位置
            var left = parseFloat($(".content-container").offset().left) - 300 + x;
            var top = parseFloat($(".content-container").offset().top) - 92 + y;

            var imageArr = getImageScale(box.image, box.geometry);

            var ww = angular.copy(box.geometry.width) * pro;
            var hh = angular.copy(box.geometry.height) * pro;

            $scope.originL = left;
            $scope.originT = top;
            if (box.image.adaptation == 'Width') {
                $scope.wOpacity = ww;
                $scope.hOpacity = '';
            } else {
                $scope.hOpacity = hh;
                $scope.wOpacity = '';
            }

            if (box.image.angle == 90 || box.image.angle == 270) {
                if (imageArr.isWidth) {
                    $scope.w = ww;
                    $scope.h = '';
                } else {
                    $scope.h = hh;
                    $scope.w = '';
                }
            } else {
                if (box.image.adaptation == 'Width') {
                    $scope.w = ww;
                    $scope.h = '';
                } else {
                    $scope.h = hh;
                    $scope.w = '';
                }
            }

            $scope.l = left;
            $scope.t = top;


            $scope.moveGeo = {
                width: ww,
                height: hh,
                scale: ww / box.geometry.width
            };

            var x = box.image.offsetx * box.image.scaleW * $scope.scale * $scope.moveGeo.scale;
            var y = box.image.offsety * box.image.scaleH * $scope.scale * $scope.moveGeo.scale;
            $("#cutImagebox-img").css({left: x + $scope.l, top: y + $scope.t});

            $scope.ww = ww;
            $scope.hh = hh;
            var s = box.maskUrl;
            ImageService.convertImgToBase64(s, function (s) {
                var imagecanvas = $("#mask-canvas")[0];
                var imagecontext = imagecanvas.getContext('2d');
                var newImg = document.createElement('img');
                // newImg.crossOrigin = "Anonymous";
                newImg.src = 'content/images/zhezhao1.png';
                newImg.onload = function () {
                    newImg.onload = null;
                    var masks = document.createElement('img');
                    masks.src = s;
                    // masks.crossOrigin = "Anonymous";
                    masks.onload = function () {
                        masks.onload = null;
                        imagecanvas.width = pageW;
                        imagecanvas.height = pageH;
                        imagecontext.drawImage(masks, left, top, ww, hh);
                        imagecontext.globalCompositeOperation = 'source-out';
                        imagecontext.drawImage(newImg, 0, 0, pageW, pageH);

                        var moveTime = setInterval(function () {
                            $scope.moveImg = {
                                width: $("#cut-opacity").width(),
                                height: $("#cut-opacity").height(),
                                angle: box.image.angle
                            };
                            $scope.imageArr = getImageScale($scope.moveImg, $scope.moveGeo);
                            if ($scope.moveImg.width != 0 && $scope.moveImg.height != 0) {
                                clearInterval(moveTime);
                            }
                        }, 600);

                        $("#cutImagebox-mask").attr('src', ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                        $scope.$digest();
                    };
                    // masks.src = box.maskUrl;
                };
                newImg.src = 'content/images/zhezhao1.png';
            });
        };

        function getImageScale(imagebox, images) {
            var w0 = angular.copy(imagebox.width);
            var h0 = angular.copy(imagebox.height);

            if (imagebox.angle == 90 || imagebox.angle == 270) {
                w0 = angular.copy(imagebox.height);
                h0 = angular.copy(imagebox.width);
            }
            var x = 0, y = 0;

            var w = angular.copy(w0);
            var h = angular.copy(h0);
            var maskWidth = images.width;
            var maskHight = images.height;
            var isWidth = true;
            if ((w0 <= h0 || maskWidth <= maskHight) && (w0 / h0 < maskWidth / maskHight) || w0 >= h0 && maskWidth >= maskHight && (w0 / h0 < maskWidth / maskHight)) {
                w0 = maskWidth;
                h0 = maskWidth * h / w;
                isWidth = true;
            } else {
                h0 = maskHight;
                w0 = maskHight * w / h;
                isWidth = false;
            }

            var scaleW = w0 / w;
            var scaleH = h0 / h;
            x = x / scaleW;
            y = y / scaleH;
            return {
                isWidth: isWidth,
                nWidth: w0,
                nHeight: h0,
                scaleW: scaleW,
                scaleH: scaleH,
                x: x,
                y: y
            }
        }


        var drag = false;
        var dragBox = {};
        /**
         * 拖拽编辑的图片
         */
        window.startImageEdit = function (e) {
            fn(e);
            // if ($scope.imgBokehtype != 'None') {
            //     return
            // }
            var box = $scope.currentImage;
            if ($scope.moveImg.width <= 0 || $scope.moveImg.height <= 0) {
                $scope.moveImg = {
                    width: $("#cut-opacity").width(),
                    height: $("#cut-opacity").height(),
                    angle: box.image.angle
                };
            }
            $scope.imageArr = getImageScale($scope.moveImg, $scope.moveGeo);
            dragBox.clientX = e.clientX ? e.clientX : 0;
            dragBox.clientY = e.clientY ? e.clientY : 0;
            dragBox.picW = $("#cutImagebox-img").width();
            dragBox.picH = $("#cutImagebox-img").height();
            // dragBox.coordX = box.image.offsetx * pro;
            // dragBox.coordY = box.image.offsety * pro;
            dragBox.coordX = box.image.offsetx * box.image.scaleW * $scope.scale * $scope.moveGeo.scale;
            dragBox.coordY = box.image.offsety * box.image.scaleH * $scope.scale * $scope.moveGeo.scale;
            drag = true;
            document.addEventListener("mousemove", dragImageEdit, true);
            document.addEventListener("mouseup", stopImageEdit, true);
        };

        function dragImageEdit(e) {
            var box = $scope.currentImage;
            if (drag) {
                var x = 0, y = 0;
                //乘以放大缩小倍数后的宽高
                var orignW = $scope.imageArr.nWidth * $scope.scale;
                var orignH = $scope.imageArr.nHeight * $scope.scale;
                var isWidth = $scope.imageArr.isWidth;
                //手指离开屏幕
                if (!isWidth && $scope.scale == 1) {    //宽图
                    x = (dragBox.coordX + (e.clientX - dragBox.clientX));
                } else if (isWidth && $scope.scale == 1) {     //高图
                    y = (dragBox.coordY + (e.clientY - dragBox.clientY));
                } else if ($scope.scale > 1) {
                    x = (dragBox.coordX + (e.clientX - dragBox.clientX));
                    y = (dragBox.coordY + (e.clientY - dragBox.clientY));
                }

                //向上滑动;
                if (-y >= (orignH - $scope.hh)) {
                    y = -(orignH - $scope.hh);
                }
                //向左滑动;        <-----
                if (-x >= (orignW - $scope.ww)) {
                    x = -(orignW - $scope.ww);
                }
                //向下滑动;
                if (y >= 0) {
                    y = 0;
                }
                // 向右滑动;    ---->
                if (x >= 0) {
                    x = 0;
                }

                var pro = $scope.currentPage.scale / $scope.currentPage.proportion;
                $("#cutImagebox-img").css({left: x + $scope.l, top: y + $scope.t});

                box.image.offsetx = x / $scope.scale / $scope.moveGeo.scale / box.image.scaleW;
                box.image.offsety = y / $scope.scale / $scope.moveGeo.scale / box.image.scaleH;
                // box.image.offsetx = x / pro;
                // box.image.offsety = y / pro;

                $scope.$digest();
                e.stopPropagation();
                e.preventDefault();
                return false;
            }

        };

        function stopImageEdit() {
            drag = false;
            dragBox = {};
            document.removeEventListener("mousemove", dragImageEdit, true);
            document.removeEventListener("mouseup", stopImageEdit, true);
        };


        /**
         * 素材蒙版分类
         * @type {boolean}
         */
        function clickClipArtLabels(cliparts) {
            var items = [{"key": "type", "op": "=", "value": vm.materialTab}];
            if($scope.artlabelChooseId){
                items.push({key: "themeClassify.id", op: "=", value: $scope.artlabelChooseId})
            }
            if(vm.materialTab=='SYSTEM'){
                items.push({"key": "opened", "op": "=", "value": true})
            }
            ClipArts.byCondition({
                items: items,
                page: vm.clipartIndexNum,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (resp, headers) {
                vm.totalClipartItems = headers('X-Total-Count');
                if(resp&&resp.length){
                    angular.forEach(resp, function (data, i1) {
                        var arr = {
                            resource: {
                                identifier: vm.url + data.identifier,
                                provider: data.provider,
                            },
                            identifier: data.identifier,
                            type: data.type,
                            id: data.id
                        }
                        if (data.type == 'USER' && $scope.template.cliparts && $scope.template.cliparts.clipart && $scope.template.cliparts.clipart.length) {
                            $scope.template.cliparts.clipart.some(function (cli) {
                                if (cli.resource.identifier == arr.resource.identifier) {
                                    arr.isRecommend = true;
                                    return
                                }
                            })
                        }
                        cliparts.clipart.push(arr);
                    })
                    vm.showCliparts = cliparts;
                } else {
                    vm.showCliparts = cliparts;
                }

            })
        }

        vm.loading = false;
        $scope.selectLabel = function (label, index) {
            $scope.artlabelChooseId = label.id;
            $scope.artlabelChooseIndex = index;
            vm.clipartIndexNum = 0;
            if (!label.firstClick) {
                vm.loading = true;
                label.firstClick = true;
            }
            vm.allCliparts = {clipart: []}
            clickClipArtLabels(vm.allCliparts);
        };

        $scope.chooseMaterialTab = function (attr) {
            vm.materialTab = attr;
            $scope.clipartlabels = [];
            if (attr == 'SYSTEM') {
                $scope.clipartlabels = vm.platformMaterialLabels;
                vm.clipartTab = 'SYSTEM';
                $scope.selectLabel($scope.clipartlabels[0],0);
            } else if(attr == 'USER') {
                $scope.clipartlabels = vm.designerMaterialLabels;
                vm.clipartTab = 'all';
                $scope.selectLabel($scope.clipartlabels[0],0);
            }else{
                vm.clipartTab = attr;
                vm.showCliparts = $scope.template.cliparts;
            }
        }


        $scope.showLabels = false;
        $scope.showMoreLabels = function () {
            $scope.showLabels = !$scope.showLabels;
        }

        $scope.showMaskLabels = false;
        $scope.showMoreMaskLabels = function () {
            $scope.showMaskLabels = !$scope.showMaskLabels;
        }
        /**
         * 保存所编辑过的图片
         */
        $scope.saveMove = function () {
            var box = $scope.currentImage;

            if (box.image.angle != 0) {
                box.imgUrl += '/rotate/' + box.image.angle;
            }
            box.image.scale = $scope.scale;
            $scope.scale = 1;

            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.onload = function () {
                newImg.onload = null;
                var _image = getImageSource(newImg, box, box.image.resource.identifier, $scope.currentPage)
                var scaleW = _image.width / newImg.width;
                var scaleH = _image.height / newImg.height;

                var masks = document.createElement('img');
                var maskUrl = 'content/images/mask_souce2.png';
                if (box.mask && box.mask.resource && box.mask.resource.identifier) {
                    maskUrl = box.mask.resource.identifier
                }
                masks.src = maskUrl;
                masks.crossOrigin = "anonymous";
                masks.onload = function () {
                    masks.onload = null;
                    masks.width = box.geometry.width;
                    masks.height = box.geometry.height
                    imagecanvas.width = masks.width;
                    imagecanvas.height = masks.height;
                    imagecontext.drawImage(masks, 0, 0, masks.width, masks.height);
                    imagecontext.globalCompositeOperation = 'source-in';

                    imagecontext.scale(scaleW, scaleH);
                    imagecontext.scale(box.image.scale, box.image.scale);
                    imagecontext.drawImage(newImg, box.image.offsetx, box.image.offsety);
                    var pic = imagecanvas.toDataURL();
                    box.bgImg = ImageService.dataURLtoBlob(pic);
                    updateHistorys();
                    $scope.$digest();
                };
                masks.src = maskUrl;
            };
            newImg.src = box.imgUrl;

            $scope.showImageCut = false;
            updateHistorys();
        };


        vm.isImageboxTrim = false;
        //编辑图片框图片
        $scope.doubleClickImagebox = function (e, index, item) {
            if (!item.image && !item.image.resource && !item.image.resource.identifier) {
                return
            }
            vm.imageboxDouble1 = index;
            vm.imageboxDouble = angular.copy(item);
            vm.isImageboxTrim = true;
            $scope.scale = vm.imageboxDouble.image.scale;
        };
        $scope.imageboxFalseClick = function () {
            vm.isImageboxTrim = false;
        };
        $scope.imageboxTrueClick = function () {
            $scope.currentPage.levels.level[vm.imageboxDouble1].imagebox = vm.imageboxDouble;
            vm.isImageboxTrim = false;
            $scope.currentPage.levels.level[vm.imageboxDouble1].imagebox = vm.imageboxDouble;
            // if ($scope.currentPage.levels.level[vm.imageboxDouble1].imagebox.mask && $scope.currentPage.levels.level[vm.imageboxDouble1].imagebox.mask.resource.identifier) {
            //     imageboxResourceCanvas($scope.currentPage.levels.level[vm.imageboxDouble1].imagebox);
            // }
            $scope.pxInsufficient($scope.currentPage.levels.level[vm.imageboxDouble1].imagebox, 'imagebox');
        };
        var isImageboxMouseDown = false;
        $scope.imageboxMouseDown = function ($event) {
            fn($event)
            var ratio = $scope.currentPage.scale / $scope.currentPage.proportion;
            var x = $event.clientX;
            var y = $event.clientY;
            var l = vm.imageboxDouble.image.offsetx * ratio * vm.imageboxDouble.image.scale;
            var t = vm.imageboxDouble.image.offsety * ratio * vm.imageboxDouble.image.scale;
            var r = -(vm.imageboxDouble.image.width * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.width) * ratio;
            var b = -(vm.imageboxDouble.image.height * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.height) * ratio;
            var angle = Number(vm.imageboxDouble.rotation.angle);
            //开关打开
            isImageboxMouseDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageboxMouseDown == false) {
                    return;
                }
                fn(e);
                var cx = (l + r) / 2;
                var cy = (t + b) / 2;
                var pt0 = rotatePoint({x: x, y: y}, {x: cx, y: cy}, angle);
                var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, angle);
                var deltaX = pt1.x - pt0.x;
                var deltaY = pt1.y - pt0.y;
                var nl = l + deltaX;
                var nt = t + deltaY;
                if (nl < r) {
                    nl = r
                }
                ;
                if (nl > 0) {
                    nl = 0
                }
                ;
                if (nt < b) {
                    nt = b
                }
                ;
                if (nt > 0) {
                    nt = 0
                }
                ;

                vm.imageboxDouble.image.offsetx = nl / ratio / vm.imageboxDouble.image.scale;
                vm.imageboxDouble.image.offsety = nt / ratio / vm.imageboxDouble.image.scale;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageboxMouseDown = false;
                $scope.$digest();
            }
        }


        // 滚动放大
        window.bbimg = function (delta) {
            if (vm.ctrlKey) {
                return
            }
            if (delta < 0 || delta == undefined && event.wheelDelta > 0) {
                $scope.scale *= 1.2;
            } else {
                $scope.scale /= 1.2;
                if ($scope.scale <= 1) {
                    $scope.scale = 1;
                }
            }
            vm.imageboxDouble.image.scale = $scope.scale;
            if (vm.imageboxDouble.image.offsetx * vm.imageboxDouble.image.scale < -(vm.imageboxDouble.image.width * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.width)) {
                vm.imageboxDouble.image.offsetx = -(vm.imageboxDouble.image.width * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.width) / vm.imageboxDouble.image.scale
            }
            if (vm.imageboxDouble.image.offsety * vm.imageboxDouble.image.scale < -(vm.imageboxDouble.image.height * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.height)) {
                vm.imageboxDouble.image.offsety = -(vm.imageboxDouble.image.height * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.height) / vm.imageboxDouble.image.scale
            }
            $scope.$digest();
            return false;
        }

        //Firefox只识别DOMMouseScroll
        var mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel";

        //判断是否是IE
        if (document.attachEvent) {
            $("#mouseWheel")[0].attachEvent("on" + mousewheelevt, function (e) {
                window.bbimg();
            });
        } else if (document.addEventListener) { //FireFox(DOMMouseScroll)、Chrome、Opera、safari
            $("#mouseWheel")[0].addEventListener(mousewheelevt, function (e) {
                e = e || window.event;
                if (e.detail) {//Firefox
                    window.bbimg(e.detail);
                } else if (e.wheelDelta) {
                    window.bbimg();
                }
            }, false);
        }
        originalEventRatio();

        function originalEventRatio() {
            window.addEventListener('mousewheel', function (event) {
                if (event.ctrlKey === true || event.metaKey) {
                    var wheelDelta = event.wheelDelta ? (event.wheelDelta / 120) : (-event.detail / 3);
                    if (wheelDelta > 0) {
                        $scope.changeShirnk(2);
                    } else {
                        $scope.changeShirnk(1);
                    }
                    $scope.$apply();
                    event.preventDefault();
                }
            }, {passive: false});
        }


        window.dragStart = function (event) {
            var target = event.target;
            event.dataTransfer.setData("Text", target.id);
            if (target.id.indexOf('dragtargetClipArt') != '-1') {
                event.dataTransfer.setData("Art", target.dataset.clipart);
            }
        };
        var _imageartOffset = 0;
        $scope.addArt = function (identifier, offsetX, offsetY) {
            if(vm.textEditor){
                return
            }
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var currentLevel = addLevel(currentPage);

            vm.unit = 'mm';
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = identifier + '?imageView2';
            newImg.onload = function () {
                newImg.onload = null;
                var width = newImg.width;
                var height = newImg.height;

                var bgImgR = width / height;
                offsetX = offsetX / currentPage.scale * currentPage.proportion;
                offsetY = offsetY / currentPage.scale * currentPage.proportion;
                var _dx = 136;
                var _imageart = {};
                if (bgImgR >= 1) {
                    if (width > _dx) {
                        _imageart = {
                            width: _dx / currentPage.scale * currentPage.proportion,
                            height: _dx / width * height / currentPage.scale * currentPage.proportion,
                            x: offsetX,
                            y: offsetY
                        }
                    } else {
                        _imageart = {
                            height: height / currentPage.scale * currentPage.proportion,
                            width: width / currentPage.scale * currentPage.proportion,
                            x: offsetX,
                            y: offsetY
                        }
                    }
                } else {
                    if (height > _dx) {
                        _imageart = {
                            height: _dx / currentPage.scale * currentPage.proportion,
                            width: _dx / height * width / currentPage.scale * currentPage.proportion,
                            x: offsetX,
                            y: offsetY
                        }
                    } else {
                        _imageart = {
                            height: height / currentPage.scale * currentPage.proportion,
                            width: width / currentPage.scale * currentPage.proportion,
                            x: offsetX,
                            y: offsetY
                        }
                    }
                }
                if(offsetX && offsetY){
                    _imageart.x = _imageart.x - _imageart.width/2 + MmTurnPx(_imageartOffset);
                    _imageart.y = _imageart.y - _imageart.height/2 + MmTurnPx(_imageartOffset);
                }else{
                    _imageart.x = (currentPage.mediabox.width - _imageart.width)/2 + MmTurnPx(_imageartOffset);
                    _imageart.y = (currentPage.mediabox.height - _imageart.height)/2 + MmTurnPx(_imageartOffset);
                }
                _imageartOffset += 1;
                $scope.pageValue.imageart = {
                    width: PxTurnMm(_imageart.width),
                    height: PxTurnMm(_imageart.height),
                    x: PxTurnMm(_imageart.x),
                    y: PxTurnMm(_imageart.y)
                };
                currentLevel.imageart = ({
                    geometry: {
                        width: _imageart.width,
                        height: _imageart.height,
                        x: _imageart.x,
                        y: _imageart.y
                    },
                    rotation: {
                        angle: 0
                    },
                    resource: {
                        identifier: identifier,
                        provider: 'qiniu',
                        width: width,
                        height: height,
                    },
                    scale: 1,
                    transparency: 1,
                    onlyshow: false,
                    movable: true,
                    editable: true,
                    bgImg: '',
                    offsetx: 0,
                    offsety: 0,
                    unit: 'mm',
                    // index: currentPage.imagearts.imageart.length,
                    levelI: $scope.template.levelI,
                    pageI: $scope.template.pageI,
                    current: 'imageart',
                    lock: false
                });

                $scope.currentPage = currentPage;
                $scope.RightPanel = 'material';
                $scope.selectBox = currentLevel.imageart;
                $scope.template.transparency = 100;
                $scope.pxInsufficient(currentLevel.imageart, 'imageart');
                updateHistorys();
                $scope.$digest();
                vm.selectElementCopy = angular.copy($scope.currentPage.levels.level[$scope.template.levelI]);
            };
        }

        /* ==========================================================================
         占位框处理
         ========================================================================== */

        function addLevel(currentPage) {
            var arr = {
                seq: 1,
                imagebox: null,
                textbox: null,
                imageart: null,
                textdocument: null,
                variable: false
            };

            if (!currentPage.levels.level) {
                currentPage.levels.level = [arr];
            } else {
                currentPage.levels.level.push(arr);
            }

            $scope.template.levelI = currentPage.levels.level.length - 1;
            var currentLevel = currentPage.levels.level[$scope.template.levelI];

            currentLevel.seq = $scope.template.levelI + 1;
            vm.unit = 'mm';
            $scope.template.angle = 0;
            return currentLevel;
        }

        //增加图片占位框
        var addPlacement = function (e, mask, attr, offsetX, offsetY) {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var currentLevel = addLevel(currentPage);

            offsetX = offsetX / currentPage.scale * currentPage.proportion;
            offsetY = offsetY / currentPage.scale * currentPage.proportion;


            currentLevel.imagebox = ({
                geometry: {
                    width: MmTurnPx(50),
                    height: MmTurnPx(50),
                    x: MmTurnPx(PxTurnMm(offsetX) - 25),
                    y: MmTurnPx(PxTurnMm(offsetY) - 25)
                },
                rotation: {
                    angle: 0
                },
                mask: {
                    resource: {
                        identifier: mask.identifier,
                        provider: 'qiniu'
                    }
                },
                border: {
                    shape: 'Rectangle',
                    radius: {
                        lt: 0,
                        rt: 0,
                        rb: 0,
                        lb: 0,
                        all: 0,
                        filletType: 'Fixed'
                    },
                    "edge": {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                    lineWidth: 0,
                    type: "Color",
                    lineType: "solid",
                    resource: {
                        "identifier": ''
                    },
                    color: '#000000',
                },
                shadow: {
                    color: '#777777',
                    x: 0,
                    y: 0,
                    blur: 0
                },
                imgUrl: 'content/images/mask_souce2.png',
                maskUrl: mask.identifier || 'content/images/mask_souce2.png',
                transparency: 1,
                bgImg: '',
                unit: 'mm',
                levelI: $scope.template.levelI,
                pageI: $scope.template.pageI,
                current: 'imagebox',
                lock: false
            });

            $scope.currentPage = currentPage;
            $scope.selectBox = currentLevel.imagebox;
            $scope.RightPanel = 'mask';
            getImageboxLen();
            if (vm.unit == 'mm') {
                $scope.pageValue.imagebox = {
                    width: 50,
                    height: 45,
                    x: PxTurnMm(offsetX) - 25,
                    y: PxTurnMm(offsetY) - 23
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.imagebox = currentPage.imagebox.geometry;
            } else if (vm.unit == 'in') {
                $scope.pageValue.imagebox = {
                    width: PxTurnIn(currentPage.imagebox.geometry.width),
                    height: PxTurnIn(currentPage.imagebox.geometry.height),
                    x: PxTurnIn(currentPage.imagebox.geometry.x),
                    y: PxTurnIn(currentPage.imagebox.geometry.y)
                }
            }
            $scope.pxInsufficient(currentLevel.imagebox, 'imagebox');
        };
        var addPlacement1 = function (e, mask, attr, offsetX, offsetY, shape, data, callback) {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var currentLevel = addLevel(currentPage);

            offsetX = offsetX / currentPage.scale * currentPage.proportion;
            offsetY = offsetY / currentPage.scale * currentPage.proportion;


            if (shape == 'FrameConfig') {
                var frameConfig = vm.frameConfigs[$('#' + data).attr('data-index')];

                $http({url: frameConfig.img + '?imageInfo'}).success(function (data) {
                    var imgZoom = 1;
                    if (data.width > data.height) {
                        imgZoom = 300 / data.width
                    } else {
                        imgZoom = 300 / data.height
                    }
                    var _w2 = (imgZoom * data.width) / (1 + frameConfig.marginLeft / 100 + frameConfig.marginRight / 100);
                    var _h2 = (imgZoom * data.height) / (1 + frameConfig.marginBottom / 100 + frameConfig.marginTop / 100);
                    currentLevel.imagebox = ({
                        geometry: {
                            width: _w2,
                            height: _h2,
                            x: offsetX - _w2 / 2,
                            y: offsetY - _h2 / 2
                        },
                        rotation: {
                            angle: 0
                        },
                        mask: {
                            resource: {
                                identifier: '',
                                provider: 'qiniu'
                            }
                        },
                        imgUrl: 'content/images/mask_souce2.png',
                        maskUrl: 'content/images/mask_souce2.png',
                        transparency: 1,
                        bgImg: '',
                        unit: 'mm',
                        levelI: $scope.template.levelI,
                        pageI: $scope.template.pageI,
                        current: 'imagebox',
                        lock: false,
                        border: {
                            shape: frameConfig.shape || 'Rectangle',
                            radius: {
                                lt: 0,
                                rt: 0,
                                rb: 0,
                                lb: 0,
                                all: 0,
                                filletType: 'Fixed'
                            },
                            "edge": {
                                top: frameConfig.marginTop,
                                right: frameConfig.marginRight,
                                bottom: frameConfig.marginBottom,
                                left: frameConfig.marginLeft
                            },
                            lineWidth: 0,
                            type: "Pic",
                            lineType: "solid",
                            resource: {
                                "identifier": frameConfig.img
                            },
                            color: '',
                        },
                        shadow: {
                            color: '#777777',
                            x: 0,
                            y: 0,
                            blur: 0
                        }
                    });

                    $scope.currentPage = currentPage;
                    $scope.selectBox = currentLevel.imagebox;
                    $scope.RightPanel = 'mask';
                    getImageboxLen();
                    if (vm.unit == 'mm') {
                        $scope.pageValue.imagebox = {
                            width: PxTurnMm(_w2),
                            height: PxTurnMm(_h2),
                            x: PxTurnMm(offsetX) - PxTurnMm(_w2) / 2,
                            y: PxTurnMm(offsetY) - PxTurnMm(_w2) / 2
                        }
                    } else if (vm.unit == 'px') {
                        $scope.pageValue.imagebox = currentPage.imagebox.geometry;
                    } else if (vm.unit == 'in') {
                        $scope.pageValue.imagebox = {
                            width: PxTurnIn(currentPage.imagebox.geometry.width),
                            height: PxTurnIn(currentPage.imagebox.geometry.height),
                            x: PxTurnIn(currentPage.imagebox.geometry.x),
                            y: PxTurnIn(currentPage.imagebox.geometry.y)
                        }
                    }
                    $scope.pxInsufficient(currentLevel.imagebox, 'imagebox');
                    updateHistorys();
                    if (callback) {
                        callback()
                    }
                })
            } else {
                currentLevel.imagebox = ({
                    geometry: {
                        width: MmTurnPx(50),
                        height: MmTurnPx(50),
                        x: MmTurnPx(PxTurnMm(offsetX) - 25),
                        y: MmTurnPx(PxTurnMm(offsetY) - 25)
                    },
                    rotation: {
                        angle: 0
                    },
                    mask: {
                        resource: {
                            identifier: mask.identifier,
                            provider: 'qiniu'
                        }
                    },
                    imgUrl: 'content/images/mask_souce2.png',
                    maskUrl: mask.identifier || 'content/images/mask_souce2.png',
                    transparency: 1,
                    bgImg: '',
                    unit: 'mm',
                    levelI: $scope.template.levelI,
                    pageI: $scope.template.pageI,
                    current: 'imagebox',
                    lock: false,
                    border: {
                        shape: shape,
                        radius: {
                            lt: 0,
                            rt: 0,
                            rb: 0,
                            lb: 0,
                            all: 0,
                            filletType: 'Fixed'
                        },
                        "edge": {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                        lineWidth: 0,
                        type: "Color",
                        lineType: "solid",
                        resource: {
                            "identifier": ''
                        },
                        color: '#000000',
                    },
                    shadow: {
                        color: '#777777',
                        x: 0,
                        y: 0,
                        blur: 0
                    }
                });

                $scope.currentPage = currentPage;
                $scope.selectBox = currentLevel.imagebox;
                $scope.RightPanel = 'mask';
                getImageboxLen();
                if (vm.unit == 'mm') {
                    $scope.pageValue.imagebox = {
                        width: 50,
                        height: 45,
                        x: PxTurnMm(offsetX) - 25,
                        y: PxTurnMm(offsetY) - 23
                    }
                } else if (vm.unit == 'px') {
                    $scope.pageValue.imagebox = currentPage.imagebox.geometry;
                } else if (vm.unit == 'in') {
                    $scope.pageValue.imagebox = {
                        width: PxTurnIn(currentPage.imagebox.geometry.width),
                        height: PxTurnIn(currentPage.imagebox.geometry.height),
                        x: PxTurnIn(currentPage.imagebox.geometry.x),
                        y: PxTurnIn(currentPage.imagebox.geometry.y)
                    }
                }
                $scope.pxInsufficient(currentLevel.imagebox, 'imagebox');
            }
            vm.selectElementCopy = angular.copy($scope.currentPage.levels.level[$scope.template.levelI]);
            updateHistorys();
            return
        };

        function getImageboxLen() {
            vm.count = 0;
            angular.forEach($scope.currentPage.levels.level, function (level) {
                if (level.imagebox && level.imagebox.geometry) {
                    vm.count++;
                }
            })
        }

        // 占位框背景
        window.updateMask = function ($event) {
            var files = $event.target.files;
            bigSizePics = [];
            vm.uploadType = "MASK";
            var _index = 0;
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.size >= 20 * 1024 * 1024) {
                    file.imageSize = Math.ceil(file.size / 1024 / 1024);
                    bigSizePics.push(file);
                    _index++
                } else {
                    getMask(files,i,function () {
                        _index++
                        if(_index == files.length){
                            $timeout(function () {
                                $event.srcElement.value = "";
                            })
                        }
                    });
                    if (bigSizePics.length == 0 && i == (files.length - 1)) {
                        vm.picLoading = false;
                    }
                }
            }
            if (bigSizePics.length > 0) {
                thumbnailatorPic();
            }
        };

        function getMask(files,i,callback) {
                var url = window.URL || window.webkitURL;
                var pic = url.createObjectURL(files[i]);
                var formData = new FormData();
                formData.append('file', files[i]);
                formData.append('key', 'mask-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: true,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        SourceMaterial.createOne({
                            identifier: data.key,
                            provider: 'qiniu',
                            name: files[i].name,
                            type: 'User',
                            themeClassifyId: $scope.masklabelChooseId
                        }, function (resp) {
                            var arr = {
                                resource: {
                                    identifier: vm.url + resp.identifier,
                                    provider: resp.provider,
                                },
                                type: resp.type,
                                id: resp.id,
                                isRecommend: false,
                                identifier: vm.url + resp.identifier,
                                provider: 'qiniu',
                                bgImg: '',
                            }
                            vm.showMasks.mask.unshift(arr);
                            if(callback){callback()}
                        },function () {
                            if(callback){callback()}
                        });
                    },
                    error: function (request) {
                        if(callback){callback()}
                    }
                });
        };

        //图片占位框点击时
        $scope.selectImg = function (e, $index, img) {
            if (!e || vm.textEditor) {
                return
            }
            if (!img.border) {
                img.border = {
                    shape: 'Rectangle',
                    radius: {
                        lt: 0,
                        rt: 0,
                        rb: 0,
                        lb: 0,
                        all: 0,
                        filletType: 'Fixed'
                    },
                    "edge": {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                    lineWidth: 0,
                    type: "Color",
                    resource: {
                        "identifier": ''
                    },
                    color: '',
                }
            }

            if (vm.unit == 'mm') {
                $scope.pageValue.imagebox = {
                    width: PxTurnMm(img.geometry.width).toFixed(2) - 0,
                    height: PxTurnMm(img.geometry.height).toFixed(2) - 0,
                    x: PxTurnMm(img.geometry.x).toFixed(2) - 0,
                    y: PxTurnMm(img.geometry.y).toFixed(2) - 0
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.imagebox = {
                    width: img.geometry.width,
                    height: img.geometry.height,
                    x: img.geometry.x,
                    y: img.geometry.y
                }
            } else if (vm.unit == 'in') {
                $scope.pageValue.imagebox = {
                    width: PxTurnIn(img.geometry.width),
                    height: PxTurnIn(img.geometry.height),
                    x: PxTurnIn(img.geometry.x),
                    y: PxTurnIn(img.geometry.y)
                }
            }

            $scope.template.angle = img.rotation.angle;
            img.current = 'imagebox';
            $scope.selectBox = img;
            vm.selectItem = angular.copy(img);

            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                img.ctrlKey = true;
                img.levelI = $index;
                vm.CtrlKeyArray.imageboxes[$index] = angular.copy(img);
                ctrlAddSingleArray();
            } else {
                if (!img.ctrlKey) {
                    $scope.clearCtrlKey();
                    vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, calMonthBox: {}, calTitleBox: {}};
                }
                vm.singleArray.imagebox = angular.copy(img);
            }
            $("#menus").fadeOut();
            $("#maskSelect").val("");
            $scope.template.levelI = $index;
            $scope.RightPanel = 'mask';
            e.stopPropagation();
            // 判断vm.borderRadiusAllbtn 给all输入框赋值
            if ($scope.selectBox.border.radius.lt == $scope.selectBox.border.radius.lb && $scope.selectBox.border.radius.lb == $scope.selectBox.border.radius.rt && $scope.selectBox.border.radius.rt == $scope.selectBox.border.radius.rb) {
                vm.borderRadiusAllbtn = true
                $scope.selectBox.border.radius.all = $scope.selectBox.border.radius.lt;
            } else {
                vm.borderRadiusAllbtn = false
            }

            vm.selectElementCopy = angular.copy($scope.currentPage.levels.level[$scope.template.levelI]);
        };

        function ctrlAddSingleArray() {
            if (vm.singleArray.imagebox.index != undefined) {
                var index = vm.singleArray.imagebox.index;
                $scope.currentPage.imageboxes.imagebox[index].ctrlKey = true;
                vm.CtrlKeyArray.imageboxes[index] = vm.singleArray.imagebox;
            }
            if (vm.singleArray.textbox.index != undefined) {
                var index = vm.singleArray.textbox.index;
                $scope.currentPage.textboxes.textbox[index].ctrlKey = true;
                vm.CtrlKeyArray.textboxes[index] = vm.singleArray.textbox;
            }
            if (vm.singleArray.imageart.index != undefined) {
                var index = vm.singleArray.imageart.index;
                $scope.currentPage.imagearts.imageart[index].ctrlKey = true;
                vm.CtrlKeyArray.imagearts[index] = vm.singleArray.imageart;
            }
            if (vm.singleArray.barcode.index != undefined) {
                vm.singleArray.barcode.ctrlKey = true;
                vm.CtrlKeyArray.barcode[0] = vm.singleArray.barcode;
            }
            if (vm.singleArray.qrcode.index != undefined) {
                vm.singleArray.qrcode.ctrlKey = true;
                vm.CtrlKeyArray.qrcode[0] = vm.singleArray.qrcode;
            }
            if (vm.singleArray.calMonthBox.index != undefined) {
                vm.singleArray.calMonthBox.ctrlKey = true;
                vm.CtrlKeyArray.calMonthBoxs[0] = vm.singleArray.calMonthBox;
            }
            if (vm.singleArray.calTitleBox && vm.singleArray.calTitleBox.index != undefined) {
                vm.singleArray.calTitleBox.ctrlKey = true;
                vm.CtrlKeyArray.calTitleBoxs[0] = vm.singleArray.calTitleBox;
            }
            vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, calMonthBox: {}, calTitleBox: {}};
        }

        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        var dragMask = {};
        $scope.buttonShows = false;
        $scope.moveBoxStart = function (e, item, index) {
            if(vm.textEditor){
                return;
            }
            fn(e);
            // $timeout(function(){
            //     $('#focusInput').focus();//当属性栏有输入框聚焦时择强制失去焦点
            // },500)
            $scope.buttonShows = true;
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = index;
            $scope.template.angle = item.rotation.angle;
            if (item.lock) {
                return
            }

            dragBox.clientX = e.clientX || 0;
            dragBox.clientY = e.clientY || 0;
            if (item.current == 'page') {
                return
            } else if (item.current == 'imagebox') {
                dragBox.coordX = item.geometry.x * $scope.currentPage.scale / $scope.currentPage.proportion;
                dragBox.coordY = item.geometry.y * $scope.currentPage.scale / $scope.currentPage.proportion;
            } else if (item.current == 'textdocument') {
                dragBox.coordX = e.currentTarget.offsetLeft;
                dragBox.coordY = e.currentTarget.offsetTop;
            } else {
                dragBox.coordX = item.geometry.x * $scope.currentPage.scale / $scope.currentPage.proportion;
                dragBox.coordY = item.geometry.y * $scope.currentPage.scale / $scope.currentPage.proportion;
            }
            drag = true;
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragBox.ctrlKey = true;
            } else {
                dragBox.ctrlKey = false;
            }
            $scope.selectBox = item;
            if(vm.staff){
                vm.coordinates = coordinates($scope.currentPage.levels.level,$scope.template.levelI);
                vm.coordinates.x = vm.coordinates.x.concat(vm.sublinesX);
                vm.coordinates.y = vm.coordinates.y.concat(vm.sublinesY);
            }
            document.addEventListener("mousemove", moveBoxOn, true);
            document.addEventListener("mouseup", moveBoxEnd, true);

        };
        //move 事件
        function moveBoxOn(e) {
            fn(e);
            $scope.$digest();
            if (e.target.nodeName == 'SPAN' && $scope.selectBox.current != 'textbox') {
                return;
            }
            if (drag) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var angle = ($scope.selectBox.rotation.angle) * Math.PI / 180;

                var clientX = e.clientX - dragBox.clientX;
                var clientY = e.clientY - dragBox.clientY;

                var imgOffsetX = (dragBox.coordX + e.clientX - dragBox.clientX);
                var imgOffsetY = (dragBox.coordY + e.clientY - dragBox.clientY);
                var imgWidth = $scope.selectBox.geometry.width * currentPage.scale / currentPage.proportion;
                var imgHeight = $scope.selectBox.geometry.height * currentPage.scale / currentPage.proportion;

                if ($scope.selectBox.current == 'textbox') {
                    imgWidth = $(".textbox" + $scope.template.pageI + $scope.template.levelI).width();
                    imgHeight = $(".textbox" + $scope.template.pageI + $scope.template.levelI).height();
                }
                var height = currentPage.height * currentPage.scale;
                var width = currentPage.width * currentPage.scale;
                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                // if (imgOffsetX <= 0) {
                //     imgOffsetX = 0;
                // }
                //
                // if (imgOffsetX >= (width + lar - imgWidth)) {
                //     imgOffsetX = width + lar - imgWidth;
                // }
                // if (imgOffsetY <= 0) {
                //     imgOffsetY = 0;
                // }
                //
                // if (imgOffsetY >= (height + tab - imgHeight)) {
                //     imgOffsetY = height + tab - imgHeight;
                // }

                if (dragBox.ctrlKey) {
                    moveTogether(currentPage, clientX, clientY);
                } else {
                    $scope.selectBox.geometry.x = imgOffsetX / currentPage.scale * currentPage.proportion;
                    $scope.selectBox.geometry.y = imgOffsetY / currentPage.scale * currentPage.proportion;
                    if(vm.staff){
                        vm.lineReferenceX = null;
                        vm.lineReferenceY = null;
                        getItemCoordinates($scope.selectBox,vm.coordinates,function (item) {
                            if(item.x){
                                vm.lineReferenceX = item.x.closestNum;
                                $scope.selectBox.geometry.x = $scope.selectBox.geometry.x + item.x.diff;
                            }
                            if(item.y){
                                vm.lineReferenceY = item.y.closestNum;
                                $scope.selectBox.geometry.y = $scope.selectBox.geometry.y + item.y.diff;
                            }
                        });
                    }
                    var value = {};
                    if ($scope.selectBox.current == 'textbox') {
                        value = $scope.pageValue.textbox;
                    } else if ($scope.selectBox.current == 'imagebox') {
                        value = $scope.pageValue.imagebox;
                    } else if ($scope.selectBox.current == 'imageart') {
                        value = $scope.pageValue.imageart;
                    } else if ($scope.selectBox.current == 'textdocument') {
                        value = $scope.pageValue.textdocument;
                    } else if ($scope.selectBox.current == 'calMonthBox') {
                        value = $scope.pageValue.calMonthBox;
                    } else if ($scope.selectBox.current == 'calTitleBox') {
                        value = $scope.pageValue.calTitleBox;
                    }
                    if(value){
                        if (vm.unit == 'mm') {
                            value.x = PxTurnMm($scope.selectBox.geometry.x).toFixed(2) - 0;//px-->mm
                            value.y = PxTurnMm($scope.selectBox.geometry.y).toFixed(2) - 0;//px-->mm
                        } else if (vm.unit == 'px') {
                            value.x = PxTurnMm($scope.selectBox.geometry.x / 25.4 * vm.windowDpiW);
                            value.y = PxTurnMm($scope.selectBox.geometry.y / 25.4 * vm.windowDpiW);
                        } else if (vm.unit == 'In') {
                            value.x = PxTurnIn($scope.selectBox.geometry.x);
                            value.y = PxTurnIn($scope.selectBox.geometry.y);
                        }
                    }
                }
                $scope.currentPage = currentPage;

            }
            return false;
        }
        //鼠标松开事件
        function moveBoxEnd() {
            vm.lineReferenceX = null;
            vm.lineReferenceY = null;
            if (dragBox.ctrlKey) {
                moveTogetherEnd();
            }
            drag = false;
            dragBox = {};
            document.removeEventListener("mousemove", moveBoxOn, true);
            document.removeEventListener("mouseup", moveBoxEnd, true);
            updateHistorys();
            $scope.$digest();
        }

        $scope.moveBoxStart1 = function (e, item, index) {
            if(vm.textEditor){
                return;
            }
            fn(e);
            $scope.buttonShows = true;
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = index;
            $scope.template.angle = item.rotation.angle;
            if (item.lock) {
                return
            }

            dragBox.clientX = e.clientX || 0;
            dragBox.clientY = e.clientY || 0;
            if (item.current == 'page') {
                return
            } else if (item.current == 'imagebox') {
                dragBox.coordX = item.geometry.x * $scope.currentPage.scale / $scope.currentPage.scene.proportion;
                dragBox.coordY = item.geometry.y * $scope.currentPage.scale / $scope.currentPage.scene.proportion;
            } else if (item.current == 'textdocument') {
                dragBox.coordX = e.currentTarget.offsetLeft;
                dragBox.coordY = e.currentTarget.offsetTop;
            } else {
                dragBox.coordX = item.geometry.x * $scope.currentPage.scale / $scope.currentPage.scene.proportion;
                dragBox.coordY = item.geometry.y * $scope.currentPage.scale / $scope.currentPage.scene.proportion;
            }
            drag = true;
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragBox.ctrlKey = true;
            } else {
                dragBox.ctrlKey = false;
            }
            $scope.selectBox = item;
            if(vm.staff){
                vm.coordinates = coordinates($scope.currentPage.levels.level,$scope.template.levelI);
                vm.coordinates.x = vm.coordinates.x.concat(vm.sublinesX);
                vm.coordinates.y = vm.coordinates.y.concat(vm.sublinesY);
            }
            document.addEventListener("mousemove", moveBoxOn1, true);
            document.addEventListener("mouseup", moveBoxEnd1, true);

        };

        //move 事件
        function moveBoxOn1(e) {
            fn(e);
            $scope.$digest();
            if (e.target.nodeName == 'SPAN' && $scope.selectBox.current != 'textbox') {
                return;
            }
            if (drag) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var angle = ($scope.selectBox.rotation.angle) * Math.PI / 180;

                var clientX = e.clientX - dragBox.clientX;
                var clientY = e.clientY - dragBox.clientY;

                var imgOffsetX = (dragBox.coordX + e.clientX - dragBox.clientX);
                var imgOffsetY = (dragBox.coordY + e.clientY - dragBox.clientY);
                var imgWidth = $scope.selectBox.geometry.width * currentPage.scale / currentPage.scene.proportion;
                var imgHeight = $scope.selectBox.geometry.height * currentPage.scale / currentPage.scene.proportion;

                if ($scope.selectBox.current == 'textbox') {
                    imgWidth = $(".textbox1" + $scope.template.pageI + $scope.template.levelI).width();
                    imgHeight = $(".textbox1" + $scope.template.pageI + $scope.template.levelI).height();
                }
                var height = currentPage.mediabox.height * currentPage.scale / currentPage.scene.proportion;
                var width = currentPage.mediabox.width * currentPage.scale / currentPage.scene.proportion;
                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.scene.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.scene.proportion;

                // if (angle == 0) {
                //     if (imgOffsetX <= 0) {
                //         imgOffsetX = 0;
                //     }
                //
                //     if (imgOffsetX >= (width - imgWidth)) {
                //         imgOffsetX = width - imgWidth;
                //     }
                //     if (imgOffsetY <= 0) {
                //         imgOffsetY = 0;
                //     }
                //
                //     if (imgOffsetY >= (height - imgHeight)) {
                //         imgOffsetY = height - imgHeight;
                //     }
                // } else {
                //     if (imgOffsetX <= 0) {
                //         imgOffsetX = 0;
                //     }
                //
                //     if (imgOffsetX >= (width + lar)) {
                //         imgOffsetX = width + lar;
                //     }
                //     if (imgOffsetY <= 0) {
                //         imgOffsetY = 0;
                //     }
                //
                //     if (imgOffsetY >= (height + tab)) {
                //         imgOffsetY = height + tab;
                //     }
                //
                // }

                if (dragBox.ctrlKey) {
                    moveTogether(currentPage, clientX, clientY);
                } else {
                    $scope.selectBox.geometry.x = imgOffsetX / currentPage.scale * currentPage.scene.proportion;
                    $scope.selectBox.geometry.y = imgOffsetY / currentPage.scale * currentPage.scene.proportion;
                    if(vm.staff){
                        vm.lineReferenceX = null;
                        vm.lineReferenceY = null;
                        getItemCoordinates($scope.selectBox,vm.coordinates,function (item) {
                            if(item.x){
                                vm.lineReferenceX = item.x.closestNum;
                                $scope.selectBox.geometry.x = $scope.selectBox.geometry.x + item.x.diff;
                            }
                            if(item.y){
                                vm.lineReferenceY = item.y.closestNum;
                                $scope.selectBox.geometry.y = $scope.selectBox.geometry.y + item.y.diff;
                            }
                        });
                    }
                    var value = {};
                    if ($scope.selectBox.current == 'textbox') {
                        value = $scope.pageValue.textbox;
                    } else if ($scope.selectBox.current == 'imagebox') {
                        value = $scope.pageValue.imagebox;
                    } else if ($scope.selectBox.current == 'imageart') {
                        value = $scope.pageValue.imageart;
                    } else if ($scope.selectBox.current == 'textdocument') {
                        value = $scope.pageValue.textdocument;
                    }
                    if (vm.unit == 'mm') {
                        value.x = PxTurnMm($scope.selectBox.geometry.x).toFixed(2) - 0;//px-->mm
                        value.y = PxTurnMm($scope.selectBox.geometry.y).toFixed(2) - 0;//px-->mm
                    } else if (vm.unit == 'px') {
                        value.x = PxTurnMm($scope.selectBox.geometry.x / 25.4 * vm.windowDpiW);
                        value.y = PxTurnMm($scope.selectBox.geometry.y / 25.4 * vm.windowDpiW);
                    } else if (vm.unit == 'in') {
                        value.x = PxTurnIn($scope.selectBox.geometry.x);
                        value.y = PxTurnIn($scope.selectBox.geometry.y);
                    }
                }
                $scope.currentPage = currentPage;

            }
            return false;
        }

        //鼠标松开事件
        function moveBoxEnd1() {
            vm.lineReferenceX = null;
            vm.lineReferenceY = null;
            if (dragBox.ctrlKey) {
                moveTogetherEnd();
            }
            drag = false;
            dragBox = {};
            document.removeEventListener("mousemove", moveBoxOn1, true);
            document.removeEventListener("mouseup", moveBoxEnd1, true);
            updateHistorys();
            $scope.$digest();
        }

        var rotateDragDown = false;
        $scope.rotateBoxStart = function (e, item, index) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = index;
            if (item.lock) {
                return
            }
            rotateDragDown = true;
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragBox.ctrlKey = true;
            } else {
                dragBox.ctrlKey = false;
            }
            $scope.selectBox = item;
            if (item.current == 'imagebox') {
                var box = $(e.target).parents('.imgBox');
                dragBox._top = box.offset().top + box.height() / 2;
                dragBox._left = box.offset().left + box.width() / 2;
                if ($scope.RightPanel == 'mask') {
                    $scope.RightPanel = 'mask';
                } else {
                    $scope.RightPanel = 'placement';
                }
            } else if (item.current == 'imageart') {
                var box = $(e.target).parents('.img-art');
                dragBox._top = box.offset().top + box.height() / 2;
                dragBox._left = box.offset().left + box.width() / 2;
                $scope.RightPanel = 'material';
            } else if (item.current == 'textbox') {
                var box = $(e.target).parents('.c-xml-page-textboxe');
                dragBox._top = box.offset().top + box.height() / 2;
                dragBox._left = box.offset().left + box.width() / 2;
                $scope.RightPanel = 'text';
            } else if (item.current == 'textdocument') {
                var box = $(e.target).parents('.c-textdocument');
                dragBox._top = box.offset().top + box.height() / 2;
                dragBox._left = box.offset().left + box.width() / 2;
                $scope.RightPanel = 'textDoc';
            }

            document.addEventListener("mousemove", rotateBoxOn, true);
            document.addEventListener("mouseup", rotateBoxEnd, true);
        }

        function rotateBoxOn(e) {
            fn(e);
            if (rotateDragDown) {
                var angle = ModulesService.getAngle(dragBox._left, dragBox._top, e.clientX, e.clientY);
                if ($scope.selectBox.current == 'imagebox' || $scope.selectBox.current == 'textdocument') {
                    $scope.selectBox.rotation.angle = 360 - angle;
                } else {
                    $scope.selectBox.rotation.angle = angle;
                }
                $scope.selectBox.rotation.angle = adsorbAngle($scope.selectBox.rotation.angle)
                $scope.template.angle = $scope.selectBox.rotation.angle;
                $scope.$digest();
            }

            // e.stopPropagation();
            // e.preventDefault();
            return false;
        }

        function rotateBoxEnd(e) {
            fn(e);
            if (rotateDragDown) {
                // var angle = ModulesService.getAngle(dragBox._left, dragBox._top, e.clientX, e.clientY);
                // if ($scope.selectBox.current == 'imagebox' || $scope.selectBox.current == 'textdocument') {
                //     $scope.selectBox.rotation.angle = 360 - angle;
                // } else {
                //     $scope.selectBox.rotation.angle = angle;
                // }
                // $scope.template.angle = $scope.selectBox.rotation.angle;
            }
            rotateDragDown = false;
            dragBox = {};
            $(".rotate-icon" + $scope.template.levelI).removeClass('rotate-icon-active');
            document.removeEventListener("mousemove", rotateBoxOn, true);
            document.removeEventListener("mouseup", rotateBoxEnd, true);
            updateHistorys();
            $scope.$digest();
        }

        function moveTogether(currentPage, clientX, clientY) {
            angular.forEach(vm.CtrlKeyArray.imageboxes, function (imagebox, i) {
                // 当前页所有占位框
                var box = currentPage.levels.level[imagebox.levelI].imagebox;
                boxSetting(box, imagebox);
            })

            angular.forEach(vm.CtrlKeyArray.imagearts, function (imageart, i) {
                // 当前页所有素材
                var box = currentPage.levels.level[imageart.levelI].imageart;
                boxSetting(box, imageart);
            })

            angular.forEach(vm.CtrlKeyArray.textboxes, function (textbox, i) {
                // 当前页所有文字
                var box = currentPage.levels.level[textbox.levelI].textbox;
                boxSetting(box, textbox);
            })

            angular.forEach(vm.CtrlKeyArray.textdocuments, function (textdocument, i) {
                // 当前页所有文字
                var box = currentPage.levels.level[textdocument.levelI].textdocument;
                boxSetting(box, textdocument);
            })

            angular.forEach(vm.CtrlKeyArray.calTitleBoxs, function (calTitleBox, i) {
                // 当前页所有文字
                var box = currentPage.levels.level[calTitleBox.levelI].calTitleBox;
                boxSetting(box, calTitleBox);
            })

            angular.forEach(vm.CtrlKeyArray.calMonthBoxs, function (calMonthBox, i) {
                // 当前页所有文字
                var box = currentPage.levels.level[calMonthBox.levelI].calMonthBox;
                boxSetting(box, calMonthBox);
            })

            var imgWidth = 0, imgHeight = 0, height = 0, width = 0, x = 0, y = 0;

            function boxSetting(box, CtrlKeyBox) {
                if (!box || box.lock) {
                    return
                }
                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;
                imgWidth = box.geometry.width * currentPage.scale / currentPage.proportion;
                imgHeight = box.geometry.height * currentPage.scale / currentPage.proportion;
                height = currentPage.height * currentPage.scale;
                width = currentPage.width * currentPage.scale;
                x = CtrlKeyBox.geometry.x / currentPage.proportion * currentPage.scale + clientX;
                y = CtrlKeyBox.geometry.y / currentPage.proportion * currentPage.scale + clientY;
                // if (x <= 0) {
                //     x = 0;
                // }
                // if (x >= (width + lar - imgWidth)) {
                //     x = width + lar - imgWidth;
                // }
                // if (y <= 0) {
                //     y = 0;
                // }
                //
                // if (y >= (height + tab - imgHeight)) {
                //     y = height + tab - imgHeight;
                // }
                box.geometry.x = x / currentPage.scale * currentPage.proportion
                box.geometry.y = y / currentPage.scale * currentPage.proportion
            }
        }


        function moveTogetherEnd() {
            angular.forEach(vm.CtrlKeyArray.imageboxes, function (imagebox, i) {
                // 当前页所有占位框
                var box = $scope.currentPage.levels.level[imagebox.levelI].imagebox;
                ctrlKeySetting(box, imagebox);
            })

            angular.forEach(vm.CtrlKeyArray.imagearts, function (imageart, i) {
                // 当前页所有素材
                var box = $scope.currentPage.levels.level[imageart.levelI].imageart;
                ctrlKeySetting(box, imageart);
            })

            angular.forEach(vm.CtrlKeyArray.textboxes, function (textbox, i) {
                // 当前页所有文字
                var box = $scope.currentPage.levels.level[textbox.levelI].textbox;
                ctrlKeySetting(box, textbox);
            })

            angular.forEach(vm.CtrlKeyArray.textdocuments, function (textdocument, i) {
                // 当前页所有文字
                var box = $scope.currentPage.levels.level[textdocument.levelI].textdocument;
                ctrlKeySetting(box, textdocument);
            })

            angular.forEach(vm.CtrlKeyArray.calTitleBoxs, function (calTitleBox, i) {
                // 当前页所有文字
                var box = $scope.currentPage.levels.level[calTitleBox.levelI].calTitleBox;
                ctrlKeySetting(box, calTitleBox);
            })

            angular.forEach(vm.CtrlKeyArray.calMonthBoxs, function (calMonthBox, i) {
                // 当前页所有文字
                var box = $scope.currentPage.levels.level[calMonthBox.levelI].calMonthBox;
                ctrlKeySetting(box, calMonthBox);
            })

            function ctrlKeySetting(box, CtrlKeyBox) {
                if (!box) {
                    return
                }
                CtrlKeyBox.geometry.x = box.geometry.x;
                CtrlKeyBox.geometry.y = box.geometry.y;
            }
        }

        /**
         *放大占位框
         * @param e
         */
        var dragFlag = false;

        var isZoomDown = false;
        $scope.resizeStartRigth = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'rightBottom', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {
                imageboxCb(it, index)
                if ($scope.currentPage.levels.level && $scope.currentPage.levels.level[$scope.template.levelI]) {
                    var level = $scope.currentPage.levels.level[$scope.template.levelI];
                    if (level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier) {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        } else {
                            // maskScale(null, level.imagebox, $scope.template.levelI, level.imagebox.mask.resource.identifier)
                        }
                    } else {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        }
                    }
                }
            });
            // imageboxZoom($event, item, 'right', pro, index)
        }
        $scope.pageLength = function(pages){
            var _index = 0;
            pages.forEach(function (page) {
                if(!page.backup){
                    _index++
                }
            })
            return _index
        }
        $scope.resizeStartLeft = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'leftTop', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {
                imageboxCb(it, index)
                if ($scope.currentPage.levels.level && $scope.currentPage.levels.level[$scope.template.levelI]) {
                    var level = $scope.currentPage.levels.level[$scope.template.levelI];
                    if (level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier) {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        } else {
                            // maskScale(null, level.imagebox, $scope.template.levelI, level.imagebox.mask.resource.identifier)
                        }
                    } else {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        }
                    }
                }
            });
            // imageboxZoom($event, item, 'left', pro, index)
        }
        $scope.resizeStartTop = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'rightTop', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {
                imageboxCb(it, index)
                if ($scope.currentPage.levels.level && $scope.currentPage.levels.level[$scope.template.levelI]) {
                    var level = $scope.currentPage.levels.level[$scope.template.levelI];
                    if (level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier) {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        } else {
                            // maskScale(null, level.imagebox, $scope.template.levelI, level.imagebox.mask.resource.identifier)
                        }
                    } else {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        }
                    }
                }
            });
            // imageboxZoom($event, item, 'top', pro, index)
        }
        $scope.resizeStartBottom = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'leftBottom', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {
                imageboxCb(it, index)
                if ($scope.currentPage.levels.level && $scope.currentPage.levels.level[$scope.template.levelI]) {
                    var level = $scope.currentPage.levels.level[$scope.template.levelI];
                    if (level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier) {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        } else {
                            // maskScale(null, level.imagebox, $scope.template.levelI, level.imagebox.mask.resource.identifier)
                        }
                    } else {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        }
                    }
                }
            });
            // imageboxZoom($event, item, 'bottom', pro, index)
        }
        $scope.resizeStartLeft1 = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'left', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {
                imageboxCb(it, index)
                if ($scope.currentPage.levels.level && $scope.currentPage.levels.level[$scope.template.levelI]) {
                    var level = $scope.currentPage.levels.level[$scope.template.levelI];
                    if (level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier) {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        } else {
                            // maskScale(null, level.imagebox, $scope.template.levelI, level.imagebox.mask.resource.identifier)
                        }
                    } else {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        }
                    }
                }
            });
            // imageboxZoom1($event, item, 'left', pro, index)
        }
        $scope.resizeStartTop1 = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'top', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {
                imageboxCb(it, index)
                if ($scope.currentPage.levels.level && $scope.currentPage.levels.level[$scope.template.levelI]) {
                    var level = $scope.currentPage.levels.level[$scope.template.levelI];
                    if (level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier) {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        } else {
                            // maskScale(null, level.imagebox, $scope.template.levelI, level.imagebox.mask.resource.identifier)
                        }
                    } else {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        }
                    }
                }
            });
            // imageboxZoom1($event, item, 'top', pro, index)
        }
        $scope.resizeStartRight1 = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'right', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {
                imageboxCb(it, index)
                if ($scope.currentPage.levels.level && $scope.currentPage.levels.level[$scope.template.levelI]) {
                    var level = $scope.currentPage.levels.level[$scope.template.levelI];
                    if (level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier) {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        } else {
                            // maskScale(null, level.imagebox, $scope.template.levelI, level.imagebox.mask.resource.identifier)
                        }
                    } else {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        }
                    }
                }
            });
            // imageboxZoom1($event, item, 'right', pro, index)
        }
        $scope.resizeStartBottom1 = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'bottom', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {
                imageboxCb(it, index)
                if ($scope.currentPage.levels.level && $scope.currentPage.levels.level[$scope.template.levelI]) {
                    var level = $scope.currentPage.levels.level[$scope.template.levelI];
                    if (level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier) {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        } else {
                            // maskScale(null, level.imagebox, $scope.template.levelI, level.imagebox.mask.resource.identifier)
                        }
                    } else {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxImg(level.imagebox, function () {
                                // imageboxResourceCanvas(level.imagebox);
                            })
                        }
                    }
                }
            });
            // imageboxZoom1($event, item, 'bottom', pro, index)
        }

        function imageboxCb(item, index) {
            $scope.template.levelI = index || $scope.template.levelI;
            $scope.currentPage.levels.level[$scope.template.levelI].imagebox = item;
            if (vm.unit == 'mm') {
                $scope.pageValue.imagebox = {
                    width: PxTurnMm(item.geometry.width).toFixed(2) - 0,
                    height: PxTurnMm(item.geometry.height).toFixed(2) - 0,
                    x: PxTurnMm(item.geometry.x).toFixed(2) - 0,
                    y: PxTurnMm(item.geometry.y).toFixed(2) - 0,
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.imagebox = {
                    width: PxTurnMm(item.geometry.width / 25.4 * vm.windowDpiW),
                    height: PxTurnMm(item.geometry.height / 25.4 * vm.windowDpiW),
                    x: PxTurnMm(item.geometry.x / 25.4 * vm.windowDpiW),
                    y: PxTurnMm(item.geometry.y / 25.4 * vm.windowDpiW),
                }
            } else if (vm.unit == 'in') {
                $scope.pageValue.imagebox = {
                    width: PxTurnIn(item.geometry.width),
                    height: PxTurnIn(item.geometry.height),
                    x: PxTurnIn(item.geometry.x),
                    y: PxTurnIn(item.geometry.y),
                }
            }
            $scope.pxInsufficient(item, 'imagebox');
        }

        function imageboxZoom($event, item, direction, pro, index) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
            var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
            var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
            var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
            var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
            var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var _scale = item.image ? angular.copy(item.image.scale) : 1;
            var _left = $($event.target).parents('.selected').offset().left + (nw / 2);
            var _top = $($event.target).parents('.selected').offset().top + (nh / 2);

            var x3 = Math.abs(_left - $event.clientX);
            var y3 = Math.abs(_top - $event.clientY);
            var distance = Math.sqrt(Math.pow(x3, 2) + Math.pow(y3, 2));
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                var nl = 0;
                var x = Math.abs(_left + nl - e.clientX);
                var y = Math.abs(_top - e.clientY);
                var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
                nl = (parseInt(z - distance)) / pro;
                var _w = (Number(nw) + Number(nl));
                var _h = _w / nw * nh;
                item.geometry.width = _w / pro;
                item.geometry.height = _h / pro;
                item.geometry.x = (x2 - (_w - nw) / 2) / pro;
                item.geometry.y = (y2 - (_h - nh) / 2) / pro;
                if (item.image) {
                    item.image.width = _w / nw * nw2 / pro;
                    item.image.height = _w / nw * nh2 / pro;
                    item.image.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / pro;
                    item.image.offsety = (_h / (nh / nh1)) / (nh1 / y1) / pro;
                }
                $scope.template.levelI = index || $scope.template.levelI;
                $scope.currentPage.levels.level[$scope.template.levelI].imagebox = item;
                if (vm.unit == 'mm') {
                    $scope.pageValue.imagebox = {
                        width: PxTurnMm(item.geometry.width).toFixed(2) - 0,
                        height: PxTurnMm(item.geometry.height).toFixed(2) - 0,
                        x: PxTurnMm(item.geometry.x).toFixed(2) - 0,
                        y: PxTurnMm(item.geometry.y).toFixed(2) - 0,
                    }
                } else if (vm.unit == 'px') {
                    $scope.pageValue.imagebox = {
                        width: PxTurnMm(item.geometry.width / 25.4 * vm.windowDpiW),
                        height: PxTurnMm(item.geometry.height / 25.4 * vm.windowDpiW),
                        x: PxTurnMm(item.geometry.x / 25.4 * vm.windowDpiW),
                        y: PxTurnMm(item.geometry.y / 25.4 * vm.windowDpiW),
                    }
                } else if (vm.unit == 'in') {
                    $scope.pageValue.imagebox = {
                        width: PxTurnIn(item.geometry.width),
                        height: PxTurnIn(item.geometry.height),
                        x: PxTurnIn(item.geometry.x),
                        y: PxTurnIn(item.geometry.y),
                    }
                }
                $scope.pxInsufficient(item, 'imagebox');
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                $event.stopPropagation();
                isZoomDown = false;
                if ($scope.currentPage.levels.level && $scope.currentPage.levels.level[$scope.template.levelI]) {
                    var level = $scope.currentPage.levels.level[$scope.template.levelI];
                    if (level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier) {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            // imageboxResourceCanvas(level.imagebox);
                        } else {
                            // maskScale(null, level.imagebox, $scope.template.levelI, level.imagebox.mask.resource.identifier)
                        }
                    }
                }

            }
        }

        function imageboxZoom1($event, item, direction, pro, index) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
            var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
            var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
            var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
            var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
            var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var _scale = item.image ? angular.copy(item.image.scale) : 1;
            var _left = $($event.target).parents('.selected').offset().left + (nw / 2);
            var _top = $($event.target).parents('.selected').offset().top + (nh / 2);

            var x3 = Math.abs(_left - $event.clientX);
            var y3 = Math.abs(_top - $event.clientY);
            var distance = Math.sqrt(Math.pow(x3, 2) + Math.pow(y3, 2));
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nl = 0;
                var x = Math.abs(_left + nl - e.clientX);
                var y = Math.abs(_top - e.clientY);
                var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
                nl = (parseInt(z - distance)) / pro;
                var _w = (Number(nw) + Number(nl));
                var _h = (Number(nh) + Number(nl));
                if (item.image) {
                    item.image.offsetx = 0;
                    item.image.offsety = 0;
                }
                if (direction == 'left' || direction == 'right') {
                    item.geometry.width = _w / pro;
                    item.geometry.x = (x2 - (_w - nw) / 2) / pro;
                }
                if (direction == 'top' || direction == 'bottom') {
                    item.geometry.height = _h / pro;
                    item.geometry.y = (y2 - (_h - nh) / 2) / pro;
                }
                $scope.template.levelI = index || $scope.template.levelI;
                $scope.currentPage.levels.level[$scope.template.levelI].imagebox = item;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                $event.stopPropagation();
                if (isZoomDown) {
                    vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, calMonthBox: {}, calTitleBox: {}};
                    vm.singleArray.imagebox = angular.copy(item);
                    dragMask = {};
                    updateHistorys();
                    var pressImg = item;
                    if (pressImg.image && pressImg.image.resource.identifier) {
                        pressImg.image.offsetx = 0;
                        pressImg.image.offsety = 0;
                        getBackgroundSource1(pressImg.image.resource, pressImg.geometry.width, pressImg.geometry.height, pressImg.image);
                    }

                    if (pressImg.mask && pressImg.mask.resource.identifier) {
                        if (pressImg.image && pressImg.image.resource.identifier) {
                            // imageboxResourceCanvas(pressImg);
                        } else {
                            // maskScale($event, pressImg, $scope.template.levelI, pressImg.mask.resource.identifier)
                        }
                    }
                    isZoomDown = false;
                    $scope.$digest();
                }
            }
        }
        $scope.updateBoxParameter = function (p1, p2) {
            var box = {}, boxW = null, boxH = null, value = {};
            var level = angular.copy($scope.currentPage.levels.level[$scope.template.levelI]);
            if ($scope.RightPanel == 'placement' || $scope.RightPanel == 'mask') {
                box = level.imagebox;
                value = $scope.pageValue.imagebox;
            } else if ($scope.RightPanel == 'material') {
                box = level.imageart;
                value = $scope.pageValue.imageart;
                boxW = angular.copy(box.resource.width);
                boxH = angular.copy(box.resource.height);
            } else if ($scope.RightPanel == 'text') {
                box = level.textbox;
                value = $scope.pageValue.textbox;
            } else if ($scope.RightPanel == 'textDoc') {
                box = level.textdocument;
                value = $scope.pageValue.textdocument;
            } else if ($scope.RightPanel == 'calTitleBox') {
                box = level.calTitleBox;
                value = $scope.pageValue.calTitleBox;
                boxW = angular.copy(box.geometry.width);
                boxH = angular.copy(box.geometry.height);
            } else if ($scope.RightPanel == 'calMonthBox') {
                box = level.calMonthBox;
                value = $scope.pageValue.calMonthBox;
                boxW = angular.copy(box.geometry.width);
                boxH = angular.copy(box.geometry.height);
            }
            if (box.lock) {
                return
            }

            if (vm.unit == 'mm') {
                box.geometry = {
                    width: MmTurnPx(value.width),
                    height: MmTurnPx(value.height),
                    x: MmTurnPx(value.x),
                    y: MmTurnPx(value.y)
                }
                if (p1 == 'width') {
                    if($scope.RightPanel == 'material'){
                        box.geometry.height = box.geometry.width * boxH / boxW;
                        value.height = PxTurnMm(box.geometry.height);
                        box.offsetx = 0;
                        box.offsety = 0;
                        box.scale = 1;
                    }else if($scope.RightPanel == 'calTitleBox'){
                        box.geometry.height = box.geometry.width * boxH / boxW;
                        value.height = PxTurnMm(box.geometry.height);
                    }else if($scope.RightPanel == 'calMonthBox'){
                        box.geometry.height = box.geometry.width * boxH / boxW;
                        var isCeil = box.geometry.height>boxH;
                        calMonthBoxW(box, isCeil);
                        value.height = PxTurnMm(box.geometry.height);
                        value.width = PxTurnMm(box.geometry.width);
                    }
                } else if (p1 == 'height') {
                    if($scope.RightPanel == 'material'){
                        box.geometry.width = box.geometry.height * boxW / boxH;
                        value.width = PxTurnMm(box.geometry.width);
                        box.offsetx = 0;
                        box.offsety = 0;
                        box.scale = 1;
                    }else if($scope.RightPanel == 'calTitleBox'){
                        box.geometry.width = box.geometry.height * boxW / boxH;
                        value.width = PxTurnMm(box.geometry.width);
                    }else if($scope.RightPanel == 'calMonthBox'){
                        box.geometry.width = box.geometry.height * boxW / boxH;
                        var isCeil = box.geometry.width>boxW;
                        calMonthBoxW(box, isCeil);
                        value.width = PxTurnMm(box.geometry.width);
                        value.height = PxTurnMm(box.geometry.height);
                    }
                }
            }
            if (vm.unit == 'px') {
                box.geometry = value;
                if (p1 == 'width') {
                    if($scope.RightPanel == 'material') {
                        box.geometry.height = box.geometry.width * boxH / boxW;
                        value.height = box.geometry.height;
                        box.offsetx = 0;
                        box.offsety = 0;
                        box.scale = 1;
                    }else if($scope.RightPanel == 'calTitleBox'){
                        box.geometry.height = box.geometry.width * boxH / boxW;
                        value.height = box.geometry.height;
                    }else if($scope.RightPanel == 'calMonthBox'){
                        box.geometry.height = box.geometry.width * boxH / boxW;
                        var isCeil = box.geometry.height>boxH;
                        calMonthBoxW(box, isCeil);
                        value.width = box.geometry.width;
                        value.height = box.geometry.height;
                    }
                } else if (p1 == 'height') {
                    if($scope.RightPanel == 'material') {
                        box.geometry.width = box.geometry.height * boxW / boxH;
                        value.width = box.geometry.width;
                        box.offsetx = 0;
                        box.offsety = 0;
                        box.scale = 1;
                    }else if($scope.RightPanel == 'calTitleBox'){
                        box.geometry.width = box.geometry.height * boxW / boxH;
                        value.width = box.geometry.width;
                    }else if($scope.RightPanel == 'calMonthBox'){
                        box.geometry.width = box.geometry.height * boxW / boxH;
                        var isCeil = box.geometry.width>boxW;
                        calMonthBoxW(box, isCeil);
                        value.width = box.geometry.width;
                        value.height = box.geometry.height;
                    }
                }
            }
            if (vm.unit == 'in') {
                box.geometry = {
                    width: InTurnPx(value.width),
                    height: InTurnPx(value.height),
                    x: InTurnPx(value.x),
                    y: InTurnPx(value.y)
                }
                if (p1 == 'width') {
                    if($scope.RightPanel == 'material') {
                        box.geometry.height = box.geometry.width * boxH / boxW;
                        value.height = PxTurnIn(box.geometry.height);
                        box.offsetx = 0;
                        box.offsety = 0;
                        box.scale = 1;
                    }else if($scope.RightPanel == 'calTitleBox'){
                        box.geometry.height = box.geometry.width * boxH / boxW;
                        value.height = PxTurnIn(box.geometry.height);
                    }else if($scope.RightPanel == 'calMonthBox'){
                        box.geometry.height = box.geometry.width * boxH / boxW;
                        var isCeil = box.geometry.height>boxH;
                        calMonthBoxW(box, isCeil);
                        value.width = PxTurnIn(box.geometry.width);
                        value.height = PxTurnIn(box.geometry.height);
                    }
                } else if (p1 == 'height') {
                    if($scope.RightPanel == 'material') {
                        box.geometry.width = box.geometry.height * boxW / boxH;
                        value.width = PxTurnIn(box.geometry.width);
                        box.offsetx = 0;
                        box.offsety = 0;
                        box.scale = 1;
                    }else if($scope.RightPanel == 'calTitleBox'){
                        box.geometry.width = box.geometry.height * boxW / boxH;
                        value.width = PxTurnIn(box.geometry.width);
                    }else if($scope.RightPanel == 'calMonthBox'){
                        box.geometry.width = box.geometry.height * boxW / boxH;
                        var isCeil = box.geometry.width>boxW;
                        calMonthBoxW(box, isCeil);
                        value.width = PxTurnIn(box.geometry.width);
                        value.height = PxTurnIn(box.geometry.height);
                    }
                }
            }
            $scope.currentPage.levels.level[$scope.template.levelI] = level;
            if (level.imagebox && level.imagebox.image) {
                imageboxImg(level.imagebox,function(){
                    $scope.$apply();
                })
            }
            var _pageValue = angular.copy($scope.pageValue);
            $scope.pageValue = "";
            $timeout(function () {
                $scope.pageValue = _pageValue;
                if ($scope.currentPage.isScene) {
                    $scope.selectScene();
                } else {
                    updateHistorys();
                }
            })
        };
        $scope.updatetextDocParameter = function (p1, p2) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];

            var box = level.textdocument;
            textdocW = angular.copy(box.geometry.width);
            textdocH = angular.copy(box.geometry.height);

            if (box.lock) {
                return
            }

            if (vm.unit == 'mm') {
                box.geometry = {
                    width: MmTurnPx($scope.pageValue.textdocument.width),
                    height: MmTurnPx($scope.pageValue.textdocument.height),
                    x: MmTurnPx($scope.pageValue.textdocument.x),
                    y: MmTurnPx($scope.pageValue.textdocument.y)
                }
                if (p1 == 'width') {
                    box.geometry.height = box.geometry.width * textdocH / textdocW;
                    $scope.pageValue.textdocument.height = PxTurnMm(box.geometry.height);
                } else {
                    box.geometry.width = box.geometry.height * textdocW / textdocH;
                    $scope.pageValue.textdocument.width = PxTurnMm(box.geometry.width);
                }
            }
            if (vm.unit == 'px') {
                box.geometry = {
                    width: $scope.pageValue.textdocument.width,
                    height: $scope.pageValue.textdocument.height,
                    x: $scope.pageValue.textdocument.x,
                    y: $scope.pageValue.textdocument.y
                }
                if (p1 == 'width') {
                    box.geometry.height = box.geometry.width * textdocH / textdocW;
                    $scope.pageValue.textdocument.height = box.geometry.height;
                } else {
                    box.geometry.width = box.geometry.height * textdocW / textdocH;
                    $scope.pageValue.textdocument.width = box.geometry.width;
                }
            }
            if (vm.unit == 'in') {
                box.geometry = {
                    width: InTurnPx($scope.pageValue.textdocument.width),
                    height: InTurnPx($scope.pageValue.textdocument.height),
                    x: InTurnPx($scope.pageValue.textdocument.x),
                    y: InTurnPx($scope.pageValue.textdocument.y)
                }
                if (p1 == 'width') {
                    box.geometry.height = box.geometry.width * textdocH / textdocW;
                    $scope.pageValue.textdocument.height = PxTurnIn(box.geometry.height);
                } else {
                    box.geometry.width = box.geometry.height * textdocW / textdocH;
                    $scope.pageValue.textdocument.width = PxTurnIn(box.geometry.width);
                }
            }

            textdocument = angular.copy(box);
            resizeLevelWaH();

            if ($scope.currentPage.isScene) {
                $scope.selectScene();
            } else {
                updateHistorys();
            }

        };

        //图片占位框旋转角度
        $scope.updateRotate = function () {
            var box = {};
            if ($scope.RightPanel == 'placement' || $scope.RightPanel == 'mask') {
                box = $scope.currentPage.levels.level[$scope.template.levelI].imagebox.rotation;
            } else if ($scope.RightPanel == 'material') {
                box = $scope.currentPage.levels.level[$scope.template.levelI].imageart.rotation;
            } else if ($scope.RightPanel == 'text') {
                box = $scope.currentPage.levels.level[$scope.template.levelI].textbox.rotation;
            } else if ($scope.RightPanel == 'barcode') {
                box = $scope.currentPage.barcode.rotation;
            } else if ($scope.RightPanel == 'qrcode') {
                box = $scope.currentPage.qrcode.rotation;
            } else if ($scope.RightPanel == 'textDoc') {
                box = $scope.currentPage.levels.level[$scope.template.levelI].textdocument.rotation;
            }

            if (box.lock) {
                return
            }

            box.angle = $scope.template.angle;
            updateHistorys();
        };

        $scope.imageartToImagebox = function () {
            var box = angular.copy($scope.currentPage.levels.level[$scope.template.levelI].imageart);
            var _seq = angular.copy($scope.currentPage.levels.level[$scope.template.levelI].seq);
            vm.unit = 'mm';
            $scope.currentPage.levels.level.splice($scope.template.levelI, 1);
            var currentLevel = addLevel($scope.currentPage);
            currentLevel.seq = _seq;

            var angle = 0;
            if (box.rotation.angle != 0) {
                angle = 360 - box.rotation.angle
            }

            currentLevel.imagebox = ({
                geometry: box.geometry,
                rotation: {
                    angle: angle
                },
                mask: {
                    resource: {
                        identifier: null,
                        provider: 'qiniu'
                    }
                },
                imgUrl: 'content/images/mask_souce2.png',
                maskUrl: 'content/images/mask_souce2.png',
                bgImg: '',
                unit: 'mm',
                levelI: $scope.template.levelI,
                pageI: $scope.template.pageI,
                current: 'imagebox',
                lock: false
            });

            $scope.selectBox = currentLevel.imagebox;
            $scope.RightPanel = 'placement'
            getImageboxLen();
            $scope.pageValue.imagebox = {
                width: PxTurnMm($scope.selectBox.geometry.width).toFixed(2) - 0,
                height: PxTurnMm($scope.selectBox.geometry.height).toFixed(2) - 0,
                x: PxTurnMm($scope.selectBox.geometry.x).toFixed(2) - 0,
                y: PxTurnMm($scope.selectBox.geometry.y).toFixed(2) - 0
            }

            updateHistorys();
        }

        $scope.delPic = function () {
            // if (!$scope.currentPage.isScene) {
            var box = null;
            if ($scope.RightPanel == 'placement' || $scope.RightPanel == 'mask') {
                box = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;
                if (box.image && box.image.resource && box.image.resource.identifier) {
                    box.image = null;
                }
                if (box.mask && box.mask.resource && box.mask.resource.identifier) {
                }
                updateHistorys();
            }
        }

        /* ==========================================================================
         素材处理
         ========================================================================== */
        // 素材点击时
        $scope.selectArt = function (e, $index, art) {
            fn(e);
            if (vm.unit == 'mm') {
                $scope.pageValue.imageart = {
                    width: PxTurnMm(art.geometry.width).toFixed(2) - 0,
                    height: PxTurnMm(art.geometry.height).toFixed(2) - 0,
                    x: PxTurnMm(art.geometry.x).toFixed(2) - 0,
                    y: PxTurnMm(art.geometry.y).toFixed(2) - 0
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.imageart = {
                    width: art.geometry.width,
                    height: art.geometry.height,
                    x: art.geometry.x,
                    y: art.geometry.y
                }
            } else if (vm.unit == 'in') {
                $scope.pageValue.imageart = {
                    width: PxTurnIn(art.geometry.width).toFixed(2) - 0,
                    height: PxTurnIn(art.geometry.height).toFixed(2) - 0,
                    x: PxTurnIn(art.geometry.x).toFixed(2) - 0,
                    y: PxTurnIn(art.geometry.y).toFixed(2) - 0
                }
            }
            $scope.template.angle = art.rotation.angle;
            $scope.template.transparency = art.transparency * 100;
            $(elem).val(art.transparency);

            art.current = 'imageart';
            $scope.selectBox = art;

            $("#menus").fadeOut();
            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                art.ctrlKey = true;
                art.levelI = $index;
                vm.CtrlKeyArray.imagearts[$index] = angular.copy(art);
                ctrlAddSingleArray();
            } else {
                if (!art.ctrlKey) {
                    $scope.clearCtrlKey();
                    vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, calMonthBox: {}, calTitleBox: {}};
                }
                vm.singleArray.imageart = angular.copy(art);
            }
            $scope.template.levelI = $index;
            // $scope.selPanel = 'material';
            $scope.RightPanel = 'material';
            e.stopPropagation();
            vm.selectElementCopy = angular.copy($scope.currentPage.levels.level[$scope.template.levelI]);
        };

        window.doubleClickClipart = function (e, item) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            var imageart = $scope.currentPage.levels.level[$scope.template.levelI].imageart;
            $scope.template.angle = imageart.rotation.angle;

            if (imageart.lock) {
                return
            }
            vm.pageMarginTop = parseFloat($(".page-item").css('margin-top'));
            vm.isImageartTrim = true;
            vm.imageartTrim = angular.copy(imageart);
            vm.imageartTrim.geometry.width1 = angular.copy(imageart.geometry.width);
            vm.imageartTrim.geometry.height1 = angular.copy(imageart.geometry.height);
            vm.imageartTrim.geometry.x1 = angular.copy(imageart.geometry.x);
            vm.imageartTrim.geometry.y1 = angular.copy(imageart.geometry.y);

            var pro = $scope.currentPage.scale / $scope.currentPage.proportion;
            if (vm.imageartTrim.geometry.width * pro == vm.imageartTrim.geometry.width1 * pro * vm.imageartTrim.scale) {
                vm.imageartTrim.geometry.width = vm.imageartTrim.geometry.width * 0.8;
                vm.imageartTrim.geometry.height = vm.imageartTrim.geometry.height * 0.8;
                vm.imageartTrim.geometry.x = Number(vm.imageartTrim.geometry.x) + Number(vm.imageartTrim.geometry.width / 0.8 * 0.2 / 2);
                vm.imageartTrim.geometry.y = Number(vm.imageartTrim.geometry.y) + Number(vm.imageartTrim.geometry.height / 0.8 * 0.2 / 2);
            }
            $scope.$digest();
        }

        //拖动素材裁切
        var isImageartTrim = false;
        $scope.imageartTrimdown = function ($event) {
            fn($event)
            var x = $event.clientX;
            var y = $event.clientY;
            var pro = $scope.currentPage.scale / $scope.currentPage.proportion;
            var l = vm.imageartTrim.geometry.x * pro;
            var t = vm.imageartTrim.geometry.y * pro;
            var r = (vm.imageartTrim.geometry.width1 * vm.imageartTrim.scale - vm.imageartTrim.geometry.width) * pro;
            var b = (($("#c-xml-imageart-trim-img").find('img').height()) / pro - vm.imageartTrim.geometry.height) * pro;

            var x1 = (Number(vm.imageartTrim.geometry.x1) + Number(vm.imageartTrim.offsetx)) * pro;
            var y1 = (Number(vm.imageartTrim.geometry.y1) + Number(vm.imageartTrim.offsety)) * pro;
            var x2 = vm.imageartTrim.geometry.x1 * pro;
            var y2 = vm.imageartTrim.geometry.y1 * pro;
            var x3 = vm.imageartTrim.offsetx * pro;
            var y3 = vm.imageartTrim.offsety * pro;
            //开关打开
            isImageartTrim = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageartTrim == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - (x - l);
                var nt = ny - (y - t);


                if (nl < x1) {
                    nl = x1
                }
                ;
                if (nl > Number(r) + Number(x1)) {
                    nl = Number(r) + Number(x1)
                }
                ;
                if (nt < y1) {
                    nt = y1
                }
                ;
                if (nt > Number(b) + Number(y1)) {
                    nt = Number(b) + Number(y1)
                }
                ;

                vm.imageartTrim.geometry.x = nl / pro;
                vm.imageartTrim.geometry.y = nt / pro;

                $("#c-xml-imageart-trim-frame").css({
                    left: nl + 'px',
                    top: nt + 'px',
                });
                $("#c-xml-imageart-trim-frame-img").css({
                    left: Number(x2 - nl) + Number(x3) + 'px',
                    top: Number(y2 - nt) + Number(y3) + 'px',
                });
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageartTrim = false;
                $event.stopPropagation();
            }
        }

        //素材裁切放大缩小
        var isImageartTrimRight = false;
        $scope.imageartTrimRight = function ($event, item) {
            fn($event)
            var x = $event.clientX;
            var y = $event.clientY;
            var pro = $scope.currentPage.scale / $scope.currentPage.proportion;
            //获取w和h
            // var nw = $($event.target).parent().width();
            // var nh = $($event.target).parent().height();
            var nw = Number(item.geometry.width * pro) + Number(item.geometry.x * pro);
            var nh = Number(item.geometry.height * pro) + Number(item.geometry.y * pro);
            var _l = item.geometry.x * pro;
            var _t = item.geometry.y * pro;


            // var r = Number($("#c-xml-imageart-trim-div").offset().left) + Number(vm.imageartTrim.geometry.width1 * pro * vm.imageartTrim.scale);
            // var b = Number($("#c-xml-imageart-trim-div").offset().top) + Number($("#c-xml-imageart-trim-img").find('img').height());

            var r = Number(item.geometry.x1 * pro) + Number(item.offsetx * pro) + Number(item.geometry.width1 * pro * item.scale);
            var b = Number(item.geometry.y1 * pro) + Number(item.offsety * pro) + Number($("#imageartTrimRight1").height());
            //开关打开
            isImageartTrimRight = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageartTrimRight == false) {
                    return;
                }

                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = Number(nw) + Number(nx - x);
                var nt = Number(nh) + Number(ny - y);

                if (nl >= r) {
                    nl = r
                }
                if (nt >= b) {
                    nt = b
                }

                item.geometry.width = (nl - _l) / pro;
                item.geometry.height = (nt - _t) / pro;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageartTrimRight = false;
                $event.stopPropagation();
            }
        }
        $scope.imageartTrimTrueClick = function () {
            vm.isImageartTrim = false;
            var pro = $scope.currentPage.scale / $scope.currentPage.proportion
            vm.imageartTrim.scale = (vm.imageartTrim.geometry.width1 * pro * vm.imageartTrim.scale) / (vm.imageartTrim.geometry.width * pro);
            vm.imageartTrim.offsetx = (Number(-(vm.imageartTrim.geometry.x - vm.imageartTrim.geometry.x1)) + Number(vm.imageartTrim.offsetx)).toFixed(2) - 0;
            vm.imageartTrim.offsety = (Number(-(vm.imageartTrim.geometry.y - vm.imageartTrim.geometry.y1)) + Number(vm.imageartTrim.offsety)).toFixed(2) - 0;
            vm.imageartTrim.geometry.x = $scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.x;
            vm.imageartTrim.geometry.y = $scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.y;
            if (vm.imageartTrim.offsetx > 0) {
                vm.imageartTrim.offsetx = 0;
            }
            if (vm.imageartTrim.offsety > 0) {
                vm.imageartTrim.offsety = 0;
            }
            if (vm.imageartTrim.scale < 1) {
                vm.imageartTrim.scale = 1;
            }
            $scope.currentPage.levels.level[$scope.template.levelI].imageart = vm.imageartTrim;
        }
        $scope.imageartTrimFalseClick = function () {
            vm.isImageartTrim = false;
        }

        //素材缩放right
        var isZoomDown = false;
        $scope.imageartdownZoomRight = function ($event, item, pro) {
            // if(item.movable){return}
            fn($event)
            $timeout(function () {
                editorBoxZoom($event, item, 'rightBottom', pro, $($event.target).parents('.selected'), function (it) {
                    calMonthBoxW();
                    $scope.$apply();
                }, function (it) {
                    calMonthBoxW();
                    if (vm.unit == 'mm') {
                        $scope.pageValue.imageart = {
                            width: PxTurnMm(item.geometry.width).toFixed(2) - 0,
                            height: PxTurnMm(item.geometry.height).toFixed(2) - 0,
                            x: PxTurnMm(item.geometry.x).toFixed(2) - 0,
                            y: PxTurnMm(item.geometry.y).toFixed(2) - 0,
                        }
                    } else if (vm.unit == 'px') {
                        $scope.pageValue.imageart = {
                            width: PxTurnMm(item.geometry.width / 25.4 * vm.windowDpiW),
                            height: PxTurnMm(item.geometry.height / 25.4 * vm.windowDpiW),
                            x: PxTurnMm(item.geometry.x / 25.4 * vm.windowDpiW),
                            y: PxTurnMm(item.geometry.y / 25.4 * vm.windowDpiW),
                        }
                    } else if (vm.unit == 'in') {
                        $scope.pageValue.imageart = {
                            width: PxTurnIn(item.geometry.width).toFixed(2) - 0,
                            height: PxTurnIn(item.geometry.height).toFixed(2) - 0,
                            x: PxTurnIn(item.geometry.x).toFixed(2) - 0,
                            y: PxTurnIn(item.geometry.y).toFixed(2) - 0,
                        }
                    }
                    $scope.pxInsufficient(item, 'imageart');
                    $scope.$apply();
                });
            })
        }
        //素材缩放left
        $scope.imageartdownZoomLeft = function ($event, item, pro) {
            // if(item.movable){return}
            fn($event)
            editorBoxZoom($event, item, 'leftTop', pro, $($event.target).parents('.selected'), function (it) {
                calMonthBoxW();
                $scope.$apply();
            }, function (it) {
                calMonthBoxW();
                if (vm.unit == 'mm') {
                    $scope.pageValue.imageart = {
                        width: PxTurnMm(item.geometry.width).toFixed(2) - 0,
                        height: PxTurnMm(item.geometry.height).toFixed(2) - 0,
                        x: PxTurnMm(item.geometry.x).toFixed(2) - 0,
                        y: PxTurnMm(item.geometry.y).toFixed(2) - 0,
                    }
                } else if (vm.unit == 'px') {
                    $scope.pageValue.imageart = {
                        width: PxTurnMm(item.geometry.width / 25.4 * vm.windowDpiW),
                        height: PxTurnMm(item.geometry.height / 25.4 * vm.windowDpiW),
                        x: PxTurnMm(item.geometry.x / 25.4 * vm.windowDpiW),
                        y: PxTurnMm(item.geometry.y / 25.4 * vm.windowDpiW),
                    }
                } else if (vm.unit == 'in') {
                    $scope.pageValue.imageart = {
                        width: PxTurnIn(item.geometry.width).toFixed(2) - 0,
                        height: PxTurnIn(item.geometry.height).toFixed(2) - 0,
                        x: PxTurnIn(item.geometry.x).toFixed(2) - 0,
                        y: PxTurnIn(item.geometry.y).toFixed(2) - 0,
                    }
                }
                $scope.pxInsufficient(item, 'imageart');
            });
        }
        //素材缩放top
        $scope.imageartdownZoomTop = function ($event, item, pro) {
            // if(item.movable){return}
            fn($event)
            editorBoxZoom($event, item, 'rightTop', pro, $($event.target).parents('.selected'), function (it) {
                calMonthBoxW();
                $scope.$apply();
            }, function (it) {
                calMonthBoxW();
                if (vm.unit == 'mm') {
                    $scope.pageValue.imageart = {
                        width: PxTurnMm(item.geometry.width).toFixed(2) - 0,
                        height: PxTurnMm(item.geometry.height).toFixed(2) - 0,
                        x: PxTurnMm(item.geometry.x).toFixed(2) - 0,
                        y: PxTurnMm(item.geometry.y).toFixed(2) - 0,
                    }
                } else if (vm.unit == 'px') {
                    $scope.pageValue.imageart = {
                        width: PxTurnMm(item.geometry.width / 25.4 * vm.windowDpiW),
                        height: PxTurnMm(item.geometry.height / 25.4 * vm.windowDpiW),
                        x: PxTurnMm(item.geometry.x / 25.4 * vm.windowDpiW),
                        y: PxTurnMm(item.geometry.y / 25.4 * vm.windowDpiW),
                    }
                } else if (vm.unit == 'in') {
                    $scope.pageValue.imageart = {
                        width: PxTurnIn(item.geometry.width).toFixed(2) - 0,
                        height: PxTurnIn(item.geometry.height).toFixed(2) - 0,
                        x: PxTurnIn(item.geometry.x).toFixed(2) - 0,
                        y: PxTurnIn(item.geometry.y).toFixed(2) - 0,
                    }
                }
                $scope.pxInsufficient(item, 'imageart');
            });
        }
        //素材缩放bottom
        $scope.imageartdownZoomBottom = function ($event, item, pro) {
            // if(!item.movable){return}
            fn($event)
            editorBoxZoom($event, item, 'leftBottom', pro, $($event.target).parents('.selected'), function (it) {
                calMonthBoxW();
                $scope.$apply();
            }, function (it) {
                calMonthBoxW();
                if (vm.unit == 'mm') {
                    $scope.pageValue.imageart = {
                        width: PxTurnMm(item.geometry.width).toFixed(2) - 0,
                        height: PxTurnMm(item.geometry.height).toFixed(2) - 0,
                        x: PxTurnMm(item.geometry.x).toFixed(2) - 0,
                        y: PxTurnMm(item.geometry.y).toFixed(2) - 0,
                    }
                } else if (vm.unit == 'px') {
                    $scope.pageValue.imageart = {
                        width: PxTurnMm(item.geometry.width / 25.4 * vm.windowDpiW),
                        height: PxTurnMm(item.geometry.height / 25.4 * vm.windowDpiW),
                        x: PxTurnMm(item.geometry.x / 25.4 * vm.windowDpiW),
                        y: PxTurnMm(item.geometry.y / 25.4 * vm.windowDpiW),
                    }
                } else if (vm.unit == 'in') {
                    $scope.pageValue.imageart = {
                        width: PxTurnIn(item.geometry.width).toFixed(2) - 0,
                        height: PxTurnIn(item.geometry.height).toFixed(2) - 0,
                        x: PxTurnIn(item.geometry.x).toFixed(2) - 0,
                        y: PxTurnIn(item.geometry.y).toFixed(2) - 0,
                    }
                }
                $scope.pxInsufficient(item, 'imageart');
            });
        }
        function calMonthBoxW(item, isCeil){
            if(item && item.current == 'calMonthBox'){
                var _width = angular.copy(item.geometry.width);
                var _height = angular.copy(item.geometry.height);
                if(isCeil){
                    item.geometry.width = Math.ceil(item.geometry.width/7) * 7;
                }else{
                    item.geometry.width = Math.floor(item.geometry.width/7) * 7;
                }
                item.geometry.height = item.geometry.width*_height/_width;
            }
        }

        /* ==========================================================================
         文字处理
         ========================================================================== */
        //增加文本框
        $scope.addText = function (size) {
            if(vm.textEditor){
                return
            }
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var currentLevel = addLevel(currentPage);
            $scope.RightPanel = 'text';

            vm.text = '请输入文字';
            $scope.size = size;

            currentLevel.textbox = ({
                transparency: 1,
                geometry: {
                    width: '',
                    height: '',
                    x: currentPage.trimbox.x + 20 + $scope.template.levelI * 2,
                    y: currentPage.trimbox.y + 20 + $scope.template.levelI * 2
                },
                rotation: {
                    angle: 0
                },
                style: {
                    font: vm.defaultFont.family,
                    size: size,
                    color: '#000000',
                    bold: false,
                    italic: false,
                    lineheight: size,
                    letterspacing: 0,
                    align: 'left',
                    writingmode: 'horizontal-tb'
                },
                fontuuid: vm.defaultFont.uuid,
                identifier: vm.url + vm.defaultFont.fontStyles[0].identifier,
                editable: true,
                movable: true,
                version: '2.0',
                text: '请输入文字',
                unit: 'mm',
                // index: currentPage.textboxes.textbox.length,
                levelI: $scope.template.levelI,
                pageI: $scope.template.pageI,
                current: 'textbox',
                lock: false
            });


            getFont(currentLevel.textbox,FontManagement);
            fontSupport(FontManagement, currentLevel.textbox);
            $(".block-width,.block-height").css({left: 0});
            $scope.template.letterspacing = $scope.template.lineheight = 0;

            $(".space-slider").addClass('hide1');
            $(".nav_spacing").removeClass('nav-spacing-active');

            vm.fontHide = currentLevel.textbox;
            fontStyle(currentLevel.textbox);
            $timeout(function () {
                var pro = currentPage.scale / currentPage.proportion;
                currentLevel.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                currentLevel.textbox.geometry.height = Number($("#c-xml-page-material-fontId").height()) + Number(5);
                updateHistorys();
                $scope.currentPage = currentPage;
                $scope.selectBox = currentLevel.textbox;

                if (vm.unit == 'mm') {
                    $scope.pageValue.textbox = {
                        width: PxTurnMm(currentLevel.textbox.geometry.width),
                        height: PxTurnMm(currentLevel.textbox.geometry.height),
                        x: PxTurnMm(currentPage.trimbox.x + 20 + $scope.template.levelI * 2),
                        y: PxTurnMm(currentPage.trimbox.y + 20 + $scope.template.levelI * 2)
                    }
                } else if (vm.unit == 'px') {
                    $scope.pageValue.textbox = currentLevel.textbox.geometry;
                } else if (vm.unit == 'in') {
                    $scope.pageValue.textbox = {
                        width: PxTurnIn(currentLevel.textbox.geometry.width),
                        height: PxTurnIn(currentLevel.textbox.geometry.height),
                        x: PxTurnIn(currentPage.trimbox.x + 20 + $scope.template.levelI * 2),
                        y: PxTurnIn(currentPage.trimbox.y + 20 + $scope.template.levelI * 2)
                    }
                }
            })
            $scope.item = vm.fontList[0];

            vm.selectElementCopy = angular.copy($scope.currentPage.levels.level[$scope.template.levelI]);
        };

        $scope.$watch("vm.text", function (newValue, oldValue) {
            // if(oldValue){
            sizeHeight();
            // }
        })
        vm.verifyTextFocus = false;
        $scope.verifyTextFocus = function(){
            vm.verifyTextFocus = true;
        }
        $scope.verifyText = function (e) {
            fn(e);
            var _textbox = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
            _textbox.characterWarning = false;
            _textbox.characterMessage = "";
            FontManagement.fontSupport({
                uuid: _textbox.fontuuid,
                bold: _textbox.style.bold,
                italic: _textbox.style.italic,
                content: vm.text
            }, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    _textbox.success = msg.success;
                    if (!msg.success) {
                        MessageService.error("当前字体不可用，请更换字体");
                        return
                    }
                    if (msg.success && !msg.supported) {
                        var message = "【" + msg.failed + "】" + "当前字体不支持这些文字！";
                        MessageService.error(message);
                        _textbox.characterWarning = true;
                        _textbox.characterMessage = message;
                    }
                    vm.text = msg.passed;
                    sizeHeight();
                }
                vm.verifyTextFocus = false;
            })
        }

        $scope.verifyTextFont = function (textbox) {
            if (!textbox) {
                return;
            }
            textbox.characterWarning = false;
            textbox.characterMessage = "";
            FontManagement.fontSupport({
                uuid: textbox.fontuuid,
                bold: textbox.style.bold,
                italic: textbox.style.italic,
                content: textbox.text
            }, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    textbox.success = msg.success;
                    if (msg.success && !msg.supported) {
                        var message = "【" + msg.failed + "】" + "当前字体不支持这些文字！";
                        textbox.characterWarning = true;
                        textbox.characterMessage = message;
                    }
                }
            })
        }

        $scope.$watch("vm.textDocCon", function (newValue, oldValue) {
            if (oldValue) {
                sizeTextDocHeight();
            }
        })

        function sizeTextDocHeight() {
            var _textbox = $scope.currentPage.levels.level[$scope.template.levelI].textdocument.levels.level[$scope.template.docI].textbox;
            _textbox.text = vm.textDocCon;
            var maxY = $contain.height();
            $timeout(function () {
                var _h = Number($(".textbox" + $scope.template.pageI + $scope.template.levelI + $scope.template.docI).find('.c-xml-page-textboxe-text').height()) + Number(5);
                var _top = Number($(".textbox" + $scope.template.pageI + $scope.template.levelI + $scope.template.docI)[0].offsetTop);
                if (_h + _top > maxY) {
                    _textbox.geometry.x = 0;
                    _textbox.geometry.y = 0;
                    ;
                }
                _textbox.geometry.height = _h / ($scope.currentPage.scale / $scope.currentPage.proportion)
            })
        }

        //编辑状态
        $scope.editableSel = function (box, attr) {
            $scope.currentPage.levels.level[$scope.template.levelI][box].editable = attr;
        }
        //不可编辑
        $scope.movableSel = function (box, attr) {
            $scope.currentPage.levels.level[$scope.template.levelI][box].movable = attr;
        }


        $scope.addTextDoc = function (text) {
            if(vm.textEditor){
                return
            }
            FontXmls.get({id: text.id}, function (res) {
                $scope.RightPanel = 'textDoc';
                vm.unit = 'mm';
                vm.data = res;
                // var level = $scope.currentPage.levels.level[$scope.template.levelI];
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var currentLevel = addLevel(currentPage);
                currentLevel.textdocument = angular.copy(vm.data.textDocument);

                var geo = currentLevel.textdocument.geometry;

                $scope.pageValue.textdocument = {
                    width: geo.width,
                    height: geo.height,
                    x: geo.x + 12 + $scope.template.levelI * 2,
                    y: geo.y + 12 + $scope.template.levelI * 2
                }
                var dataBackground = vm.data.textDocument.background;
                var textdocument = currentLevel.textdocument;
                textdocument.geometry = getGeometry($scope.pageValue.textdocument);
                textdocument.current = 'textdocument';
                textdocument.rotation = {
                    angle: 0
                };
                textdocument.background.offsetx = MmTurnPx(dataBackground.offsetx);
                textdocument.background.offsety = MmTurnPx(dataBackground.offsety);
                var resource = textdocument.background.resource;
                if (resource.identifier) {
                    $http({url: resource.identifier + '?imageInfo'}).success(function (data) {
                        var pageW = textdocument.geometry.width,
                            pageH = textdocument.geometry.height,
                            bgW = data.width,
                            bgH = data.height;

                        // 高适配
                        var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                        // 宽适配
                        if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                            w = pageW;
                            h = pageW * bgH / bgW;
                            adaptation = 'Width';
                        }
                        textdocument.background.width = w;
                        textdocument.background.resource.width = bgW;
                        textdocument.background.resource.height = bgH;
                        textdocument.background.height = h;
                        textdocument.background.type = 'Pic';
                        textdocument.background.adaptation = adaptation
                    }).error(function (error) {
                        MessageService.error("获取图片信息失败");
                    });
                }

                angular.forEach(textdocument.levels.level, function (level, l) {
                    var dataLevel = vm.data.textDocument.levels.level[l];
                    if (level.imageart && level.imageart.geometry) {
                        level.imageart.current = 'imageart';
                        level.imageart.geometry = getGeometry(dataLevel.imageart.geometry);
                        var resource = dataLevel.imageart.resource;
                        var arr = ThreeHundredDpiToMm(resource.width, resource.height);
                        $http({url: resource.identifier + '?imageInfo'}).success(function (data) {
                            level.imageart.resource = {
                                identifier: resource.identifier,
                                provider: 'qiniu',
                                width: data.width,
                                height: data.height
                            }

                            level.imageart.offsetx = MmTurnPx(dataLevel.imageart.offsetx);
                            level.imageart.offsety = MmTurnPx(dataLevel.imageart.offsety);
                        }).error(function (error) {
                            MessageService.error("获取图片信息失败");
                        });
                    }
                    if (level.textbox && level.textbox.geometry) {
                        level.textbox.current = 'textbox';
                        level.textbox.geometry = getGeometry(dataLevel.textbox.geometry);
                        getFont(level.textbox,FontManagement);
                    }
                })
                updateHistorys();
                currentLevel.textdocument.levelI = $scope.template.levelI;
                currentLevel.textdocument.pageI = $scope.template.pageI;
                $scope.currentPage = currentPage;
                $scope.selectBox = currentLevel.textdocument;
                return
            });
        }


        //文本框点击时

        $scope.selectDoc = function (e, $index, textdocument) {
            if(vm.textEditor){
                return;
            }
            fn(e)
            if (vm.unit == 'mm') {
                $scope.pageValue.textdocument = {
                    width: PxTurnMm(textdocument.geometry.width).toFixed(2) - 0,
                    height: PxTurnMm(textdocument.geometry.height).toFixed(2) - 0,
                    x: PxTurnMm(textdocument.geometry.x).toFixed(2) - 0,
                    y: PxTurnMm(textdocument.geometry.y).toFixed(2) - 0
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.textdocument = {
                    width: textdocument.geometry.width,
                    height: textdocument.geometry.height,
                    x: textdocument.geometry.x,
                    y: textdocument.geometry.y
                }
            } else if (vm.unit == 'in') {
                $scope.pageValue.textdocument = {
                    width: PxTurnIn(textdocument.geometry.width).toFixed(2) - 0,
                    height: PxTurnIn(textdocument.geometry.height).toFixed(2) - 0,
                    x: PxTurnIn(textdocument.geometry.x).toFixed(2) - 0,
                    y: PxTurnIn(textdocument.geometry.y).toFixed(2) - 0
                }
            }
            $scope.template.angle = textdocument.rotation.angle;
            textdocument.current = 'textdocument';
            $scope.selectBox = textdocument;

            //如果是按住ctrl键 实现多选复制
            // var mm = $scope.currentPage.imageboxes.imagebox[$index];
            if (e.ctrlKey == true || e.metaKey == true) {
                if (textdocument.lock) {
                    return
                }
                textdocument.ctrlKey = true;
                textdocument.levelI = $index;
                vm.CtrlKeyArray.textdocuments[$index] = angular.copy(textdocument);
                ctrlAddSingleArray();
            } else {
                $scope.clearCtrlKey();
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, textdocument: {}, calMonthBox: {}, calTitleBox: {}};
                vm.singleArray.textdocument = angular.copy(textdocument);
            }
            $("#menus").fadeOut();
            // setZindex($index);
            $("#maskSelect").val("");
            $scope.template.levelI = $index;
            $scope.RightPanel = 'textDoc';
            e.stopPropagation();
        };

        /**
         *放大占位框
         * @param e
         */
        var dragDocFlag = false, dragDoc = {}, textdocW = 1, textdocH = 1, textdocument = null;
        window.resizeDocStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;

            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            textdocument = angular.copy($scope.currentPage.levels.level[$scope.template.levelI].textdocument);

            if (textdocument.lock) {
                return
            }
            dragDoc.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragDoc.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragDoc.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft;        //鼠标到模板左边的距离
            dragDoc.coordY = targ.offsetParent.offsetTop + targ.offsetTop;         //鼠标到模板顶部的距离
            dragDoc.maxX = $contain.width();
            dragDoc.maxY = $contain.height();
            dragDocFlag = true;

            $scope.RightPanel = 'textDoc';

            textdocW = angular.copy(textdocument.geometry.width);
            textdocH = angular.copy(textdocument.geometry.height);

            document.addEventListener("mousemove", resizeDocOn, true);
            document.addEventListener("mouseup", resizeDocEnd, true);
        };

        function resizeDocOn(e) {
            if (dragDocFlag) {
                fn(e);
                // 是否按住shift键
                dragDoc.shiftKey = e.shiftKey;
                var targ = e.target ? e.target : e.srcElement;
                if (targ == null) {
                    return;
                }
                var imgIndex = $scope.template.levelI;
                if (imgIndex == null) {
                    return;
                }
                var pageIndex = $scope.template.pages.page[$scope.template.pageI];
                if (pageIndex == null) {
                    return;
                }
                var item = pageIndex.levels.level[$scope.template.levelI].textdocument;
                if (item == null) {
                    return;
                }

                var pro = pageIndex.scale / pageIndex.proportion
                var x = item.geometry.x * pro;
                var y = item.geometry.y * pro;
                //20 小图标的宽高
                var w = (dragDoc.coordX + 10 + e.clientX - dragDoc.clientX - x);
                // var w = (dragDoc.coordX + 10 + e.clientX - dragDoc.clientX - item.geometry.x *pro) /pro;
                var h = w / item.geometry.width * item.geometry.height

                if (w < 40 || h < 40) {
                    return
                }

                if (x + w > dragDoc.maxX) {
                    var maxW = dragDoc.maxX - x;
                    item.geometry.width = maxW / pro;
                    item.geometry.height = (maxW / item.geometry.width * item.geometry.height) / pro;
                } else if (y + h > dragDoc.maxY) {
                    var maxH = dragDoc.maxY - y;
                    item.geometry.height = maxH / pro;
                    item.geometry.width = (maxH / item.geometry.height * item.geometry.width) / pro;
                } else {
                    item.geometry.width = w / pro;
                    item.geometry.height = h / pro;
                }

                if (vm.unit == 'mm') {
                    $scope.pageValue.textdocument.width = PxTurnMm(item.geometry.width);
                    $scope.pageValue.textdocument.height = PxTurnMm(item.geometry.height);
                } else if (vm.unit == 'px') {
                    $scope.pageValue.textdocument.width = item.geometry.width;
                    $scope.pageValue.textdocument.height = item.geometry.height;
                } else if (vm.unit == 'in') {
                    $scope.pageValue.textdocument.width = PxTurnIn(item.geometry.width);
                    $scope.pageValue.textdocument.height = PxTurnIn(item.geometry.height);
                }
                resizeLevelWaH();

            }
            $scope.$digest();
            return false;
        }

        function resizeDocEnd() {
            dragDocFlag = false;
            dragDoc = {};

            document.removeEventListener("mousemove", resizeDocOn, true);
            document.removeEventListener("mouseup", resizeDocEnd, true);
            updateHistorys();
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            $scope.$digest();
        }

        window.resizeDocStart1 = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;

            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            textdocument = angular.copy($scope.currentPage.levels.level[$scope.template.levelI].textdocument);

            if (textdocument.lock) {
                return
            }
            dragDoc.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragDoc.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragDoc.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft;        //鼠标到模板左边的距离
            dragDoc.coordY = targ.offsetParent.offsetTop + targ.offsetTop;         //鼠标到模板顶部的距离
            dragDoc.maxX = $contain.width();
            dragDoc.maxY = $contain.height();
            dragDocFlag = true;

            $scope.RightPanel = 'textDoc';

            textdocW = angular.copy(textdocument.geometry.width);
            textdocH = angular.copy(textdocument.geometry.height);

            document.addEventListener("mousemove", resizeDocOn1, true);
            document.addEventListener("mouseup", resizeDocEnd1, true);
        };

        function resizeDocOn1(e) {
            if (dragDocFlag) {
                fn(e);
                // 是否按住shift键
                dragDoc.shiftKey = e.shiftKey;
                var targ = e.target ? e.target : e.srcElement;
                if (targ == null) {
                    return;
                }
                var imgIndex = $scope.template.levelI;
                if (imgIndex == null) {
                    return;
                }
                var pageIndex = $scope.template.pages.page[$scope.template.pageI];
                if (pageIndex == null) {
                    return;
                }
                var item = pageIndex.levels.level[$scope.template.levelI].textdocument;
                if (item == null) {
                    return;
                }

                var pro = pageIndex.scale / pageIndex.proportion * $scope.proportion;
                var x = item.geometry.x * pro;
                var y = item.geometry.y * pro;
                //20 小图标的宽高
                var w = (dragDoc.coordX + 10 + e.clientX - dragDoc.clientX - x);
                // var w = (dragDoc.coordX + 10 + e.clientX - dragDoc.clientX - item.geometry.x *pro) /pro;
                var h = w / item.geometry.width * item.geometry.height;

                if (w < 10 || h < 10) {
                    return
                }

                if (x + w > dragDoc.maxX) {
                    var maxW = dragDoc.maxX - x;
                    item.geometry.width = maxW / pro;
                    item.geometry.height = (maxW / item.geometry.width * item.geometry.height) / pro;
                } else if (y + h > dragDoc.maxY) {
                    var maxH = dragDoc.maxY - y;
                    item.geometry.height = maxH / pro;
                    item.geometry.width = (maxH / item.geometry.height * item.geometry.width) / pro;
                } else {
                    item.geometry.width = w / pro;
                    item.geometry.height = h / pro;
                }

                if (vm.unit == 'mm') {
                    $scope.pageValue.textdocument.width = PxTurnMm(item.geometry.width);
                    $scope.pageValue.textdocument.height = PxTurnMm(item.geometry.height);
                } else if (vm.unit == 'px') {
                    $scope.pageValue.textdocument.width = item.geometry.width;
                    $scope.pageValue.textdocument.height = item.geometry.height;
                } else if (vm.unit == 'in') {
                    $scope.pageValue.textdocument.width = PxTurnIn(item.geometry.width);
                    $scope.pageValue.textdocument.height = PxTurnIn(item.geometry.height);
                }


                resizeLevelWaH();

            }
            $scope.$digest();
            return false;
        }

        function resizeDocEnd1() {
            dragDocFlag = false;
            dragDoc = {};

            document.removeEventListener("mousemove", resizeDocOn1, true);
            document.removeEventListener("mouseup", resizeDocEnd1, true);
            updateHistorys();
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            $scope.$digest();
        }

        function resizeLevelWaH() {
            var doc = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textdocument;
            doc.background.offsetx = textdocument.background.offsetx * doc.geometry.width / textdocW;
            doc.background.offsety = textdocument.background.offsety * doc.geometry.height / textdocH;
            doc.background.width = textdocument.background.width * doc.geometry.width / textdocW;
            doc.background.height = textdocument.background.height * doc.geometry.height / textdocH;

            angular.forEach(doc.levels.level, function (level, l) {
                var textdoc = textdocument.levels.level[l];
                if (level.imageart) {
                    var geo = {
                        width: textdoc.imageart.geometry.width * doc.geometry.width / textdocW,
                        height: textdoc.imageart.geometry.height * doc.geometry.height / textdocH,
                        x: textdoc.imageart.geometry.x * doc.geometry.width / textdocW,
                        y: textdoc.imageart.geometry.y * doc.geometry.height / textdocH,
                    }
                    level.imageart.geometry = geo;

                    level.imageart.offsetx = textdoc.imageart.offsetx * doc.geometry.width / textdocW;
                    level.imageart.offsety = textdoc.imageart.offsety * doc.geometry.height / textdocH;
                }
                if (level.textbox) {
                    var textGeo = {
                        width: textdoc.textbox.geometry.width * doc.geometry.width / textdocW,
                        height: textdoc.textbox.geometry.height * doc.geometry.height / textdocH,
                        x: textdoc.textbox.geometry.x * doc.geometry.width / textdocW,
                        y: textdoc.textbox.geometry.y * doc.geometry.height / textdocH,
                    }
                    level.textbox.geometry = textGeo;
                    level.textbox.style.size = textdoc.textbox.style.size * doc.geometry.width / textdocW;
                    level.textbox.style.lineheight = textdoc.textbox.style.lineheight * doc.geometry.width / textdocW;
                }
            })
        }

        $scope.slipGroup = function () {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var textdocument = angular.copy(currentPage.levels.level[$scope.template.levelI].textdocument);
            // var page = document.getElementById('pageContent');
            // var pageRect = page.getBoundingClientRect();
            // var pageLeft = pageRect.left;
            // var pageTop = pageRect.top;
            // var currentPage = $scope.template.pages.page[$scope.template.pageI];
            // var textdocument = angular.copy(currentPage.levels.level[$scope.template.levelI].textdocument);
            $scope.deleteBox();
            var textGeo = textdocument.geometry;
            vm.unit = 'mm';
            if (textdocument.background && textdocument.background.resource && textdocument.background.resource.identifier) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var currentLevel = addLevel(currentPage);
                $scope.pageValue.textdocument = {
                    width: PxTurnMm(textGeo.width),
                    height: PxTurnMm(textGeo.height),
                    x: PxTurnMm(textGeo.x),
                    y: PxTurnMm(textGeo.y)
                }
                var angle = 0;
                if (textdocument.rotation.angle) {
                    angle = 360-textdocument.rotation.angle;
                }
                var resource = textdocument.background.resource;
                var scale = 1;
                if (textdocument.background.adaptation == "Height") {
                    scale = textdocument.background.width / textdocument.geometry.width;
                }
                currentLevel.imageart = ({
                    geometry: {
                        width: textdocument.geometry.width,
                        height: textdocument.geometry.height,
                        x: textdocument.geometry.x,
                        y: textdocument.geometry.y
                    },
                    rotation: {
                        angle: angle
                    },
                    // 模板使用，微信端不可进行操作
                    // type: 'static',
                    resource: {
                        identifier: resource.identifier,
                        provider: 'qiniu',
                        width: resource.width,
                        height: resource.height
                    },
                    scale: scale,
                    offsetx: textdocument.background.offsetx,
                    offsety: textdocument.background.offsety,
                    transparency: 1,
                    onlyshow: false,
                    movable: textdocument.movable,
                    editable: textdocument.editable,
                    bgImg: '',
                    unit: 'mm',
                    // index: currentPage.imagearts.imageart.length,
                    levelI: $scope.template.levelI,
                    pageI: $scope.template.pageI,
                    current: 'imageart',
                    lock: false
                });

                $scope.selectBox = currentLevel.imageart;
            }
            angular.forEach(textdocument.levels.level, function (level, i) {
                if (level.imageart) {
                    var rotateCenter = rotatePoint(
                        {
                            x:(level.imageart.geometry.x + textGeo.x + level.imageart.geometry.width * 0.5) ,
                            y:(level.imageart.geometry.y + textGeo.y + level.imageart.geometry.height * 0.5)
                        },
                        {
                            x:textGeo.x + textGeo.width * 0.5,
                            y:textGeo.y + textGeo.height *0.5
                        },
                        angle
                    )
                    var currentPage = $scope.template.pages.page[$scope.template.pageI];
                    var currentLevel = addLevel(currentPage);
                    var resource = level.imageart.resource;
                    currentLevel.imageart = ({
                        geometry: {
                            width: level.imageart.geometry.width,
                            height: level.imageart.geometry.height,
                            x: rotateCenter.x - level.imageart.geometry.width * 0.5,
                            y: rotateCenter.y - level.imageart.geometry.height * 0.5
                        },
                        rotation: {
                            angle:angle + level.imageart.rotation.angle
                        },
                        // 模板使用，微信端不可进行操作
                        type: 'static',
                        resource: {
                            identifier: resource.identifier,
                            provider: 'qiniu',
                            width: resource.width,
                            height: resource.height
                        },
                        scale: level.imageart.scale,
                        offsetx: level.imageart.offsetx,
                        offsety: level.imageart.offsety,
                        transparency: level.imageart.transparency,
                        onlyshow: false,
                        movable: textdocument.movable,
                        editable: textdocument.editable,
                        bgImg: '',
                        unit: 'mm',
                        // index: currentPage.imagearts.imageart.length,
                        levelI: $scope.template.levelI,
                        pageI: $scope.template.pageI,
                        current: 'imageart',
                        lock: false
                    });
                }
                if (level.textbox) {
                    var rotateCenter = rotatePoint(
                        {
                            x:(level.textbox.geometry.x + textGeo.x + level.textbox.geometry.width * 0.5) ,
                            y:(level.textbox.geometry.y + textGeo.y + level.textbox.geometry.height * 0.5)
                        },
                        {
                            x:textGeo.x + textGeo.width * 0.5,
                            y:textGeo.y + textGeo.height *0.5
                        },
                        angle
                    );
                    var currentPage = $scope.template.pages.page[$scope.template.pageI];
                    var currentLevel = addLevel(currentPage);
                    currentLevel.textbox = angular.copy(level.textbox);
                    currentLevel.textbox.movable = textdocument.movable;
                    currentLevel.textbox.editable = textdocument.editable;
                    currentLevel.textbox.unit = 'mm';
                    currentLevel.textbox.rotation.angle = angle + currentLevel.textbox.rotation.angle;
                    currentLevel.textbox.levelI = $scope.template.levelI;
                    currentLevel.textbox.pageI = $scope.template.pageI;
                    currentLevel.textbox.current = 'textbox';
                    currentLevel.textbox.lock = false;
                    currentLevel.textbox.geometry.x =  rotateCenter.x - level.textbox.geometry.width * 0.5;
                    currentLevel.textbox.geometry.y = rotateCenter.y - level.textbox.geometry.height * 0.5;
                    fontStyle(currentLevel.textbox);
                }
            })
            updateHistorys();
            $scope.currentPage = currentPage;
            $scope.RightPanel = 'material';
            $scope.template.transparency = 100;

        }

        //文本框点击时
        $scope.selectText = function (e, $index, text) {
            if(vm.textEditor){return}
            fn(e);

            angular.forEach(vm.fontList, function (list) {
                if (list.fontuuid == text.fontuuid) {
                    $scope.item = list
                }
            })

            $scope.template.text = text.text;
            $scope.size = text.style.size;
            $scope.template.angle = text.rotation.angle;

            if (vm.unit == 'mm') {
                $scope.pageValue.textbox = {
                    width: PxTurnMm(text.geometry.width).toFixed(2) - 0,
                    height: PxTurnMm(text.geometry.height).toFixed(2) - 0,
                    x: PxTurnMm(text.geometry.x).toFixed(2) - 0,
                    y: PxTurnMm(text.geometry.y).toFixed(2) - 0
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.textbox = {
                    width: text.geometry.width,
                    height: text.geometry.height,
                    x: text.geometry.x,
                    y: text.geometry.y
                }
            } else if (vm.unit == 'in') {
                $scope.pageValue.textbox = {
                    width: PxTurnIn(text.geometry.width).toFixed(2) - 0,
                    height: PxTurnIn(text.geometry.height).toFixed(2) - 0,
                    x: PxTurnIn(text.geometry.x).toFixed(2) - 0,
                    y: PxTurnIn(text.geometry.y).toFixed(2) - 0
                }
            }
            $scope.template.angle = text.rotation.angle;
            vm.text = text.text;

            $scope.template.letterspacing = text.style.letterspacing;
            $(".block-width").css({left: text.style.letterspacing});
            $scope.template.lineheight = text.style.lineheight;
            $(".block-height").css({left: text.style.lineheight});

            text.current = 'textbox';
            $scope.selectBox = text;

            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                text.ctrlKey = true;
                text.levelI = $index;
                vm.CtrlKeyArray.textboxes[$index] = angular.copy(text);
                ctrlAddSingleArray();
                $scope.RightPanel = 'all';
            } else {
                if (!text.ctrlKey) {
                    $scope.clearCtrlKey();
                    vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, calMonthBox: {}, calTitleBox: {}};
                }
                vm.singleArray.textbox = angular.copy(text);
                $scope.RightPanel = 'text';
            }
            $("#menus").fadeOut();
            $scope.template.levelI = $index;
            // $scope.selPanel = 'text';

            vm.selectElementCopy = angular.copy($scope.currentPage.levels.level[$scope.template.levelI]);
        };
        $scope.selectTextDocFont = function (e, levelIndex, docIndex, text) {
            fn(e);
            $scope.template.levelI = levelIndex;
            $scope.template.docI = docIndex;
            vm.textDocCon = text.text;

            $("#menus").fadeOut();
        };
        vm.textEditor = false;
        $scope.doubleClicktext = function (e, item) {
            fn(e);
            if (item.lock || vm.textEditor) {
                return
            }
            $timeout(function () {
                vm.textEditor = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
                $(e.target).css({'cursor':'text'});
                $(e.target).attr({'contenteditable':'true'});
                $(e.target).focus();
                $(e.target).parents('.c-xml-page-textboxe').css({'height':'auto'});
                $(e.target).parent('pre').css({'height':'auto'});
                if (document.selection) {
                    var range = document.body.createTextRange();
                    range.moveToElementText($(e.target));
                    range.select();
                } else if (window.getSelection) {
                    var range = document.createRange();
                    range.selectNodeContents($(e.target)[0]);
                    window.getSelection().removeAllRanges();
                    window.getSelection().addRange(range);
                }
            })
        }
        //去除所有html标签，只保留文字
        var textReg=/<\/?.+?\/?>/g;
        // 过滤style行内标签
        var textReg1=/ style\s*?=\s*?(['"])[\s\S]*?\1/g;
        $scope.textBlur = function (e, item, i) {
            fn(e);
            $timeout(function () {
                $(e.target).css({'cursor':'pointer'});
                $(e.target).attr({'contenteditable':'false'});
                item.text = angular.copy($(e.target).text());
                item.text = item.text.replace(/&nbsp;/g,' ').replace(textReg1,'').replace(/<br>/g,'\n').replace(textReg,'');
                item.characterWarning = false;
                item.characterMessage = "";
                FontManagement.fontSupport({
                    uuid: item.fontuuid,
                    bold: item.style.bold,
                    italic: item.style.italic,
                    content: item.text
                }, function (res) {
                    var msg = res.message;
                    if (res.status == 200) {
                        item.success = msg.success;
                        if (!msg.success) {
                            MessageService.error("当前字体不可用，请更换字体");
                            return
                        }
                        if (msg.success && !msg.supported) {
                            var message = "【" + msg.failed + "】" + "当前字体不支持这些文字！";
                            MessageService.error(message);
                            item.characterWarning = true;
                            item.characterMessage = message;
                        }
                        $(e.target).html(msg.passed);
                        vm.textEditor.text = msg.passed;
                        sizeHeight1(function () {
                            vm.textEditor = false;
                            $scope.$apply();
                        });
                    }
                })
            })
        }
        $scope.changeHeight = function(e,item){
            // vm.textEditor.text = $(e.target).text();
            // vm.textEditor.text = vm.textEditor.text.replace(/&nbsp;/g,' ').replace(textReg1,'').replace(/<br>/g,'\n').replace(textReg,'');
            // sizeHeight1()
        }
        // var a = null;
        $scope.updateTextSize = function (value) {
            // if (a !== null) {
            //     clearTimeout(a)
            // }
            // a = setTimeout(function () {
            //     $timeout(function () {
            //         var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
            //         if (text.lock) {
            //             return
            //         }
            //         var sizeValue = value <= 6 ? 6 : value;
            //         text.style.size = sizeValue;
            //         text.style.lineheight = $scope.template.lineheight = sizeValue;
            //         sizeHeight();
            //         updateHistorys();
            //     })
            // },500)
            $timeout(function () {
                var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
                if (text.lock) {
                    return
                }
                var sizeValue = value <= 6 ? 6 : value;
                text.style.size = sizeValue;
                text.style.lineheight = $scope.template.lineheight = sizeValue;
                if(text.geometry.width < ptToPx(text.style.size)){
                    text.geometry.width = ptToPx(text.style.size)+10;
                }
                $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox = angular.copy(text);
                sizeHeight();
                updateHistorys();
            })
        };

        function fontStyle(font) {
            var _fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == font.fontuuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        _fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            font.fontStyle = _fontStyle;
            font.style.bold1 = false;
            font.style.italic1 = false;
            if (_fontStyle.indexOf('NORMAL') != '-1') {
                font.style.normal = true;
                font.style.bold = false;
                font.style.italic = false;
            } else {
                font.style.normal = false;
                if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    font.style.bold = true;
                    font.style.italic = true;
                } else {
                    if (_fontStyle.indexOf('ITALIC') != '-1') {
                        font.style.bold = false;
                        font.style.italic = true;
                    }
                    if (_fontStyle.indexOf('BOLD') != '-1') {
                        font.style.bold = true;
                        font.style.italic = false;
                    }
                }
            }
            if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                font.style.bold1 = true;
                font.style.italic1 = true;
            } else {
                if (_fontStyle.indexOf('ITALIC') != '-1') {
                    font.style.italic1 = true;
                }
                if (_fontStyle.indexOf('BOLD') != '-1') {
                    font.style.bold1 = true;
                }
            }
        }

        //文字放大缩小
        var isTextDown = false;
        $scope.textboxZoomRight = function ($event, item, pro, index) {
            fn($event)
            editorBoxZoom($event, item, 'right', pro, $($event.target).parents('.selected'), function (it) {
                sizeHeight();
                $scope.$apply();
            }, function (it) {
            });
        }
        $scope.textboxZoomLeft = function ($event, item, pro, index) {
            fn($event)
            editorBoxZoom($event, item, 'left', pro, $($event.target).parents('.selected'), function (it) {
                sizeHeight();
                $scope.$apply();
            }, function (it) {
            });
        }
        $scope.textboxZoomTop = function ($event, item, pro, index) {
            fn($event)
            editorBoxZoom($event, item, 'top', pro, $($event.target).parents('.selected'), function (it) {
                sizeHeight();
                $scope.$apply();
            }, function (it) {
            });
        }
        $scope.textboxZoomBottom = function ($event, item, pro, index) {
            fn($event)
            editorBoxZoom($event, item, 'bottom', pro, $($event.target).parents('.selected'), function (it) {
                sizeHeight();
                $scope.$apply();
            }, function (it) {
            });
        }
        $scope.textboxZoomLeft1 = function ($event, item, pro, index) {
            fn($event)
            editorBoxZoom($event, item, 'leftTop', pro, $($event.target).parents('.selected'), function (it) {
                sizeHeight();
                $scope.$apply();
            }, function (it) {
            });
        };
        $scope.textboxZoomTop1 = function ($event, item, pro, index) {
            fn($event)
            editorBoxZoom($event, item, 'rightTop', pro, $($event.target).parents('.selected'), function (it) {
                sizeHeight();
                $scope.$apply();
            }, function (it) {
            });
        };
        $scope.textboxZoomRight1 = function ($event, item, pro, index) {
            fn($event)
            editorBoxZoom($event, item, 'rightBottom', pro, $($event.target).parents('.selected'), function (it) {
                sizeHeight();
                $scope.$apply();
            }, function (it) {
            });
        };
        $scope.textboxZoomBottom1 = function ($event, item, pro, index) {
            fn($event)
            editorBoxZoom($event, item, 'leftBottom', pro, $($event.target).parents('.selected'), function (it) {
                sizeHeight();
                $scope.$apply();
            }, function (it) {
            });
        };


        vm.selectFont = '';
        vm.selectFontBtn = fontInit;
        vm.selectFontName = selectFontName;

        //获取字体
        vm.chineseSupport = true;
        fontInit(function () {
            vm.fontList1 = angular.copy(vm.fontList);
            $scope.item = vm.fontList[0];
        })
        function fontInit(callback) {
            if (a !== null) {
                clearTimeout(a)
            }
            a = setTimeout(function () {
                vm.fontList = [];
                FontManagement.getAll3({family: vm.selectFont}, function (res) {
                    vm.fontList = res;
                    if(callback){callback()}
                });
                $scope.$apply();
            },800)
        }

        vm.fontList1 = [];

        function selectFontName(item) {
            if (item) {
                for (var i = 0; i < vm.fontList1.length; i++) {
                    if (vm.fontList1[i].uuid == item) {
                        return vm.fontList1[i].family
                    }
                }
            }
        }

        $scope.updateTextFont = function (data) {
            if(vm.textEditor){return}
            if (data == undefined) {
                MessageService.error("文字添加失败，请上传字体!");
                return
            }
            if (!data.enable) {
                MessageService.error("当前字体不可用，请更换字体");
                return
            }
            var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
            if (text.lock) {
                return
            }
            FontManagement.fontSupport({
                uuid: data.uuid,
                bold: text.style.bold,
                italic: text.style.italic,
                content: text.text
            }, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    text.success = msg.success;
                    if (!msg.success) {
                        MessageService.error("当前字体不可用，请更换字体");
                        return
                    }
                    if (msg.success && !msg.supported) {
                        var message = "【" + msg.failed + "】" + "当前字体不支持这些文字！";
                        MessageService.error(message);
                    } else {
                        text.style.font = data.family;
                        text.fontuuid = data.uuid;
                        text.identifier = vm.url + data.fontStyles[0].identifier;
                        $scope.item = data;
                        fontStyle(text);
                        text.loading = true;
                        getFont(text,FontManagement,function () {
                            text.loading = false;
                        });
                        sizeHeight()
                        text.characterWarning = false;
                        text.characterMessage = "";
                        updateHistorys();
                    }
                } else {
                    MessageService.error("检查字体字符异常");
                }
            })
        };

        $scope.showTextSpacing = function () {
            $(".space-slider").toggleClass('hide1');
            $(".nav_spacing").toggleClass('nav-spacing-active');
            $scope.isAlignHover = false;
            $scope.isDistriHover = false;
            toobarFlag1 = true;
            toobarFlag2 = true;
        }

        // 字/行间距鼠标点击移动
        var spacingW = {};
        var flagSpacing = false
        window.resizeSpacingStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            spacingW.clientX = e.clientX || 0;
            spacingW.originLeft = targ.offsetLeft;
            spacingW.value = targ.dataset.value;
            var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
            if (text.lock) {
                return
            }
            flagSpacing = true;
            document.addEventListener("mousemove", resizeSpacingOn, true);
            document.addEventListener("mouseup", resizeSpacingEnd, true);
        }

        function resizeSpacingOn(e) {
            if (flagSpacing) {
                var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
                var l = spacingW.originLeft + e.clientX - spacingW.clientX;
                if (spacingW.value == 'height' && l < text.style.size / 2) {
                    l = text.style.size / 2;
                } else if (spacingW.value == 'width' && l < 0) {
                    l = 0;
                }
                if (l > 110) {
                    l = 110
                }
                if (spacingW.value == 'width') {
                    $(".block-width").css({left: l});
                    text.style.letterspacing = $scope.template.letterspacing = l;
                } else {
                    $(".block-height").css({left: l});
                    text.style.lineheight = $scope.template.lineheight = l;
                }
                sizeHeight();
                $scope.$digest();
            }
        }

        function resizeSpacingEnd() {
            spacingW = {}
            flagSpacing = false;
            updateHistorys();
            document.removeEventListener("mousemove", resizeSpacingOn, true);
            document.removeEventListener("mouseup", resizeSpacingEnd, true);
        }

        function fontEdit() {
            for (var i = 0; i < vm.data.textDocument.levels.level.length; i++) {
                var _font = vm.data.textDocument.levels.level[i].textbox;
                if (_font.selected) {
                    _font = vm.font;
                    break;
                }
            }
        }

        //字体family
        $scope.operationFontFamily = function () {
            getFont(vm.font,FontManagement)
            fontEdit()
        }

        function sizeHeight(){
            $timeout(function () {
                if($scope.selectBox && $scope.selectBox.current == 'textbox'){
                    var _h = Number($("#c-xml-page-material-fontId1").height()) + Number(10);
                    var _w = Number($("#c-xml-page-material-fontId1").width()) + Number(10);
                    if(!writingmodeInit($scope.selectBox.style.writingmode)){
                        $scope.selectBox.geometry.height = _h;
                    }
                    if(writingmodeInit($scope.selectBox.style.writingmode)){
                        $scope.selectBox.geometry.width = _w;
                    }
                    $scope.currentPage.levels.level[$scope.template.levelI].textbox = $scope.selectBox;
                    $scope.$apply();
                }
            })
        }
        function sizeHeight1(callback) {
            if (vm.textEditor) {
                $timeout(function () {
                    var _h = Number($("#c-xml-page-material-fontId2").height()) + Number(10);
                    var _w = Number($("#c-xml-page-material-fontId2").width()) + Number(10);
                    if(!writingmodeInit(vm.textEditor.style.writingmode)){
                        vm.textEditor.geometry.height = _h;
                    }
                    if(writingmodeInit(vm.textEditor.style.writingmode)){
                        vm.textEditor.geometry.width = _w;
                    }
                    $scope.currentPage.levels.level[$scope.template.levelI].textbox = vm.textEditor;
                    if(callback){callback()};
                })
            }
        }

        $scope.writingmodeInit = writingmodeInit;

        function writingmodeInit(item) {
            if (item == 'vertical-lr' || item == 'vertical-rl') {
                return true
            } else {
                return false
            }
        }

        //横版
        $scope.textHengban = function () {
            var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
            var text1 = angular.copy(text);
            text.style.writingmode = 'horizontal-tb';
            $timeout(function () {
                text.geometry.width = text1.geometry.height;
                text.geometry.height = text1.geometry.width;
            }, 200)
        }
        //竖版
        $scope.textShuban = function (item) {
            var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
            var text1 = angular.copy(text);
            var _writingmode = angular.copy(text.style.writingmode);
            text.style.writingmode = item;
            $timeout(function () {
                if (!_writingmode || _writingmode == 'horizontal-tb') {
                    text.geometry.width = text1.geometry.height;
                    text.geometry.height = text1.geometry.width;
                }
            }, 200)
        }

        //字体
        $scope.operationFont = function () {
            fontEdit()
        }
        //斜体
        $scope.operationItalic = function () {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                if(level.textbox.style.italic){
                    if(level.textbox.fontStyle.indexOf('NORMAL') > -1){
                        level.textbox.style.italic = false;
                        if (level.textbox.fontStyle.indexOf('BOLD') == '-1') {
                            level.textbox.style.bold = false;
                        }
                    }else{
                        if(level.textbox.style.bold){
                            level.textbox.style.italic = false;
                        }
                    }
                }else{
                    if(level.textbox.fontStyle.indexOf('ITALIC') > -1){
                        level.textbox.style.italic = true;
                        if(level.textbox.style.bold){
                            if(level.textbox.fontStyle.indexOf('BOLD_ITALIC') == -1){
                                level.textbox.style.bold = false;
                            }
                        }else{
                            // if( level.textbox.fontStyle.indexOf('BOLD_ITALIC') > -1){
                            //     level.textbox.style.bold = true;
                            // }
                        }
                    }else if (level.textbox.fontStyle.indexOf('BOLD_ITALIC') > -1) {
                        level.textbox.style.bold = true;
                        level.textbox.style.italic = true;
                    }
                }
                $timeout(function () {
                    sizeHeight();
                    updateHistorys();
                },200)
            }
        }
        //粗体
        $scope.operationBold = function () {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                if(level.textbox.style.bold){
                    if(level.textbox.fontStyle.indexOf('NORMAL') > -1){
                        level.textbox.style.bold = !level.textbox.style.bold;
                        if (level.textbox.fontStyle.indexOf('ITALIC') == '-1') {
                            level.textbox.style.italic = level.textbox.style.bold;
                        }
                    }else{
                        if(level.textbox.style.italic){
                            level.textbox.style.bold = !level.textbox.style.bold;
                        }
                    }
                }else{
                    if(level.textbox.fontStyle.indexOf('BOLD') > -1){
                        level.textbox.style.bold = true;
                        if(level.textbox.style.italic){
                            if(level.textbox.fontStyle.indexOf('BOLD_ITALIC') == -1){
                                level.textbox.style.italic = false;
                            }
                        }else{
                            // if( level.textbox.fontStyle.indexOf('BOLD_ITALIC') > -1){
                            //     level.textbox.style.italic = true;
                            // }
                        }
                    }else if (level.textbox.fontStyle.indexOf('BOLD_ITALIC') > -1) {
                        level.textbox.style.bold = true;
                        level.textbox.style.italic = true;
                    }
                }
                $timeout(function () {
                    sizeHeight();
                    updateHistorys();
                },200)
            }
        }
        //间距
        vm.isOperationSpacing = false
        $scope.operationSpacing = function ($event) {
            if (!vm.isOperationSpacing) {
                toolbarStatus();
            }
            vm.isOperationSpacing = !vm.isOperationSpacing;
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }
        $scope.changeLetterspacing = function(){
            // if (a !== null) {
            //     clearTimeout(a)
            // }
            // a = setTimeout(function () {
            //     $timeout(function () {
            //         sizeHeight();
            //         updateHistorys();
            //     })
            // },500)
            $timeout(function () {
                $scope.currentPage.levels.level[$scope.template.levelI].textbox.style.letterspacing = angular.copy(vm.selectElementCopy.textbox.style.letterspacing);
                sizeHeight();
                updateHistorys();
            })
        }
        $scope.changeLineheight = function() {
            // if (a !== null) {
            //     clearTimeout(a)
            // }
            // a = setTimeout(function () {
            //     $timeout(function () {
            //         if($scope.currentPage.levels.level[$scope.template.levelI].textbox.style.lineheight < $scope.currentPage.levels.level[$scope.template.levelI].textbox.style.size){
            //             $scope.currentPage.levels.level[$scope.template.levelI].textbox.style.lineheight = $scope.currentPage.levels.level[$scope.template.levelI].textbox.style.size;
            //         }
            //         sizeHeight();
            //         updateHistorys();
            //     })
            // },500)
            $timeout(function () {
                if(vm.selectElementCopy.textbox.style.lineheight < $scope.currentPage.levels.level[$scope.template.levelI].textbox.style.size){
                    vm.selectElementCopy.textbox.style.lineheight = $scope.currentPage.levels.level[$scope.template.levelI].textbox.style.size;
                }
                $scope.currentPage.levels.level[$scope.template.levelI].textbox.style.lineheight = angular.copy(vm.selectElementCopy.textbox.style.lineheight);
                sizeHeight();
                updateHistorys();
            })
        }

        $scope.lineWidthChange = function(){
            $scope.currentPage.levels.level[$scope.template.levelI].imagebox.border.lineWidth = vm.selectElementCopy.imagebox.border.lineWidth;
        }

        function debounce(fn, wait) {
            var timer = null;
            return function () {
                if (timer !== null) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function () {
                    fn()
                }, wait);
            }
        }

        // 画布对齐操作
        vm.isAlign = false;
        $scope.alignHover2 = function(attr){
            toolbarStatus();
            vm.isAlign = attr;
        }

        //对齐点击
        vm.isOperationDir = false
        $scope.operationDir = function () {
            if (!vm.isOperationDir) {
                toolbarStatus();
            }
            vm.isOperationDir = !vm.isOperationDir;
        }

        //左对齐
        $scope.operationBarLeft = function ($event) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.align = 'left';
            }
            vm.isOperationDir = false;
            updateHistorys();
        }
        //居中对齐
        $scope.operationBarCenter = function ($event) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.align = 'center';
            }

            vm.isOperationDir = false;
            updateHistorys();
        }
        //右对齐
        $scope.operationBarRight = function ($event) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.align = 'right';
            }

            vm.isOperationDir = false;
            updateHistorys();
        }
        //两端对齐
        $scope.operationBarJustify = function ($event) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.align = 'justify';
            }

            vm.isOperationDir = false;
            updateHistorys();
        }
        //颜色
        $scope.operationColorOpen = function ($event) {
            fn($event)
            vm.isOperationColor = !vm.isOperationColor;
        }

        $scope.operationColor = function (item) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.color = item.id;
            }
            vm.textColor = null;
            updateHistorys();
        }

        $scope.chooseTextColor = function () {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            level.textbox.style.color = vm.textColor;
            vm.textColor = null;
        }

        /* ==========================================================================
         条形码处理
         ========================================================================== */
        // 增加条形码
        $scope.addBarCode = function () {
            if(vm.textEditor){return}
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.barcode && currentPage.barcode.geometry) {
                return
            }
            vm.unit = 'mm';

            currentPage.barcode = {
                rotation: {angle: 0},
                geometry: {
                    width: MmTurnPx(60),
                    height: MmTurnPx(30),
                    x: (currentPage.mediabox.width - MmTurnPx(60))/2,
                    y: (currentPage.mediabox.height - MmTurnPx(30))/2
                },
                zoom: 1,
                unit: 'mm',
                lock: false,
                current: 'barcode'
            };
            $scope.currentPage = currentPage;

            $scope.selectBox = currentPage.barcode;
            if (vm.unit == 'mm') {
                $scope.pageValue.barcode = {
                    width: 60,
                    height: 30,
                    x: 16,
                    y: 16
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.barcode = currentPage.barcode.geometry;
            } else if (vm.unit == 'in') {
                $scope.pageValue.barcode = {
                    width: PxTurnIn(currentPage.barcode.geometry.width),
                    height: PxTurnIn(currentPage.barcode.geometry.height),
                    x: PxTurnIn(currentPage.barcode.geometry.x),
                    y: PxTurnIn(currentPage.barcode.geometry.y)
                };
            }

            updateHistorys();
        };
        // 增加二维码
        $scope.addQrCode = function () {
            if(vm.textEditor){return}
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.qrcode && currentPage.qrcode.geometry) {
                return
            }
            vm.unit = 'mm';

            currentPage.qrcode = {
                rotation: {angle: 0},
                geometry: {
                    width: MmTurnPx(60),
                    height: MmTurnPx(60),
                    x: (currentPage.mediabox.width - MmTurnPx(60))/2,
                    y: (currentPage.mediabox.height - MmTurnPx(60))/2
                },
                zoom: 1,
                unit: 'mm',
                lock: false,
                current: 'qrcode'
            };
            $scope.currentPage = currentPage;

            $scope.selectBox = currentPage.qrcode;
            if (vm.unit == 'mm') {
                $scope.pageValue.qrcode = {
                    width: 60,
                    height: 30,
                    x: 16,
                    y: 16
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.qrcode = currentPage.qrcode.geometry;
            } else if (vm.unit == 'in') {
                $scope.pageValue.qrcode = {
                    width: PxTurnIn(currentPage.qrcode.geometry.width),
                    height: PxTurnIn(currentPage.qrcode.geometry.height),
                    x: PxTurnIn(currentPage.qrcode.geometry.x),
                    y: PxTurnIn(currentPage.qrcode.geometry.y)
                };
            }

            updateHistorys();
        };

        $scope.updateBarParameter = function (type) {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.barcode.lock) {
                return
            }
            if (type == 'width') {
                if ($scope.pageValue.barcode.width < 10) {
                    $scope.pageValue.barcode.width = 10;
                }
                // $scope.pageValue.barcode.height = $scope.pageValue.barcode.width/2;
            }
            if (type == 'height') {
                if ($scope.pageValue.barcode.height < 5) {
                    $scope.pageValue.barcode.height = 5;
                }
                // $scope.pageValue.barcode.width = $scope.pageValue.barcode.height*2;
            }

            if (vm.unit == 'mm') {
                currentPage.barcode.geometry = {
                    width: MmTurnPx($scope.pageValue.barcode.width),
                    height: MmTurnPx($scope.pageValue.barcode.height),
                    x: MmTurnPx($scope.pageValue.barcode.x),
                    y: MmTurnPx($scope.pageValue.barcode.y)
                }
            }
            if (vm.unit == 'px') {
                currentPage.barcode.geometry = {
                    width: $scope.pageValue.barcode.width,
                    height: $scope.pageValue.barcode.height,
                    x: $scope.pageValue.barcode.x,
                    y: $scope.pageValue.barcode.y
                }
            }
            if (vm.unit == 'in') {
                currentPage.barcode.geometry = {
                    width: InTurnPx($scope.pageValue.barcode.width),
                    height: InTurnPx($scope.pageValue.barcode.height),
                    x: InTurnPx($scope.pageValue.barcode.x),
                    y: InTurnPx($scope.pageValue.barcode.y)
                }
            }
            updateHistorys();
        };
        $scope.updateQrcodeParameter = function (item) {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.qrcode.lock) {
                return
            }
            if (item == 'width') {
                if ($scope.pageValue.qrcode.width < 10) {
                    $scope.pageValue.qrcode.width = 10
                }
                $scope.pageValue.qrcode.height = $scope.pageValue.qrcode.width;
            }
            if (item == 'height') {
                if ($scope.pageValue.qrcode.height < 10) {
                    $scope.pageValue.qrcode.height = 10
                }
                $scope.pageValue.qrcode.width = $scope.pageValue.qrcode.height;
            }

            if (vm.unit == 'mm') {
                currentPage.qrcode.geometry = {
                    width: MmTurnPx($scope.pageValue.qrcode.width),
                    height: MmTurnPx($scope.pageValue.qrcode.height),
                    x: MmTurnPx($scope.pageValue.qrcode.x),
                    y: MmTurnPx($scope.pageValue.qrcode.y)
                }
            }
            if (vm.unit == 'px') {
                currentPage.qrcode.geometry = {
                    width: $scope.pageValue.qrcode.width,
                    height: $scope.pageValue.qrcode.height,
                    x: $scope.pageValue.qrcode.x,
                    y: $scope.pageValue.qrcode.y
                }
            }
            if (vm.unit == 'in') {
                currentPage.qrcode.geometry = {
                    width: InTurnPx($scope.pageValue.qrcode.width),
                    height: InTurnPx($scope.pageValue.qrcode.height),
                    x: InTurnPx($scope.pageValue.qrcode.x),
                    y: InTurnPx($scope.pageValue.qrcode.y)
                }
            }
            updateHistorys();
        };

        //条形码点击时
        $scope.selectBar = function (e, currentPage, item) {
            fn(e);
            $scope.RightPanel = item;
            var barcode = currentPage[item];

            if (vm.unit == 'mm') {
                $scope.pageValue[item] = {
                    width: PxTurnMm(barcode.geometry.width).toFixed(2) - 0,
                    height: PxTurnMm(barcode.geometry.height).toFixed(2) - 0,
                    x: PxTurnMm(barcode.geometry.x).toFixed(2) - 0,
                    y: PxTurnMm(barcode.geometry.y).toFixed(2) - 0
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue[item] = {
                    width: barcode.geometry.width,
                    height: barcode.geometry.height,
                    x: barcode.geometry.x,
                    y: barcode.geometry.y
                }
            } else if (vm.unit == 'in') {
                $scope.pageValue[item] = {
                    width: PxTurnIn(barcode.geometry.width).toFixed(2) - 0,
                    height: PxTurnIn(barcode.geometry.height).toFixed(2) - 0,
                    x: PxTurnIn(barcode.geometry.x).toFixed(2) - 0,
                    y: PxTurnIn(barcode.geometry.y).toFixed(2) - 0
                }
            }

            $scope.template.angle = currentPage[item].rotation.angle;
            barcode.current = item;
            $scope.selectBox = barcode;


            var mm = $scope.currentPage[item];
            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                if (barcode.lock) {
                    return
                }
                mm.ctrlKey = true;
                vm.CtrlKeyArray[item][0] = angular.copy(mm);
                ctrlAddSingleArray();
            } else {
                mm.ctrlKey = false;
                $scope.clearCtrlKey();
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, calMonthBox: {}, calTitleBox: {}};
                if (barcode.lock) {
                    return
                }
                vm.singleArray[item] = angular.copy(mm);
            }
            $("#menus").fadeOut();

        };

        //条形码移动事件
        var dragBar;
        var dragBarBox = {};
        window.moveBarcodeStart = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            dragBarBox.clientX = e.clientX || 0;
            dragBarBox.clientY = e.clientY || 0;
            dragBarBox.coordX = targ.offsetLeft;
            dragBarBox.coordY = targ.offsetTop;
            dragBar = true;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.barcode.lock) {
                return
            }
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragBarBox.ctrlKey = true;
            } else {
                dragBarBox.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveBarcodeOn, true);
            document.addEventListener("mouseup", moveBarcodeOnEnd, true);
        };

        //条形码move事件
        function moveBarcodeOn(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();

            if (dragBar) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];

                var clientX = e.clientX - dragBarBox.clientX;
                var clientY = e.clientY - dragBarBox.clientY;

                var barOffsetX = (dragBarBox.coordX + clientX);
                var barOffsetY = (dragBarBox.coordY + clientY);
                var barWidth = currentPage.barcode.geometry.width * currentPage.scale / currentPage.proportion;
                var barHeight = currentPage.barcode.geometry.height * currentPage.scale / currentPage.proportion;
                var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scale;
                var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scale;
                var barcodeAngle = currentPage.barcode.rotation.angle;

                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                var barx = 0, bary = 0;

                //中心点
                var xcenter = barWidth / 2 + barOffsetX;
                var ycenter = barHeight / 2 + barOffsetY;


                if (barcodeAngle == 0) {
                    if (barOffsetX <= 0) {
                        barOffsetX = 0;
                    }

                    if (barOffsetX >= (width + lar - barWidth)) {
                        barOffsetX = width + lar - barWidth;
                    }
                    if (barOffsetY <= 0) {
                        barOffsetY = 0;
                    }

                    if (barOffsetY >= (height + tab - barHeight)) {
                        barOffsetY = height + tab - barHeight;
                    }

                    barx = barOffsetX / currentPage.scale * currentPage.proportion;
                    bary = barOffsetY / currentPage.scale * currentPage.proportion;
                } else {

                    if (xcenter <= 0) {
                        xcenter = 0;
                    }

                    if (xcenter >= (width + lar)) {
                        xcenter = width + lar;
                    }
                    if (ycenter <= 0) {
                        ycenter = 0;
                    }

                    if (ycenter >= (height + tab)) {
                        ycenter = height + tab;
                    }
                    barx = (xcenter - barWidth / 2) / currentPage.scale * currentPage.proportion;
                    bary = (ycenter - barHeight / 2) / currentPage.scale * currentPage.proportion;
                }

                currentPage.barcode.geometry.x = barx;
                currentPage.barcode.geometry.y = bary;

                if (vm.unit == 'mm') {
                    $scope.pageValue.barcode.x = PxTurnMm(barx).toFixed(2) - 0;//px-->mm
                    $scope.pageValue.barcode.y = PxTurnMm(bary).toFixed(2) - 0;//px-->mm
                } else if (vm.unit == 'px') {
                    $scope.pageValue.barcode.x = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                    $scope.pageValue.barcode.y = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                } else if (vm.unit == 'in') {
                    $scope.pageValue.barcode.x = PxTurnIn(barx).toFixed(2) - 0;
                    $scope.pageValue.barcode.y = PxTurnIn(bary).toFixed(2) - 0;
                }
            }
            return false;
        }

        //条形码鼠标松开
        function moveBarcodeOnEnd() {
            $scope.$digest();
            dragBar = false;
            document.removeEventListener("mousemove", moveBarcodeOn, true);
            document.removeEventListener("mouseup", moveBarcodeOnEnd, true);
            dragBarBox = {};
            updateHistorys();
        }

        window.moveBarcodeStart1 = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            dragBarBox.clientX = e.clientX || 0;
            dragBarBox.clientY = e.clientY || 0;
            dragBarBox.coordX = targ.offsetLeft;
            dragBarBox.coordY = targ.offsetTop;
            dragBar = true;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.barcode.lock) {
                return
            }
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragBarBox.ctrlKey = true;
            } else {
                dragBarBox.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveBarcodeOn1, true);
            document.addEventListener("mouseup", moveBarcodeOnEnd1, true);
        };

        //条形码move事件
        function moveBarcodeOn1(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();

            if (dragBar) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];

                var clientX = e.clientX - dragBarBox.clientX;
                var clientY = e.clientY - dragBarBox.clientY;

                var barOffsetX = (dragBarBox.coordX + clientX);
                var barOffsetY = (dragBarBox.coordY + clientY);
                var barWidth = currentPage.barcode.geometry.width * currentPage.scale / currentPage.proportion;
                var barHeight = currentPage.barcode.geometry.height * currentPage.scale / currentPage.proportion;
                var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scale;
                var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scale;
                var barcodeAngle = currentPage.barcode.rotation.angle;

                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                var barx = 0, bary = 0;

                //中心点
                var xcenter = barWidth / 2 + barOffsetX;
                var ycenter = barHeight / 2 + barOffsetY;


                if (barcodeAngle == 0) {
                    if (barOffsetX <= 0) {
                        barOffsetX = 0;
                    }

                    if (barOffsetX >= (width + lar - barWidth)) {
                        barOffsetX = width + lar - barWidth;
                    }
                    if (barOffsetY <= 0) {
                        barOffsetY = 0;
                    }

                    if (barOffsetY >= (height + tab - barHeight)) {
                        barOffsetY = height + tab - barHeight;
                    }

                    barx = barOffsetX / currentPage.scale * currentPage.proportion / $scope.proportion;
                    bary = barOffsetY / currentPage.scale * currentPage.proportion / $scope.proportion;
                } else {

                    if (xcenter <= 0) {
                        xcenter = 0;
                    }

                    if (xcenter >= (width + lar)) {
                        xcenter = width + lar;
                    }
                    if (ycenter <= 0) {
                        ycenter = 0;
                    }

                    if (ycenter >= (height + tab)) {
                        ycenter = height + tab;
                    }
                    barx = (xcenter - barWidth / 2) / currentPage.scale * currentPage.proportion / $scope.proportion;
                    bary = (ycenter - barHeight / 2) / currentPage.scale * currentPage.proportion / $scope.proportion;
                }

                currentPage.barcode.geometry.x = barx;
                currentPage.barcode.geometry.y = bary;

                if (vm.unit == 'mm') {
                    $scope.pageValue.barcode.x = PxTurnMm(barx).toFixed(2) - 0;//px-->mm
                    $scope.pageValue.barcode.y = PxTurnMm(bary).toFixed(2) - 0;//px-->mm
                } else if (vm.unit == 'px') {
                    $scope.pageValue.barcode.x = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                    $scope.pageValue.barcode.y = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                } else if (vm.unit == 'in') {
                    $scope.pageValue.barcode.x = PxTurnIn(barx).toFixed(2) - 0;
                    $scope.pageValue.barcode.y = PxTurnIn(bary).toFixed(2) - 0;
                }
                // }
            }
            return false;
        }

        //条形码鼠标松开
        function moveBarcodeOnEnd1() {
            $scope.$digest();
            dragBar = false;
            document.removeEventListener("mousemove", moveBarcodeOn, true);
            document.removeEventListener("mouseup", moveBarcodeOnEnd, true);
            dragBarBox = {};
            updateHistorys();
        }

        //二维码移动事件
        var dragQrcodeTrue;
        var dragQrcode = {};
        window.moveQrcodeStart = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            dragQrcode.clientX = e.clientX || 0;
            dragQrcode.clientY = e.clientY || 0;
            dragQrcode.coordX = targ.offsetLeft;
            dragQrcode.coordY = targ.offsetTop;
            dragQrcodeTrue = true;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.qrcode.lock) {
                return
            }
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragQrcode.ctrlKey = true;
            } else {
                dragQrcode.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveQrcodeOn, true);
            document.addEventListener("mouseup", moveQrcodeOnEnd, true);
        };

        //条形码move事件
        function moveQrcodeOn(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();

            if (dragQrcodeTrue) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];

                var clientX = e.clientX - dragQrcode.clientX;
                var clientY = e.clientY - dragQrcode.clientY;

                var barOffsetX = (dragQrcode.coordX + clientX);
                var barOffsetY = (dragQrcode.coordY + clientY);
                var barWidth = currentPage.qrcode.geometry.width * currentPage.scale / currentPage.proportion;
                var barHeight = currentPage.qrcode.geometry.height * currentPage.scale / currentPage.proportion;
                var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scale;
                var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scale;
                var qrcodeAngle = currentPage.qrcode.rotation.angle;

                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                var barx = 0, bary = 0;

                //中心点
                var xcenter = barWidth / 2 + barOffsetX;
                var ycenter = barHeight / 2 + barOffsetY;


                if (qrcodeAngle == 0) {
                    if (barOffsetX <= 0) {
                        barOffsetX = 0;
                    }

                    if (barOffsetX >= (width + lar - barWidth)) {
                        barOffsetX = width + lar - barWidth;
                    }
                    if (barOffsetY <= 0) {
                        barOffsetY = 0;
                    }

                    if (barOffsetY >= (height + tab - barHeight)) {
                        barOffsetY = height + tab - barHeight;
                    }

                    barx = barOffsetX / currentPage.scale * currentPage.proportion;
                    bary = barOffsetY / currentPage.scale * currentPage.proportion;
                } else {

                    if (xcenter <= 0) {
                        xcenter = 0;
                    }

                    if (xcenter >= (width + lar)) {
                        xcenter = width + lar;
                    }
                    if (ycenter <= 0) {
                        ycenter = 0;
                    }

                    if (ycenter >= (height + tab)) {
                        ycenter = height + tab;
                    }
                    barx = (xcenter - barWidth / 2) / currentPage.scale * currentPage.proportion;
                    bary = (ycenter - barHeight / 2) / currentPage.scale * currentPage.proportion;
                }

                currentPage.qrcode.geometry.x = barx;
                currentPage.qrcode.geometry.y = bary;

                if (vm.unit == 'mm') {
                    $scope.pageValue.qrcode.x = PxTurnMm(barx).toFixed(2) - 0;//px-->mm
                    $scope.pageValue.qrcode.y = PxTurnMm(bary).toFixed(2) - 0;//px-->mm
                } else if (vm.unit == 'px') {
                    $scope.pageValue.qrcode.x = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                    $scope.pageValue.qrcode.y = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                } else if (vm.unit == 'in') {
                    $scope.pageValue.qrcode.x = PxTurnIn(barx).toFixed(2) - 0;
                    $scope.pageValue.qrcode.y = PxTurnIn(bary).toFixed(2) - 0;
                }
            }
            return false;
        }

        //条形码鼠标松开
        function moveQrcodeOnEnd() {
            $scope.$digest();
            dragQrcode = false;
            document.removeEventListener("mousemove", moveQrcodeOn, true);
            document.removeEventListener("mouseup", moveQrcodeOnEnd, true);
            dragQrcode = {};
            updateHistorys();
        }

        window.moveQrcodeStart1 = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            dragQrcode.clientX = e.clientX || 0;
            dragQrcode.clientY = e.clientY || 0;
            dragQrcode.coordX = targ.offsetLeft;
            dragQrcode.coordY = targ.offsetTop;
            dragQrcodeTrue = true;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.qrcode.lock) {
                return
            }
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragQrcode.ctrlKey = true;
            } else {
                dragQrcode.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveQrcodeOn1, true);
            document.addEventListener("mouseup", moveQrcodeOnEnd1, true);
        };

        //条形码move事件
        function moveQrcodeOn1(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();

            if (dragQrcodeTrue) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];

                var clientX = e.clientX - dragQrcode.clientX;
                var clientY = e.clientY - dragQrcode.clientY;

                var barOffsetX = (dragQrcode.coordX + clientX);
                var barOffsetY = (dragQrcode.coordY + clientY);
                var barWidth = currentPage.qrcode.geometry.width * currentPage.scale / currentPage.proportion;
                var barHeight = currentPage.qrcode.geometry.height * currentPage.scale / currentPage.proportion;
                var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scale;
                var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scale;
                var qrcodeAngle = currentPage.qrcode.rotation.angle;

                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                var barx = 0, bary = 0;

                //中心点
                var xcenter = barWidth / 2 + barOffsetX;
                var ycenter = barHeight / 2 + barOffsetY;


                if (qrcodeAngle == 0) {
                    if (barOffsetX <= 0) {
                        barOffsetX = 0;
                    }

                    if (barOffsetX >= (width + lar - barWidth)) {
                        barOffsetX = width + lar - barWidth;
                    }
                    if (barOffsetY <= 0) {
                        barOffsetY = 0;
                    }

                    if (barOffsetY >= (height + tab - barHeight)) {
                        barOffsetY = height + tab - barHeight;
                    }

                    barx = barOffsetX / currentPage.scale * currentPage.proportion / $scope.proportion;
                    bary = barOffsetY / currentPage.scale * currentPage.proportion / $scope.proportion;
                } else {

                    if (xcenter <= 0) {
                        xcenter = 0;
                    }

                    if (xcenter >= (width + lar)) {
                        xcenter = width + lar;
                    }
                    if (ycenter <= 0) {
                        ycenter = 0;
                    }

                    if (ycenter >= (height + tab)) {
                        ycenter = height + tab;
                    }
                    barx = (xcenter - barWidth / 2) / currentPage.scale * currentPage.proportion / $scope.proportion;
                    bary = (ycenter - barHeight / 2) / currentPage.scale * currentPage.proportion / $scope.proportion;
                }

                currentPage.qrcode.geometry.x = barx;
                currentPage.qrcode.geometry.y = bary;

                if (vm.unit == 'mm') {
                    $scope.pageValue.qrcode.x = PxTurnMm(barx).toFixed(2) - 0;//px-->mm
                    $scope.pageValue.qrcode.y = PxTurnMm(bary).toFixed(2) - 0;//px-->mm
                } else if (vm.unit == 'px') {
                    $scope.pageValue.qrcode.x = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                    $scope.pageValue.qrcode.y = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                } else if (vm.unit == 'in') {
                    $scope.pageValue.qrcode.x = PxTurnIn(barx).toFixed(2) - 0;
                    $scope.pageValue.qrcode.y = PxTurnIn(bary).toFixed(2) - 0;
                }
                // }
            }
            return false;
        }

        //条形码鼠标松开
        function moveQrcodeOnEnd1() {
            $scope.$digest();
            dragQrcode = false;
            document.removeEventListener("mousemove", moveQrcodeOn1, true);
            document.removeEventListener("mouseup", moveQrcodeOnEnd1, true);
            dragQrcode = {};
            updateHistorys();
        }

        /* ==========================================================================
         单位切换
         ========================================================================== */

        // mm---px
        function ThreeHundredDpi(attr1, attr2) {
            // Math.floor(px / 300 * 25.4)   (px--mm)
            // Math.ceil(mm / 25.4 * 300)   (mm---px)
            var px1 = parseFloat((attr1 / 25.4 * vm.windowDpiW - 0)).toFixed(2) - 0;
            var px2 = parseFloat((attr2 / 25.4 * vm.windowDpiH - 0)).toFixed(2) - 0;
            return {
                px1: px1,
                px2: px2
            }
        }

        // px---mm
        function ThreeHundredDpiToMm(attr1, attr2) {
            var mm1 = parseFloat((attr1 / vm.windowDpiW * 25.4 - 0)).toFixed(2) - 0;
            var mm2 = parseFloat((attr2 / vm.windowDpiH * 25.4 - 0)).toFixed(2) - 0;
            return {
                mm1: mm1,
                mm2: mm2
            }
        }


        /* ==========================================================================
         鼠标滑过事件
         ========================================================================== */

        $scope.isAlignHover = false;
        $scope.isDistriHover = false;
        var toobarFlag1 = true;
        var toobarFlag2 = true;
        $scope.toobarAlignment = function () {
            $(".space-slider").addClass('hide1');
            if (toobarFlag1) {
                $scope.isAlignHover = true;
                $scope.isDistriHover = false;
                toobarFlag1 = false;
                toobarFlag2 = true;
            } else {
                $scope.isAlignHover = false;
                toobarFlag1 = true;
            }
        }

        $scope.toolbarDistribution = function () {
            $(".space-slider").addClass('hide1');
            if (toobarFlag2) {
                $scope.isAlignHover = false;
                $scope.isDistriHover = true;
                toobarFlag2 = false;
                toobarFlag1 = true;
            } else {
                $scope.isDistriHover = false;
                toobarFlag2 = true;
            }
        }
        $scope.canvasMiddle = function () {
            var all = getAllCtrlKeyArray();
            if (all.length > 0) {
                for (var i = 0; i < all.length; i++) {
                    if (!all[i].lock) {
                        all[i].geometry.x = ($scope.template.pages.page[$scope.template.pageI].mediabox.width - all[i].geometry.width) / 2;
                        all[i].geometry.y = ($scope.template.pages.page[$scope.template.pageI].mediabox.height - all[i].geometry.height) / 2;
                    }
                }
                resetCtrlKeyArray();
                return;
            }
            if (vm.CtrlKeyArray.imageboxes.length > 1) {
                $scope.template.pages.page[$scope.template.pageI].levels.level.forEach(function (item) {
                    if (!item.imagebox.lock && item.imagebox.ctrlKey) {
                        item.imagebox.geometry.x = ($scope.template.pages.page[$scope.template.pageI].mediabox.width - item.imagebox.geometry.width) / 2;
                        item.imagebox.geometry.y = ($scope.template.pages.page[$scope.template.pageI].mediabox.height - item.imagebox.geometry.height) / 2;
                    }
                    item.imagebox.ctrlKey = false
                })
                vm.CtrlKeyArray.imageboxes = [];
                resetCtrlKeyArray();
                return
            }
            var item = null;
            if ($scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox) {
                item = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
            }
            if ($scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textdocument) {
                item = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textdocument;
            }
            if ($scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imagebox) {
                item = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imagebox;
            }
            if ($scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imageart) {
                item = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imageart;
            }
            if ($scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].calTitleBox) {
                item = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].calTitleBox;
            }
            if ($scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].calMonthBox) {
                item = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].calMonthBox;
            }
            if (item && !item.lock) {
                item.geometry.x = ($scope.template.pages.page[$scope.template.pageI].mediabox.width - item.geometry.width) / 2;
                item.geometry.y = ($scope.template.pages.page[$scope.template.pageI].mediabox.height - item.geometry.height) / 2;
            }
            resetCtrlKeyArray();
        };

        $scope.boxAlignment = function (attr) {
            var levels = $scope.currentPage.levels;
            var all = getAllCtrlKeyArray();

            if (attr == 'left') {
                all.sort(compare2('x', true));
            } else if (attr == 'right') {
                all.sort(compare2('x', false, 'width'));
            } else if (attr == 'top') {
                all.sort(compare2('y', true));
            } else if (attr == 'bottom') {
                all.sort(compare2('y', false, 'height'));
            }
            if (attr == 'vertical' && all.length > 0) {
                var verticalY = horizontalAndVerticalCenter(attr, all);
            }
            if (attr == 'standard' && all.length > 0) {
                var standardX = horizontalAndVerticalCenter(attr, all);
            }
            var bigBoxCoord = null;
            if(['left','right','top','bottom'].includes(attr)){
                bigBoxCoord = getAllElemCoord(all);
            }
            for (var i = 0; i < all.length; i++) {
                if (!all[i].lock) {
                    if (attr == 'left') {
                        var coord = getElemMoveCoord('left',bigBoxCoord,all[i]);
                        all[i].geometry.x = coord;

                    } else if (attr == 'right') {
                        var coord = getElemMoveCoord('right',bigBoxCoord,all[i]);
                        all[i].geometry.x = coord;

                    } else if (attr == 'top') {
                        var coord = getElemMoveCoord('top',bigBoxCoord,all[i]);
                        all[i].geometry.y = coord;

                    } else if (attr == 'bottom') {
                        var coord = getElemMoveCoord('bottom',bigBoxCoord,all[i]);
                        all[i].geometry.y = coord;

                    } else if (attr == 'vertical') {
                        all[i].geometry.y = verticalY - all[i].geometry.height / 2;
                    } else {
                        all[i].geometry.x = standardX - all[i].geometry.width / 2;
                    }


                    if (all[i].current == 'imagebox') {
                        levels.level[all[i].levelI].imagebox = all[i];
                    } else if (all[i].current == 'textbox') {
                        levels.level[all[i].levelI].textbox = all[i];
                    } else if (all[i].current == 'calMonthBox') {
                        levels.level[all[i].levelI].calMonthBox = all[i];
                    } else if (all[i].current == 'calTitleBox') {
                        levels.level[all[i].levelI].calTitleBox = all[i];
                    } else {
                        levels.level[all[i].levelI].imageart = all[i];
                    }
                }
            }
            // moveTogetherEnd();
            updateHistorys();
            resetCtrlKeyArray();

        }

        $scope.boxDistribution = function (attr) {
            var all1 = getAllCtrlKeyArray();
            var all = [];
            all1.forEach(function(box){
                if (!box.lock) {
                    all.push(box)
                }
            })
            if (all.length < 3) {
                MessageService.error('请选择3个以上非锁定状态的元素！');
                return
            }

            // 水平分布
            if (attr == 'standard') {
                setAllCtrlKeyArray(all, 'x');
            } else {
                // 垂直分布
                setAllCtrlKeyArray(all, 'y');

            }
            resetCtrlKeyArray();
            updateHistorys();
        }

        function setAllCtrlKeyArray(all, attr) {
            var len = all.length - 1;
            all.sort(compare2(attr, true));
            if (attr == 'x') {
                var elementAllWidth = 0;
                for (var i = 1; i < len; i++) {
                    elementAllWidth+=all[i].geometry.width;
                }
                var spacingPx = (all[len].geometry.x - all[0].geometry.x - all[0].geometry.width - elementAllWidth) / len;
                for (var i = 1; i < len; i++) {
                    all[i].geometry.x = all[i-1].geometry.x + all[i-1].geometry.width + spacingPx;
                }
            }
            if (attr == 'y') {
                var elementAllHeight = 0;
                for (var i = 1; i < len; i++) {
                    elementAllHeight+=all[i].geometry.height;
                }
                var spacingPx = (all[len].geometry.y - all[0].geometry.y - all[0].geometry.height - elementAllHeight) / len;
                for (var i = 1; i < len; i++) {
                    all[i].geometry.y = all[i-1].geometry.y + all[i-1].geometry.height + spacingPx;
                }
            }
        }
        //移动画布
        vm.moveCanvasView = false;
        canvasInfo();
        function canvasInfo(){
            vm.moveCanvasObj = {
                x:0,
                y:0
            };
        }
        $scope.moveCanvas = function(e){
            if(!vm.moveCanvasView){
                return
            }
            var _moveCanvasObj = angular.copy(vm.moveCanvasObj);

            moveCanvas(e, function (x, y) {
                vm.moveCanvasObj.x = _moveCanvasObj.x + x;
                vm.moveCanvasObj.y = _moveCanvasObj.y + y;
                $timeout(function () {
                    updateMeasureRuler();
                    $scope.$apply();
                })

            })
        }

        /**
         *
         * 自定义元素右键菜单样式  复制、剪切、删除
         *
         */
        document.onkeydown = function (event) {
            // document.getElementById('#input')==document.activeElement
            var focus = false;
            document.querySelectorAll('input[type="text"]').forEach(function(input){
                if(input == document.activeElement){
                    focus = true;
                }
            })
            document.querySelectorAll('input[type="number"]').forEach(function(input){
                if(input == document.activeElement){
                    focus = true;
                }
            })
            document.querySelectorAll('textarea').forEach(function(textarea){
                if(textarea == document.activeElement){
                    focus = true;
                }
            })
            if(focus || vm.textEditor){
                return
            }
            // fn(event);
            if(event.keyCode == 32 && !vm.textEditor){
                $timeout(function () {
                    vm.moveCanvasView = true;
                })
                return false
            }
            if (event.keyCode == 13) {
                if (vm.isImageartTrim) {
                    $scope.imageartTrimTrueClick();
                    return false;
                }
                if (vm.isImageboxTrim) {
                    $scope.imageboxTrueClick();
                    return false;
                }
                if ($scope.showCut) {
                    $scope.showCut = false;
                    return false;
                }
            }
            //F11
            if (event.keyCode===122 && !vm.isFullScreen) {
                event.preventDefault();
                $scope.fullScreen();
            }
            $timeout(function () {
                vm.ctrlKey = true;
                if (event.keyCode == 27) {
                    if (vm.fullFlag) {
                        vm.fullFlag = false;
                    }
                    $scope.showCut = false;
                    vm.isImageartTrim = false;
                    $scope.imageboxFalseClick();
                }
                // 删除
                if (event.keyCode == 46) {
                    $scope.deleteBox();
                    $scope.$digest();
                }
                // ctrl+c
                if ((event.ctrlKey == true || event.metaKey == true) && event.keyCode == 67) {
                    if (event.target.nodeName == 'TEXTAREA') {
                        return
                    }

                    if(vm.CtrlKeyArray.imageboxes.length||vm.CtrlKeyArray.imagearts.length||vm.CtrlKeyArray.textboxes.length||vm.CtrlKeyArray.calMonthBoxs.length||vm.CtrlKeyArray.calTitleBoxs.length){
                        vm.copyArray = angular.copy(vm.CtrlKeyArray);
                    }
                }
                //ctrl+v
                if ((event.ctrlKey == true || event.metaKey == true) && event.keyCode == 86) {
                    if (event.target.nodeName == 'TEXTAREA') {
                        return
                    }
                    var currentPage = $scope.currentPage;
                    if(!vm.copyArray || vm.loadingImg){
                        return
                    }
                    if(vm.copyArray.imageboxes.length||vm.copyArray.imagearts.length||vm.copyArray.textboxes.length||vm.copyArray.calMonthBoxs.length||vm.copyArray.calTitleBoxs.length){
                        copyTogether(event,currentPage);
                    }
                    $scope.$digest();
                }
                // ctrl+x
                if ((event.ctrlKey == true || event.metaKey == true) && event.keyCode == 88 && !vm.textEditor) {
                    $scope.deleteBox();
                    $scope.$digest();
                }
                if (event.ctrlKey == true || event.metaKey == true) {
                    $timeout(function () {
                        if ($scope.RightPanel == 'mask' && $scope.currentPage.levels.level[$scope.template.levelI] && $scope.currentPage.levels.level[$scope.template.levelI].imagebox && !$scope.currentPage.levels.level[$scope.template.levelI].imagebox.ctrlKey) {
                            $scope.selectImg(event, $scope.template.levelI, $scope.currentPage.levels.level[$scope.template.levelI].imagebox)
                        }
                        if ($scope.RightPanel == 'material' && $scope.currentPage.levels.level[$scope.template.levelI] && $scope.currentPage.levels.level[$scope.template.levelI].imageart && !$scope.currentPage.levels.level[$scope.template.levelI].imageart.ctrlKey) {
                            $scope.selectArt(event, $scope.template.levelI, $scope.currentPage.levels.level[$scope.template.levelI].imageart)
                        }
                        if ($scope.RightPanel == 'text' && $scope.currentPage.levels.level[$scope.template.levelI] && $scope.currentPage.levels.level[$scope.template.levelI].textbox && !$scope.currentPage.levels.level[$scope.template.levelI].textbox.ctrlKey) {
                            $scope.selectText(event, $scope.template.levelI, $scope.currentPage.levels.level[$scope.template.levelI].textbox)
                        }
                        if ($scope.RightPanel == 'calMonthBox' && $scope.currentPage.levels.level[$scope.template.levelI] && $scope.currentPage.levels.level[$scope.template.levelI].calMonthBox && !$scope.currentPage.levels.level[$scope.template.levelI].calMonthBox.ctrlKey) {
                            $scope.selectCalMonthBox(event, $scope.template.levelI, $scope.currentPage.levels.level[$scope.template.levelI].calMonthBox)
                        }
                        if ($scope.RightPanel == 'calTitleBox' && $scope.currentPage.levels.level[$scope.template.levelI] && $scope.currentPage.levels.level[$scope.template.levelI].calTitleBox && !$scope.currentPage.levels.level[$scope.template.levelI].calTitleBox.ctrlKey) {
                            $scope.selectCalTitleBox(event, $scope.template.levelI, $scope.currentPage.levels.level[$scope.template.levelI].calTitleBox)
                        }
                    })
                }

                if (!vm.textEditor && event.target.tagName == 'INPUT') {
                    return
                }
            })
            // ctrl+z
            if (event.keyCode == 90 && event.ctrlKey && !vm.textEditor) {
                $scope.rollback(0);
                $scope.$digest();
            }
            // 上
            if (event.keyCode == 38 && !vm.textEditor) {
                keycodeArrow(38);
                fn(event)
            }
            // 下
            if (event.keyCode == 40 && !vm.textEditor) {
                keycodeArrow(40);
                fn(event)
            }
            // 左
            if (event.keyCode == 37 && !vm.textEditor) {
                keycodeArrow(37);
                fn(event)
            }
            // 右
            if (event.keyCode == 39 && !vm.textEditor) {
                keycodeArrow(39);
                fn(event)
            }
            event.returnvalue = false;
        };


        function copyTogether(e,currentPage){
            $scope.currentPage.updateThumbnail = true;
            angular.forEach(vm.copyArray.imageboxes, function (imagebox, i) {
                if(imagebox&&imagebox.geometry){
                    // 当前页所有占位框
                    var box = angular.copy(imagebox);
                    var currentLevel = addLevel(currentPage);
                    currentLevel.imagebox = copyDetail2(box);
                    updateHistorys();
                }

            })

            angular.forEach(vm.copyArray.imagearts, function (imageart, i) {
                if(imageart&&imageart.geometry){
                    // 当前页所有素材
                    var box = angular.copy(imageart);
                    var currentLevel = addLevel(currentPage);
                    currentLevel.imageart = copyDetail2(box);
                    updateHistorys();
                }

            })

            angular.forEach(vm.copyArray.textboxes, function (textbox, i) {
                if(textbox&&textbox.geometry){
                    // 当前页所有文字
                    var box = angular.copy(textbox);
                    var currentLevel = addLevel(currentPage);
                    currentLevel.textbox = copyDetail2(box);
                    updateHistorys();
                }

            })

            angular.forEach(vm.copyArray.calTitleBoxs, function (calTitleBox, i) {
                if(calTitleBox&&calTitleBox.geometry){
                    var currentPage = $scope.currentPage;
                    monthIndexSet(currentPage, function () {
                        // 当前页所有文字
                        var box = angular.copy(calTitleBox);
                        var currentLevel = addLevel(currentPage);
                        currentLevel.calTitleBox = copyDetail2(box);
                        dateUpdate()
                        updateHistorys();
                    })
                }

            })
            angular.forEach(vm.copyArray.calMonthBoxs, function (calMonthBox, i) {
                if(calMonthBox&&calMonthBox.geometry){
                    var currentPage = $scope.currentPage;
                    monthIndexSet(currentPage, function () {
                        // 当前页所有文字
                        var box = angular.copy(calMonthBox);
                        var currentLevel = addLevel(currentPage);
                        currentLevel.calMonthBox = copyDetail2(box);
                        dateUpdate()
                        updateHistorys();
                    })
                }

            });
            $scope.levelClick(e,$scope.currentPage.levels.level[$scope.currentPage.levels.level.length-1],0);
        }
        function dateUpdate() {
            if($scope.currentPage && $scope.currentPage.levels && $scope.currentPage.levels.level){
                $scope.currentPage.levels.level.forEach(function (level) {
                    if(level.calMonthBox){
                        level.calMonthBox.obj=null;
                        level.calMonthBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,$scope.currentPage.config.monthIndex);
                        level.calMonthBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,$scope.currentPage.config.monthIndex);
                    }
                    if(level.calTitleBox){
                        level.calTitleBox.obj=null;
                        level.calTitleBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,$scope.currentPage.config.monthIndex);
                        level.calTitleBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,$scope.currentPage.config.monthIndex);
                    }
                })
            }
        }


        function copyDetail2(box) {
            box.levelI = $scope.currentPage.levels.level.length-1;
            box.lock = false;
            var repeatEle = false;
            $scope.currentPage.levels.level.forEach(function(item){
                if(item.imagebox && item.imagebox.geometry.x == box.geometry.x && item.imagebox.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.imageart && item.imageart.geometry.x == box.geometry.x && item.imageart.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.textbox && item.textbox.geometry.x == box.geometry.x && item.textbox.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.code && item.code.geometry.x == box.geometry.x && item.code.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.calTitleBox && item.calTitleBox.geometry.x == box.geometry.x && item.calTitleBox.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.calMonthBox && item.calMonthBox.geometry.x == box.geometry.x && item.calMonthBox.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
            })
            if(!repeatEle){
                return box
            }
            if (box.current == 'imagebox') {
                vm.copyArray.imageboxes.forEach(function(imagebox){
                    if(imagebox && imagebox.geometry.x == box.geometry.x && imagebox.geometry.y == box.geometry.y){
                        var x = PxTurnMm(imagebox.geometry.x) + 1;
                        var y = PxTurnMm(imagebox.geometry.y) + 1;
                        imagebox.geometry.x = MmTurnPx(x);
                        imagebox.geometry.y = MmTurnPx(y);
                    }
                })
            }
            if (box.current == 'imageart') {
                vm.copyArray.imagearts.forEach(function(imageart){
                    if(imageart && imageart.geometry.x == box.geometry.x && imageart.geometry.y == box.geometry.y){
                        var x = PxTurnMm(imageart.geometry.x) + 1;
                        var y = PxTurnMm(imageart.geometry.y) + 1;
                        imageart.geometry.x = MmTurnPx(x);
                        imageart.geometry.y = MmTurnPx(y);
                    }
                })
            }
            if(box.current == 'textbox'){
                vm.copyArray.textboxes.forEach(function(textbox){
                    if(textbox && textbox.geometry.x == box.geometry.x && textbox.geometry.y == box.geometry.y){
                        var x = PxTurnMm(textbox.geometry.x) + 1;
                        var y = PxTurnMm(textbox.geometry.y) + 1;
                        textbox.geometry.x = MmTurnPx(x);
                        textbox.geometry.y = MmTurnPx(y);
                    }
                })
            }
            if(box.current == 'calTitleBox'){
                vm.copyArray.calTitleBoxs.forEach(function(calTitleBox){
                    if(calTitleBox && calTitleBox.geometry.x == box.geometry.x && calTitleBox.geometry.y == box.geometry.y){
                        var x = PxTurnMm(calTitleBox.geometry.x) + 1;
                        var y = PxTurnMm(calTitleBox.geometry.y) + 1;
                        calTitleBox.geometry.x = MmTurnPx(x);
                        calTitleBox.geometry.y = MmTurnPx(y);
                    }
                })
            }
            if(box.current == 'calMonthBox'){
                vm.copyArray.calMonthBoxs.forEach(function(calMonthBox){
                    if(calMonthBox && calMonthBox.geometry.x == box.geometry.x && calMonthBox.geometry.y == box.geometry.y){
                        var x = PxTurnMm(calMonthBox.geometry.x) + 1;
                        var y = PxTurnMm(calMonthBox.geometry.y) + 1;
                        calMonthBox.geometry.x = MmTurnPx(x);
                        calMonthBox.geometry.y = MmTurnPx(y);
                    }
                })
            }
            var x = PxTurnMm(box.geometry.x) + 1;
            var y = PxTurnMm(box.geometry.y) + 1;
            box.geometry.x = MmTurnPx(x);
            box.geometry.y = MmTurnPx(y);
            // 判断边界
            if (box.geometry.y < 0) {
                box.geometry.y = 0;
            }
            if (box.geometry.y > $scope.currentPage.mediabox.height - box.geometry.height) {
                box.geometry.y = $scope.currentPage.mediabox.height - box.geometry.height;
            }
            if (box.geometry.x < 0) {
                box.geometry.x = 0;
            }
            if (box.geometry.x > $scope.currentPage.mediabox.width - box.geometry.width) {
                box.geometry.x = $scope.currentPage.mediabox.width - box.geometry.width;
            }
            return box
        }
        document.onkeyup = function(event){
            $timeout(function () {
                vm.ctrlKey = false;
            })
            if(event.keyCode == 32){
                $timeout(function () {
                    vm.moveCanvasView = false;
                })
            }
        }

        function keycodeArrow(keyCode) {
            var temp = JSON.parse(localStorage.getItem('singleArray'));
            var levels = $scope.currentPage.levels;
            var level = levels.level[$scope.template.levelI];
            var currentPage = $scope.template.pages.page[$scope.template.pageI];

            var all = getAllCtrlKeyArray(1);

            var len = all.length;
            var box = null, value = {};
            if (!len) {
                if ($scope.selectBox.current == 'imagebox') {
                    box = level.imagebox;
                    value = $scope.pageValue.imagebox;
                }
                if ($scope.selectBox.current == 'textbox') {
                    box = level.textbox;
                    value = $scope.pageValue.textbox;
                }
                if ($scope.selectBox.current == 'imageart') {
                    box = level.imageart;
                    value = $scope.pageValue.imageart;
                }
                if ($scope.selectBox.current == 'textdocument') {
                    box = level.textdocument;
                    value = $scope.pageValue.textdocument;
                }
                if ($scope.selectBox.current == 'calMonthBox') {
                    box = level.calMonthBox;
                    value = $scope.pageValue.calMonthBox;
                }
                if ($scope.selectBox.current == 'calTitleBox') {
                    box = level.calTitleBox;
                    value = $scope.pageValue.calTitleBox;
                }
                if (!box || box.lock) {
                    return
                }

                var maxHeightPx = parseFloat(currentPage.mediabox.height - box.geometry.height).toFixed(2) - 0;
                var maxWidthPx = parseFloat(currentPage.mediabox.width - box.geometry.width).toFixed(2) - 0;
                // 上
                if (keyCode == 38) {
                    if (vm.unit == 'px') {
                        if (value.y >= 1) {
                            value.y--;
                            box.geometry.y = MmTurnPx(value.y / vm.windowDpiH * 25.4);
                        }
                    } else if (vm.unit == 'mm') {
                        if (value.y >= 1) {
                            value.y -= 0.2;
                            box.geometry.y = MmTurnPx(value.y);
                        }
                    } else if (vm.unit == 'in') {
                        if (value.y >= 1) {
                            value.y -= 0.2;
                            box.geometry.y = InTurnPx(value.y);
                        }
                    }
                }
                // 下
                if (keyCode == 40) {
                    if (vm.unit == 'px') {
                        if (value.y < maxHeightPx) {
                            value.y++;
                            box.geometry.y = MmTurnPx(value.y / vm.windowDpiH * 25.4);
                        }
                    } else if (vm.unit == 'mm') {
                        if (value.y < PxTurnMm(currentPage.mediabox.height - box.geometry.height)) {
                            value.y += 0.2;
                            box.geometry.y = MmTurnPx(value.y);
                        }
                    } else if (vm.unit == 'in') {
                        if (value.y < PxTurnIn(currentPage.mediabox.height - box.geometry.height)) {
                            value.y += 0.2;
                            box.geometry.y = InTurnPx(value.y);
                        }
                    }
                }
                // 左
                if (keyCode == 37) {
                    if (vm.unit == 'px') {
                        if (value.x >= 1) {
                            value.x--;
                            box.geometry.x = MmTurnPx(value.x / vm.windowDpiW * 25.4);
                        }
                    } else if (vm.unit == 'mm') {
                        if (value.x >= 1) {
                            value.x -= 0.2;
                            box.geometry.x = MmTurnPx(value.x);
                        }
                    } else if (vm.unit == 'in') {
                        if (value.x >= 1) {
                            value.x -= 0.2;
                            box.geometry.x = InTurnPx(value.x);
                        }
                    }
                }
                // 右
                if (keyCode == 39) {
                    if (vm.unit == 'px') {
                        if (box.geometry.x < maxWidthPx) {
                            value.x++;
                            box.geometry.x = MmTurnPx(value.x / vm.windowDpiW * 25.4);
                        }
                    } else if (vm.unit == 'mm') {
                        if (value.x < PxTurnMm(maxWidthPx)) {
                            value.x += 0.2;
                            box.geometry.x = MmTurnPx(value.x);
                        }
                    } else if (vm.unit == 'in') {
                        if (value.x < PxTurnIn(maxWidthPx)) {
                            value.x += 0.2;
                            box.geometry.x = InTurnPx(value.x);
                        }
                    }
                }
                updateHistorys();
                $scope.$apply();
            } else {
                for (var i = len - 1; i >= 0; i--) {
                    if (!all[i]) {
                        continue;
                    }
                    var box = null;
                    if (all[i].current == 'imagebox') {
                        box = levels.level[all[i].levelI].imagebox;
                    } else if (all[i].current == 'textbox') {
                        box = levels.level[all[i].levelI].textbox;
                    } else if (all[i].current == 'imageart') {
                        box = levels.level[all[i].levelI].imageart;
                    } else if (all[i].current == 'calTitleBox') {
                        box = levels.level[all[i].levelI].calTitleBox;
                    } else if (all[i].current == 'calMonthBox') {
                        box = levels.level[all[i].levelI].calMonthBox;
                    }
                    if (!box || box.lock) {
                        return
                    }
                    var maxHeightPx = parseFloat(currentPage.mediabox.height - box.geometry.height).toFixed(2) - 0;
                    var maxWidthPx = parseFloat(currentPage.mediabox.width - box.geometry.width).toFixed(2) - 0;
                    if (keyCode == 38) {
                        if (vm.unit == 'px') {
                            if (box.geometry.y >= 1) {
                                box.geometry.y--;
                            }
                        } else if (vm.unit == 'mm') {
                            var y = PxTurnMm(box.geometry.y);
                            if (y >= 1) {
                                y -= 0.2;
                                box.geometry.y = MmTurnPx(y);
                            }
                        } else if (vm.unit == 'in') {
                            var y = PxTurnIn(box.geometry.y);
                            if (y >= 1) {
                                y -= 0.2;
                                box.geometry.y = InTurnPx(y);
                            }
                        }
                    }
                    // 下
                    if (keyCode == 40) {
                        if (vm.unit == 'px') {
                            if (box.geometry.y < maxHeightPx) {
                                box.geometry.y++;
                            }
                        } else if (vm.unit == 'mm') {
                            var y = PxTurnMm(box.geometry.y);
                            if (y < PxTurnMm(maxHeightPx)) {
                                y += 0.2;
                                box.geometry.y = MmTurnPx(y);
                            }
                        } else if (vm.unit == 'in') {
                            var y = PxTurnIn(box.geometry.y);
                            if (y < PxTurnIn(maxHeightPx)) {
                                y += 0.2;
                                box.geometry.y = InTurnPx(y);
                            }
                        }
                    }
                    // 左
                    if (keyCode == 37) {
                        if (vm.unit == 'px') {
                            if (box.geometry.x >= 1) {
                                box.geometry.x--;
                            }
                        } else if (vm.unit == 'mm') {
                            var x = PxTurnMm(box.geometry.x)
                            if (x >= 1) {
                                x -= 0.2;
                                box.geometry.x = MmTurnPx(x);
                            }
                        } else if (vm.unit == 'in') {
                            var x = PxTurnIn(box.geometry.x)
                            if (x >= 1) {
                                x -= 0.2;
                                box.geometry.x = InTurnPx(x);
                            }
                        }
                    }
                    // 右
                    if (keyCode == 39) {
                        if (vm.unit == 'px') {
                            if (box.geometry.x < maxWidthPx) {
                                box.geometry.x++;
                            }
                        } else if (vm.unit == 'mm') {
                            var x = PxTurnMm(box.geometry.x);
                            if (x < PxTurnMm(maxWidthPx)) {
                                x += 0.2;
                                box.geometry.x = MmTurnPx(x);
                            }
                        } else if (vm.unit == 'In') {
                            var x = PxTurnIn(box.geometry.x);
                            if (x < PxTurnIn(maxWidthPx)) {
                                x += 0.2;
                                box.geometry.x = InTurnPx(x);
                            }
                        }
                    }
                    updateHistorys();
                    $scope.$apply();
                }
            }


            $scope.currentPage = currentPage;
        }

        //复制
        $scope.copying = function () {
            var activeElement = document.activeElement.id;
            if ($scope.selectBox.current == 'textbox' && activeElement == "selectFontInput") {
                //如果当前选中是文本 且字体输入框获取了焦点 则不复制
                return;
            }
            var temp = JSON.parse(localStorage.getItem('singleArray'));
            if (vm.singleArray) {
                temp = vm.singleArray;
            }
            var all = getAllCtrlKeyArray(1);
            var len = all.length;
            if ((temp[$scope.selectBox.current] && temp[$scope.selectBox.current].geometry) || len > 0) {
                // if((!temp[$scope.selectBox.current] || !temp[$scope.selectBox.current].geometry)){
                //     return;
                // }
                var levels = $scope.currentPage.levels;
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                // 单选状态
                if (!len && $scope.selectBox.current) {
                    var currentLevel = addLevel(currentPage);
                    if ($scope.selectBox.current == 'imagebox' && temp.imagebox && temp.imagebox.geometry) {
                        var box = angular.copy(temp.imagebox);
                        currentLevel.imagebox = copyDetail(box, temp.imagebox);
                        vm.singleArray.imagebox = currentLevel.imagebox;
                    }
                    if ($scope.selectBox.current == 'textbox' && temp.textbox && temp.textbox.geometry) {
                        var box = angular.copy(temp.textbox);
                        currentLevel.textbox = copyDetail(box, temp.textbox);
                        vm.singleArray.textbox = currentLevel.textbox;

                    }
                    if ($scope.selectBox.current == 'imageart' && temp.imageart && temp.imageart.geometry) {
                        var box = angular.copy(temp.imageart);
                        currentLevel.imageart = copyDetail(box, temp.imageart);
                        vm.singleArray.imageart = currentLevel.imageart;

                    }
                    if ($scope.selectBox.current == 'textdocument' && temp.textdocument && temp.textdocument.geometry) {
                        var box = angular.copy(temp.textdocument);
                        currentLevel.textdocument = copyDetail(box, temp.textdocument);
                        vm.singleArray.textdocument = currentLevel.textdocument;

                    }
                    if ($scope.selectBox.current == 'calMonthBox' && temp.calMonthBox && temp.calMonthBox.geometry) {
                        var box = angular.copy(temp.calMonthBox);
                        currentLevel.calMonthBox = copyDetail(box, temp.calMonthBox);
                        vm.singleArray.calMonthBox = currentLevel.calMonthBox;

                    }
                    if ($scope.selectBox.current == 'calTitleBox' && temp.calTitleBox && temp.calTitleBox.geometry) {
                        var box = angular.copy(temp.calTitleBox);
                        currentLevel.calTitleBox = copyDetail(box, temp.calTitleBox);
                        vm.singleArray.calTitleBox = currentLevel.calTitleBox;

                    }
                    $scope.template.levelI = levels.level.length - 1;
                } else {
                    if (all.length == 0) {
                        return;
                    }
                    // ctrl+点击
                    for (var i = len - 1; i >= 0; i--) {
                        if (!all[i]) {
                            continue;
                        }
                        var box = angular.copy(all[i]);
                        var currentLevel = addLevel(currentPage);
                        if (all[i].current == 'imagebox') {
                            if (levels.level[box.levelI]) {
                                currentLevel.imagebox = copyDetail(box, all[i]);
                            } else {
                                box.levelI = levels.length;
                                currentLevel.imagebox = box;
                            }
                        } else if (all[i].current == 'textbox') {
                            if (levels.level[box.levelI]) {
                                currentLevel.textbox = copyDetail(box, all[i]);
                            } else {
                                box.index = levels.length;
                                currentLevel.textbox = box;
                            }
                        } else if (all[i].current == 'imageart') {
                            if (levels.level[box.levelI]) {
                                currentLevel.imageart = copyDetail(box, all[i]);
                            } else {
                                box.index = levels.length;
                                currentLevel.imageart = box;
                            }
                        } else if (all[i].current == 'textdocument') {
                            if (levels.level[box.levelI]) {
                                currentLevel.textdocument = copyDetail(box, all[i]);
                            } else {
                                box.index = levels.length;
                                currentLevel.textdocument = box;
                            }
                        }
                    }
                    $scope.clearCtrlKey();
                    localStorage.removeItem('singleArray');
                    $scope.selectBox = {}
                }
                getImageboxLen();
                getPagePx();
                updateHistorys();
            }
        };

        // 剪切
        $scope.cuting = function () {
            var temp = JSON.parse(localStorage.getItem('singleArray'));

            var levels = $scope.currentPage.levels;
            var level = levels.level[$scope.template.levelI];
            var currentPage = $scope.template.pages.page[$scope.template.pageI]
            // 当前页所有占位框
            var currentImages = currentPage.imageboxes.imagebox;
            // 当前页所有文本
            var currentTexts = currentPage.textboxes.textbox;
            // 当前页所有素材
            var currentArts = currentPage.imagearts.imageart;
            var all = getAllCtrlKeyArray(1);

            var len = all.length;
            // 单选状态
            if (!len) {
                if ($scope.selectBox.current == 'imagebox') {
                    currentImages.splice($scope.template.imgI, 1);
                    // $scope.template.imgI = currentImages.length - 1;
                }
                if ($scope.selectBox.current == 'textbox') {
                    currentTexts.splice($scope.template.textI, 1);
                    // $scope.template.textI = currentTexts.length - 1;
                }
                if ($scope.selectBox.current == 'imageart') {
                    currentArts.splice($scope.template.artI, 1);
                    // $scope.template.artI = currentArts.length - 1;
                }
                localStorage.setItem('singleArray', JSON.stringify(vm.singleArray));
            } else {
                // ctrl+点击
                for (var i = len - 1; i >= 0; i--) {
                    if (!all[i]) {
                        continue;
                    }
                    var box = angular.copy(all[i]);
                    if (all[i].current == 'imagebox') {
                        currentImages.splice(box.index, 1);
                    } else if (all[i].current == 'textbox') {
                        currentTexts.splice(box.index, 1);
                    } else if (all[i].current == 'imageart') {
                        currentArts.splice(box.index, 1);
                    }
                }
            }
        }

        function copyDetail(box, currentbox) {
            box.geometry.x = MmTurnPx(1) + currentbox.geometry.x;
            box.geometry.y = MmTurnPx(1) + currentbox.geometry.y;
            // box.geometry.x = PxTurnMm(currentbox.geometry.x) + 1;
            // box.geometry.y = PxTurnMm(currentbox.geometry.y) + 1;
            if ($scope.currentPage.levels.level == null) {
                $scope.currentPage.levels.level = [];
            }
            box.levelI = $scope.currentPage.levels.level.length - 1;
            // 判断边界
            if (box.geometry.y < 0) {
                box.geometry.y = 0;
            }
            if (box.geometry.y > $scope.currentPage.mediabox.height - box.geometry.height) {
                box.geometry.y = $scope.currentPage.mediabox.height - box.geometry.height;
            }
            if (box.geometry.x < 0) {
                box.geometry.x = 0;
            }
            if (box.geometry.x > $scope.currentPage.mediabox.width - box.geometry.width) {
                box.geometry.x = $scope.currentPage.mediabox.width - box.geometry.width;
            }
            return box;
        }

        /**
         * 上一张，下一张
         */
        vm.isSkip = false;
        $scope.pageDirection = function (flag) {
            if(vm.isSkip || (flag&&!$scope.visibleLeft) || (!flag&&!$scope.visibleRight) || vm.textEditor){
                return
            }
            vm.isSkip = true;
            $scope.selectBox = {
                current: 'page'
            }
            vm.loadingImg = true;
            $timeout(function () {
                var _pageIndex = null;
                if (flag) {
                    _pageIndex = $scope.template.pageI - 1;
                } else {
                    _pageIndex = $scope.template.pageI + 1;
                }
                checkPageCover(_pageIndex,function () {
                    $scope.currentPage = null;
                    $timeout(function (){
                        // 上一张
                        if (flag) {
                            $scope.template.pageI = $scope.template.pageI - 1;
                        } else {
                            $scope.template.pageI = $scope.template.pageI + 1;
                        }
                        $scope.lastPage = $scope.template.pages.page[$scope.template.pageI];
                        $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
                        // $(".bg-image").attr('src', '');
                        $scope.RightPanel = 'page';
                        isCanLeftAndRightPage();
                        loadAttribute();
                        $scope.pageHistory = [];

                        var w = 0;
                        for (var i = 0; i < $scope.template.pageI - 3; i++) {
                            var page = $scope.template.pages.page[i];
                            w += page.width * 98 / page.height + 16;
                        }
                        $(".nav-ul").scrollLeft(w);
                        vm.isSkip = false;
                        $scope.changeShirnk(0);
                        resetCtrlKeyArray();
                        vm.loadingImg = false;
                    })
                });
            })
            vm.isTransp = false;
            vm.isShadowSet = false;

        };

        //点击页面显示左右方向
        function isCanLeftAndRightPage() {
            $scope.template.levelI = 0;
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            if ($scope.currentPage.scene) {
                $scope.currentPage.isScene = true;
            } else {
                $scope.currentPage.isScene = false;
            }
            setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, $scope.template.pageI);
            var len = 0;
            angular.forEach($scope.template.pages.page, function (p) {
                if (!p.backup) {
                    len++;
                }
            })

            if ($scope.template.pages.page.length == 1) {
                $scope.visibleLeft = false;
                $scope.visibleRight = false;
            } else if ($scope.template.pageI == 0) {
                $scope.visibleLeft = false;
                $scope.visibleRight = true;
            } else if ($scope.template.pageI == len - 1) {
                $scope.visibleLeft = true;
                $scope.visibleRight = false;
            } else {
                $scope.visibleLeft = true;
                $scope.visibleRight = true;
            }
            getImageboxLen();
        }

        /**
         * 1.无封面则直接生成
         * 2.点击事件中先判断一下开始时间是否为空，为空时就把当前时间赋给开始时间（说明是第一次点击）
         * 否则就是第二次点击，把当前时间赋值给结束时间。
         * 第二次点击时就可以用结束时间减去开始时间，得到两次点击的时间间隔。
         */
        function checkPageCover(pageIndex, callback) {
            if(!$scope.lastPage.updateThumbnail){
                if(callback){callback()}
                return;
            }
            if (pageIndex != $scope.template.pageI) {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage, null, function () {
                    if (callback) {
                        callback()
                    }
                });
            }else{
                if(callback){callback()}
            }

            oldPage = angular.copy($scope.template.pages.page[$scope.template.pageI])
        }

        /**
         * 放大缩小及还原
         */
        $scope.changeShirnk = function (zoomIn) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            var number = 0.02;
            var marTop = 0;

            // 还原
            if (zoomIn == 0) {
                currentPage.scale = 1;
                canvasInfo();
            }

            // 缩小
            if (zoomIn == 1) {
                if (currentPage.scale <= 0.5) {
                    return;
                }
                currentPage.scale = Math.abs(currentPage.scale - number).toFixed(2);
            }

            // 放大
            if (zoomIn == 2) {
                var scale = Math.abs(currentPage.scale - 0 + number).toFixed(2);
                //限制放大倍数
                if (scale > 1.5) {
                    return
                }
                currentPage.scale = scale;
            }
            // 原尺寸
            if (zoomIn == 3) {
                currentPage.scale = 1/currentPage.width*currentPage.mediabox.width;
            }
            currentPage.proportion1 = currentPage.width*currentPage.scale/currentPage.custom.width;
            $scope.currentPage = currentPage;
            updateMeasureRuler();
        }

        $scope.pageSeq = function(page){
            if(page && page.config){
                var _seq = 0;
                for(var i=0; i<$scope.template.pages.page.length; i++){
                    var _page = $scope.template.pages.page[i];
                    if(_page && _page.config &&_page.config.partid == page.config.partid){
                        _seq += 1;
                        if(_page.uuid == page.uuid){
                            break
                        }
                    }
                }
                return _seq;
            }
        }
        $scope.pageSeq1 = function(page){
            if(page && page.config){
                if(page.config.partid != 'P'){
                    return $scope.partNamt(page.config.partid)
                }else{
                    var _seq = 0;
                    for(var i=0; i<$scope.template.pages.page.length; i++){
                        var _page = $scope.template.pages.page[i];
                        if(_page && _page.config &&_page.config.partid == page.config.partid){
                            _seq += 1;
                            if(_page.uuid == page.uuid){
                                break
                            }
                        }
                    }
                    return '内页第'+ _seq + '页';
                }
            }
        }
        //删除模板
        $scope.deletePage = function (index, e) {
            fn(e)
            if($scope.template.pages.page.length == 1){
                MessageService.error("模板页最少为一页");
                return;
            }
            if (index == null && ($scope.template.pageI == null || $scope.template.pageI < 0)) {
                return;
            }

            vm.deleteSure = true;
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确定',
                msg:'确认要删除当前页面吗？'
            },function(){
                $scope.lastPage = angular.copy($scope.template.pages.page[index]);
                $scope.deletePageSure();
            });
            vm.deletaPageIndex = index;
            $scope.pageHistory = [];
        };
        $scope.deletePageSure = function (callback) {
            $timeout(function () {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage, null, function (identifier) {
                    if (!$scope.template.deletedPages) {
                        $scope.template.deletedPages = {page: []};
                    }
                    //取被删除页的部件索引
                    var delPartIndex = $scope.template.config.parts.list.findIndex(function(item){
                        return item.id === $scope.lastPage.config.partid
                    })
                    $scope.template.config.parts.list[delPartIndex].counter = $scope.template.config.parts.list[delPartIndex].counter-1;
                    if($scope.template.config.parts.list[delPartIndex].counter <= 0){
                        if($scope.template.backupPages.list){
                            for(var i=$scope.template.backupPages.list.length-1; i>=0; i-- ){
                                var backupPage = $scope.template.backupPages.list[i];
                                if(backupPage.config.partid == $scope.template.config.parts.list[delPartIndex].id){
                                    $scope.template.backupPages.list.splice(i,1);
                                }
                            }
                        }
                    };
                    if(vm.page.modelType == 'THREE'){
                        var _modelData = JSON.parse(vm.page.modelData);
                        _modelData.forEach(function (modelItem) {
                            if(modelItem.page == vm.deletaPageIndex){
                                modelItem.page = "";
                            }
                        })
                        vm.page.modelData = JSON.stringify(_modelData);
                    }
                    var partName = angular.copy($scope.template.pages.page[vm.deletaPageIndex].config.partid);
                    var p = angular.copy($scope.template.pages.page[vm.deletaPageIndex]);
                    p.pagecover.identifier = identifier;
                    if(vm.templateCopy){
                        vm.templateCopy.pages.page.splice(vm.deletaPageIndex, 1);
                    }
                    $scope.template.pages.page.splice(vm.deletaPageIndex, 1)
                    $scope.template.deletedPages.page.push(p);
                    var len = $scope.template.pages.page.length;
                    if (len > 1) {
                        $scope.template.pages.page[len - 1].spread = false;
                    }
                    if (vm.deletaPageIndex == 0) {
                        $scope.template.pageI = 0;
                    } else if ($scope.template.pageI >= vm.deletaPageIndex) {//当前选中页是被删除页或在被删除页之后，索引向前移动一位
                        $scope.template.pageI = $scope.template.pageI - 1;
                    } else {
                        for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                            var page = $scope.template.pages.page[i];
                            if (!page.backup) {
                                $scope.template.pageI = i;
                                break;
                            }
                        }
                    }
                    if(partName != $scope.template.pages.page[$scope.template.pageI].config.partid){
                        for (var i = 0; i < $scope.template.pages.page.length; i++) {
                            var page = $scope.template.pages.page[i];
                            if (page.config.partid == partName) {
                                $scope.template.pageI = i;
                            }
                        }
                    }
                    $timeout(function () {
                        $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
                        vm.deleteSure = false;
                        $scope.lastPage = $scope.currentPage;
                        // var nodes = document.getElementsByClassName('page-item2');
                        // setThumbnailPic(nodes[0], $scope.lastPage);
                        updateHistorys();
                        isCanLeftAndRightPage();
                        if(callback){callback()}
                    })
                })
            });

        }

        $scope.restorePage = function (item, index) {
            if(vm.textEditor){return}
            vm.deletaPageIndex = -1;
            $scope.template.config.parts.list.forEach(function (part,index) {
                if(part.id == item.config.partid){
                    if(part.id != 'P' && part.counter > 0){
                        for(var c=0; c<$scope.template.pages.page.length; c++){
                            if($scope.template.pages.page[c].config.partid == part.id){
                                vm.deletaPageIndex = c;
                            }
                        }
                    }
                }
            })
            if(vm.deletaPageIndex>-1){
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确定',
                    msg:'已存在当前部件，如继续恢复会删除当前部件下的页，是否继续'
                },function(){
                    $scope.selectPages(vm.deletaPageIndex, null, function () {
                        $scope.deletePageSure(function () {
                            restorePageInit(item, index)
                        });
                    });
                });
            }else{
                restorePageInit(item, index)
            }
        }
        function restorePageInit(item, index){
            vm.operation = true;
            var nodes = document.getElementsByClassName('page-item2');
            setThumbnailPic(nodes[0], $scope.lastPage, null, function () {
                $scope.template.config.parts.list.forEach(function (part,index) {
                    if(part.id == item.config.partid){
                        part.counter = Number(part.counter) + 1;
                    }
                })
                $scope.template.pages.page.push(item);
                $scope.template.deletedPages.page.splice(index, 1);
                $scope.pageHistory = [];
                $timeout(function () {
                    pagesSort(function () {
                        $scope.selectPages($scope.template.pages.page.length-1);
                    })
                })
            })
        }

        // 窗口宽高变化
        window.onresize = function () {
            if (!$scope.currentPage) {
                return;
            }
            if ($scope.currentPage.trimbox) {
                setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, $scope.template.pageI);
                $scope.$digest();
            }
            getFullState();
        };

        function compare2(property, attr, property2) {
            return function (a, b) {
                var value1 = a.geometry[property];
                var value2 = b.geometry[property];
                if (attr) {
                    return value1 - value2;
                } else {
                    var value12 = a.geometry[property2];
                    var value22 = b.geometry[property2];
                    return value2 + value22 - value1 - value12;
                }
            }
        }

        function getAllCtrlKeyArray() {
            var imageboxes = vm.CtrlKeyArray.imageboxes,
                textboxes = vm.CtrlKeyArray.textboxes,
                imagearts = vm.CtrlKeyArray.imagearts,
                all = [];
            var barcode = vm.CtrlKeyArray.barcode;
            var qrcode = vm.CtrlKeyArray.qrcode;
            // //选中的占位框
            // for (var i = 0; i < imageboxes.length; i++) {
            //     if (imageboxes[i]) {
            //         all.push(imageboxes[i]);
            //     }
            // }
            // //选中的文本框
            // for (var i = 0; i < textboxes.length; i++) {
            //     if (textboxes[i]) {
            //         all.push(textboxes[i]);
            //     }
            // }
            // //选中的素材
            // for (var i = 0; i < imagearts.length; i++) {
            //     if (imagearts[i]) {
            //         all.push(imagearts[i]);
            //     }
            // }
            // //选中的条码
            // for (var i = 0; i < barcode.length; i++) {
            //     all.push(barcode[i]);
            // }
            // //选中的二维码
            // for (var i = 0; i < qrcode.length; i++) {
            //     all.push(qrcode[i]);
            // }
            if ($scope.currentPage == null || !$scope.currentPage.levels || !$scope.currentPage.levels.level) {
                return all;
            }

            angular.forEach($scope.currentPage.levels.level, function (level, i) {
                if (level.imageart && level.imageart.ctrlKey) {
                    all.push(level.imageart)
                }
                if (level.imagebox && level.imagebox.ctrlKey) {
                    all.push(level.imagebox)
                }
                if (level.textbox && level.textbox.ctrlKey) {
                    all.push(level.textbox)
                }
                if (level.textdocument && level.textdocument.ctrlKey) {
                    all.push(level.textdocument)
                }
                if (level.calTitleBox && level.calTitleBox.ctrlKey) {
                    all.push(level.calTitleBox)
                }
                if (level.calMonthBox && level.calMonthBox.ctrlKey) {
                    all.push(level.calMonthBox)
                }
                if (level.code && level.code.ctrlKey) {
                    all.push(level.code)
                }
            })
            return all;
        }

        $scope.resetCtrlKeyArray = function (e) {
            if(!vm.textEditor){
                fn(e)
                resetCtrlKeyArray();
                $scope.template.levelI = null;
            }
        };

        function resetCtrlKeyArray() {
            $scope.RightPanel = "";
            var levels = $scope.currentPage.levels;
            if(!levels.level){
                return
            }
            for (var x = 0; x < levels.level.length; x++) {
                var l = levels.level[x];
                if (l.imagebox && l.imagebox.current) {
                    l.imagebox.ctrlKey = false;
                }
                if (l.imageart && l.imageart.current) {
                    l.imageart.ctrlKey = false;
                }
                if (l.textbox && l.textbox.current) {
                    l.textbox.ctrlKey = false;
                }
                if (l.textdocument && l.textdocument.current) {
                    l.textdocument.ctrlKey = false;
                }
                if (l.calTitleBox && l.calTitleBox.current) {
                    l.calTitleBox.ctrlKey = false;
                }
                if (l.calMonthBox && l.calMonthBox.current) {
                    l.calMonthBox.ctrlKey = false;
                }
            }

            vm.CtrlKeyArray.imageboxes = [];
            vm.CtrlKeyArray.textboxes = [];
            vm.CtrlKeyArray.imagearts = [];
            vm.CtrlKeyArray.barcode = [];
            vm.CtrlKeyArray.qrcode = [];
            vm.CtrlKeyArray.calMonthBoxs = [];
            vm.CtrlKeyArray.calTitleBoxs = [];
        }

        $scope.clearCtrlKey = function (flag, e) {
            if(vm.textEditor){
                return
            }
            fn(e)
            // 点击页面内容
            if (flag) {
                getPagePx();
                $scope.shezhi = false;
                // 清空选中
                // $scope.selectBox.current = 'page';
                vm.selectItem = null;
                $scope.selectBox = null;
            }
            $scope.isAlignHover = false;
            $scope.isDistriHover = false;
            toobarFlag1 = true;
            toobarFlag2 = true;
            toolbarStatus();

            vm.CtrlKeyArray.imageboxes = [];
            vm.CtrlKeyArray.textboxes = [];
            vm.CtrlKeyArray.textdocuments = [];
            vm.CtrlKeyArray.imagearts = [];
            vm.CtrlKeyArray.barcode = [];
            vm.CtrlKeyArray.qrcode = [];
            vm.CtrlKeyArray.calMonthBoxs = [];
            vm.CtrlKeyArray.calTitleBoxs = [];

            angular.forEach($scope.currentPage.levels.level, function (l, x) {
                if (l.imagebox && l.imagebox.current) {
                    l.imagebox.ctrlKey = false;
                }
                if (l.imageart && l.imageart.current) {
                    l.imageart.ctrlKey = false;
                }
                if (l.textbox && l.textbox.current) {
                    l.textbox.ctrlKey = false;
                }
                if (l.textdocument && l.textdocument.current) {
                    l.textdocument.ctrlKey = false;
                }
                if (l.calMonthBox && l.calMonthBox.current) {
                    l.calMonthBox.ctrlKey = false;
                }
                if (l.calTitleBox && l.calTitleBox.current) {
                    l.calTitleBox.ctrlKey = false;
                }
            })
        }

        function getPagePx() {
            $scope.RightPanel = 'page';
            loadAttribute();
        }

        $scope.editSize = function (attr) {
            var temp = $scope.template.pages.page[$scope.template.pageI];
            vm.unit = 'mm';
            unit();
            vm.page.editbox = angular.copy(temp.editbox);
            vm.page.editbox = {
                x: PxTurnMm(vm.page.editbox.x),
                y: PxTurnMm(vm.page.editbox.y),
                width: PxTurnMm(vm.page.editbox.width),
                height: PxTurnMm(vm.page.editbox.height),
            };
            vm.page.editboxs = angular.copy(temp.editboxes.editbox);
            vm.page.editboxs.forEach(function (editbox) {
                editbox.x = Number(PxTurnMm(editbox.x).toFixed(2));
                editbox.y = Number(PxTurnMm(editbox.y).toFixed(2))
                editbox.width = Number(PxTurnMm(editbox.width).toFixed(2));
                editbox.height = Number(PxTurnMm(editbox.height).toFixed(2));
            })
            vm.page.pageSize = angular.copy($scope.pageValue.mediabox);
            oldSizePx = angular.copy($scope.currentPage.bloodPx);
            oldSizeMm = angular.copy($scope.currentPage.blood);
            vm.page.pageBloodB = oldSizeMm.bottom - 0;
            vm.page.pageBloodL = oldSizeMm.left - 0;
            vm.page.pageBloodR = oldSizeMm.right - 0;
            vm.page.pageBloodT = oldSizeMm.top - 0;
            vm.page.pageSizeUnit = "mm";

            createModal('app/entities/page/page-edit.html', 'PageEditController', attr).result.then(
                function (data) {
                    vm.operation = true;
                    vm.page = data;
                    vm.unit = vm.page.dpi.data;
                    var temp = $scope.template.pages.page;
                    var i = $scope.template.pageI;

                    if (vm.unit == 'px') {
                        $scope.pageValue.mediabox = data.pageSize;
                        temp[i].editbox = data.editbox;
                        temp[i].editboxes = {
                            editbox: data.editboxs
                        };
                        temp[i].mediabox.width = data.pageSize.width;
                        temp[i].mediabox.height = data.pageSize.height;
                        temp[i].trimbox.x = data.pageBloodL;
                        temp[i].trimbox.y = data.pageBloodT;
                    }
                    if (vm.unit == 'mm') {
                        $scope.pageValue.mediabox = data.pageSize;
                        temp[i].mediabox.width = MmTurnPx(data.pageSize.width);
                        temp[i].mediabox.height = MmTurnPx(data.pageSize.height);
                        temp[i].trimbox.x = MmTurnPx(data.pageBloodL);
                        temp[i].trimbox.y = MmTurnPx(data.pageBloodT);
                        temp[i].editbox = getGeometry(data.editbox);
                        temp[i].editboxes = {
                            editbox: data.editboxs
                        };
                        temp[i].editboxes.editbox.forEach(function (editbox) {
                            editbox.width = MmTurnPx(editbox.width);
                            editbox.height = MmTurnPx(editbox.height);
                            editbox.x = MmTurnPx(editbox.x);
                            editbox.y = MmTurnPx(editbox.y);
                        })
                    }
                    if (vm.unit == 'in') {
                        $scope.pageValue.mediabox = data.pageSize;
                        temp[i].mediabox.width = InTurnPx(data.pageSize.width);
                        temp[i].mediabox.height = InTurnPx(data.pageSize.height);
                        temp[i].trimbox.x = InTurnPx(data.pageBloodL);
                        temp[i].trimbox.y = InTurnPx(data.pageBloodT);
                        temp[i].editbox = {
                            height: InTurnPx(temp[i].editbox.height),
                            width: InTurnPx(temp[i].editbox.width),
                            x: InTurnPx(temp[i].editbox.x),
                            y: InTurnPx(temp[i].editbox.y)
                        };
                        temp[i].editboxes = {
                            editbox: data.editboxs
                        };
                        temp[i].editboxes.editbox.forEach(function (editbox) {
                            editbox.width = InTurnPx(editbox.width);
                            editbox.height = InTurnPx(editbox.height);
                            editbox.x = InTurnPx(editbox.x);
                            editbox.y = InTurnPx(editbox.y);
                        })
                    }
                    getBloodSize(temp[i], i);
                    resetBackground();
                    $scope.currentPage = temp[i];
                });
        }

        var createModal = function (url, controller, attr) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size:'lg',
                resolve: {
                    pages: {page: vm.page, sizeSet: attr, token: vm.token, domain: vm.url, template: $scope.template},
                    documentpics: {arr: $scope.template.documentpics.documentpic},
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            });
        };

        //撤销，重做按钮事件
        $scope.rollback = function (forward) {
            var i = angular.copy($scope.template.pageI);
            if($scope.pageHistory.length  == 0){
                return
            }
            if (forward) {
                $scope.currentPage = null;
                $scope.lastPage = null;
                $timeout(function () {
                    if(vm.templateCopy && vm.templateCopy.pages.page[i]) {
                        $scope.template.pages.page[i] = angular.copy(vm.templateCopy.pages.page[i]);
                    }else{
                        $scope.template.pages.page[i] = {
                            bgImg: '',
                            background: {resource: {}},
                            barcode: {},
                            qrcode: {},
                            scale: 1,
                            levels: {},
                            width: $scope.template.pages.page[i].width,
                            height: $scope.template.pages.page[i].height,
                            spread: $scope.template.pages.page[i].spread,
                            backup: $scope.template.pages.page[i].backup,
                            unreplace: $scope.template.pages.page[i].unreplace,
                            editbox: getGeometry($scope.template.pages.page[i].editbox),
                            editboxes: angular.copy($scope.template.pages.page[i].editboxes),
                            blood: angular.copy($scope.template.pages.page[i].blood),
                            bloodPx: angular.copy($scope.template.pages.page[i].bloodPx),
                            isScene: $scope.template.pages.page[i].isScene,
                            seq: $scope.template.pages.page[i].seq,
                            trimbox: $scope.template.pages.page[i].trimbox,
                            mediabox: $scope.template.pages.page[i].mediabox,
                            uuid: $scope.template.pages.page[i].uuid,
                            pagecover: $scope.template.pages.page[i].pagecover,
                            scene: $scope.template.pages.page[i].scene,
                            hasBgImg: false,
                            unit: 'mm'
                        };
                    }
                    $scope.RightPanel = 'page';
                    $scope.currentPage = $scope.template.pages.page[i];
                    $scope.currentPage.updateThumbnail = true;
                    $scope.lastPage = $scope.currentPage;
                    vm.unit = 'mm';
                    $scope.pageValue.mediabox = {
                        width: PxTurnMm($scope.currentPage.mediabox.width),
                        height: PxTurnMm($scope.currentPage.mediabox.height)
                    }
                    setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, i);
                    angular.forEach($scope.template.pages.page, function (item, index) {
                        if (!item.bgImg) {
                            $('#bgImg' + index).attr('src', '');            //添加src
                        }
                    });
                    $scope.clearCtrlKey();
                    var _template = angular.copy($scope.pageHistory[$scope.pageHistory.length-1]);
                    $scope.pageHistory = [vm.templateCopy,_template,_template];
                    $scope.changeShirnk(0);
                })
            } else {
                if($scope.pageHistory.length  < 2){
                    $scope.currentPage = null;
                    $scope.lastPage = null;
                    $timeout(function () {
                        $scope.template.pages.page[i] = angular.copy(vm.templateCopy.pages.page[i]);
                        $scope.currentPage = $scope.template.pages.page[i];
                        $scope.currentPage.updateThumbnail = true;
                        $scope.lastPage = $scope.currentPage;
                        $scope.pageHistory = [];
                        $scope.changeShirnk(0);
                    })
                    return
                }
                //$scope.template.pages.page[i] = null;
                $scope.currentPage = null;
                $scope.lastPage = null;
                $timeout(function () {
                    var _template = angular.copy($scope.pageHistory[$scope.pageHistory.length-2]);
                    $scope.template.pages.page[i] = _template.pages.page[i];
                    // $scope.template.pages.page[i] = angular.copy(vm.templateCopy.pages.page[i]);
                    $scope.pageHistory.splice($scope.pageHistory.length-1,1);
                    $scope.RightPanel = 'page';
                    $scope.currentPage = $scope.template.pages.page[i];
                    $scope.currentPage.updateThumbnail = true;
                    $scope.lastPage = $scope.currentPage;
                    vm.unit = 'mm';
                    $scope.pageValue.mediabox = {
                        width: PxTurnMm($scope.currentPage.mediabox.width),
                        height: PxTurnMm($scope.currentPage.mediabox.height)
                    }
                    setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, i);
                    angular.forEach($scope.template.pages.page, function (item, index) {
                        if (!item.bgImg) {
                            $('#bgImg' + index).attr('src', '');            //添加src
                        }
                    });
                    $scope.clearCtrlKey();
                    $scope.changeShirnk(0);
                })
            }
        };
        // 圆角设置
        vm.isRoundedSet = false;
        $scope.roundedSet = function (attr) {
            toolbarStatus();
            vm.isRoundedSet = attr;
        }

        // 边框设置
        vm.isBorderSet = false;
        $scope.borderSet = function (attr) {
            toolbarStatus();
            vm.isBorderSet = attr;
        }

        // 阴影设置
        vm.isShadowSet = false;
        $scope.shadowSet = function (attr) {
            toolbarStatus();
            vm.isShadowSet = attr;
        }
        // 透明度
        $scope.transparencySelect = function (attr) {
            if (!$scope.selectBox || !$scope.RightPanel || $scope.RightPanel == 'calMonthBox' || $scope.RightPanel == 'calTitleBox') {
                MessageService.error("请选择一个图片或者素材、文字");
                return
            }
            toolbarStatus();
            var current = $scope.selectBox.current;
            if (current == 'imageart' || current == 'textbox' || current == 'imagebox') {
                vm.isTransp = attr;

            }
            $scope.template.transparency = $scope.selectBox.transparency * 100;
            $(elem).val($scope.selectBox.transparency);
        }
        $scope.transparencyChange1 = function(){
            $timeout(function () {
                if($scope.template.transparency === '' || $scope.template.transparency === null || $scope.template.transparency<0){
                    $scope.template.transparency = 0
                }
                if($scope.template.transparency > 100){
                    $scope.template.transparency = 100
                }
                $scope.selectBox.transparency = $scope.template.transparency / 100;
            })
        }
        $scope.$watch("selectBox.transparency", function (newValue, oldValue) {
            if($scope.selectBox){
                $scope.template.transparency = Math.round($scope.selectBox.transparency * 100);
            }
        },true)

        $scope.coverageSelect = function (attr) {
            toolbarStatus();
            var current = $scope.selectBox.current;
            if (current != 'page') {
                vm.isCoverage = attr;
            }
        }

        $scope.opacityElement = function (mediabox, geometry) {
            var opacity = 1;
            if (!geometry || !mediabox) {
                return 1
            }
            if (geometry.x > -geometry.width && geometry.y > -geometry.height && geometry.x < mediabox.width && geometry.y < mediabox.height) {
                opacity = 1;
            } else {
                opacity = 0.3;
            }
            return opacity
        }

        /**
         * 图层
         * @param attr----up,down,top,bottom
         */
        $scope.coverageMove = function (attr) {
            var currentPage = angular.copy($scope.currentPage);
            var levels = currentPage.levels.level;
            if (levels.length == 1) {
                return
            }

            var levelI = $scope.template.levelI;
            // 上移一层
            if (attr == 'up') {
                if (levelI == levels.length || !levels[levelI + 1]) {
                    MessageService.error("已在最上层")
                    return
                }
                var seq = levels[levelI].seq;
                levels[levelI].seq = levels[levelI + 1].seq;
                levels[levelI + 1].seq = seq;
            }

            // 下移一层
            if (attr == 'down') {
                if (levelI == 0 || !levels[levelI - 1]) {
                    MessageService.error("已在最下层");
                    return
                }
                var seq = levels[levelI].seq;
                levels[levelI].seq = levels[levelI - 1].seq;
                levels[levelI - 1].seq = seq;
            }

            // 置顶
            if (attr == 'top') {
                if (levelI == levels.length - 1) {
                    MessageService.error("已在最上层")
                    return
                }
                var seq = levels[levelI].seq;
                levels[levelI].seq = levels[levels.length - 1].seq;

                for (var i = levelI + 1; i < levels.length; i++) {
                    var item = levels[i];
                    item.seq = i;
                }
            }

            // 置底
            if (attr == 'bottom') {
                if (levelI == 0) {
                    MessageService.error("已在最下层");
                    return
                }
                var seq = levels[levelI].seq;
                levels[levelI].seq = levels[0].seq;

                for (var i = 0; i < levelI; i++) {
                    var item = levels[i];
                    item.seq += 1;
                }
            }

            $scope.template.levelI = levels[levelI].seq - 1;
            levels.sort(compare('seq', true));
            $scope.currentPage = {};
            $scope.template.pages.page[$scope.template.pageI] = {};
            $timeout(function () {
                $scope.currentPage = currentPage;
                $scope.template.pages.page[$scope.template.pageI] = currentPage;
                vm.CtrlKeyArray.imageboxes = [];
                vm.CtrlKeyArray.textboxes = [];
                vm.CtrlKeyArray.imagearts = [];
                vm.CtrlKeyArray.barcode = [];
                vm.CtrlKeyArray.qrcode = [];
                vm.CtrlKeyArray.calTitleBoxs = [];
                vm.CtrlKeyArray.calMonthBoxs = [];
            })
        }

        // 锁定
        $scope.locking = function () {
            if (!$scope.selectBox || !$scope.RightPanel || !$scope.currentPage.levels.level[$scope.template.levelI]) {
                MessageService.error("请先选择一个元素");
                return
            }
            // 当前页所有占位框
            var currentImages = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;
            // 当前页所有文本
            var currentTexts = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
            // 当前页所有素材
            var currentArts = $scope.currentPage.levels.level[$scope.template.levelI].imageart;
            var all = getAllCtrlKeyArray(1);

            var len = all.length;
            // 单选状态
            if (!len) {
                $scope.selectBox.lock = !$scope.selectBox.lock;
            } else {
                // ctrl+点击
                for (var i = 0; i < len; i++) {
                    if (all[i].current == 'imagebox') {
                        currentImages[all[i].index].lock = !currentImages[all[i].index].lock;
                    } else if (all[i].current == 'textbox') {
                        currentTexts[all[i].index].lock = !currentTexts[all[i].index].lock;
                    } else {
                        currentArts[all[i].index].lock = !currentArts[all[i].index].lock;
                    }
                }
            }
            updateHistorys();
        }

        // 删除
        $scope.deleteBox = function () {
            var all = getAllCtrlKeyArray(1);
            var len = all.length;
            if ($scope.selectBox.current == 'barcode') {
                if ($scope.currentPage.barcode.lock) {
                    return
                }
                $scope.currentPage.barcode = {};
                $scope.RightPanel="";
                return
            }
            if ($scope.selectBox.current == 'qrcode') {
                if ($scope.currentPage.qrcode.lock) {
                    return
                }
                $scope.currentPage.qrcode = {};
                $scope.RightPanel="";
                return
            }
            var levels = angular.copy($scope.currentPage.levels);
            if (!levels || !levels.level || !levels.level[$scope.template.levelI]) {
                return;
            }
            var level = levels.level[$scope.template.levelI];
            // 单选状态
            if (!len) {
                if ($scope.selectBox == undefined || $scope.selectBox.current == 'page') {
                    $scope.deleteBgImg();
                    return
                }
                if ($scope.selectBox.current == 'imagebox') {
                    if (level.imagebox.lock) {
                        return
                    }
                }
                if ($scope.selectBox.current == 'textbox') {
                    if (level.textbox.lock) {
                        return
                    }
                }
                if ($scope.selectBox.current == 'imageart') {
                    if (level.imageart.lock) {
                        return
                    }
                }
                if ($scope.selectBox.current == 'textdocument') {
                    if (level.textdocument.lock) {
                        return
                    }
                }
                if ($scope.selectBox.current == 'calMonthBox') {
                    if (level.calMonthBox.lock) {
                        return
                    }
                }
                if ($scope.selectBox.current == 'calTitleBox') {
                    if (level.calTitleBox.lock) {
                        return
                    }
                }
                levels.level.splice($scope.template.levelI, 1);

            } else {
                // ctrl+点击
                for (var i = len - 1; i >= 0; i--) {
                    if (!all[i]) {
                        continue;
                    }
                    if (all[i].current == 'barcode') {
                        $scope.currentPage.barcode = {};

                    } else if (all[i].current == 'qrcode') {
                        $scope.currentPage.qrcode = {};

                    } else {
                        if(!all[i].lock){
                            levels.level.splice(all[i].levelI, 1);
                        }
                    }
                }
                $scope.clearCtrlKey();
            }

            for (var x = 0; x < levels.level.length; x++) {
                var l = levels.level[x];
                if (l.imagebox && l.imagebox.current) {
                    l.imagebox.levelI = x;
                }
                if (l.imageart && l.imageart.current) {
                    l.imageart.levelI = x;
                }
                if (l.textbox && l.textbox.current) {
                    l.textbox.levelI = x;
                }
                if (l.textdocument && l.textdocument.current) {
                    l.textdocument.levelI = x;
                }
                if (l.calTitleBox && l.calTitleBox.current) {
                    l.calTitleBox.levelI = x;
                }
                if (l.calMonthBox && l.calMonthBox.current) {
                    l.calMonthBox.levelI = x;
                }
                l.seq = x + 1;
            }

            // $scope.currentPage.levels = [];
            // $timeout(function () {
                $scope.currentPage.levels = levels;
                $scope.RightPanel = 'page';
                $scope.selectBox = {};
                getPagePx();
                getImageboxLen();
                updateHistorys();
            // })
        };

        $scope.codeDelete = function (type) {
            if (type == 'barcode') {
                if ($scope.currentPage.barcode.lock) {
                    return
                }
                $scope.currentPage.barcode = {};
                return
            }
            if (type == 'qrcode') {
                if ($scope.currentPage.qrcode.lock) {
                    return
                }
                $scope.currentPage.qrcode = {};
                return
            }
        }
        vm.watchSure = false;
        $scope.watchArticle = function () {
            if (vm.page.modelType && vm.page.modelType === 'THREE') {
                setThumbnailPic1(function () {
                    vm.threeTemplate = $scope.template;
                    vm.threeProductXmlData = {
                        modelBlendent: vm.page.modelBlendent,
                        modelData: vm.page.modelData,
                        modelPath: vm.page.modelPath,
                    };
                    vm.threeView = true;
                })
            } else {
                var template = angular.copy($scope.template);
                var arr = getTemplate(template);
                vm.previewArticle = arr.template;
                vm.watchSure = true;
            }
        }

        function checkPageFonts(pages) {
            if (!pages || pages.length == 0) {
                return true;
            }
            for (var i = 0; i < pages.length; i++) {
                var page = pages[i];
                if (page.levels && page.levels.level && page.levels.level.length > 0) {
                    for (var j = 0; j < page.levels.level.length; j++) {
                        var level = page.levels.level[j];
                        if (level && level.textbox) {
                            var textbox = level.textbox;
                            if (textbox.characterWarning) {
                                if (!page.backup) {
                                    MessageService.error("检测到页面文字中包含字体不支持的字符！");
                                } else {
                                    MessageService.error("检测到备用页面文字中包含字体不支持的字符！");
                                }
                                return false;
                            }
                        }
                    }
                }
            }
            return true;
        }

        function clearPageStorageInfo() {
            if (vm.pageStorageName && vm.pageStoragePredefined) {
                localStorage.removeItem(vm.pageStorageName);
            }
        }

        /*!
        *    attr - 场景值
        *       0 - 发布模版
        *       1 - 保存模版
        *       2 - 发布产品
        *       3 - 即时保存
        */
        vm.documentDetection = {
            pass: true
        };
        vm.documentDetectionTab="text";
        vm.saveDate = "";
        /**
         * 显示原创协议弹窗
         * @param {*} callback
         */
        function showOriginalAgreement(callback,attr){
            var userInfo = $localStorage.user;
            if(userInfo.original){
                return callback(attr);
            }
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/original-agreement.html',
                controller: 'OriginalAgreementController',
                controllerAs: 'vm',
                backdrop: "static",
                size: 'lg',
                resolve: {

                }
            }).result.then(function (state) {
                if(state){
                    callback(attr);
                }

            })
        }
        var isCalendar = false;
        vm.issetMonth = false;
        $scope.savePage = function(attr){
            if(vm.textEditor){
                return;
            }
            //发布模板,先校验原创协议
            if(attr === 0 || attr === 2){
                if($scope.template.documentpics.documentpic.length === 0){
                    MessageService.error("请添加样例图！");
                    return
                }
                //判断动态日历是否有月序
                if($scope.template.config.dynamic){
                    vm.serialNumberPages=[];
                    vm.issetMonth = false;
                    $scope.template.pages.page.forEach(function (page) {
                        if(!$scope.isSetMonth(page)){
                            vm.serialNumberPages.push(page)
                        }
                    })
                    if(vm.serialNumberPages.length>0){
                        vm.issetMonth = true;
                        return
                    }
                }
                //判断动态日历月序是否连续
                if($scope.template.config.dynamic && !isCalendar){
                    var numbers = [];
                    $scope.template.pages.page.forEach(function (page) {
                        if(page.config.monthIndex>0){
                            numbers.push(page.config.monthIndex)
                        }
                    })
                    var result = findMissingNumbers(numbers);
                    if (result.length>0){
                        $uibModal.open({
                            templateUrl: 'app/entities/page/modal/calendar-modal.html',
                            controller: 'CalendarModalController',
                            backdrop: 'static',
                            keyboard: true,
                            windowClass: "jh-modal-md",
                            controllerAs: 'vm',
                            resolve: {
                                entity: [function () {
                                    return {
                                        numbers: numbers,
                                        templateConfig: $scope.template.config
                                    };
                                }]
                            }
                        }).result.then(function (data) {
                            if (data) {
                                isCalendar = true;
                                $scope.savePage(attr)
                            }
                        });
                        return
                    }
                }

            }
            if(attr === 0){
                showOriginalAgreement($scope.handleSavePage,attr);
            }else if(attr === 2){
                //先检查作品是否完成
                var template = angular.copy($scope.template);
                var c = 0;

                vm.documentDetection = documentTextDetection($scope.template);
                if(!vm.documentDetection.pass){
                    if(vm.documentDetection.isText){
                        vm.documentDetectionTab="text";
                    }else{
                        vm.documentDetectionTab="repetition";
                    }
                    return;
                }
                angular.forEach(template.pages.page, function (item, i) {
                    if (!item.backup) {
                        c++
                    }
                })

                if (vm.page.id && !vm.hasModel && vm.productTemplatePageCount < template.pages.page.length) {
                    if (c != vm.productTemplatePageCount) {
                        MessageService.error("模板非备用数量页与标准模板不符！");
                        return
                    }
                }

                if (vm.page.addpage && (vm.page.minpage > c || vm.page.maxpage < c)) {
                    MessageService.error("页数范围在" + vm.page.minpage + " ~ " + vm.page.maxpage + "之间");
                    return
                }

                // 当attr==2时， 将会检查相框内是否填充图片
                var arr = getTemplate(template, attr);
                template = arr.template;
                if (!arr.isSave) {
                    return MessageService.error("请先制作完作品！");

                }else{
                    showOriginalAgreement($scope.handleSavePage,attr);
                }
            }else{
                $scope.handleSavePage(attr);
            }
        }
        $scope.handleSavePage = function (attr) {
            var template = angular.copy($scope.template);
            var c = 0;

            vm.documentDetection = documentTextDetection($scope.template);
            if(!vm.documentDetection.pass){
                if(vm.documentDetection.isText){
                    vm.documentDetectionTab="text";
                }else{
                    vm.documentDetectionTab="repetition";
                }
                return;
            }
            angular.forEach(template.pages.page, function (item, i) {
                if (!item.backup) {
                    c++
                }
            })

            if (vm.page.id && !vm.hasModel && vm.productTemplatePageCount < template.pages.page.length) {
                if (c != vm.productTemplatePageCount) {
                    MessageService.error("模板非备用数量页与标准模板不符！");
                    return
                }
            }

            if (vm.page.addpage && (vm.page.minpage > c || vm.page.maxpage < c)) {
                MessageService.error("页数范围在" + vm.page.minpage + " ~ " + vm.page.maxpage + "之间");
                return
            }

            vm.isSaving = true;
            $scope.isHasGeometry = true;

            // 当attr==2时， 将会检查相框内是否填充图片
            var arr = getTemplate(template, attr);
            template = arr.template;
            var tipArr = arr.tipArr;
            if (!arr.isSave) {
                MessageService.error("发布产品失败，请先制作完作品！");
                vm.isSaving = false;
                return
            }
            if (tipArr.length) {
                var str = tipArr.join(',')
                MessageService.error("第" + str + "页需要设置为不可替换！");
                vm.isSaving = false;
                return
            }
            var _variableRepeat = isRepeat(arr.variableData)
            if (_variableRepeat) {
                MessageService.error("可变数据不可重复");
                vm.isSaving = false;
                return
            }
            // return
            // Qinius.get(function (msg) {
            var i = $scope.template.pageI;
            var nodes = document.getElementsByClassName('page-item2');
            // vm.token = msg.token;
            $scope.lastPage = $scope.template.pages.page[$scope.template.pageI];
            if (!$scope.isHasGeometry) {
                MessageService.error("请输入正确的占位框宽高！");
                vm.isSaving = false;
                return
            }
            $timeout(function () {
                var width = $scope.lastPage.mediabox.width;
                var height = $scope.lastPage.mediabox.height;
                if($scope.lastPage.scene){
                    $scope.lastPage.isScene = true;
                }
                if ($scope.lastPage.isScene) {
                    width = $scope.lastPage.scene.geometry.width;
                    height = $scope.lastPage.scene.geometry.height;
                }
                var _ratio = $scope.lastPage.ratio || 1;
                getDomOnload($scope,"pageThumbnail",function () {
                    html2canvas_adapt(nodes[0], {
                        onrendered: function (canvas) {
                            var url = canvas.toDataURL("image/png");
                            var blob = dataURLtoBlob(url);

                            var formData = new FormData();
                            formData.append('file', blob);
                            formData.append('key', 'clipArt-' + UidService.get());
                            formData.append('token', vm.token);
                            $.ajax({
                                cache: true,
                                type: "POST",
                                url: 'https://upload.qiniup.com/',
                                data: formData,
                                async: false,
                                contentType: false,
                                processData: false,
                                success: function (data) {
                                    template.pages.page[i].updateThumbnail=false;
                                    template.pages.page[i].pagecover = {
                                        identifier: vm.url + data.key,
                                        provider: 'qiniu'
                                    }
                                    $scope.template.pages.page[i].updateThumbnail=false;
                                    $scope.template.pages.page[i].pagecover = {
                                        identifier: vm.url + data.key,
                                        provider: 'qiniu'
                                    }
                                    for(var j=0; j<template.pages.page.length; j++){
                                        if(template.pages.page[j].updateThumbnail){
                                            vm.isSaving = false;
                                            MessageService.confirm({
                                                title:'提示',
                                                cancel:'取消',
                                                confirm:'去更新',
                                                msg:'当前模板存在需要更新的缩略图，是否现在更新'
                                            },function(){
                                                $scope.showAllThumbnailPic();
                                            });
                                            return;
                                        }
                                    }
                                    if (!$scope.isHasGeometry) {
                                        MessageService.error("请输入正确的占位框宽高！");
                                        vm.isSaving = false;
                                        return
                                    }
                                    if(vm.thirdparty){
                                        ProductXml.thirdXmlSave({
                                            document: template,
                                            key: $stateParams.key,
                                            xmlId: $stateParams.xmlId,
                                            version: '4.0'
                                        }, function () {
                                            vm.isSaving = false;
                                            MessageService.success("保存成功！");
                                            window.history.back()
                                        }, function () {
                                            vm.isSaving = false;
                                            MessageService.error("保存失败！");
                                        });
                                        return;
                                    }
                                    if (attr == 1 || attr == 3) {
                                        if (($stateParams.id && !$stateParams.copy) || vm.currentAid) {
                                            ProductXml.update({
                                                document: template,
                                                bindingType: vm.page.bindingType,
                                                pattern: vm.page.pattern,
                                                replace: vm.page.replace,
                                                name: vm.page.name,
                                                id: $stateParams.id || vm.currentAid,
                                                provider: vm.page.provider,
                                                catalogId: vm.page.catalog.id,
                                                showPic: true,
                                                version: '4.0',
                                                catalogName: vm.page.catalog.name,
                                                type: 'XML',
                                                productTemplateId: vm.page.id ? vm.page.id : '',
                                                hashtags: vm.hashtags,
                                                modelBlendent: vm.page.modelBlendent,
                                                modelData: vm.page.modelData,
                                                modelPath: vm.page.modelPath,
                                                modelType: vm.page.modelType,
                                                productType: vm.page.productType
                                            }, function () {
                                                var now = new Date();
                                                var hours = now.getHours();
                                                var minutes = now.getMinutes();
                                                vm.saveDate = hours + ":" + minutes + '已保存';
                                                vm.operation = false;
                                                vm.isSaving = false;

                                                if (attr == 1) {
                                                    MessageService.success("保存成功！");
                                                    if (!vm.isCustom) {
                                                        clearPageStorageInfo();
                                                        $state.go('selfTemplateList', {reload: true})
                                                    } else {
                                                        clearPageStorageInfo();
                                                        $state.go('selfTemplateList', {self: 'self'}, {reload: true})
                                                    }
                                                } else if (attr == 3) {
                                                }
                                            }, error);
                                        } else {
                                            ProductXml.save({
                                                document: template,
                                                bindingType: vm.page.bindingType,
                                                pattern: vm.page.pattern,
                                                replace: vm.page.replace,
                                                name: vm.page.name,
                                                catalogId: vm.page.catalog.id,
                                                catalogName: vm.page.catalog.name,
                                                showPic: true,
                                                version: '4.0',
                                                type: 'XML',
                                                productTemplateId: vm.page.id ? vm.page.id : '',
                                                hashtags: vm.hashtags,
                                                modelBlendent: vm.page.modelBlendent,
                                                modelData: vm.page.modelData,
                                                modelPath: vm.page.modelPath,
                                                modelType: vm.page.modelType
                                            }, function (resp) {
                                                vm.isSaving = false;
                                                MessageService.success("保存成功！");
                                                if (attr == 1) {
                                                    if (!vm.isCustom) {
                                                        clearPageStorageInfo();
                                                        $state.go('templateList', {reload: true})
                                                    } else {
                                                        clearPageStorageInfo();
                                                        $state.go('selfTemplateList', {self: 'self'}, {reload: true})
                                                    }
                                                } else if (attr == 3) {
                                                    vm.currentAid = resp.id
                                                }
                                            }, error);
                                        }
                                    }
                                    if (attr == 0) {
                                        vm.isSaving = false;
                                        $uibModal.open({
                                            templateUrl: 'app/entities/page/modal/document-detection.html',
                                            controller: 'DocumentDetectionController',
                                            controllerAs: 'vm',
                                            size: 'lg',
                                            resolve: {
                                                entity: [function () {
                                                    return {
                                                        document: template,
                                                        bindingType: vm.page.bindingType,
                                                        pattern: vm.page.pattern,
                                                        replace: vm.page.replace,
                                                        name: vm.page.name,
                                                        id: $stateParams.id,
                                                        provider: vm.page.provider,
                                                        catalogId: vm.page.catalog.id,
                                                        catalogName: vm.page.catalog.name,
                                                        showPic: true,
                                                        version: '4.0',
                                                        type: 'XML',
                                                        productTemplateId: vm.page.id ? vm.page.id : '',
                                                        hashtags: vm.hashtags,
                                                        modelBlendent: vm.page.modelBlendent,
                                                        modelData: vm.page.modelData,
                                                        modelPath: vm.page.modelPath,
                                                        modelType: vm.page.modelType,
                                                        storeIds: [],
                                                        source: 'editor',
                                                        productType: vm.page.productType
                                                    };
                                                }]
                                            }
                                        }).result.then(function (isReturn) {
                                            if (isReturn) {
                                                clearPageStorageInfo();
                                                MessageService.success("发布成功！");
                                                $state.go('templateList', {reload: true})
                                            }
                                            vm.isSaving = false;
                                        });
                                    }
                                    if (attr == 2) {
                                        // if (!checkPageFonts(template.pages.page)) {
                                        //     vm.isSaving = false;
                                        //     return;
                                        // }
                                        vm.isSaving = false;
                                        $uibModal.open({
                                            templateUrl: 'app/entities/page/modal/document-detection.html',
                                            controller: 'DocumentDetectionController',
                                            controllerAs: 'vm',
                                            size: 'lg',
                                            resolve: {
                                                entity: [function () {
                                                    return {
                                                        document: template,
                                                        bindingType: vm.page.bindingType,
                                                        pattern: vm.page.pattern,
                                                        replace: vm.page.replace,
                                                        name: vm.page.name,
                                                        id: $stateParams.id,
                                                        provider: vm.page.provider,
                                                        catalogId: vm.page.catalog.id,
                                                        catalogName: vm.page.catalog.name,
                                                        showPic: true,
                                                        version: '4.0',
                                                        type: 'PRODUCT',
                                                        productTemplateId: vm.page.id ? vm.page.id : '',
                                                        hashtags: vm.hashtags,
                                                        modelBlendent: vm.page.modelBlendent,
                                                        modelData: vm.page.modelData,
                                                        modelPath: vm.page.modelPath,
                                                        modelType: vm.page.modelType,
                                                        storeIds: [],
                                                        source: 'editor',
                                                        productType: vm.page.productType
                                                    };
                                                }]
                                            }
                                        }).result.then(function (isReturn) {
                                            if (isReturn) {
                                                clearPageStorageInfo();
                                                MessageService.success("发布成功！");
                                                $state.go('templateList', {reload: true})

                                            }
                                            vm.isSaving = false;
                                        });
                                    }
                                    $scope.$digest();
                                },
                                error: function (request) {
                                    MessageService.error("文件上传失败");
                                    vm.isSaving = false;
                                }
                            });
                        },
                        letterRendering: true,
                        useCORS: true,
                        width: width * _ratio,
                        height: height * _ratio,
                        windowWidth: document.body.scrollWidth,
                        windowHeight: document.body.scrollHeight,
                        x: $scope.lastPage.isScene ? (-($scope.lastPage.scene.geometry.width - $scope.lastPage.scene.geometry.width * _ratio) / 2) : -($scope.lastPage.mediabox.width - $scope.lastPage.mediabox.width * _ratio) / 2,
                        y: $scope.lastPage.isScene ? (-($scope.lastPage.scene.geometry.height - $scope.lastPage.scene.geometry.height * _ratio) / 2) : -($scope.lastPage.mediabox.height - $scope.lastPage.mediabox.height * _ratio) / 2,
                        ratio: _ratio || 1,
                        backgroundColor: "rgba(0, 0, 0, 0)"
                    });
                })
            },200)
            // });
        }
        $scope.exitSureCb = function(){
            if(!vm.operation){
                $scope.exitPageHide();
            }else{
                vm.exitSure = true;
                MessageService.confirm({
                    title:'温馨提示',
                    goback:'取消',
                    cancel:'不保存',
                    confirm:'保存',
                    msg:'退出前是否需要保存已变更的内容?'
                },function(){
                    $scope.exitPage();
                },function (flag) {
                    if (flag) {
                        $scope.exitPageHide();
                    }
                })
            }
        }
        function isRepeat(arr) {
            var hash = {};
            for (var i in arr) {
                if (hash[arr[i]]) {
                    return true;
                }
                hash[arr[i]] = true;
            }
            return false;
        }

        function getTemplate(template, attr) {
            var isSave = true;
            template.bindingtype = vm.page.bindingType;
            template.pattern = vm.page.pattern;
            template.cover = angular.copy(vm.page.cover);
            template.replace = vm.page.replace;
            template.version = "4.0";
            template.addpage = vm.page.addpage;
            template.minpage = vm.page.minpage;
            template.maxpage = vm.page.maxpage;
            template.addunit = vm.page.addunit;
            template.xLines={line:vm.sublinesX};
            template.yLines={line:vm.sublinesY};
            var tipArr = [], variableData = [];
            angular.forEach(template.pages.page, function (item, i) {
                item.centerrotation = true;
                if (item.background) {
                    item.background.offsetx = PxTurnMm(item.background.offsetx);
                    item.background.offsety = PxTurnMm(item.background.offsety);
                }
                item.mediabox = {
                    width: PxTurnMm(item.mediabox.width),
                    height: PxTurnMm(item.mediabox.height),
                    x: 0,
                    y: 0
                };
                item.trimbox = {
                    width: PxTurnMm(item.trimbox.width),
                    height: PxTurnMm(item.trimbox.height),
                    x: PxTurnMm(item.trimbox.x),
                    y: PxTurnMm(item.trimbox.y)
                };

                item.editbox = {
                    width: PxTurnMm(item.editbox.width),
                    height: PxTurnMm(item.editbox.height),
                    x: PxTurnMm(item.editbox.x),
                    y: PxTurnMm(item.editbox.y)
                };
                if (item.editboxes && item.editboxes.editbox) {
                    item.editboxes.editbox.forEach(function (editbox) {
                        editbox.width = PxTurnMm(editbox.width);
                        editbox.height = PxTurnMm(editbox.height);
                        editbox.x = PxTurnMm(editbox.x);
                        editbox.y = PxTurnMm(editbox.y);
                    })
                }
                if (item.scene) {
                    item.scene.resource = {
                        provider: 'qiniu',
                        identifier: item.scene.background.resource.identifier
                    }
                    item.scene.geometry = {
                        width: PxTurnMm(item.scene.geometry.width),
                        height: PxTurnMm(item.scene.geometry.height),
                        x: PxTurnMm(item.scene.geometry.x),
                        y: PxTurnMm(item.scene.geometry.y)
                    };
                    if (item.scene.imagebox) {
                        item.scene.imagebox.geometry = {
                            width: PxTurnMm(item.scene.imagebox.geometry.width),
                            height: PxTurnMm(item.scene.imagebox.geometry.height),
                            x: PxTurnMm(item.scene.imagebox.geometry.x),
                            y: PxTurnMm(item.scene.imagebox.geometry.y)
                        };
                        item.scene.imagebox.rotation = {
                            angle: item.scene.imagebox.rotation.angle
                        };
                    }
                    item.scene.hierarchy = item.scene.hierarchy;
                }

                if (item.barcode && item.barcode.geometry
                    && item.barcode.geometry.width > 0.01 && item.barcode.geometry.height > 0.01) {
                    item.barcode = {
                        geometry: {
                            width: PxTurnMm(item.barcode.geometry.width),
                            height: PxTurnMm(item.barcode.geometry.height),
                            x: PxTurnMm(item.barcode.geometry.x),
                            y: PxTurnMm(item.barcode.geometry.y)
                        },
                        rotation: angular.copy(item.barcode.rotation),
                    };
                    if (!item.unreplace) {
                        tipArr.push(i + 1);
                    }
                } else {
                    item.barcode = null;
                }
                if (item.qrcode && item.qrcode.geometry
                    && item.qrcode.geometry.width > 0.01 && item.qrcode.geometry.height > 0.01) {
                    item.qrcode = {
                        geometry: {
                            width: PxTurnMm(item.qrcode.geometry.width),
                            height: PxTurnMm(item.qrcode.geometry.height),
                            x: PxTurnMm(item.qrcode.geometry.x),
                            y: PxTurnMm(item.qrcode.geometry.y)
                        },
                        rotation: angular.copy(item.qrcode.rotation),
                    };
                    if (!item.unreplace) {
                        tipArr.push(i + 1);
                    }
                } else {
                    item.qrcode = null;
                }

                angular.forEach(item.levels.level, function (level, l) {
                    if (level.variable) {
                        variableData.push(level.name)
                    }
                    if (level.imageart && level.imageart.current) {
                        var art = level.imageart;
                        art.geometry = {
                            width: PxTurnMm(art.geometry.width),
                            height: PxTurnMm(art.geometry.height),
                            x: PxTurnMm(art.geometry.x),
                            y: PxTurnMm(art.geometry.y)
                        };
                        art.offsetx = PxTurnMm(art.offsetx);
                        art.offsety = PxTurnMm(art.offsety);
                        art.rotation.angle = -art.rotation.angle;
                    }
                    if (level.imagebox && level.imagebox.current) {
                        var img = level.imagebox;
                        if (img.geometry.width <= 1 || img.geometry.height <= 1) {
                            $scope.isHasGeometry = false;
                        }
                        ;
                        img.geometry = {
                            width: PxTurnMm(img.geometry.width),
                            height: PxTurnMm(img.geometry.height),
                            x: PxTurnMm(img.geometry.x),
                            y: PxTurnMm(img.geometry.y)
                        };

                        if (img.image) {
                            img.image = {
                                offsetx: PxTurnMm(img.image.offsetx),
                                offsety: PxTurnMm(img.image.offsety),
                                scale: img.image.scale,
                                angle: img.image.angle,
                                resource: {
                                    identifier: img.image.resource ? img.image.resource.identifier : '',
                                    provider: img.image.resource ? img.image.resource.provider : ''
                                },
                                resourceid: img.image.resourceid
                            }
                        }
                        if (attr == 2) {
                            if (!img.image || img.image.resource.identifier == "")
                                isSave = false;
                        }
                    }
                    if (level.textbox && level.textbox.current) {
                        var text = level.textbox;
                        if (text.text == '') {
                            level.textbox = null;
                        } else {
                            text.geometry = {
                                width: PxTurnMm(text.geometry.width),
                                height: PxTurnMm(text.geometry.height),
                                x: PxTurnMm(text.geometry.x),
                                y: PxTurnMm(text.geometry.y)
                            }
                            text.rotation.angle = -text.rotation.angle;
                        }
                    }
                    if (level.textdocument && level.textdocument.current) {
                        var textdocument = level.textdocument;
                        textdocument.background.offsetx = PxTurnMm(textdocument.background.offsetx);
                        textdocument.background.offsety = PxTurnMm(textdocument.background.offsety);
                        textdocument.geometry = {
                            width: PxTurnMm(textdocument.geometry.width),
                            height: PxTurnMm(textdocument.geometry.height),
                            x: PxTurnMm(textdocument.geometry.x),
                            y: PxTurnMm(textdocument.geometry.y)
                        }
                        angular.forEach(textdocument.levels.level, function (l) {
                            if (l.imageart) {
                                var imageart = l.imageart;
                                imageart.geometry = {
                                    width: PxTurnMm(imageart.geometry.width),
                                    height: PxTurnMm(imageart.geometry.height),
                                    x: PxTurnMm(imageart.geometry.x),
                                    y: PxTurnMm(imageart.geometry.y)
                                };
                                imageart.offsetx = PxTurnMm(imageart.offsetx);
                                imageart.offsety = PxTurnMm(imageart.offsety);
                                imageart.rotation.angle = -imageart.rotation.angle;
                            }
                            if (l.textbox) {
                                if (l.textbox.text == '') {
                                    l.textbox = null;
                                } else {
                                    l.textbox.geometry = {
                                        width: PxTurnMm(l.textbox.geometry.width),
                                        height: PxTurnMm(l.textbox.geometry.height),
                                        x: PxTurnMm(l.textbox.geometry.x),
                                        y: PxTurnMm(l.textbox.geometry.y)
                                    }
                                    l.textbox.rotation.angle = -l.textbox.rotation.angle;
                                }
                            }
                        })
                    }
                    if (level.calTitleBox && level.calTitleBox.current) {
                        var _calTitleBox = angular.copy(level.calTitleBox);
                        level.calTitleBox.geometry = {
                            width: PxTurnMm(level.calTitleBox.geometry.width),
                            height: PxTurnMm(level.calTitleBox.geometry.height),
                            x: PxTurnMm(level.calTitleBox.geometry.x),
                            y: PxTurnMm(level.calTitleBox.geometry.y)
                        };
                        level.calTitleBox.rotation.angle = -level.calTitleBox.rotation.angle;
                        level.calTitleBox.settings={
                            border:level.calTitleBox.settings.border,
                            items:{
                                item:angular.copy(_calTitleBox.settings.items.item?_calTitleBox.settings.items.item:_calTitleBox.settings.items)
                            }
                        };
                        level.calTitleBox.layout={
                            geometry: {
                                width:level.calTitleBox.layout.width>=0?level.calTitleBox.layout.width:level.calTitleBox.layout.geometry.width,
                                height:level.calTitleBox.layout.height>=0?level.calTitleBox.layout.height:level.calTitleBox.layout.geometry.height,
                                x:0,
                                y:0,
                            },
                            previewUrl:level.calTitleBox.layout.previewUrl,
                            type:level.calTitleBox.layout.type,
                            items:{
                                item:angular.copy(_calTitleBox.layout.items.item?_calTitleBox.layout.items.item:_calTitleBox.layout.items),
                            }
                        };
                        level.calTitleBox.layout.items.item.forEach(function (item) {
                            item.geometry = {
                                width:item.w>=0?item.w:item.geometry.width,
                                height:item.h>=0?item.h:item.geometry.height,
                                x:item.x>=0?item.x:item.geometry.x,
                                y:item.y>=0?item.y:item.geometry.y,
                            }
                        })
                    }
                    if (level.calMonthBox && level.calMonthBox.current) {
                        var _calMonthBox = angular.copy(level.calMonthBox);
                        level.calMonthBox.geometry = {
                            width: PxTurnMm(level.calMonthBox.geometry.width),
                            height: PxTurnMm(level.calMonthBox.geometry.height),
                            x: PxTurnMm(level.calMonthBox.geometry.x),
                            y: PxTurnMm(level.calMonthBox.geometry.y)
                        };
                        level.calMonthBox.rotation.angle = -level.calMonthBox.rotation.angle;

                        level.calMonthBox.weekStyles={item:angular.copy(_calMonthBox.weekStyles.item?_calMonthBox.weekStyles.item:_calMonthBox.weekStyles)};
                        level.calMonthBox.weekLayout={
                            geometry: {
                                width:level.calMonthBox.weekLayout.width>=0?level.calMonthBox.weekLayout.width:level.calMonthBox.weekLayout.geometry.width,
                                height:level.calMonthBox.weekLayout.height>=0?level.calMonthBox.weekLayout.height:level.calMonthBox.weekLayout.geometry.height,
                                x:0,
                                y:0,
                            },
                            items:{
                                item:angular.copy(_calMonthBox.weekLayout.items.item?_calMonthBox.weekLayout.items.item:_calMonthBox.weekLayout.items)
                            },
                            type:level.calMonthBox.weekLayout.type,
                            previewUrl:level.calMonthBox.weekLayout.previewUrl,
                        };
                        level.calMonthBox.weekLayout.items.item.forEach(function (weekLayout) {
                            weekLayout.geometry = {
                                width:weekLayout.w>=0?weekLayout.w:weekLayout.geometry.width,
                                height:weekLayout.h>=0?weekLayout.h:weekLayout.geometry.height,
                                x:weekLayout.x>=0?weekLayout.x:weekLayout.geometry.x,
                                y:weekLayout.y>=0?weekLayout.y:weekLayout.geometry.y,
                            }
                        })
                        level.calMonthBox.dateStyles={item:angular.copy(_calMonthBox.dateStyles.item?_calMonthBox.dateStyles.item:_calMonthBox.dateStyles)},
                        level.calMonthBox.dateLayout={
                            geometry: {
                                width:level.calMonthBox.dateLayout.width>=0?level.calMonthBox.dateLayout.width:level.calMonthBox.dateLayout.geometry.width,
                                height:level.calMonthBox.dateLayout.height>=0?level.calMonthBox.dateLayout.height:level.calMonthBox.dateLayout.geometry.height,
                                x:0,
                                y:0,
                            },
                            items:{
                                item:angular.copy(_calMonthBox.dateLayout.items.item?_calMonthBox.dateLayout.items.item:_calMonthBox.dateLayout.items)
                            },
                            type:level.calMonthBox.dateLayout.type,
                            previewUrl:level.calMonthBox.dateLayout.previewUrl
                        };
                        level.calMonthBox.dateLayout.items.item.forEach(function (dateLayout) {
                            dateLayout.geometry = {
                                width:dateLayout.w>=0?dateLayout.w:dateLayout.geometry.width,
                                height:dateLayout.h>=0?dateLayout.h:dateLayout.geometry.height,
                                x:dateLayout.x>=0?dateLayout.x:dateLayout.geometry.x,
                                y:dateLayout.y>=0?dateLayout.y:dateLayout.geometry.y,
                            }
                        })
                        level.calMonthBox.weekStyles.item.forEach(function (weekStyle) {
                            var _items = angular.copy(weekStyle.items.item?weekStyle.items.item:weekStyle.items);
                            _items.forEach(function (_item){
                                _item.geometry = {
                                    width:_item.w,
                                    height:_item.h,
                                    x:_item.x,
                                    y:_item.y,
                                }
                            })
                            weekStyle.items = {
                                item:_items
                            }
                        })
                        level.calMonthBox.dateStyles.item.forEach(function (dateStyle) {
                            var _items = angular.copy(dateStyle.items.item?dateStyle.items.item:dateStyle.items);
                            _items.forEach(function (_item){
                                _item.geometry = {
                                    width:_item.w,
                                    height:_item.h,
                                    x:_item.x,
                                    y:_item.y,
                                }
                            })
                            dateStyle.items = {
                                item:_items
                            }
                        })
                    }
                })
            });

            if ($scope.template.deletedPages && $scope.template.deletedPages.page && $scope.template.deletedPages.page.length) {
                pageToMM(template.deletedPages.page, $scope.template.deletedPages);
            }
            if ($scope.template.backupPages && $scope.template.backupPages.list && $scope.template.backupPages.list.length) {
                pageToMM(template.backupPages.list, $scope.template.backupPages.list);
            }
            return {
                tipArr: tipArr,
                template: template,
                isSave: isSave,
                variableData: variableData
            }
        }

        function pageToMM(pages, tempPages) {
            angular.forEach(pages, function (item, i) {
                item.centerrotation = true;
                if (item.background) {
                    item.background.offsetx = PxTurnMm(item.background.offsetx);
                    item.background.offsety = PxTurnMm(item.background.offsety);
                }
                item.mediabox = {
                    width: PxTurnMm(item.mediabox.width),
                    height: PxTurnMm(item.mediabox.height),
                    x: 0,
                    y: 0
                };
                item.trimbox = {
                    width: PxTurnMm(item.trimbox.width),
                    height: PxTurnMm(item.trimbox.height),
                    x: PxTurnMm(item.trimbox.x),
                    y: PxTurnMm(item.trimbox.y)
                };

                item.editbox = {
                    width: PxTurnMm(item.editbox.width),
                    height: PxTurnMm(item.editbox.height),
                    x: PxTurnMm(item.editbox.x),
                    y: PxTurnMm(item.editbox.y)
                };
                if (item.editboxes && item.editboxes.editbox) {
                    item.editboxes.editbox.forEach(function (editbox) {
                        editbox.width = PxTurnMm(editbox.width);
                        editbox.height = PxTurnMm(editbox.height);
                        editbox.x = PxTurnMm(editbox.x);
                        editbox.y = PxTurnMm(editbox.y);
                    })
                }
                if (item.scene) {
                    item.scene.resource = {
                        provider: 'qiniu',
                        identifier: item.scene.background.resource.identifier
                    }
                    item.scene.geometry = {
                        width: PxTurnMm(item.scene.geometry.width),
                        height: PxTurnMm(item.scene.geometry.height),
                        x: PxTurnMm(item.scene.geometry.x),
                        y: PxTurnMm(item.scene.geometry.y)
                    };
                    if (item.scene.imagebox) {
                        item.scene.imagebox.geometry = {
                            width: PxTurnMm(item.scene.imagebox.geometry.width),
                            height: PxTurnMm(item.scene.imagebox.geometry.height),
                            x: PxTurnMm(item.scene.imagebox.geometry.x),
                            y: PxTurnMm(item.scene.imagebox.geometry.y)
                        };
                        item.scene.imagebox.rotation = {
                            angle: item.scene.imagebox.rotation.angle
                        };
                    }
                    item.scene.hierarchy = item.scene.hierarchy;
                }

                if (item.barcode && item.barcode.geometry) {
                    item.barcode = {
                        geometry: {
                            width: PxTurnMm(item.barcode.geometry.width),
                            height: PxTurnMm(item.barcode.geometry.height),
                            x: PxTurnMm(item.barcode.geometry.x),
                            y: PxTurnMm(item.barcode.geometry.y)
                        },
                        rotation: angular.copy(item.barcode.rotation),
                    };
                } else {
                    item.barcode = null;
                }
                if (item.qrcode && item.qrcode.geometry) {
                    item.qrcode = {
                        geometry: {
                            width: PxTurnMm(item.qrcode.geometry.width),
                            height: PxTurnMm(item.qrcode.geometry.height),
                            x: PxTurnMm(item.qrcode.geometry.x),
                            y: PxTurnMm(item.qrcode.geometry.y)
                        },
                        rotation: angular.copy(item.qrcode.rotation),
                    };
                } else {
                    item.qrcode = null;
                }

                angular.forEach(item.levels.level, function (level, l) {
                    if (level.imageart && level.imageart.current) {
                        var art = level.imageart;
                        art.geometry = {
                            width: PxTurnMm(art.geometry.width),
                            height: PxTurnMm(art.geometry.height),
                            x: PxTurnMm(art.geometry.x),
                            y: PxTurnMm(art.geometry.y)
                        };
                        art.offsetx = PxTurnMm(art.offsetx);
                        art.offsety = PxTurnMm(art.offsety);
                        art.rotation.angle = -art.rotation.angle;
                    }
                    if (level.imagebox && level.imagebox.current) {
                        var img = level.imagebox;
                        if (img.geometry.width <= 1 || img.geometry.height <= 1) {
                            $scope.isHasGeometry = false;
                        }
                        ;
                        img.geometry = {
                            width: PxTurnMm(img.geometry.width),
                            height: PxTurnMm(img.geometry.height),
                            x: PxTurnMm(img.geometry.x),
                            y: PxTurnMm(img.geometry.y)
                        };

                        if (img.image) {
                            img.image = {
                                offsetx: PxTurnMm(img.image.offsetx),
                                offsety: PxTurnMm(img.image.offsety),
                                scale: img.image.scale,
                                angle: img.image.angle,
                                resource: {
                                    identifier: img.image.resource ? img.image.resource.identifier : '',
                                    provider: img.image.resource ? img.image.resource.provider : ''
                                },
                                resourceid: img.image.resourceid
                            }
                        }
                    }
                    if (level.textbox && level.textbox.current) {
                        var text = level.textbox;
                        if (text.text == '') {
                            level.textbox = null;
                        } else {
                            text.geometry = {
                                width: PxTurnMm(text.geometry.width),
                                height: PxTurnMm(text.geometry.height),
                                x: PxTurnMm(text.geometry.x),
                                y: PxTurnMm(text.geometry.y)
                            }
                            text.rotation.angle = -text.rotation.angle;
                        }
                    }
                    if (level.textdocument && level.textdocument.current) {
                        var textdocument = level.textdocument;
                        textdocument.background.offsetx = PxTurnMm(textdocument.background.offsetx);
                        textdocument.background.offsety = PxTurnMm(textdocument.background.offsety);
                        textdocument.geometry = {
                            width: PxTurnMm(textdocument.geometry.width),
                            height: PxTurnMm(textdocument.geometry.height),
                            x: PxTurnMm(textdocument.geometry.x),
                            y: PxTurnMm(textdocument.geometry.y)
                        }
                        angular.forEach(textdocument.levels.level, function (l) {
                            if (l.imageart) {
                                var imageart = l.imageart;
                                imageart.geometry = {
                                    width: PxTurnMm(imageart.geometry.width),
                                    height: PxTurnMm(imageart.geometry.height),
                                    x: PxTurnMm(imageart.geometry.x),
                                    y: PxTurnMm(imageart.geometry.y)
                                };
                                imageart.offsetx = PxTurnMm(imageart.offsetx);
                                imageart.offsety = PxTurnMm(imageart.offsety);
                                imageart.rotation.angle = -imageart.rotation.angle;
                            }
                            if (l.textbox) {
                                if (l.textbox.text == '') {
                                    l.textbox = null;
                                } else {
                                    l.textbox.geometry = {
                                        width: PxTurnMm(l.textbox.geometry.width),
                                        height: PxTurnMm(l.textbox.geometry.height),
                                        x: PxTurnMm(l.textbox.geometry.x),
                                        y: PxTurnMm(l.textbox.geometry.y)
                                    }
                                    l.textbox.rotation.angle = -l.textbox.rotation.angle;
                                }
                            }
                        })
                    }
                    if (level.calTitleBox && level.calTitleBox.current) {
                        var _calTitleBox = angular.copy(level.calTitleBox);
                        level.calTitleBox.geometry = {
                            width: PxTurnMm(level.calTitleBox.geometry.width),
                            height: PxTurnMm(level.calTitleBox.geometry.height),
                            x: PxTurnMm(level.calTitleBox.geometry.x),
                            y: PxTurnMm(level.calTitleBox.geometry.y)
                        };
                        level.calTitleBox.rotation.angle = -level.calTitleBox.rotation.angle;
                        level.calTitleBox.settings={
                            border:level.calTitleBox.settings.border,
                            items:{
                                item:angular.copy(_calTitleBox.settings.items.item?_calTitleBox.settings.items.item:_calTitleBox.settings.items)
                            }
                        };
                        level.calTitleBox.layout={
                            geometry: {
                                width:level.calTitleBox.layout.width>=0?level.calTitleBox.layout.width:level.calTitleBox.layout.geometry.width,
                                height:level.calTitleBox.layout.height>=0?level.calTitleBox.layout.height:level.calTitleBox.layout.geometry.height,
                                x:0,
                                y:0,
                            },
                            previewUrl:level.calTitleBox.layout.previewUrl,
                            type:level.calTitleBox.layout.type,
                            items:{
                                item:angular.copy(_calTitleBox.layout.items.item?_calTitleBox.layout.items.item:_calTitleBox.layout.items),
                            }
                        };
                        level.calTitleBox.layout.items.item.forEach(function (item) {
                            item.geometry = {
                                width:item.w>=0?item.w:item.geometry.width,
                                height:item.h>=0?item.h:item.geometry.height,
                                x:item.x>=0?item.x:item.geometry.x,
                                y:item.y>=0?item.y:item.geometry.y,
                            }
                        })
                    }
                    if (level.calMonthBox && level.calMonthBox.current) {
                        var _calMonthBox = angular.copy(level.calMonthBox);
                        level.calMonthBox.geometry = {
                            width: PxTurnMm(level.calMonthBox.geometry.width),
                            height: PxTurnMm(level.calMonthBox.geometry.height),
                            x: PxTurnMm(level.calMonthBox.geometry.x),
                            y: PxTurnMm(level.calMonthBox.geometry.y)
                        };
                        level.calMonthBox.rotation.angle = -level.calMonthBox.rotation.angle;

                        level.calMonthBox.weekStyles={item:angular.copy(_calMonthBox.weekStyles.item?_calMonthBox.weekStyles.item:_calMonthBox.weekStyles)};
                        level.calMonthBox.weekLayout={
                            geometry: {
                                width:level.calMonthBox.weekLayout.width>=0?level.calMonthBox.weekLayout.width:level.calMonthBox.weekLayout.geometry.width,
                                height:level.calMonthBox.weekLayout.height>=0?level.calMonthBox.weekLayout.height:level.calMonthBox.weekLayout.geometry.height,
                                x:0,
                                y:0,
                            },
                            items:{
                                item:angular.copy(_calMonthBox.weekLayout.items.item?_calMonthBox.weekLayout.items.item:_calMonthBox.weekLayout.items)
                            },
                            type:level.calMonthBox.weekLayout.type,
                            previewUrl:level.calMonthBox.weekLayout.previewUrl,
                        };
                        level.calMonthBox.weekLayout.items.item.forEach(function (weekLayout) {
                            weekLayout.geometry = {
                                width:weekLayout.w>=0?weekLayout.w:weekLayout.geometry.width,
                                height:weekLayout.h>=0?weekLayout.h:weekLayout.geometry.height,
                                x:weekLayout.x>=0?weekLayout.x:weekLayout.geometry.x,
                                y:weekLayout.y>=0?weekLayout.y:weekLayout.geometry.y,
                            }
                        })
                        level.calMonthBox.dateStyles={item:angular.copy(_calMonthBox.dateStyles.item?_calMonthBox.dateStyles.item:_calMonthBox.dateStyles)},
                            level.calMonthBox.dateLayout={
                                geometry: {
                                    width:level.calMonthBox.dateLayout.width>=0?level.calMonthBox.dateLayout.width:level.calMonthBox.dateLayout.geometry.width,
                                    height:level.calMonthBox.dateLayout.height>=0?level.calMonthBox.dateLayout.height:level.calMonthBox.dateLayout.geometry.height,
                                    x:0,
                                    y:0,
                                },
                                items:{
                                    item:angular.copy(_calMonthBox.dateLayout.items.item?_calMonthBox.dateLayout.items.item:_calMonthBox.dateLayout.items)
                                },
                                type:level.calMonthBox.dateLayout.type,
                                previewUrl:level.calMonthBox.dateLayout.previewUrl
                            };
                        level.calMonthBox.dateLayout.items.item.forEach(function (dateLayout) {
                            dateLayout.geometry = {
                                width:dateLayout.w>=0?dateLayout.w:dateLayout.geometry.width,
                                height:dateLayout.h>=0?dateLayout.h:dateLayout.geometry.height,
                                x:dateLayout.x>=0?dateLayout.x:dateLayout.geometry.x,
                                y:dateLayout.y>=0?dateLayout.y:dateLayout.geometry.y,
                            }
                        })
                        level.calMonthBox.weekStyles.item.forEach(function (weekStyle) {
                            var _items = angular.copy(weekStyle.items.item?weekStyle.items.item:weekStyle.items);
                            weekStyle.items = {
                                item:_items
                            }
                        })
                        level.calMonthBox.dateStyles.item.forEach(function (dateStyle) {
                            var _items = angular.copy(dateStyle.items.item?dateStyle.items.item:dateStyle.items);
                            dateStyle.items = {
                                item:_items
                            }
                        })
                    }
                })
            });
        }

        function loadPageToPx(templatePage, pageIndex, callback) {
            if (templatePage.loaded) {
                return;
            }
            if (templatePage.background && templatePage.background.resource && templatePage.background.resource.identifier) {
                var background = templatePage.background;
                var pageW = templatePage.mediabox.width,
                    pageH = templatePage.mediabox.height;
                if (background.resource && background.resource.identifier) {
                    getBackgroundSource(background.resource, pageW, pageH, background)
                }
            }
            var promise_list = [];
            if (templatePage.levels && templatePage.levels.level) {
                angular.forEach(templatePage.levels.level, function (level, n) {
                    if (level.imageart && level.imageart.geometry) {
                        var deferred = $q.defer();
                        var art = level.imageart;
                        $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                            templatePage.levels.level[n].imageart = {
                                geometry: art.geometry,
                                rotation: {
                                    angle: art.rotation.angle
                                },
                                type: art.type,
                                resource: {
                                    identifier: art.resource.identifier,
                                    provider: art.resource.provider,
                                    width: data.width,
                                    height: data.height
                                },
                                scale: art.scale,
                                offsetx: art.offsetx,
                                offsety: art.offsety,
                                unit: 'mm',
                                current: 'imageart',
                                levelI: n,
                                pageI: pageIndex,
                                lock: art.lock,
                                ctrlKey: false,
                                transparency: art.transparency,
                                onlyshow: art.onlyshow,
                                movable: art.movable,
                                editable: art.editable,
                            }
                            deferred.resolve("ok");
                        }).error(function (error) {
                            MessageService.error("获取图片信息失败");
                            deferred.resolve("error");
                        });
                        promise_list.push(deferred.promise);
                    }
                    if (level.imagebox && level.imagebox.geometry) {
                        var image = level.imagebox;
                        if (image.shadow == null) {
                            image.shadow = {
                                color: '#777777',
                                x: 0,
                                y: 0,
                                blur: 0
                            }
                        }
                        if (image.border == null) {
                            image.border = {
                                shape: 'Rectangle',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0,
                                    all: 0,
                                    filletType: 'Fixed'
                                },
                                "edge": {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                                lineWidth: 0,
                                type: "Color",
                                lineType: "solid",
                                resource: {
                                    "identifier": ''
                                },
                                color: '#000000',
                            }
                        }
                        templatePage.levels.level[n].imagebox = {
                            geometry: image.geometry,
                            rotation: angular.copy(image.rotation),
                            mask: angular.copy(image.mask),
                            image: angular.copy(image.image),
                            maskUrl: 'content/images/mask_souce2.png',
                            imgUrl: 'content/images/mask_souce2.png',
                            unit: 'mm',
                            current: 'imagebox',
                            levelI: n,
                            pageI: pageIndex,
                            lock: image.lock,
                            border: image.border,
                            shadow: image.shadow,
                            ctrlKey: false,
                            transparency: isNaN(image.transparency) ? 1 : image.transparency
                        }


                        if (image.image && image.image.resource && image.image.resource.identifier) {       //qiniu
                            templatePage.levels.level[n].imagebox.image = {
                                resource: {
                                    identifier: image.image ? image.image.resource.identifier : '',
                                    provider: image.image ? image.image.resource.provider : 'qiniu'
                                },
                                imgSize: (!image.image) || image.image.imgSize == null ? '' : image.image.imgSize,
                                parameter: (!image.image) || image.image.parameter == null ? [] : angular.fromJson(image.image.parameter),
                                filter: (!image.image) || image.image.filter == null ? '' : image.image.filter,
                                offsetx: (!image.image) || image.image.offsetx == null ? 0 : image.image.offsetx,
                                offsety: (!image.image) || image.image.offsety == null ? 0 : image.image.offsety,
                                angle: image.image && image.image.angle ? image.image.angle : 0,
                                scale: (!image.image) || image.image.scale == 1 ? 1 : image.image.scale,
                                index: (!image.image) ? '' : image.image.index,
                                resourceid: (!image.image) ? '' : image.image.resourceid,
                            }
                            templatePage.levels.level[n].imagebox.imgUrl = image.image.resource.identifier + '?imageMogr2/auto-orient/thumbnail/!40p';
                            if (image.image.angle != 0) {
                                templatePage.levels.level[n].imagebox.imgUrl += '/rotate/' + image.image.angle
                            }
                            var promise = getBackgroundSource1(templatePage.levels.level[n].imagebox.image.resource, templatePage.levels.level[n].imagebox.geometry.width, templatePage.levels.level[n].imagebox.geometry.height, templatePage.levels.level[n].imagebox.image)
                            if (promise) {
                                promise_list.push(promise);
                            }
                        } else {
                            var deferred = $q.defer();
                            var imagecanvas = document.createElement('canvas');
                            var imagecontext = imagecanvas.getContext('2d');
                            var newImg = document.createElement('img');
                            newImg.crossOrigin = "Anonymous";
                            newImg.src = 'content/images/mask_souce2.png';
                            newImg.onload = function () {
                                newImg.onload = null;
                                var width = newImg.width;
                                var height = newImg.height;
                                if (image.mask && image.mask.resource && image.mask.resource.identifier) {
                                    var masks = document.createElement('img');
                                    masks.crossOrigin = "anonymous";
                                    masks.onload = function () {
                                        masks.onload = null;
                                        imagecanvas.width = width;
                                        imagecanvas.height = height;
                                        imagecontext.drawImage(masks, 0, 0, width, height);
                                        imagecontext.globalCompositeOperation = 'source-in';
                                        imagecontext.drawImage(newImg, 0, 0);
                                        templatePage.levels.level[n].imagebox.bgImg = ImageService.dataURLtoBlob(imagecanvas.toDataURL());
                                        deferred.resolve("ready");
                                        $scope.$apply();
                                    };
                                    masks.src = image.mask.resource.identifier;
                                }else{
                                    deferred.resolve("ready");
                                }
                            };
                            newImg.src = 'content/images/mask_souce2.png';
                            // promise_list.push(deferred.promise);
                        }
                        if (templatePage.levels.level[n].imagebox.mask && templatePage.levels.level[n].imagebox.mask.resource && templatePage.levels.level[n].imagebox.mask.resource.identifier) {
                            // imageboxResourceCanvas(templatePage.levels.level[n].imagebox);
                            // var deferred = $q.defer();
                            if (templatePage.levels.level[n].imagebox.image && templatePage.levels.level[n].imagebox.image.resource.identifier) {
                                // imageboxResourceCanvas(templatePage.levels.level[n].imagebox, function () {
                                //     // deferred.resolve("ready");
                                // });
                            } else {
                                // maskScale(null, templatePage.levels.level[n].imagebox, n, templatePage.levels.level[n].imagebox.mask.resource.identifier, function () {
                                //     // deferred.resolve("ready");
                                // });
                            }
                            // promise_list.push(deferred.promise);
                        }
                    }
                    if (level.textbox && level.textbox.geometry) {
                        var text = level.textbox;
                        templatePage.levels.level[n].textbox = {
                            geometry: text.geometry,
                            identifier: text.identifier,
                            rotation: {
                                angle: text.rotation.angle
                            },
                            angle: angular.copy(text.angle),
                            status: angular.copy(text.status),
                            style: angular.copy(text.style),
                            type: angular.copy(text.type),
                            text: angular.copy(text.text),
                            version: text.version,
                            unit: 'mm',
                            current: 'textbox',
                            levelI: n,
                            pageI: pageIndex,
                            lock: text.lock,
                            ctrlKey: false,
                            transparency: text.transparency,
                            lineheight: text.style.lineheight,
                            letterspacing: text.style.letterspacing,
                            scale: 1,
                            movable: text.movable,
                            editable: text.editable,
                            fontuuid: text.fontuuid
                        }
                        if (text.fontuuid) {
                            //下载字体
                            var deferred = $q.defer();
                            getFont(templatePage.levels.level[n].textbox,FontManagement, function () {
                                deferred.resolve("ready");
                            });
                            promise_list.push(deferred.promise);
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        var textdocument = level.textdocument;
                        templatePage.levels.level[n].textdocument = {
                            background: textdocument.background,
                            geometry: textdocument.geometry,
                            rotation: {
                                angle: textdocument.rotation.angle || 0
                            },
                            unit: 'mm',
                            current: 'textdocument',
                            levels: angular.copy(textdocument.levels),
                            levelI: n,
                            pageI: pageIndex,
                            lock: textdocument.lock,
                            ctrlKey: false,
                            transparency: textdocument.transparency,
                            movable: textdocument.movable,
                            editable: textdocument.editable,
                            scale: textdocument.geometry.width / templatePage.mediabox.width
                        }

                        var backg = templatePage.levels.level[n].textdocument.background;
                        var pageW = templatePage.levels.level[n].textdocument.geometry.width,
                            pageH = templatePage.levels.level[n].textdocument.geometry.height;
                        if (backg.resource && backg.resource.identifier) {
                            var promise = getBackgroundSource(backg.resource, pageW, pageH, backg);
                            if (promise) {
                                promise_list.push(promise);
                            }
                        }

                        angular.forEach(textdocument.levels.level, function (lev, l) {
                            if (lev.imageart && lev.imageart.geometry) {
                                var deferred = $q.defer();
                                var art = lev.imageart;
                                $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                    templatePage.levels.level[n].textdocument.levels.level[l].imageart = {
                                        geometry: art.geometry,
                                        rotation: {
                                            angle: art.rotation.angle
                                        },
                                        type: art.type,
                                        resource: {
                                            identifier: art.resource.identifier,
                                            provider: art.resource.provider,
                                            width: data.width,
                                            height: data.height
                                        },
                                        scale: art.scale,
                                        offsetx: art.offsetx,
                                        offsety: art.offsety,
                                        unit: 'mm',
                                        current: 'imageart',
                                        levelI: n,
                                        pageI: pageIndex,
                                        lock: false,
                                        transparency: art.transparency,
                                        onlyshow: art.onlyshow,
                                        movable: art.movable,
                                        editable: art.editable,
                                    }
                                    deferred.resolve("ready");
                                }).error(function (error) {
                                    MessageService.error("获取图片信息失败");
                                    deferred.resolve("error");
                                });
                                promise_list.push(deferred.promise);
                            }
                            if (lev.textbox && lev.textbox.geometry) {
                                var text = lev.textbox;
                                templatePage.levels.level[n].textdocument.levels.level[l].textbox = {
                                    geometry: text.geometry,
                                    identifier: text.identifier,
                                    rotation: {
                                        angle: text.rotation.angle
                                    },
                                    angle: angular.copy(text.angle),
                                    status: angular.copy(text.status),
                                    style: angular.copy(text.style),
                                    type: angular.copy(text.type),
                                    text: angular.copy(text.text),
                                    unit: 'mm',
                                    current: 'textbox',
                                    levelI: n,
                                    pageI: pageIndex,
                                    lock: false,
                                    transparency: text.transparency,
                                    lineheight: text.style.lineheight,
                                    letterspacing: text.style.letterspacing,
                                    fontuuid: text.fontuuid
                                }
                                //下载字体
                                if (text.fontuuid) {
                                    var deferred = $q.defer();
                                    getFont(text,FontManagement, function () {
                                        deferred.resolve("ready");
                                    });
                                    fontStyle(text);
                                    promise_list.push(deferred);
                                }
                            }
                        })
                    }
                });
            }

            if (callback) {
                if (promise_list.length == 0) {
                    callback(templatePage);
                } else {
                    $q.all(promise_list).then(function (res) {
                        callback(templatePage);
                    });
                }
            }

            templatePage.loaded = true;
        }

        var seq = 0;
        function pagesToPx(pages, tempPages, flag) {
            angular.forEach(pages, function (page, i) {
                tempPageInfo(page, tempPages, flag,i)
            });
        }
        function tempPageInfo(page, tempPages, flag,i,callback) {
            if (!page.editbox) {
                page.editbox = angular.copy(page.trimbox);
            }
            if (!page.editboxes || !page.editboxes.editbox) {
                page.editboxes = {
                    editbox: [page.editbox]
                };
            }
            if (!page.backup) {
                seq++;
            }
            tempPages.page[i] = {

                isScene: false,
                seq: seq,
                width: MmTurnPx(page.trimbox.width),
                height: MmTurnPx(page.trimbox.height),
                trimbox: getGeometry(page.trimbox),
                mediabox: getGeometry(page.mediabox),
                editbox: getGeometry(page.editbox),
                editboxes: angular.copy(page.editboxes),
                config: angular.copy(page.config),
                blood: {
                    top: page.trimbox.y,
                    bottom: page.mediabox.height - page.trimbox.y - page.trimbox.height,
                    left: page.trimbox.x,
                    right: page.mediabox.width - page.trimbox.x - page.trimbox.width,
                },
                bloodPx: {
                    top: MmTurnPx(page.trimbox.y),
                    bottom: MmTurnPx(page.mediabox.height - page.trimbox.y - page.trimbox.height),
                    left: MmTurnPx(page.trimbox.x),
                    right: MmTurnPx(page.mediabox.width - page.trimbox.x - page.trimbox.width),
                },
                spread: page.spread,
                backup: page.backup,
                type: page.type,
                standardAddPage: !vm.isCustom && !vm.hasModel && page.backup ? true : false,
                unreplace: page.unreplace || false,
                scale: 1,
                barcode: {},
                qrcode: {},
                uuid: page.uuid,
                background: angular.copy(page.background),
                pagecover: {
                    identifier: page.pagecover && page.pagecover.identifier ? page.pagecover.identifier : '',
                    provider: page.pagecover && page.pagecover.provider ? page.pagecover.provider : '',
                },
                hasBgImg: page.background && page.background.resource.identifier ? true : false,
                bgImg: page.background.resource.identifier ? vm.url + page.background.resource.identifier + '?imageMogr2/interlace/1' : '',
                levels: angular.copy(page.levels),
                unit: 'mm',
                index: i
            };
            tempPages.page[i].editboxes.editbox.forEach(function (editbox, index) {
                tempPages.page[i].editboxes.editbox[index] = getGeometry(editbox);
            })
            tempPages.page[i].custom = {};
            if (page.scene) {
                tempPages.page[i].scene = {
                    background: {
                        resource: {
                            provider: page.scene.resource.provider,
                            identifier: page.scene.resource.identifier
                        }
                    },
                    geometry: getGeometry(page.scene.geometry),
                    imagebox: {
                        geometry: getGeometry(page.scene.imagebox.geometry),
                        rotation: {angle: page.scene.imagebox.rotation.angle}
                    },
                    isScene: true,
                    scale: 1,
                    unit: 'mm',
                    hierarchy: page.scene.hierarchy,
                    mouldid: page.scene.mouldid,
                }
                tempPages.page[i].scene.isScene = true;
                var w = tempPages.page[i].scene.geometry.width;
                var h = tempPages.page[i].scene.geometry.height;
                var originalScale = $con.width() * 0.8 / ($con.height() * 0.8);
                if (originalScale < page.scene.geometry.width / page.scene.geometry.height) {
                    //宽定，高不定
                    tempPages.page[i].scene.width = $con.width() * 0.8;
                    tempPages.page[i].scene.height = $con.width() * 0.8 * h / w;
                } else {

                    //高定，宽不动
                    tempPages.page[i].scene.height = $con.height() * 0.8;
                    tempPages.page[i].scene.width = $con.height() * 0.8 * w / h
                }
                tempPages.page[i].scene.proportion = (w / tempPages.page[i].scene.width);
                var wah = ThreeHundredDpi(page.scene.geometry.width, page.scene.geometry.height);
                tempPages.page[i].scene.widthPx = wah.px1;
                tempPages.page[i].scene.heightPx = wah.px2;
                if (vm.windowH / h >= vm.windowW / w) {
                    tempPages.page[i].scene.ratio = vm.windowW / w * 0.8;
                } else {
                    tempPages.page[i].scene.ratio = vm.windowH / h * 0.8;
                }
                if (vm.windowH1 / h >= vm.windowW1 / w) {
                    tempPages.page[i].scene.ratio1 = vm.windowW1 / w;
                } else {
                    tempPages.page[i].scene.ratio1 = vm.windowH1 / h;
                }
                tempPages.page[i].custom.width = tempPages.page[i].scene.geometry.width;
                tempPages.page[i].custom.height = tempPages.page[i].scene.geometry.height;
            }else{
                tempPages.page[i].custom.width = tempPages.page[i].mediabox.width;
                tempPages.page[i].custom.height = tempPages.page[i].mediabox.height;
            }
            if (tempPages.page[i].uuid == null) {
                tempPages.page[i].uuid = UidService.get();
            }

            if (page.barcode && page.barcode.geometry) {
                var barcode = page.barcode.geometry;
                var angle = 0;
                if (page.barcode.rotation && page.barcode.rotation.angle) {
                    angle = page.barcode.rotation.angle
                }
                tempPages.page[i].barcode = {
                    current: 'barcode',
                    geometry: {
                        width: MmTurnPx(barcode.width),
                        height: MmTurnPx(barcode.height),
                        x: MmTurnPx(barcode.x),
                        y: MmTurnPx(barcode.y),
                    },
                    rotation: {
                        angle: angle
                    },
                    lock: barcode.lock,
                }
            }

            if (page.qrcode && page.qrcode.geometry) {
                var qrcode = page.qrcode.geometry;
                var angle = 0;
                if (page.qrcode.rotation && page.qrcode.rotation.angle) {
                    angle = page.qrcode.rotation.angle
                }
                tempPages.page[i].qrcode = {
                    current: 'qrcode',
                    geometry: {
                        width: MmTurnPx(qrcode.width),
                        height: MmTurnPx(qrcode.height),
                        x: MmTurnPx(qrcode.x),
                        y: MmTurnPx(qrcode.y),
                    },
                    rotation: {
                        angle: angle
                    },
                    lock: qrcode.lock,
                }
            }
            if (page.background && page.background.resource && page.background.resource.identifier) {
                tempPages.page[i].background.offsetx = MmTurnPx(page.background.offsetx);
                tempPages.page[i].background.offsety = MmTurnPx(page.background.offsety);
            }

            angular.forEach(tempPages.page[i].levels.level, function (level, l) {
                if (level.imageart && level.imageart.geometry) {
                    var art = level.imageart;
                    art.current = 'imageart';
                    art.geometry = {
                        width: MmTurnPx(art.geometry.width),
                        height: MmTurnPx(art.geometry.height),
                        x: MmTurnPx(art.geometry.x),
                        y: MmTurnPx(art.geometry.y)
                    };
                    art.offsetx = MmTurnPx(art.offsetx);
                    art.offsety = MmTurnPx(art.offsety);
                    art.rotation.angle = -art.rotation.angle;
                }
                if (level.imagebox && level.imagebox.geometry) {
                    var img = level.imagebox;
                    if (img.geometry.width <= 1 || img.geometry.height <= 1) {
                        $scope.isHasGeometry = false;
                    }
                    img.current = 'imagebox';
                    img.geometry = {
                        width: MmTurnPx(img.geometry.width),
                        height: MmTurnPx(img.geometry.height),
                        x: MmTurnPx(img.geometry.x),
                        y: MmTurnPx(img.geometry.y)
                    };

                    if (img.image) {
                        img.image = {
                            offsetx: MmTurnPx(img.image.offsetx),
                            offsety: MmTurnPx(img.image.offsety),
                            scale: img.image.scale,
                            angle: img.image.angle,
                            resource: {
                                identifier: img.image.resource ? img.image.resource.identifier : '',
                                provider: img.image.resource ? img.image.resource.provider : ''
                            },
                            resourceid: img.image.resourceid
                        }
                    }
                }
                if (level.textbox && level.textbox.geometry) {
                    var text = level.textbox;
                    if (text.text == '') {
                        level.textbox = null;
                    } else {
                        text.current = 'textbox';
                        text.geometry = {
                            width: MmTurnPx(text.geometry.width),
                            height: MmTurnPx(text.geometry.height),
                            x: MmTurnPx(text.geometry.x),
                            y: MmTurnPx(text.geometry.y)
                        }
                        text.rotation.angle = -text.rotation.angle;
                    }
                }
                if (level.textdocument && level.textdocument.geometry) {
                    var textdocument = level.textdocument;
                    textdocument.current = 'textdocument';
                    textdocument.background.offsetx = MmTurnPx(textdocument.background.offsetx);
                    textdocument.background.offsety = MmTurnPx(textdocument.background.offsety);
                    textdocument.geometry = {
                        width: MmTurnPx(textdocument.geometry.width),
                        height: MmTurnPx(textdocument.geometry.height),
                        x: MmTurnPx(textdocument.geometry.x),
                        y: MmTurnPx(textdocument.geometry.y)
                    }
                    angular.forEach(textdocument.levels.level, function (l) {
                        if (l.imageart && l.imageart.geometry) {
                            var imageart = l.imageart;
                            imageart.current = 'imageart';
                            imageart.geometry = {
                                width: MmTurnPx(imageart.geometry.width),
                                height: MmTurnPx(imageart.geometry.height),
                                x: MmTurnPx(imageart.geometry.x),
                                y: MmTurnPx(imageart.geometry.y)
                            };
                            imageart.offsetx = MmTurnPx(imageart.offsetx);
                            imageart.offsety = MmTurnPx(imageart.offsety);
                            imageart.rotation.angle = -imageart.rotation.angle;
                        }
                        if (l.textbox && l.textbox.geometry) {
                            if (l.textbox.text == '') {
                                l.textbox = null;
                            } else {
                                l.textbox.current = 'textbox';
                                l.textbox.geometry = {
                                    width: MmTurnPx(l.textbox.geometry.width),
                                    height: MmTurnPx(l.textbox.geometry.height),
                                    x: MmTurnPx(l.textbox.geometry.x),
                                    y: MmTurnPx(l.textbox.geometry.y)
                                }
                                l.textbox.rotation.angle = -l.textbox.rotation.angle;
                            }
                        }
                    })
                }
                if (level.calMonthBox && level.calMonthBox.geometry) {
                    var calMonthBox = level.calMonthBox;
                    calMonthBox.rotation = {angle:0};
                    calMonthBox.current = 'calMonthBox';
                    calMonthBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,tempPages.page[i].config.monthIndex);
                    calMonthBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,tempPages.page[i].config.monthIndex);
                    calMonthBox.geometry = {
                        width: MmTurnPx(calMonthBox.geometry.width),
                        height: MmTurnPx(calMonthBox.geometry.height),
                        x: MmTurnPx(calMonthBox.geometry.x),
                        y: MmTurnPx(calMonthBox.geometry.y)
                    };
                    // calMonthBox.rotation.angle = -calMonthBox.rotation.angle;
                }
                if (level.calTitleBox && level.calTitleBox.geometry) {
                    var calTitleBox = level.calTitleBox;
                    calTitleBox.rotation = {angle:0};
                    calTitleBox.current = 'calTitleBox';
                    calTitleBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,tempPages.page[i].config.monthIndex);
                    calTitleBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,tempPages.page[i].config.monthIndex);
                    calTitleBox.geometry = {
                        width: MmTurnPx(calTitleBox.geometry.width),
                        height: MmTurnPx(calTitleBox.geometry.height),
                        x: MmTurnPx(calTitleBox.geometry.x),
                        y: MmTurnPx(calTitleBox.geometry.y)
                    };
                    // calTitleBox.rotation.angle = -calTitleBox.rotation.angle;
                }
            });


            if (!page.backup) {
                loadPageToPx(tempPages.page[i], i,function () {
                    if(callback){callback()}
                });
            }
            if (flag && flag == 'pages') {
                setOriginalWaH(MmTurnPx(page.trimbox.width), MmTurnPx(page.trimbox.height), i);
            }

            ratioInfo(tempPages.page[i])
        }
        function tempPageInfo1(partData, tempPage,pageIndex,scaleW,scaleH,callback,spread,usePartEditbox) {
            var part = angular.copy(partData);
            tempPage.updateThumbnail=true;
            tempPage.loaded = false;
            var _scale = scaleW > scaleH ? scaleH : scaleW;
            if((partData.spread && !tempPage.spread) || (!partData.spread && tempPage.spread)){
                if(spread) {
                    _scale = scaleW > scaleH ? scaleW : scaleH;
                }
                if (partData.spread) {
                    part.mediabox.width = (part.mediabox.width - Number(part.bleed.left) - Number(part.bleed.right)) / 2 + Number(part.bleed.left) + Number(part.bleed.right);
                }else{
                    part.mediabox.width = (part.mediabox.width - Number(part.bleed.left) - Number(part.bleed.right)) * 2 + Number(part.bleed.left) + Number(part.bleed.right);
                }
                var mediabox = angular.copy(part.mediabox);
                var trimbox = angular.copy({
                    height: part.mediabox.height - Number(part.bleed.bottom) - Number(part.bleed.top),
                    width: part.mediabox.width - Number(part.bleed.left) - Number(part.bleed.right),
                    x: part.bleed.left,
                    y: part.bleed.top
                });
            }else{
                var mediabox = angular.copy(part.mediabox);
                var trimbox = angular.copy({
                    height: part.mediabox.height - Number(part.bleed.bottom) - Number(part.bleed.top),
                    width: part.mediabox.width - Number(part.bleed.left) - Number(part.bleed.right),
                    x: part.bleed.left,
                    y: part.bleed.top
                });
            }
            var _oldMediabox = angular.copy(tempPage.mediabox);
            tempPage.width= MmTurnPx(trimbox.width);
            tempPage.height= MmTurnPx(trimbox.height);
            tempPage.trimbox= getGeometry(trimbox);
            tempPage.mediabox= getGeometry(mediabox);
            if (usePartEditbox) {
                // 修改部件上的数据页面需要同步
                tempPage.editboxes= getEditboxesFromPart(partData);
                tempPage.editboxes= getEditboxes(tempPage,getGeometry(partData.mediabox));
            }else{
                //不看部件上的设置，只针对当前页面变化做判断处理
                tempPage.editboxes= getEditboxes(tempPage,_oldMediabox);
            }

            tempPage.editbox= tempPage.editboxes.editbox[0];

            tempPage.blood= part.bleed;
            tempPage.bloodPx= {
                top: MmTurnPx(trimbox.y),
                bottom: MmTurnPx(mediabox.height - trimbox.y - trimbox.height),
                left: MmTurnPx(trimbox.x),
                right: MmTurnPx(mediabox.width - trimbox.x - trimbox.width),
            };
            tempPage.custom= {};
            if (part.scene) {
                tempPage.scene = angular.copy(part.scene);
                tempPage.scene = {
                    background: {
                        resource: {
                            provider: part.scene.resource.provider,
                            identifier: part.scene.resource.identifier
                        }
                    },
                    geometry: getGeometry(part.scene.geometry),
                    imagebox: {
                        geometry: getGeometry(part.scene.imagebox.geometry),
                        rotation: {angle: part.scene.imagebox.rotation.angle}
                    },
                    isScene: true,
                    scale: 1,
                    unit: 'mm',
                    hierarchy: part.scene.hierarchy,
                    mouldid: part.scene.mouldid,
                }
                tempPage.scene.isScene = true;
                var w = tempPage.scene.geometry.width;
                var h = tempPage.scene.geometry.height;
                var originalScale = $con.width() * 0.8 / ($con.height() * 0.8);
                if (originalScale < part.scene.geometry.width / part.scene.geometry.height) {
                    //宽定，高不定
                    tempPage.scene.width = $con.width() * 0.8;
                    tempPage.scene.height = $con.width() * 0.8 * h / w;
                } else {

                    //高定，宽不动
                    tempPage.scene.height = $con.height() * 0.8;
                    tempPage.scene.width = $con.height() * 0.8 * w / h
                }
                tempPage.scene.proportion = (w / tempPage.scene.width);
                var wah = ThreeHundredDpi(part.scene.geometry.width, part.scene.geometry.height);
                tempPage.scene.widthPx = wah.px1;
                tempPage.scene.heightPx = wah.px2;
                if (vm.windowH / h >= vm.windowW / w) {
                    tempPage.scene.ratio = vm.windowW / w * 0.8;
                } else {
                    tempPage.scene.ratio = vm.windowH / h * 0.8;
                }
                if (vm.windowH1 / h >= vm.windowW1 / w) {
                    tempPage.scene.ratio1 = vm.windowW1 / w;
                } else {
                    tempPage.scene.ratio1 = vm.windowH1 / h;
                }
                tempPage.custom.width = tempPage.scene.geometry.width;
                tempPage.custom.height = tempPage.scene.geometry.height;
            }else{
                tempPage.custom.width = tempPage.mediabox.width;
                tempPage.custom.height = tempPage.mediabox.height;
            }
            if (tempPage.barcode && tempPage.barcode.geometry) {
                tempPage.barcode.geometry= {
                    width: tempPage.barcode.geometry.width * _scale,
                    height: tempPage.barcode.geometry.height * _scale,
                    x: tempPage.barcode.geometry.x * _scale,
                    y: tempPage.barcode.geometry.y * _scale,
                };
            }

            if (tempPage.qrcode && tempPage.qrcode.geometry) {
                tempPage.qrcode.geometry= {
                    width: tempPage.qrcode.geometry.width * _scale,
                    height: tempPage.qrcode.geometry.height * _scale,
                    x: tempPage.qrcode.geometry.x * _scale,
                    y: tempPage.qrcode.geometry.y * _scale,
                };
            }
            if (tempPage.background && tempPage.background.resource && tempPage.background.resource.identifier) {
                var background = angular.copy(tempPage.background)
                resetBackground();
                if(scaleW == scaleH){
                    tempPage.background.offsetx = background.offsetx * _scale;
                    tempPage.background.offsety = background.offsety * _scale;
                }
            }
            if(!spread){
                angular.forEach(tempPage.levels.level, function (level, l) {
                    if (level.imageart && level.imageart.geometry) {
                        var nw = angular.copy(level.imageart.geometry.width);
                        var nh = angular.copy(level.imageart.geometry.height);
                        if(_scale > 1) {
                            level.imageart.geometry.x= level.imageart.geometry.x * _scale;
                            level.imageart.geometry.y= level.imageart.geometry.y * _scale;
                            level.imageart.geometry.height= level.imageart.geometry.height * _scale;
                            level.imageart.geometry.width = level.imageart.geometry.height * nw / nh;
                            level.imageart.offsetx = level.imageart.offsetx * _scale;
                            level.imageart.offsety = level.imageart.offsety * _scale;
                        }else{
                            level.imageart.geometry.x= level.imageart.geometry.x * _scale;
                            level.imageart.geometry.y= level.imageart.geometry.y * _scale;
                            level.imageart.geometry.width= level.imageart.geometry.width * _scale;
                            level.imageart.geometry.height = level.imageart.geometry.width * nh / nw;
                            level.imageart.offsetx = level.imageart.offsetx * _scale;
                            level.imageart.offsety = level.imageart.offsety * _scale;
                        }
                    }
                    if (level.imagebox && level.imagebox.geometry) {
                        level.imagebox.geometry = {
                            width: level.imagebox.geometry.width * _scale,
                            height: level.imagebox.geometry.height * _scale,
                            x: level.imagebox.geometry.x * _scale,
                            y: level.imagebox.geometry.y * _scale
                        };
                        if (level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                            level.imagebox.image.offsetx = 0;
                            level.imagebox.image.offsety = 0;
                            level.imagebox.image.scale = 1;
                            imageboxImg(level.imagebox, function () {
                                $scope.$apply();
                            })
                        }
                    }
                    if (level.textbox && level.textbox.geometry) {
                        if (level.textbox.text == '') {
                            level.textbox = null;
                        } else {
                            level.textbox.geometry = {
                                width: level.textbox.geometry.width * _scale,
                                height: level.textbox.geometry.height * _scale,
                                x: level.textbox.geometry.x * _scale,
                                y: level.textbox.geometry.y * _scale
                            };
                            level.textbox.style.size = level.textbox.style.size * _scale;
                            level.textbox.style.lineheight = level.textbox.style.lineheight * _scale;
                            level.textbox.style.letterspacing = level.textbox.style.letterspacing * _scale;
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        var textdocument = level.textdocument;
                        var textdocW = angular.copy(textdocument.geometry.width);
                        var textdocH = angular.copy(textdocument.geometry.height);
                        textdocument.geometry = {
                            width: textdocument.geometry.width * _scale,
                            height: textdocument.geometry.height * _scale,
                            x: textdocument.geometry.x * _scale,
                            y: textdocument.geometry.y * _scale
                        };
                        textdocument.background.offsetx = textdocument.background.offsetx * textdocument.geometry.width / textdocW;
                        textdocument.background.offsety = textdocument.background.offsety * textdocument.geometry.height / textdocH;
                        textdocument.background.width = textdocument.background.width * textdocument.geometry.width / textdocW;
                        textdocument.background.height = textdocument.background.height * textdocument.geometry.height / textdocH;

                        angular.forEach(textdocument.levels.level, function (level, l) {
                            var textdoc = textdocument.levels.level[l];
                            if (level.imageart) {
                                var geo = {
                                    width: textdoc.imageart.geometry.width * textdocument.geometry.width / textdocW,
                                    height: textdoc.imageart.geometry.height * textdocument.geometry.height / textdocH,
                                    x: textdoc.imageart.geometry.x * textdocument.geometry.width / textdocW,
                                    y: textdoc.imageart.geometry.y * textdocument.geometry.height / textdocH,
                                }
                                level.imageart.geometry = geo;

                                level.imageart.offsetx = textdoc.imageart.offsetx * textdocument.geometry.width / textdocW;
                                level.imageart.offsety = textdoc.imageart.offsety * textdocument.geometry.height / textdocH;
                            }
                            if (level.textbox) {
                                var textGeo = {
                                    width: textdoc.textbox.geometry.width * textdocument.geometry.width / textdocW,
                                    height: textdoc.textbox.geometry.height * textdocument.geometry.height / textdocH,
                                    x: textdoc.textbox.geometry.x * textdocument.geometry.width / textdocW,
                                    y: textdoc.textbox.geometry.y * textdocument.geometry.height / textdocH,
                                }
                                level.textbox.geometry = textGeo;
                                level.textbox.style.size = textdoc.textbox.style.size * textdocument.geometry.width / textdocW;
                                level.textbox.style.lineheight = textdoc.textbox.style.lineheight * textdocument.geometry.width / textdocW;
                            }
                        })
                    }
                    if (level.calMonthBox && level.calMonthBox.geometry) {
                        level.calMonthBox.geometry = {
                            width: level.calMonthBox.geometry.width * _scale,
                            height: level.calMonthBox.geometry.height * _scale,
                            x: level.calMonthBox.geometry.x * _scale,
                            y: level.calMonthBox.geometry.y * _scale
                        };
                    }
                    if (level.calTitleBox && level.calTitleBox.geometry) {
                        level.calTitleBox.geometry = {
                            width: level.calTitleBox.geometry.width * _scale,
                            height: level.calTitleBox.geometry.height * _scale,
                            x: level.calTitleBox.geometry.x * _scale,
                            y: level.calTitleBox.geometry.y * _scale
                        };
                    }
                });
            }
            setOriginalWaH(MmTurnPx(tempPage.trimbox.width), MmTurnPx(tempPage.trimbox.height), pageIndex);
            if(callback){
                callback()
            }
        }

        function getEditboxes(tempPage,oldMediabox){
            var editboxes = {
                editbox:[]
            };
            var _tempPage = angular.copy(tempPage)
            var xLeftMinValue = null; //距离画布左侧的值
            var xRightMinValue = null; //距离画布右侧的值
            for (var i = 0; i < _tempPage.editboxes.editbox.length; i++) {
                var item = _tempPage.editboxes.editbox[i];
                if (xLeftMinValue == null) {
                    xLeftMinValue = Number(item.x);
                }else if (xLeftMinValue > Number(item.x)) {
                    xLeftMinValue = Number(item.x);
                }
                var _xRightMinValue = Number(oldMediabox.width) - (Number(item.x) + Number(item.width));
                if (xRightMinValue == null) {
                    xRightMinValue = _xRightMinValue;
                }else if (xRightMinValue > _xRightMinValue) {
                    xRightMinValue = _xRightMinValue;
                }
            }
            // var _s = (_tempPage.mediabox.width - xRightMinValue - xLeftMinValue) / (oldMediabox.width -xRightMinValue - xLeftMinValue);
            var _s = (_tempPage.mediabox.width) / (oldMediabox.width);
            _tempPage.editboxes.editbox.forEach(function (editbox) {
                editbox.width = editbox.width*_s;
                editbox.height = editbox.height;
                // editbox.x = (editbox.x - xLeftMinValue) *_s + xLeftMinValue;
                editbox.x = editbox.x*_s;
                editbox.y = editbox.y;
                editboxes.editbox.push(angular.copy(editbox))
            })
            return editboxes
        }

        function getEditboxesFromPart(partData){
            var part = angular.copy(partData)
            var editboxes = {
                editbox:[]
            };
            part.editboxes.editbox.forEach(function(editbox){
                editboxes.editbox.push(getGeometry(editbox));
            })
            return editboxes;
        }

        function success(data) {
            vm.isSaving = false;
            MessageService.success("保存成功！");
            clearPageStorageInfo();
            if (!vm.isCustom) {
                $state.go('templateList', {reload: true})
            } else {
                $state.go('selfTemplateList', {self: 'self'}, {reload: true})
            }
        }

        function error() {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }


        function success2(data) {
            vm.isSaving = false;
            clearPageStorageInfo();
            MessageService.success("发布成功！");
            $state.go('templateList', {reload: true})
        }

        function error2() {
            vm.isSaving = false;
            MessageService.error("发布失败，请联系管理员配置发布路径！");
        }

        $scope.exitPage = function (item) {
            vm.exitSure = false;
            if(!vm.thirdparty){
                clearPageStorageInfo();
            }
            if(!item){
                $scope.savePage(1)
            }
        };
        $scope.exitPageHide = function () {
            vm.exitSure = false;
            if(!vm.thirdparty){
                clearPageStorageInfo();
            }
            window.history.back()
        };

        vm.leftShow = true;
        vm.rightShow = true;
        vm.bottomShow = true;
        $scope.hidePanel = function (attr) {
            if(attr === 1){vm.leftShow = !vm.leftShow};
            if(attr === 0){vm.rightShow = !vm.rightShow};
            if(attr === 2){vm.bottomShow = !vm.bottomShow};
            $timeout(function () {
                updateMeasureRuler();
            })
        }


        //历史纪录
        $scope.pageHistory = [];
        vm.operation = false;
        var updateHistorys = function () {
            $scope.currentPage.updateThumbnail = true;
            vm.operation = true;
            $scope.template.pages.page[$scope.template.pageI] = $scope.currentPage;
            $scope.pageHistory.push(angular.copy($scope.template));
            if ($scope.pageHistory.length > 50) {
                $scope.pageHistory.splice($scope.pageHistory.length - 4, $scope.pageHistory.length - 5)
            }
        };

        //防止事件冒泡，默认事件
        $scope.fn = fn1;
        function fn($event) {
            if($event){
                var $event = $event || window.event;
                if($event && $event.preventDefault){
                    $event.preventDefault();
                }
                else {
                    window.event.returnValue = false;
                    return false;
                }
                $event.stopPropagation();
            }
        }
        function fn1($event) {
            var $event = $event || window.event;
            // if($event && $event.preventDefault){
            //     $event.preventDefault();
            // }
            // else {
            //     window.event.returnValue = false;
            //     return false;
            // }
            $event.stopPropagation();
        }

        $scope.showThumbnailPic = function () {
            var i = $scope.template.pageI;
            $scope.currentPage = $scope.template.pages.page[i];
            $scope.lastPage = $scope.template.pages.page[i];
            vm.loadingImg = true;
            $timeout(function () {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage, null, function () {
                    vm.loadingImg = false;
                    $scope.thumbnailPic = true;
                    $scope.thumbnailType = 'one';
                })
                updateMeasureRuler();
            })
        }
        $scope.allThumbnailUpdate = function (index) {
            if(!$scope.thumbnailPic){
                return;
            }
            if($scope.lastPage.loading){
                MessageService.error("缩略图正在生成中，请稍后再试");
                return
            }
            var _i =index > -1 ? index : 0;
            $scope.template.pages.page.forEach(function (page,index1) {
                if(index1 >= _i && page.updateThumbnail){
                    page.loading = true;
                }
            });
            if($scope.template.pages.page[_i]){
                if(!$scope.template.pages.page[_i].updateThumbnail){
                    _i++
                    $scope.allThumbnailUpdate(_i)
                    return
                }else{
                    $scope.template.pages.page[_i].loading = false;
                    $scope.thisThumbnailUpdate(_i, function () {
                        _i++
                        if(_i == $scope.template.pages.page.length){
                            MessageService.success("缩略图已更新完成");
                        }else{
                            $scope.allThumbnailUpdate(_i)
                        }
                    })
                }
            }
        }
        $scope.thisThumbnailUpdate = function(index,callback){
            if($scope.lastPage.loading){
                MessageService.error("缩略图正在生成中，请稍后再试");
                return
            }
            $scope.lastPage = $scope.template.pages.page[index];
            $scope.lastPage.loading = true;
            $scope.lastPage.isPrompt = false;
            $timeout(function () {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage, null, function () {
                    $scope.lastPage.loading = false;
                    $scope.lastPage = $scope.template.pages.page[$scope.template.pageI];
                    if(callback){callback()}
                })
            },1000)
        }

        $scope.showAllThumbnailPic = function (callback) {
            if(vm.isSkip){return}
            var i = $scope.template.pageI;
            $scope.currentPage = $scope.template.pages.page[i];
            $scope.lastPage = $scope.template.pages.page[i];

            $timeout(function () {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage, null, function () {
                    $scope.template.pages.page.forEach(function (page) {
                        page.loading = false;
                    });
                    $scope.thumbnailPic = true;
                    $scope.thumbnailType = 'all';
                })
                updateMeasureRuler();
            });

            // var nodes = document.getElementsByClassName('pageNode');
            // Qinius.get(function (msg) {
            //     vm.token = msg.token;
            //     angular.forEach($scope.template.pages.page, function (page, i) {
            //         setThumbnailPic(nodes[i], page)
            //     })
            setTimeout(function () {
                swiper();
            }, 1000);
            // });
        }


        function setThumbnailPic1(callback) {
            var i = $scope.template.pageI;
            $scope.currentPage = $scope.template.pages.page[i];
            $scope.lastPage = $scope.template.pages.page[i];
            setTimeout(function () {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage, null, function () {
                    if (callback) {
                        callback()
                    }
                })
            })
        }

        function setThumbnailPic(node, page, flag, callback) {
            getDomOnload($scope, "pageThumbnail",function () {
                page.pagecover = null;
                var width = $scope.lastPage.mediabox.width;
                var height = $scope.lastPage.mediabox.height;
                if($scope.lastPage.scene){
                    $scope.lastPage.isScene = true;
                }
                if ($scope.lastPage.isScene) {
                    width = $scope.lastPage.scene.geometry.width;
                    height = $scope.lastPage.scene.geometry.height;
                }
                var _complete = false;
                $timeout(function () {
                    if(!_complete){
                        _complete = true;
                        if(callback){callback()}
                        page.isPrompt = true;
                    }
                },10000)
                var _ratio = $scope.lastPage.ratio || 1;
                html2canvas_adapt(node, {
                    onrendered: function (canvas) {
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);

                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', 'cover-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                if(_complete){
                                    return;
                                }
                                _complete = true;
                                page.updateThumbnail=false;
                                page.pagecover = {
                                    identifier: vm.url + data.key,
                                    provider: 'qiniu'
                                }
                                if (flag && flag == 'cover') {
                                    pageAnimate();
                                }
                                if (flag && flag == 'addSimple') {
                                    createModal('app/entities/page/page-simple.html', 'PageSimpleController').result.then(
                                        function (data) {
                                            $scope.template.documentpics.documentpic = data.documentpic;
                                            vm.operation = true;
                                        });
                                    return
                                }
                                if (flag && flag == 'all') {
                                    for (var i = 1; i < $scope.template.pages.page.length; i++) {
                                        var page1 = $scope.template.pages.page[i];
                                        if (!page1.scene) {
                                            page1.pagecover = page.pagecover;
                                        } else {
                                            page1.pagecover = {
                                                identifier: "",
                                                provider: 'qiniu'
                                            };
                                        }
                                    }
                                }
                                if (callback) {
                                    callback(vm.url + data.key)
                                }
                                $scope.$digest();
                            },
                            error: function (request) {
                                MessageService.error("文件上传失败");
                            }
                        });
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: width * _ratio,
                    height: height * _ratio,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: $scope.lastPage.isScene ? (-($scope.lastPage.scene.geometry.width - $scope.lastPage.scene.geometry.width * _ratio) / 2) : -($scope.lastPage.mediabox.width - $scope.lastPage.mediabox.width * _ratio) / 2,
                    y: $scope.lastPage.isScene ? (-($scope.lastPage.scene.geometry.height - $scope.lastPage.scene.geometry.height * _ratio) / 2) : -($scope.lastPage.mediabox.height - $scope.lastPage.mediabox.height * _ratio) / 2,
                    ratio: _ratio || 1,
                    backgroundColor: "rgba(0, 0, 0, 0)",
                });
            })
        }

        function dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/) ? arr[0].match(/:(.*?);/)[1] : null,
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type: mime});
        }

        $scope.modalHide = function () {
            var _loading = false;
            for(var i=0; i<$scope.template.pages.page.length; i++){
                var _page = $scope.template.pages.page[i];
                if(_page.loading){
                    _loading = true;
                    break
                }
            }
            if(_loading){
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'是否停止缩略图生成？'
                },function(){
                    for(var i=0; i<$scope.template.pages.page.length; i++){
                        var _page = $scope.template.pages.page[i];
                        _page.loading = false;
                    }
                    $scope.thumbnailPic = false;
                }, function () {
                });
            }else{
                $scope.thumbnailPic = false;
            }
        };

        //swiper
        function swiper() {
            var swiper = new Swiper('.swiper-container', {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }

        vm.isFullScreen = false;
        function getFullState(){
            $timeout(function () {
                vm.isFullScreen = Math.abs(window.screen.height-window.document.documentElement.clientHeight) <= 17;
            })
        }
        getFullState();
        //全屏
        $scope.fullScreen = function(){
            var el = document.documentElement
            var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen
            if (rfs) { // typeof rfs != "undefined" && rfs
                rfs.call(el)
            }else if (typeof window.ActiveXObject !== 'undefined') {
                // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
                var wscript = new ActiveXObject('WScript.Shell')
                if (wscript != null) {
                    wscript.SendKeys('{F11}')
                }
            }
        }
        $scope.exitScreen = function(){
            var el = document
            var cfs = el.cancelFullScreen || el.mozCancelFullScreen || el.msExitFullscreen || el.webkitExitFullscreen || el.exitFullscreen
            if (cfs) { // typeof cfs != "undefined" && cfs
                cfs.call(el)
            } else if (typeof window.ActiveXObject !== 'undefined') {
                // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
                var wscript = new ActiveXObject('WScript.Shell')
                if (wscript != null) {
                    wscript.SendKeys('{F11}')
                }
            }
        }

        function toolbarStatus() {
            vm.isOperationDir = false;
            vm.isOperationSpacing = false;
            vm.isTransp = false;
            vm.isCoverage = false;
            vm.isRoundedSet = false;
            vm.isBorderSet = false;
            vm.isShadowSet = false;
            vm.isAlign = false;
            // $scope.isDistriHover = false;
            // $scope.isAlignHover = false;
            vm.isOperationColor = false;
        }


        /********************************************PSD操作**********************start*************************************/
        $scope.showPsdModal = function () {
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/psd-upload.html',
                controller: 'PsdUploadController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: [function () {
                        return {
                            defaultFont: vm.defaultFont,
                            token: vm.token,
                            url: vm.url,
                            showMasks: vm.showMasks,
                            totalClipartItems: vm.totalClipartItems,
                        };
                    }]
                }
            }).result.then(function (data) {
                if (data) {
                    if(!$scope.template.psdFiles.psdFile){
                        $scope.template.psdFiles.psdFile = [];
                    }
                    data.forEach(function (itme) {
                        $scope.template.psdFiles.psdFile.push(itme);
                    })
                    vm.operation = true;
                }
            });
        }

        $scope.delPsdFile = function (item) {
            var _index = -1;
            $scope.template.psdFiles.psdFile.forEach(function (psd, index) {
                if (psd.name == item.name && item.cover.identifier == psd.cover.identifier) {
                    _index = index;
                }
            })
            $scope.template.psdFiles.psdFile.splice(_index, 1);
        }
        var loading = null;
        $scope.insertPsdFile = function (item, index) {
            loading = MessageService.loading();
            var page = $scope.template.pages.page[$scope.template.pageI];
            page.levels.level = [];
            var template = angular.copy(JSON.parse(item.data));
            // 将PSD模版应用到当前页
            for (var i = 0; i < template.layers.length; i++) {
                var layer = template.layers[i];
                if (layer.type === 'Image') {
                    if (layer.hasMask && layer.mask) {
                        addLayerAsImagebox(layer, i, true, page);
                    } else {
                        addLayerAsImageart(layer, i, true, page);
                    }
                } else if (layer.type === 'Text') {
                    addLayerAsText(layer, true, page);
                }
            }
            // 检查素材精度
            $timeout(function () {
                currentPageElement();
                loading.close();
            }, 2000);
        }

        function addLayerAsImagebox(layer, i, isHistory, page) {
            var mask = layer.mask;
            var currentPage = page;
            var currentLevel = addLevel(currentPage);
            var maskIdentifier = mask.imageUrl;
            var imageIdentifier = layer.imageUrl;
            var offsetX = layer.geometry.x - mask.geometry.x;
            var offsetY = layer.geometry.y - mask.geometry.y;
            vm.unit = 'mm';

            currentLevel.imagebox = {
                geometry: mask.geometry,
                rotation: {
                    angle: 0
                },
                transparency: layer.opacity || 1.0,
                image: {
                    resource: {
                        identifier: imageIdentifier,
                        provider: 'qiniu',
                    },
                    width: layer.geometry.width,
                    height: layer.geometry.height,
                    offsetx: offsetX,
                    offsety: offsetY,
                    scale: layer.geometry.width/mask.geometry.width || 1
                },
                mask: {
                    resource: {
                        identifier: maskIdentifier,
                        provider: 'qiniu'
                    },
                    width: mask.geometry.width,
                    height: mask.geometry.height
                },
                border: {
                    shape: 'Rectangle',
                    radius: {
                        lt: 0,
                        rt: 0,
                        rb: 0,
                        lb: 0,
                        all: 0,
                        filletType: 'Fixed'
                    },
                    "edge": {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                    lineWidth: 0,
                    type: "Color",
                    lineType: "solid",
                    resource: {
                        "identifier": ''
                    },
                    color: '#000000',
                },
                shadow: {
                    color: '#777777',
                    x: 0,
                    y: 0,
                    blur: 0
                },
                imgUrl: imageIdentifier || 'content/images/mask_souce2.png',
                maskUrl: maskIdentifier || 'content/images/mask_souce2.png',
                bgImg: '',
                unit: 'mm',
                levelI: $scope.template.levelI,
                pageI: $scope.template.pageI,
                current: 'imagebox',
                lock: false
            };
            if (currentLevel.imagebox.image && currentLevel.imagebox.image.resource.identifier) {
                imageboxImg1(currentLevel.imagebox, layer.geometry.width, layer.geometry.height);
                if(currentLevel.imagebox.image.offsetx>0){currentLevel.imagebox.image.offsetx=0};
                if(currentLevel.imagebox.image.offsety>0){currentLevel.imagebox.image.offsety=0};
                if(currentLevel.imagebox.image.offsetx*currentLevel.imagebox.image.scale<(currentLevel.imagebox.geometry.width-currentLevel.imagebox.image.width*currentLevel.imagebox.image.scale)){
                    currentLevel.imagebox.image.offsetx=(currentLevel.imagebox.geometry.width-currentLevel.imagebox.image.width*currentLevel.imagebox.image.scale)/currentLevel.imagebox.image.scale
                }
                if(currentLevel.imagebox.image.offsety*currentLevel.imagebox.image.scale<(currentLevel.imagebox.geometry.height-currentLevel.imagebox.image.height*currentLevel.imagebox.image.scale)){
                    currentLevel.imagebox.image.offsety=(currentLevel.imagebox.geometry.height-currentLevel.imagebox.image.height*currentLevel.imagebox.image.scale)/currentLevel.imagebox.image.scale
                }
            }
            // imageboxResourceCanvas(currentLevel.imagebox, function () {
                currentLevel.seq = i - 0 + 1;
                $scope.currentPage = currentPage;
                $scope.RightPanel = 'material';
                $scope.selectBox = currentLevel.imageart;
                $scope.template.transparency = 100;
                // if(!isHistory){
                    setTimeout(function () {
                        updateHistorys();
                        $scope.$digest();
                    }, 1000);
                // }
            // });

        }
        function imageboxImg1(imagebox, imgW, imgH) {
            var imgR = imagebox.geometry.width / imagebox.geometry.height;
            var imgR1 = imgW / imgH;
            if (imgR >= imgR1) {
                imagebox.image.resource.adaptation = 'Height';
                imagebox.image.width = imagebox.geometry.width;
                imagebox.image.height = imagebox.geometry.width / imgW * imgH;
            } else {
                imagebox.image.resource.adaptation = 'Width';
                imagebox.image.width = imagebox.geometry.height / imgH * imgW;
                imagebox.image.height = imagebox.geometry.height;
            }
        }

        function addLayerAsImageart(layer, i, isHistory, page) {
            var currentPage = page;
            var currentLevel = addLevel(currentPage);
            var identifier = layer.imageUrl;
            vm.unit = 'mm';
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = identifier + '?imageView2';
            newImg.onload = function () {
                newImg.onload = null;
                var width = newImg.width;
                var height = newImg.height;
                var arr = ThreeHundredDpiToMm(width, height);

                var bgImgR = width / height;
                var _imageart = {};
                if (bgImgR >= 1) {
                    if (width > currentPage.trimbox.width) {
                        _imageart = {
                            width: currentPage.trimbox.width - 0,
                            height: currentPage.trimbox.width / width * height,
                            x: currentPage.trimbox.x - 0,
                            y: currentPage.trimbox.y - 0
                        }
                    } else {
                        _imageart = {
                            height: height,
                            width: width,
                            x: currentPage.trimbox.x - 0,
                            y: currentPage.trimbox.y - 0
                        }
                    }
                } else {
                    if (height > currentPage.trimbox.height) {
                        _imageart = {
                            height: currentPage.trimbox.height - 0,
                            width: currentPage.trimbox.height / height * width,
                            x: currentPage.trimbox.x - 0,
                            y: currentPage.trimbox.y - 0
                        }
                    } else {
                        _imageart = {
                            height: height,
                            width: width,
                            x: currentPage.trimbox.x - 0,
                            y: currentPage.trimbox.y - 0
                        }
                    }
                }
                $scope.pageValue.imageart = {
                    height: PxTurnMm(_imageart.height),
                    width: PxTurnMm(_imageart.width),
                    x: PxTurnMm(_imageart.x),
                    y: PxTurnMm(_imageart.y)
                }

                currentLevel.imageart = ({
                    geometry: layer.geometry,
                    rotation: {
                        angle: 0
                    },
                    transparency: layer.opacity || 1.0,
                    resource: {
                        identifier: identifier,
                        provider: 'qiniu',
                        width: width,
                        height: height,
                    },
                    scale: 1,
                    onlyshow: false,
                    movable: true,
                    editable: true,
                    bgImg: '',
                    offsetx: 0,
                    offsety: 0,
                    unit: 'mm',
                    // index: currentPage.imagearts.imageart.length,
                    levelI: i,
                    pageI: $scope.template.pageI,
                    current: 'imageart',
                    lock: false
                });

                currentLevel.seq = i - 0 + 1;
                // if(!isHistory){
                    updateHistorys();
                // }
                $scope.currentPage = currentPage;
                $scope.RightPanel = 'material';
                $scope.selectBox = currentLevel.imageart;
                $scope.template.transparency = 100;
                $scope.$digest();
            };
        }

        function checkFontBold(font, flag) {
            if (!font || !font.fontStyles) {
                return false;
            }
            var supportNormal = false;
            var supportBold = false;
            var supportItalic = false;
            for (var i = 0; i < font.fontStyles.length; i++) {
                var s = font.fontStyles[i];
                if (s.style == "NORMAL") {
                    supportNormal = true;
                } else if (s.style == "BOLD") {
                    supportBold = true;
                } else if (s.style == "ITALIC") {
                    supportItalic = true;
                } else if (s.style == "BOLD_ITALIC") {
                    supportItalic = true;
                    supportBold = true;
                }
            }
            if (flag && supportBold) {
                return true;
            }
            if (!supportNormal && !supportItalic && supportBold) {
                // 该字体只支持bold
                return true;
            }
            return false;
        }

        function checkFontItalic(font, flag) {
            if (!font || !font.fontStyles) {
                return false;
            }
            var supportNormal = false;
            var supportBold = false;
            var supportItalic = false;
            for (var i = 0; i < font.fontStyles.length; i++) {
                var s = font.fontStyles[i];
                if (s.style == "NORMAL") {
                    supportNormal = true;
                } else if (s.style == "BOLD") {
                    supportBold = true;
                } else if (s.style == "ITALIC") {
                    supportItalic = true;
                } else if (s.style == "BOLD_ITALIC") {
                    supportItalic = true;
                    supportBold = true;
                }
            }
            if (flag && supportItalic) {
                return true;
            }
            if (!supportNormal && !supportBold && supportItalic) {
                // 该字体只支持斜体
                return true;
            }
            return false;
        }

        function addLayerAsText(layer, isHistory, page) {
            var currentPage = page;
            var currentLevel = addLevel(currentPage);

            $scope.RightPanel = 'text';

            // vm.text = '请输入文字';
            $scope.size = layer.style.size;

            var font = vm.defaultFont;
            var fontBold = checkFontBold(font, layer.style.bold);
            var fontItalic = checkFontItalic(font, layer.style.italic);
            currentLevel.textbox = ({
                transparency: layer.opacity || 1.0,
                geometry: {
                    x: layer.geometry.x,
                    y: layer.geometry.y,
                    width: layer.geometry.width,
                    height: layer.geometry.height
                },
                rotation: layer.rotation,
                style: {
                    font: font.family,
                    size: layer.style.size,
                    color: layer.style.color,
                    bold: fontBold,
                    italic: fontItalic,
                    lineheight: layer.style.lineheight,
                    letterspacing: layer.style.letterspace,
                    align: layer.style.align || 'left',
                    writingmode: layer.style.writemode
                },
                fontuuid: font.uuid,
                identifier: vm.url + font.fontStyles[0].identifier,
                editable: true,
                movable: true,
                version: '2.0',
                text: layer.value,
                unit: 'mm',
                // index: currentPage.textboxes.textbox.length,
                levelI: $scope.template.levelI,
                pageI: $scope.template.pageI,
                current: 'textbox',
                lock: false
            });

            var textbox = currentLevel.textbox;
            FontManagement.fontSupport({
                uuid: font.uuid,
                bold: fontBold,
                italic: fontItalic,
                content: layer.value
            }, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    font.success = msg.success;
                    if (msg.success && !msg.supported) {
                        var message = "【" + msg.failed + "】" + "当前字体不支持这些文字！"
                        MessageService.error(message);
                        textbox.characterWarning = true;
                        textbox.characterMessage = message;
                    }
                }
            });

            currentLevel.seq = currentPage.levels.level.length;

            getFont(currentLevel.textbox,FontManagement);
            $(".block-width,.block-height").css({left: 0});
            $scope.template.letterspacing = $scope.template.lineheight = 0;

            $(".space-slider").addClass('hide1');
            $(".nav_spacing").removeClass('nav-spacing-active');

            vm.fontHide = currentLevel.textbox;
            fontStyle(currentLevel.textbox);
            // $timeout(function () {
            //     var pro = currentPage.scale / currentPage.proportion;
            //     currentLevel.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width() / pro) + Number(10);
            //     currentLevel.textbox.geometry.height = Number($("#c-xml-page-material-fontId").height() / pro) + Number(5);
            // if(!isHistory){
                updateHistorys();
            // }
            $scope.currentPage = currentPage;
            $scope.selectBox = currentLevel.textbox;

            if (vm.unit == 'mm') {
                $scope.pageValue.textbox = {
                    width: PxTurnMm(currentLevel.textbox.geometry.width),
                    height: PxTurnMm(currentLevel.textbox.geometry.height),
                    x: PxTurnMm(currentPage.trimbox.x - 0 + 20 + $scope.template.levelI * 2),
                    y: PxTurnMm(currentPage.trimbox.y - 0 + 20 + $scope.template.levelI * 2)
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.textbox = currentLevel.textbox.geometry;
            } else if (vm.unit == 'In') {
                $scope.pageValue.textbox = {
                    width: PxTurnIn(currentLevel.textbox.geometry.width),
                    height: PxTurnIn(currentLevel.textbox.geometry.height),
                    x: PxTurnIn(currentPage.trimbox.x - 0 + 20 + $scope.template.levelI * 2),
                    y: PxTurnIn(currentPage.trimbox.y - 0 + 20 + $scope.template.levelI * 2)
                }
            }
            // })
            $scope.item = vm.fontList[0];
        }

        /********************************************PSD操作**********************end*************************************/


        /********************************************重复生成**********************start*************************************/

        $scope.generBox = function (e, box) {
            fn(e)
            if (!box || !$scope.RightPanel) {
                MessageService.error("请先选择一个图片或者素材！");
                return
            }
            if (box.current != 'imagebox' && box.current != 'imageart' && box.current != 'calMonthBox' && box.current != 'calTitleBox') {
                MessageService.error("请选择一个图片、素材、日历、日历标题");
                return;
            }
            if (box.lock) {
                MessageService.error("请先解锁");
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/box-copy.html',
                controller: 'BoxCopyController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function () {
                        return {
                            box: box,
                            pageI: $scope.template.pageI
                        };
                    }]
                }
            }).result.then(function (data) {
                var box2 = angular.copy(box);
                if (data) {
                    //从当前页：this  ;   从第一页：one
                    var index = 0, arrI = 1;
                    if (data.range == 'this') {
                        index = $scope.template.pageI;
                    }

                    for (var i = index; i < $scope.template.pages.page.length; i++) {
                        var page = $scope.template.pages.page[i];
                        if(page.config.partid == $scope.currentPage.config.partid){
                            if (!page.levels.level) {
                                page.levels.level = [];
                            }
                            var arr = {
                                seq: page.levels.level.length - 0 + 1,
                                imagebox: null,
                                textbox: null,
                                imageart: null,
                                textdocument: null,
                                calMonthBox: null,
                                calTitleBox: null,
                                variable: false
                            }
                            arr[box.current] = box2;
                            arr[box.current].pageI = i;
                            arr[box.current].levelI = page.levels.level.length;

                            //从第一页开始,全部增加
                            if (data.generLoc == 'all') {
                                if (i != $scope.template.pageI) {
                                    var arr2 = angular.copy(arr)
                                    if(box.current == 'calMonthBox' || box.current == 'calTitleBox'){
                                        arr2[box.current].obj=null;
                                        arr2[box.current].sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                                        arr2[box.current].sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                                        if(box.current == 'calMonthBox'){
                                            arr2[box.current].width=arr2[box.current].geometry.width;
                                            arr2[box.current].height=arr2[box.current].geometry.height;
                                        }
                                    }
                                    page.levels.level.push(arr2);
                                    page.updateThumbnail=true;
                                }
                            } else if (data.generLoc == 'next') {
                                var boxSeq = $scope.template.pages.page[$scope.template.pageI].seq;
                                if (i != $scope.template.pageI) {
                                    if (page.seq % 2 == boxSeq % 2) {
                                        var arr2 = angular.copy(arr)
                                        if(box.current == 'calMonthBox' || box.current == 'calTitleBox'){
                                            arr2[box.current].obj=null;
                                            arr2[box.current].sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                                            arr2[box.current].sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                                            if(box.current == 'calMonthBox'){
                                                arr2[box.current].width=arr2[box.current].geometry.width;
                                                arr2[box.current].height=arr2[box.current].geometry.height;
                                            }
                                        }
                                        page.levels.level.push(arr2);
                                        page.updateThumbnail=true;
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }

        /********************************************重复生成**********************end*************************************/


        /********************************************标准模板**********************start*************************************/

        function loadProductTemplate(tempCatalogId, pageIndex) {
            var items = [
                {key: "status", op: "=", value: true},
                {key: "cancelled", op: "=", value: "false"},
                {key: "type", op: "=", value: vm.page.productType},
                {key: "version", op: ">", value: 1},
                ];
            items.push({key: "catalog.id", op: "=", value: tempCatalogId})
            ProductTemplate.byCondition({
                items: items,
                page: pageIndex,
                size: 20,
                sort: ["seq,asc"]
            }, function (data, headers) {
                vm.productTemplatesCount = headers('X-Total-Count');
                if (data && data.length) {
                    angular.forEach(data, function (arr, i) {
                        vm.productTemplates.push(arr);
                    })
                }
            })
        }

        $scope.selectTempLabel = function (label, index) {
            vm.tempCatalogId = label.id;
            vm.proTempIndexNum = 0;
            vm.productTemplates = [];
            // if (!label.firstClick) {
            //     vm.loading = true;
            //     label.firstClick = true;
            // }

            loadProductTemplate(vm.tempCatalogId, vm.proTempIndexNum)
            $timeout(function () {
                // vm.loading = false;
                var h = $scope.dHeight - $(".c-productTemplate-view1").height() - 100;
                $(".productTemplate-con1").height(h);
            }, 1500)
        };

        $scope.showTempLabels = false;
        $scope.showTempMoreLabels = function () {
            $scope.showTempLabels = !$scope.showTempLabels;
            setTimeout(function () {
                var h = $scope.dHeight - $(".c-productTemplate-view1").height() - 100;
                $(".productTemplate-con1").height(h);
            }, 800);
        }

        $scope.loadMoreProductTemplates = function () {
            if (vm.productTemplates.length >= vm.productTemplatesCount) {
                MessageService.error("已全部加载完成");
                return
            } else if (vm.productTemplates.length == 0) {
                vm.proTempIndexNum = -1;
            }
            ++vm.proTempIndexNum;

            loadProductTemplate(vm.tempCatalogId, vm.proTempIndexNum)
        }

        vm.proTempPage = null;
        vm.changeProTempSure = false;
        vm.changeProTempLen = $scope.template.pages.page.length;
        var proTempTips = ['确认要更换模板吗？若尺寸不一致，系统将根据画布尺寸自动缩放','系统检测到您选择替换的模版页数大于当前模版页数','系统检测到您选择替换的模版页数少于当前模版页数']
        $scope.changeProductTemplate = function (item, index) {
            vm.proTempPage = angular.copy(item);
            vm.changeProTempLen = $scope.template.pages.page.length;
            if (item.pageCount == $scope.template.pages.page.length) {
                vm.proTempTitle = proTempTips[0];
                vm.proTempText = null;
            } else if (item.pageCount > $scope.template.pages.page.length) {
                vm.proTempTitle = proTempTips[1];
                vm.proTempText = '，系统将默认应用至新模板前' + $scope.template.pages.page.length + '页';
            } else if (item.pageCount < $scope.template.pages.page.length) {
                vm.proTempTitle = proTempTips[2];
                vm.proTempText = '，系统将默认保留前' + (item.pageCount) + '页'
                vm.changeProTempLen = ($scope.template.pages.page.length - item.pageCount);
            }
            vm.changeProTempSure = true;
        }

        $scope.exitChangeProTemp = function () {
            var pagesData = angular.copy($scope.template.pages.page);
            vm.changeProTempSure = false;
            vm.operation = true;
            var _template = {
                "addpage":false,
                "addunit":0,
                "maxpage":0,
                "minpage":0,
                "replace":"",
                "cover":"",
                backupPages:{
                    list:[]
                },
                config:{
                    innerOffset:0,
                    parts:{
                        list:[]
                    },
                    productType:''
                },
                deletedPages:{
                    page:[]
                },
                "frameConfigs":{
                    "frameConfig":[]
                },
                pages: {
                    page: []
                },
                "version":"4.0",
                transparency: 1,
                scene: null,
                pageI: 0,
                angle: 0,
                backup: false,
                spread: false,
                unreplace: false,
                letterspacing: 0,
                lineheight: 0,
                text: '',
                bgimgs: {bgimg: []},
                bgimgs2: {bgimg: []},
                masks: {mask: []},
                cliparts: {clipart: []},
                documentpics: {documentpic: []},
                showPic: false,
                psdFiles: {psdFile: []}
            };
            var _seq = 0;
            vm.proTempPage.parts.sort(compare('seq', true));
            vm.proTempPage.parts.forEach(function (part,index) {
                var editboxes = {
                    editbox:[{
                        height: part.pageEditH,
                        width: part.pageEditW,
                        x: part.pageEditX,
                        y: part.pageEditY
                    }]
                };
                _template.config.parts.list.push({
                    "bleed":{
                        "bottom":part.pageBloodB,
                        "left":part.pageBloodL,
                        "right":part.pageBloodR,
                        "top":part.pageBloodT
                    },
                    "editboxes":editboxes,
                    "mediabox":{
                        height: part.pageSizeH,
                        width: part.pageSizeW,
                    },
                    "counter":part.pageCount,
                    "id":part.code,
                    "name":part.name,
                    "required":(part.code == 'P' || part.code == 'P0') ? true : false,
                    "seq":index,
                    "spread":part.spread
                })
                var _page = createPage1(vm.proTempPage, part, editboxes);
                for(var i=0;i<part.pageCount; i++){
                    _page.uuid = UidService.get();
                    _page.seq = _seq;
                    if(vm.proTempPage.type == 'BOOK'){
                        _template.pages.page.push(angular.copy(_page));
                        _seq++
                    }
                    if(vm.proTempPage.type == 'PHOTO'){
                        _template.pages.page.push(angular.copy(_page));
                        _seq++
                    }
                    if(vm.proTempPage.type == 'CALENDAR'){
                        if(vm.proTempPage.pageCount == 14){
                            for(var i=0; i<(vm.proTempPage.pageCount-1); i++){
                                _page.config.monthIndex=i;
                            }
                        }
                        if(vm.proTempPage.pageCount == 26){
                            for(var i=0; i<(vm.proTempPage.pageCount-1); i++){
                                _page.config.monthIndex=i%2 === 1 ? Math.ceil(i/2) : 0;
                                _template.pages.page.push(angular.copy(_page));
                                _seq++
                            }
                        }
                    }
                    if(vm.proTempPage.type == 'THING'){
                        _template.pages.page.push(angular.copy(_page));
                        _seq++;
                    }
                }
            })
            vm.hashtags = '';
            if(vm.proTempPage.catalog.hashtags){
                for (var i = 0; i < vm.proTempPage.catalog.hashtags.length; i++) {
                    vm.hashtags += vm.proTempPage.catalog.hashtags[i].name + ','
                }
            }
            _template.config.innerOffset = vm.proTempPage.innerOffset;
            _template.config.productType = vm.proTempPage.type;
            _template.config.dynamic = vm.proTempPage.dynamic;
            if(_template.config.dynamic){
                _template.config.startYear = (vm.proTempPage.month.split("-"))[0];
                _template.config.startMonth = (vm.proTempPage.month.split("-"))[1];
            }
            _template.cover = vm.proTempPage.cover;
            _template.replace = vm.proTempPage.replace;
            _template.addpage = vm.proTempPage.addpage;
            _template.minpage = vm.proTempPage.minpage;
            _template.maxpage = vm.proTempPage.maxpage;
            _template.addunit = vm.proTempPage.addunit;
            var _resp = {
                document: _template,
                replace: vm.proTempPage.replace,
                name: vm.proTempPage.name,
                catalogId: vm.proTempPage.catalog.id,
                catalogName: vm.proTempPage.catalog.name,
                version: '4.0',
                type: 'XML',
                productTemplateId: vm.proTempPage.id,
                hashtags: vm.hashtags,
                modelBlendent: vm.proTempPage.modelBlendent,
                modelData: vm.proTempPage.modelData,
                modelPath: vm.proTempPage.modelPath,
                modelType: vm.proTempPage.modelType,
                productType: vm.proTempPage.type
            }
            vm.changeProTempSure = false;
            vm.loadingImg = true;
            getDocumentXml(_resp,function () {
                $scope.template.pages.page.forEach(function (page, index) {
                    if(pagesData[index]){
                        page.background = angular.copy(pagesData[index].background);
                        page.levels = angular.copy(pagesData[index].levels);
                        page.qrcode = angular.copy(pagesData[index].qrcode);
                        page.barcode = angular.copy(pagesData[index].barcode);
                            (function (index1) {
                                if($scope.template.pages.page[index1].background.type == 'Pic' && $scope.template.pages.page[index1].background.resource && $scope.template.pages.page[index1].background.resource.identifier){
                                    var background = $scope.template.pages.page[index1].background;
                                    var pageW = $scope.template.pages.page[index1].mediabox.width,
                                        pageH = $scope.template.pages.page[index1].mediabox.height;
                                    getBackgroundSource(background.resource, pageW, pageH, background)
                                }
                            })(index)
                    }
                })
            })
        }
        function createPage1(data, part, editboxes) {
            var _page = {
                config:{
                    "dynamic":(data.dynamic ? true : false),
                    "monthIndex":data.monthIndex || 0,
                    "monthMultiple":false,
                    "partid":part.code
                },
                isScene: false,
                bgImg: '',
                background: {resource: {},color:'#ffffff', type: "Color"},
                pagecover: {},
                levels: {},
                barcode: {},
                qrcode: {},
                editbox: editboxes.editbox[0],
                editboxes: editboxes,
                mediabox: {
                    height: part.pageSizeH,
                    width: part.pageSizeW,
                },
                trimbox: {
                    height: part.pageSizeH - Number(part.pageBloodB) - Number(part.pageBloodT),
                    width: part.pageSizeW - Number(part.pageBloodL) - Number(part.pageBloodR),
                    x: part.pageBloodL,
                    y: part.pageBloodT
                },
                scale: 1,
                proportion: 1,
                seq: 0,
                spread: part.spread,
                backup: false,
                unreplace: !data.replace
            }
            if (part.mould) {
                if(part.mould.modelType == 'TWO' || part.mould.modelType == 'NONE'){
                    part.mould.modelType = 'TWO';
                    _page.scene = {
                        background: {
                            resource: {
                                identifier: part.mould.modelPic.indexOf('http') > -1 ? part.mould.modelPic : vm.domainQ + part.mould.modelPic,
                                provider: "qiniu"
                            }
                        },
                        geometry: {
                            width: part.mould.gWidth,
                            height: part.mould.gHeight,
                            x: 0,
                            y: 0
                        },
                        imagebox: {
                            geometry: {
                                width: part.mould.width,
                                height: part.mould.height,
                                x: part.mould.x,
                                y: part.mould.y
                            },
                            rotation: {angle: 0},
                            transparency: 1,
                        },
                        hierarchy: part.mould.hierarchy,
                        mouldid: part.mould.id,
                        isScene: true,
                        scale: 1,
                        unit: "mm",
                        resource:{
                            identifier: part.mould.modelPic.indexOf('http') > -1 ? part.mould.modelPic : vm.domainQ + part.mould.modelPic,
                            provider: "qiniu"
                        }
                    }
                }
            }
            return _page;
        }

        /********************************************标准模板**********************end*************************************/

        function currentPageElement() {
            $scope.pxInsufficient($scope.currentPage, 'background');
            if ($scope.currentPage && $scope.currentPage.levels && $scope.currentPage.levels.level) {
                $scope.currentPage.levels.level.forEach(function (level) {
                    if (level.imagebox && level.imagebox.current) {
                        $scope.pxInsufficient(level.imagebox, 'imagebox');
                    }
                    if (level.imageart && level.imageart.current) {
                        $scope.pxInsufficient(level.imageart, 'imageart');
                    }
                    if (level.textbox && level.textbox.current) {
                        $scope.verifyTextFont(level.textbox);
                    }
                })
            }
        }
        $scope.completeIndex = function(index) {
            return ($scope.currentPage.levels.level.length-1-index)
        }

        $scope.levelClick = function(e, level, index){
            var index = $scope.completeIndex(index)
            if (level.imagebox&&level.imagebox.current) {
                $scope.selectImg(e, index, level.imagebox)
            }
            if (level.imageart&&level.imageart.current) {
                $scope.selectArt(e, index, level.imageart)
            }
            if (level.textbox&&level.textbox.current) {
                $scope.selectText(e, index, level.textbox)
            }
            if (level.textdocument&&level.textdocument.current) {
                $scope.selectDoc(e, index, level.textdocument)
            }
            if (level.calMonthBox&&level.calMonthBox.current) {
                $scope.selectCalMonthBox(e, index, level.calMonthBox)
            }
            if (level.calTitleBox&&level.calTitleBox.current) {
                $scope.selectCalTitleBox(e, index, level.calTitleBox)
            }
            fn(e)
        }
        $scope.levelInfo = function (level, index) {
            var item = "";
            index = $scope.currentPage.levels.level.length - 1 - index;
            var i = index + 1;
            if (level.imagebox) {
                item += "相框"
            }
            if (level.imageart) {
                item += "素材"
            }
            if (level.textbox) {
                item += level.textbox.text || ""
            }
            if (level.textdocument) {
                item += "文本模板"
            }
            if (level.calTitleBox) {
                item += "日历标题"
            }
            if (level.calMonthBox) {
                item += "日历组件"
            }
            return item
        };
        $scope.warnInfo = function (level) {
            if (level) {
                if (level.imagebox) {
                    if (level.imagebox.lowAccuracy || level.imagebox.lowAccuracyMask) {
                        return true
                    } else {
                        return false
                    }
                }
                if (level.imageart) {
                    return level.imageart.lowAccuracy
                }
                if (level.textbox) {
                    return !level.textbox.success
                }
                if (level.textdocument) {
                    return level.textdocument.lowAccuracy
                }
            }
        };
        $scope.warnMessage = function (level) {
            if (level) {
                var lowAccuracyMessage = "像素精度较低";
                if (level.imagebox) {
                    if (level.imagebox.lowAccuracy || level.imagebox.lowAccuracyMask) {
                        return lowAccuracyMessage;
                    } else {
                        return ""
                    }
                }
                if (level.imageart) {
                    if (level.imageart.lowAccuracy) {
                        return lowAccuracyMessage;
                    }
                }
                if (level.textbox) {
                    if (level.textbox.characterWarning) {
                        return level.textbox.characterMessage;
                    }
                    if (level.textbox.lowAccuracy) {
                        return lowAccuracyMessage;
                    }
                }
                if (level.textdocument) {
                    if (level.textdocument.lowAccuracy) {
                        return lowAccuracyMessage;
                    }
                }
            }
            return '';
        };
        $scope.levelDelete = function (index) {
            index = $scope.currentPage.levels.level.length - 1 - index;
            $scope.currentPage.levels.level.splice(index, 1);
            updateHistorys();
        };
        $scope.clearPage = function () {
            $scope.currentPage.levels.level = [];
            $scope.currentPage.barcode = null;
            $scope.currentPage.qrcode = null;
            updateHistorys();
        }
        $scope.PxTurnMm = function (item) {
            return PxTurnMm(item)
        }
        $scope.pageLook = function (index) {
            vm.isPageThumbnail = true;
            vm.pageThumbnails = [];
            vm.pageThumbnailsIndex = null;
            $scope.template.backupPages.list.forEach(function (page, i) {
                vm.pageThumbnails.push(page)
                if (index === i) {
                    vm.pageThumbnailsIndex = vm.pageThumbnails.length - 1;
                }
            })
        }

        $scope.pageThumbnailHide = function (e) {
            e.stopPropagation();
            vm.isPageThumbnail = false;
        }
        $scope.pageThumbnailHide1 = function (e) {
            e.stopPropagation();
        }
        //元素精度检测
        $scope.pxInsufficient = function (item, type) {
            if(!vm.gitDpi){
                return
            }
            item.lowAccuracy = false;
            if (type == 'background') {
                if (item.background.type == 'Pic' && item.background.resource && item.background.resource.identifier) {
                    var _width = PxTurnMm(item.mediabox.width);
                    var _height = PxTurnMm(item.mediabox.height);
                    var _data = pxInsufficient(item.background.resource.width, item.background.resource.height, item.background.scale, _width, _height);
                    if (_data < vm.gitDpi.bgDpi - 0.01) {
                        item.lowAccuracy = true;
                    }
                }
                $timeout(function () {
                    $scope.currentPage.lowAccuracy = item.lowAccuracy;
                }, 300)
            }
            if (type == 'imageart' && $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imageart) {
                $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imageart.lowAccuracy = false;
                if (item.resource.width && item.resource.height) {
                    var _width = PxTurnMm(item.geometry.width);
                    var _height = PxTurnMm(item.geometry.height);
                    var _data = pxInsufficient(item.resource.width, item.resource.height, item.scale, _width, _height);
                    if (_data < vm.gitDpi.clipArtDpi - 0.01) {
                        item.lowAccuracy = true;
                    }
                } else {
                    imageInfo($http, item.resource, function () {
                        var _width = PxTurnMm(item.geometry.width);
                        var _height = PxTurnMm(item.geometry.height);
                        var _data = pxInsufficient(item.resource.width, item.resource.height, item.scale, _width, _height);
                        if (_data < vm.gitDpi.clipArtDpi - 0.01) {
                            item.lowAccuracy = true;
                            $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imageart.lowAccuracy = true;
                        }
                    })
                }
                var imageart = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imageart;
                $timeout(function () {
                    if (imageart) {
                        imageart.lowAccuracy = item.lowAccuracy;
                    }
                }, 300)
            }

            if (type == 'imagebox') {
                if (item && item.image && item.image.resource && item.image.resource.identifier) {
                    if (item.image.resource.width && item.image.resource.height) {
                        var _width = PxTurnMm(item.geometry.width);
                        var _height = PxTurnMm(item.geometry.height);
                        var _data = pxInsufficient(item.image.resource.width, item.image.resource.height, item.image.scale, _width, _height);
                        if (_data < vm.gitDpi.imgDpi - 0.01) {
                            item.lowAccuracy = true;
                        }
                    } else {
                        imageInfo($http, item.image.resource, function () {
                            var _width = PxTurnMm(item.geometry.width);
                            var _height = PxTurnMm(item.geometry.height);
                            var _data = pxInsufficient(item.image.resource.width, item.image.resource.height, item.image.scale, _width, _height);
                            if (_data < vm.gitDpi.imgDpi - 0.01) {
                                item.lowAccuracy = true;
                            }
                        })
                    }
                    var imagebox = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imagebox;
                    $timeout(function () {
                        if (imagebox) {
                            imagebox.lowAccuracy = item.lowAccuracy;
                        }
                    }, 300)
                }
                if (item && item.mask && item.mask.resource && item.mask.resource.identifier) {
                    item.lowAccuracyMask = false;
                    if (item.mask.resource.width && item.mask.resource.height) {
                        var _width = PxTurnMm(item.geometry.width);
                        var _height = PxTurnMm(item.geometry.height);
                        var _data = pxInsufficient(item.mask.resource.width, item.mask.resource.height, item.mask.scale, _width, _height);
                        if (_data < vm.gitDpi.maskDpi - 0.01) {
                            item.lowAccuracyMask = true;
                        }
                    } else {
                        imageInfo($http, item.mask.resource, function () {
                            var _width = PxTurnMm(item.geometry.width);
                            var _height = PxTurnMm(item.geometry.height);
                            var _data = pxInsufficient(item.mask.resource.width, item.mask.resource.height, item.mask.scale, _width, _height);
                            if (_data < vm.gitDpi.maskDpi - 0.01) {
                                item.lowAccuracyMask = true;
                            }
                        })
                    }
                    var imagebox = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imagebox;
                    $timeout(function () {
                        if (imagebox) {
                            imagebox.lowAccuracyMask = item.lowAccuracyMask;
                        }
                    }, 300)
                }
            }
        }

        //元素四角拖拽
        $scope.cursor = function (angle, source, boolean) {
            var _cursor = '';
            if (angle > 0 && !boolean) {
                angle = 360 - angle;
            }
            if (source == 'left' || source == 'right') {
                if (337 <= angle || angle < 22) {
                    _cursor = 'se-resize';
                }
                if (22 <= angle && angle < 67) {
                    _cursor = 'n-resize';
                }
                if (67 <= angle && angle < 112) {
                    _cursor = 'sw-resize';
                }
                if (112 <= angle && angle < 157) {
                    _cursor = 'e-resize';
                }
                if (157 <= angle && angle < 202) {
                    _cursor = 'se-resize';
                }
                if (202 <= angle && angle < 247) {
                    _cursor = 'n-resize';
                }
                if (247 <= angle && angle < 292) {
                    _cursor = 'sw-resize';
                }
                if (292 <= angle && angle < 337) {
                    _cursor = 'e-resize';
                }
            }
            if (source == 'top' || source == 'bottom') {
                if (337 <= angle || angle < 22) {
                    _cursor = 'sw-resize';
                }
                if (22 <= angle && angle < 67) {
                    _cursor = 'e-resize';
                }
                if (67 <= angle && angle < 112) {
                    _cursor = 'se-resize';
                }
                if (112 <= angle && angle < 157) {
                    _cursor = 'n-resize';
                }
                if (157 <= angle && angle < 202) {
                    _cursor = 'sw-resize';
                }
                if (202 <= angle && angle < 247) {
                    _cursor = 'e-resize';
                }
                if (247 <= angle && angle < 292) {
                    _cursor = 'se-resize';
                }
                if (292 <= angle && angle < 337) {
                    _cursor = 'n-resize';
                }
            }
            if (source == 'left1' || source == 'right1') {
                if (337 <= angle || angle < 22) {
                    _cursor = 'e-resize';
                }
                if (22 <= angle && angle < 67) {
                    _cursor = 'se-resize';
                }
                if (67 <= angle && angle < 112) {
                    _cursor = 'n-resize';
                }
                if (112 <= angle && angle < 157) {
                    _cursor = 'sw-resize';
                }
                if (157 <= angle && angle < 202) {
                    _cursor = 'e-resize';
                }
                if (202 <= angle && angle < 247) {
                    _cursor = 'se-resize';
                }
                if (247 <= angle && angle < 292) {
                    _cursor = 'n-resize';
                }
                if (292 <= angle && angle < 337) {
                    _cursor = 'sw-resize';
                }
            }
            if (source == 'top1' || source == 'bottom1') {
                if (337 <= angle || angle < 22) {
                    _cursor = 'n-resize';
                }
                if (22 <= angle && angle < 67) {
                    _cursor = 'sw-resize';
                }
                if (67 <= angle && angle < 112) {
                    _cursor = 'e-resize';
                }
                if (112 <= angle && angle < 157) {
                    _cursor = 'se-resize';
                }
                if (157 <= angle && angle < 202) {
                    _cursor = 'n-resize';
                }
                if (202 <= angle && angle < 247) {
                    _cursor = 'sw-resize';
                }
                if (247 <= angle && angle < 292) {
                    _cursor = 'e-resize';
                }
                if (292 <= angle && angle < 337) {
                    _cursor = 'se-resize';
                }
            }
            return _cursor
        }

        //备用页
        $scope.backupPartCb = function(item){
            vm.backupPartId = item.id;
        }

        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ? (_radius + Number(imagebox.border.lineWidth)/2) * ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                } else {
                    return 0
                }
            } else {
                return 0
            }
        }

        $scope.imageboxWRadius2 = function (imagebox, radius) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                } else {
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? _r + Number(imagebox.border.lineWidth) / 2 : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius3 = function (imagebox, radius) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                } else {
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth) / 2) > 0 ? _r - Number(imagebox.border.lineWidth) / 2 : 0
                }
            } else {
                return 0
            }
        }
        $scope.borderRadius = function () {
            $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imagebox.border.radius = angular.copy(vm.selectElementCopy.imagebox.border.radius);
        }
        vm.borderRadiusAllbtn = true
        $scope.borderRadiusAll = function () {
            vm.selectElementCopy.imagebox.border.radius.lt = vm.selectElementCopy.imagebox.border.radius.all;
            vm.selectElementCopy.imagebox.border.radius.rt = vm.selectElementCopy.imagebox.border.radius.all;
            vm.selectElementCopy.imagebox.border.radius.rb = vm.selectElementCopy.imagebox.border.radius.all;
            vm.selectElementCopy.imagebox.border.radius.lb = vm.selectElementCopy.imagebox.border.radius.all;
            $scope.borderRadius();
        }
        $scope.borderRadiusAllbtn = function () {
            vm.borderRadiusAllbtn = true;
            vm.selectElementCopy.imagebox.border.radius.rt = vm.selectElementCopy.imagebox.border.radius.lt;
            vm.selectElementCopy.imagebox.border.radius.rb = vm.selectElementCopy.imagebox.border.radius.lt;
            vm.selectElementCopy.imagebox.border.radius.lb = vm.selectElementCopy.imagebox.border.radius.lt;
            vm.selectElementCopy.imagebox.border.radius.all = vm.selectElementCopy.imagebox.border.radius.lt;
            $scope.borderRadius();
        }

        $scope.changeFilletType = function(type){
            if (vm.selectElementCopy.imagebox.border.radius.filletType == type) {
                return
            }
            vm.selectElementCopy.imagebox.border.radius.filletType = type;
            switchToRoundedMode(vm.selectElementCopy.imagebox,vm.borderRadiusAllbtn);//切换圆角显示模式
            $scope.borderRadius();
        }

        vm.showBorderLabels = false;
        vm.chooseBorderClassId = null;
        $scope.showLabels = false;
        // 切换
        $scope.chooseBorderTab = function (attr) {
            if (vm.borderTab == attr) {
                return
            }
            vm.borderTab = attr;
            vm.chooseBorderClassId = null;
            vm.showBorderLabels = false;
            vm.frameConfigs = [];
            vm.borderItems = [];
            vm.borderPage = 0;
            $timeout(function () {
                vm.borderListHeight = $scope.dHeight - $('#borderLabelsHeight').height() - 50 - 40;
            })
            if (vm.borderTab == 'USER') {
                $scope.borderLabels = vm.myBorderClass;
                getUserBorders()
            }
            if (vm.borderTab == 'SYSTEM') {
                $scope.borderLabels = vm.systemBorderClass;
                getSystemBorders()
            }
            if (vm.borderTab == 'RECOMMEND') {
                vm.frameConfigs = $scope.template.frameConfigs.frameConfig;
            }

        }

        vm.frameConfigs = [];
        vm.borderListHeight = 0;
        vm.borderPage = 0;
        vm.borderSize = 20;
        vm.borderItems = [];
        vm.borderDataCount = 0;
        function getSystemBorders() {
            vm.borderItems.push({key: "enable", op: "=", value: true})
            FrameConfig.byCondition({
                items: vm.borderItems,
                sort: ["id,desc"],
                size: vm.borderSize,
                page: vm.borderPage,
            }, function (res,headers) {
                vm.borderDataCount = headers('X-Total-Count');
                if (res.message instanceof Array) {
                    res.message.forEach(function (item) {
                        if($scope.template.frameConfigs && $scope.template.frameConfigs.frameConfig && $scope.template.frameConfigs.frameConfig.length>0){
                            for(var i=0; i<$scope.template.frameConfigs.frameConfig.length; i++){
                                if($scope.template.frameConfigs.frameConfig[i].id == item.id){
                                    item.isRecommend = true;
                                    break
                                }
                            }
                        }
                        vm.frameConfigs.push(item)
                    })
                    vm.borderListHeight = $scope.dHeight - $('#borderLabelsHeight').height() - 50 - 40;
                }


            })
        }

        function getUserBorders() {
            vm.borderItems.push({key: "enable", op: "=", value: true})
            FrameConfig.byCondition1({
                items: vm.borderItems,
                sort: ["id,desc"],
                size: vm.borderSize,
                page: vm.borderPage,
            }, function (res,headers) {
                vm.borderDataCount = headers('X-Total-Count');
                if (res.message instanceof Array) {
                    res.message.forEach(function (item) {
                        if ($scope.template.frameConfigs && $scope.template.frameConfigs.frameConfig && $scope.template.frameConfigs.frameConfig.length > 0) {
                            $scope.template.frameConfigs.frameConfig.forEach(function (recommend) {
                                if (recommend.id == item.id) {
                                    item.isRecommend = true;
                                }
                            })
                        }
                        vm.frameConfigs.push(item)
                    })
                }
            })
        }

        $scope.showMoreLabels = function () {
            $scope.showLabels = !$scope.showLabels;
        }
        $scope.selectBorderLabelAll = function () {
            vm.chooseBorderClassId = null;
            vm.frameConfigs = [];
            vm.borderItems = [];
            if (vm.borderTab == 'SYSTEM') {
                getSystemBorders()
            }

            if (vm.borderTab == 'USER') {
                getUserBorders()
            }
        }
        // 点击分类
        $scope.selectBorderLabel = function (label, index) {
            vm.chooseBorderClassId = label.id;
            vm.frameConfigs = [];
            vm.borderItems = [];
            vm.borderPage = 0;
            vm.borderItems.push({key: "themeClassify.id", op: "=", value: label.id})
            if (vm.borderTab == 'SYSTEM') {
                getSystemBorders()
            }

            if (vm.borderTab == 'USER') {
                getUserBorders()
            }
        };

        // 计算边框内容的高度
        $scope.showMoreBorderLabels = function () {
            vm.showBorderLabels = !vm.showBorderLabels;
            $timeout(function () {
                vm.borderListHeight = $scope.dHeight - $('#borderLabelsHeight').height() - 110;
            })
        }

        // 点击加载更多
        $scope.loadMoreBorder = function () {
            if (vm.borderDataLength < vm.borderSize) {
                MessageService.error('没有更多的边框了')
                return
            }
            vm.borderPage++;

            if (vm.borderTab == 'SYSTEM') {
                getSystemBorders()
            }

            if (vm.borderTab == 'USER') {
                getUserBorders()
            }
        }
        $scope.recommendBorder = function (item) {
            var recommendBorder = $scope.template.frameConfigs.frameConfig || [];
            item.isRecommend = !item.isRecommend;
            if (item.isRecommend) {
                recommendBorder.push(item);
            } else {
                recommendBorder.some(function (recommend, i) {
                    if (recommend.id == item.id) {
                        recommendBorder.splice(i, 1)
                        return
                    }
                })
            }
        }
        $scope.canvasCb = function (e) {
            $scope.showCut = false;
            vm.isImageartTrim = false;
            $scope.imageboxFalseClick();
            fn(e)
        }
        $scope.lineTypeChenge = function (lineType) {
            $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imagebox.border.lineType = lineType;
        }
        $scope.dropShadow = function (imagebox, radius) {
            if (imagebox == null || imagebox.shadow == null) {
                return
            }
            var width = $scope.imageboxW(imagebox) * radius;
            var height = $scope.imageboxH(imagebox) * radius;
            return dropShadow(imagebox, width, height)
        }

        function thumbnailatorPic() {
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics = [];
                if (values) {
                    //for (var j = 0; j < values.length; j++) {
                        //var value = values[j];
                        //var data = {key: value.key};
                        if (vm.uploadType == "BG"){
                            var len = 0;
                            if (vm.showBackgrounds && vm.showBackgrounds.length) {
                                len = vm.showBackgrounds.length;
                            }
                            for (var i = 0; i < values.length; i++) {
                                getBackground(values, i, len);
                            }
                        }else if (vm.uploadType == "MASK"){
                            for (var i = 0; i < values.length; i++) {
                                getMask(values,i);
                            }
                        }else if (vm.uploadType == "CLIPART"){
                            for (var i = 0; i < values.length; i++){
                                var len = vm.showCliparts.clipart.length;
                                getArtImg(values, i, len);
                                vm.clipartTab = 'all';
                            }
                        }else if (vm.uploadType == "BgImg"){
                            var len = $scope.template.bgimgs.bgimg.length;
                            for (var i = 0; i < values.length; i++) {
                                getBgImg(values,i,len);
                            }
                        }else if (vm.uploadType == "UserImg"){
                            for (var i = 0; i < values.length; i++) {
                                getUserImg(values,i);
                            }
                        }
                    //}
                    vm.picLoading = false;
                }
                vm.uploadType = "";
            }, function () {
                vm.picLoading = false;
            });false
        }
        // 画布位置X
        $scope.canvasCoordX = function (width){
            if (!width) {
                return 0;
            }
            vm.pageCenterWidth = $('#pageCenterSize').width();
            var x = (vm.pageCenterWidth-(width || 0))/2 + vm.moveCanvasObj.x;
            return x;
        }
        // 画布位置Y
        $scope.canvasCoordY = function (height){
            if (!height) {
                return 0;
            }
            vm.pageCenterHeight = $('#pageCenterSize').height();
            var y = (vm.pageCenterHeight-(height || 0))/2  + vm.moveCanvasObj.y;
            return y;
        }
        vm.getAllCtrlKeyArray = getAllCtrlKeyArray;
        $scope.singleBoxAlignment = function (attr) {
            var all = getAllCtrlKeyArray();
            if(all.length >= 2){
                $scope.boxAlignment(attr)
                return
            }
            if (!$scope.selectBox.geometry) {
                MessageService.error("请选择一个元素");
                return
            }
            if ($scope.selectBox.lock) {
                MessageService.error("请先解锁该元素");
                return
            }
            if (attr == 'left') {
                $scope.selectBox.geometry.x = elementAlign($scope.selectBox.geometry , $scope.selectBox.rotation.angle , 'left' , $scope.template.pages.page[$scope.template.pageI].mediabox.width , $scope.template.pages.page[$scope.template.pageI].mediabox.height);
            } else if (attr == 'right') {
                $scope.selectBox.geometry.x = elementAlign($scope.selectBox.geometry , $scope.selectBox.rotation.angle , 'right' , $scope.template.pages.page[$scope.template.pageI].mediabox.width , $scope.template.pages.page[$scope.template.pageI].mediabox.height);
            } else if (attr == 'top') {
                $scope.selectBox.geometry.y = elementAlign($scope.selectBox.geometry , $scope.selectBox.rotation.angle , 'top' , $scope.template.pages.page[$scope.template.pageI].mediabox.width , $scope.template.pages.page[$scope.template.pageI].mediabox.height);
            } else if (attr == 'bottom') {
                $scope.selectBox.geometry.y = elementAlign($scope.selectBox.geometry , $scope.selectBox.rotation.angle , 'bottom' , $scope.template.pages.page[$scope.template.pageI].mediabox.width , $scope.template.pages.page[$scope.template.pageI].mediabox.height);
            } else if (attr == 'vertical') {
                $scope.selectBox.geometry.y = ($scope.template.pages.page[$scope.template.pageI].mediabox.height - $scope.selectBox.geometry.height) / 2;
            } else {
                $scope.selectBox.geometry.x = ($scope.template.pages.page[$scope.template.pageI].mediabox.width - $scope.selectBox.geometry.width) / 2;
            }
            updateHistorys()
        }

        //备用页拖拽standbyPage
        var standbyPageSort = null;
        var oldPageIndex = null;
        var newPageIndex = null;
        standbyPageDrag();
        function standbyPageDrag() {
            $timeout(function () {
                standbyPageSort = new Sortable(document.getElementById('standbyPage'), {
                    swap: false,
                    filter: '.filtered',
                    cancel: ".notDrag",
                    preventOnFilter: true,
                    animation: 150,
                    onStart: function (/**Event*/evt) {
                        // 拿到当前拖拽的pageIndex
                        oldPageIndex = evt.oldIndex+$scope.template.backupPages.list.length - $('#standbyPage').find(".standbyPageLi").length;
                    },
                    onEnd: function (/**Event*/evt) {
                        vm.loadingImg = true;
                        // 拿到当前拖拽后的pageIndex
                        newPageIndex = evt.newIndex+$scope.template.backupPages.list.length - $('#standbyPage').find(".standbyPageLi").length;
                        $timeout(function(){
                            var otherObj1 = angular.copy($scope.template.backupPages.list[oldPageIndex]);
                            var pages = angular.copy($scope.template.backupPages.list)
                            pages.splice(oldPageIndex,1)
                            pages.splice(newPageIndex,0,otherObj1)
                            var _page = angular.copy(pages);
                            $scope.template.backupPages.list = [];
                            $timeout(function () {
                                pageSeq(_page, UidService, function (page) {
                                    $scope.template.backupPages.list = page;
                                    vm.loadingImg = false;
                                    $scope.$apply()
                                })
                            },100)
                        })

                    }
                });
            })
        }

        $scope.productType = function(item) {
            switch (item) {
                case 'BOOK':
                    return '照片书';
                case 'PHOTO':
                    return '冲印';
                case 'CALENDAR':
                    return '台历';
                case 'THING':
                    return '万物';
                default:
                    return ''
            }
        };
        vm.attributeTab='page';
        /*刻度尺*/
        vm.staff = true;
        var measureRuler = null;
        var measureRuler2 = null;
        function measureRulerInit() {
            var _w = PxTurnMm($scope.currentPage.custom.width) < 1000 ? 1000 : PxTurnMm($scope.currentPage.custom.width);
            var _h = PxTurnMm($scope.currentPage.custom.height) < 1000 ? 1000 : PxTurnMm($scope.currentPage.custom.height);
            var _mult = MmTurnPx(1)/$scope.currentPage.proportion*$scope.currentPage.scale/10;
            var w = $scope.currentPage.mediabox.width/$scope.currentPage.proportion*$scope.currentPage.scale;
            var h = $scope.currentPage.mediabox.height/$scope.currentPage.proportion*$scope.currentPage.scale;
            if($scope.currentPage.isScene){
                _mult = MmTurnPx(1)*$scope.currentPage.scale/$scope.currentPage.scene.proportion/10;
                w = $scope.currentPage.scene.geometry.width*$scope.currentPage.scale/$scope.currentPage.scene.proportion;
                h = $scope.currentPage.scene.geometry.height*$scope.currentPage.scale/$scope.currentPage.scene.proportion;
            }
            var _left = $scope.canvasCoordX(w);
            var _top = $scope.canvasCoordY(h);
            measureRuler =new MeasureRuler({
                wrapperId:"graduatedScale-cross",     //容器ID，页面中写一个DIV就行 (必须)
                max:_w,  //刻度尺最大的刻度    (非必须，默认为1000)
                unitSet:unitInit(_mult),//刻度尺单元长度    (非必须，默认是10)
                mult:_mult,  //刻度值倍数，默认是最小刻度值为10px，如果定mult为3则最小刻度为30px (非必须，默认为1)
                left:_left,
                click:function (item) {
                    var _item = item*$scope.currentPage.proportion/$scope.currentPage.scale;
                    if($scope.currentPage.isScene){
                        _item = item/$scope.currentPage.scale*$scope.currentPage.scene.proportion;
                    }
                    _item -= ($scope.currentPage.isScene?$scope.currentPage.scene.imagebox.geometry.x:0);
                    if(sublinesInfo(_item,'x')){
                        vm.sublinesX.push(_item);
                    }
                    $scope.$apply();
                }
            })
            measureRuler2 =new MeasureRuler({
                wrapperId:"graduatedScale-vertical",     //容器ID，页面中写一个DIV就行 (必须)
                max:_h,                     //刻度尺最大的刻度    (非必须，默认为1000)
                unitSet:unitInit(_mult),                   //刻度尺单元长度    (非必须，默认是10)
                mult:_mult,                   //刻度值倍数，默认是最小刻度值为10px，如果定mult为3则最小刻度为30px (非必须，默认为1)
                vertical:true,
                top:_top,
                yClick:function (item) {
                    var _item = item*$scope.currentPage.proportion/$scope.currentPage.scale;
                    if($scope.currentPage.isScene){
                        _item = item/$scope.currentPage.scale*$scope.currentPage.scene.proportion;
                    }
                    _item -= ($scope.currentPage.isScene?$scope.currentPage.scene.imagebox.geometry.y:0);
                    if(sublinesInfo(_item,'y')){
                        vm.sublinesY.push(_item);
                    }
                    $scope.$apply();
                }
            })
        }
        function updateMeasureRuler() {
            if(measureRuler&&measureRuler2){
                var _mult = MmTurnPx(1)/$scope.currentPage.proportion*$scope.currentPage.scale/10;
                var w = $scope.currentPage.mediabox.width/$scope.currentPage.proportion*$scope.currentPage.scale;
                var h = $scope.currentPage.mediabox.height/$scope.currentPage.proportion*$scope.currentPage.scale;
                if($scope.currentPage.isScene){
                    _mult = MmTurnPx(1)*$scope.currentPage.scale/$scope.currentPage.scene.proportion/10;
                    w = $scope.currentPage.scene.geometry.width*$scope.currentPage.scale/$scope.currentPage.scene.proportion;
                    h = $scope.currentPage.scene.geometry.height*$scope.currentPage.scale/$scope.currentPage.scene.proportion;
                }
                var _left = $scope.canvasCoordX(w);
                var _top = $scope.canvasCoordY(h);
                measureRuler.reDrawRuler({
                    unitSet:unitInit(_mult),//刻度尺单元长度    (非必须，默认是10)
                    mult:_mult,  //刻度值倍数，默认是最小刻度值为10px，如果定mult为3则最小刻度为30px (非必须，默认为1)
                    left:_left,
                    click:function (item) {
                        if (a !== null) {
                            clearTimeout(a)
                        }
                        a = setTimeout(function () {
                            var _item = item*$scope.currentPage.proportion/$scope.currentPage.scale;
                            if($scope.currentPage.isScene){
                                _item = item/$scope.currentPage.scale*$scope.currentPage.scene.proportion;
                            }
                            _item -= ($scope.currentPage.isScene?$scope.currentPage.scene.imagebox.geometry.x:0);
                            if(sublinesInfo(_item,'x')){
                                vm.sublinesX.push(_item);
                            }
                            $scope.$apply();
                        },200)
                    }
                });
                measureRuler2.reDrawRuler({
                    unitSet:unitInit(_mult),
                    mult:_mult,
                    top:_top,
                    yClick:function (item) {
                        if (a !== null) {
                            clearTimeout(a)
                        }
                        a = setTimeout(function () {
                            var _item = item*$scope.currentPage.proportion/$scope.currentPage.scale;
                            if($scope.currentPage.isScene){
                                _item = item/$scope.currentPage.scale*$scope.currentPage.scene.proportion;
                            }
                            _item -= ($scope.currentPage.isScene?$scope.currentPage.scene.imagebox.geometry.y:0);
                            if(sublinesInfo(_item,'y')){
                                vm.sublinesY.push(_item);
                            }
                            $scope.$apply();
                        },200)
                    }
                });
            }
        }

        //新增参考线
        $scope.addLineReference = function () {
            createModal('app/entities/page/modal/reference.html', 'ReferenceController').result.then(
                function (data) {
                    if(data){
                        if(data.direction == 'x' && sublinesInfo(data.place,'x')){
                            data.place -= ($scope.currentPage.isScene?$scope.currentPage.scene.imagebox.geometry.x:0);
                        }
                        if(data.direction == 'y'){
                            data.place -= ($scope.currentPage.isScene?$scope.currentPage.scene.imagebox.geometry.y:0);
                        }
                        if(data.direction == 'x' && sublinesInfo(data.place,'x')){
                            vm.sublinesX.push(data.place);
                        }
                        if(data.direction == 'y' && sublinesInfo(data.place,'y')){
                            vm.sublinesY.push(data.place);
                        }
                        vm.staff = true;
                    }
                })
        }

        function unitInit(_mult) {
            var _unitSet = 10;
            if(_mult>0.6){
                _unitSet = 10;
            }
            if(_mult<0.6){
                _unitSet = 30;
            }
            if(_mult<0.5){
                _unitSet = 30;
            }
            if(_mult<0.4){
                _unitSet = 50;
            }
            if(_mult<0.3){
                _unitSet = 80;
            }
            if(_mult<0.2){
                _unitSet = 100;
            }
            if(_mult<0.1){
                _unitSet = 200;
            }
            return _unitSet
        }
        //--------------------------------------------------日历标题--------------------------------------------
        vm.widgetTitleTab='SYSTEM';
        vm.widgetTitleList=[];
        vm.widgetTitlePage = 0;
        $scope.widgetTitleInit = function(item) {
            vm.widgetTitleTab=item;
            vm.widgetTitleList=[];
            vm.widgetTitlePage = 0;
            widgetTitleInit1();
        }
        $scope.widgetTitleNext = function () {
            vm.widgetTitlePage++;
            widgetTitleInit1();
        }
        function widgetTitleInit1() {
            if(vm.widgetTitleTab=='SYSTEM'){
                TitleWidget.byCondition2({
                    items:[{key:"enabled", op:"=", value:"true"},{key:"userId", op:"isNull",value:""},{key:"builtin", op:"=", value:"false"}],
                    page: vm.widgetTitlePage,
                    size: 20,
                    sort: ["id,desc"]
                }, function (res,headers) {
                    vm.widgetTitleCount = headers('X-Total-Count');
                    if(res){
                        res.forEach(function (item) {
                            vm.widgetTitleList.push(item)
                        })
                    }
                });
            }
            if(vm.widgetTitleTab=='USER'){
                TitleWidget.byCondition({
                    items:[{key:"enabled", op:"=", value:"true"},{key:"builtin", op:"=", value:"false"}],
                    page: vm.widgetTitlePage,
                    size: 20,
                    sort: ["id,desc"]
                }, function (res,headers) {
                    vm.widgetTitleCount = headers('X-Total-Count');
                    if(res){
                        res.forEach(function (item) {
                            vm.widgetTitleList.push(item)
                        })
                    }
                });
            }
        }
        $scope.widgetTitleAdd = function (e, item) {
            if(vm.textEditor){
                return
            }
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var currentLevel = addLevel(currentPage);
            monthIndexSet(currentPage, function () {
                currentLevel.calTitleBox = getTitleWidget(currentLevel.seq,item,true,currentPage.trimbox.x,currentPage.trimbox.y).calTitleBox;
                currentLevel.calTitleBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,currentPage.config.monthIndex);
                currentLevel.calTitleBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,currentPage.config.monthIndex);
                $scope.currentPage = currentPage;
                $scope.RightPanel = 'calTitleBox';
                $scope.selectBox = currentLevel.calTitleBox;
                $scope.template.transparency = 100;
                var index = $scope.currentPage.levels.level.length-1;
                $scope.selectCalTitleBox(e,index,$scope.currentPage.levels.level[index].calTitleBox);
                updateHistorys();
            })
        }
        $scope.selectCalTitleBox = function (e, $index, art) {
            if(vm.textEditor){
                return
            }
            fn(e);
            $scope.template.angle = art.rotation.angle;
            art.current = 'calTitleBox';

            if (vm.unit == 'mm') {
                $scope.pageValue.calTitleBox = {
                    width: PxTurnMm(art.geometry.width).toFixed(2) - 0,
                    height: PxTurnMm(art.geometry.height).toFixed(2) - 0,
                    x: PxTurnMm(art.geometry.x).toFixed(2) - 0,
                    y: PxTurnMm(art.geometry.y).toFixed(2) - 0
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.calTitleBox = {
                    width: art.geometry.width,
                    height: art.geometry.height,
                    x: art.geometry.x,
                    y: art.geometry.y
                }
            } else if (vm.unit == 'in') {
                $scope.pageValue.calTitleBox = {
                    width: PxTurnIn(art.geometry.width),
                    height: PxTurnIn(art.geometry.height),
                    x: PxTurnIn(art.geometry.x),
                    y: PxTurnIn(art.geometry.y)
                }
            }
            $scope.selectBox = art;
            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                art.ctrlKey = true;
                art.levelI = $index;
                vm.CtrlKeyArray.calTitleBoxs[$index] = angular.copy(art);
                ctrlAddSingleArray();
            } else {
                if (!art.ctrlKey) {
                    $scope.clearCtrlKey();
                    vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, calMonthBox: {}, calTitleBox: {}};
                }
                vm.singleArray.calTitleBox = angular.copy(art);
            }
            $scope.template.levelI = $index;
            // $scope.selPanel = 'calTitleBox';
            $scope.RightPanel = 'calTitleBox';
            e.stopPropagation();
        };
        $scope.onSettingsChange = function (text) {
            if (text) {
                $scope.currentPage = null;
                $scope.lastPage = null;
                $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].calTitleBox.settings = "";
                $scope.template.pages.page[$scope.template.pageI].update = true;
                $timeout(function() {
                    var data = JSON.parse(text);
                    $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].calTitleBox.settings = data;
                    $scope.template.pages.page[$scope.template.pageI].update = false;
                    $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
                    $scope.lastPage = $scope.template.pages.page[$scope.template.pageI];
                    updateHistorys();
                });
            }
        }
        //--------------------------------------------------日历--------------------------------------------
        vm.widgetMonthTab='SYSTEM';
        vm.widgetMonthList=[];
        vm.widgetMonthPage = 0;
        $scope.widgetMonthInit = function(item) {
            vm.widgetMonthTab=item;
            vm.widgetMonthList=[];
            vm.widgetMonthPage = 0;
            widgetMonthInit1();
        }
        $scope.widgetMonthNext = function () {
            vm.widgetMonthPage++;
            widgetMonthInit1();
        }
        function widgetMonthInit1() {
            if(vm.widgetMonthTab=='SYSTEM'){
                MonthWidget.byCondition2({
                    items:[{key:"enabled", op:"=", value:"true"},{key:"userId", op:"isNull",value:""},{key:"builtin", op:"=", value:"false"}],
                    page: vm.widgetMonthPage,
                    size: 20,
                    sort: ["id,desc"]
                }, function (res,headers) {
                    vm.widgetMonthCount = headers('X-Total-Count');
                    if(res){
                        res.forEach(function (item) {
                            vm.widgetMonthList.push(item)
                        })
                    }
                });
            }
            if(vm.widgetMonthTab=='USER'){
                MonthWidget.byCondition({
                    items:[{key:"enabled", op:"=", value:"true"},{key:"builtin", op:"=", value:"false"}],
                    page: vm.widgetMonthPage,
                    size: 20,
                    sort: ["id,desc"]
                }, function (res,headers) {
                    vm.widgetMonthCount = headers('X-Total-Count');
                    if(res){
                        res.forEach(function (item) {
                            vm.widgetMonthList.push(item)
                        })
                    }
                });
            }
        }
        $scope.widgetMonthAdd = function (e, item) {
            if(vm.textEditor){
                return
            }
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var currentLevel = addLevel(currentPage);
            monthIndexSet(currentPage, function () {
                currentLevel.calMonthBox = getMonthWidget(currentLevel.seq,item,true,currentPage.trimbox.x,currentPage.trimbox.y).calMonthBox;
                currentLevel.calMonthBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,currentPage.config.monthIndex);
                currentLevel.calMonthBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,currentPage.config.monthIndex);
                $scope.currentPage = currentPage;
                $scope.RightPanel = 'calMonthBox';
                $scope.selectBox = currentLevel.calMonthBox;
                $scope.template.transparency = 100;
                var index = $scope.currentPage.levels.level.length-1;
                $scope.selectCalMonthBox(e,index,$scope.currentPage.levels.level[index].calMonthBox);
                updateHistorys();
            })
        }
        $scope.selectCalMonthBox = function (e, $index, art) {
            if(vm.textEditor){
                return
            }
            fn(e);
            $scope.template.angle = art.rotation.angle;
            art.current = 'calMonthBox';
            if (vm.unit == 'mm') {
                $scope.pageValue.calMonthBox = {
                    width: PxTurnMm(art.geometry.width).toFixed(2) - 0,
                    height: PxTurnMm(art.geometry.height).toFixed(2) - 0,
                    x: PxTurnMm(art.geometry.x).toFixed(2) - 0,
                    y: PxTurnMm(art.geometry.y).toFixed(2) - 0
                }
            } else if (vm.unit == 'px') {
                $scope.pageValue.calMonthBox = {
                    width: art.geometry.width,
                    height: art.geometry.height,
                    x: art.geometry.x,
                    y: art.geometry.y
                }
            } else if (vm.unit == 'in') {
                $scope.pageValue.calMonthBox = {
                    width: PxTurnIn(art.geometry.width),
                    height: PxTurnIn(art.geometry.height),
                    x: PxTurnIn(art.geometry.x),
                    y: PxTurnIn(art.geometry.y)
                }
            }
            $scope.selectBox = art;
            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                art.ctrlKey = true;
                art.levelI = $index;
                vm.CtrlKeyArray.calMonthBoxs[$index] = angular.copy(art);
                ctrlAddSingleArray();
            } else {
                if (!art.ctrlKey) {
                    $scope.clearCtrlKey();
                    vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, calMonthBox: {}, calTitleBox: {}};
                }
                vm.singleArray.calMonthBox = angular.copy(art);
            }
            $scope.template.levelI = $index;
            // $scope.selPanel = 'calMonthBox';
            $scope.RightPanel = 'calMonthBox';
            e.stopPropagation();
        };
        $scope.onMonthChange = function (text) {
            if (text) {
                $scope.currentPage.levels.level[$scope.template.levelI].calMonthBox = "";
                $scope.lastPage = null;
                $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].calMonthBox = "";
                $scope.template.pages.page[$scope.template.pageI].update = true;
                $timeout(function() {
                    var data = JSON.parse(text);
                    $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].calMonthBox = data;
                    $scope.template.pages.page[$scope.template.pageI].update = false;
                    $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
                    $scope.lastPage = $scope.template.pages.page[$scope.template.pageI];
                    $timeout(function () {
                        unit()
                    })
                    updateHistorys();
                });
                // $scope.currentPage.levels.level[$scope.template.levelI].calMonthBox = "";
                // $timeout(function() {
                //     var data = JSON.parse(text);
                //     $scope.currentPage.levels.level[$scope.template.levelI].calMonthBox = data;
                //     updateHistorys();
                // });
            }
        }
        var a = null;
        $scope.monthIndexUpdate = function () {
            vm.loadingImg = true;
            if($scope.currentPage) {
                $scope.currentPage.updateThumbnail = true;
            }
            if (a !== null) {
                clearTimeout(a)
            }
            a = setTimeout(function () {
                if($scope.currentPage){
                    if($scope.currentPage.config.monthIndex>24){
                        MessageService.error("最大不能超过24个月");
                        $scope.currentPage.config.monthIndex = 1;
                    }
                    if($scope.currentPage.levels && $scope.currentPage.levels.level){
                        $scope.currentPage.levels.level.forEach(function (level) {
                            if (level.calMonthBox && level.calMonthBox.geometry) {
                                var calMonthBox = level.calMonthBox;
                                calMonthBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,$scope.currentPage.config.monthIndex);
                                calMonthBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,$scope.currentPage.config.monthIndex);
                                calMonthBox.obj=null;
                            }
                            if (level.calTitleBox && level.calTitleBox.geometry) {
                                var calTitleBox = level.calTitleBox;
                                calTitleBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,$scope.currentPage.config.monthIndex);
                                calTitleBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,$scope.currentPage.config.monthIndex);
                            }
                        })
                    }
                    $timeout(function () {
                        vm.loadingImg = false;
                    },500)
                }
                $scope.$apply();
            },500)
        }
        $scope.$watch("currentPage", function (newValue, oldValue) {
            if($scope.currentPage && $scope.currentPage.config && $scope.currentPage.config.monthIndex == 0){
                $scope.currentPage.config.monthIndex = "";
            }
        },true)
        function monthIndexSet(page,callback) {
            if($scope.template.config.dynamic && !page.config.monthIndex){
                $uibModal.open({
                    templateUrl: 'app/entities/page/modal/month-index-set.html',
                    controller: 'MonthIndexSetController',
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                }).result.then(function (data) {
                    if (data) {
                        page.config.monthIndex = data;
                        callback()
                    }
                });
            }else{
                callback()
            }
        }
        $scope.pageNameCb = function () {
            vm.isPageName = true;
            vm.operation = true;
            $timeout(function () {
                $("#c-editor-head-name").focus();
            })
        }
        $scope.pageNameCb1 = function () {
            vm.isPageName1 = true;
            vm.operation = true;
            $timeout(function () {
                $("#c-editor-head-name1").focus();
            })
        }
        $scope.isSetMonth = function (page) {//判断是否设置月
            var _return = true;
            if(page && page.levels && page.levels.level){
                var isExist = false;
                page.levels.level.forEach(function (level) {
                    if((level.calMonthBox && level.calMonthBox.geometry) || (level.calTitleBox && level.calTitleBox.geometry)){
                        isExist = true;
                    }
                })
                if(isExist && !page.config.monthIndex){
                    _return = false;
                }
            }
            return _return
        }
        //参考线移动
        vm.lineReferenceIndex = 0;
        vm.lineReferenceType = null;
        vm.lineReferenceData = {};
        vm.isLineReference = false;
        $scope.moveLineReference = function (e, item, index, type) {
            if(vm.textEditor){
                return;
            }
            fn(e);
            vm.lineReferenceIndex = index;
            vm.lineReferenceType = type;
            vm.lineReferenceData.clientX = e.clientX || 0;
            vm.lineReferenceData.clientY = e.clientY || 0;
            if($scope.currentPage.isScene){
                vm.lineReferenceData.coordinates = item*$scope.currentPage.scale/$scope.currentPage.scene.proportion;
            }else{
                vm.lineReferenceData.coordinates = item/$scope.currentPage.proportion*$scope.currentPage.scale;
            }
            vm.isLineReference = true;
            document.addEventListener("mousemove", moveLineReferenceOn, true);
            document.addEventListener("mouseup", moveLineReferenceEnd, true);

        };
        //move 事件
        function moveLineReferenceOn(e) {
            fn(e);
            if (vm.isLineReference) {
                if(vm.lineReferenceType == 'x'){
                    var _item = (Number(vm.lineReferenceData.coordinates) + (e.clientX - vm.lineReferenceData.clientX));
                    if($scope.currentPage.isScene){
                        vm.sublinesX[vm.lineReferenceIndex] = _item/$scope.currentPage.scale*$scope.currentPage.scene.proportion;
                    }else{
                        vm.sublinesX[vm.lineReferenceIndex] = _item*$scope.currentPage.proportion/$scope.currentPage.scale;
                    }
                }
                if(vm.lineReferenceType == 'y'){
                    var _item = Number(vm.lineReferenceData.coordinates) + (e.clientY - vm.lineReferenceData.clientY);
                    if($scope.currentPage.isScene){
                        vm.sublinesY[vm.lineReferenceIndex] = _item/$scope.currentPage.scale*$scope.currentPage.scene.proportion;
                    }else{
                        vm.sublinesY[vm.lineReferenceIndex] = _item * $scope.currentPage.proportion/$scope.currentPage.scale;
                    }
                }
            }
            $scope.$apply();
        }
        //鼠标松开事件
        function moveLineReferenceEnd() {
            if(vm.lineReferenceType == 'x'){
                if(!sublinesInfo(vm.sublinesX[vm.lineReferenceIndex],'x')){
                    vm.sublinesX.splice(vm.lineReferenceIndex,1)
                }
            }
            if(vm.lineReferenceType == 'y'){
                if(!sublinesInfo(vm.sublinesY[vm.lineReferenceIndex],'y')){
                    vm.sublinesY.splice(vm.lineReferenceIndex,1)
                }
            }
            vm.lineReferenceIndex = 0;
            vm.lineReferenceType = null;
            vm.lineReferenceData = {};
            vm.isLineReference = false;
            document.removeEventListener("mousemove", moveLineReferenceOn, true);
            document.removeEventListener("mouseup", moveLineReferenceEnd, true);
            $scope.$apply();
        }
        function sublinesInfo(item, type) {
            var _isS = true;
            if(type == 'x'){
                if(item<0 || item>$scope.currentPage.mediabox.width){
                    _isS = false
                }
            }
            if(type == 'y'){
                if(item<0 || item>$scope.currentPage.mediabox.height){
                    _isS = false
                }
            }
            return _isS
        }

        //判断查看缩略图距离浏览器底部距离
        $scope.attributeViewBtn3 = function (e) {
            var _dom = $(".c-editor-attribute");
            if (_dom.scrollTop() + _dom.innerHeight() < _dom[0].scrollHeight) {
                return true
            } else {
                return false
            }
        }
        //一键清除当前页相框
        $scope.clearImageboxImg = function () {
            if ($scope.currentPage && $scope.currentPage.levels && $scope.currentPage.levels.level && $scope.currentPage.levels.level.length) {
                for (var i = $scope.currentPage.levels.level.length - 1; i >= 0; i--) {
                    var level = $scope.currentPage.levels.level[i];
                    if (level.imagebox && level.imagebox.geometry && level.imagebox.image) {
                        level.imagebox.image = null;
                        $scope.currentPage.updateThumbnail = true;
                    }
                }
                $timeout(function () {
                    updateHistorys();
                })
            }
        }
        //一键清除所有页相框
        $scope.clearAllImageboxImg = function () {
            MessageService.confirm({
                title:'温馨提示',
                cancel:'取消',
                confirm:'确认',
                msg:'是否清除所有相框里的图片？'
            },function(){
                $scope.template.pages.page.forEach(function (page) {
                    if (page && page.levels && page.levels.level && page.levels.level.length) {
                        for (var i = page.levels.level.length - 1; i >= 0; i--) {
                            var level = page.levels.level[i];
                            if (level.imagebox && level.imagebox.geometry && level.imagebox.image) {
                                level.imagebox.image = null;
                                page.updateThumbnail = true;
                            }
                        }
                    }
                })
                $timeout(function () {
                    updateHistorys();
                })
            })
        }
    }

})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PatternsController', PatternsController);

    PatternsController.$inject = ['$scope', '$state', 'Moulds', 'ParseLinks', 'AlertService', 'paginationConstants',
     'pagingParams', 'MessageService', 'Qinius', '$uibModal','$timeout'];

    function PatternsController ($scope, $state, Moulds, ParseLinks, AlertService, paginationConstants,
     pagingParams, MessageService, Qinius, $uibModal,$timeout) {
        var vm = this;
        vm.oldView = false;
        //搜索防抖定时器
        vm.timer = null;
        //分页信息
        vm.pageInfo = {
            page: 1,
            size: 20,
            total: 0,
        }
        //批量操作状态
        vm.batchState = false;
        //选中的数量
        vm.selectCount = 0;
        vm.loadPage = loadPage;
        vm.predicate = "lastModifiedDate";
        vm.reverse = false;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.status = status;
        $scope.domains = Qinius.getDomain();
        vm.modelTypeList=[
            {name:'2D模型', value:'TWO'},
            {name:'3D模型', value:'THREE'},
        ];
        vm.modelType = pagingParams.modelType || 'TWO';

        loadAll();

        //分页变更
        vm.currentChange = function () {
            $timeout(function () {
                vm.loadAll();
            })
        }
        //批量操作选择
        $scope.checkItem = function(item){
            if (!vm.batchState) {
                return
            }
            if(!item.selected){
                item.selected = true;
            }else{
                item.selected = false;
            }
            //获取选中的数量
            var selectList = vm.data.filter(function (item) {
                return item.selected === true;
            })
            vm.selectCount = selectList.length;
        };
        function loadAll () {
            var items = [{key: "cancelled", op: "=", value: 'false'},{key:"sourceType", op:"=", value:'front'}];
            if (vm.searchQuery) {
                items.push({key: "name", op: "like", value: vm.searchQuery});
            }

            if (vm.modelType == 'THREE') {
                items.push({key: "modelType", op: "=", value: vm.modelType});
            }
            if (vm.modelType == 'TWO') {
                items.push({key: "modelType", op: "=", value: "TWO"});
            }
            vm.data = [];
            Moulds.byCondition2({
                items: items,
                page: vm.pageInfo.page - 1,
                size: vm.pageInfo.size,
                sort: ["lastModifiedDate,desc"]
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.pageInfo.total = headers('X-Total-Count');
                vm.data = data;
                //退出批量编辑
                vm.batchState = false;
                vm.selectCount = 0;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        $scope.modelTypeTab = function (item) {
            vm.modelType = item.value;
            vm.page = 1;
            loadAll();
        }
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                modelType: vm.modelType
            });
        }

        function search() {
            if(event.keyCode === 13){
                vm.links = null;
                vm.pageInfo.page = 1;
                vm.loadAll();
            }
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        //新建
        $scope.add = function (type) {
            if(type == 'TWO'){
                $uibModal.open({
                    templateUrl: 'app/entities/patterns/modal/patterns-modal.html',
                    controller: 'PatternsModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: [function() {
                            return {
                                type: 'add',
                                id: ''
                            };
                        }]
                    }
                }).result.then(function(data) {
                    if(data){
                        loadAll();
                    }
                });
            }
            if(type == 'THREE'){
                $uibModal.open({
                    templateUrl: 'app/entities/patterns/modal/patterns-modal-three.html',
                    controller: 'PatternsModalThreeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: [function() {
                            return {
                                type: 'add',
                                id: ''
                            };
                        }]
                    }
                }).result.then(function(data) {
                    if(data){
                        loadAll();
                    }
                });
            }
        };
        //编辑产品
        $scope.edit = function (item) {
            if(vm.modelType == 'TWO'){
                $uibModal.open({
                    templateUrl: 'app/entities/patterns/modal/patterns-modal.html',
                    controller: 'PatternsModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: [function() {
                            return {
                                type: 'edit',
                                id: item
                            };
                        }]
                    }
                }).result.then(function(data) {
                    if(data){
                        loadAll();
                    }
                });
            }
            if(vm.modelType == 'THREE'){
                $uibModal.open({
                    templateUrl: 'app/entities/patterns/modal/patterns-modal-three.html',
                    controller: 'PatternsModalThreeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: [function() {
                            return {
                                type: 'edit',
                                id: item
                            };
                        }]
                    }
                }).result.then(function(data) {
                    if(data){
                        loadAll();
                    }
                });
            }
        };

        $scope.look = function (item) {
            var data = {
                id:item.id,
            }
            if(vm.modelType == 'TWO'){
                data.modelType = 'TWO';
                data.modelPic = $scope.domains.domain + item.modelPic
            }
            if(vm.modelType == 'THREE'){
                data.modelType = 'THREE';
                data.modelPath = item.modelPath;
                data.modelData = JSON.parse(item.modelData)
            }
            console.log(data,'sss');
            $uibModal.open({
                templateUrl: 'app/entities/patterns/modal/patterns-preview.html',
                controller: 'PatternsPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return data
                    }]
                }
            });
        }
        $scope.batchDelete = function(){
            //获取选中的
            var selectList = vm.data.filter(function (item) {
                return item.selected === true;
            });
            if(selectList.length == 0) return MessageService.error("至少选择一条数据");
             var ids = selectList.map(function (item) {
                return item.id;
            });
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确认要删除选中的模型？'
            },function(){
                Moulds.batchDelete(ids,function(){
                    MessageService.success("删除成功");
                    vm.loadAll();
                },function(){
                    MessageService.error("网络异常，请重试");
                    vm.batchDeleteLoading = false;
                })
            })
        }
        $scope.delete = function(id){
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确认要删除该模型？'
            },function(){
                Moulds.delete({id:id},function(data){
                    MessageService.success("删除成功");
                    loadAll();
                },function(){
                    MessageService.error("删除失败");
                })
            })
        };
    }
})();

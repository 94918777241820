(function () {
    "use strict";

    angular
        .module("platformApp")
        .controller("PatternsPreviewController", PatternsPreviewController);

    PatternsPreviewController.$inject = [
        "$timeout",
        "$scope",
        "UidService",
        "$stateParams",
        "$uibModalInstance",
        "entity",
        "$state",
        "Moulds",
        "MessageService",
        "Qinius",
        "$uibModal",
    ];

    function PatternsPreviewController(
        $timeout,
        $scope,
        UidService,
        $stateParams,
        $uibModalInstance,
        entity,
        $state,
        Moulds,
        MessageService,
        Qinius,
        $uibModal
    ) {
        var vm = this;
        vm.modelPic = entity.modelPic;
        vm.modelType = entity.modelType;
        vm.modelPath = entity.modelPath;
        vm.modelData = entity.modelData;
        //返回
        vm.cancel = function(){
            $uibModalInstance.close(false);
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('TemplateListController', TemplateListController);

    TemplateListController.$inject = ['$state', '$scope', '$uibModal', 'ParseLinks','Qinius','$q', 'paginationConstants', 'MessageService','$stateParams','Hashtag','ProductXml', 'ProductTemplate', '$location','Catalog','$localStorage'];

    function TemplateListController($state, $scope, $uibModal, ParseLinks,Qinius,$q, paginationConstants, MessageService,$stateParams,Hashtag,ProductXml, ProductTemplate, $location, Catalog,$localStorage) {

        var vm = this;

        $(".navbar").show();
        $(".wrap").css({margin: '50px 0 10px 254px'});
        vm.templateList =[];
        vm.hashtags = [];
        vm.loadPage = loadPage;
        var _location = $location.search();
        vm.reverse = '';
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.jump = jump;
        vm.id ='';
        vm.operations=['编辑','复制','删除','分享','发布模板','发布产品', '获取数据'];
        vm.show = false;
        vm.tagScreenIsOpen = false;
        vm.isBatch = false;

        vm.tableData = {
            size:paginationConstants.itemsPerPage,
            page:_location.page || 1,
            searchQuery: _location.search || '',
            selectN: _location.selectN || '',
            classify: _location.classify || '',
            shareStatus: _location.shareStatus || '',
            source: _location.source || '',
            product: _location.product || '',
            upgradeStatus: _location.upgradeStatus || ''
        }
        localStorage.removeItem('label');
        vm.sourceList=[
            {name:'自定义',id:'self'},
            {name:'平台推荐',id:'recommend'}
        ];
        vm.productList = [
            {name:'照片书',id:'BOOK'},
            {name:'冲印',id:'PHOTO'},
            {name:'台历',id:'CALENDAR'},
            {name:'万物',id:'THING'},
        ];

        vm.shareStatus = [
            {name:'全部',id:'',},
            {name:'未分享',id:null},
            {name:'审核中',id:'Created',},
            {name:'已分享',id:'Approved',},
            {name:'已驳回',id:'Rejected',}
        ]

        $scope.selectShareStatus = function(item){
            vm.tableData.shareStatus = item;
            vm.tableData.page = 1;
            getTemplate();
        }

        vm.upgradeStatus = [
            {name:'全部',id:''},
            {name:'已升级',id:'upgrade'},
            {name:'未升级',id:'noUpgrade'},
        ]


        $scope.selectUpgradeStatus = function(item){
            vm.tableData.upgradeStatus = item;
            vm.tableData.page = 1;
            getTemplate();
        }


        //模板分类
        Catalog.getAll({items:[{key:"usable", op:"=", value:"true"},{key:"catalogType", op:"=", value:"XML"}]}, function (res) {
            vm.catalogs = res;
        });

        vm.domain = Qinius.getDomain();
        $q.all([vm.domain.$promise]).then(function(){
            vm.domainQ  =vm.domain.domain
        });

        $scope.templateFlag = function(template){
            angular.forEach(vm.templateList ,function(item){
                if (item.id != template.id ) {
                    item.flag = false;
                }
            })
            template.flag = !template.flag;
        }

        $scope.getResult = function(template){
            if (template.status == "Rejected") {
                // $state.go("template-opened-result", {id: template.id});
                // $uibModal.open({
                //     templateUrl: 'app/entities/page/template-opened-result.html',
                //     controller: 'PageShareController',
                //     controllerAs: 'vm',
                //     backdrop: 'static',
                //     size: 'lg',
                //     resolve: {
                //         entity: ['ProductXml',   function (ProductXml) {
                //             return ProductXml.get({id: template.id}).$promise;
                //         }],
                //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                //             return $translate.refresh();
                //         }]
                //     }
                // }).result.then(function() {

                // });
                MessageService.confirm({
                    title:'驳回理由',
                    hideCancel:true,
                    cancel:'取消',
                    confirm:'知道了',
                    msg:'理由： '+template.statusRemarks,
                },function(){

                })
            }
        };
        function tempVersion(temp,callback){
            if(temp.version != '4.0'){
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'系统全面更新，模板需升级方可使用，确认升级?',
                    isThemecolor:true
                },function(){
                    $uibModal.open({
                        templateUrl: 'app/entities/page/modal/template-upgrade.html',
                        controller: 'TemplateUpgradeController',
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: 'vm',
                        size:'lg',
                        resolve: {
                            entity: function(){
                                return temp
                            },
                            pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil.parsePage($stateParams.page)
                                };
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('page');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(
                        function (data) {
                            if(data){
                                getTemplate();
                                ProductXml.get({id: temp.id}, function (res) {
                                    callback(res)
                                })
                            }
                        })
                })
            }else{
                callback(temp)
            }
        }
        /**
         * 显示原创协议弹窗
         * @param {*} callback
         */
        function showOriginalAgreement(callback){
            var userInfo = $localStorage.user;
            if(userInfo.original){
                return callback(true);
            }
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/original-agreement.html',
                controller: 'OriginalAgreementController',
                controllerAs: 'vm',
                backdrop: "static",
                size: 'lg',
                resolve: {

                }
            }).result.then(function (state) {
                callback(state);
            })
        }
        $scope.operateLi = function(tempItem, op){
            if (op == null || tempItem == null) {
                return;
            }
            if (op == "删除") {
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'确认删除该模板？'
                },function(){
                    ProductXml.delete({id:tempItem.id},function(data){
                        loadAll();
                        MessageService.success("删除成功");
                    },function(){
                        MessageService.error("删除失败");
                    });
                })
                return;
            }
            tempVersion(tempItem,function (temp) {
                if (op == "编辑") {
                    // $uibModal.open({
                    //     templateUrl: 'app/entities/page/modal/page-standard.html',
                    //     controller: 'PageStandardController',
                    //     backdrop: 'static',
                    //     keyboard: true,
                    //     windowClass: "jh-modal-md",
                    //     controllerAs: 'vm',
                    //     resolve: {
                    //         items: function(){
                    //             return {
                    //                 type: 'edit',
                    //                 id: temp.id,
                    //                 data: null
                    //             }
                    //         },
                    //         pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    //             return {
                    //                 page: PaginationUtil.parsePage($stateParams.page)
                    //             };
                    //         }],
                    //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    //             $translatePartialLoader.addPart('page');
                    //             return $translate.refresh();
                    //         }]
                    //     }
                    // }).result.then(function(data) {
                    //     if(data){
                    //         getTemplate()
                    //     }
                    // });
                    $state.go('page-my-suffix-edit', {id: tempItem.id}, {reload: true})
                }else if (op == '编辑数据') {
                    $uibModal.open({
                        templateUrl: 'app/entities/page/modal/page-standard.html',
                        controller: 'PageStandardController',
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: 'vm',
                        resolve: {
                            items: function(){
                                return {
                                    type: 'edit',
                                    id: temp.id,
                                    data: null
                                }
                            },
                            pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil.parsePage($stateParams.page)
                                };
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('page');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function(data) {
                        if(data){
                            getTemplate()
                        }
                    });
                }else if (op == "复制") {
                    $uibModal.open({
                        templateUrl: 'app/entities/page/modal/page-standard.html',
                        controller: 'PageStandardController',
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: 'vm',
                        resolve: {
                            items: function(){
                                return {
                                    type: 'copy',
                                    id: temp.id,
                                    data: null
                                }
                            },
                            pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil.parsePage($stateParams.page)
                                };
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('page');
                                return $translate.refresh();
                            }]
                        }
                    });
                }else if (op == "发布模板") {
                    showOriginalAgreement(function(state){
                        if(state){
                            ProductXml.get({id:temp.id}, function (res) {
                                if(res.document.documentpics.documentpic.length === 0){
                                    MessageService.error("请添加样例图！");
                                    return
                                }
                                calendarContinuous(res.document,function () {
                                    $uibModal.open({
                                        templateUrl: 'app/entities/page/modal/document-detection.html',
                                        controller: 'DocumentDetectionController',
                                        controllerAs: 'vm',
                                        size: 'lg',
                                        resolve: {
                                            entity: [function() {
                                                return {
                                                    document: res.document,
                                                    id:temp.id,
                                                    type: 'XML',
                                                    storeIds:[],
                                                    source:'list'
                                                };
                                            }]
                                        }
                                    }).result.then(function (isReturn) {
                                    })
                                })
                            })
                        }
                    });
                }else if (op == "获取数据") {
                    ProductXml.updateSizeInfo({
                        id:temp.id,
                    },function(data){
                        loadAll();
                        MessageService.success("修改成功");
                    },function(){
                        MessageService.error("修改失败");
                    });
                }else if (op == "分享") {
                    $uibModal.open({
                        templateUrl: 'app/entities/page/template-share.html',
                        controller: 'PageShareController',
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: 'vm',
                        resolve: {
                            entity: ['ProductXml',   function (ProductXml) {
                                return ProductXml.get({id: temp.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        getTemplate()
                    });
                }else if (op == "发布产品") {
                    var pass = true;
                    ProductXml.get({id:temp.id}, function (res) {
                        if(res.document.documentpics.documentpic.length === 0){
                            MessageService.error("请添加样例图！");
                            return
                        }
                        var _page = res.document.pages.page;
                        for(var i=0; i<_page.length; i++){
                            var _level = _page[i].levels.level;
                            if (_level== null) {
                                continue;
                            }
                            for(var j=0; j<_level.length; j++){
                                if(_level[j].imagebox){
                                    if(!_level[j].imagebox.image){
                                        pass = false;
                                        break;
                                    }
                                }
                            }
                        }
                        if(!pass){
                            MessageService.error("没有制作完成！");
                        }else {
                            showOriginalAgreement(function(state){
                                if(state){
                                    calendarContinuous(res.document,function () {
                                        $uibModal.open({
                                            templateUrl: 'app/entities/page/modal/document-detection.html',
                                            controller: 'DocumentDetectionController',
                                            controllerAs: 'vm',
                                            size: 'lg',
                                            resolve: {
                                                entity: [function() {
                                                    return {
                                                        document: res.document,
                                                        id:temp.id,
                                                        type: 'PRODUCT',
                                                        storeIds:[],
                                                        source:'list'
                                                    };
                                                }]
                                            }
                                        }).result.then(function (isReturn) {
                                        })
                                    })
                                }
                            })
                        }
                    })
                }
                else if (op == "重命名") {
                    $uibModal.open({
                        templateUrl: 'app/entities/page/modal/template-rename.html',
                        controller: 'TemplateRenameController',
                        controllerAs: 'vm',
                        size: 'm',
                        resolve: {
                            entity: [function() {
                                return {
                                    id: temp.id,
                                    name: temp.name
                                };
                            }]
                        }
                    }).result.then(function (data) {
                        temp.name = data.name;
                        $scope.$digest();
                    })
                }
                else if (op == "升级"){

                }
                angular.forEach(vm.templateList ,function(item){
                    item.flag = false;
                })
            })
        }

        function calendarContinuous(template,callback) {
            if(template.config.dynamic){
                var numbers = [];
                template.pages.page.forEach(function (page) {
                    if(page.config.monthIndex>0){
                        numbers.push(page.config.monthIndex)
                    }
                })
                var result = findMissingNumbers(numbers);
                if (result.length>0){
                    $uibModal.open({
                        templateUrl: 'app/entities/page/modal/calendar-modal.html',
                        controller: 'CalendarModalController',
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: 'vm',
                        resolve: {
                            entity: [function () {
                                return {
                                    numbers: numbers,
                                    templateConfig: template.config
                                };
                            }]
                        }
                    }).result.then(function (data) {
                        if (data) {
                            callback()
                        }
                    });
                    return
                }
            }
            callback()
        }

        //    分类显示
        loadAll();
        function loadAll () {
            //获取可使用的主题标签
            Hashtag.byCondition2({
                items:[{key:"usable",value:true, op:"="}]
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.hashtags = data;
            }
            function onError(error) {
                MessageService.error("获取主题标签数据失败");
            }
            getTemplate();
        }

        vm.pageCb = pageCb;
        function pageCb(page) {
            vm.tableData.page = page;
            getTemplate();
        }
        //   获取模板列表
        function getTemplate(){
            var items=[];
            if (vm.tableData.selectN != '' && vm.tableData.selectN != null) {
                items.push({key: "hashtags", op: "like", value: vm.tableData.selectN});
            }
            if (vm.tableData.source == "self") {
                items.push({key: "productTemplate.id", op: "isNull"});
            }
            if (vm.tableData.source == "recommend") {
                items.push({key: "productTemplate.id", op: "notNull"});
            }
            if (vm.tableData.classify != '') {
                items.push({key: "catalog.id", op: "=", value: vm.tableData.classify});
            }
            if (vm.tableData.product != '') {
                items.push({key: "productType", op: "=", value: vm.tableData.product});
            }
            if (vm.tableData.shareStatus != '') {
                if(vm.tableData.shareStatus === null){
                    items.push({key: "status", value: null, op: "isnull"});
                }else{
                    items.push({key: "status", op: "=", value: vm.tableData.shareStatus});
                }

            }
            if (vm.tableData.upgradeStatus != '') {
                if (vm.tableData.upgradeStatus == 'upgrade') {
                    items.push({key: "productType", op: "notNull"});
                }
                if (vm.tableData.upgradeStatus == 'noUpgrade') {
                    items.push({key: "productType", op: "isNull"});
                }
            }

            if (vm.tableData.searchQuery) {
                items.push({key: "name", op: "like", value: vm.tableData.searchQuery});
            }
            ProductXml.byCondition({
                items: items,
                page: vm.tableData.page-1,
                size: vm.tableData.size,
                sort: ["lastModifiedDate,desc"]
            }, onSuccess1, onError1);

            function onSuccess1(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.templateList = data;
                transition();
                vm.selectList = [];
                vm.selectIds = [];
                vm.templateList.forEach(function(item){
                    item.selected = false;
                })
            }
            function onError1(error) {
                MessageService.error("获取模板列表数据失败");
            }
        }

        $scope.selectTag = function(item){
            vm.tableData.selectN =item;
            vm.tableData.page = 1;
            getTemplate();
        };

        $scope.selectClassify = function(item){
            vm.tableData.classify = item;
            vm.tableData.page = 1;
            getTemplate();
        }

        $scope.selectSource = function(item){
            vm.tableData.source = item;
            vm.tableData.page = 1;
            getTemplate();
        }

        $scope.selectProduct = function(item){
            vm.tableData.product = item;
            vm.tableData.page = 1;
            getTemplate();
        }

        function loadPage(page) {
            vm.tableData.page = page;
            getTemplate();
            vm.transition();
        }

        function transition() {
            // $location.search({
            //     page:vm.tableData.page || 1,
            //     searchQuery: vm.tableData.searchQuery,
            //     selectN: vm.tableData.selectN,
            //     classify: vm.tableData.classify,
            //     shareStatus: vm.tableData.shareStatus,
            //     source: vm.tableData.source,
            //     product: vm.tableData.product,
            //     upgradeStatus: vm.tableData.upgradeStatus
            // })
        }

        function search() {
            if (event.keyCode === 13) {
                vm.links = null;
                vm.tableData.page = 1;
                vm.reverse = false;
                getTemplate();
            }

        }

        function clear() {
            vm.links = null;
            vm.tableData.page = 1;
            vm.reverse = false;
            vm.tableData.searchQuery = null;
            getTemplate();
        }
        function jump () {
            vm.tableData.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.tableData.page);
        }

        $scope.previewTemplate = function(templateItem){
            tempVersion(templateItem,function (template) {
                ProductXml.get({id:template.id},function(data){
                    createModal('app/entities/page/template-view.html', 'TemplateViewController',data.id).result.then(
                        function (data) {

                        });
                });
                $(".modal-content").hide();
            })
        };

        var createModal = function (url, controller,id) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: ['ProductXml', '$stateParams', function (ProductXml, $stateParams) {
                        return ProductXml.get({id: id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }]
                }
            });
        };
        $scope.hashtags = function(hashtags){
            if (hashtags) {
                var hashtags = hashtags.split(',')
                return hashtags
            }else{
                return
            }
        }
        $scope.addLabel=function(item){
            // $state.go('templateList.new',{
            //     id:item.id
            // });
            console.log(item);
            $uibModal.open({
                templateUrl: 'app/entities/page/template-dialog.html',
                controller: 'TemplateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            seq: null,
                            type: null,
                            usable: null,
                            id: null,
                            templateId:item.id
                        };
                    }
                }
            }).result.then(function (isReturn) {
                item.hashtags = isReturn.hashtags
            });
            localStorage.setItem('label',JSON.stringify(item));
        }

        vm.checkAndFix = checkAndFix;
        function checkAndFix() {
            ProductXml.checkAndFixDocument({ticket: 'check12345'}, function(res) {
                var counter = res.counter;
                var duration = res.durationInSeconds;
                MessageService.success("操作成功！修复数量:" + counter + ", 使用时间:" + duration + "s");
            }, function(err) {

            });
        }

        $scope.gotoCreate = function (item, custom, productType) {
            if(custom){
                createModal2('app/entities/page/modal/template-add.html', 'TemplateAddController',item, custom,productType).result.then(
                    function (data) {
                });
            }
        };

        var createModal2 = function (url, controller,item, custom,productType) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    items: function(){
                        return {
                            type: 'add',
                            data: item,
                            custom: custom,
                            productType:productType
                        }
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            });
        };

        $scope.productType = function (item) {
            switch (item) {
                case 'BOOK':
                    return '照片书';
                    break;
                case 'PHOTO':
                    return '冲印';
                    break;
                case 'CALENDAR':
                    return '台历';
                    break;
                case 'THING':
                    return '万物';
                    break;
                default:
                    return ''
            }
        }


        $scope.batch = function(){
            vm.templateList.forEach(function(item){
                item.selected = false;
            })
            vm.selectList = [];
            vm.selectIds = [];
            vm.batch = true;
        }

        $scope.quitBatch = function(){
            vm.batch = false;
        }
        vm.selectList = [];
        $scope.selectItem = function(item){
            if (!vm.batch) {
                return
            }
            item.selected = !item.selected;
            selects();
        }

        function selects(){
            vm.selectList = [];
            vm.selectIds = [];
            vm.templateList.forEach(function(item){
                if (item.selected) {
                    vm.selectList.push(item);
                    vm.selectIds.push(item.id);
                }
            })
        }

        $scope.deleteSelect = function(){
            if (vm.selectIds.length == 0) {
                MessageService.notice('请选择需要删除的模板')
                return
            }
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确认删除选中的模板？'
            },function(){
                ProductXml.batchDelete(vm.selectIds,function(){
                    loadAll();
                    MessageService.success("删除成功");
                    vm.batch = false;
                },function(){
                    MessageService.error("删除失败");
                });
            })
        }

        $scope.changeMore = function(){
            vm.tagScreenIsOpen = !vm.tagScreenIsOpen;
        }

        $scope.editorClassify = function(){
            $uibModal.open({
                templateUrl: 'app/entities/page/catalog-management.html',
                controller: 'CatalogManagementController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: function () {
                        return {catalogType: "XML"}
                    }
                }
            }).result.then(function(res) {
                Catalog.getAll({items:[{key:"usable", op:"=", value:"true"},{key:"catalogType", op:"=", value:"XML"}]}, function (res) {
                    vm.catalogs = res;
                    vm.tableData.classify = '';
                    getTemplate()
                });
            },function(){
                Catalog.getAll({items:[{key:"usable", op:"=", value:"true"},{key:"catalogType", op:"=", value:"XML"}]}, function (res) {
                    vm.catalogs = res;
                    vm.tableData.classify = '';
                    getTemplate()
                });
            });
        }
    }
})();

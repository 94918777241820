(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PageCreateController', PageCreateController);

    PageCreateController.$inject = ['$scope', '$uibModalInstance', '$state', 'MessageService', '$stateParams', 'ProductXml', 'items', 'Catalog','$timeout',
        'UidService','Qinius','$q'];

    function PageCreateController($scope, $uibModalInstance, $state, MessageService, $stateParams, ProductXml, items, Catalog, $timeout,
                                  UidService,Qinius,$q) {

        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function(){
            $scope.domain = vm.domains.domain;
            vm.token = vm.tokens.token;
        })

        vm.dpiChoose = [{data: 'mm', id: 0}, {data: 'px', id: 1}];
        $scope.dpi = vm.dpiChoose[0];
        vm.aid = $stateParams.id || $stateParams.copy;
        vm.catalogs = Catalog.getAll({items: [{key: "usable", op: "=", value: "true"}]});

        if (vm.aid) {
            ProductXml.get({id: vm.aid}, function (resp) {
                if (resp.document && resp.document.pages && resp.document.pages.page) {
                    var pages = resp.document.pages.page;
                    var page = pages[0];
                    vm.top = page.trimbox.y;
                    vm.bottom = page.mediabox.height - page.trimbox.height - page.trimbox.y;
                    vm.left = page.trimbox.x;
                    vm.right = page.mediabox.width - page.trimbox.width - page.trimbox.x;

                    vm.page = {
                        name: resp.name,
                        pageSize: {width: page.trimbox.width, height: page.trimbox.height},
                        editbox: {
                            width: page.editbox?page.editbox.width:(page.trimbox.width+vm.left+vm.right),
                            height: page.editbox?page.editbox.height:(page.trimbox.height+vm.top+vm.bottom),
                            x: page.editbox?page.editbox.x:0,
                            y: page.editbox?page.editbox.y:0
                        },
                        cover:resp.document.cover,
                        bloodSize: {top: vm.top, bottom: vm.bottom, left: vm.left, right: vm.right},
                        pageNum: pages.length,
                        dpi: $scope.dpi,
                        bindingType: resp.bindingType,
                        pattern: resp.pattern,
                        replace: resp.replace + "",
                        catalogId: resp.catalogId
                    };
                    console.log(vm.page);
                }
            })
        } else {
            if (items != null && items != "") {
                vm.page = {
                    name: items.name,
                    pageSize: {width: items.pageSizeW, height: items.pageSizeH},
                    bloodSize: {
                        top: items.pageBloodT,
                        bottom: items.pageBloodB,
                        left: items.pageBloodL,
                        right: items.pageBloodR
                    },
                    editbox: {
                        width: items.pageSizeW+items.pageBloodL+items.pageBloodR,
                        height: items.pageSizeH+items.pageBloodT+items.pageBloodB,
                        x: 0,
                        y: 0
                    },
                    pageNum: 1,
                    dpi: $scope.dpi,
                    bindingType: 'Left',
                    pattern: 'BOOK',
                    replace: "true",
                    catalogId: ""
                };
            } else {
                vm.page = {
                    name: '',
                    pageSize: {width: 420, height: 285},
                    editbox: {width: 426, height: 264, x: 0, y: 0},
                    bloodSize: {top: 3, bottom: 3, left: 3, right: 3},
                    pageNum: 1,
                    dpi: $scope.dpi,
                    bindingType: 'Left',
                    pattern: 'BOOK',
                    replace: "true",
                    catalogId: ""
                };
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.isGuanlian = false;
        $scope.guanlian = function (attr) {
            if (!attr) {
                $scope.scale = vm.page.pageSize.width / vm.page.pageSize.height;
            }

            $scope.isGuanlian = !$scope.isGuanlian;
        }
        $scope.updateTemplateHeightAndWidth = function (attr) {
            if ($scope.isGuanlian) {
                if (attr) {
                    vm.page.pageSize.width = Math.floor(vm.page.pageSize.height * $scope.scale)
                } else {
                    vm.page.pageSize.height = Math.floor(vm.page.pageSize.width / $scope.scale)
                }
            }
        };

        $scope.updateDpi = function (data) {

            vm.page.dpi = data;

            if (data.id == 1) {
                var wah = ThreeHundredDpi(vm.page.pageSize.width, vm.page.pageSize.height);
                vm.page.pageSize = {
                    width: wah.px1,
                    height: wah.px2,
                }
                var tab = ThreeHundredDpi(vm.page.bloodSize.top, vm.page.bloodSize.bottom);
                var lar = ThreeHundredDpi(vm.page.bloodSize.left, vm.page.bloodSize.right);
                vm.page.bloodSize = {
                    top: tab.px1,
                    bottom: tab.px2,
                    left: lar.px1,
                    right: lar.px2,
                }
                var editWah = ThreeHundredDpi(vm.page.editbox.width, vm.page.editbox.height);
                var editXay = ThreeHundredDpi(vm.page.editbox.x, vm.page.editbox.y);
                vm.page.editbox = {
                    width: editWah.px1,
                    height: editWah.px2,
                    x: editXay.px1,
                    y: editXay.px2,
                }
            } else {
                var wah = ThreeHundredDpiToMm(vm.page.pageSize.width, vm.page.pageSize.height);
                vm.page.pageSize = {
                    width: wah.mm1,
                    height: wah.mm2,
                }
                var tab = ThreeHundredDpiToMm(vm.page.bloodSize.top, vm.page.bloodSize.bottom);
                var lar = ThreeHundredDpiToMm(vm.page.bloodSize.left, vm.page.bloodSize.right);
                vm.page.bloodSize = {
                    top: tab.mm1,
                    bottom: tab.mm2,
                    left: lar.mm1,
                    right: lar.mm2,
                }

                var editWah = ThreeHundredDpiToMm(vm.page.editbox.width, vm.page.editbox.height);
                var editXay = ThreeHundredDpiToMm(vm.page.editbox.x, vm.page.editbox.y);
                vm.page.editbox = {
                    width: editWah.mm1,
                    height: editWah.mm2,
                    x: editXay.mm1,
                    y: editXay.mm2,
                }

            }


        }

        // mm---px
        function ThreeHundredDpi(attr1, attr2) {
            // Math.floor(px / 300 * 25.4)   (px--mm)
            // Math.ceil(mm / 25.4 * 300)   (mm---px)
            var px1 = parseFloat((attr1 / 25.4 * 300 - 0)).toFixed(2) - 0;
            var px2 = parseFloat((attr2 / 25.4 * 300 - 0)).toFixed(2) - 0;
            return {
                px1: px1,
                px2: px2
            }
        }

        // px---mm
        function ThreeHundredDpiToMm(attr1, attr2) {
            var mm1 = parseFloat((attr1 / 300 * 25.4 - 0)).toFixed(2) - 0;
            var mm2 = parseFloat((attr2 / 300 * 25.4 - 0)).toFixed(2) - 0;
            return {
                mm1: mm1,
                mm2: mm2
            }
        }

        $scope.validate = function () {
            if (vm.page.pageSize == null || vm.page.pageSize == "" || vm.page.pageSize.width == null || vm.page.pageSize.width == "" || vm.page.pageSize.height == null || vm.page.pageSize.height == "") {
                MessageService.error("请设置页面尺寸");
                return false;
            }
            if (vm.page.dpi == 1) {
                var wah = ThreeHundredDpi(10, 10);
                if (vm.page.pageSize.width < wah.px1 || vm.page.pageSize.height < wah.px2) {
                    MessageService.error("请设置页面尺寸");
                    return false;
                }
            } else {
                if (vm.page.pageSize.width < 0 || vm.page.pageSize.height < 0) {
                    MessageService.error("页面尺寸太小，建议修改画布尺寸为10以上");
                    return false;
                }
            }

            if (vm.page.bloodSize == null || vm.page.bloodSize == "") {
                MessageService.error("请设置出血尺寸");
                return false;
            }

            if (vm.page.editbox == null || vm.page.editbox == "") {
                MessageService.error("请设置可编辑区域");
                return false;
            }
            if (vm.page.name == null || vm.page.name == "") {
                MessageService.error("请输入作品名称");
                return false;
            }
            if (vm.page.pageNum == null || vm.page.pageNum == "") {
                MessageService.error("请输入页数");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            onSaveSuccess(vm.page);
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            console.log(result)
            vm.isSaving = false;
            var pageStorageName = 'page-new';
            if ($stateParams.id) {
                pageStorageName = 'page-' + $stateParams.id;
                localStorage.setItem(pageStorageName, JSON.stringify(result));
                $state.go('page-my-suffix-edit', {id: $stateParams.id}, {reload: true})
                // var url = window.location.href.slice(0, -window.location.hash.length) + '#/page-my-suffix/' + $stateParams.id;
                // window.location.href = url
                // console.log(window.location.href);
            } else if ($stateParams.copy) {
                pageStorageName = 'page-' + $stateParams.copy + "-copy";
                localStorage.setItem(pageStorageName, JSON.stringify(result));
                $state.go('page-my-suffix-copy', {copy: $stateParams.copy}, {reload: true})
            } else {
                localStorage.setItem(pageStorageName, JSON.stringify(result));
                $state.go('page-my-suffix', {reload: true});
            }

        }

        function onSaveError() {
            vm.isSaving = false;
        }

        $scope.removeFile = function () {
            vm.page.cover = {};
        };

        vm.detailImgs = [];
        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'cover-' + UidService.get());
                formData.append('token', vm.token);
                $("#file").val("");
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.page.cover = {
                            identifier:data.key,
                            provider:'qiniu'
                        };
                        var flag = true;        //是否可以添加
                        if (vm.detailImgs.length > 0 && vm.detailImgs.length < 5) {
                            for (var i = vm.detailImgs.length - 1; i >= 0; i--) {
                                if (vm.detailImgs[i] == data.key) {
                                    // 不建议上传相同的图片，没有意义
                                    flag = false;
                                }
                            }
                        }
                        if (vm.detailImgs.length + 1 > 4) {
                            MessageService.error("最多上传四张附件");
                            return;
                        }
                        if (flag) {
                            vm.detailImgs.push(data.key);
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
    }

})();

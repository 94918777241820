(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('platformApp')
        .factory('TxMatrix', TxMatrix);

    function TxMatrix() {
    	var service = {
    		transformPt: transformPt,
    		transformRect: transformRect,
    		getRotateAngle: getRotateAngle,
    		getRotateInstance: getRotateInstance,
    		getScaleValue: getScaleValue
    	};
    	return service;

    	// Matrix definition
    	// [ xx  xy   0
    	//   yx  yy   0
    	//   tx  ty   1 ]
    	// 
    	// [ cos(theta)    -sin(theta)       0
    	//   sin(theta)	   cos(theta)		 0
    	//	   tx             ty             1]
    	//
    	// tm = [xx, xy, yx, yy, tx, ty]

    	// 点的矩阵变换
    	function transformPt(tm, x, y) {
    		var a = tm[0];
    		var b = tm[1];
    		var c = tm[2];
    		var d = tm[3];
    		var e = tm[4];
    		var f = tm[5];

    		return {
    			x: x * a + y * c + e, 
    			y: x * b + y * d + f
    		};
    	}

    	// 矩形的矩阵变换
    	function transformRect(tm, x, y, width, height) {
    		var pt0 = transformPt(tm, x, y);
    		var pt1 = transformPt(tm, x + width, y);
    		var pt2 = transformPt(tm, x, y + height);
    		var pt3 = transformPt(tm, x + width, y + height);

    		var minX = Math.min(pt0.x, Math.min(pt1.x, Math.min(pt2.x, pt3.x)));
    		var maxX = Math.max(pt0.x, Math.max(pt1.x, Math.max(pt2.x, pt3.x)));
    		var minY = Math.min(pt0.y, Math.min(pt1.y, Math.min(pt2.y, pt3.y)));
    		var maxY = Math.max(pt0.y, Math.max(pt1.y, Math.max(pt2.y, pt3.y)));

    		return {
    			x: minX,
    			y: minY,
    			width: maxX - minX,
    			height: maxY - minY
    		};
    	}

    	function getRotateAngle(tm) {
    		var xx = tm[0];
			var xy = tm[1];
			var yx = tm[2];
			var yy = tm[3];

			var ssp = Math.sqrt(Math.pow(xx, 2) + Math.pow(yx, 2), 2);

			// [-90 - 90]
			if (xx >= 0) {
				var degree = Math.asin(yx / ssp) * 180 / Math.PI;
				if (degree < 0) {
					degree += 360;
				}
				return degree;
			}
			// (90, 180)
			else if (xx < 0) {
				return 180 - Math.asin(yx / ssp) * 180 / Math.PI;
			}
    	}

    	function getScaleValue(tm) {
    		var xx = tm[0];
			var xy = tm[1];
			var yx = tm[2];
			var yy = tm[3];

			var ssp = Math.sqrt(Math.pow(xx, 2) + Math.pow(yx, 2), 2);
			return ssp;
    	}

    	// 求逆矩阵
    	function getRotateInstance(angle) {

    		var tm = [];
    		var theta = angleToRad(angle);
    		tm.push(Math.cos(theta));
    		tm.push(-Math.sin(theta));
    		tm.push(Math.sin(theta));
    		tm.push(Math.cos(theta));
    		tm.push(0);
    		tm.push(0);

    		return tm;
    	}

    	function angleToRad(angle) {
    		return angle * Math.PI / 180;
    	}

    }

})();
(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('BuyProductXMLController', BuyProductXMLController);

    BuyProductXMLController.$inject = ['$timeout', '$scope', '$stateParams', 'item', 'ProductXmlPurchase', 'MessageService',
    '$http', '$sce', '$state',  '$uibModalInstance','$localStorage'];

    function BuyProductXMLController ($timeout, $scope, $stateParams,  item, ProductXmlPurchase, MessageService,
        $http, $sce, $state, $uibModalInstance,$localStorage) {
        var vm = this;

        vm.xml = item;
        vm.prepare = false;
        vm.payMethod = "WeChat";
        vm.paymentState = false;
        init();
        function init(){
            var data = {
                productXmlId:vm.xml.id,
                productXmlName:vm.xml.name,
                type: vm.payMethod,
                amount: vm.xml.price,
                name: "模板购买"
            }
            ProductXmlPurchase.create(data ,function(result){
                vm.purchaseId = result.id;
                payInfo();
                $http({
                    url: '/bunny/api/product-xml-purchases/createQRCode/'+vm.purchaseId,
                    method: 'POST',
                    responseType: 'blob'
                }).success(function(res) {
                    vm.prepare = true;
                    var blob = new Blob([res], {
                        type: "image/jpeg"
                    });
                    $scope.image = URL.createObjectURL(blob);
                })
            },function(){
                MessageService.error("创建充值订单失败，请稍后再试");
            })
        }
        $scope.rechargeAlipay = function(){
            vm.paymentState = true;
            var url = $state.href("alipayXml", {item:JSON.stringify(vm.xml)}, {reload: true});
            window.open(url,'_blank');
        }
        $scope.changePayMethod = function(method){
            if (method == null) {
                return;
            }
            vm.payMethod = method;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.reback = function(){
            vm.prepare = false;
            if ($scope.timer) {
                clearInterval($scope.timer);
            }
            $uibModalInstance.close();
        }
        vm.user = $localStorage.user;
        if (vm.user == null) {
            Account.get( {}, function(data){
                if (data.status == 200) {
                    vm.user = data.data;
                }
            });
        }
        function payInfo() {
            //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
            $scope.timer = setInterval(function() {
                vm.paid = false;
                ProductXmlPurchase.getAllByCondition({items:[{key:"paid", op:"=", value:"true"} ,{key:"userId", op:"=", value:vm.user.id}]}, function (res) {
                    if(res.length>0){
                        for(var i = 0; i<res.length ; i++){
                            var purchase = res[i];
                            if (vm.xml.id == purchase.productXmlId) {
                                vm.paid = true;
                                clearInterval($scope.timer);
                                MessageService.success("支付完成");
                                $uibModalInstance.close();
                                break;
                            }
                        }
                    }
                });
                // ProductXmlPurchase.get({
                //     id: vm.purchaseId
                // }, function(val) {
                //     if (!!val && val.paid) {
                //         vm.paid = true;
                //         clearInterval($scope.timer);
                //         MessageService.success("支付完成");
                //         $uibModalInstance.close(val);
                //     }
                // }, function() {});
            }, 2000);
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ModelSelectController', ModelSelectController);

    ModelSelectController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'Moulds', 'MessageService', 'Qinius'];

    function ModelSelectController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, Moulds, MessageService, Qinius) {
        var vm = this;
        vm.type = entity.type;
        vm.modelData = entity.data;
        vm.modelType = entity.modelType;
        vm.cancel = cancel;
        vm.save = save;
        vm.search = "";
        $scope.domains = Qinius.getDomain();
        vm.model = null;
        vm.data = [];


        init()
        function init(){
            vm.data = [];
            var items = [{key: "name", op: "like", value: vm.search},
                {key: "sourceType", op: "=", value: 'front'}];
            if(vm.modelType == 'THREE'){
                items.push({key: "modelType", op: "=", value: 'THREE'})
            }
            if(vm.modelType == 'TWO'){
                items.push({key: "modelType", op: "=", value: 'TWO'})
            }
            Moulds.getAllUsable({
                name: vm.search,
                modelType: vm.modelType
            }, function (res) {
                if(vm.modelType == 'TWO'){
                    for(var i=0; i<res.length; i++){
                        if(!res[i].cancelled){
                            if(vm.type == 'edit'){
                                if(vm.modelData.mould && res[i].id == vm.modelData.mould.id){
                                    res[i].selected = true;
                                    vm.model = res[i];
                                }
                            }
                            vm.data.push(res[i]);
                        }
                    }
                }
                if(vm.modelType == 'THREE'){
                    for(var i=0; i<res.length; i++){
                        if(!res[i].cancelled){
                            if(vm.type == 'edit'){
                                if(vm.modelData.mould && res[i].id == vm.modelData.mould.id){
                                    res[i].selected = true;
                                    vm.model = res[i];
                                }
                            }
                            vm.data.push(res[i]);
                        }
                    }
                }
            }, function (error) {
                MessageService.error("获取列表失败！");
            });
        }
        vm.selectTable = selectTable;
        function selectTable(event){
            if (!event.which === 13) {
                return
            }
            init()
        }

        vm.check = check;
        function check(item) {
            for(var i=0; i<vm.data.length; i++){
                if(vm.data[i].id != item.id){
                    vm.data[i].selected = false;
                }
            }
            item.selected = !item.selected ? true : false;
            if(item.selected){
                vm.model = item;
            }else {
                vm.model = null;
            }
        }
        //保存
        function save() {
            if(!vm.model){
                MessageService.error("请选择模型！");
                return
            }
            $uibModalInstance.close(vm.model);
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
    }
})();

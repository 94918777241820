(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarCenterBodyController', CalendarCenterBodyController);

        CalendarCenterBodyController.$inject = ['$state', '$scope', 'ProductXml', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', 'MonthWidget', 'TitleWidget', '$localStorage', 'Qinius', '$uibModal','$timeout'];

    function CalendarCenterBodyController($state, $scope, ProductXml, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, MonthWidget, TitleWidget, $localStorage, Qinius, $uibModal,$timeout) {
        var vm = this;
        //搜索防抖定时器
        vm.timer = null;
        //分页信息
        vm.pageInfo = {
            page:1,
            size:20,
            total:0,
        }
        //分类
        vm.viewType = "calendar";
        vm.loadPage = loadPage;
        vm.predicate = "id";
        vm.reverse = false;
        vm.transition = transition;
        vm.search = search;
        vm.getList = getList;
        vm.searchQuery = "";
        vm.currentSearch = pagingParams.search;
        vm.account = $localStorage.user;
        $scope.domains = Qinius.getDomain();

        vm.getList();

        $scope.delete = function(id){
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除该数据？'
            },function(){
                MonthWidget.delete({id: id},function () {
                    vm.getList();
                });
            },function(){});
        };
        //当前页变更
        vm.currentChange = function(){
            $timeout(function(){
                vm.getList();
            })
        }
        function getList(){
            if(!vm.account){
                return $state.go('home');
            }
            var items = [
                {key:"status", op:"=", value:'Approved'}
            ];
            if (vm.searchQuery) {
                items.push({key:"name",value:vm.searchQuery, op:"like"})
            }
            //日历组件
            if(vm.viewType == "calendar"){
                MonthWidget.byCondition3({
                    items:items,
                    page: vm.pageInfo.page - 1,
                    size: vm.pageInfo.size,
                    sort: ["lastModifiedDate,desc"]
                },
                onSuccess, onError);
            }else if(vm.viewType == "title"){
                //标题组件
                TitleWidget.byCondition3({
                    items:items,
                    page: vm.pageInfo.page -1,
                    size: vm.pageInfo.size,
                    sort: ["lastModifiedDate,desc"]
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.pageInfo.total = headers('X-Total-Count');
                vm.titleList = data;
            }
            function onError(error) {
                MessageService.error("请求失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search() {
            if(event.keyCode === 13){
                vm.links = null;
                vm.pageInfo.page = 1;
                vm.predicate = 'id';
                vm.reverse = false;
                vm.getList();
            }
        }

        //分类点击
        $scope.clickTypeItem = function(data){
            if(vm.viewType == data) return;
            vm.viewType = data;
            vm.pageInfo.page = 1;
            $timeout(function(){
                vm.getList();
            })

        }
        //查看
        $scope.look = function (data) {
            //日历组件
            if(vm.viewType == "calendar"){
                $uibModal.open({
                    templateUrl: 'app/entities/calendar-center/calendar-center-body-preview-model.html',
                    controller: 'CalendarCenterBodyPreviewModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function() {
                            return {
                                data:data
                            };
                        }]
                    }
                });
            }
            //标记组件
            if(vm.viewType == "title"){
                $uibModal.open({
                    templateUrl: 'app/entities/calendar-center/calendar-center-title-preview-model.html',
                    controller: 'CalendarCenterTitlePreviewModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function() {
                            return {
                                data:data
                            };
                        }]
                    }
                });
            }


        }
        $scope.save = function(data){
            //日历插件
            if(vm.viewType == "calendar"){
                MonthWidget.get({id: data.id}, function (res) {
                    console.log(res)
                    res.id = "";
                    res.opened = false;
                    res.status = null;
                    MonthWidget.create(res, function (res) {
                        MessageService.success("使用成功");
                        $state.go('calendar-xml',{id: res.message.id})
                    })
                })
            }
            //标题插件
            if(vm.viewType == "title"){
                TitleWidget.get({id: data.id}, function (res) {
                    console.log(res)
                    res.id = "";
                    res.opened = false;
                    res.status = null;
                    TitleWidget.create(res, function (res) {
                        MessageService.success("使用成功");
                        $state.go('calendar-title-xml',{id: res.message.id})
                    })
                })
            }

        }
    }
})();

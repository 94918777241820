(function () {
    "use strict";

    angular
        .module("platformApp")
        .controller("CalendarController", CalendarController);

    CalendarController.$inject = [
        "$state",
        "$scope",
        "ProductXml",
        "ParseLinks",
        "AlertService",
        "paginationConstants",
        "pagingParams",
        "MessageService",
        "MonthWidget",
        "$localStorage",
        "Qinius",
        "$uibModal",
        "TitleWidget",
        "$timeout",
        "$stateParams"
    ];

    function CalendarController(
        $state,
        $scope,
        ProductXml,
        ParseLinks,
        AlertService,
        paginationConstants,
        pagingParams,
        MessageService,
        MonthWidget,
        $localStorage,
        Qinius,
        $uibModal,
        TitleWidget,
        $timeout,
        $stateParams
    ) {
        var vm = this;
        vm.oldView = false;
        //搜索防抖定时器
        vm.timer = null;
        //分页信息
        vm.pageInfo = {
            page: 1,
            size: 20,
            total: 0,
        };
        //批量操作状态
        vm.batchState = false;
        vm.statusList = [
            { label: "全部", value: "", op: "=" },
            { label: "未分享", value: null, op: "isnull" },
            { label: "审核中", value: "Created", op: "=" },
            { label: "已分享", value: "Approved", op: "=" },
            { label: "已驳回", value: "Rejected", op: "=" },
        ];
        vm.pageView = $stateParams.type || "calendar";
        //分类
        vm.genreList = [
            { label: "日历组件", value: "calendar" },
            { label: "标题组件", value: "calendarTitle" },
        ];
        //选中的状态
        vm.selectStatus = "";
        vm.selectCount = 0;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.account = $localStorage.user;
        $scope.domains = Qinius.getDomain();
        vm.status = status;

        loadAll();

        $scope.delete = function (item) {
            MessageService.confirm(
                {
                    title: "提示",
                    cancel: "取消",
                    confirm: "确认",
                    msg: "确定要删除该数据？"
                },
                function () {
                    if (vm.pageView == "calendar") {
                        MonthWidget.delete({ id: item.id }, function () {
                            loadAll();
                        });
                    } else if (vm.pageView == "calendarTitle") {
                        TitleWidget.delete({ id: item.id }, function () {
                            loadAll();
                        });
                    }
                },
                function () {}
            );
        };
        $scope.batchDelete = function(){
            if (vm.batchDeleteLoading) return;
            //获取选中的
            var selectList = vm.textList.filter(function (item) {
                return item.selected === true;
            })
            if(selectList.length === 0 ) return MessageService.error("至少选择一条数据");
            var ids = selectList.map(function (item) {
                return item.id;
            });
            MessageService.confirm(
                {
                    title: "提示",
                    cancel: "取消",
                    confirm: "确认",
                    msg: "确定要删除选中的数据？",
                },
                function () {
                    vm.batchDeleteLoading = true;
                    if (vm.pageView == "calendar") {
                        MonthWidget.batchDelete(ids,function(){
                            MessageService.success("删除成功");
                            vm.batchDeleteLoading = false;
                            vm.loadAll();
                        },function(){
                            MessageService.error("网络异常，请重试");
                            vm.batchDeleteLoading = false;
                        })
                    } else if (vm.pageView == "calendarTitle") {
                        TitleWidget.batchDelete(ids,function(){
                            MessageService.success("删除成功");
                            vm.batchDeleteLoading = false;
                            vm.loadAll();
                        },function(){
                            MessageService.error("网络异常，请重试");
                            vm.batchDeleteLoading = false;
                        })
                    }
                },
                function () {}
            );
        }
        //分页变更
        vm.currentChange = function () {
            $timeout(function () {
                vm.loadAll();
            });
        };
        //批量操作选择
        $scope.checkItem = function(item){
            if (!vm.batchState) {
                return
            }
            if(!item.selected){
                item.selected = true;
            }else{
                item.selected = false;
            }
            //获取选中的数量
            var selectList = vm.textList.filter(function (item) {
                return item.selected === true;
            })
            vm.selectCount = selectList.length;
        };
        function loadAll() {
            if (vm.account == undefined || vm.account == null) {
                $state.go("home");
                return;
            }
            var items = [{key:"builtin", op:"=", value:"false"}];
            var status = vm.statusList.find(function (item) {
                return item.value === vm.selectStatus;
            });
            if (status.value !== "") {
                items.push({
                    key: "status",
                    value: status.value,
                    op: status.op,
                });
            }
            if (vm.searchQuery) {
                items.push({
                    key: "name",
                    value: vm.searchQuery,
                    op: "like",
                });
            }
            if (vm.pageView == "calendar") {
                //日历
                MonthWidget.byCondition(
                    {
                        items: items,
                        page: vm.pageInfo.page - 1,
                        size: vm.pageInfo.size,
                        sort: ["lastModifiedDate,desc"],
                    },
                    onSuccess,
                    onError
                );
            } else if (vm.pageView == "calendarTitle") {
                TitleWidget.byCondition(
                    {
                        items: items,
                        page: vm.pageInfo.page - 1,
                        size: vm.pageInfo.size,
                        sort: ["lastModifiedDate,desc"],
                    },
                    onSuccess,
                    onError
                );
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.pageInfo.total = headers("X-Total-Count");
                vm.textList = data;
                //退出编辑模式
                vm.batchState = false;
                vm.selectCount = 0;
            }

            function onError(error) {
                MessageService.error("文字模板获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
                type:vm.pageView
            });
        }
        //状态点击
        $scope.statusCheck = function (e) {
            if (e === vm.selectStatus) return;
            vm.selectStatus = e;
            vm.pageInfo.page = 1;
            vm.loadAll();
        };
        function search() {
            if(event.keyCode === 13){
                vm.links = null;
                vm.pageInfo.page = 1;
                vm.predicate = "id";
                vm.reverse = false;
                vm.loadAll();
            }
        }
        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.release = function (data) {
            MonthWidget.get({ id: data.id }, function (res) {
                $uibModal
                    .open({
                        templateUrl:
                            "app/entities/text/modal/text-detection.html",
                        controller: "TextDetectionController",
                        controllerAs: "vm",
                        size: "lg",
                        resolve: {
                            entity: [
                                function () {
                                    return {
                                        textDocument: res.textDocument,
                                        id: res.id,
                                        storeIds: [],
                                    };
                                },
                            ],
                        },
                    })
                    .result.then(function (isReturn) {});
            });

            // MonthWidget.onlyRelease({id: data.id}, function (res) {
            //     MessageService.success("发布成功");
            //     loadAll();
            // }, function (error) {
            //     MessageService.error("发布失败，请稍后重试！");
            // })
        };
        //分类切换
        $scope.selectGenre = function (e) {
            if (vm.pageView === e.value) return;
            vm.pageView = e.value;
            vm.pageInfo.page = 1;
            //vm.loadAll();
            transition();
        };
        //查看驳回原因
        $scope.showReason = function (item) {
            MessageService.confirm({
                title:'驳回理由',
                cancel:'取消',
                hideCancel:true,
                confirm:'知道了',
                msg:'理由： '+ (item.rejectReason || '') ,
            },function(){

            })
        }
        //分享
        $scope.share = function (data) {
            if (vm.pageView == "calendar") {
                MonthWidget.share({ id: data.id }, function (res) {
                    MessageService.success("分享成功");
                    loadAll();
                });
            } else if (vm.pageView == "calendarTitle") {
                TitleWidget.share({ id: data.id }, function (res) {
                    MessageService.success("分享成功");
                    loadAll();
                });
            }
        };
        //批量分享
        $scope.batchShare = function() {
            if (vm.batchShareLoading) return;
            //获取选中的
            var selectList = vm.textList.filter(function (item) {
                return item.selected === true ;
            })
            if(selectList.length === 0 ) return MessageService.error("至少选择一条数据");
            //过滤掉已分享的数据
            selectList = selectList.filter(function (item) {
                return item.status !== 'Approved';
            });
            var ids = selectList.map(function (item) {
                return item.id;
            });
            vm.batchShareLoading = true;
            if (ids.length > 0){
                if(vm.pageView === 'calendar'){
                    MonthWidget.batchShare(ids,function(){
                        MessageService.success("分享成功");
                        vm.batchShareLoading = false;
                        vm.loadAll();
                    },function(){
                        MessageService.error("网络异常，请重试");
                        vm.batchShareLoading = false;
                    })
                }else if(vm.pageView === 'calendarTitle'){
                    TitleWidget.batchShare(ids,function(){
                        MessageService.success("分享成功");
                        vm.batchShareLoading = false;
                        vm.loadAll();
                    },function(){
                        MessageService.error("网络异常，请重试");
                        vm.batchShareLoading = false;
                    })
                }

            }else{
                vm.batchShareLoading = false;
                vm.loadAll();
            }
        }
        //查看
        $scope.look = function (data) {
            if(vm.pageView == "calendar" && !data.previewUrl){
                return MessageService.error("暂无预览图");
            }
            $uibModal.open({
                templateUrl: "app/entities/text/modal/text-xml-look.html",
                controller: "TextXmlLookController",
                controllerAs: "vm",
                backdrop: "static",
                resolve: {
                    entity: [
                        function () {
                            return {
                                url: data.previewUrl,
                                domain: $scope.domains.domain,
                            };
                        },
                    ],
                },
            });

        };
        //新增
        $scope.add = function (type) {
            if(type == "calendar"){
                $uibModal.open({
                    templateUrl: "app/entities/calendar/modal/calendar-add.html",
                    controller: "CalendarAddController",
                    controllerAs: "vm",
                    backdrop: "static",
                    size:'lg',
                    resolve: {
                        entity: [
                            function () {
                                return null;
                            },
                        ],
                    },
                }).result.then(function() {
                    loadAll();
                });;
            }else if(type == "calendarTitle"){
                $uibModal.open({
                    templateUrl: 'app/entities/calendar/modal/calendar-title-add.html',
                    controller: 'CalendarTitleAddController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size:'lg',
                    resolve: {
                        entity: [function() {
                            return null;
                        }]
                    }
                }).result.then(function(res) {
                    if (res) {
                        loadAll();
                    }
                });
            }

        };
        //复制
        $scope.copy = function (item) {
            var _data = angular.copy(item);
            _data.name = _data.name + "_副本";
            if (vm.pageView == "calendar") {
                $uibModal.open({
                    templateUrl:
                        "app/entities/calendar/modal/calendar-add.html",
                    controller: "CalendarAddController",
                    controllerAs: "vm",
                    backdrop: "static",
                    size:'lg',
                    resolve: {
                        entity: [
                            function () {
                                return _data;
                            },
                        ],
                    },
                });
            } else if (vm.pageView == "calendarTitle") {
                $uibModal.open({
                    templateUrl:
                        "app/entities/calendar/modal/calendar-title-add.html",
                    controller: "CalendarTitleAddController",
                    controllerAs: "vm",
                    backdrop: "static",
                    size:'lg',
                    resolve: {
                        entity: [
                            function () {
                                return _data;
                            },
                        ],
                    },
                });
            }
        };

        function status(item) {
            switch (item) {
                case "Created":
                    return "已分享";
                    break;
                case "Approved":
                    return "已通过";
                    break;
                case "Rejected":
                    return "已拒绝";
                    break;
                default:
                    return "未分享";
            }
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FrameControlDialogController', FrameControlDialogController);

        FrameControlDialogController.$inject = ['$state','$timeout', "$q", '$scope', 'UidService', '$stateParams', '$uibModalInstance', 'entity', 'FrameConfig', 'Qinius',
            'MessageService','ThemeClassify','$http','$uibModal'];

    function FrameControlDialogController ($state,$timeout, $q, $scope, UidService, $stateParams, $uibModalInstance, entity, FrameConfig, Qinius, MessageService,ThemeClassify, $http,$uibModal) {
        var vm = this;
        //是否是编辑模式
        vm.frameCopy = null;
        vm.isEdit = false;
        if (entity.message) {
            vm.isEdit = true;
            vm.frame = entity.message;
            $http({url: vm.frame.img + '?imageInfo'}).success(function (data) {
                vm.imgHeight = data.height;
                vm.imgWidth = data.width;
                vm.imgZoom = 236 / vm.imgWidth
                vm.frame.marginTop1 =Math.round( vm.frame.marginTop * (vm.imgZoom * vm.imgHeight) / (1 + vm.frame.marginBottom /100 + vm.frame.marginTop /100) / (vm.imgZoom * vm.imgHeight) );
                vm.frame.marginBottom1 =Math.round( vm.frame.marginBottom * (vm.imgZoom * vm.imgHeight) / (1 + vm.frame.marginBottom /100 + vm.frame.marginTop /100) / (vm.imgZoom * vm.imgHeight) );
                vm.frame.marginLeft1 =Math.round( vm.frame.marginLeft * (vm.imgZoom * vm.imgWidth) / (1 + vm.frame.marginLeft /100 + vm.frame.marginRight /100) / (vm.imgZoom * vm.imgWidth) );
                vm.frame.marginRight1 =Math.round( vm.frame.marginRight * (vm.imgZoom * vm.imgWidth) / (1 + vm.frame.marginLeft /100 + vm.frame.marginRight /100) / (vm.imgZoom * vm.imgWidth) );
                vm.frameCopy = angular.copy( vm.frame );
            }).error(function (error) {});

        }else{
            vm.frame = entity
            vm.frame.marginTop1 = 15;
            vm.frame.marginBottom1 = 15;
            vm.frame.marginLeft1 = 15;
            vm.frame.marginRight1 = 15;
            vm.frameCopy = angular.copy( vm.frame );
        }

        vm.clear = clear;
        vm.save = save;
        vm.openedCheck = openedCheck;
        vm.step='first';
        vm.shape=[
            {name:'圆形',value:'Ellipse'},
            {name:'方型',value:'Rectangle'}
        ]
        vm.attachments =[];
        vm.imgs = [];
        vm.keys = [];
        vm.themeClassifies = ThemeClassify.getAllByCondition1({items:[{key:"type",value:"BORDER", op:"="},{key:"used",value:"true", op:"="}]});

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });

        vm.domain = Qinius.getDomain();
        $q.all([ vm.domain.$promise]).then(function(){
            vm.domainQ =   vm.domain.domain;
        })

        function openedCheck(flag) {
            vm.frame.enable = flag;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        function onSaveSuccess (result) {
            MessageService.success("边框素材保存成功");
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        function onSaveError () {
            MessageService.error("边框素材保存失败");
            vm.isSaving = false;
        };

        var bigSizePics = [];
        //批量上传素材
        window.updateImgFile = function ($event) {
            $timeout(function () {
                bigSizePics = [];
                for(var i=0;i<$event.target.files.length;i++){
                    var file = $event.target.files[i];
                    vm.imgs.push(file.name);
                    if (file.size >= 20 * 1024 * 1024) {
                        file.imageSize = Math.ceil(file.size / 1024 / 1024);
                        bigSizePics.push(file);
                    } else {
                        fileGet(file,i);
                    }
                }
                if (bigSizePics.length > 0) {
                    thumbnailatorPic();
                }
                $event.srcElement.value = ""
            });
        };
        function fileGet(file,i) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'mask-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.attachments.push({identifier:data.key,fileName:vm.imgs[i]});
                    vm.keys.push(data.key);
                    vm.frame.img = vm.domainQ + data.key;
                    $http({url: vm.frame.img + '?imageInfo'}).success(function (data) {
                        vm.imgHeight = data.height;
                        vm.imgWidth = data.width;
                        vm.imgZoom = 236 / vm.imgWidth
                    }).error(function (error) {});
                    },
                error: function (request) {
                    MessageService.error("文件上传失败");
                }
            });
        }

        $scope.nextStep=function(){
            if (!vm.frame.themeClassifyId) {
                MessageService.error('请选择边框分类')
                return;
            }
            if (!vm.frame.name) {
                MessageService.error('请输入名称')
                return;
            }
            if (!vm.frame.seq) {
                MessageService.error('请输入排序')
                return;
            }
            if (!vm.frame.img) {
                MessageService.error('请上传边框')
                return;
            }
            vm.step='second'
        }
        function save () {
            //修改标签
            if($state.current.name === "frame-control.updateTag"){
               return FrameConfig.put(vm.frame,onSaveSuccess,onSaveError)
            }
            var photoFrame = document.getElementById('photoFrame');
            var width = photoFrame.offsetWidth;
            var height = photoFrame.offsetHeight;
            var x = $('#photoFrame').offset().top;
            var y = $('#photoFrame').offset().left;
            html2canvas_adapt(photoFrame,{
                onrendered: function (canvas) {
                    var url = canvas.toDataURL("image/png");
                    var blob = dataURLtoBlob(url);
                    var formData = new FormData();
                    formData.append('file', blob);
                    formData.append('key', 'cover-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            vm.frame.cover=vm.domainQ + data.key
                            console.log(vm.frame.cover);
                            vm.frame.marginBottom = $scope.HeightBottom() /$scope.photoHeight() *100
                            vm.frame.marginTop = $scope.HeightTop() /$scope.photoHeight() *100
                            vm.frame.marginLeft = $scope.WidthLeft() /$scope.photoWidth() *100
                            vm.frame.marginRight = $scope.WidthRight() /$scope.photoWidth() *100
                            vm.frame.type='USER';
                            if (entity.message) {
                                FrameConfig.put(vm.frame,onSaveSuccess,onSaveError)
                            }else{
                                FrameConfig.forUser(vm.frame,onSaveSuccess,onSaveError)
                            }
                        }
                    })
                },
                letterRendering: true,
                useCORS: true,
                width: width,
                height: height,
                windowWidth: document.body.scrollWidth,
                windowHeight: document.body.scrollHeight,
                x:x,
                y:y,
                backgroundColor: "rgba(0, 0, 0, 0)"
            })
        };

        function dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/) ? arr[0].match(/:(.*?);/)[1] : null,
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type: mime});
        }
        // 计算照片孔宽高
        $scope.photoWidth = function(){
            return (vm.imgZoom * vm.imgWidth) * (1  - vm.frame.marginLeft1 /100 - vm.frame.marginRight1 /100)
        }
        $scope.photoHeight = function(){
            return (vm.imgZoom * vm.imgHeight) * (1  - vm.frame.marginTop1 /100 - vm.frame.marginBottom1 /100)
        }
        $scope.HeightTop  = function(){
            return (vm.imgZoom * vm.imgHeight) * vm.frame.marginTop1 /100;
        }
        $scope.HeightBottom  = function(){
            return (vm.imgZoom * vm.imgHeight) * vm.frame.marginBottom1 /100;
        }
        $scope.WidthLeft  = function(){
            return (vm.imgZoom * vm.imgWidth) * vm.frame.marginLeft1 /100;
        }
        $scope.WidthRight  = function(){
            return (vm.imgZoom * vm.imgWidth) * vm.frame.marginRight1 /100;
        }
        // 换算
        $scope.marginTop = function(){
            if (vm.frameCopy.marginTop1 + vm.frameCopy.marginBottom1 >= 100) {
                vm.frameCopy.marginTop1 = 100 - vm.frameCopy.marginBottom1
                MessageService.error('上下边距之和不能大于100,当前上边距最大值是：'+vm.frameCopy.marginTop1)
            }
            vm.frame.marginTop1 = vm.frameCopy.marginTop1;
        }
        $scope.marginBottom = function(){
            if (vm.frameCopy.marginTop1 + vm.frameCopy.marginBottom1 >= 100) {
                vm.frameCopy.marginBottom1 = 100 - vm.frameCopy.marginTop1
                MessageService.error('上下边距之和不能大于100,当前下边距最大值是：'+vm.frameCopy.marginBottom1)
            }
            vm.frame.marginBottom1 = vm.frameCopy.marginBottom1;
        }
        $scope.marginLeft = function(){
            if (vm.frameCopy.marginLeft1 + vm.frameCopy.marginRight1 >= 100) {
                vm.frameCopy.marginLeft1 = 100 - vm.frameCopy.marginRight1;
                MessageService.error('左右边距之和不能大于100,当前左边距最大值是：'+vm.frameCopy.marginLeft1)
            }
            vm.frame.marginLeft1 = vm.frameCopy.marginLeft1;
        }
        $scope.marginRight = function(){
            if (vm.frameCopy.marginLeft1 + vm.frameCopy.marginRight1 >= 100) {
                vm.frameCopy.marginRight1 = 100 - vm.frameCopy.marginLeft1
                MessageService.error('左右边距之和不能大于100,当前右边距最大值是：'+vm.frameCopy.marginRight1)
            }
            vm.frame.marginRight1 = vm.frameCopy.marginRight1;
        }

        function thumbnailatorPic(){
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics = [];
                if (values) {
                    //for (var j = 0; j < values.length; j++) {

                    //var data = {key: value.key};
                    for (var i = 0; i < values.length; i++) {
                        var value = values[i];
                        fileGet(value,i);
                    }
                    //}
                }
            }, function () {
            });false
        }

    }
})();

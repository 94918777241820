(function () {
    "use strict";

    angular.module("platformApp").controller("FontManagementDialogController", FontManagementDialogController);

    FontManagementDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$uibModalInstance",
        "entity",
        "FontManagement",
        "Upload",
        "MessageService"
    ];

    function FontManagementDialogController(
        $timeout,
        $scope,
        $stateParams,
        $uibModalInstance,
        entity,
        FontManagement,
        Upload,
        MessageService
    ) {
        var vm = this;
        //选择的文件
        vm.files = [];
        //上传Loading
        vm.uploading = false;
        //是否展示导入按钮
        vm.showUpload = true;
        vm.saveText = "上传";
        vm.clear = clear;
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });
        $(document).ready(function () {
            if (window.history && window.history.pushState) {
                $(window).on("popstate", function () {
                    clear();
                });
            }
            //文件拖曳上传
            $("#upload-font-box").bind("dragenter", function (event) {
                event.preventDefault();
            });
            $("#upload-font-box").bind("dragover", function (event) {
                event.preventDefault();
            });
            $("#upload-font-box").bind("drop", function (event) {
                event.preventDefault();
                var files = event.originalEvent.dataTransfer.files;
                var reg = /(font\/otf)|(font\/ttf)|(font\/fon)/gi;
                if (files.length > 30) return MessageService.error("选择文件不能超过30个");
                $timeout(function () {
                    for (var i = 0; i < files.length; i++) {
                        console.log(files[i]);
                        //判断文件类型与文件大小符合要求
                        if (
                            (reg.test(files[i].type) || /\.(otf|ttf|fon)$/.test(files[i].name)) &&
                            files[i].size < 20 * 1024 * 1024
                        ) {
                            files[i].status = "wait";
                            vm.files.push(files[i]);
                        }
                    }
                    if (vm.files.length === 0) return MessageService.error("请选择小于20M正确的字体文件");
                });
            });
        });

        function clear() {
            if (vm.uploading) {
                MessageService.info("正在上传字体，请稍后再关闭窗口");
                return;
            }
            $uibModalInstance.dismiss("cancel");
        }

        function fileGet(index) {
            if (vm.uploading || index > vm.files.length - 1) return;
            vm.uploading = true;
            vm.showUpload = false;
            var file = vm.files[index];
            file.status = "loading";
            Upload.upload({
                type: "POST",
                url: "bunny/api/font-managements/upload", //上传路径
                data: {
                    file: file,
                    name: file.name,
                    type: "User"
                }
            }).then(
                function successCallback(res) {
                    vm.uploading = false;
                    if (res.status && res.status == 200) {
                        file.status = "success";
                    } else {
                        file.status = "error";
                    }
                    if (vm.files && vm.files.length - 1 > index) {
                        fileGet(index + 1);
                    }

                    $scope.$emit("platformApp:bFontManagementUpdate");
                },
                function errorCallback(response) {
                    file.status = "error";
                    vm.uploading = false;
                    if (vm.files && vm.files.length - 1 > index) {
                        fileGet(index + 1);
                    }
                }
            );
        }

        //字体上传
        window.updateImgFile = function ($event) {
            $scope.$digest();
            if ($event.target.files.length > 30 || vm.files.length + $event.target.files.length > 30) {
                MessageService.error("选择文件太多，请重新选择");
                return;
            }
            vm.saveText = "上传";
            $timeout(function () {
                for (var i = 0; i < $event.target.files.length; i++) {
                    var file = $event.target.files[i];
                    if (file.size < 20 * 1024 * 1024) {
                        file.status = "wait";
                        vm.files.push(file);
                    } else {
                        MessageService.error("选择的文件不能大于20M");
                    }
                }
            });
        };
        //删除字体
        $scope.deleteFont = function (index) {
            vm.files.splice(index, 1);
        };
        $scope.save = function () {
            if (vm.files.length === 0) return;
            fileGet(0);
        };
    }
})();

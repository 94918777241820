(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('FontXmls', FontXmls)
        .factory('ThemeClassify', ThemeClassify);
    FontXmls.$inject = ['$resource'];
    ThemeClassify.$inject = ['$resource'];
    function FontXmls($resource) {
        var resourceUrl = 'bunny/' + 'api/font-xmls/:path/:id';

        return $resource(resourceUrl, {}, {
            "save": {
                method: 'POST'
            },
            "delete": {
                method: 'DELETE'
            },
            "batchDelete":{//批量删除
                method:"POST",
                params:{
                    path:"batchDelete"
                }
            },
            "release": {
                method: 'POST',
                params:{
                    path:"release"
                },
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray:true
            },
            "getAll":{
                method:"POST",
                params:{
                    path:"getAll"
                },
                isArray:true
            },
            "getAll2":{
                method:"POST",
                params:{
                    path:"getAll2"
                },
                isArray:true
            },
            "batchUse":{
                method:"POST",
                params:{
                    path:"batchUse"
                },
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'onlyRelease': {
                method: 'GET',
                params:{
                    path:"onlyRelease"
                },
            },
            'share': {
                method: 'GET',
                params:{
                    path:"share"
                },
            },
            'batchShare':{//批量分享
                method: 'POST',
                params:{
                    path:'batchShare'
                }
            },
            'auditing': {
                method: 'GET',
                params:{
                    path:"auditing"
                },
            },
        });
    }
    function ThemeClassify ($resource) {
        var resourceUrl =  'bunny/' + 'api/theme-classify/:path/:id';
        return $resource(resourceUrl, {}, {
            "saveForUser":{
                method:"POST",
                params:{
                    path:"saveForUser"
                }
            },
            "byCondition1":{
                method:"POST",
                params:{
                    path:"byCondition1"
                },
                isArray:true
            },
            "getAllByCondition1":{
                method:"POST",
                params:{
                    path:"getAllByCondition1"
                },
                isArray:true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                },
                isArray:true
            },
            "byCondition4":{
                method:"GET",
                params:{
                    path:"byCondition4"
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray:true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray:true
            },
            "getAllByCondition3":{
                method:"POST",
                params:{
                    path:"getAllByCondition3"
                },
                isArray:true
            },
            "getAllByCondition4":{
                method:"GET",
                params:{
                    path:"getAllByCondition4"
                }
            },
            "del":{
                method:"DELETE"
            },
            "changeStatue":{
                method:"GET",
                params:{
                    path:"changeStatue"
                }
            },
            "get":{
                method:"GET"
            },
            "save":{
                method:"PUT"
            }
        });
    }
})();

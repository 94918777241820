(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('frame-control', {
            parent: 'app',
            url: '/frame-control?page&sort&search&c',
            data: {
                authorities: [],
                pageTitle: 'platformAppTitle.myUpload'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/frame-control/frame-control.html',
                    controller: 'FrameControlController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null,
                c:null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        c: $stateParams.c
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('frame');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('title');
                    return $translate.refresh();
                }]
            }
        })
        .state('frame-control.new', {
            parent: 'frame-control',
            url: '/new',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/frame-control/frame-control-dialog.html',
                    controller: 'FrameControlDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                enable:true,
                                seq:null,
                                img:null,
                                shape:null,
                                marginTop:15,
                                marginBottom:15,
                                marginLeft:15,
                                marginRight:15,
                                themeClassifyId:null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('frame-control', {}, {reload: true});
                }, function() {
                    $state.go('frame-control');
                });
            }]
        })
        .state('frame-control.edit', {
            parent: 'frame-control',
            url: '/{id}/edit',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/frame-control/frame-control-dialog.html',
                    controller: 'FrameControlDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['FrameConfig', function(FrameConfig) {
                            return FrameConfig.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('frame-control', {}, {reload: true});
                }, function() {
                    $state.go('frame-control');
                });
            }]
        })

        .state('frame-control.updateTag', {
            parent: 'frame-control',
            url: '/{id}/updateTag',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/frame-control/update-tag.html',
                    controller: 'FrameControlDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['FrameConfig', function(FrameConfig) {
                            return FrameConfig.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('frame-control', {}, {reload: true});
                }, function() {
                    $state.go('frame-control');
                });
            }]
        })
         ;


    }

})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CatalogDialogController', CatalogDialogController);

    CatalogDialogController.$inject = ['$timeout', '$q', '$scope', '$stateParams', '$uibModalInstance','Catalog','entity','MessageService', 'ThemeClassify'];

    function CatalogDialogController ($timeout, $q, $scope, $stateParams, $uibModalInstance, Catalog,entity, MessageService, ThemeClassify) {
        var vm = this;

        vm.catalog = entity;
        vm.clear = clear;
        vm.catalogType = $stateParams.catalogType
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function(){
            if(vm.catalog.name == null || vm.catalog.name==""){
                MessageService.error("请输入品类名称");
                return false;
            }
            if(vm.catalog.seq == null || vm.catalog.seq==""){
                MessageService.error("请输入序号");
                return false;
            }
            return true;
        };

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if(vm.catalogType=='CLIPART' || vm.catalogType=='BORDER'){
                vm.catalog.type = vm.catalogType;
                ThemeClassify.saveForUser(vm.catalog, onSaveSuccess, onSaveError);
                return;
            }

            if($stateParams.id){
                Catalog.updateCatalog({
                    id:$stateParams.id,
                    name:vm.catalog.name,
                    type:'User',
                    seq:vm.catalog.seq,
                    catalogType:vm.catalog.catalogType
                }, onSaveSuccess, onSaveError);
            }else{
                Catalog.createCatalog({
                    name:vm.catalog.name,
                    type:'User',
                    seq:vm.catalog.seq,
                    catalogType:vm.catalog.catalogType
                }, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            MessageService.success("成功");
            $scope.$emit('backApp:catalogUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("失败");
            vm.isSaving = false;
        }
    }
})();

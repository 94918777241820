(function() {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('platformApp')
        .factory('TextUtilService', TextUtilService);

    function TextUtilService () {
        var NO_PRINTABLES = [
            '\x01',     // SOH
            '\x02',     // STX
            '\x03',     // ETX
            '\x04',     // EOT
            '\x05',     // ENQ
            '\x06',     // ACK
            '\x07',     // BEL
            '\x08',     // BS
            // '\x09',     // HT | Tab
            // '\x0A',     // LF
            '\x0B',     // VT
            '\x0C',     // FF
            // '\x0D',     // CR
            '\x0E',     // SO
            '\x0F',     // SI
            '\x10',     // DLE
            '\x11',     // DC1
            '\x12',     // DC2
            '\x13',     // DC3
            '\x14',     // DC4
            '\x15',     // NAK
            '\x16',     // SYN
            '\x17',     // ETB
            '\x18',     // CAN
            '\x19',     // EM
            '\x1A',     // SUB
            '\x1B',     // ESC
            '\x1C',     // FS
            '\x1D',     // GS
            '\x1E',     // RS
            '\x1F',     // US
        ];

        var service = {
            escapeNoPrintables: escapeNoPrintables
        };

        return service;

        function toHex(num) {
            if (!num) {
                return undefined;
            }
            return num.toString(16).padStart(2, '0');
        }

        

        function escapeNoPrintables(text) {
            if (!text) {
                return text;
            }
            var result = text;
            try {
                for (var i = 0; i < NO_PRINTABLES.length; i++) {
                    var ch = NO_PRINTABLES[i];
                    if (result.indexOf(ch) != -1) {
                        result = result.replaceAll(ch, '');
                    }
                }
            }
            catch (error) {
                console.log("Error to escapeNoPrintables", error);
                result = text;
            }
            return result;
        }

    }
})();
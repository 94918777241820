(function () {
    "use strict";

    angular.module("platformApp").controller("CatalogManagementController", CatalogManagementController);

    CatalogManagementController.$inject = [
        "$state",
        "$scope",
        "$uibModal",
        "$http",
        "$q",
        "Catalog",
        "MessageService",
        "$stateParams",
        "ThemeClassify",
        "entity",
        "$uibModalInstance",
        "$timeout"
    ];

    function CatalogManagementController(
        $state,
        $scope,
        $uibModal,
        $http,
        $q,
        Catalog,
        MessageService,
        $stateParams,
        ThemeClassify,
        entity,
        $uibModalInstance,
        $timeout
    ) {
        var vm = this;
        vm.catalogs = [];
        vm.catalogType = $stateParams.catalogType || entity.catalogType;
        vm.name = "";
        if(vm.catalogType === "CLIPART" || vm.catalogType === "BORDER" || vm.catalogType === "MASK" || vm.catalogType == "BACKGROUND" || vm.catalogType == "GALLERY"){
            vm.name = "标签";
        }else if(vm.catalogType === "LAYOUT"){
            vm.name = "主题";
        }else{
            vm.name = "分类";
        }
        console.log(vm.catalogType);
        $scope.close = function () {
            $uibModalInstance.close();
        };
        //    分类显示
        loadAll();
        function loadAll() {
            if (vm.catalogType == "CLIPART" || vm.catalogType == "BORDER" || vm.catalogType == "MASK" || vm.catalogType == "BACKGROUND" || vm.catalogType == "GALLERY") {
                ThemeClassify.getAllByCondition1(
                    {
                        items: [{ key: "type", op: "=", value: vm.catalogType }]
                    },
                    function (data) {
                        vm.catalogs = data;
                        vm.catalogs.sort(function (a, b) {return a.seq - b.seq;});
                    }
                );
            } else {
                Catalog.getAll(
                    {
                        items: [
                            {
                                key: "catalogType",
                                op: "=",
                                value: vm.catalogType
                            }
                        ]
                    },
                    function (data) {
                        vm.catalogs = data;
                        vm.catalogs.sort(function (a, b) {return a.seq - b.seq;});
                    }
                );
            }
        }
        var sort = null;
        function sortInit(){
            $timeout(function(){
                sort = new Sortable(document.getElementById('pageList'), {
                    swap: false,
                    preventOnFilter: true,
                    animation: 150,
                    onEnd: function (/**Event*/evt) {
                        var item = angular.copy(vm.catalogs[evt.oldIndex])
                        vm.catalogs.splice(evt.oldIndex,1);
                        vm.catalogs.splice(evt.newIndex,0,item);
                        $scope.$apply();
                    }
                });
            },800)
        }
        vm.sortCatalog = false;
        $scope.sortCatalog = function(){
            vm.sortCatalog = true;
            sortInit()
        }

        $scope.sortSave = function(){
            vm.sortCatalog = false;
            vm.catalogs.forEach(function(catalog,index){
                catalog.seq = index;
            })
            if (vm.catalogType == "CLIPART" || vm.catalogType == "BORDER" || vm.catalogType == "MASK" || vm.catalogType == "BACKGROUND" || vm.catalogType == "GALLERY") {
                ThemeClassify.updateSeqByList(vm.catalogs,function (data) {
                    data.status == 200 ? MessageService.success('排序成功') : MessageService.error(data.message || '排序失败');
                    loadAll();
                });
            } else {
                Catalog.updateSeqByList(vm.catalogs,function (data) {
                    data.status == 200 ? MessageService.success('排序成功') : MessageService.error(data.message || '排序失败')
                    loadAll();
                });
            }
        }
        $scope.sortCancel = function(){
            vm.sortCatalog = false;
            loadAll();
        }
        $scope.useCatalog = function (item) {
            if (vm.catalogType == "CLIPART" || vm.catalogType == "BORDER" || vm.catalogType == "MASK" || vm.catalogType == "BACKGROUND" || vm.catalogType == "GALLERY") {
                ThemeClassify.changeStatue({ id: item.id }, onSaveSuccess, onSaveError);
            } else {
                Catalog.usable({ id: item.id }, onSaveSuccess, onSaveError);
            }
        };
        vm.addCatalog = false;
        $scope.addCatalog = function () {
            if (vm.catalogs.length > 0 && vm.catalogs[vm.catalogs.length - 1].id == null) {
                return MessageService.notice("请将新添加的" + vm.name + "保存");
            }
            vm.addCatalog = true;
            if (vm.catalogType == "CLIPART" || vm.catalogType == "BORDER" || vm.catalogType == "MASK" || vm.catalogType == "BACKGROUND" || vm.catalogType == "GALLERY") {
                ThemeClassify.getAllByCondition1(
                    {
                        items: [{ key: "type", op: "=", value: vm.catalogType }]
                    },
                    function (data) {
                        vm.catalogs = data;
                        vm.catalogs.sort(function (a, b) {return a.seq - b.seq;});
                        vm.catalogs.push({
                            used: true,
                            edit: true,
                            type: vm.catalogType,
                            seq: vm.catalogs.length
                        });
                        $("#model-body").animate(
                            {
                                scrollTop: $("#model-body").prop("scrollHeight")
                            },
                            1000
                        );
                        $timeout(function () {
                            $("#model-body .catalog-input:last").focus();
                        }, 500);
                    }
                );
            } else {
                Catalog.getAll(
                    {
                        items: [
                            {
                                key: "catalogType",
                                op: "=",
                                value: vm.catalogType
                            }
                        ]
                    },
                    function (data) {
                        vm.catalogs = data;
                        vm.catalogs.sort(function (a, b) {return a.seq - b.seq;});
                        vm.catalogs.push({
                            usable: true,
                            edit: true,
                            catalogType: vm.catalogType,
                            seq: vm.catalogs.length -1
                        });
                        $("#model-body").animate(
                            {
                                scrollTop: $("#model-body").prop("scrollHeight")
                            },
                            500
                        );
                        $timeout(function () {
                            $("#model-body .catalog-input:last").focus();
                        }, 500);
                    }
                );
            }
        };

        $scope.useCatalog2 = function (item) {
            ThemeClassify.changeStatue({ id: item.id }, onSaveSuccess, onSaveError);
        };
        function onSaveSuccess() {
            loadAll();
            MessageService.success("启停成功");
        }

        function onSaveError() {
            MessageService.error("启停失败");
        }

        $scope.cancel = function (data) {
            loadAll();
            vm.addCatalog = false;
        };

        $scope.edit = function (catalog) {
            if (vm.catalogs[vm.catalogs.length - 1].id == null) {
                MessageService.notice("请将新添加的" + vm.name + "保存");
                return;
            }
            var catalogId = catalog.id;
            if (vm.catalogType == "CLIPART" || vm.catalogType == "BORDER" || vm.catalogType == "MASK"  || vm.catalogType == "BACKGROUND" || vm.catalogType == "GALLERY") {
                ThemeClassify.getAllByCondition1(
                    {
                        items: [{ key: "type", op: "=", value: vm.catalogType }]
                    },
                    function (data) {
                        vm.catalogs = data;
                        vm.catalogs.sort(function (a, b) {return a.seq - b.seq;});
                        vm.catalogs.forEach(function (catalog) {
                            if (catalog.id == catalogId) {
                                catalog.edit = true;
                            }
                        });
                    }
                );
            } else {
                Catalog.getAll(
                    {
                        items: [
                            {
                                key: "catalogType",
                                op: "=",
                                value: vm.catalogType
                            }
                        ]
                    },
                    function (data) {
                        vm.catalogs = data;
                        vm.catalogs.sort(function (a, b) {return a.seq - b.seq;});
                        vm.catalogs.forEach(function (catalog) {
                            if (catalog.id == catalogId) {
                                catalog.edit = true;
                            }
                        });
                    }
                );
            }
        };

        $scope.save = function (data) {
            if (!data.name) {
                MessageService.notice("请输入" + vm.name + "名称");
                return;
            }
            if (data.name.length > 22) {
                MessageService.notice("输入的" + vm.name + "名称长度超出");
                return;
            }
            if (data.id == null) {
                if (vm.catalogType == "CLIPART" || vm.catalogType == "BORDER" || vm.catalogType == "MASK"  || vm.catalogType == "BACKGROUND" || vm.catalogType == "GALLERY") {
                    ThemeClassify.saveForUser(data, function (data) {
                        MessageService.success("保存成功");
                        loadAll();
                        vm.addCatalog = false;
                    });
                } else {
                    Catalog.save(data, function (data) {
                        MessageService.success("保存成功");
                        loadAll();
                        vm.addCatalog = false;
                    });
                }
            } else {
                if (vm.catalogType == "CLIPART" || vm.catalogType == "BORDER" || vm.catalogType == "MASK"  || vm.catalogType == "BACKGROUND" || vm.catalogType == "GALLERY") {
                    ThemeClassify.save(data, function (data) {
                        MessageService.success("保存成功");
                        loadAll();
                        vm.addCatalog = false;
                    });
                } else {
                    Catalog.updateCatalog(data, function (data) {
                        MessageService.success("保存成功");
                        loadAll();
                        vm.addCatalog = false;
                    });
                }
            }
        };

        $scope.delete = function (item, event) {
            event.stopPropagation();
            if (vm.catalogType == "CLIPART" || vm.catalogType == "BORDER" || vm.catalogType == "MASK"  || vm.catalogType == "BACKGROUND" || vm.catalogType == "GALLERY") {
                MessageService.confirm(
                    {
                        title: "提示",
                        cancel: "取消",
                        confirm: "确认",
                        msg: "确认删除该" + vm.name +"吗？",
                        isDelete: true
                    },
                    function () {
                        ThemeClassify.del({ id: item.id }, function (data) {
                            loadAll();
                        });
                    },
                    function () {},
                    true
                );
            } else {
                MessageService.confirm(
                    {
                        title: "提示",
                        cancel: "取消",
                        confirm: "确认",
                        msg: "确认删除该"+ vm.name +"吗？",
                        isDelete: true
                    },
                    function () {
                        Catalog.deleteCatalog({ id: item.id }, function (data) {
                            loadAll();
                        });
                    },
                    function () {},
                    true
                );
            }
        };
    }
})();

(function () {
    "use strict";

    angular.module("platformApp").controller("RequestResetController", RequestResetController);

    RequestResetController.$inject = [
        "$timeout",
        "Auth",
        "errorConstants",
        "$scope",
        "MessageService",
        "Verify",
        "$interval",
        "FrontUser",
        "$state"
    ];

    function RequestResetController(
        $timeout,
        Auth,
        errorConstants,
        $scope,
        MessageService,
        Verify,
        $interval,
        FrontUser,
        $state
    ) {
        var vm = this;

        vm.error = null;
        vm.resetPassword = resetPassword;
        $scope.paracont = "获取验证码";
        vm.success = null;
        vm.checkCode = false;
        //密码框类型
        vm.passType = "password";
        vm.confirmPassType = "password";
        //手机号校验错误状态
        vm.checkPhoneErr = false;
        var timer = null;

        $scope.checkVerifyCode = function () {
            if (vm.registerAccount.code.length <= 5) {
                return;
            }
            Verify.checkVerifyCode(
                {
                    tel: vm.registerAccount.phone,
                    code: vm.registerAccount.code,
                    incident: "忘记密码"
                },
                function (msg) {
                    if (msg.status == 200 && msg.message == true) {
                        vm.checkCode = true;
                    } else {
                        vm.checkCode = false;
                        MessageService.error("手机验证失败");
                    }
                },
                function () {
                    vm.checkCode = false;
                    MessageService.error("手机验证失败");
                }
            );
        };

        $scope.changePhone = function () {
            vm.checkPhoneErr = false;
        };
        //更改密码框类型
        $scope.changePassType = function (passType, type) {
            vm[passType] = type;
        };

        $scope.getVerifyCode = function () {
            if (vm.disabled) {
                return;
            }
            if (vm.registerAccount.phone == null || vm.registerAccount.phone == "") {
                MessageService.error("请先输入手机号码");
                return;
            }
            var flag = checkPhone(vm.registerAccount.phone);
            if (!flag) {
                vm.checkPhoneErr = true;
                return MessageService.error("您的手机号不合规，请检查重新输入");
            }
            vm.countdown = 120;
            // 发送验证码到手机
            Verify.getVerifyCode(
                {
                    tel: vm.registerAccount.phone,
                    incident: "忘记密码"
                },
                function (msg) {
                    clearInterval(timer);
                    timer = $interval(function () {
                        if (vm.countdown > 0) {
                            vm.disabled = true;
                            vm.countdown--;
                            $scope.paracont = vm.countdown + "s";
                        } else {
                            vm.disabled = false;
                            $interval.cancel(timer);
                            $scope.paracont = "重发验证码";
                        }
                    }, 1000);
                }
            );
        };

        function resetPassword() {
            vm.error = null;
            if (vm.registerAccount.name == null || vm.registerAccount.name == "") {
                return MessageService.error("账号不能为空");
            }
            if (vm.registerAccount.password && vm.registerAccount.password.length < 5) {
                return MessageService.error("密码至少有五个字符");
            }
            if (vm.registerAccount.password !== vm.confirmPassword) {
                return MessageService.error("两次输入的密码不一致，请重新输入");
            }
            if (!vm.checkCode) {
                return MessageService.error("手机号验证失败，请重试");
            }
            var postData = {
                phone: vm.registerAccount.phone,
                password: vm.registerAccount.password
            };
            FrontUser.forgetPassword(
                postData,
                function (data) {
                    MessageService.success("密码重置成功，请使用新密码登录");
                    $state.go("home");
                },
                function () {
                    MessageService.error("重置密码失败，请稍后再试");
                }
            );
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PictureAddController', PictureAddController);

    PictureAddController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService','Catalog'];

    function PictureAddController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService,Catalog) {
        var vm = this;
        vm.save = save;
        vm.cancel = cancel;
        vm.units = [
            { name: 'px', id: 'px'},
            { name: 'mm', id: 'mm'}
        ];
        vm.data = {
            name: '',
            width: '',
            height: '',
            unit: 'mm'
        }
        Catalog.getAll({items:[{key:"usable",op:"=",value:"true"},{key:"catalogType",op:"=",value:"LAYOUT"}]}, function (data) {
            vm.catalogs = data;
        })
        //返回
        function cancel() {
            $uibModalInstance.close();
        }

        function save() {
            var reg = /^\d+(?=\.{0,1}\d+$|$)/
            if(isNaN(vm.data.width) || Number(vm.data.width) < 1){
                MessageService.error("宽度必须输入正数");
                return
            }
            if(isNaN(vm.data.height) || Number(vm.data.height) < 1){
                MessageService.error("高度必须输入正数");
                return
            }
            if(!vm.data.catalogId){
                MessageService.error("主题不能为空");
                return
            }

            var _data = angular.copy(vm.data);
            if (_data.unit == 'px'){
                // _data.width = (_data.width/25.4 * vm.windowDpiW).toFixed(2);
                // _data.height = (_data.height/25.4 * vm.windowDpiH).toFixed(2);
                _data.width = (_data.width/vm.windowDpiW * 25.4).toFixed(2);
                _data.height = (_data.height/vm.windowDpiH * 25.4).toFixed(2);
            }
            _data.unit = 'mm';
            localStorage.setItem("picturePage",JSON.stringify(_data));
            $state.go('picture-xml');
            $uibModalInstance.close();
        }

        //单位换算
        $scope.unitCb = function(){
            console.log(vm.data.unit)
            if(vm.data.unit == 'mm'){
                vm.data.width = (vm.data.width/vm.windowDpiW*25.4).toFixed(0);
                vm.data.height = (vm.data.height/vm.windowDpiH*25.4).toFixed(0);
            }
            if (vm.data.unit == 'px'){
                vm.data.width = (vm.data.width/25.4 * vm.windowDpiW).toFixed(2);
                vm.data.height = (vm.data.height/25.4 * vm.windowDpiH).toFixed(2);
            }
        }

        //获取dpi
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpiW = arrDPI[0];
            vm.windowDpiH = arrDPI[1];
            return arrDPI;
        }
    }
})();

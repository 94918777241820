(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarCenterTitleController', CalendarCenterTitleController);

        CalendarCenterTitleController.$inject = ['$state', '$scope', 'ProductXml', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', 'TitleWidget', '$localStorage', 'Qinius', '$uibModal'];

    function CalendarCenterTitleController($state, $scope, ProductXml, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, TitleWidget, $localStorage, Qinius, $uibModal) {
        var vm = this;
        $(".sidebar1").hide();
        $(".navbar").show();
        $(".nav-bg").show();
        $(".wrap").css({margin: 0});

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.account = $localStorage.user;
        $scope.domains = Qinius.getDomain();

        loadAll();

        $scope.delete = function(id){
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除该数据？'
            },function(){
                TitleWidget.delete({id: id},function () {
                    loadAll();
                });
            },function(){});
        };

        function loadAll () {
            if (vm.account == undefined || vm.account == null) {
                $state.go('home');
                return;
            }
            if (pagingParams.search) {
                TitleWidget.byCondition3({
                    items:[{key:"family",value:pagingParams.search, op:"like"},{key:"status", op:"=", value:'Approved'}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                TitleWidget.byCondition3({
                        items:[{key:"status", op:"=", value:'Approved'}],
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    },
                    onSuccess, onError);

            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.titleList = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                console.log(2);
                MessageService.error("字体获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        //查看
        $scope.look = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/calendar-center/calendar-center-title-preview-model.html',
                controller: 'CalendarCenterTitlePreviewModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            data:data
                        };
                    }]
                }
            });
        }
        $scope.save = function(data){
            TitleWidget.get({id: data.id}, function (res) {
                console.log(res)
                res.id = "";
                res.opened = false;
                res.status = null;
                TitleWidget.save(res, function (res) {
                    MessageService.success("使用成功");
                })
            })
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RecommendLookController', RecommendLookController);

    RecommendLookController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService'];

    function RecommendLookController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService) {
        var vm = this;
        vm.cancel = cancel;
        vm.url = entity.url;

        //返回
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();

(function () {
    'use strict';

    var PagePreviewControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/page-preview.html',
        controllerAs: 'vm',
        bindings: {
            page: '<',
            scale: '<',
            cover: '<',
            pageIndex: '<',
            imgSize: '<',
            imgDpi: '<',
            isCover: '<',
            isImageboxNot: '<',
            thumbnail:'<',
            isMin:'<'
        },

        controller: ['$scope', function ($scope) {
            var vm = this;
            //边框
            $scope.imageboxW = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.width
                        // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                    }
                } else {
                    return imagebox.geometry.width
                }
            }
            $scope.imageboxH = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.height
                        // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                    }
                } else {
                    return imagebox.geometry.height
                }
            }
            $scope.imageboxL = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.x
                        // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                    }
                } else {
                    return imagebox.geometry.x
                }
            }
            $scope.imageboxT = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.y
                        // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                    }
                } else {
                    return imagebox.geometry.y
                }
            }
            $scope.imageboxWEdge = function (imagebox, edge) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.border.lineWidth)
                    }
                    if (imagebox.border.type == 'Pic') {
                        if (edge == 'left') {
                            return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                        }
                        if (edge == 'top') {
                            return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                        }
                        if (edge == 'right') {
                            return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                        }
                        if (edge == 'bottom') {
                            return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                        }
                    }
                } else {
                    return 0
                }
            }
            $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    var _ratio = 1;
                    if(ratio && ratio1){
                        if(imagebox.geometry.width > imagebox.geometry.height){
                            _ratio = ratio1
                        }else{
                            _ratio = ratio
                        }
                    }
                    if(ratio && !ratio1){
                        _ratio = ratio
                    }
                    if (imagebox.border.shape == 'Ellipse') {
                        return '50%'
                    }
                    if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                        var _radius = imagebox.border.radius[radius];
                        return _radius > 0 ? (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                    }else{
                        var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                        radiusW = radiusW + imagebox.border.lineWidth;
                        var _r = radiusW * imagebox.border.radius[radius] / 100;
                        return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                    }
                } else {
                    return 0
                }
            }
            $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    var _ratio = 1;
                    if(ratio && ratio1){
                        if(imagebox.geometry.width > imagebox.geometry.height){
                            _ratio = ratio1
                        }else{
                            _ratio = ratio
                        }
                    }
                    if(ratio && !ratio1){
                        _ratio = ratio
                    }
                    if (imagebox.border.shape == 'Ellipse') {
                        return '50%'
                    }
                    if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                        var _radius = imagebox.border.radius[radius];
                        return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                    }else{
                        var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                        radiusW = radiusW + imagebox.border.lineWidth;
                        var _r = radiusW * imagebox.border.radius[radius] / 100;
                        return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                    }
                } else {
                    return 0
                }
            }
            $scope.imageboxWRadius2 = function (imagebox, radius) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.shape == 'Ellipse') {
                        return '200%'
                    } else {
                        var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                        radiusW = radiusW + imagebox.border.lineWidth;
                        var _r = radiusW * imagebox.border.radius[radius] / 100;
                        return _r > 0 ? _r + Number(imagebox.border.lineWidth)/2 : 0
                    }
                } else {
                    return 0
                }
            }
            $scope.imageboxWRadius3 = function (imagebox, radius) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.shape == 'Ellipse') {
                        return '200%'
                    } else {
                        var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                        radiusW = radiusW + imagebox.border.lineWidth;
                        var _r = radiusW * imagebox.border.radius[radius] / 100;
                        return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? _r - Number(imagebox.border.lineWidth)/2 : 0
                    }
                } else {
                    return 0
                }
            }
            $scope.dropShadow = function(imagebox,ratio){
                if (imagebox.shadow == null) {
                    imagebox.shadow={
                        color:'#777777',
                        x:0,
                        y:0,
                        blur:0
                    }
                }
                var width = $scope.imageboxW(imagebox) * ratio;
                var height =$scope.imageboxH(imagebox) * ratio;
                return dropShadow(imagebox,width,height)
            }
        }]
    };
    angular.module('platformApp')
    	.component('pagePreview', PagePreviewControl);

})();

(function () {
    "use strict";

    angular.module("platformApp").controller("PasswordController", PasswordController);

    PasswordController.$inject = [
        "Auth",
        "Principal",
        "$scope",
        "FrontUser",
        "MessageService",
        "$state",
        "$uibModalInstance"
    ];

    function PasswordController(Auth, Principal, $scope, FrontUser, MessageService, $state, $uibModalInstance) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        Principal.identity().then(function (account) {
            vm.account = account;
        });

        $scope.getBtnDisabledState = function () {
            if (vm.oldpassword && vm.password && vm.confirmPassword) {
                return false;
            } else {
                return true;
            }
        };

        $scope.inputChange = function () {
            vm.oldpasswordError = "";
            vm.passwordError = "";
            vm.confirmPasswordError = "";
        };
        function changePassword() {
            vm.oldpasswordError = "";
            vm.passwordError = "";
            vm.confirmPasswordError = "";
            if (!vm.oldpassword) {
                vm.oldpasswordError = "旧密码不能为空";
                return;
            }
            if (!vm.password || vm.password.length < 5) {
                vm.passwordError = "新密码不能小于5个字符";
                return;
            }
            if (vm.password !== vm.confirmPassword) {
                vm.confirmPasswordError = "新密码和密码确认不一致，请重新输入";
                return;
            }
            vm.doNotMatch = null;
            FrontUser.checkPassword(
                { id: vm.account.id, password: vm.oldpassword },
                function (data) {
                    if (data.status != 200) {
                        vm.oldpasswordError = "旧密码输入错误";
                    } else {
                        FrontUser.changePassword(
                            { id: vm.account.id, oldPassword: vm.oldpassword, password: vm.password },
                            function (data) {
                                MessageService.success("修改密码成功，请重新登录");
                                Auth.logout();
                                $state.go("home");
                                $uibModalInstance.dismiss("cancel");
                            },
                            function () {
                                MessageService.error("修改密码失败");
                            }
                        );
                    }
                },
                function () {
                    vm.oldpasswordError = "旧密码输入错误";
                }
            );
        }
        vm.cancel = function () {
            $uibModalInstance.dismiss();
        };
    }
})();

(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PageSceneController', PageSceneController);

    PageSceneController.$inject = ['$scope', '$uibModalInstance', '$q', '$timeout', 'MessageService','pages', 'UidService'];

    function PageSceneController($scope, $uibModalInstance, $q, $timeout, MessageService,pages, UidService) {

        var vm = this;

        vm.clear = clear;
        vm.save = save;
        console.log(pages);
        vm.dpiChoose = [{data: 'mm', id: 0}, {data: 'px', id: 1}];
        $scope.dpi = vm.dpiChoose[0];

        vm.token = pages.token;
        $scope.domain = pages.domain;

        var unitTransform = function (mm) {
            // mm换算成px  Math.ceil(mm / 25.4 * 300);
            return (mm * 3) - 0;
        };
        var unitTransformPx = function (px) {
            // px换算成mm  Math.floor(px / 300 * 25.4)
            return (px * 1 / 3).toFixed(2) - 0;
        };

        $scope.scene = {
            isScene: true,
            background: {
                resource: {
                    provider: 'qiniu',
                    identifier: ''
                }
            },
            geometry: {
                width: unitTransform(420),
                height: unitTransform(285),
                x: unitTransform(0),
                y: unitTransform(0)
            },
            widthM: 420,
            heightM: 285,
            offsetXM: 0,
            offsetYM: 0,
            proportion: 1,
            scale: 1,
            unit: 'mm'
        }

        //模板背景图更改
        window.updateImgFile = function ($event) {
            $scope.$digest();
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //  成功读取
                        $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) {     //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('key', 'background-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        $scope.scene.background.resource.identifier = $scope.domain + data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        $scope.removeFile = function () {
            $scope.scene.background.resource.identifier = '';
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.updateDpi = function (data) {

            if (data.id == 1) {
                $scope.scene.unit = 'px';
                var wah = ThreeHundredDpi($scope.scene.widthM, $scope.scene.heightM);
                $scope.scene.widthPx = wah.px1;
                $scope.scene.heightPx = wah.px2;
            } else {
                $scope.scene.unit = 'mm';
                var wah = ThreeHundredDpiToMm($scope.scene.widthPx, $scope.scene.heightPx);
                $scope.scene.widthM = wah.mm1;
                $scope.scene.heightM = wah.mm2;
            }


        }

        // mm---px
        function ThreeHundredDpi(attr1, attr2) {
            // Math.floor(px / 300 * 25.4)   (px--mm)
            // Math.ceil(mm / 25.4 * 300)   (mm---px)
            var px1 = parseFloat((attr1 / 25.4 * 300 - 0)).toFixed(2) - 0;
            var px2 = parseFloat((attr2 / 25.4 * 300 - 0)).toFixed(2) - 0;
            return {
                px1: px1,
                px2: px2
            }
        }

        // px---mm
        function ThreeHundredDpiToMm(attr1, attr2) {
            var mm1 = parseFloat((attr1 / 300 * 25.4 - 0)).toFixed(2) - 0;
            var mm2 = parseFloat((attr2 / 300 * 25.4 - 0)).toFixed(2) - 0;
            return {
                mm1: mm1,
                mm2: mm2
            }

        }

        $scope.validate = function () {
            // if (vm.page.pageSize == null || vm.page.pageSize == "") {
            //     MessageService.error("请设置页面尺寸");
            //     return false;
            // }
            // if (vm.page.bloodSize == null || vm.page.bloodSize == "") {
            //     MessageService.error("请设置出血尺寸");
            //     return false;
            // }
            // if (vm.page.name == null || vm.page.name == "") {
            //     MessageService.error("请输入作品名称");
            //     return false;
            // }
            // if (vm.page.pageNum == null || vm.page.pageNum == "") {
            //     MessageService.error("请输入页数");
            //     return false;
            // }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            if ($scope.scene.unit == 'px') {
                var wah = ThreeHundredDpiToMm($scope.scene.widthPx, $scope.scene.heightPx);
                $scope.scene.widthM = wah.mm1;
                $scope.scene.heightM = wah.mm2;
            } else {
                var wah = ThreeHundredDpi($scope.scene.widthM, $scope.scene.heightM);
                $scope.scene.widthPx = wah.px1;
                $scope.scene.heightPx = wah.px2;
            }
            $scope.scene.geometry.width = unitTransform($scope.scene.widthM);
            $scope.scene.geometry.height = unitTransform($scope.scene.heightM);

            onSaveSuccess($scope.scene);
        }


        function onSaveSuccess(result) {
            console.log(result)

            vm.isSaving = false;
            $uibModalInstance.close(result);

        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();

(function () {
    'use strict';

    var calendarSetControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/calendar/controls/calendar-set.control.html',
        controllerAs: 'vm',
        bindings: {
            data: '=',
            pageValue: '=',
            unit: '=',
            units: '<',
            onChange:'&',
            onUnit:'&',
            onUpdatePageValue:'&'
        },

        controller: ['$scope', '$timeout', '$uibModal','UidService','MessageService','FontManagement', function ($scope, $timeout, $uibModal,UidService,MessageService,FontManagement) {
            var vm = this;
            vm.weekStyleKeys = [
                {id:'w1',value:'星期一'},
                {id:'w2',value:'星期二'},
                {id:'w3',value:'星期三'},
                {id:'w4',value:'星期四'},
                {id:'w5',value:'星期五'},
                {id:'w6',value:'星期六'},
                {id:'w7',value:'星期日'},
            ];
            vm.weekStyleKey = "w1";
            vm.dateConfigKeys = [
                {id:'w1',value:'星期一'},
                {id:'w2',value:'星期二'},
                {id:'w3',value:'星期三'},
                {id:'w4',value:'星期四'},
                {id:'w5',value:'星期五'},
                {id:'w6',value:'星期六'},
                {id:'w7',value:'星期日'},
                {id:'others',value:'其它月'},
                {id:'holiday',value:'节假日'},
            ];
            vm.sampleYears = [
                {id:'2020',value:'2020'},
                {id:'2021',value:'2021'},
                {id:'2022',value:'2022'},
                {id:'2023',value:'2023'},
                {id:'2024',value:'2024'},
                {id:'2025',value:'2025'},
                {id:'2026',value:'2026'},
                {id:'2027',value:'2027'},
                {id:'2028',value:'2028'},
                {id:'2029',value:'2029'},
                {id:'2030',value:'2030'},
            ];
            vm.sampleMonths = [
                {id:'1',value:'1'},
                {id:'2',value:'2'},
                {id:'3',value:'3'},
                {id:'4',value:'4'},
                {id:'5',value:'5'},
                {id:'6',value:'6'},
                {id:'7',value:'7'},
                {id:'8',value:'8'},
                {id:'9',value:'9'},
                {id:'10',value:'10'},
                {id:'11',value:'11'},
                {id:'12',value:'12'},
            ];
            vm.displayTypes = [
                { name: '一行7列', id: 'C7'},
                { name: '一行14列', id: 'C14'},
                { name: '一行21列', id: 'C21'},
            ];
            vm.dateConfigKey = "w1";
            FontManagement.getAll2({items: [{key: "defaultShow", op: "=", value: true},{key: "enable", op: "=", value: true},{key: "cancelled", op: "=", value: false}], sort: ["id,asc"]}, function (res) {
                if(res && res.length>0){
                    vm.defaultFontUuid = res[0].uuid;
                }
            });
            vm.$onInit = function(data) {
                updateWeekStyleOptions();
                updateDateStyleOptions();
                colorInit()
            }
            vm.$onChanges = function(data) {
                if(data.data&&data.data.currentValue){
                    vm.data = data.data.currentValue;
                }
                if(data.unit&&data.unit.currentValue){
                    vm.unit = data.unit.currentValue;
                }
                if(data.pageValue&&data.pageValue.currentValue){
                    vm.pageValue = data.pageValue.currentValue;
                }
            }
            function colorInit() {
                vm.colors={
                    backgroundColors:[],
                    borderColors:[],
                    fontColors:[],
                    fontBackgroundColors:[]
                }
                if(vm.data.backgroundColor){
                    vm.colors.backgroundColors.push(vm.data.backgroundColor)
                }
                if(vm.data.dateStyles instanceof Array){
                    vm.data.dateStyles.forEach(function (item) {
                        if(item.backgroundColor){
                            vm.colors.backgroundColors.push(item.backgroundColor)
                        }
                        if(item.border&&item.border.color){
                            vm.colors.borderColors.push(item.border.color)
                        }
                        if(item.items&&item.items.length>0){
                            item.items.forEach(function (item1) {
                                if(item1.backgroundColor){
                                    vm.colors.fontBackgroundColors.push(item1.backgroundColor)
                                }
                                if(item1.textColor){
                                    vm.colors.fontColors.push(item1.textColor)
                                }
                            })
                        }
                    })
                }
            }

            function updateWeekStyleOptions() {
                vm.weekStyleOptions = [];
                $timeout(function () {
                    var options = [];
                    for (var i = 0; i < vm.data.weekStyles.length; i++) {
                        var style = vm.data.weekStyles[i];
                        options.push({name: style.name, uuid: style.uuid});
                    }
                    vm.weekStyleOptions = options;
                })
            }

            function updateDateStyleOptions() {
                vm.dateStyleOptions = [];
                $timeout(function () {
                    var options = [];
                    for (var i = 0; i < vm.data.dateStyles.length; i++) {
                        var style = vm.data.dateStyles[i];
                        options.push({name: style.name, uuid: style.uuid});
                    }
                    vm.dateStyleOptions = options;
                })
            }
            //属性栏
            vm.tag = 1;
            $scope.tagClick = function(item){
                vm.tag = item;
            }
            $scope.showOthersCb = function(item){
                vm.data.showOthers = item;
                $scope.widgetUpdate();
            }
            //周设置
            $scope.selectWeekLayout = function() {
                $uibModal.open({
                    templateUrl: 'app/entities/calendar/controls/layout-select.html',
                    controller: 'LayoutSelectController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        msg: function() {
                            return {
                                type: 'WEEK'
                            };
                        }
                    }
                }).result.then(function(res) {
                    if (res.id != vm.data.weekLayoutId) {
                        vm.data.weekLayout = res;
                        vm.data.weekLayoutId = res.id;
                        vm.data.obj=null;
                        resetWeekStyles();
                    }
                });
            }
            function resetWeekStyles() {
                for (var k = 0; k < vm.data.weekStyles.length; k++) {
                    var style = vm.data.weekStyles[k];

                    style.items = [];
                    if (vm.data.weekLayout && vm.data.weekLayout.items) {
                        for (var i = 0; i < vm.data.weekLayout.items.length; i++) {
                            var item = vm.data.weekLayout.items[i];


                            style.items.push({
                                name: item.name,
                                type: item.type,
                                format: item.format,
                                sample: item.sample,
                                textFontUuid: vm.defaultFontUuid,
                                textFamily: "",
                                textSize: item.textSize,
                                textColor: item.textColor,
                                textAlign: item.textAlign,
                                url: item.url,
                                w:item.w,
                                h:item.h,
                                x:item.x,
                                y:item.y,
                                geometry:{
                                    width:item.w,
                                    height:item.h,
                                    x:item.x,
                                    y:item.y,
                                },
                            });
                        }
                    }
                }
                $scope.widgetUpdate();
            }
            vm.addWeekStyle = function () {
                var style = {
                    name: "样式" + randomString(),
                    uuid: UidService.get(),
                    backgroundColor: null,
                    border: {
                        lineType: null,
                        lineWidth: 0,
                        color: "#000000",
                        blRadius: 0,
                        brRadius: 0,
                        tlRadius: 0,
                        trRadius: 0
                    },
                    items:[]
                };
                if(vm.data.geometry){
                    if (vm.data.weekStyles[0] && vm.data.weekStyles[0].items) {
                        for (var i = 0; i < vm.data.weekStyles[0].items.length; i++) {
                            var item = vm.data.weekStyles[0].items[i];
                            style.items.push({
                                backgroundColor:null,
                                name: item.name,
                                type: item.type,
                                format: item.format,
                                sample: item.sample,
                                textFontUuid: vm.defaultFontUuid,
                                textFamily: "",
                                textSize: item.textSize,
                                textColor: item.textColor || "#000000",
                                textAlign: item.textAlign,
                                url: item.url,
                                w:item.w,
                                h:item.h,
                                x:item.x,
                                y:item.y,
                                geometry:{
                                    width:item.w,
                                    height:item.h,
                                    x:item.x,
                                    y:item.y,
                                },
                            });
                        }
                    }
                }else{
                    if (vm.data.weekLayout && vm.data.weekLayout.items) {
                        for (var i = 0; i < vm.data.weekLayout.items.length; i++) {
                            var item = vm.data.weekLayout.items[i];

                            style.items.push({
                                backgroundColor:null,
                                name: item.name,
                                type: item.type,
                                format: item.format,
                                sample: item.sample,
                                textFontUuid: vm.defaultFontUuid,
                                textFamily: "",
                                textSize: item.textSize,
                                textColor: item.textColor || "#000000",
                                textAlign: item.textAlign,
                                url: item.url,
                                geometry:{
                                    width:item.w,
                                    height:item.h,
                                    x:item.x,
                                    y:item.y,
                                },
                                w:item.w,
                                h:item.h,
                                x:item.x,
                                y:item.y,
                            });
                        }
                    }
                }
                vm.data.weekStyles.push(style);
                updateWeekStyleOptions();
            }
            vm.editWeekStyle = function (style, $index) {
                var layout = vm.data.weekLayout;
                var myStyle = vm.data.weekStyles[$index];
                var data = {
                    obj: null,
                    settings: myStyle,
                    layout: layout,
                    config: vm.data.weekConfig,
                    name:style.name,
                    colors:vm.getPageColors()
                };
                $uibModal.open({
                    templateUrl: 'app/entities/calendar/controls/cell-settings-modal.html',
                    controller: 'CellSettingsModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        msg: function() {
                            return data;
                        }
                    }
                }).result.then(function(res) {
                    style.name = res.name;
                    if (res.settings) {
                        vm.data.weekStyles[$index].debug = res.settings.debug;
                        vm.data.weekStyles[$index].backgroundColor = res.settings.backgroundColor;
                        if (res.settings.border) {
                            vm.data.weekStyles[$index].border = res.settings.border;
                        }
                        if (res.settings.items) {
                            vm.data.weekStyles[$index].items = res.settings.items || style.items;
                        }
                        updateWeekStyleOptions();
                        $scope.widgetUpdate();
                    }
                });
            }
            vm.removeWeekStyle = function ($index) {
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'确认删除？'
                },function(){
                    var wc = vm.data.weekConfig;
                    var s = vm.data.weekStyles[$index];
                    if (!wc.w1 || wc.w1 == s.uuid) {
                        wc.w1 = 'default';
                    }
                    if (!wc.w2 || wc.w2 == s.uuid) {
                        wc.w2 = 'default';
                    }
                    if (!wc.w3 || wc.w3 == s.uuid) {
                        wc.w3 = 'default';
                    }
                    if (!wc.w4 || wc.w4 == s.uuid) {
                        wc.w4 = 'default';
                    }
                    if (!wc.w5 || wc.w5 == s.uuid) {
                        wc.w5 = 'default';
                    }
                    if (!wc.w6 || wc.w6 == s.uuid) {
                        wc.w6 = 'default';
                    }
                    if (!wc.w7 || wc.w7 == s.uuid) {
                        wc.w7 = 'default';
                    }
                    vm.data.weekStyles.splice($index, 1);
                    updateWeekStyleOptions();
                    $scope.widgetUpdate();
                })
            }
            //复制周样式
            vm.copyWeekStyle = function (index) {
                var style = angular.copy(vm.data.weekStyles[index]);
                style.id = null;
                style.uuid = UidService.get();
                style.name = style.name + "_副本";
                vm.data.weekStyles.push(style);
                updateWeekStyleOptions();

            }
            //日设置
            $scope.selectDateLayout = function() {
                $uibModal.open({
                    templateUrl: 'app/entities/calendar/controls/layout-select.html',
                    controller: 'LayoutSelectController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        msg: function() {
                            return {
                                type: 'DATE'
                            };
                        }
                    }
                }).result.then(function(res) {
                    if (res.id != vm.data.dateLayoutId) {
                        vm.data.obj=null;
                        vm.data.dateLayout = res;
                        vm.data.dateLayoutId = res.id;
                        resetDateStyles();
                    }
                });
            }
            function resetDateStyles() {
                for (var k = 0; k < vm.data.dateStyles.length; k++) {
                    var style = vm.data.dateStyles[k];

                    style.items = [];
                    if (vm.data.dateLayout && vm.data.dateLayout.items) {
                        for (var i = 0; i < vm.data.dateLayout.items.length; i++) {
                            var item = vm.data.dateLayout.items[i];
                            style.items.push({
                                name: item.name,
                                type: item.type,
                                format: item.format,
                                sample: item.sample,
                                textFontUuid: vm.defaultFontUuid,
                                textFamily: "",
                                textSize: item.textSize,
                                textColor: item.textColor,
                                textAlign: item.textAlign,
                                url: item.url,
                                w:item.w,
                                h:item.h,
                                x:item.x,
                                y:item.y,
                                geometry:{
                                    width:item.w,
                                    height:item.h,
                                    x:item.x,
                                    y:item.y,
                                },
                            });
                        }
                    }
                }
                $scope.widgetUpdate();
            }
            vm.addDateStyle = function () {
                var style = {
                    name: "样式" + randomString(),
                    uuid: UidService.get(),
                    backgroundColor: null,
                    border: {
                        lineType: null,
                        lineWidth: 0,
                        color: "#000000",
                        blRadius: 0,
                        brRadius: 0,
                        tlRadius: 0,
                        trRadius: 0
                    },
                    items:[]
                };

                if(vm.data.geometry){
                    if (vm.data.dateStyles[0] && vm.data.dateStyles[0].items) {
                        for (var i = 0; i < vm.data.dateStyles[0].items.length; i++) {
                            var item = vm.data.dateStyles[0].items[i];
                            style.items.push({
                                backgroundColor: null,
                                name: item.name,
                                type: item.type,
                                format: item.format,
                                sample: item.sample,
                                textFontUuid: vm.defaultFontUuid,
                                textFamily: "",
                                textSize: item.textSize || 14,
                                textColor: item.textColor || "#000000",
                                textAlign: item.textAlign || "center",
                                url: item.url,
                                w:item.w,
                                h:item.h,
                                x:item.x,
                                y:item.y,
                                geometry:{
                                    width:item.w,
                                    height:item.h,
                                    x:item.x,
                                    y:item.y,
                                },
                            });
                        }
                    }
                }else{
                    if (vm.data.dateLayout && vm.data.dateLayout.items) {
                        for (var i = 0; i < vm.data.dateLayout.items.length; i++) {
                            var item = vm.data.dateLayout.items[i];
                            style.items.push({
                                backgroundColor: null,
                                name: item.name,
                                type: item.type,
                                format: item.format,
                                sample: item.sample,
                                textFontUuid: vm.defaultFontUuid,
                                textFamily: "",
                                textSize: item.textSize || 14,
                                textColor: item.textColor || "#000000",
                                textAlign: item.textAlign || "center",
                                url: item.url,
                                w:item.w,
                                h:item.h,
                                x:item.x,
                                y:item.y,
                                geometry:{
                                    width:item.w,
                                    height:item.h,
                                    x:item.x,
                                    y:item.y,
                                },
                            });
                        }
                    }
                }
                vm.data.dateStyles.push(style);
                updateDateStyleOptions();
            }
            vm.editDateStyle = function (style, $index) {
                var layout = vm.data.dateLayout;
                var myStyle = vm.data.dateStyles[$index];

                var data = {
                    obj: null,
                    settings: myStyle,
                    layout: layout,
                    config: vm.data.dateConfig,
                    name:style.name,
                    colors:vm.getPageColors()
                };
                $uibModal.open({
                    templateUrl: 'app/entities/calendar/controls/cell-settings-modal.html',
                    controller: 'CellSettingsModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        msg: function() {
                            return data;
                        }
                    }
                }).result.then(function(res) {
                    style.name = res.name;
                    if (res.settings) {
                        vm.data.dateStyles[$index].debug = res.settings.debug;
                        vm.data.dateStyles[$index].backgroundColor = res.settings.backgroundColor;
                        if (res.settings.border) {
                            vm.data.dateStyles[$index].border = res.settings.border;
                        }
                        if (res.settings.items) {
                            vm.data.dateStyles[$index].items = res.settings.items || style.items;
                        }
                        updateDateStyleOptions();
                        $scope.widgetUpdate();
                    }
                });
            }
            vm.removeDateStyle = function ($index) {
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'确认删除？'
                },function(){
                    var dc = vm.data.dateConfig;
                    var s = vm.data.dateStyles[$index];
                    if (!dc.w1 || dc.w1 == s.uuid) {
                        dc.w1 = 'default';
                    }
                    if (!dc.w2 || dc.w2 == s.uuid) {
                        dc.w2 = 'default';
                    }
                    if (!dc.w3 || dc.w3 == s.uuid) {
                        dc.w3 = 'default';
                    }
                    if (!dc.w4 || dc.w4 == s.uuid) {
                        dc.w4 = 'default';
                    }
                    if (!dc.w5 || dc.w5 == s.uuid) {
                        dc.w5 = 'default';
                    }
                    if (!dc.w6 || dc.w6 == s.uuid) {
                        dc.w6 = 'default';
                    }
                    if (!dc.w7 || dc.w7 == s.uuid) {
                        dc.w7 = 'default';
                    }
                    if (!dc.others || dc.others == s.uuid) {
                        dc.others = 'default';
                    }
                    if (!dc.holiday || dc.holiday == s.uuid) {
                        dc.holiday = 'default';
                    }
                    vm.data.dateStyles.splice($index, 1);
                    updateDateStyleOptions();
                    $scope.widgetUpdate();
                })
            }
            //复制日样式
            vm.copyDateStyle = function (index) {
                var style = angular.copy(vm.data.dateStyles[index]);
                style.id = null;
                style.uuid = UidService.get();
                style.name = style.name + "_副本";
                vm.data.dateStyles.push(style);
                updateDateStyleOptions();

            }
            function randomString() {
                return Math.random().toString(36).slice(-5);
            }
            $scope.onYearMonthChnage = function () {
                vm.data.obj=null;
                $scope.widgetUpdate();
            }
            $scope.dateConfigMargin = function (){
                vm.data.dateConfig.margin = parseInt(vm.data.dateConfig.margin);
                $scope.sizeUpdate();
            }
            $scope.dateConfigHeadSpacing = function (){
                vm.data.dateConfig.headSpacing = parseInt(vm.data.dateConfig.headSpacing);
                $scope.sizeUpdate();
            }
            $scope.weekConfigMargin = function (){
                vm.data.weekConfig.margin = parseInt(vm.data.weekConfig.margin);
                $scope.sizeUpdate();
            }
            $scope.sizeUpdate = function(){
                vm.data.update = true;
                $scope.widgetUpdate();
            }
            $scope.dateRemoveBorderOverlap = function (){
                vm.data.dateConfig.margin = 0;
                if(vm.data.dateStyles){
                    vm.data.dateStyles.forEach(function (item){
                        item.border.blRadius = 0;
                        item.border.brRadius = 0;
                        item.border.tlRadius = 0;
                        item.border.trRadius = 0;
                    })
                }
                $scope.widgetUpdate();
            }
            $scope.weekRemoveBorderOverlap = function (){
                vm.data.weekConfig.margin = 0;
                if(vm.data.weekStyles){
                    vm.data.weekStyles.forEach(function (item){
                        item.border.blRadius = 0;
                        item.border.brRadius = 0;
                        item.border.tlRadius = 0;
                        item.border.trRadius = 0;
                    })
                }
                $scope.widgetUpdate();
            }
            $scope.weekRemoveBorderBottom = function (){
                vm.data.dateConfig.headSpacing = 0;
                if(vm.data.weekStyles){
                    vm.data.weekStyles.forEach(function (item){
                        item.border.blRadius = 0;
                        item.border.brRadius = 0;
                        item.border.tlRadius = 0;
                        item.border.trRadius = 0;
                    })
                }
                $scope.widgetUpdate();
            }
            $scope.widgetUpdate = function() {
                verify(function () {
                    $timeout(function () {
                        colorInit()
                        vm.onChange({text: JSON.stringify(vm.data)});
                    })
                });
            }
            var a = null;
            function verify(callback){
                if (a !== null) {
                    clearTimeout(a)
                }
                a = setTimeout(function () {
                    $timeout(function () {
                        if(vm.data.weekConfig.cellWidth<10 || vm.data.weekConfig.cellWidth == undefined){
                            vm.data.weekConfig.cellWidth = 10;
                        }
                        if(vm.data.weekConfig.cellHeight<10 || vm.data.weekConfig.cellHeight == undefined){
                            vm.data.weekConfig.cellHeight = 10;
                        }
                        // if(vm.data.weekConfig.margin<0 || vm.data.weekConfig.margin == undefined){
                        //     vm.data.weekConfig.margin = 0;
                        // }
                        if(vm.data.dateConfig.cellWidth<10 || vm.data.dateConfig.cellWidth == undefined){
                            vm.data.dateConfig.cellWidth = 10;
                        }
                        if(vm.data.dateConfig.cellHeight<10 || vm.data.dateConfig.cellHeight == undefined){
                            vm.data.dateConfig.cellHeight = 10;
                        }
                        // if(vm.data.dateConfig.margin<0 || vm.data.dateConfig.margin == undefined){
                        //     vm.data.dateConfig.margin = 0;
                        // }
                        callback()
                    })
                },500)
            }

            $scope.unitConversion = function () {
                vm.onUnit({text: vm.unit});
            }
            $scope.updateBoxParameter = function (item) {
                vm.onUpdatePageValue({text:item})
            }
            //获取当前组件上所有用到的颜色
            vm.getPageColors = function () {
                var colors = [];
                colors.push(vm.data.backgroundColor);
                //日单元格
                vm.dateConfigKeys.forEach(function (item) {
                    var dateStyle = vm.data.dateStyles.find(function (styleItem) {
                        return vm.data.dateConfig[item.id] === styleItem.uuid
                    })
                    if(dateStyle){
                        colors.push(dateStyle.backgroundColor)
                        colors.push(dateStyle.border.color);
                        dateStyle.items.forEach(function (val) {
                            colors.push(val.backgroundColor);
                            colors.push(val.textColor);

                        })

                    }
                })
                //周单元格
                vm.weekStyleKeys.forEach(function (item) {
                    var weekStyle = vm.data.weekStyles.find(function (styleItem) {
                        return vm.data.weekConfig[item.id] === styleItem.uuid
                    })
                    if(weekStyle){
                        colors.push(weekStyle.backgroundColor)
                        colors.push(weekStyle.border.color);
                        weekStyle.items.forEach(function (val) {
                            colors.push(val.backgroundColor);
                            colors.push(val.textColor);

                        })

                    }
                })

                return colorsRemoveDuplicates(colors);
            }
            //colors去重
            function colorsRemoveDuplicates(colors) {
                var list = [];
                colors.forEach(function (item) {
                    if(item && list.indexOf(item.toLowerCase()) < 0){
                        list.push(item.toLowerCase())
                    }
                });
                return list;
            }
        }]
    };
    angular.module('platformApp')
        .component('calendarSet', calendarSetControl);

})();

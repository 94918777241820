(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PictureShareController', PictureShareController);

    PictureShareController.$inject = ['$state', '$scope', 'ProductXml', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', 'PicLayoutXmls', '$localStorage', 'Qinius', '$uibModal','Catalog','$timeout'];

    function PictureShareController($state, $scope, ProductXml, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, PicLayoutXmls, $localStorage, Qinius, $uibModal,Catalog,$timeout) {
        var vm = this;
        $(".sidebar1").hide();
        $(".navbar").show();
        $(".nav-bg").show();
        $(".wrap").css({margin: 0});
        //搜索防抖定时器
        vm.timer = null;
        //分页信息
        vm.pageInfo = {
            page:1,
            size:20,
            total:0,
        }
        //批量操作状态
        vm.batchState = false;
        //批量操作选中的条数
        vm.selectCount = 0;
        vm.loadPage = loadPage;
        vm.predicate = "id";
        vm.reverse = false;
        vm.transition = transition;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = "";
        vm.catalogId = null;
        vm.selectedAll = false;
        vm.account = $localStorage.user;
        $scope.domains = Qinius.getDomain();
        vm.pictureArr=[];
        Catalog.getAll2({items:[{key:"type",op:"=",value:"SYSTEM"},{key:"catalogType",op:"=",value:"LAYOUT"},{key:"usable", op:"=", value:"true"}]}, function (res) {
            res.sort(function (a,b) {
                return a.seq - b.seq
            })
            vm.catalogs = res;
            if(res.length>0 && vm.catalogId == null){
                vm.catalogId = res[0].id;
            }
            loadAll();
        });
        //分类点击
        $scope.catalogClick = function (item) {
            if(item === null){
                vm.catalogId = null;
            }else{
                vm.catalogId = item.id;
            }

            vm.page = 1;
            vm.loadAll();
        }
        vm.currentChange = function(){
            $timeout(function(){
                vm.loadAll();
            })
        }
        function loadAll () {
            if (vm.account == undefined || vm.account == null) {
                $state.go('home');
                return;
            }
            var items = [{key:"opened", op:"=", value:'true'},{key:"status", op:"=", value:'Approved'}];
            if(vm.catalogId != null){
                items.push({key:"backCatalog.id", op:"=", value:vm.catalogId})
            }
            if(vm.searchQuery != null){
                items.push({key:"name", op:"like", value:vm.searchQuery})
            }
            PicLayoutXmls.byCondition2({
                    items:items,
                    page: vm.pageInfo.page - 1,
                    size: vm.pageInfo.size,
                    sort: ["lastModifiedDate,desc"]
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.pageInfo.total = headers('X-Total-Count');
                vm.textList = data;
                vm.selectedAll = false;
                //退出批量编辑模式
                vm.pictureArr = [];
                vm.batchState = false;
            }
            function onError(error) {
                console.log(2);
                MessageService.error("布局获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                catalogId: vm.catalogId,
                selectedAll: vm.selectedAll,
            });
        }

        function search() {
            if(event.keyCode === 13){
                vm.pageInfo.page = 1;
                vm.predicate = 'id';
                if(vm.searchQuery == ""){
                    vm.reverse = false;
                }
                vm.loadAll();
            }

        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        $scope.release = function (data) {
            PicLayoutXmls.onlyRelease({id: data.id}, function (res) {

            })
        }

        $scope.pictureSelect = function(item){
            if(!vm.batchState) return;
            item.selected = !item.selected;
            pictureSelectInfo();
            if(vm.pictureArr.length == vm.textList.length){
                vm.selectedAll = true;
            }else{
                vm.selectedAll = false;
            }
        }
        $scope.selectedAllClick = function () {
            vm.selectedAll = !vm.selectedAll;
            vm.textList.forEach(function (item) {
                item.selected = vm.selectedAll;
            })
            pictureSelectInfo();
            if(vm.pictureArr.length == vm.textList.length){
                vm.selectedAll = true;
            }else{
                vm.selectedAll = false;
            }
        }

        function pictureSelectInfo(){
            vm.pictureArr=[];
            vm.textList.forEach(function (item) {
                if(item.selected){
                    vm.pictureArr.push(item)
                }
            })
        }

        //查看
        $scope.look = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/text/modal/text-xml-look.html',
                controller: 'TextXmlLookController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            url: data.cover,
                            domain: $scope.domains.domain
                        };
                    }]
                }
            });
        }
        $scope.save = function(id){
            if(vm.pictureArr.length == 0 && !id){
                MessageService.error("请选择布局");
                return
            }
            $uibModal.open({
                templateUrl: 'app/entities/picture/picture-share-modal1.html',
                controller: 'PictureShareModal1Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        // return vm.pictureArr;
                        return {
                            selectedAll: vm.selectedAll,
                            pictureArr: id ? [id] : vm.pictureArr,
                            backCatalogId: vm.catalogId,
                        };
                    }]
                }
            }).result.then(function(){
                //弹窗关闭
                vm.loadAll();
            });
        }
    }
})();

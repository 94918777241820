(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MycenterController', MycenterController);

    MycenterController.$inject = ['$scope', '$timeout', '$state', "$q", 'Moulds', 'ParseLinks', 'AlertService', 'paginationConstants',
        'pagingParams', 'MessageService', 'Qinius', '$uibModal', 'GetSetDpi', 'GetSetDpi2'
    ];

    function MycenterController($scope, $timeout, $state, $q, Moulds, ParseLinks, AlertService, paginationConstants,
        pagingParams, MessageService, Qinius, $uibModal, GetSetDpi, GetSetDpi2) {
        var vm = this;
        vm.edit = false;
         
        vm.gitDpi = GetSetDpi.getCurrent()
        vm.gitDpiOptions;
        // 请求页面数据
        $q.all([vm.gitDpi.$promise]).then(function() {
            $scope.store = vm.gitDpi.message;
        }).then(function() {
            GetSetDpi2.getAllByCondition({"sort":["value,asc"]}, function(res) {
                vm.gitDpiOptions = res.message
            })
        });

        // 确认提交
        $scope.submitDpi = function() {
            GetSetDpi.submitDpi($scope.store, function(res) {
                if (res.status == 200) {
                    vm.edit = false;
                    MessageService.success("保存成功");
                } else {
                    MessageService.error("失败");
                }
            })
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PatternsModalController', PatternsModalController);

    PatternsModalController.$inject = ['$timeout', '$scope', 'UidService', '$stateParams', '$uibModalInstance', 'entity', '$state', 'Moulds', 'MessageService', 'Qinius','$uibModal'];

    function PatternsModalController ($timeout, $scope, UidService, $stateParams, $uibModalInstance, entity, $state, Moulds, MessageService, Qinius,$uibModal) {
        var vm = this;
        vm.oldView = false;

        vm.cancel = cancel;
        vm.save = save;
        vm.type = entity.type;
        vm.id = entity.id;
        vm.openedCheck = openedCheck;
        $scope.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.units = [
            {name:'px', id:'px'},
            {name:'mm', id:'mm'},
        ]
        if(vm.type == 'add'){
            vm.data = {
                name: '',
                gWidth: '',
                gHeight: '',
                sizeUnit: 'mm',
                x: '',
                y: '',
                width: '',
                height: '',
                modelPic: '',
                hierarchy: 'BOTTOM',
                modelType:'TWO'
            }
        }else {
            Moulds.get({id: vm.id},function (res) {
                vm.data = res;
            })
        }

        var bigSizePics = [] ;
        //模型图片
        window.uploadPic = function ($event) {
            var files = $event.target.files;
            bigSizePics = [];
            if (files != null && files.length > 0) {
                var file = files[0];
                if (file.size >= 20 * 1024 * 1024){
                    file.imageSize = Math.ceil(file.size / 1024 / 1024);
                    bigSizePics.push(file);
                }else {
                    qiniuUpload(file);
                }
            }
            if (bigSizePics.length > 0) {
                thumbnailatorPic();
            }
            $event.srcElement.value = ""
        }
        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'pic-' + UidService.get());
            formData.append('token', vm.tokens.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.data.modelPic = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        $scope.removeFile = function () {
            vm.data.modelPic = "";
        }

        //层级
        function openedCheck(item) {
            vm.data.hierarchy = item ? 'TOP' : 'BOTTOM';
            console.log(vm.data.hierarchy)
        }

        function checkFloatNum(str){
            var reg_zs = /^[0-9]\d*(\.\d+)?$/i;
            if (!reg_zs.test(str)) {
                return false;
            }
            return true;
        }
        //保存
        function save() {
            if(vm.data.sizeUnit == 'px'){
                vm.data.sizeUnit = 'mm';
                unit(vm.data.sizeUnit);
            }
            if(vm.data.name.length > 20 || vm.data.name.length < 1){
                MessageService.error("模型名称不能为空且不大于20个字符");
                return
            }
            if(!checkFloatNum(vm.data.gWidth) || !checkFloatNum(vm.data.gHeight)){
                MessageService.error("请输入有效的模型尺寸");
                return
            }
            if(!checkFloatNum(vm.data.x) || !checkFloatNum(vm.data.y) || !checkFloatNum(vm.data.width) || !checkFloatNum(vm.data.height)){
                MessageService.error("请输入有效的定制区域值");
                return
            }
            if(!vm.data.modelPic){
                MessageService.error("请上传模型图片");
                return
            }
            if(vm.data.sizeUnit == 'px'){
                unit('mm')
                vm.data.sizeUnit = 'mm';
            }
            vm.data.sourceType = 'front';
            if(vm.type == 'add'){
                Moulds.save(vm.data, function (res) {
                    MessageService.success("新增成功");
                    $uibModalInstance.close(true);
                },function (error) {
                    MessageService.error("保存失败，请稍后重试！");
                })
            }
            if(vm.type == 'edit'){
                Moulds.update(vm.data, function (res) {
                    MessageService.success("编辑成功");
                    $uibModalInstance.close(true);
                },function (error) {
                    MessageService.error("保存失败，请稍后重试！");
                })
            }
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
        //dpi
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[0];
            return arrDPI;
        }

        //单位换算
        $scope.unitConversion = function () {
            unit(vm.data.sizeUnit)
        }
        function unit(item){
            if(item == 'mm'){
                vm.data.gWidth = (vm.data.gWidth/vm.windowDpi*25.4).toFixed(2);
                vm.data.gHeight = (vm.data.gHeight/vm.windowDpi*25.4).toFixed(2);
                vm.data.x = (vm.data.x/vm.windowDpi*25.4).toFixed(2);
                vm.data.y = (vm.data.y/vm.windowDpi*25.4).toFixed(2);
                vm.data.width = (vm.data.width/vm.windowDpi*25.4).toFixed(2);
                vm.data.height = (vm.data.height/vm.windowDpi*25.4).toFixed(2);
            }
            if (item == 'px'){
                vm.data.gWidth = (vm.data.gWidth/25.4 * vm.windowDpi).toFixed(2);
                vm.data.gHeight = (vm.data.gHeight/25.4 * vm.windowDpi).toFixed(2);
                vm.data.x = (vm.data.x/25.4 * vm.windowDpi).toFixed(2);
                vm.data.y = (vm.data.y/25.4 * vm.windowDpi).toFixed(2);
                vm.data.width = (vm.data.width/25.4 * vm.windowDpi).toFixed(2);
                vm.data.height = (vm.data.height/25.4 * vm.windowDpi).toFixed(2);
            }
        }

        function thumbnailatorPic(){
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics = [];
                if (values) {
                    //for (var j = 0; j < values.length; j++) {

                    //var data = {key: value.key};
                    var value = values[0];
                    qiniuUpload(value);
                    //}
                }
            }, function () {
            });false
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PictureController', PictureController);

    PictureController.$inject = ['$state', '$scope', 'ProductXml', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', 'PicLayoutXmls', '$localStorage', 'Qinius', '$uibModal', '$stateParams','$timeout','Catalog'];

    function PictureController($state, $scope, ProductXml, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, PicLayoutXmls, $localStorage, Qinius, $uibModal, $stateParams,$timeout,Catalog) {
        var vm = this;
        //搜索防抖定时器
        vm.timer = null;
        //分页信息
        vm.pageInfo = {
            page: 1,
            size: 20,
            total: 0,
        }
        //批量操作状态
        vm.batchState = false;
        vm.statusList = [
            {label: '全部', value: '', op: '='},
            {label: '未分享', value: null, op: 'isnull'},
            {label: '审核中', value: 'Created', op: '='},
            {label: '已分享', value: 'Approved', op: '='},
            {label: '已驳回', value: 'Rejected', op: '='}
        ]
        //主题列表
        vm.catalogs = [];
        //选中的主题id
        vm.catalogSelected = null;
        vm.selectStatus = '';
        //选中的数量
        vm.selectCount = 0;
        vm.loadPage = loadPage;
        vm.predicate = "lastModifiedDate";
        vm.reverse = false;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = "";
        vm.jump = jump;
        vm.account = $localStorage.user;
        $scope.domains = Qinius.getDomain();
        vm.operations=['编辑','复制','删除','分享'];
        vm.catalogId = $stateParams.id;

        loadAll();

        //分页变更
        vm.currentChange = function () {
            $timeout(function () {
                vm.loadAll();
            })
        }
        vm.getCatalog = function () {
            Catalog.getAll({items:[{key:"usable",op:"=",value:"true"},{key:"catalogType",op:"=",value:"LAYOUT"}]}, function (data) {
                vm.catalogs = data;
                vm.catalogs.sort(function(a, b){
                    return a.seq - b.seq;
                })
                console.log(vm.catalogs,"ca")
            })
        }
        vm.getCatalog();
        function loadAll() {
            if (vm.account == undefined || vm.account == null) {
                $state.go('home');
                return;
            }
            var items = [];
            if(vm.catalogSelected){
                items.push({key: "catalog.id", op: "=", value: vm.catalogSelected})
            }
            var status = vm.statusList.find(function (item) {
                return item.value === vm.selectStatus;
            })
            if (status.value !== "") {
                items.push({key: 'status', value: status.value, op: status.op})
            }
            if (vm.searchQuery) {
                items.push({key: "name", value: vm.searchQuery, op: "like"});
            }
            PicLayoutXmls.byCondition({
                items:items,
                page: vm.pageInfo.page -1,
                size: vm.pageInfo.size,
                sort: ["lastModifiedDate,desc"]
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.pageInfo.total = headers('X-Total-Count');
                vm.textList = data;
                //退出批量操作
                vm.batchState = false;
                vm.selectCount = 0;
            }
            function onError(error) {
                MessageService.error("照片布局列表获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search() {
            if(event.keyCode != 13) return;
            vm.links = null;
            vm.pageInfo.page = 1;
            vm.loadAll();

        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }
        //主题选择
        $scope.selectCatalog = function(e){
            if((e && e.id == vm.catalogSelected) || (!e && !vm.catalogSelected)) return;
            if(!e){
                vm.catalogSelected = null;
            }else{
                vm.catalogSelected = e.id
            }
            vm.pageInfo.page = 1;
            vm.loadAll();
        }
        //批量操作选择
        $scope.checkItem = function(item){
            if (!vm.batchState) {
                return
            }
            if(!item.selected){
                item.selected = true;
            }else{
                item.selected = false;
            }
            //获取选中的数量
            var selectList = vm.textList.filter(function (item) {
                return item.selected === true;
            })
            vm.selectCount = selectList.length;
        };
        //状态点击
        $scope.statusCheck = function (e) {
            if (e === vm.selectStatus) return;
            vm.selectStatus = e;
            vm.pageInfo.page = 1;
            vm.loadAll();
        }
        //查看
        $scope.look = function(item){
            console.log(item);
            $uibModal.open({
                templateUrl: 'app/entities/picture/modal/picture-preview.html',
                controller: 'PicturePreviewController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: [function () {
                        return {
                            cover:item.cover
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            });
        }
        //查看驳回原因
        $scope.showReason = function (item) {
            MessageService.confirm({
                title:'驳回理由',
                cancel:'取消',
                hideCancel:true,
                confirm:'知道了',
                msg:'理由： '+ (item.rejectReason || '') ,
            },function(){

            })
        }
        $scope.editorClassify = function(){
            $uibModal.open({
                templateUrl: 'app/entities/page/catalog-management.html',
                controller: 'CatalogManagementController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: function () {
                        return {catalogType: "LAYOUT"}
                    }
                }
            }).result.then(function(res) {
                vm.getCatalog();
            },function(){
                vm.getCatalog();
            });
        }
        $scope.share = function (data) {
            var ids = [];
            if(data){
                ids = [data.id];
            }else{
                var selectList = vm.textList.filter(function (item) {
                    return item.selected === true;
                })
                if(selectList.length === 0) return MessageService.error("至少选择一条数据");
                ids = selectList.map(function (item) {
                    return item.id;
                });

            }
            $uibModal.open({
                templateUrl: 'app/entities/picture/picture-share-modal.html',
                controller: 'PictureShareModalController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: [function () {
                        return ids;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                vm.loadAll();
            });
        }
        $scope.delete = function (data) {
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除该数据？'
            },function(){
                PicLayoutXmls.delete({id: data.id}, function (res) {
                    MessageService.success("删除成功");
                    vm.loadAll();
                })
            },function(){});
        }
        //批量删除
        $scope.batchDelete = function(){
            var selectList = vm.textList.filter(function (item) {
                return item.selected === true;
            })
            if(selectList.length === 0) return MessageService.error("至少选择一条数据");
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除选中的数据？'
            },function() {
                var ids = selectList.map(function (item) {
                    return item.id;
                });
                PicLayoutXmls.batchDelete(ids,function(){
                    MessageService.success("删除成功");
                    vm.loadAll();
                })
            })

        }
        $scope.editXml = function (data) {
            $state.go('picture-xml-edit',{id: data.id});
        }
        $scope.copyXml = function (data) {
            PicLayoutXmls.copy({id: data.id}, function (res) {
                MessageService.success("复制成功");
                loadAll();
            })
        }
        //新增
        $scope.add = function () {
            $uibModal.open({
                templateUrl: 'app/entities/picture/modal/picture-add.html',
                controller: 'PictureAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                        };
                    }]
                }
            });
        }

        $scope.editXmlTheme = function(item){
            $uibModal.open({
                templateUrl: 'app/entities/picture/modal/picture-edit.html',
                controller: 'PictureEditController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: item
                }
            }).result.then(function(backData) {
                if (backData) {
                    loadAll();
                }
            });
        }

        $scope.operation = function(item){
            var operationOldFlag = item.flag;
            vm.textList.forEach(function(li) {
                li.flag = false;
            });
            item.flag = !operationOldFlag;
        }
    }
})();

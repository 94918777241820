window.angular.module('modules.service', []).factory('ModulesService', ['FontManagement', 'MessageService', '$timeout', '$rootScope', '$state', '$uibModal', '$stateParams',
    function (FontManagement, MessageService, $timeout, $rootScope, $state, $uibModal, $stateParams) {

        var vm = this;

        this.sorts = function (predicate, reverse) {
            // return ["createdDate,desc"];
            return [predicate + ',' + (reverse ? 'asc' : 'desc')];
        }

        this.fn = function (e) {
            // e = e || window.event;
            // e.cancelBubble = true;
            // e.stopPropagation();
            // e.returnValue = false;
            // e.preventDefault();
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        // 得到浏览器dpi
        this.getDPI = function () {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            } else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            // vm.windowDpiW = 300;
            // vm.windowDpiH = 300;
            return arrDPI;
        }

        // 判断是哪个浏览器
        this.myBrowser = function () {
            var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
            var isOpera = userAgent.indexOf("Opera") > -1;
            if (isOpera) {
                return "Opera"
            }
            ; //判断是否Opera浏览器
            if (userAgent.indexOf("Firefox") > -1) {
                return "FF";
            } //判断是否Firefox浏览器
            if (userAgent.indexOf("Chrome") > -1) {
                return "Chrome";
            }
            if (userAgent.indexOf("Safari") > -1) {
                return "Safari";
            } //判断是否Safari浏览器
            if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
                return "IE";
            }
            ; //判断是否IE浏览器
        }

        // 全屏
        this.toggleFullScreen = function (attr) {
            if (!attr) {
                //进入全屏
                var de = document.documentElement;
                if (de.requestFullscreen) {
                    de.requestFullscreen();
                } else if (de.mozRequestFullScreen) {
                    de.mozRequestFullScreen();
                } else if (de.webkitRequestFullScreen) {
                    de.webkitRequestFullScreen();
                }
                return true
            } else {
                //退出全屏
                var de = document;
                if (de.exitFullscreen) {
                    de.exitFullscreen();
                } else if (de.mozCancelFullScreen) {
                    de.mozCancelFullScreen();
                } else if (de.webkitCancelFullScreen) {
                    de.webkitCancelFullScreen();
                }
                return false
            }
        }

        // 旋转角度
        this.getAngle = function (px, py, mx, my) {//获得人物中心和鼠标坐标连线，与y轴正半轴之间的夹角
            var x = Math.abs(px - mx);
            var y = Math.abs(py - my);
            var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
            var cos = y / z;
            var radina = Math.acos(cos);//用反三角函数求弧度
            var angle = Math.floor(180 / (Math.PI / radina));//将弧度转换成角度
            if (mx < px && my > py) {//鼠标在第三象限
                angle = angle;
            }
            if (mx < px && my < py) {
                angle = 180 - angle;
            }
            if (mx > px && my < py) {
                angle = 180 + angle;
            }
            if (mx > px && my > py) {
                angle = 360 - angle;
            }
            return angle;

        }

        // 进度条
        this.rangeValue = function (elem) {
            return elem.value
        }

        // mm换算成px
        this.unitTransform = function (mm) {
            // mm换算成px  Math.ceil(mm / 25.4 * 300);
            // return (mm * 3) - 0;
            var dpi = this.getDPI();
            return mm / 25.4 * dpi[0];
        }

        // px换算成mm
        this.unitTransformPx = function (px) {
            // px换算成mm  Math.floor(px / 300 * 25.4)
            // return (px * 1 / 3).toFixed(2) - 0;
            var dpi = this.getDPI();
            return px / dpi[0] * 25.4;
        }

        this.getGeometryToPx = function (obj) {
            return {
                height: this.unitTransform(obj.height),
                width: this.unitTransform(obj.width),
                x: this.unitTransform(obj.x),
                y: this.unitTransform(obj.y)
            }
        }

        // px---mm
        this.threeHundredDpiToMm = function (attr1, attr2) {
            var dpi = this.getDPI();
            var mm1 = parseFloat((attr1 / dpi[0] * 25.4 - 0)).toFixed(2) - 0;
            var mm2 = parseFloat((attr2 / dpi[0] * 25.4 - 0)).toFixed(2) - 0;
            return {
                mm1: mm1,
                mm2: mm2
            }
        }

        this.splitFileName = function (text) {
            var pattern = /\.{1}[a-z]{1,}$/;
            if (pattern.exec(text) !== null) {
                return (text.slice(0, pattern.exec(text).index));
            } else {
                return text;
            }
        }

        this.compare = function (property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        return this;

    }]);

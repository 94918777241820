(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('MonthIndexSetController', MonthIndexSetController);

    MonthIndexSetController.$inject = ['$scope', '$uibModalInstance', 'MessageService'];

    function MonthIndexSetController( $scope, $uibModalInstance, MessageService) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.monthIndex="";

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if (!vm.monthIndex) {
                MessageService.error('第几个月只能为正整数')
                return;
            }
            if (vm.monthIndex>24) {
                MessageService.error('最大不能超过24个月')
                return;
            }
            if (vm.monthIndex<1) {
                MessageService.error('不能小于1个月')
                return;
            }
            $uibModalInstance.close(vm.monthIndex);
        }

    }

})();

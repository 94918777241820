(function () {
    "use strict";

    angular.module("platformApp").controller("FrameControlIssueDialogController", FrameControlIssueDialogController);

    FrameControlIssueDialogController.$inject = [
        "$timeout",
        "$q",
        "$scope",
        "UidService",
        "$stateParams",
        "$uibModalInstance",
        "FrameConfig",
        "Qinius",
        "MessageService",
        "ThemeClassify",
        "$http",
        "entity",
        "borderId"
    ];

    function FrameControlIssueDialogController(
        $timeout,
        $q,
        $scope,
        UidService,
        $stateParams,
        $uibModalInstance,
        FrameConfig,
        Qinius,
        MessageService,
        ThemeClassify,
        $http,
        entity,
        borderId
    ) {
        var vm = this;
        //是否全选
        vm.checkAll = false;
        vm.clear = clear;
        vm.save = save;
        vm.thirdPartySettings = angular.copy(entity.thirdPartySettings) || [];
        vm.checkFn = function (item) {
            item.selected = !item.selected;
            //判断是否全选
            var selectedList = vm.thirdPartySettings.filter(function (item) {
                return item.selected;
            });
            if (selectedList.length === vm.thirdPartySettings.length) {
                vm.checkAll = true;
            } else {
                vm.checkAll = false;
            }
        };
        vm.step = "firstStep";
        //发布提交Loading
        vm.saveLoading = false;
        vm.checkAllFn = function () {
            vm.checkAll = !vm.checkAll;
            vm.thirdPartySettings.forEach(function (item) {
                item.selected = vm.checkAll;
            });
        };

        function save() {
            vm.storeIds = [];
            vm.thirdPartySettings.forEach(function (item) {
                if (item.selected) {
                    vm.storeIds.push(item.id);
                }
            });
            if (vm.storeIds.length == 0) {
                MessageService.error("请选择发布的渠道");
                return;
            }
            vm.storeIds = vm.storeIds.toString();
            vm.step = "secondStep";
            vm.saveLoading = true;
            FrameConfig.onlyRelease(
                { id: borderId, storeIds: vm.storeIds },
                function (res) {
                    vm.releaseData = res;
                    vm.saveLoading = false;
                    if (vm.releaseData.status == 400) {
                        vm.releaseData.message.forEach(function (item) {
                            vm.thirdPartySettings.forEach(function (it) {
                                if (item.storeId == it.id) {
                                    item.name = it.name;
                                }
                            });
                        });
                    }
                },
                function () {
                    MessageService.error("发布失败");
                    vm.saveLoading = false;
                }
            );
        }
        function clear() {
            $uibModalInstance.dismiss();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('NoticeController', NoticeController);

    NoticeController.$inject = ['$state', '$scope', 'Notice', 'ParseLinks', 'paginationConstants', 'MessageService', '$uibModal'];

    function NoticeController($state, $scope, Notice, ParseLinks, paginationConstants, MessageService, $uibModal) {

        var vm = this;
        $(".sidebar1").hide();
        $(".navbar").show();
        $(".nav-bg").show();
        $(".wrap").css({margin: 0});
        vm.tab = ""

        loadAll();

        $scope.checkoutTab = function(tab){
            vm.tab = tab
            var items=[];
            if (!!vm.tab) {
                items.push({key:"noticeType", op:"=", value:tab});
            }
            Notice.getAllByCondition1({items: items}, onSuccess, onError);
        }

        $scope.giveNoticeCb = function(data){
            if (!data.read) {
                Notice.read({id:data.id});
            }
            $uibModal.open({
                templateUrl: 'app/entities/notice/notice-detail.html',
                controller: 'NoticeDetailController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size:'lg',
                resolve: {
                    entity: data,
                }
            }).result.then(function(res) {
                data.read = true; 
            });
        }

        function onSuccess(data, headers) {
            vm.data = data.message;
        }
        function onError(error) {
            MessageService.error("数据加载失败");
        }

        function loadAll () { 
            Notice.getAllByCondition1({ }, onSuccess, onError);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarCenterBodyPreviewModalController', CalendarCenterBodyPreviewModalController);

        CalendarCenterBodyPreviewModalController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService', 'CalendarService'];

    function CalendarCenterBodyPreviewModalController($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService, CalendarService) {
        var vm = this;
        vm.cancel = cancel;
        vm.width = 400;
        vm.height = 400;
        vm.widget = entity.data || {};
        vm.monthObj = {};

        init();

        function init() {
            vm.data = angular.copy(vm.widget);

            if (vm.data.sampleYear) {
                vm.data.sampleYear = String(vm.data.sampleYear);
            }
            if (vm.data.sampleMonth) {
                vm.data.sampleMonth = String(vm.data.sampleMonth);
            }
            if (vm.data.width) {
                vm.height = vm.data.height * 400 / vm.data.width;
            }
            queryMonthdaysDataset();
        }

        function queryMonthdaysDataset() {
            var req = {
                year: vm.data.sampleYear || 2023,
                month: vm.data.sampleMonth || 1,
                startOfWeek: vm.data.startOfWeek || 0,
            };
            var dateFormats = [];
            var dateLayout = vm.data.dateLayout || {};
            if (dateLayout.items && dateLayout.items.length) {
                for (var i = 0; i < dateLayout.items.length; i++) {
                    var item = dateLayout.items[i];
                    if (item.type == 'date') {
                        dateFormats.push({ type: item.type, format: item.format });
                    }
                }
            }
            var weekFormats = [];
            var weekLayout = vm.data.weekLayout || {};
            if (weekLayout.items && weekLayout.items.length) {
                for (var i = 0; i < weekLayout.items.length; i++) {
                    var item = weekLayout.items[i];
                    if (item.type == 'week') {
                        weekFormats.push({ type: item.type, format: item.format });
                    }
                }
            }

            req.dateFormats = dateFormats;
            req.weekFormats = weekFormats;
            CalendarService.queryMonthTable(req, function (res) {
                vm.monthObj = res.data || {};
            });

        }

        //返回
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();

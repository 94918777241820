(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PageAddLabelController', PageAddLabelController);

    PageAddLabelController.$inject = ['$scope', '$uibModalInstance', '$state', '$stateParams','pages', 'MessageService'];

    function PageAddLabelController($scope, $uibModalInstance, $state, $stateParams, pages, MessageService) {

        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.label = ''


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }


        $scope.validate = function () {
            if (vm.label == null || vm.label == "") {
                MessageService.error("请输入主题分类");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            onSaveSuccess(vm.label);
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();

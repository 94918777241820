(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('PicLayoutXmls', PicLayoutXmls);
    PicLayoutXmls.$inject = ['$resource'];
    function PicLayoutXmls($resource) {
        var resourceUrl = 'bunny/' + 'api/pic-layout-xmls/:path/:id';

        return $resource(resourceUrl, {}, {
            "useAll":{
                method: 'GET',
                params:{
                    path:"useAll"
                },
            },
            "covert":{
                method: 'GET',
                params:{
                    path:"covert"
                },
            },
            "use":{
                method: 'GET',
                params:{
                    path:"use"
                },
            },
            "save": {
                method: 'POST'
            },
            "delete": {
                method: 'DELETE'
            },
            "batchDelete":{//批量删除
                method: 'POST',
                params:{
                    path:"batchDelete"
                }
            },
            "release": {
                method: 'POST',
                params:{
                    path:"release"
                },
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray:true
            },
            "getAll":{
                method:"POST",
                params:{
                    path:"getAll"
                },
                isArray:true
            },
            "getAll2":{
                method:"POST",
                params:{
                    path:"getAll2"
                },
                isArray:true
            },
            "copy":{
                method:"GET",
                params:{
                    path:"copy"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'onlyRelease': {
                method: 'GET',
                params:{
                    path:"onlyRelease"
                },
            },
            'share': {
                method: 'GET',
                params:{
                    path:"share"
                },
            },
            'batchShare': {//批量分享
                method: 'POST',
                params:{
                    path:"batchShare"
                }
            },
            'auditing': {
                method: 'GET',
                params:{
                    path:"auditing"
                },
            },
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MaskDetailController', MaskDetailController); 

    MaskDetailController.$inject = ['$timeout', "$q", '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SourceMaterial',
            'MessageService','ThemeClassify','$http','$uibModal'];

    function MaskDetailController ($timeout, $q, $scope, $stateParams, $uibModalInstance, entity, SourceMaterial, 
        MessageService,ThemeClassify, $http,$uibModal) {

        var vm = this;
        vm.data = entity;
        vm.save = save;
        vm.themeClassifies = ThemeClassify.getAllByCondition1({items:[{key:"type",value:"MASK", op:"="},{key:"used",value:"true", op:"="}]});

        $scope.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }

        function save (){
            SourceMaterial.update(vm.data, function(res){
                MessageService.success("保存成功");
                $uibModalInstance.close(res);
            }, function(ers){
                MessageService.error("保存失败,请稍后再试");
                $uibModalInstance.dismiss('cancel');
            })
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarTitleController', CalendarTitleController);

    CalendarTitleController.$inject = ['$state', '$scope', 'ProductXml', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', 'TitleWidget', '$localStorage', 'Qinius', '$uibModal'];

    function CalendarTitleController($state, $scope, ProductXml, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, TitleWidget, $localStorage, Qinius, $uibModal) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.account = $localStorage.user;
        $scope.domains = Qinius.getDomain();
        vm.status = status;

        loadAll();

        $scope.delete = function(id){
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除该数据？'
            },function(){
                TitleWidget.delete({id: id},function () {
                    loadAll();
                });
            },function(){});
        };

        function loadAll () {
            if (vm.account == undefined || vm.account == null) {
                $state.go('home');
                return;
            }
            TitleWidget.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
        }
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.textList = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            MessageService.error("文字模板获取失败");
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        $scope.release = function (data) {
            TitleWidget.get({id:data.id}, function (res) {
                $uibModal.open({
                    templateUrl: 'app/entities/text/modal/text-detection.html',
                    controller: 'TextDetectionController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: [function() {
                            return {
                                textDocument: res.textDocument,
                                id:res.id,
                                storeIds:[],
                            };
                        }]
                    }
                }).result.then(function (isReturn) {
                })
            })



            // TitleWidget.onlyRelease({id: data.id}, function (res) {
            //     MessageService.success("发布成功");
            //     loadAll();
            // }, function (error) {
            //     MessageService.error("发布失败，请稍后重试！");
            // })
        }


        $scope.share = function (data) {
            TitleWidget.share({id: data.id}, function (res) {
                MessageService.success("分享成功");
                loadAll();
            })
        }
        $scope.delete = function (data) {
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除该数据？'
            },function(){
                TitleWidget.delete({id: data.id}, function (res) {
                    MessageService.success("删除成功");
                    loadAll();
                })
            },function(){});
        }
        //查看
        $scope.look = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/text/modal/text-xml-look.html',
                controller: 'TextXmlLookController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            url: data.previewUrl,
                            domain: $scope.domains.domain
                        };
                    }]
                }
            });
        }
        //新增
        $scope.add = function () {
            $uibModal.open({
                templateUrl: 'app/entities/calendar/modal/calendar-title-add.html',
                controller: 'CalendarTitleAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return null;
                    }]
                }
            }).result.then(function(res) {
                if (res) {
                    loadAll();
                }
            });
        }
        //复制
        $scope.copy = function (item) {
            var _data = angular.copy(item);
            _data.name = _data.name + '_副本';
            $uibModal.open({
                templateUrl: 'app/entities/calendar/modal/calendar-title-add.html',
                controller: 'CalendarTitleAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return _data;
                    }]
                }
            });
        }
        function status(item) {
            switch (item) {
                case 'Created':
                    return '已分享';
                    break;
                case 'Approved':
                    return '已通过';
                    break;
                case 'Rejected':
                    return '已拒绝';
                    break;
                default:
                    return '未分享'
            }
        }
    }
})();

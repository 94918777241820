(function() {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('platformApp')
        .factory('Base64Image', Base64Image);

    function Base64Image () {
        

        var service = {
            getSize: getSize,
            getMimeType: getMimeType,
            compress: compress
        };

        return service;

        function getSize(data) {
            if (!data) {
                return 0;
            }
            var start = 0;
            var end = data.length;
            var pos = data.indexOf(';base64,');

            if (pos > 0) {
                start = pos + 8;
            }
            pos = data.indexOf("=");
            if (pos > 0) {
                end = pos;
            }

            var len = end - start;
            return parseInt(len - (len/8) * 2);
        }

        function getMimeType(data) {
            var arr = data.split(',');
            var mime = arr[0].match(/:(.*?);/)[1];
            // return mime.replace("image/", "");
            return mime;
        }

        function compress(data, length, quality, callback) {
            var getMimeType = function (urlData) {
                var arr = urlData.split(',');
                var mime = arr[0].match(/:(.*?);/)[1];
                // return mime.replace("image/", "");
                return mime;
            };

            var quality_value = quality || 0.6;
            var newImage = new Image();
            var imgWidth, imgHeight;

            newImage.src = data;
            newImage.setAttribute("crossOrigin", 'Anonymous');  //url为外域时需要
            newImage.onload = function() {
                imgWidth = this.width;
                imgHeight = this.height;
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                if (Math.max(imgWidth, imgHeight) > length) {
                    if (imgWidth > imgHeight) {
                        canvas.width = length;
                        canvas.height = length * imgHeight / imgWidth;
                    } else {
                        canvas.height = length;
                        canvas.width = length * imgWidth / imgHeight;
                    }
                } else {
                    canvas.width = imgWidth;
                    canvas.height = imgHeight;
                }
                // 覆盖默认填充颜色 (#000)
                var fillStyle = '#fff';
                ctx.fillStyle = fillStyle;
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
                var base64 = canvas.toDataURL("image/jpeg", quality_value); //压缩语句

                if (callback) {
                    callback(base64);
                }
            }
        }
    }
})();

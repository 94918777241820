(function () {
    'use strict';

    var FontFamilyControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/calendar/controls/font-family.html',
        controllerAs: 'vm',
        bindings: {
            family: "=",
            onChange:'&',
            isCalendar:"<",//是否是日历
        },

        controller: ['$scope', 'FontManagement', '$q', 'Qinius','MessageService', function ($scope, FontManagement, $q, Qinius,MessageService) {
            var vm = this;
            $scope.domains = Qinius.getDomain();
            $q.all([$scope.domains.$promise]).then(function () {
                vm.url = $scope.domains.domain;
            })
            //获取字体
            vm.fontList = [];
            vm.fontList1 = [];
            FontManagement.getAll3({}, function (resp) {
                if(vm.isCalendar){
                    resp.forEach(function (item) {
                        if(item.calendarSupport){
                            vm.fontList.push(item);
                        }
                    })
                }else{
                    vm.fontList = angular.copy(resp);
                }
                vm.fontList1 = angular.copy(vm.fontList);
            })
            $scope.selectFontName = function (item) {
                if (item) {
                    for (var i = 0; i < vm.fontList1.length; i++) {
                        if (vm.fontList1[i].uuid == item) {
                            return vm.fontList1[i].family
                        }
                    }
                }
            }
            vm.selectFont = '';
            vm.selectFontBtn = fontInit;
            var a = null;
            function fontInit() {
                if (a !== null) {
                    clearTimeout(a)
                }
                a = setTimeout(function () {
                    vm.fontList = [];
                    var item = [];
                    if (vm.selectFont) {
                        item.push({key: "family", op: "like", value: vm.selectFont});
                    }
                    if(vm.isCalendar){
                        item.push({
                            key:'calendarSupport',
                            op:'=',
                            value:true
                        })
                    }
                    FontManagement.getAll3({family:vm.selectFont}, function (resp) {
                        if(vm.isCalendar){
                            resp.forEach(function (item) {
                                if(item.calendarSupport){
                                    vm.fontList.push(item);
                                }
                            })
                        }else{
                            vm.fontList = angular.copy(resp);
                        }
                    })
                },800)
            }

            $scope.updateTextFont = function (data) {
                fontManagement(data,function () {
                    vm.family = data.uuid;
                    vm.onChange({text: vm.family});
                    getFont({
                        fontuuid: vm.family
                    },FontManagement);
                })
            }
            function fontManagement(data,callback) {
                var text="〇一二三四五六七八九十零壹贰叁肆伍陆柒捌玖拾年月日子丑寅卯辰巳午未申酉戌亥甲乙丙丁戊己庚辛壬贵鼠牛虎兔龙蛇马羊猴鸡狗猪abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ廿 公农历周星期";
                //普通
                var normalFlag = data.fontStyles.find(function (item) {
                    return item.style === 'NORMAL';
                });
                //粗体
                var boldFlag = data.fontStyles.find(function (item) {
                    return item.style === 'BOLD';
                });
                //斜体
                var italtcFlag = data.fontStyles.find(function (item) {
                    return item.style === 'ITALIC';
                });
                //粗斜体
                var boldItaltcFlag = data.fontStyles.find(function (item) {
                    return item.style === 'BOLD_ITALIC';
                });
                var bold = false;
                var italic = false;
                if(!normalFlag && boldFlag){
                    bold = true;
                }
                if(!normalFlag && italtcFlag){
                    italic = true;
                }
                if(!normalFlag && boldItaltcFlag){
                    bold = true;
                    italic = true;
                }
                FontManagement.fontSupport({
                    uuid: data.uuid,
                    bold: bold,
                    italic: italic,
                    content: text
                }, function (res) {
                    var msg = res.message;
                    if (res.status == 200&&msg.success&&msg.supported) {
                        callback()
                    } else {
                        MessageService.error("当前字体不可用");
                    }
                },function () {
                    MessageService.error("当前字体不可用");
                })
            }

        }]
    };
    angular.module('platformApp')
        .component('fontFamily', FontFamilyControl);

})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('background', {
            parent: 'app',
            url: '/background?page&sort&search',
            data: {
                authorities: [],
                pageTitle: 'platformAppTitle.myUpload'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/my-upload/background.html',
                    controller: 'BackGroundController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productXmlPurchase');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('title');
                    return $translate.refresh();
                }]
            }
        })
        .state('background.updateTag', {
            parent: 'background',
            url: '/{id}/updateTag',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/my-upload/update-tag.html',
                    controller: 'BackgroundDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['Backgrounds', function(Backgrounds) {
                            return Backgrounds.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('background', {}, {reload: true});
                }, function() {
                    $state.go('background');
                });
            }]
        })

        .state('pic', {
            parent: 'app',
            url: '/pic?page&sort&search',
            data: {
                authorities: [],
                pageTitle: 'platformAppTitle.myUpload'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/my-upload/pic.html',
                    controller: 'PicController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productXmlPurchase');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('title');
                    return $translate.refresh();
                }]
            }
        })
        .state('pic.updateTag', {
            parent: 'pic',
            url: '/{id}/updateTag',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/my-upload/update-tag.html',
                    controller: 'PicDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['Galleries', function(Galleries) {
                            return Galleries.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pic', {}, {reload: true});
                }, function() {
                    $state.go('pic');
                });
            }]
        })

        .state('mask', {
            parent: 'app',
            url: '/mask?page&sort&search',
            data: {
                authorities: [],
                pageTitle: 'platformAppTitle.myUpload'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/my-upload/mask.html',
                    controller: 'MaskController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productXmlPurchase');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('title');
                    return $translate.refresh();
                }]
            }
        })
        .state('mask.updateTag', {
            parent: 'mask',
            url: '/{id}/updateTag',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/my-upload/update-tag.html',
                    controller: 'MaskDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['SourceMaterial', function(SourceMaterial) {
                            return SourceMaterial.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('mask', {}, {reload: true});
                }, function() {
                    $state.go('mask');
                });
            }]
        })

        .state('clip-art', {
            parent: 'app',
            url: '/clip-art?page&sort&search',
            data: {
                authorities: [],
                pageTitle: 'platformAppTitle.myUpload'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/my-upload/clipArt.html',
                    controller: 'ClipArtController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productXmlPurchase');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('title');
                    return $translate.refresh();
                }]
            }
        })
        .state('clip-art.updateTag', {
            parent: 'clip-art',
            url: '/{id}/updateTag',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/my-upload/update-tag.html',
                    controller: 'ClipArtDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['ClipArts', function(ClipArts) {
                            return ClipArts.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('clip-art', {}, {reload: true});
                }, function() {
                    $state.go('clip-art');
                });
            }]
        })
         ;
    }

})();

(function () {
    "use strict";

    var ModelePagination = {
        restrict: "EAC",
        replace: true,
        transclude: true,
        templateUrl: "app/entities/components/modele-pagination.html",
        controllerAs: "vm",
        scope: {},
        bindings: {
            page: "=",
            total:"<",
            size:"<",
            currentChange:"&"

        },
        controller: [
            "$scope",
            "$timeout",
            function ($scope,$timeout) {
                var vm = this;
                vm.pages = 1;
                vm.$onInit = function(){
                    // console.log(vm.page);
                    // console.log(this.total);

                }
                vm.$onChanges = function(){
                    if(vm.total > 0){
                        vm.pages = Math.ceil(vm.total / vm.size);
                    }else{
                        vm.pages = 1
                    }
                    if(vm.page > vm.pages){
                        vm.page = vm.pages;
                        $timeout(function(){
                            vm.currentChange({page: vm.page});
                        })
                    }
                    $timeout(function(){
                        vm.initPage();
                    })


                }
                vm.initPage = function () {
                    laypage({
                        cont: "modele-pagination",
                        curr:vm.page,
                        pages: vm.pages,
                        skip: true, //是否开启跳页
                        first: false,
                        last: false,
                        limit: true,
                        prev: '<i class="iconfont icon-left"></i>',
                        next: '<i class="iconfont icon-right"></i>',
                        jump: function (obj,first) {
                            if(!first){
                                vm.page = obj.curr;
                                //当前页变更事件
                                if(vm.currentChange){
                                    $timeout(function(){
                                        vm.currentChange({page: vm.page});
                                        //页面滚动到顶部
                                        $(".c-home-body").animate({scrollTop:0},200);
                                    })

                                }
                            }

                        },
                    });
                };

            },
        ],
    };
    angular
        .module("platformApp")
        .component("modelePagination", ModelePagination);
})();

(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('CalendarLayout', CalendarLayout)
        .factory('CalendarService', CalendarService)
        .factory('TitleWidget', TitleWidget)
        .factory('MonthWidget', MonthWidget)
        .factory('CalendarConfig', CalendarConfig);

    CalendarLayout.$inject = ['$resource'];
    function CalendarLayout ($resource) {
        var resourceUrl =  'bunny/' + 'api/cal-layouts/:path';

        return $resource(resourceUrl, {}, {
            "getLayouts":{
                method:"GET",
                params:{
                    path:"getLayouts"
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "updatePreview": {
                method: "POST",
                params: {
                    path: "updatePreview"
                }
            }
        });
    }

    TitleWidget.$inject = ['$resource'];
    function TitleWidget ($resource) {
        var resourceUrl =  'bunny/' + 'api/cal-title-widgets/:path/:id';

        return $resource(resourceUrl, {}, {
            'update': {
                method: "POST",
                params: {
                    path: "edit"
                }
            },
            "share": {
                method: "GET",
                params: {
                    path: "share"
                }
            },
            "copy": {
                method: "GET",
                params: {
                    path: "copy"
                }
            },
            "batchShare":{//批量分享标题
                method: "POST",
                params:{
                    path:'batchShare'
                }
            },
            "create": {
                method: "POST",
                params: {
                    path: "create"
                }
            },
            "edit": {
                method: "POST",
                params: {
                    path: "edit"
                }
            },
            "getWidgets":{
                method:"GET",
                params:{
                    path:"getWidgets"
                }
            },
            "getAllUsable":{
                method:"GET",
                params:{
                    path:"getAllUsable"
                },
                isArray: true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "updatePreview": {
                method: "POST",
                params: {
                    path: "updatePreview"
                }
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray: true
            },
            "byCondition3":{//用于分享
                method:"POST",
                params:{
                    path:"byCondition3"
                },
                isArray: true
            },
            'auditing': {
                method: 'GET',
                params:{
                    path:"auditing"
                },
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "delete": {
                method: 'DELETE'
            },
            "batchDelete":{//批量删除标题
                method: "POST",
                params:{
                    path:'batchDelete'
                }
            },
            "save": {
                method: 'POST'
            },
        });
    }

    MonthWidget.$inject = ['$resource'];
    function MonthWidget ($resource) {
        var resourceUrl =  'bunny/' + 'api/cal-month-widgets/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "share": {
                method: "GET",
                params: {
                    path: "share"
                }
            },
            "copy": {
                method: "GET",
                params: {
                    path: "copy"
                }
            },
            "batchShare":{//批量分享日历
                method: "POST",
                params:{
                    path:'batchShare'
                }
            },
            "create": {
                method: "POST",
                params: {
                    path: "create"
                }
            },
            'update': {
                method: "POST",
                params: {
                    path: "edit"
                }
            },
            "edit": {
                method: "POST",
                params: {
                    path: "edit"
                }
            },
            "getWidgets":{
                method:"GET",
                params:{
                    path:"getWidgets"
                }
            },
            "getAllUsable":{
                method:"GET",
                params:{
                    path:"getAllUsable"
                },
                isArray: true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "updatePreview": {
                method: "POST",
                params: {
                    path: "updatePreview"
                }
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray: true
            },
            "byCondition3":{//用于分享
                method:"POST",
                params:{
                    path:"byCondition3"
                },
                isArray: true
            },
            'auditing': {
                method: 'GET',
                params:{
                    path:"auditing"
                },
            },
            "delete": {
                method: 'DELETE'
            },
            "batchDelete":{//批量删除日历
                method: "POST",
                params:{
                    path:'batchDelete'
                }
            },
            "save": {
                method: "POST",
                params: {
                    path: "save"
                }
            }
        });
    }

    CalendarConfig.$inject = ['$resource'];
    function CalendarConfig ($resource) {
        var resourceUrl =  'bunny/' + 'api/cal-config/:path';

        return $resource(resourceUrl, {}, {
            "getDefault": {
                method: "GET",
                params: {
                    path: "getDefault"
                }
            },
            "save": {
                method: "POST",
                params: {
                    path: "save"
                }
            }
        });
    }

    CalendarService.$inject = ['$resource'];
    function CalendarService($resource) {
        var resourceUrl = 'bunny/api/calendar-service/:path';
        return $resource(resourceUrl, {}, {
            "getYear":{
                method:"GET",
                params:{
                    path:"getYear"
                },
                isArray:true
            },
            "getYears":{
                method:"GET",
                params:{
                    path:"getYears"
                },
                isArray:true
            },
            "getMonth":{
                method:"GET",
                params:{
                    path:"getMonth"
                }
            },
            'getMonthsOfYear': {
                method: 'GET',
                params:{
                    path:'getMonthsOfYear'
                }
            },
            'getDate': {
                method: 'GET',
                params:{
                    path:'getDate'
                }
            },
            'getDatesOfMonth': {
                method: 'GET',
                params:{
                    path:'getDatesOfMonth'
                }
            },
            'getDatesOfTable': {
                method: 'GET',
                params:{
                    path:'getDatesOfTable'
                }
            },
            'getWeekday': {
                method: 'GET',
                params:{
                    path:'getWeekday'
                }
            },
            'getWeekdays': {
                method: 'GET',
                params:{
                    path:'getWeekdays'
                }
            },
            'queryYearMonthSet': {
                method: "POST",
                params: {
                    path: "queryYearMonthSet"
                }
            },
            'queryWeekdaysSet': {
                method: "POST",
                params: {
                    path: "queryWeekdaysSet"
                }
            },
            'queryMonthdaysSet': {
                method: "POST",
                params: {
                    path: "queryMonthdaysSet"
                }
            },
            'queryMonthTable': {
                method: "POST",
                params: {
                    path: "queryMonthTable"
                }
            }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PsdUploadController', PsdUploadController);

    PsdUploadController.$inject = ['Auth', 'Principal', '$scope', '$q', 'MessageService', '$state', '$uibModalInstance','PsdTemplateLoader','UidService','entity','ClipArts','SourceMaterial','$timeout'];

    function PsdUploadController (Auth, Principal, $scope, $q, MessageService, $state, $uibModalInstance,PsdTemplateLoader,UidService,entity,ClipArts,SourceMaterial,$timeout) {
        var vm = this;
        var errorPsdIndex = 0, upPsdNum = 0;
        vm.psdFiles = [];
        vm.templatePsdFiles = [];
        vm.isCanUpload = true;
        vm.defaultFont = entity.defaultFont;
        vm.token = entity.token;
        vm.url = entity.url;
        vm.showMasks = entity.showMasks;

        $(document).ready(function () {
            if (window.history && window.history.pushState) {
                $(window).on("popstate", function () {
                    clear();
                });
            }
            //文件拖曳上传
            $("#upload-psd-box").bind("dragenter", function (event) {
                event.preventDefault();
            });
            $("#upload-psd-box").bind("dragover", function (event) {
                event.preventDefault();
            });
            $("#upload-psd-box").bind("drop", function (event) {
                event.preventDefault();
                var files = event.originalEvent.dataTransfer.files;
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    if (file.size < 100) {
                        MessageService.error(file.name + "不是有效的psd文件");
                        continue;
                    }
                    file.status = 'wait';
                    vm.psdFiles.push(file);
                }
                $scope.$digest();
            });
        });
        window.uploadPsd = function ($event) {
            for (var i = 0; i < $event.target.files.length; i++) {
                var file = $event.target.files[i];
                if (file.size < 100) {
                    MessageService.error(file.name + "不是有效的psd文件");
                    continue;
                }
                file.status = 'wait';
                vm.psdFiles.push(file);
            }
            $scope.$digest();
        };
        $scope.savePsdFile = function () {
            if (!vm.isCanUpload) {
                return
            }
            if (vm.psdFiles.length > 0) {
                errorPsdIndex = 0;
                upPsdNum = 0;
                vm.isCanUpload = false;
                for (var i = 0; i < vm.psdFiles.length; i++) {
                    if (vm.psdFiles[i].status != 'success') {
                        vm.psdFiles[i].status = 'loading';
                        loadPsdFile(vm.psdFiles[i], i)
                    }
                }
            } else {
                MessageService.notice("请选择文件");
            }
        }
        function loadPsdFile(file, i) {
            PsdTemplateLoader.loadPsdFile(file).then(function (template) {
                // console.log("i: "+i)
                // console.log('template', template);
                file.status = 'success';
                template.name = file.name;
                processPsdToArtclipAndTemplate(template);
                var haveCompressed = false;
                for (var i = 0; i < template.layers.length; i++) {
                    var layer = template.layers[i];
                    if (layer.compressed) {
                        haveCompressed = true;
                        break;
                    }
                }
                if (haveCompressed) {
                    MessageService.notice("PSD文件中有素材超过20MB, 已被压缩处理");
                }
                MessageService.success("PSD文件解析完成, 正在处理素材...");
            }, function (err) {
                errorPsdIndex++;
                upPsdNum++;
                file.status = 'error';
                if (upPsdNum == vm.psdFiles.length) {
                    vm.isCanUpload = true;
                }
            });
        }
        function processPsdToArtclipAndTemplate(psdTemplate) {
            var template = psdTemplate;
            var promise_list = [];
            // for PSD cover image
            promise_list.push(uploadPsdCoverImage(template));
            for (var i = 0; i < template.layers.length; i++) {
                var layer = template.layers[i];
                if (layer.type === 'Image' && layer.imageData) {
                    promise_list.push(updatePsdLayerImage(layer));
                    if (layer.hasMask && layer.mask) {
                        promise_list.push(uploadPsdLayerMask(layer.mask));
                    }
                } else if (layer.type === 'Text') {
                    layer.style.family = vm.defaultFont.family;
                }
            }

            // console.log(promise_list)
            // 等待上传封面图喝创建素材
            $q.all(promise_list).then(function () {
                console.log(template);
                var _data = {
                    cover: {
                        identifier: template.imageUrl,
                        provider: 'qiniu'
                    },
                    name: template.name,
                    data: JSON.stringify(template)
                }
                upPsdNum++;
                vm.templatePsdFiles.push(_data);
                if (!errorPsdIndex) {
                    // vm.psdUploadShow = false;
                }
                if (upPsdNum == vm.psdFiles.length) {
                    vm.isCanUpload = true;
                    vm.psdUploadShow = false;
                    $uibModalInstance.close(vm.templatePsdFiles);
                }
            });
        }
        function uploadPsdCoverImage(psdTemplate) {
            var template = psdTemplate;

            var deferred = $q.defer();

            var blob = dataURLtoBlob(template.imageData);

            var formData = new FormData();
            formData.append('file', blob);
            formData.append('key', 'thumbnail-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    template.imageKey = data.key;
                    template.imageUrl = vm.url + data.key;
                    template.imageData = null;

                    deferred.resolve(template);
                },
                error: function (request) {
                    console.log("error");
                    deferred.reject('err');
                }
            });

            return deferred.promise;
        }
        function updatePsdLayerImage(psdLayer) {
            var layer = psdLayer;

            var deferred = $q.defer();

            var blob = dataURLtoBlob(layer.imageData);
            var formData = new FormData();
            formData.append('file', blob);
            formData.append('key', 'clipArt-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    layer.imageKey = data.key;
                    layer.imageUrl = vm.url + data.key;
                    layer.imageData = null;

                    ClipArts.createOne({
                        identifier: data.key,
                        provider: 'qiniu',
                        themeClassifyId: ''
                    }, function (resp) {
                        vm.totalClipartItems = vm.totalClipartItems - 0 + 1;
                        deferred.resolve(layer);
                    }, function (err) {
                        deferred.reject('err');
                    });

                },
                error: function (request) {
                    console.log("error");
                    deferred.reject('err');
                }
            });

            return deferred.promise;
        }
        function uploadPsdLayerMask(layerMask) {
            var layer = layerMask;

            var deferred = $q.defer();

            var blob = dataURLtoBlob(layer.imageData);
            var formData = new FormData();
            formData.append('file', blob);
            formData.append('key', 'mask-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    layer.imageKey = data.key;
                    layer.imageUrl = vm.url + data.key;
                    layer.imageData = null;

                    var arr = {
                        resource: {
                            identifier: vm.url + data.key,
                            provider: 'qiniu'
                        },
                        identifier: vm.url + data.key,
                        provider: 'qiniu',
                        bgImg: '',
                        type: 'User',
                        isRecommend: false
                    }

                    SourceMaterial.createOne({
                        identifier: data.key,
                        provider: 'qiniu',
                        type: 'User'
                    }, function (resp) {
                        arr.id = resp.id;
                        vm.showMasks.mask.unshift(arr);
                        deferred.resolve(layer);
                    }, function (err) {
                        deferred.reject('err');
                    });

                },
                error: function (request) {
                    console.log("error");
                    deferred.reject('err');
                }
            });

            return deferred.promise;
        }
        $scope.deletePsd = function(index){
            vm.psdFiles.splice(index, 1);
        }
        vm.cancel = function () {
            if (!vm.isCanUpload) {
                MessageService.error("psd上传中");
                return
            }
            $uibModalInstance.dismiss();
        }
    }
})();

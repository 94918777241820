(function () {
    "use strict";

    angular
        .module("platformApp")
        .controller("RecommendTemplateController", RecommendTemplateController);

    RecommendTemplateController.$inject = [
        "$state",
        "$scope",
        "$timeout",
        "$uibModal",
        "$http",
        "Qinius",
        "$q",
        "paginationConstants",
        "$stateParams",
        "ProductTemplate",
        "ProductXml",
        "Catalog",
        "UidService",
        "MessageService"
    ];

    function RecommendTemplateController(
        $state,
        $scope,
        $timeout,
        $uibModal,
        $http,
        Qinius,
        $q,
        paginationConstants,
        $stateParams,
        ProductTemplate,
        ProductXml,
        Catalog,
        UidService,
        MessageService
    ) {
        var vm = this;
        vm.oldView = false;

        //分页信息
        vm.pageInfo = {
            page: 1,
            size: 20,
            total: 0
        };
        //搜索定时器
        vm.timer = null;
        //选中的产品分类索引
        vm.productSelected = null;
        //产品列表
        vm.productList = [
            { label: "全部", value: null },
            { label: "照片书", value: "BOOK" },
            { label: "冲印", value: "PHOTO" },
            { label: "台历", value: "CALENDAR" },
            { label: "万物", value: "THING" }
        ];
        $(".navbar").show();
        $(".wrap").css({ margin: "40px 0 10px 254px" });
        vm.domain = Qinius.getDomain();
        $q.all([vm.domain.$promise]).then(function () {
            vm.domainQ = vm.domain.domain;
            timeoutInit();
            vm.hotxmls = ProductXml.byCondition2({
                items: [
                    { key: "hotspot", op: "=", value: "true" },
                    { key: "status", op: "=", value: "Approved" }
                ]
            });
            vm.latexmls = ProductXml.byCondition({
                sort: ["createdDate,desc"]
            });
        });
        $scope.gotoCreate = function (item, custom) {
            console.log(item);
            // return
            if (custom) {
                createModal(
                    "app/entities/page/modal/template-add.html",
                    "TemplateAddController",
                    item,
                    custom
                ).result.then(function (data) {});
            } else {
                if (item.version == 1) {
                    MessageService.error("当前模板暂不能使用，请联系运营人员");
                    // MessageService.confirm({
                    //     title:'提示',
                    //     cancel:'取消',
                    //     confirm:'确认',
                    //     msg:'系统全面更新，模板需升级方可使用，确认升级?'
                    // },function(){
                    //     createModal('app/entities/page/modal/template-upgrade.html', 'TemplateUpgradeController',item, custom).result.then(
                    //         function (data) {
                    //             if(data){
                    //                 loadAll();
                    //                 saveTemplate(data)
                    //             }
                    //         });
                    // })
                } else {
                    saveTemplate(item);
                }
            }
        };
        function saveTemplate(data) {
            if(vm.isSaving){
                return
            }
            vm.isSaving = true;
            $scope.template = {
                "addpage":false,
                "addunit":0,
                "maxpage":0,
                "minpage":0,
                "replace":"",
                "cover":"",
                backupPages:{
                    list:[]
                },
                config:{
                    innerOffset:0,
                    parts:{
                        list:[]
                    },
                    productType:''
                },
                deletedPages:{
                    page:[]
                },
                "frameConfigs":{
                    "frameConfig":[]
                },
                pages: {
                    page: []
                },
                "version":"4.0",
                transparency: 1,
                scene: null,
                pageI: 0,
                angle: 0,
                backup: false,
                spread: false,
                unreplace: false,
                letterspacing: 0,
                lineheight: 0,
                text: '',
                bgimgs: {bgimg: []},
                bgimgs2: {bgimg: []},
                masks: {mask: []},
                cliparts: {clipart: []},
                documentpics: {documentpic: []},
                showPic: false,
                psdFiles: {psdFile: []}
            };
            var _seq = 0;
            data.parts.sort(compare('seq', true));
            data.parts.forEach(function (part,index) {
                var editboxes = {
                    editbox:[{
                        height: part.pageEditH,
                        width: part.pageEditW,
                        x: part.pageEditX,
                        y: part.pageEditY
                    }]
                };
                $scope.template.config.parts.list.push({
                    "bleed":{
                        "bottom":part.pageBloodB,
                        "left":part.pageBloodL,
                        "right":part.pageBloodR,
                        "top":part.pageBloodT
                    },
                    "editboxes":editboxes,
                    "mediabox":{
                        height: part.pageSizeH,
                        width: part.pageSizeW,
                    },
                    "counter":part.pageCount,
                    "id":part.code,
                    "name":part.name,
                    "required":(part.code == 'P' || part.code == 'P0') ? true : false,
                    "seq":index,
                    "spread":part.spread
                })
                var _page = createPage(data, part, editboxes);
                for(var i=0;i<part.pageCount; i++){
                    _page.uuid = UidService.get();
                    _page.seq = _seq;
                    if(data.type == 'BOOK'){
                        $scope.template.pages.page.push(angular.copy(_page));
                        _seq++
                    }
                    if(data.type == 'PHOTO'){
                        $scope.template.pages.page.push(angular.copy(_page));
                        _seq++
                    }
                    if(data.type == 'CALENDAR'){
                        $scope.template.pages.page.push(angular.copy(_page));
                        _seq++
                    }
                    if(data.type == 'THING'){
                        $scope.template.pages.page.push(angular.copy(_page));
                        _seq++;
                    }
                }
            })
            vm.hashtags = '';
            if(data.catalog.hashtags){
                for (var i = 0; i < data.catalog.hashtags.length; i++) {
                    vm.hashtags += data.catalog.hashtags[i].name + ','
                }
            }
            $scope.template.config.innerOffset = data.innerOffset;
            $scope.template.config.productType = data.type;
            $scope.template.config.dynamic = data.dynamic;
            if($scope.template.config.dynamic){
                $scope.template.config.startYear = (data.month.split("-"))[0];
                $scope.template.config.startMonth = (data.month.split("-"))[1];
            }
            $scope.template.cover = data.cover;
            $scope.template.replace = data.replace;
            $scope.template.addpage = data.addpage;
            $scope.template.minpage = data.minpage;
            $scope.template.maxpage = data.maxpage;
            $scope.template.addunit = data.addunit;
            ProductXml.save({
                document: $scope.template,
                replace: data.replace,
                name: data.name,
                catalogId: data.catalog.id,
                catalogName: data.catalog.name,
                version: '4.0',
                type: 'XML',
                productTemplateId: data.id,
                hashtags: vm.hashtags,
                modelBlendent: data.modelBlendent,
                modelData: data.modelData,
                modelPath: data.modelPath,
                modelType: data.modelType,
                productType: data.type
            }, function (res) {
                vm.isSaving = false;
                $state.go('page-my-suffix-edit', {id: res.id}, {reload: true})
            }, function () {
                vm.isSaving = false;
            });
        }
        function createPage(data, part, editboxes) {
            var _page = {
                config:{
                    "dynamic":(data.dynamic ? true : false),
                    "monthIndex":0,
                    "monthMultiple":false,
                    "partid":part.code
                },
                isScene: false,
                bgImg: '',
                background: {resource: {},color:'#ffffff', type: "Color"},
                pagecover: {},
                levels: {},
                barcode: {},
                qrcode: {},
                editbox: editboxes.editbox[0],
                editboxes: editboxes,
                mediabox: {
                    height: part.pageSizeH,
                    width: part.pageSizeW,
                },
                trimbox: {
                    height: part.pageSizeH - Number(part.pageBloodB) - Number(part.pageBloodT),
                    width: part.pageSizeW - Number(part.pageBloodL) - Number(part.pageBloodR),
                    x: part.pageBloodL,
                    y: part.pageBloodT
                },
                scale: 1,
                proportion: 1,
                seq: 0,
                spread: part.spread,
                backup: false,
                unreplace: !data.replace
            }
            if (part.mould) {
                if(part.mould.modelType == 'TWO' || part.mould.modelType == 'NONE'){
                    part.mould.modelType = 'TWO';
                    _page.scene = {
                        background: {
                            resource: {
                                identifier: part.mould.modelPic.indexOf('http') > -1 ? part.mould.modelPic : vm.domainQ + part.mould.modelPic,
                                provider: "qiniu"
                            }
                        },
                        geometry: {
                            width: part.mould.gWidth,
                            height: part.mould.gHeight,
                            x: 0,
                            y: 0
                        },
                        imagebox: {
                            geometry: {
                                width: part.mould.width,
                                height: part.mould.height,
                                x: part.mould.x,
                                y: part.mould.y
                            },
                            rotation: {angle: 0},
                            transparency: 1,
                        },
                        hierarchy: part.mould.hierarchy,
                        mouldid: part.mould.id,
                        isScene: true,
                        scale: 1,
                        unit: "mm",
                        resource:{
                            identifier: part.mould.modelPic.indexOf('http') > -1 ? part.mould.modelPic : vm.domainQ + part.mould.modelPic,
                            provider: "qiniu"
                        }
                    }
                }
            }
            return _page;
        }
        $scope.gotoCreate1 = function (item, custom) {
            if (custom) {
                $uibModal
                    .open({
                        templateUrl:
                            "app/entities/page/modal/template-modal.html",
                        controller: "TemplateModalController",
                        controllerAs: "vm",
                        backdrop: "static",
                        resolve: {
                            entity: [
                                function () {
                                    return {
                                        type: "add",
                                        data: ""
                                    };
                                }
                            ]
                        }
                    })
                    .result.then(function (data) {
                        if (data) {
                        }
                    });
            } else {
                var _item = item;
                for (var j = _item.pageTemplates.length - 1; j >= 0; j--) {
                    if (!_item.pageTemplates[j].pageEdits) {
                        _item.pageTemplates[j].pageEdits = [
                            {
                                pageEditX: parseInt(
                                    _item.pageTemplates[j].pageEditX
                                ),
                                pageEditY: parseInt(
                                    _item.pageTemplates[j].pageEditY
                                ),
                                pageEditW: parseInt(
                                    _item.pageTemplates[j].pageEditW
                                ),
                                pageEditH: parseInt(
                                    _item.pageTemplates[j].pageEditH
                                )
                            }
                        ];
                    }
                    if (_item.pageTemplates[j].cancelled) {
                        _item.pageTemplates.splice(j, 1);
                    }
                }

                _item.pageSizeH = parseInt(_item.pageSizeH) - 0;
                _item.pageSizeW = parseInt(_item.pageSizeW) - 0;
                _item.pageBloodT = parseInt(_item.pageBloodT) - 0;
                _item.pageBloodB = parseInt(_item.pageBloodB) - 0;
                _item.pageBloodL = parseInt(_item.pageBloodL) - 0;
                _item.pageBloodR = parseInt(_item.pageBloodR) - 0;
                _item.pageEdits = parseInt(_item.pageEdits) - 0;
                if (!_item.pageEdits) {
                    _item.pageEdits = [
                        {
                            pageEditX: parseInt(_item.pageEditX),
                            pageEditY: parseInt(_item.pageEditY),
                            pageEditW: parseInt(_item.pageEditW),
                            pageEditH: parseInt(_item.pageEditH)
                        }
                    ];
                }
                _item.custom = vm.type;
                localStorage.setItem("page-new", JSON.stringify(_item));
                $state.go("page-my-suffix", { reload: true });
            }
        };

        $scope.productType = function (item) {
            switch (item) {
                case "BOOK":
                    return "照片书";
                case "PHOTO":
                    return "冲印";
                case "CALENDAR":
                    return "台历";
                case "THING":
                    return "万物";
                default:
                    return "";
            }
        };
        var createModal = function (url, controller, item, custom) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: "static",
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: "vm",
                resolve: {
                    items: function () {
                        return {
                            type: "add",
                            data: item,
                            custom: custom
                        };
                    },
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                )
                            };
                        }
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("page");
                            return $translate.refresh();
                        }
                    ]
                }
            });
        };

        //    分类显示
        vm.userDefinedPage = 1;
        vm.userDefinedSize = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.jump = jump;
        vm.pageCb = pageCb;
        vm.clear = clear;
        function jump() {
            vm.userDefinedPage = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadAll();
        }
        function pageCb() {
            loadAll();
        }
        function clear() {
            vm.searchQuery = "";
            loadAll();
        }
        //产品选择
        $scope.selectProduct = function (item) {
            if (vm.productSelected === item.value) return;
            vm.productSelected = item.value;
            vm.pageInfo.page = 1;
            loadAll();
        };
        //当前页变更
        vm.currentChange = function () {
            $timeout(function () {
                loadAll();
            });
        };
        function loadAll() {
            var items = [
                { key: "status", op: "=", value: true },
                { key: "cancelled", op: "=", value: "false" }
            ];
            if (vm.catalogView) {
                items.push({
                    key: "catalog.id",
                    op: "=",
                    value: vm.catalogView.id
                });
            }
            if (vm.searchQuery) {
                items.push({
                    key: "name",
                    op: "like",
                    value: vm.searchQuery
                });
            }
            if (vm.productSelected) {
                items.push({
                    key: "type",
                    op: "=",
                    value: vm.productSelected
                });
            }

            ProductTemplate.byCondition(
                {
                    items: items,
                    page: vm.pageInfo.page - 1,
                    size: vm.pageInfo.size,
                    sort: ["id,desc"]
                },
                function (data, headers) {
                    vm.pageInfo.total = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.userDefined = data;
                }
            );
        }
        vm.catalogView = $stateParams.catalogId ? {id:$stateParams.catalogId} : "";

        function timeoutInit() {
            Catalog.getAll3(
                {
                    items: [{ key: "usable", op: "=", value: "true" }],
                    sort: ["id,desc"]
                },
                function (res) {
                    vm.catalogs = res;
                    loadAll();
                }
            );
        }
        $scope.catalogCb = function (item) {
            if (item == "all") {
                vm.catalogView = "";
            } else {
                vm.catalogView = item;
            }
            vm.pageInfo.page = 1;
            loadAll();
        };

        function search() {
            //回车
            if(event.keyCode === 13){
                vm.pageInfo.page = 1;
                loadAll();
            }
        }
        $scope.operateLi = function (tempItem) {
            tempVersion(tempItem, function (temp) {
                $state.go(
                    "page-my-suffix-edit",
                    { id: temp.id },
                    { reload: true }
                );
            });
        };
        $scope.look = function (e) {
            if(!e.cover) return MessageService.error("暂无预览图");
            $uibModal.open({
                templateUrl: "app/entities/page/modal/recommend-look.html",
                controller: "RecommendLookController",
                controllerAs: "vm",
                backdrop: "static",
                resolve: {
                    entity: [
                        function () {
                            return {
                                url: vm.domainQ + e.cover
                            };
                        },
                    ],
                },
            });
        }
        function tempVersion(temp, callback) {
            if (temp.version != "4.0") {
                MessageService.confirm(
                    {
                        title: "提示",
                        cancel: "取消",
                        confirm: "确认",
                        msg: "系统全面更新，模板需升级方可使用，确认升级?",
                    },
                    function () {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/page/modal/template-upgrade.html",
                                controller: "TemplateUpgradeController",
                                backdrop: "static",
                                keyboard: true,
                                windowClass: "jh-modal-md",
                                controllerAs: "vm",
                                size: "lg",
                                resolve: {
                                    entity: function () {
                                        return temp;
                                    },
                                    pagingParams: [
                                        "$stateParams",
                                        "PaginationUtil",
                                        function (
                                            $stateParams,
                                            PaginationUtil
                                        ) {
                                            return {
                                                page: PaginationUtil.parsePage(
                                                    $stateParams.page
                                                )
                                            };
                                        }
                                    ],
                                    translatePartialLoader: [
                                        "$translate",
                                        "$translatePartialLoader",
                                        function (
                                            $translate,
                                            $translatePartialLoader
                                        ) {
                                            $translatePartialLoader.addPart(
                                                "page"
                                            );
                                            return $translate.refresh();
                                        }
                                    ]
                                }
                            })
                            .result.then(function (data) {
                                if (data) {
                                    ProductXml.get(
                                        { id: temp.id },
                                        function (res) {
                                            callback(res);
                                        }
                                    );
                                }
                            });
                    }
                );
            } else {
                callback(temp);
            }
        }
    }
})();

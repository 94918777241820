(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('TemplateController', TemplateController);

    TemplateController.$inject = ['$state', '$scope', '$timeout', '$uibModal', '$http', 'Qinius','$q', 'pagingParams', 'paginationConstants', '$stateParams','ProductTemplate'];

    function TemplateController($state, $scope, $timeout, $uibModal, $http, Qinius,$q, pagingParams, paginationConstants, $stateParams,ProductTemplate) {

        var vm = this;
        $(".navbar").show();
        $(".wrap").css({margin: '40px 0 10px 254px'});

        $scope.gotoCreate = function (item) {
            createModal('app/entities/page/page-create.html', 'PageCreateController',item).result.then(
                function (data) {
                });
        };

        var createModal = function (url, controller,item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    items: function(){
                        return item
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            });
        };

        vm.domain = Qinius.getDomain();
        $q.all([vm.domain.$promise]).then(function(){
            vm.domainQ  =vm.domain.domain
        });

        //    分类显示
        loadAll();
        function loadAll () {
            ProductTemplate.getAllByCondition({items:[]}, function (data) {
                vm.userDefined = data;
            })
        }

    }

})();

(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PageSimpleController', PageSimpleController);

    PageSimpleController.$inject = ['pages', '$rootScope', '$scope', '$state', '$uibModalInstance', '$q', 'documentpics', 'MessageService', 'UidService','ListService'];

    function PageSimpleController(pages, $rootScope, $scope, $state, $uibModalInstance, $q, documentpics, MessageService, UidService,ListService) {
        var vm = this;
        vm.documentpics = {
            documentpic:angular.copy(documentpics.arr)
        }
        vm.clear = clear;
        vm.save = save;
        vm.token = pages.token;
        $scope.domain = pages.domain;
        var pageCover = [];

        angular.forEach(pages.template.pages.page,function (p) {
            if(!p.backup&&p.pagecover&&p.pagecover.identifier){
                var res = p.pagecover.identifier.split($scope.domain).join("");
                pageCover.push({
                    name:res,
                    resource:{
                        identifier:res,
                        provider:"qiniu",
                    },
                    added: true,
                    uuid: UidService.get()
                })
            }

        })

        $scope.deletePic = function(index){
            vm.documentpics.documentpic.splice(index, 1);
        };

        function qiniuUpload(files) {
            var result = [];
            for (var i = files.length - 1; i >= 0; i--) {
                var file = files[i];
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'file' + file.name + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: true,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        result.push({ key:data.key})
                        if (result.length == files.length) {
                            result.sort(function(a, b){
                                return a.key.localeCompare(b.key);
                            })
                            if (vm.documentpics.documentpic == null) {
                                vm.documentpics.documentpic = [];
                            }
                            for (var j = 0 ; j< result.length ; j++) {
                                var seq = vm.documentpics.documentpic.length+1;
                                var r = result[j];
                                vm.documentpics.documentpic.push({
                                    name:r.key,
                                    seq:seq,
                                    resource:{
                                        identifier: $scope.domain + r.key,
                                        provider:"qiniu",
                                    },
                                    added: true
                                });
                            }
                            $scope.$apply();
                        }
                    },
                    error: function (request) {
                        MessageService.error("图片上传失败");
                    }
                });
            }

        }

        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files !=null && files.length >0) {
                // qiniuUpload(files);
                var isSort = true;
                var arr = [];
                for (var i = 0; i< files.length  ; i++) {
                    var f = files[i];
                    arr.push(files[i])
                    var name = f.name.substring(0,f.name.indexOf('.'));
                    if (/^[\d\-]+$/.test(name)) {
                        files[i].name1 = name.replace('-','')
                    }else{
                        isSort = false;
                    }
                }
                if (isSort) {
                    arr.sort(function(a,b){
                        return a.name1 - b.name1
                    })
                }
                qiniuUpload(arr);
            }
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('editorApp:productXmlUpdate', function(event, result) {
            vm.productXml = result;
        });

        $scope.$on('$destroy', unsubscribe);

        function save() {

            vm.isSaving = false;
            $uibModalInstance.close(vm.documentpics);
        }

        $scope.generCover = function () {
            angular.forEach(pageCover,function (cover) {
                var result = ListService.inList(cover, vm.documentpics.documentpic, ["uuid"]);
                if(result){
                    cover.isAdd = true;
                }else{
                    cover.isAdd = false;
                }
            })
            console.log(pageCover)
            angular.forEach(pageCover,function (cover) {
                if(!cover.isAdd){
                    cover.seq = vm.documentpics.documentpic.length+1;
                    if(cover.name){
                        cover.name = cover.name.substring(cover.name.lastIndexOf("/")+1);
                    }
                    if(cover.resource && cover.resource.identifier){
                        cover.resource.identifier = $scope.domain + cover.resource.identifier.substring(cover.resource.identifier.lastIndexOf("/")+1);
                    }
                    vm.documentpics.documentpic.push(cover)
                }

            })
            console.log(vm.documentpics)
        }
    }
})();

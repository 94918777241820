(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ClipArtDetailController', ClipArtDetailController);

    ClipArtDetailController.$inject = ['$timeout', "$q", '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ClipArts',
            'MessageService','ThemeClassify','$http','$uibModal'];

    function ClipArtDetailController ($timeout, $q, $scope, $stateParams, $uibModalInstance, entity, ClipArts,
        MessageService,ThemeClassify, $http,$uibModal) {

        var vm = this;
        vm.data = entity;
        vm.save = save;
        vm.themeClassifies = ThemeClassify.getAllByCondition1({items:[{key:"type",value:"CLIPART", op:"="},{key:"used",value:"true", op:"="}]});

        $scope.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }
        function save (){
            if(!vm.data.themeClassifyId) return MessageService.error("请选择标签");
            ClipArts.update(vm.data, function(res){
                MessageService.success("保存成功");
                $uibModalInstance.close(res);
            }, function(ers){
                MessageService.error("保存失败,请稍后再试");
                $uibModalInstance.dismiss('cancel');
            })
        }

    }
})();

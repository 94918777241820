(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarTitleDataController', CalendarTitleDataController);

    CalendarTitleDataController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService','CalendarLayout'];

    function CalendarTitleDataController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService,CalendarLayout) {
        var vm = this;
        vm.save = save;
        vm.cancel = cancel;
        vm.layouts = [];
        vm.data = entity || {};
        CalendarLayout.getLayouts({type: 'TITLE',available: true}, function (res) {
            if (res.status == 200 && res.message && res.message.length) {
                vm.layouts = res.message;
            }
        }, function (res) {
            MessageService.error("加载失败");
        });
        vm.onLayoutSelect = function (item) {
            vm.data = item;
        }
        //返回
        function cancel() {
            $uibModalInstance.close();
        }
        function save() {
            if(!vm.data.id){
                MessageService.error("请选择默认标题组件");
                return
            }
            $uibModalInstance.close(vm.data);
        }
    }
})();

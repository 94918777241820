(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('text', {
            parent: 'app',
            url: '/text',
            data: {
                authorities: [],
                pageTitle: 'platformAppTitle.text'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/text/text.html',
                    controller: 'TextController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fontManagement');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('title');
                    return $translate.refresh();
                }]
            }
        })
        .state('text-xml', {
            url: '/text-xml',
            data: {
                authorities: [],
                pageTitle: 'platformApp.productXml.home.title'
            },
            views: {
                'navbar@': {
                    templateUrl: 'app/entities/text/text-xml-edit.html',
                    controller: 'TextXmlEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('text-xml-edit', {
            url: '/text-xml-edit/{id}',
            data: {
                authorities: [],
                pageTitle: 'platformAppTitle.text-xml-edit'
            },
            views: {
                'navbar@': {
                    templateUrl: 'app/entities/text/text-xml-edit.html',
                    controller: 'TextXmlEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('title');
                    return $translate.refresh();
                }]
            }
        })
            .state('textList', {
                parent: 'page',
                url: '/textList?page&sort&search',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.productXml.home.title'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/text/text-list.html',
                        controller: 'TextListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fontManagement');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('textShare', {
                parent: 'app1',
                url: '/textShare?page&sort&search',
                data: {
                    authorities: [],
                    pageTitle: 'platformAppTitle.textShare'
                },
                views: {
                    'content1': {
                        templateUrl: 'app/entities/text/text-share.html',
                        controller: 'TextShareController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fontManagement');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('title');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();

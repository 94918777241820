(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('WidgetSelectController', WidgetSelectController);

    WidgetSelectController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'items', '$state', 'MessageService', 'Catalog','Qinius', '$q', 'UidService',
        '$uibModal','TitleWidget','MonthWidget','ProductXml'];

    function WidgetSelectController ($timeout, $scope, $stateParams, $uibModalInstance, items, $state, MessageService, Catalog,Qinius, $q, UidService,
                                    $uibModal,TitleWidget,MonthWidget,ProductXml) {
        var vm = this;

        vm.type = items.type;
        vm.list = [];
        vm.selected = null;
        vm.searchQuery = "";
        vm.getTitle = function (){
            if(vm.type === "titleWidget"){
                return "选择标题组件";
            }
            if(vm.type === "monthWidget"){
                return "选择布局组件";
            }
            return '';
        }
        getSelectList();
        //获取列表
         function getSelectList(){
            var items = [
                {key:"enabled", op:"=", value:"true"}
            ];
            if(vm.searchQuery){
                items.push({key: "name", value: vm.searchQuery, op: "like"});
            }
            if(vm.type === "titleWidget"){
                TitleWidget.getAllUsable({name:vm.searchQuery}, function (res) {
                    vm.list = res;
                });
            }
            if(vm.type === "monthWidget"){
                MonthWidget.getAllUsable({name:vm.searchQuery}, function (res) {
                    vm.list = res;
                });
            }
        }
        //搜索
        $scope.search = function (){
            getSelectList();
        }
        //取消
        $scope.cancel = function (){
            $uibModalInstance.close();
        }
        //确定
        $scope.confirm = function(){
            if(!vm.selected){
                return MessageService.error("请选择组件");
            }
            var Item = vm.list.find(function(item){
                return item.id === vm.selected;
            })
            $uibModalInstance.close(Item);
        }
        //选择
        $scope.selectItem = function(e){
            vm.selected = e;
        }


    }
})();

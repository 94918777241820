(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'MessageService', '$state', 'Auth', '$rootScope',
    '$localStorage'];

    function HomeController ($scope, Principal, MessageService, $state, Auth, $rootScope,
        $localStorage) {
        var vm = this;
        vm.passType = 'password';
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = login;
        vm.register = register;
        vm.rememberMe = true;
        vm.changePassType = changePassType;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        $(".navbar").hide();
        $(".wrap").css({margin:0});
        Auth.logout();
        getAccount();

        //修改密码框类型
        function changePassType(type){
            vm.passType = type;
        }
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function login(event) {
            if (vm.username == null || vm.username == '' || vm.password == null || vm.password == '') {
                MessageService.error("请输入用户名和密码");
                return;
            }
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                $(".navbar").show();
                vm.account = $localStorage.user;
                $(".users-a").html(vm.account.name)
                $state.go('page');
                $(".wrap").css({margin: '48px 0 10px 254px'});
                delete $localStorage.menu1;
                delete $localStorage.menu2;
                delete $localStorage.pageName;
                $rootScope.$broadcast('authenticationSuccess');
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function (error) {
                if (error && error.data && error.data["com.boostech.westeros.security.UserNotActivatedException"]) {
                    MessageService.error("用户未激活");
                }else{
                    MessageService.error("用户名或密码不正确");
                }
                vm.authenticationError = true;
            });


        }

        function register () {
            $state.go('register');
        }
    }
})();

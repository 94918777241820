(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('GetSetDpi', GetSetDpi)
        .factory('GetSetDpi2', GetSetDpi2);

    GetSetDpi.$inject = ['$resource'];
    GetSetDpi2.$inject = ['$resource'];

    function GetSetDpi($resource) {
        var resourceUrl = 'bunny/' + 'api/precision-settings/:path/:id';

        return $resource(resourceUrl, {}, {

            "getCurrent": {
                method: 'GET',
                params: {
                    path: "getCurrent"
                },
            },
            "submitDpi": {
                method: 'PUT',
            },
        });
    }

    function GetSetDpi2($resource) {
        var resourceUrl = 'bunny/' + 'api/precision-items/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition": {
                method: 'POST',
                params: {
                    path: "getAllByCondition"
                },
            },
        });
    }
})();
(function() {
	'use strict';
	angular
		.module('platformApp')
		.factory('ListService', ListService);

	ListService.$inject = [];

	function ListService() {
		var service = {
			inList: inList,
			inList2 :inList2,
			removeEmptyInList: removeEmptyInList
		};
		return service;

		function inList2(value, list, valueAttr , listAttr) {
			if (list) {
				for (var i = list.length - 1; i >= 0; i--) {
					var v = list[i];
					if (valueAttr && listAttr) {
						if (v && value[valueAttr] == v[listAttr]) {
							return v;
						};
					} else {
						if (v && v.id == value.id) {
							return v;
						}
					}
				}
			}
		}

		function inList(value, list, attrList) {
			if (list) {
				for (var i = list.length - 1; i >= 0; i--) {
					var v = list[i];
					if (attrList) {
						if (v) {
							var match = 0;
							for (var j = 0; attrList && j < attrList.length; j++) {
								var attr = attrList[j];
								if (attr) {
									if (v[attr] == value[attr]) {
										match++;
									} else {
										break;
									}
								}
							}
							if (match == attrList.length) {
								return v;
							}
						}
					} else {
						if (v && v.id == value.id) {
							return v;
						}
					}
				}
			}
		}

		function removeEmptyInList(list, key) {
			if (!list) return;
			for (var i = 0; i < list.length; i++) {
				var item = list[i];
				if (item && !item[key]) {
					list.splice(i, 1);
					i--;
				}
			}
		}
	}



})();

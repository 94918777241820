(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('BackgroundAddController', BackgroundAddController);

    BackgroundAddController.$inject = ['$timeout', '$scope', '$stateParams','Qinius','$uibModal', '$uibModalInstance', 'entity', '$state', 'MessageService','ThemeClassify','SourceMaterial','UidService','ClipArts','Backgrounds'];

    function BackgroundAddController ($timeout, $scope, $stateParams,Qinius,$uibModal, $uibModalInstance, entity, $state, MessageService,ThemeClassify,SourceMaterial,UidService,ClipArts,Backgrounds) {
        var vm = this;
        //上传的文件列表
        vm.fileList = [];
        //关闭弹窗
        vm.cancel = function () {
            $uibModalInstance.close();
        }
        //标签
        vm.themeClassifyId = null;
        vm.themeClassifies = ThemeClassify.getAllByCondition1({items:[{key:"type",value:"BACKGROUND", op:"="},{key:"used",value:"true", op:"="}]});
        vm.tokenTime = 360000;
        Qinius.get(function (msg) {
            vm.token = msg.token;
            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        });
        vm.domain = Qinius.getDomain();
        var bigSizePics = [];
        vm.fileInfo = null;
        window.updateBackground = function ($event) {
            $scope.$digest();
            var files = $event.target.files;
            if(files.length > 50 || files.length + vm.fileList.length > 50){
                return MessageService.error("一次上传不能超过50张背景");
            }
            bigSizePics = [];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.size >= 20 * 1024 * 1024) {
                    file.imageSize = Math.ceil(file.size / 1024 / 1024);
                    bigSizePics.push(file);
                } else {
                    file.key = 'clipArt-' + UidService.get();
                    file.loadState = 0;// 0：等待上传 1：上传中，2：上传成功 3:上传失败
                    vm.fileList.push(file);
                }
            }
            getBackground();
            if (bigSizePics.length > 0) {
                thumbnailatorPic();
            }
            $event.srcElement.value = "";
            $scope.$apply();
        };
        //删除图片
        $scope.deleteItem = function (index) {
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除此图片？'
            },function(){
                vm.fileList.splice(index,1);
            },function(){});

        }
        function thumbnailatorPic() {
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics = [];
                if (values) {
                    for (var i = 0; i < values.length; i++) {
                        values[i].key = 'clipArt-' +  UidService.get();
                        values[i].loadState = 0;// 0：等待上传 1：上传中，2：上传成功 3:上传失败
                        vm.fileList.push(values[i]);
                    }
                    getBackground();
                }
            }, function () {
            });
        }
        var upladingNum = 0;
        function getBackground() {
            angular.forEach(vm.fileList,function(item){
                if(upladingNum >= 5) return;
                if(item.loadState === 0){
                    upladingNum++;
                    item.loadState = 1;
                    var url = window.URL || window.webkitURL;
                    var img = new Image();              //手动创建一个Image对象
                    img.src = url.createObjectURL(item);//创建Image的对象的url
                    img.crossOrigin = "Anonymous";
                    var fileName = item.name;
                    var formData = new FormData();
                    formData.append('file', item);
                    formData.append('key', item.key);
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: true,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            item.loadState = 2;
                            item.fileName = fileName;
                            item.img = vm.domain.domain + data.key + '?imageView2';
                            item.preview = vm.domain.domain + data.key + '?imageMogr2/interlace/1/thumbnail/192x192';
                            upladingNum--;
                            if(upladingNum <=0){
                                getBackground();
                            }
                            $scope.$apply();
                        },
                        error: function (request) {
                            item.loadState = 3;
                            upladingNum--;
                            if(upladingNum <=0){
                                getBackground();
                            }
                            var reader = new FileReader();
                            reader.readAsDataURL(item);
                            reader.onload = function(e){
                                item.preview = e.target.result
                                $scope.$apply();
                            }

                        }
                    });
                }
            });

        }
        vm.subLoading = false;
        vm.subAdd = function (){
            if(vm.subLoading) return;
            if(!vm.themeClassifyId) return MessageService.error("请选择标签");
            var flag = vm.fileList.find(function(item){
                return item.loadState !== 2;
            })
            if(flag) return MessageService.error("图片正在上传或上传失败，请确认后重试");
            vm.subLoading = true;
            var data = [];
            angular.forEach(vm.fileList,function (item) {
                var obj = {
                    name: item.name,
                    identifier:item.key,
                    provider: 'qiniu',
                    roleType: 'USER',
                    themeClassifyId: vm.themeClassifyId
                }
                data.push(obj)
            })
            Backgrounds.batchList(data, function (resp) {
                vm.subLoading = false;
                MessageService.success("添加成功");
                $uibModalInstance.close(true);
            },function () {
                vm.subLoading = false;
            });
        }

    }
})();

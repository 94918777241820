(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarAddController', CalendarAddController);

    CalendarAddController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService','CalendarLayout','MonthWidget','FontManagement'];

    function CalendarAddController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService,CalendarLayout,MonthWidget,FontManagement) {
        var vm = this;
        vm.save = save;
        vm.cancel = cancel;
        vm.types = [
            { name: '一行7列', id: 'C7'},
            { name: '一行14列', id: 'C14'},
            { name: '一行21列', id: 'C21'},
        ];
        vm.isCopy = false;
        if(entity){
            vm.data = entity;
            vm.isCopy = true;
        }else{
            var date = new Date();
            vm.data = {
                name: "",
                sampleYear: date.getFullYear(),
                sampleMonth: date.getMonth() + 1,
                weekLayoutId: null,
                dateLayoutId: null,
                displayType: 'C7',
            };
        }
        CalendarLayout.getLayouts({type: 'WEEK'}, function (res) {
            if (res.status == 200 && res.message && res.message.length) {
                vm.data.weekLayout = res.message[0];
                vm.data.weekLayoutId = res.message[0].id;
                onLayoutChange()
            }
        }, function (res) {
            MessageService.error("加载失败");
        });
        CalendarLayout.getLayouts({type: 'DATE'}, function (res) {
            if (res.status == 200 && res.message && res.message.length) {
                vm.data.dateLayout = res.message[0];
                vm.data.dateLayoutId = res.message[0].id;
                onLayoutChange()
            }
        }, function (res) {
            MessageService.error("加载失败");
        });
        function onLayoutChange() {
            var wc = vm.data.weekConfig || {};
            var dc = vm.data.dateConfig || {};
            var headWidth = (Number.parseInt(wc.cellWidth || 40) + Number.parseInt(wc.margin || 0)) * displayType(vm.data.displayType);
            var bodyWidth = (Number.parseInt(dc.cellWidth || 40) + Number.parseInt(dc.margin || 0)) * displayType(vm.data.displayType);

            var tableHeight = Number.parseInt(wc.cellHeight ||20) + (Number.parseInt(dc.cellHeight || 40) + Number.parseInt(dc.margin || 0)) * 5;

            var width = Math.max(headWidth, bodyWidth);

            vm.data.width = width;
            vm.data.height = tableHeight;
            vm.height = vm.data.height * vm.width / vm.data.width;
        }
        $scope.displayTypeUpdate = onLayoutChange;

        FontManagement.getAll2({items: [{key: "defaultShow", op: "=", value: true}], sort: ["id,asc"]}, function (res) {
            vm.data.defaultFontUuid = res[0].uuid;
        });
        //返回
        function cancel() {
            $uibModalInstance.close();
        }
        function save() {
            if(!vm.data.name){
                MessageService.error("日历组件名称不能为空");
                return
            }
            if (!vm.data.weekLayoutId) {
                MessageService.error("数据错误");
                return;
            }
            if (!vm.data.dateLayoutId) {
                MessageService.error("数据错误");
                return;
            }
            vm.isSaving = true;
            if (vm.isCopy) {
                MonthWidget.copy({id:vm.data.id, name:vm.data.name, displayType:vm.data.displayType}, function (res) {
                    if (res && res.status == "200") {
                        $state.go('calendar-xml',{id:res.message.id});
                        $uibModalInstance.close(res);
                    } else {
                        MessageService.error("复制失败");
                    }
                    vm.isSaving = false;
                }, function (res) {
                    vm.isSaving = false;
                    MessageService.error(res.data.message || "复制失败")
                });
            }else{
                MonthWidget.create(vm.data, function (res) {
                    if (res && res.status == "200") {
                        $state.go('calendar-xml',{id:res.message.id});
                        $uibModalInstance.close(res);
                    } else {
                        MessageService.error("创建失败");
                    }
                    vm.isSaving = false;
                }, function (res) {
                    vm.isSaving = false;
                    MessageService.error(res.data.message || "提交失败")
                });
            }
        }
    }
})();

(function () {
    "use strict";
    var localLoading = {
        restrict: "EAC",
        replace: true,
        transclude: true,
        templateUrl: "app/entities/components/local-loading.html",
        controllerAs: "vm",
        scope: {},
        bindings: {
            title: "@",
            show: "<"
        },
        controller: [
            "$scope",
            "$timeout",
            function ($scope, $timeout) {
                var vm = this;
                console.log(vm);
            }
        ]
    };
    angular.module("platformApp").component("localLoading", localLoading);
})();

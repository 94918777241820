(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$scope',
    '$localStorage', '$uibModal','Catalog', "Notice", '$rootScope'];

    function NavbarController($state, Auth, Principal, ProfileService, LoginService, $scope,
     $localStorage, $uibModal,Catalog, Notice, $rootScope) {
        var vm = this;
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.menus=[];
        vm.menusSelf=[];
        vm.account = $localStorage.user;

        vm.current = $state.current;
        $rootScope.$on('$stateChangeStart',
            function(event, toState, toParams, fromState, fromParams) {
                vm.current = toState;
                console.log(toState);
            })
    }
})();

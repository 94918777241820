(function () {
    "use strict";

    angular
        .module("platformApp")
        .controller("PicturePreviewController", PicturePreviewController);

    PicturePreviewController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$uibModalInstance",
        "entity",
        "$state",
        "MessageService",
        "Catalog",
    ];

    function PicturePreviewController(
        $timeout,
        $scope,
        $stateParams,
        $uibModalInstance,
        entity,
        $state,
        MessageService,
        Catalog
    ) {
        var vm = this;
        vm.cover = entity.cover;
        //返回
        vm.cancel = function() {
            $uibModalInstance.close(false);
        };
    }
})();

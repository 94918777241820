(function () {
    "use strict";

    angular
        .module("platformApp")
        .controller("XmlCenterController", XmlCenterController)
        .directive("errSrc", function () {
            return {
                link: function (scope, element, attrs) {
                    element.bind("error", function () {
                        if (attrs.src != attrs.errSrc) {
                            attrs.$set("src", attrs.errSrc);
                        }
                    });
                }
            };
        });

    XmlCenterController.$inject = [
        "$state",
        "$scope",
        "Qinius",
        "$uibModal",
        "$http",
        "$q",
        "pagingParams",
        "paginationConstants",
        "$timeout",
        "$stateParams",
        "MessageService",
        "ProductXml",
        "Catalog",
        "Hashtag",
        "ParseLinks",
        "$localStorage",
        "Account",
        "ProductXmlPurchase",
        "$window"
    ];

    function XmlCenterController(
        $state,
        $scope,
        Qinius,
        $uibModal,
        $http,
        $q,
        pagingParams,
        paginationConstants,
        $timeout,
        $stateParams,
        MessageService,
        ProductXml,
        Catalog,
        Hashtag,
        ParseLinks,
        $localStorage,
        Account,
        ProductXmlPurchase,
        $window
    ) {
        var vm = this;
        $(".sidebar1").hide();
        $(".navbar").show();
        $(".nav-bg").show();
        $(".wrap").css({ margin: 0 });
        //分类
        vm.catalogList = [
            { id: null, name: "全部", active: true },
            { id: null, name: "热门模板", active: false }
        ];
        //模板
        vm.hashtagList = [{ id: null, name: "全部", active: true }];
        //选中的产品分类索引
        vm.productActive = 0;
        //产品列表
        vm.productList = [
            { label: "全部", value: null },
            { label: "照片书", value: "BOOK" },
            { label: "冲印", value: "PHOTO" },
            { label: "台历", value: "CALENDAR" },
            { label: "万物", value: "THING" }
        ];
        //排序
        vm.sortList = [
            { label: "最新上传", value: "shareDate" },
            { label: "最多使用", value: "usedCount" }
        ];
        //价格筛选列
        vm.priceList = [
            { label: "全部", value: null },
            { label: "免费", value: "=" },
            { label: "付费", value: ">" }
        ];
        //搜索框输入定时器
        vm.timer = null;
        //标签筛选项是否展开
        vm.tagScreenIsOpen = false;
        //分页信息
        vm.pageInfo = {
            page: 1,
            size: 20,
            total: 0
        };
        vm.user = $localStorage.user;
        if (vm.user == null) {
            Account.get(
                {},
                function (data) {
                    if (data.status == 200) {
                        vm.user = data.data;
                    }
                },
                function () {}
            );
        }

        vm.search = search;
        vm.loadPage = loadPage;
        vm.predicate = "shareDate";
        vm.reverse = false;
        vm.transition = transition;
        vm.searchQuery = "";
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        $scope.catalogSelected = null;
        $scope.tagSelected = null;
        $scope.productSelected = null;
        $scope.priceSelected = null;
        //分类选择
        $scope.selectCatalog = function (catalog) {
            if (catalog.active) return;
            vm.pageInfo.page = 1;
            angular.forEach(vm.catalogList, function (item) {
                item.active = false;
                if (catalog.name === item.name) {
                    item.active = true;
                    if (catalog.name === "全部") {
                        $scope.catalogSelected = null;
                    } else if (catalog.name === "热门模板") {
                        $scope.catalogSelected = catalog.name;
                    } else {
                        $scope.catalogSelected = catalog;
                    }
                }
            });
            $scope.getXmlByCondition();
        };
        //标签选择
        $scope.selectTag = function (tag) {
            if (tag.active) return;
            vm.pageInfo.page = 1;
            angular.forEach(vm.hashtagList, function (item) {
                item.active = false;
                if (item.name === tag.name) {
                    item.active = true;
                    if (item.name === "全部") {
                        $scope.tagSelected = item.name;
                    } else {
                        $scope.tagSelected = tag;
                    }
                }
            });
            $scope.getXmlByCondition();
        };
        //产品选择
        $scope.selectProduct = function (index) {
            if (vm.productActive === index) return;
            vm.productActive = index;
            $scope.productSelected = vm.productList[index].value;
            vm.pageInfo.page = 1;
            $scope.getXmlByCondition();
        };
        //获取产品名称
        $scope.getProductName = function (type) {
            if (!type) return;
            var productItem = vm.productList.find(function (item) {
                return item.value === type;
            });
            if (productItem) {
                return productItem.label;
            }
            return "";
        };
        //排序选择
        $scope.selectSort = function (sort) {
            if (vm.predicate === sort) return;
            vm.predicate = sort;
            vm.pageInfo.page = 1;
            $scope.getXmlByCondition();
        };
        //价格筛选点
        $scope.selectPrice = function (e) {
            if ($scope.priceSelected === e) return;
            $scope.priceSelected = e;
            vm.pageInfo.page = 1;
            $scope.getXmlByCondition();
        };
        //判断模板是否可以使用或者需要购买
        $scope.canUse = function (template) {
            if (
                template.price == null ||
                template.price == 0 ||
                vm.user.id == template.userId ||
                template.purchased
            ) {
                return true;
            }
            return false;
        };

        $scope.buyXml = function (item) {
            createModal3(
                "app/entities/page/buy-product-xml.html",
                "BuyProductXMLController",
                item
            ).result.then(function (data) {
                //刷新列表
                $scope.getXmlByCondition();
                //$window.location.reload()
            });
        };
        //更改展开收起状态
        $scope.changeMoreState = function (key) {
            vm[key] = !vm[key];
        };
        var createModal3 = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: "static",
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: "vm",
                resolve: {
                    item: function () {
                        return item;
                    },
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                )
                            };
                        }
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("page");
                            return $translate.refresh();
                        }
                    ]
                }
            });
        };

        $scope.previewTemplate = function (template) {
            if (template.version != "4.0") {
                MessageService.error("当前模板不可用");
                return;
            }
            ProductXml.get({ id: template.id }, function (data) {
                createModal2(
                    "app/entities/page/template-view.html",
                    "TemplateViewController",
                    data.id
                ).result.then(function (data) {});
            });
            $(".modal-content").hide();
        };

        var createModal2 = function (url, controller, id) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: "static",
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: "vm",
                resolve: {
                    entity: [
                        "ProductXml",
                        "$stateParams",
                        function (ProductXml, $stateParams) {
                            return ProductXml.get({ id: id }).$promise;
                        }
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("product");
                            $translatePartialLoader.addPart("productType");
                            return $translate.refresh();
                        }
                    ]
                }
            });
        };
        $scope.gotoCreate = function (item) {
            createModal(
                "app/entities/page/page-create.html",
                "PageCreateController",
                item
            ).result.then(function (data) {});
        };
        var createModal = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: "static",
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: "vm",
                resolve: {
                    items: function () {
                        return item;
                    },
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                )
                            };
                        }
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("page");
                            return $translate.refresh();
                        }
                    ]
                }
            });
        };
        //当前页变更
        vm.currentChange = function () {
            $timeout(function () {
                $scope.getXmlByCondition();
            });
        };

        $scope.getXmlByCondition = function () {
            var items = [
                { key: "status", op: "=", value: "Approved" },
                { key: "opened", op: "=", value: "true" }
            ];
            if (
                $scope.catalogSelected != null &&
                $scope.catalogSelected != "热门模板"
            ) {
                items.push({
                    key: "backCatalog.id",
                    op: "=",
                    value: $scope.catalogSelected.id
                });
            } else if (
                $scope.catalogSelected != null &&
                $scope.catalogSelected == "热门模板"
            ) {
                items.push({ key: "hotspot", op: "=", value: "true" });
            }
            if ($scope.tagSelected != null && $scope.tagSelected != "全部") {
                items.push({
                    key: "hashtags",
                    op: "like",
                    value: $scope.tagSelected.name
                });
            }
            if (vm.searchQuery != "") {
                items.push({ key: "name", op: "like", value: vm.searchQuery });
            }
            if (vm.productActive !== 0) {
                items.push({
                    key: "productType",
                    op: "=",
                    value: $scope.productSelected
                });
            }
            if ($scope.priceSelected) {
                items.push({
                    key: "price",
                    op: $scope.priceSelected,
                    value: "0.00"
                });
            }
            ProductXml.byCondition2(
                {
                    items: items,
                    page: vm.pageInfo.page - 1,
                    size: vm.pageInfo.size,
                    sort: sort()
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc")
                ];
                // if (vm.predicate !== 'id') {
                //     result.push('id');
                // }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.pageInfo.total = headers("X-Total-Count");
                console.log(vm.pageInfo.total);
                vm.productXmls = data;
            }
            function onError(error) {
                MessageService.error("数据加载失败");
            }
        };

        $scope.getXmlByCondition();

        $scope.getTag = function (xml) {
            if (xml.hashtags == null) {
                return;
            }
            return xml.hashtags.replace(";", "  ");
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch
            });
        }

        function search() {
            if(event.keyCode === 13){
                vm.links = null;
                vm.pageInfo.page = 1;
                vm.reverse = false;
                $scope.getXmlByCondition();
            }
        }
        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        vm.domain = Qinius.getDomain();
        vm.hashtags = Hashtag.byCondition2({
            items: [{ key: "usable", value: true, op: "=" }]
        });
        vm.catalogs = Catalog.getAll2({
            items: [
                { key: "type", op: "=", value: "SYSTEM" },
                { key: "catalogType", op: "=", value: "XML" },
                { key: "usable", op: "=", value: "true" }
            ]
        });
        $q.all([
            vm.catalogs.$promise,
            vm.hashtags.$promise,
            vm.domain.$promise
        ]).then(function () {
            vm.domainQ = vm.domain.domain;
            vm.catalogs.sort(function (a, b) {
                return a.seq - b.seq;
            });
            vm.catalogList = vm.catalogList.concat(vm.catalogs);
            vm.hashtags.sort(function (a, b) {
                return a.seq - b.seq;
            });
            vm.hashtagList = vm.hashtagList.concat(vm.hashtags);
        });

        $scope.useTemp = function (temp) {
            if (temp.version != "4.0") {
                MessageService.error("当前模板不可用");
                return;
            }
            if (temp.userId != vm.user.id && temp.price > 0) {
                var items = [
                    { key: "paid", op: "=", value: "true" },
                    { key: "productXml.id", op: "=", value: temp.id },
                    { key: "userId", op: "=", value: vm.user.id }
                ];
                ProductXmlPurchase.getAllByCondition(
                    { items: items },
                    function (data) {
                        if (data && data.length >= 1) {
                            $scope.employ(temp);
                        } else {
                            MessageService.error("请先购买模板，再使用。");
                        }
                    },
                    function () {
                        MessageService.error("查询购买记录失败，请稍后再试。");
                    }
                );
            } else {
                $scope.employ(temp);
            }
        };

        $scope.employ = function (temp) {
            ProductXml.get({ id: temp.id }, function (res) {
                $uibModal.open({
                    templateUrl: "app/entities/page/modal/page-standard.html",
                    controller: "PageStandardController",
                    backdrop: false,
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: "vm",
                    resolve: {
                        items: function () {
                            return {
                                type: "copy",
                                hideModal:true,//隐藏弹窗
                                id: temp.id,
                                data: null
                            };
                        },
                        pagingParams: [
                            "$stateParams",
                            "PaginationUtil",
                            function ($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil.parsePage(
                                        $stateParams.page
                                    )
                                };
                            }
                        ],
                        translatePartialLoader: [
                            "$translate",
                            "$translatePartialLoader",
                            function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart("page");
                                return $translate.refresh();
                            }
                        ]
                    }
                });
            });
        };
    }
})();

(function () {
    "use strict";

    var ModeleSelect = {
        restrict: "EAC",
        replace: true,
        transclude: true,
        templateUrl: "app/entities/components/modele-select.html",
        controllerAs: "vm",
        scope: {},
        bindings: {
            ngModel: "=",
            placeholder: "@",
            prefix: "@", //前缀
            options: "<",
            labelKey: "@",
            valueKey: "@",
            ngChange: "&",
            style:"@",
        },
        controller: [
            "$scope",
            "$timeout",
            "$element",
            "$compile",
            function ($scope, $timeout, $element, $compile) {
                var vm = this;
                //创建option节点
                var template = '<div class="modele-select-option scrollbar-main"></div>';
                var linkFn = $compile(template);
                var content = linkFn($scope);
                // 插入到 body 元素下面
                angular.element(document.body).append(content);
                //options选项点击
                $(content).on("click", ".option-item", function (e) {
                    var itemIndex = $(this).index();
                    if (vm.ngModel === vm.options[itemIndex][vm.valueKey]) return;
                    vm.ngModel = vm.options[itemIndex][vm.valueKey];
                    $timeout(function () {
                        if (typeof vm.ngChange === "function") {
                            vm.ngChange();
                        }
                    },20);
                });

                //下拉框是否展示
                vm.showOption = false;
                var selectEle = $element.find(".modele-select")[0];
                //下拉框默认位置
                $timeout(function () {
                    $(content).css({
                        left: selectEle.getBoundingClientRect().left + "px",
                        top: selectEle.getBoundingClientRect().bottom + 4 + "px"
                    });
                });
                //select框宽度
                vm.selectWidth = selectEle.getBoundingClientRect().width;
                //设置默认值
                if (!vm.placeholder) vm.placeholder = "请选择";
                if (!vm.labelKey) vm.labelKey = "label";
                if (!vm.valueKey) vm.valueKey = "value";
                if(!vm.options) vm.options = [];
                vm.$onChanges = function () {
                    vm.createOptions();
                };
                //组装option
                vm.createOptions = function () {
                    var optionDom = "";
                    angular.forEach(vm.options, function (item) {
                        optionDom +=
                            '<div class="option-item" ' +
                            'data-value="' +
                            item[vm.valueKey] +
                            '">' +
                            item[vm.labelKey] +
                            "</div>";
                    });
                    $(content).html(optionDom);
                    if (vm.showOption) {
                        vm.getOptionPlace();
                    }
                }
                //获取选择框展示的名称
                vm.getShowName = function () {
                    if(vm.ngModel === "" || vm.ngModel === null){
                        return '';
                    }else{
                        var selectIndex = vm.options.findIndex(function(item){
                            return item[vm.valueKey] === vm.ngModel;
                        })
                        if(selectIndex >= 0){
                            //修改页面状态
                            $(content).find(".option-item").removeClass("active");
                            $(content).find(".option-item").eq(selectIndex).addClass("active");
                            return vm.options[selectIndex][vm.labelKey];
                        }
                        return '';
                    }
                };
                //判断下拉框展示位置
                vm.getOptionPlace = function () {
                    if (!vm.showOption) return;
                    $timeout(function () {
                        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                        var windowWidth = document.documentElement.clientWidth || document.body.clientWidth;
                        var selectInfo = selectEle.getBoundingClientRect();
                        var optionHeight = $(content)[0].getBoundingClientRect().height;
                        vm.selectWidth = selectInfo.width;
                        //为美观考虑，暂时跟选择框宽度保持一致（产品）
                        $(content).css({
                            width: vm.selectWidth + 'px'
                            // minWidth: vm.selectWidth + "px",
                            // maxWidth: windowHeight + "px"
                        })
                        //判断右侧距离是否足够
                        if($(content)[0].getBoundingClientRect().right >= windowWidth){
                            $(content).css({
                                right: 0,
                                left:'auto'
                            });
                        }else{
                            $(content).css({
                                left: selectInfo.left + "px",
                                right:'auto'
                            });
                        }



                        //判断下方高度是否足够
                        if (windowHeight - selectInfo.bottom > optionHeight + 4) {
                            $(content).css({
                                top: selectInfo.bottom + 4 + "px",
                                bottom: "auto",
                                transform:"none"
                            });
                            return;
                        }
                        //判断上方高度是否足够
                        if (selectInfo.top > optionHeight + 4) {
                            $(content).css({
                                bottom: windowHeight - selectInfo.top + 4 + "px",
                                top: "auto",
                                transform:"none"
                            });
                            return;
                        }
                        //距离不够，居中显示
                        $(content).css({
                            top: "50%",
                            transform: "translate(0, -50%)"
                        });
                        console.log($(content)[0].getBoundingClientRect());
                    });
                };
                //点击输入框，展示或隐藏下拉列表
                $scope.clickInput = function () {
                    if (vm.showOption) {
                        $scope.hide();
                    } else {
                        $scope.show();
                    }
                };
                $scope.show = function () {
                    vm.createOptions();
                    $timeout(function () {
                        vm.showOption = true;
                        $(content).show();
                        vm.getOptionPlace();
                    });
                };
                $scope.hide = function () {
                    $timeout(function () {
                        vm.showOption = false;
                        $(content).hide();
                    });
                };
                //页面点击
                function docClick() {
                    if (vm.showOption) {
                        $scope.hide();
                    }
                }
                //键盘事件
                function onKeyDown() {
                    var keyCode = event.keyCode;
                    if (keyCode === 27 || keyCode === 9) {
                        $scope.hide();
                    }
                }

                vm.resizeTimer = null;
                //屏幕大小变化或页面滚动
                function onResizeOrScroll() {
                    if (vm.showOption) {
                        if (!vm.resizeTimer) {
                            vm.resizeTimer = setTimeout(function () {
                                //重新计算下拉框展示位置
                                vm.getOptionPlace();
                                vm.resizeTimer = null;
                            }, 100);
                        }
                    }
                }
                document.addEventListener("click", docClick);
                document.addEventListener("keydown", onKeyDown);
                window.addEventListener("resize", onResizeOrScroll);
                window.addEventListener("scroll", onResizeOrScroll);
                window.addEventListener("mousewheel",onResizeOrScroll);

                vm.$onDestroy = function () {
                    //删除插入元素，取消监听事件
                    content.remove();
                    document.removeEventListener("click", docClick);
                    document.removeEventListener("keydown", onKeyDown);
                    window.removeEventListener("resize", onResizeOrScroll);
                    window.removeEventListener("scroll", onResizeOrScroll);
                    window.removeEventListener("mousewheel",onResizeOrScroll);
                };
            }
        ]
    };
    angular.module("platformApp").component("modeleSelect", ModeleSelect);
})();

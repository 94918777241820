(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PictureXmlController', PictureXmlController);

    PictureXmlController.$inject = ['$state', '$scope', 'UidService', 'ProductXml', 'ParseLinks', 'AlertService', 'MessageService', '$uibModal', 'FontManagement', 'Qinius', '$q', '$timeout', 'PicLayoutXmls', 'ClipArts', 'Backgrounds', 'ThemeClassify', 'SourceMaterial', 'entity', 'ImageService'];

    function PictureXmlController($state, $scope, UidService, ProductXml, ParseLinks, AlertService, MessageService, $uibModal, FontManagement, Qinius, $q, $timeout, PicLayoutXmls, ClipArts, Backgrounds, ThemeClassify, SourceMaterial, entity, ImageService) {
        var vm = this;
        // vm.moreDisplay = false;
        // vm.moreIcon = false;
        // $(window).resize(function(){
        //     if ($('.c-xml-action').outerWidth() <= 800) {
        //         vm.moreIcon = true;
        //     }else{
        //         vm.moreIcon = false;
        //         vm.moreDisplay = false;
        //     }
        // })
        //是否是拖曳画布状态
        vm.moveCanvasView = false;
        vm.moveCanvasInfo = {
            x:0,
            y:0
        }
        //初始化画布移动信息
        vm.initMoveCanvasInfo = function(){
            vm.moveCanvasInfo = {
                x:0,
                y:0
            }
        }
        if(entity){
            vm.data = entity;
        }else{
            vm.picturePage = JSON.parse(localStorage.picturePage);
            vm.data = {
                name: vm.picturePage.width + '×' + vm.picturePage.height + 'MM',
                cover: '',
                identifier: '',
                opened: false,
                quantity: '',
                catalogId: vm.picturePage.catalogId,
                catalogName: vm.picturePage.catalogName,
                document: {
                    movable: true,
                    mediabox: {
                        x: '0',
                        y: '0',
                        width: vm.picturePage.width,
                        height: vm.picturePage.height
                    },
                    levels: {
                        level: []
                    }
                }
            }
        }
        $scope.domains = Qinius.getDomain();
        vm.navList = [
            {img:'/content/images/icons/nav_photo.svg', name: '相框', id:'imagebox'}
        ]
        vm.navPitch = 'imagebox';
        vm.units = [
            { name: 'px', id: 'px'},
            { name: 'mm', id: 'mm'}
        ];
        vm.unit = "px";
        vm.xmlSelect = {
            type: '',
            index:''
        };
        vm.cookies = [];
        vm.isUnfold = false;
        vm.isUnfoldRight = false;
        vm.isXmlDblclick = false;
        vm.photoTab='oneself';
        vm.mask = [];
        vm.recommendImg = [];
        $scope.masklabels = [
            {id:'', name:'全部'}
        ]
        $scope.$watch(function() {
            return vm.data.name;
        }, function(newName, oldName) {
            if (newName!==undefined) {
                document.title = newName + '-设计页'
            }
        });
        $q.all([$scope.domains.$promise, vm.data.$promise]).then(function () {
            vm.url = $scope.domains.domain;
            //获取token
            Qinius.get(function (msg) {
                vm.token = msg.token;
            });
            init();
            //作品宽高
            initWindow()
            vm.dataInit = angular.copy(vm.data);

            //分类
            ThemeClassify.getAllByCondition({
                items:[{key: "type", op: "=", value: 'MASK'},{key: "cancelled", op: "=", value: false},{key: "used", op: "=", value: true}],
                sort: ["id,desc"]
            }, function (res) {
                res.forEach(function (item) {
                    if(!item.cancelled){
                        $scope.masklabels.push(item)
                    }
                })
                vm.masklabelChooseId = $scope.masklabels[0].id;
                vm.bgImgIndex = 0;
                clickMaskLabels();
            });
        })

        function init(){
            vm.data.document.mediabox.width = transitionPx(vm.data.document.mediabox.width).toFixed(2);
            vm.data.document.mediabox.height = transitionPx(vm.data.document.mediabox.height).toFixed(2);
            if (vm.data.document.levels.level == null) {
                vm.data.document.levels.level = [];
            }
            angular.forEach(vm.data.document.levels.level, function (level, index) {
                if(level.imagebox){
                    level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                    level.imagebox.geometry = {
                        width: transitionPx(level.imagebox.geometry.width),
                        height: transitionPx(level.imagebox.geometry.height),
                        x: transitionPx(level.imagebox.geometry.x),
                        y: transitionPx(level.imagebox.geometry.y)
                    };
                    maskScale(vm.data.document.levels.level[index].imagebox, index, level.imagebox.mask.resource.identifier)
                }
            });
            vm.attribute = {
                product: {
                    width: vm.data.document.mediabox.width,
                    height:vm.data.document.mediabox.height,
                }
            }
        }
        //相框
        vm.bgImgList = [];
        vm.bgImgSum = 10;
        vm.bgImgIndex = 0;
        vm.isBgImgData = false;

        $scope.selectMaskLabel = function (item) {
            vm.masklabelChooseId = item.id;
            vm.bgImgIndex = 0;
            vm.mask=[];
            clickMaskLabels();
        }
        function clickMaskLabels() {
            var items = [];
            if(vm.masklabelChooseId){
                items.push({key: "themeClassify.id", op: "=", value: vm.masklabelChooseId})
            }else{
                // items.push({key: "type", op: "=", value: 'User'})
            }
            SourceMaterial.byCondition({
                items: items,
                page: vm.bgImgIndex,
                size: vm.bgImgSum,
                sort: ["id,desc"]
            }, function (resp, headers) {
                vm.totalMaskItems = headers('X-Total-Count');
                angular.forEach(resp, function (data,i) {
                    var arr = {
                        resource: {
                            identifier: vm.url + data.identifier,
                            provider: data.provider,
                        },
                        type: data.type,
                        id: data.id,
                        isRecommend: false,
                        identifier: vm.url + data.identifier,
                        provider: 'qiniu',
                        bgImg: '',
                    }
                    vm.mask.push(arr);
                })
                if(resp.length < vm.bgImgSum){
                    vm.isBgImgData = true;
                }else{
                    vm.isBgImgData = false;
                }
            })
        }
        $scope.bgImgPageing = function(){
            vm.bgImgIndex++;
            clickMaskLabels()
        }
        $scope.recommendCb = function($event, item){
            vm.recommendImg = [];
            item.isRecommend = !item.isRecommend;
            vm.mask.forEach(function (data) {
                if(data.isRecommend){
                    vm.recommendImg.push(data);
                }
            })
            fn($event)
        }

        $scope.navCb = function (data) {
            vm.navPitch = data;
            // if(vm.navPitch == data.id){
            //     vm.isUnfold = !vm.isUnfold;
            //     // vm.isUnfoldRight = !vm.isUnfoldRight;
            // }else {
            //     vm.navPitch = data.id;
            //     vm.isUnfold = false;
            //     // vm.isUnfoldRight = false;
            // }
        };

        $scope.unfoldLeft = function(){
            vm.isUnfold = !vm.isUnfold;
        }
        $scope.unfoldRight = function(){
            vm.isUnfoldRight = !vm.isUnfoldRight;
        }
        $scope.photoTabClick = function(item){
            vm.photoTab=item;
        }
        $scope.aa = function(){
            console.log('fdslkjahfjkshda')
        }
        // 相框的上传
        window.updatePic = function (e) {
            console.log('fdslkjahfjkshda')
            var files = e.target.files;
            getBgImg(files, 0);
        };
        function getBgImg(file, i) {
            var formData = new FormData();
            formData.append('file', file[i]);
            formData.append('unique_names', false);
            formData.append('key', 'mask-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    SourceMaterial.createOne({
                        identifier: data.key,
                        provider: 'qiniu',
                        themeClassifyId: vm.masklabelChooseId,
                        opened: true,
                        type: 'User'
                    }, function (resp) {
                        resp.resource= {
                            identifier: vm.url + resp.identifier,
                            provider: 'qiniu',
                        };
                        resp.identifier= vm.url + resp.identifier;
                        vm.mask.unshift(resp);
                    });
                    $scope.$apply();
                    i++;
                    if (i < file.length) {
                        getBgImg(file, i);
                    }
                },
                error: function (error) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        $scope.bgImgCb = function(item){

            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = item.resource.identifier + '?imageMogr2/auto-orient/rotate';
            newImg.onload = function () {
                var _level = {
                    imagebox:{},
                    seq: vm.data.document.levels.level.length + Number(1)
                }
                var _imagebox = {
                    blurredrectangle:null,
                    bokehtype:'None',
                    transparency:1,
                    geometry:{
                        width:newImg.width,
                        height:newImg.height,
                        x:0,
                        y:0
                    },
                    image: {
                        resource: {
                            identifier: '',
                            provider: ''
                        },
                        filterResource: {
                            identifier: '',
                            provider: 'qiniu'
                        },
                        scale: 1,
                        angle: 0
                    },
                    mask: {
                        resource:{
                            identifier: item.resource.identifier,
                            provider: 'qiniu',
                        }
                    },
                    rotation:{
                        angle: 0
                    },
                    border: {
                        shape: 'Rectangle',
                        radius: {
                            lt: 0,
                            rt: 0,
                            rb: 0,
                            lb: 0,
                            all:0,
                            filletType: "Fixed"
                        },
                        "edge": {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                        lineWidth: 0,
                        type: "Color",
                        lineType:"solid",
                        resource: {
                            "identifier": ''
                        },
                        color: '#000000',
                    },
                    shadow:{
                        color:'#777777',
                        x:0,
                        y:0,
                        blur:0
                    },
                }
                _imagebox.geometry.width= vm.data.document.mediabox.width / 2;
                _imagebox.geometry.height= _imagebox.geometry.width/newImg.width*newImg.height;
                _imagebox.geometry.x = (vm.data.document.mediabox.width - _imagebox.geometry.width) / 2;
                _imagebox.geometry.y = (vm.data.document.mediabox.height - _imagebox.geometry.height) / 2;
                _level.imagebox = _imagebox;
                vm.data.document.levels.level.push(_level);
                maskScale(vm.data.document.levels.level[vm.data.document.levels.level.length-1].imagebox, vm.data.document.levels.level.length-1, item.resource.identifier)
                $scope.$digest();
            };
        };

        $scope.canvasMiddle = function(){
            if (!$scope.selectBox) {
                MessageService.notice('请选择相框')
                return
            }
            if (vm.ctrlKey.length>1) {
                vm.data.document.levels.level.forEach(function(item){
                    if(!item.imagebox.lock && item.imagebox.selected){
                        item.imagebox.geometry.x = (vm.data.document.mediabox.width - item.imagebox.geometry.width) / 2;
                        item.imagebox.geometry.y = (vm.data.document.mediabox.height - item.imagebox.geometry.height) / 2;
                    }
                    item.imagebox.selected = false
                })
                vm.ctrlKey = []
                return
            }
            var item = null;

            if(vm.data.document.levels.level[vm.xmlSelect.index].imagebox){
                item = vm.data.document.levels.level[vm.xmlSelect.index].imagebox;
            }
            if(item && !item.lock){
                item.geometry.x = (vm.data.document.mediabox.width - item.geometry.width) / 2;
                item.geometry.y = (vm.data.document.mediabox.height - item.geometry.height) / 2;
            }
        }

        function maskScale(imageboxs, index, identifier) {
            var imagebox = angular.copy(imageboxs);
            imagebox.mask.resource.identifier = identifier;

            var imgUrl = 'content/images/camera.png';

            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = imgUrl;
            newImg.onload = function () {
                newImg.onload = null;
                var width = newImg.width;
                var height = newImg.height;
                var scaleW = 1, scaleH = 1;
                var masks = document.createElement('img');
                masks.src = identifier;
                masks.crossOrigin = "anonymous";
                masks.onload = function () {
                    masks.onload = null;
                    imagecanvas.width = width;
                    imagecanvas.height = height;
                    imagecontext.drawImage(masks, 0, 0, width, height);
                    imagecontext.globalCompositeOperation = 'source-in';
                    imagecontext.scale(scaleW, scaleH);
                    imagecontext.drawImage(newImg, 0, 0);
                    var pic = imagecanvas.toDataURL();
                    imageboxs.bgImg = ImageService.dataURLtoBlob(pic);
                    $scope.$digest();
                };
            };

        }


        $scope.bgImgDelete = function ($event, item, index) {
            SourceMaterial.delete({id: item.id}, function () {
                vm.mask.splice(index, 1)
            });
            fn($event)
        }
        vm.ctrlKey = []
        //相框
        $scope.imageartClick = function($event, imagebox, index){
            updateUnitImagebox(imagebox);
            if ($event.ctrlKey == true || $event.metaKey == true) {
                if (vm.xmlSelect.index !== '') {
                    vm.data.document.levels.level[vm.xmlSelect.index].imagebox.selected = !imagebox.selected;
                    vm.ctrlKey.push(vm.data.document.levels.level[vm.xmlSelect.index].imagebox)
                }
                imagebox.selected = !imagebox.selected;
                vm.xmlSelect.type = "";
                vm.xmlSelect.index = "";
                vm.ctrlKey.push(imagebox)
            }else{
                if(!vm.data.document.levels.level[index].imagebox.selected){
                    vm.ctrlKey = [];
                    vm.data.document.levels.level.forEach(function(item){
                        item.imagebox.selected = false
                    })
                }
                vm.xmlSelect.type = 'imagebox';
                vm.xmlSelect.index = index;
                $scope.selectBox = imagebox;
                fn($event)
            }
            toolbarStatus();
        }
        $scope.isAlignHover = false;
        $scope.isDistriHover = false;
        $scope.toobarAlignment = function (attr) {
            if (!$scope.selectBox) {
                MessageService.notice('请选择一个相框')
                return
            }
            toolbarStatus();
            $scope.isAlignHover = attr;
        }

        $scope.boxDistribution = function (attr) {
            var all1 = vm.ctrlKey
            var all = [];
            all1.forEach(function(box){
                if (!box.lock) {
                    all.push(box)
                }
            })
            if (all.length < 3) {
                MessageService.notice('请选择3个以上非锁定状态的元素！');
                return
            }

            // 水平分布
            if (attr == 'standard') {
                setAllCtrlKeyArray(all, 'x');
            } else {
                // 垂直分布
                setAllCtrlKeyArray(all, 'y');

            }
        }
        function setAllCtrlKeyArray(all, attr) {
            var len = all.length - 1;
            all.sort(compare2(attr, true));
            if (attr == 'x') {
                var elementAllWidth = 0;
                for (var i = 1; i < len; i++) {
                    elementAllWidth+=all[i].geometry.width;
                }
                var spacingPx = (all[len].geometry.x - all[0].geometry.x - all[0].geometry.width - elementAllWidth) / len;
                for (var i = 1; i < len; i++) {
                    all[i].geometry.x = all[i-1].geometry.x + all[i-1].geometry.width + spacingPx;
                }
            }
            if (attr == 'y') {
                var elementAllHeight = 0;
                for (var i = 1; i < len; i++) {
                    elementAllHeight+=all[i].geometry.height;
                }
                var spacingPx = (all[len].geometry.y - all[0].geometry.y - all[0].geometry.height - elementAllHeight) / len;
                for (var i = 1; i < len; i++) {
                    all[i].geometry.y = all[i-1].geometry.y + all[i-1].geometry.height + spacingPx;
                }
            }
            vm.ctrlKey = []
            vm.data.document.levels.level.forEach(function(item){
                item.imagebox.selected = false;
            })

        }
        //拖动图片框
        var isDown = false;
        $scope.imageboxDown = function ($event, item) {
            if(item.lock){return}
            var $event = $event || window.event;
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            if (vm.ctrlKey.length > 0) {
                levelMove($event);
            }else{
                imageMove($event, item)
            }
        }
        function levelMove(event) {
            console.log(vm.ctrlKey)
            angular.forEach(vm.data.document.levels.level, function (level, i) {
                if(level.imagebox && level.imagebox.selected){
                    if(!level.imagebox.lock){
                        (function(imagebox){
                            imageMove(event, imagebox)
                        })(level.imagebox)
                    }
                }
            })
        }
        var moveItem = [];
        function imageMove($event, item){
            $event.stopPropagation();
            var x = $event.clientX;
            var y = $event.clientY;
            item.l = angular.copy(item.geometry.x * vm.ratio);
            item.t = angular.copy(item.geometry.y * vm.ratio);
            moveItem.push(item)
            //开关打开
            isDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isDown == false) {
                    return;
                }
                moveItem.forEach(function (item1) {
                    var nx = e.clientX;
                    var ny = e.clientY;
                    //计算移动后的左偏移量和顶部的偏移量
                    var nl = nx - (x - item1.l);
                    var nt = ny - (y - item1.t);
                    item1.geometry.x = nl / vm.ratio;
                    item1.geometry.y = nt / vm.ratio;
                })
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                if (isDown) {
                    cookie();
                }
                isDown = false;
                moveItem = [];
                $event.stopPropagation();
            }
        }
        // //缩放
        var isZoomDown = false;
        // $scope.resizeStartRigth = function($event, item,pro,index,slide){
        //     if(item.lock){return}
        //     fn($event);
        //     imageboxZoom($event, item, 'right',pro,index,slide)
        // }
        // $scope.resizeStartLeft = function ($event, item,pro,index,slide) {
        //     if(item.lock){return}
        //     fn($event);
        //     imageboxZoom($event, item, 'left',pro,index,slide)
        // }
        // $scope.resizeStartTop = function ($event, item,pro,index,slide) {
        //     if(item.lock){return}
        //     fn($event);
        //     imageboxZoom($event, item, 'top',pro,index,slide)
        // }
        // $scope.resizeStartBottom = function ($event, item,pro,index,slide) {
        //     if(item.lock){return}
        //     fn($event);
        //     imageboxZoom($event, item, 'bottom',pro,index,slide)
        // }
        // $scope.resizeStartLeft1 = function ($event, item,pro,index,slide) {
        //     if(item.lock){return}
        //     fn($event);
        //     imageboxZoom1($event, item, 'left',pro,index,slide)
        // }
        // $scope.resizeStartTop1 = function ($event, item,pro,index,slide) {
        //     if(item.lock){return}
        //     fn($event);
        //     imageboxZoom1($event, item, 'top',pro,index,slide)
        // }
        // $scope.resizeStartRight1 = function ($event, item,pro,index,slide) {
        //     if(item.lock){return}
        //     fn($event);
        //     imageboxZoom1($event, item, 'right',pro,index,slide)
        // }
        // $scope.resizeStartBottom1 = function ($event, item,pro,index,slide) {
        //     if(item.lock){return}
        //     fn($event);
        //     imageboxZoom1($event, item, 'bottom',pro,index,slide)
        // }
        function imageboxZoom($event,item, direction,pro,index,slide) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
            var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
            var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
            var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
            var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
            var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var _scale = item.image ? angular.copy(item.image.scale) : 1;
            var maxX = vm.data.document.mediabox.width * vm.ratio;
            var maxY = vm.data.document.mediabox.height * vm.ratio;

            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;

                if(direction == 'bottom' || direction == 'left'){
                    var _w = (Number(nw) - Number(nl));
                }
                if(direction == 'top' || direction == 'right'){
                    var _w = (Number(nw) + Number(nl));
                }
                var _h = _w / nw * nh;
                // if(_w > ($scope.currentPage.width+$scope.wahBorder/$scope.currentPage.proportion)*$scope.currentPage.scale){
                //     _w = ($scope.currentPage.width+$scope.wahBorder/$scope.currentPage.proportion)*$scope.currentPage.scale;
                //     _h = _w / nw * nh;
                // }
                // if(_h > ($scope.currentPage.height+$scope.xayBorder/$scope.currentPage.proportion)*$scope.currentPage.scale){
                //     _h = ($scope.currentPage.height+$scope.xayBorder/$scope.currentPage.proportion)*$scope.currentPage.scale;
                //     _w = _h / nh * nw;
                // }
                if(_w > maxX){
                    _w = maxX;
                    _h = _w / nw * nh;
                }
                if(_h > maxY){
                    _h = maxY;
                    _w = _h / nh * nw;
                }
                item.geometry.width = _w / pro;
                item.geometry.height = _h / pro;
                if(item.image && item.image.resource.identifier){
                    item.image.width = _w / nw * nw2 / pro;
                    item.image.height = _w / nw * nh2 / pro;
                    item.image.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / pro;
                    item.image.offsety = (_h / (nh / nh1)) / (nh1 / y1) / pro;

                    if(item.bokehtypes != 'None'){
                        var imgR = item.geometry.width/item.geometry.height;
                        var imgR1 = item.image.width/item.image.height;
                        if(imgR >= imgR1){
                            item.image.resource.adaptation = 'Height';
                            item.blurredrectangle = {
                                width: item.geometry.width,
                                height: item.geometry.width/item.image.width*item.image.height,
                                x: 0,
                                y: (item.geometry.height - item.geometry.width/item.image.width*item.image.height) / 2,
                            }
                        }else {
                            item.image.resource.adaptation = 'Width';
                            item.blurredrectangle = {
                                width: item.geometry.height/item.image.height*item.image.width,
                                height: item.geometry.height,
                                x: (item.geometry.width - item.geometry.height/item.image.height*item.image.width) / 2,
                                y: 0,
                            }
                        }
                    }
                }
                if(direction == 'left'){
                    item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                    item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                    if(item.geometry.x < 0){
                        item.geometry.x = 0;
                    }
                    if(item.geometry.y < 0){
                        item.geometry.y = 0;
                    }
                }
                if(direction == 'top'){
                    if(item.geometry.width*pro > maxX - item.geometry.x*pro){
                        item.geometry.width = (maxX - item.geometry.x*pro) / pro;
                    }
                    item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                    if(item.geometry.y < 0){
                        item.geometry.y = 0;
                    }
                }
                if(direction == 'right'){
                    if(item.geometry.width*pro > maxX - item.geometry.x*pro){
                        item.geometry.width = (maxX - item.geometry.x*pro) / pro;
                        item.geometry.height = item.geometry.width / nw * nh;
                    }
                    if(Number(item.geometry.height * pro) + Number(item.geometry.y * pro) > maxY){
                        item.geometry.height = (maxY - Number(item.geometry.y * pro)) / pro;
                        item.geometry.width = item.geometry.height / nh * nw;
                        return;
                    }
                }
                if(direction == 'bottom'){
                    if(Number(item.geometry.height * pro) + Number(item.geometry.y * pro) > maxY){
                        item.geometry.height = (maxY - Number(item.geometry.y * pro)) / pro;
                        item.geometry.width = item.geometry.height / nh * nw;
                        return;
                    }
                    item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                    if(item.geometry.x < 0){
                        item.geometry.x = 0;
                    }
                }

                vm.data.document.levels.level[vm.xmlSelect.index].imagebox = item;
                updateUnitImagebox(item);
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                if(isZoomDown){
                    cookie();
                }
                $scope.$digest();
                $event.stopPropagation();
                isZoomDown = false;
            }
        }
        function imageboxZoom1($event,item, direction, pro,index,slide) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
            var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
            var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
            var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
            var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
            var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var _scale = item.image ? angular.copy(item.image.scale) : 1;
            var maxX = vm.data.document.mediabox.width * vm.ratio;
            var maxY = vm.data.document.mediabox.height * vm.ratio;
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;
                var nt = ny - y;

                if(direction == 'left'){
                    var _w = (Number(nw) - Number(nl));
                }
                if(direction == 'right'){
                    var _w = (Number(nw) + Number(nl));
                }
                if(direction == 'top'){
                    var _h = (Number(nh) - Number(nt));
                }
                if(direction == 'bottom'){
                    var _h = (Number(nh) + Number(nt));
                }
                if(direction == 'left'){
                    item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                    item.geometry.width = _w / pro;
                    if(item.geometry.x < 0){
                        item.geometry.x = 0;
                    }
                }
                if(direction == 'top'){
                    item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                    item.geometry.height = _h / pro;
                    if(item.geometry.y < 0){
                        item.geometry.y = 0;
                    }
                }
                if(direction == 'right'){
                    item.geometry.width = _w / pro;
                }
                if(direction == 'bottom'){
                    item.geometry.height = _h / pro;
                }
                if(item.geometry.width*pro > maxX - item.geometry.x*pro){
                    item.geometry.width = (maxX - item.geometry.x*pro) / pro;
                }
                if(item.geometry.height*pro > maxY - item.geometry.y*pro){
                    item.geometry.height = (maxY - item.geometry.y*pro) / pro;
                }
                vm.data.document.levels.level[vm.xmlSelect.index].imagebox = item;
                updateUnitImagebox(item);
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                if (isZoomDown){
                    cookie();
                }
                $event.stopPropagation();
                isZoomDown = false;
            }
        }
        $scope.imageboxdownRotate = function ($event, item) {
            if(item.lock){return}
            fn($event);
            var _top = $($event.target).parents('.c-xml-page-material').offset().top + (item.geometry.height * vm.ratio / 2);
            var _left = $($event.target).parents('.c-xml-page-material').offset().left + (item.geometry.width * vm.ratio / 2);

            //开关打开
            var isRotate = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isRotate == false) {
                    return;
                }
                item.rotation.angle = getAngle(_left,_top, e.clientX, e.clientY);
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function (e) {
                if(isRotate){
                    cookie()
                }
                isRotate = false;
            };
        }
        function getAngle(px,py,mx,my){//获得人物中心和鼠标坐标连线，与y轴正半轴之间的夹角
            var x = Math.abs(px-mx);
            var y = Math.abs(py-my);
            var z = Math.sqrt(Math.pow(x,2)+Math.pow(y,2));
            var cos = y/z;
            var radina = Math.acos(cos);//用反三角函数求弧度
            var angle = Math.floor(180/(Math.PI/radina));//将弧度转换成角度
            if(mx<px&&my>py){//鼠标在第三象限
                angle = 360 - angle;
            }
            if(mx<px&&my<py){
                angle = 180 + angle;
            }
            if(mx>px&&my<py){
                angle = 180 - angle;
            }
            if(mx>px&&my>py){
                angle = 360 + angle;
            }
            return normalizeAngle(-angle);
        }
        // 旋转角度取值0-360
        function normalizeAngle(angle) {
            angle = angle % 360;
            if (angle < 0) {
            angle = angle + 360;
            }
            return angle;
        }
        $scope.fn = fn;
        function fn($event){
            var $event = $event || window.event;
            // if($event && $event.preventDefault){
            //     $event.preventDefault();
            // }
            // else {
            //     window.event.returnValue = false;
            //     return false;
            // }
            $event.stopPropagation();
        }

        //点击作品框
        $scope.xmlClick = function($event){
            if ($event.ctrlKey == true || $event.metaKey == true) {
                return
            }
            fn($event)
        }

        $scope.clearCtrlKey = function ($event) {
            if ($event.ctrlKey == true || $event.metaKey == true) {
                return
            }
            vm.ctrlKey = []
            vm.data.document.levels.level.forEach(function(item){
                item.imagebox.selected = false;
            })
            // console.log(vm.data.document.levels.level[vm.xmlSelect.index].imagebox.geometry.x,vm.data.document.levels.level[vm.xmlSelect.index].imagebox.geometry.y,'点计画布');
            $scope.selectBox = null;
            vm.xmlSelect = {
                type: '',
                index:''
            };
            toolbarStatus();
        }

        $scope.boxAlignment = function (attr) {
            var all = vm.ctrlKey
            if (attr == 'left') {
                all.sort(compare2('x', true));
            } else if (attr == 'right') {
                all.sort(compare2('x', false, 'width'));
            } else if (attr == 'top') {
                all.sort(compare2('y', true));
            } else if (attr == 'bottom') {
                all.sort(compare2('y', false,'height'));
            }
            if(attr == 'vertical' && all.length>0){
                var verticalY = horizontalAndVerticalCenter(attr, all);
            }
            if(attr == 'standard' && all.length>0){
                var standardX = horizontalAndVerticalCenter(attr, all);
            }
            var bigBoxCoord = null;
            if(['left','right','top','bottom'].includes(attr)){
                bigBoxCoord = getAllElemCoord(all);
            }
            for (var i = 0; i < all.length; i++) {
                if (all[i].lock) {
                    break;
                }
                if (attr == 'left') {
                    var coord = getElemMoveCoord('left',bigBoxCoord,all[i]);
                    all[i].geometry.x = coord;

                } else if (attr == 'right') {
                    var coord = getElemMoveCoord('right',bigBoxCoord,all[i]);
                    all[i].geometry.x = coord;

                } else if (attr == 'top') {
                    var coord = getElemMoveCoord('top',bigBoxCoord,all[i]);
                    all[i].geometry.y = coord;

                } else if (attr == 'bottom') {
                    var coord = getElemMoveCoord('bottom',bigBoxCoord,all[i]);
                    all[i].geometry.y = coord;

                } else if (attr == 'vertical') {
                    all[i].geometry.y = verticalY - all[i].geometry.height / 2;
                } else {
                    all[i].geometry.x = standardX - all[i].geometry.width / 2;
                }
            }
            vm.ctrlKey = []
            vm.data.document.levels.level.forEach(function(item){
                item.imagebox.selected = false;
            })
            cookie();
        }

        function compare2(property, attr,property2) {
            return function (a, b) {
                var value1 = a.geometry[property];
                var value2 = b.geometry[property];
                // console.log(value1);
                // console.log(value2);
                if (attr) {
                    return value1 - value2;
                } else {
                    var value12 = a.geometry[property2];
                    var value22 = b.geometry[property2];
                    return value2+value22 - value1-value12;
                }
            }
        }
        //单位换算
        $scope.unitConversion = function () {
            unit()
        }
        function unit(){
            if(vm.unit == 'mm'){
                vm.attribute = {
                    product: {
                        width: (vm.data.document.mediabox.width/vm.windowDpiW*25.4).toFixed(2),
                        height:(vm.data.document.mediabox.height/vm.windowDpiH*25.4).toFixed(2),
                    },
                    imagebox:!$scope.selectBox?null:{
                        width: PxTurnMm($scope.selectBox.geometry.width),
                        height: PxTurnMm($scope.selectBox.geometry.height),
                        x: PxTurnMm($scope.selectBox.geometry.x),
                        y: PxTurnMm($scope.selectBox.geometry.y)
                    },
                }
            }
            if (vm.unit == 'px'){
                vm.attribute = {
                    product: {
                        width: vm.data.document.mediabox.width,
                        height:vm.data.document.mediabox.height,
                    },
                    imagebox:!$scope.selectBox?null:{
                        width: $scope.selectBox.geometry.width,
                        height: $scope.selectBox.geometry.height,
                        x: $scope.selectBox.geometry.x,
                        y: $scope.selectBox.geometry.y
                    },
                }
            }
        }
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpiW = arrDPI[0];
            vm.windowDpiH = arrDPI[1];
            // vm.windowDpiW = 300;
            // vm.windowDpiH = 300;
            return arrDPI;
        }

        //作品框宽高优化
        function initWindow(){
            vm.windowH = window.innerHeight - 110;
            vm.windowW = window.innerWidth - 500;
            if(vm.windowH / vm.data.document.mediabox.height >= vm.windowW / vm.data.document.mediabox.width ){
                vm.ratio = vm.windowW / vm.data.document.mediabox.width * 0.8;
            }else {
                vm.ratio = vm.windowH / vm.data.document.mediabox.height * 0.8;
            }
            //记录初始缩放比
            vm.initRatio = vm.ratio;
            vm.windowH1 = 200;
            vm.windowW1 = 200;
            if(vm.windowH1 / vm.data.document.mediabox.height >= vm.windowW1 / vm.data.document.mediabox.width ){
                vm.ratio1 = vm.windowW1 / vm.data.document.mediabox.width * 0.8;
            }else {
                vm.ratio1 = vm.windowH1 / vm.data.document.mediabox.height * 0.8;
            }
        }
        window.onresize = function(){
            initWindow()
            $scope.$apply();
            getFullState();
        }
        //属性栏
        $scope.$watch("vm.attribute.product.width",function(newValue,oldValue){
            if(oldValue){
                if(vm.unit == 'mm'){vm.data.document.mediabox.width = (vm.attribute.product.width/25.4 * vm.windowDpiW).toFixed(2);}
                if (vm.unit == 'px'){vm.data.document.mediabox.width = vm.attribute.product.width;}
                initWindow();
            }
        })
        $scope.$watch("vm.attribute.product.height",function(newValue,oldValue){
            if(oldValue){
                if(vm.unit == 'mm'){vm.data.document.mediabox.height = (vm.attribute.product.height/25.4 * vm.windowDpiH).toFixed(2);}
                if (vm.unit == 'px'){vm.data.document.mediabox.height = vm.attribute.product.height;}
                initWindow();
            }
        })

        //锁定
        $scope.xmlLock = function($event){
            if (!$scope.selectBox) {
                MessageService.notice('请选择一个相框')
                return
            }
            if(vm.xmlSelect.type == 'imagebox'){
                vm.data.document.levels.level[vm.xmlSelect.index].imagebox.lock = !vm.data.document.levels.level[vm.xmlSelect.index].imagebox.lock ? true : false;
                console.log(vm.data.document.levels.level[vm.xmlSelect.index].imagebox.lock)
            }
            fn($event)
        }
        //复制
        $scope.xmlCopy = function($event){
            if (!$scope.selectBox) {
                MessageService.notice('请选择一个相框')
                return
            }
            xmlCopy()
            fn($event)
        }
        //键盘事件
        document.onkeydown = function(event){
            if(vm.isTransp){
                return
            }
            fn(event);
            //ctrl + c
            if (event.ctrlKey && window.event.keyCode==67){
                if(event.target.nodeName=='TEXTAREA'){
                    return
                }
                return false;
            }
            //ctrl + v
            if (event.ctrlKey && window.event.keyCode==86){
                if(event.target.nodeName=='TEXTAREA'){
                    return
                }
                xmlCopy();
                $scope.$digest();
                return false;
            }
            // 删除
            if (event.keyCode == 46) {
                xmlDelete();
                $scope.$digest();
            }

            // 上
            if (event.keyCode == 38) {
                keycodeArrow(38);
            }
            // 下
            if (event.keyCode == 40) {
                keycodeArrow(40);
            }
            // 左
            if (event.keyCode == 37) {
                keycodeArrow(37);
            }
            // 右
            if (event.keyCode == 39) {
                keycodeArrow(39);
            }
            //空格
            if (event.keyCode === 32){
                if(vm.moveCanvasView) return false;
                $timeout(function(){
                    vm.moveCanvasView = true;
                })
                return false;
            }
            //F11
            if (event.keyCode===122 && !vm.isFullScreen) {
                event.preventDefault();
                $scope.fullScreen();
            }
            // ctrl+z
            if (event.keyCode == 90 && event.ctrlKey) {
                $scope.xmlRevocation(event);
                $scope.$digest();
            }
        }
        document.onkeyup = function(){
            if(event.keyCode === 32){
                $timeout(function(){
                    vm.moveCanvasView = false;
                })
            }

        }
        //移动画布
        $scope.moveCanvas = function(e){
            if(!vm.moveCanvasView) return;
            var moveCanvasInfo = angular.copy(vm.moveCanvasInfo);
            moveCanvas(e,function(x,y){
                $timeout(function(){
                    vm.moveCanvasInfo.x = moveCanvasInfo.x + x;
                    vm.moveCanvasInfo.y = moveCanvasInfo.y + y;
                })

            })
        }
        //画布位置x
        $scope.canvasCoordX = function(width){
            if(!width) return 0;
            var canvasWidth = $("#canvas").width();
            var x = (canvasWidth - width) / 2 + vm.moveCanvasInfo.x;

            return x + "px";
        }
        //画布位置Y
        $scope.canvasCoordY = function(height){
            if(!height) return 0;
            var canvasHeight = $("#canvas").height();
            var y = (canvasHeight - height) / 2 + vm.moveCanvasInfo.y;
            return y + "px";
        }
        /**
         * 画布放大、缩小、还原
         * @param {*} type  0 还原 1缩小 2放大  3原尺寸
         */
        $scope.changeCanvasSize = function(type){
            $timeout(function(){
                //步长
                var stepSize = 0.02;
                //还原
                if(type === 0){
                    initWindow();
                    vm.initMoveCanvasInfo();
                }
                //1:1 原尺寸
                if(type === 3){
                    vm.ratio = 1;
                    vm.initMoveCanvasInfo();
                }
                // 缩小
                if(type === 1){
                    vm.ratio = vm.ratio - stepSize;
                    //限制最小缩放比不能小于0.5倍的初始尺寸
                    if(vm.ratio < (0.5 * vm.initRatio)) vm.ratio = 0.5 * vm.initRatio;
                }
                //放大
                if(type === 2){
                    vm.ratio = vm.ratio + stepSize;
                    //限制放大倍数不能大于1.5倍的初始尺寸
                    if(vm.ratio > (1.5 * vm.initRatio)) vm.ratio = 1.5 * vm.initRatio;
                }
            })
        }
        //鼠标滚轮事件
        function originalEventRatio(){
            if (event.ctrlKey === true || event.metaKey) {
                var wheelDelta = event.wheelDelta ? (event.wheelDelta / 120) : (-event.detail / 3);
                if (wheelDelta > 0) {
                    $scope.changeCanvasSize(2);
                } else {
                    $scope.changeCanvasSize(1);
                }
                $scope.$apply();
                event.preventDefault();
            }
        }
        //鼠标滚轮事件
        window.addEventListener('mousewheel',originalEventRatio,{passive: false});
        $scope.$on('$destroy',function () {
            //取消监听事件
            window.removeEventListener('mousewheel',originalEventRatio)
        });
        function xmlCopy(){
            var _level = "";
            if(vm.xmlSelect.type == 'imagebox'){
                _level = angular.copy(vm.data.document.levels.level[vm.xmlSelect.index]);
                if(_level){
                    _level.imagebox.geometry.x = Number(_level.imagebox.geometry.x) + Number(10);
                    _level.imagebox.geometry.y = Number(_level.imagebox.geometry.y) + Number(10);
                    _level.seq = Number(vm.data.document.levels.level.length) + Number(1);
                    vm.data.document.levels.level.push(_level)
                }
                cookie()
            }
        }
        //上移一层
        $scope.seqUp = function($event){
            seqTier('seqUp');
            fn($event)
        }
        //下移一层
        $scope.seqDown = function($event){
            seqTier('seqDown');
            fn($event)
        }
        //置顶
        $scope.seqStick = function($event){
            seqTier('seqStick');
            fn($event)
        }
        //置底
        $scope.seqBottom = function($event){
            seqTier('seqBottom');
            fn($event)
        }
        function seqTier(data){
            if(vm.xmlSelect.type == 'imagebox'){
                for(var i=0; i<vm.data.document.levels.level.length; i++){
                    if(vm.data.document.levels.level[i].imagebox) {
                        var _imageart = vm.data.document.levels.level[i].imagebox;
                        if (i === vm.xmlSelect.index) {
                            var _seq = Number(vm.data.document.levels.level.length) + Number(1);
                            if(data == 'seqUp'){
                                if(vm.data.document.levels.level[i].seq >= _seq){
                                    MessageService.notice("已在最顶层");
                                    return
                                }
                                vm.data.document.levels.level[i].seq > _seq ? _seq : vm.data.document.levels.level[i].seq++;
                            }
                            if(data == 'seqDown'){
                                if(vm.data.document.levels.level[i].seq == 0){
                                    MessageService.notice("已在最底层");
                                    return
                                }
                                vm.data.document.levels.level[i].seq < 1 ? 0 : vm.data.document.levels.level[i].seq--
                            }
                            if(data == 'seqStick'){
                                if(vm.data.document.levels.level[i].seq >= _seq){
                                    MessageService.notice("已在最顶层");
                                    return
                                }
                                vm.data.document.levels.level[i].seq = _seq
                            }
                            if(data == 'seqBottom'){
                                if(vm.data.document.levels.level[i].seq == 0){
                                    MessageService.notice("已在最底层");
                                    return
                                }
                                vm.data.document.levels.level[i].seq = 0
                            }
                            break
                        }
                    }
                }
            }
            cookie()
        }

        //删除
        $scope.xmlDelete = function($event){
            if (!$scope.selectBox) {
                MessageService.notice('请选择一个相框')
                return
            }
            xmlDelete()
            fn($event)
        }
        function xmlDelete(){
            if(vm.xmlSelect.type == 'imagebox'){
                for(var i=0; i<vm.data.document.levels.level.length; i++){
                    if(vm.data.document.levels.level[i].imagebox){
                        var _imageart = vm.data.document.levels.level[i].imagebox;
                        if(i === vm.xmlSelect.index){
                            vm.data.document.levels.level.splice(i, 1)
                            $scope.selectBox = null;
                            vm.xmlSelect.index = null;
                            break
                        }
                    }
                }
                vm.xmlSelect.type = "";
                vm.xmlSelect.index = '';
                cookie()
            }
        }
        //上一步
        $scope.xmlRevocation = function($event){
            $timeout(function () {
                if(vm.cookies.length<2){
                    vm.data = angular.copy(vm.dataInit);
                    vm.cookies=[];
                    return
                }
                vm.data = vm.cookies[vm.cookies.length-2];
                vm.cookies.splice(1)
            })
            clear();
            fn($event)
        };
        //恢复
        $scope.xmlNextStep = function($event){
            $timeout(function () {
                vm.data = angular.copy(vm.dataInit);
                vm.cookies = [];
                initWindow()
                angular.forEach(vm.data.document.levels.level, function (level, index) {
                    if(level.imagebox){
                        level.imagebox.geometry = {
                            width: level.imagebox.geometry.width,
                            height: level.imagebox.geometry.height,
                            x: level.imagebox.geometry.x,
                            y: level.imagebox.geometry.y
                        };
                        maskScale(vm.data.document.levels.level[index].imagebox, index, level.imagebox.mask.resource.identifier)
                    }
                });

                $scope.$digest();
            })
            clear()
            fn($event)
        };
        vm.isFullScreen = false;
        function getFullState(){
            $timeout(function () {
                vm.isFullScreen = Math.abs(window.screen.height-window.document.documentElement.clientHeight) <= 17;
            })
        }
        getFullState();
        //全屏
        $scope.fullScreen = function(){
            var el = document.documentElement
            var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen
            if (rfs) { // typeof rfs != "undefined" && rfs
                rfs.call(el)
            }else if (typeof window.ActiveXObject !== 'undefined') {
                // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
                var wscript = new ActiveXObject('WScript.Shell')
                if (wscript != null) {
                    wscript.SendKeys('{F11}')
                }
            }
        }
        $scope.exitScreen = function(){
            var el = document
            var cfs = el.cancelFullScreen || el.mozCancelFullScreen || el.msExitFullscreen || el.webkitExitFullscreen || el.exitFullscreen
            if (cfs) { // typeof cfs != "undefined" && cfs
                cfs.call(el)
            } else if (typeof window.ActiveXObject !== 'undefined') {
                // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
                var wscript = new ActiveXObject('WScript.Shell')
                if (wscript != null) {
                    wscript.SendKeys('{F11}')
                }
            }
        }
        //缓存每一步
        vm.operation = false;
        function cookie(){
            var _data = angular.copy(vm.data);
            vm.cookies.push(_data);
            vm.operation = true;
        }

        function addElement(type, data) {
            var _seq = Number(vm.data.document.levels.level.length) + Number(1);
            if(type == 'imagebox'){
                var _level = {
                    seq: _seq,
                    imagebox: null
                }
                _level.imagebox = data;
                vm.data.document.levels.level.push(_level);
            }
        }
        //保存至作品
        $scope.save = function () {
            vm.xmlSelect.index = null;
            $scope.selectBox = null;
            $timeout(function () {
                var _data = angular.copy(vm.data);
                _data.document = selectCancel();
                _data.quantity = _data.document.levels.level.length;
                var nodes = document.getElementById('c-xml');
                html2canvas_adapt(nodes, {
                    onrendered: function(canvas) {
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);

                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', 'cover-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                _data.cover = vm.url + data.key;
                                //_data.name = _data.document.mediabox.width.toFixed(2) + '×' + _data.document.mediabox.height.toFixed(2) + 'mm';
                                if(_data.id){
                                    PicLayoutXmls.update(_data, function (res) {
                                        MessageService.success("保存成功");
                                        $state.go('picture')
                                    })
                                }else{
                                    PicLayoutXmls.save(_data, function (res) {
                                        MessageService.success("保存成功");
                                        $state.go('picture')
                                    })
                                }
                                $scope.$digest();
                            },
                            error: function (request) {
                                MessageService.error("文件上传失败");
                            }
                        });
                    },
                    letterRendering: true,
                    useCORS: true,
                    logging: false
                });
            })
        }

        function selectCancel() {
            var _document = angular.copy(vm.data.document);
            _document.mediabox.width = transitionMm(_document.mediabox.width);
            _document.mediabox.height = transitionMm(_document.mediabox.height);
            angular.forEach(_document.levels.level, function (level, index) {
                if(level.imagebox){
                    level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                    level.imagebox.geometry = {
                        width: transitionMm(level.imagebox.geometry.width),
                        height: transitionMm(level.imagebox.geometry.height),
                        x: transitionMm(level.imagebox.geometry.x),
                        y: transitionMm(level.imagebox.geometry.y)
                    };
                }
            });
            return _document
        }

        //mm转px
        function transitionPx(item) {
            return Number(item) * vm.windowDpiW / 25.4;
        }
        //px转mm
        function transitionMm(item) {
            return Number(item) * 25.4 / vm.windowDpiW;
        }
        // $scope.out = function () {
        //     MessageService.confirm({
        //         title:'提示',
        //         cancel:'取消',
        //         confirm:'确认',
        //         msg:'确认要退出吗？'
        //     },function(){
        //         $state.go('picture');
        //     })
        // }
        $scope.out = function () {
            if(!vm.operation){
                history.go(-1)
            }else{
                MessageService.confirm({
                    title:'温馨提示',
                    goback:'取消',
                    cancel:'不保存',
                    confirm:'保存',
                    msg:'退出前是否需要保存已变更的内容?',
                },function(){
                    $scope.save();
                },function (flag) {
                    if(flag){
                        history.go(-1)
                    }
                })
            }
        }
        function clear() {
            vm.isFont = false;
            vm.xmlSelect = {
                type: ''
            };
        }
        function dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }
         // 圆角设置
         vm.isRoundedSet = false;
         $scope.roundedSet = function(attr){
            if (!$scope.selectBox) {
                MessageService.notice('请选择一个相框')
                return
            }
             toolbarStatus();
             vm.isRoundedSet = attr;
             vm.borderRadiusAllbtn = false
         }

         // 边框设置
         vm.isBorderSet = false;
         $scope.borderSet = function(attr){
            if (!$scope.selectBox) {
                MessageService.notice('请选择一个相框')
                return
            }
             toolbarStatus();
             vm.isBorderSet = attr;
         }

         // 阴影设置
         vm.isShadowSet = false;
         $scope.shadowSet = function(attr){
            if (!$scope.selectBox) {
                MessageService.notice('请选择一个相框')
                return
            }
             toolbarStatus();
             vm.isShadowSet = attr;
         }
        // 透明度
        vm.isTransp = false;
        $scope.transparencySelect = function (e,attr) {
            if (!$scope.selectBox) {
                MessageService.notice('请选择一个相框')
                return
            }
            fn(e);
            toolbarStatus();
            vm.isTransp = attr;
            if(vm.isTransp){
                vm.transparency1 = Math.round($scope.selectBox.transparency*100);
            }
        }
        $scope.changeTransparency = function($event){
            fn($event)
            vm.transparency1 = Math.round($scope.selectBox.transparency*100);
        }
        $scope.transparencyChange1 = function($event){
            fn($event);
            $timeout(function () {
                if(vm.transparency1 === '' || vm.transparency1 === null || vm.transparency1<0){
                    vm.transparency1 = 0;
                }
                if(vm.transparency1 > 100){
                    vm.transparency1 = 100;
                }
                $scope.selectBox.transparency = vm.transparency1 / 100;
            })
        }
        $scope.coverageSelect = function (attr) {
            if (!$scope.selectBox) {
                MessageService.notice('请选择一个相框')
                return
            }
            toolbarStatus();
            vm.isCoverage = attr;
        }
         function toolbarStatus() {
            vm.isOperationDir = false;
            vm.isOperationSpacing = false;
            vm.isTransp = false;
            vm.isCoverage = false;
            vm.isRoundedSet = false;
            vm.isBorderSet = false;
            vm.isShadowSet = false;
            vm.isOperationColor = false;
            $scope.isAlignHover = false;
        }
        vm.borderRadiusAllbtn = false
        $scope.borderRadiusAll = function () {
            $scope.selectBox.border.radius.lt = $scope.selectBox.border.radius.all;
            $scope.selectBox.border.radius.rt = $scope.selectBox.border.radius.all;
            $scope.selectBox.border.radius.rb = $scope.selectBox.border.radius.all;
            $scope.selectBox.border.radius.lb = $scope.selectBox.border.radius.all;
        }
        $scope.borderRadiusAllbtn = function(event){
            fn(event);
            vm.borderRadiusAllbtn = true;
            $scope.selectBox.border.radius.rt = $scope.selectBox.border.radius.lt;
            $scope.selectBox.border.radius.rb = $scope.selectBox.border.radius.lt;
            $scope.selectBox.border.radius.lb = $scope.selectBox.border.radius.lt;
            $scope.selectBox.border.radius.all = $scope.selectBox.border.radius.lt
        }

        $scope.changeFilletType = function(type){
            if ($scope.selectBox.border.radius.filletType == type) {
                return
            }
            $scope.selectBox.border.radius.filletType = type;
            switchToRoundedMode($scope.selectBox,vm.borderRadiusAllbtn);//切换圆角显示模式
        }
        $scope.borderRadius = function(event){
            fn(event);
        }

        $scope.imageboxWRadius = function (imagebox, radius, ratio) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ? (_radius + Number(imagebox.border.lineWidth)/2) * ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.lineTypeChenge = function(lineType){
            vm.data.document.levels.level[vm.xmlSelect.index].imagebox.border.lineType = lineType;
        }
        $scope.dropShadow = function(imagebox,radius){
            if (imagebox.shadow==null) {
                imagebox.shadow = {
                    color:'#777777',
                    x:0,
                    y:0,
                    blur:0
                }
            }
            if (imagebox.border == null) {
                imagebox.border = {
                    shape: 'Rectangle',
                    radius: {
                        lt: 0,
                        rt: 0,
                        rb: 0,
                        lb: 0,
                        all:0,
                        filletType: "Fixed"
                    },
                    "edge": {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                    lineWidth: 0,
                    type: "Color",
                    lineType:"solid",
                    resource: {
                        "identifier": ''
                    },
                    color: '#000000',
                }
            }
            var width = imagebox.geometry.width * radius;
            var height = imagebox.geometry.height * radius;
            return dropShadow(imagebox,width,height)
        }


        $scope.moreDisplay = function(){
            vm.moreDisplay = !vm.moreDisplay;
        }

        function updateUnitImagebox(item){
            if (vm.unit == 'mm') {
                vm.attribute.imagebox = {
                    width: PxTurnMm(item.geometry.width).toFixed(2) - 0,
                    height: PxTurnMm(item.geometry.height).toFixed(2) - 0,
                    x: PxTurnMm(item.geometry.x).toFixed(2) - 0,
                    y: PxTurnMm(item.geometry.y).toFixed(2) - 0,
                }
            } else if (vm.unit == 'px') {
                vm.attribute.imagebox = {
                    width: PxTurnMm(item.geometry.width / 25.4 * vm.windowDpiW).toFixed(2)- 0,
                    height: PxTurnMm(item.geometry.height / 25.4 * vm.windowDpiW).toFixed(2)- 0,
                    x: PxTurnMm(item.geometry.x / 25.4 * vm.windowDpiW).toFixed(2)- 0,
                    y: PxTurnMm(item.geometry.y / 25.4 * vm.windowDpiW).toFixed(2)- 0,
                }
            } else if (vm.unit == 'in') {
                vm.attribute.imagebox = {
                    width: PxTurnIn(item.geometry.width),
                    height: PxTurnIn(item.geometry.height),
                    x: PxTurnIn(item.geometry.x),
                    y: PxTurnIn(item.geometry.y),
                }
            }
        }

        $scope.updateBoxParameter = function () {
            var box = {}, value = {};
            if (vm.xmlSelect.type == 'imagebox') {
                box = $scope.selectBox;
                value = vm.attribute.imagebox;
            }
            if (box.lock) {
                return
            }

            if (vm.unit == 'mm') {
                box.geometry = {
                    width: MmTurnPx(value.width),
                    height: MmTurnPx(value.height),
                    x: MmTurnPx(value.x),
                    y: MmTurnPx(value.y)
                }
            }
            if (vm.unit == 'px') {
                box.geometry = value;
            }
        };

        function keycodeArrow(keyCode) {
            var temp = JSON.parse(localStorage.getItem('singleArray'));
            var levels = vm.data.document.levels;
            var level = levels.level[vm.xmlSelect.index];
            var currentPage = vm.data.document;
            var all = vm.ctrlKey;

            var len = all.length;
            var box = [], value = {};
            if (!len) {
                if ($scope.selectBox) {
                    box = level.imagebox;
                    value = angular.copy(vm.attribute.imagebox);
                }
                if (box.lock) {
                    return
                }

                var maxHeightPx = parseFloat(currentPage.mediabox.height - box.geometry.height).toFixed(2) - 0;
                var maxWidthPx = parseFloat(currentPage.mediabox.width - box.geometry.width).toFixed(2) - 0;
                $timeout(function(){
                    // 上
                    if (keyCode == 38) {
                        if (vm.unit == 'px') {
                            if (value.y >= 1) {
                                value.y--;
                                box.geometry.y = MmTurnPx(value.y / vm.windowDpiH * 25.4);
                            }
                        } else if (vm.unit == 'mm') {
                            if (value.y >= 1) {
                                value.y -= 0.2;
                                box.geometry.y = MmTurnPx(value.y);
                            }
                        } else if (vm.unit == 'in') {
                            if (value.y >= 1) {
                                value.y -= 0.2;
                                box.geometry.y = InTurnPx(value.y);
                            }
                        }
                    }
                    // 下
                    if (keyCode == 40) {
                        if (vm.unit == 'px') {
                            if (value.y < maxHeightPx) {
                                value.y++;
                                box.geometry.y = MmTurnPx(value.y / vm.windowDpiH * 25.4);
                            }
                        } else if (vm.unit == 'mm') {
                            if (value.y < PxTurnMm(currentPage.mediabox.height - box.geometry.height)) {
                                value.y += 0.2;
                                box.geometry.y = MmTurnPx(value.y);
                            }
                        } else if (vm.unit == 'in') {
                            if (value.y < PxTurnIn(currentPage.mediabox.height - box.geometry.height)) {
                                value.y += 0.2;
                                box.geometry.y = InTurnPx(value.y);
                            }
                        }
                    }
                    // 左
                    if (keyCode == 37) {
                        if (vm.unit == 'px') {
                            if (value.x >= 1) {
                                value.x--;
                                box.geometry.x = MmTurnPx(value.x / vm.windowDpiW * 25.4);
                            }
                        } else if (vm.unit == 'mm') {
                            if (value.x >= 1) {
                                value.x -= 0.2;
                                box.geometry.x = MmTurnPx(value.x);
                            }
                        } else if (vm.unit == 'in') {
                            if (value.x >= 1) {
                                value.x -= 0.2;
                                box.geometry.x = InTurnPx(value.x);
                            }
                        }
                    }
                    // 右
                    if (keyCode == 39) {
                        if (vm.unit == 'px') {
                            if (box.geometry.x < maxWidthPx) {
                                value.x++;
                                box.geometry.x = MmTurnPx(value.x / vm.windowDpiW * 25.4);
                            }
                        } else if (vm.unit == 'mm') {
                            if (value.x < PxTurnMm(maxWidthPx)) {
                                value.x += 0.2;
                                box.geometry.x = MmTurnPx(value.x);
                            }
                        } else if (vm.unit == 'In') {
                            if (value.x < PxTurnIn(maxWidthPx)) {
                                value.x += 0.2;
                                box.geometry.x = InTurnPx(value.x);
                            }
                        }
                    }

                    updateUnitImagebox(box)
                })
            } else {
                for (var i = len - 1; i >= 0; i--) {
                    if (!all[i]) {
                        continue;
                    }
                    var box = [];
                    if (all[i]) {
                        box = all[i];
                    }
                    if (box.lock) {
                        return
                    }
                    var maxHeightPx = parseFloat(currentPage.mediabox.height - box.geometry.height).toFixed(2) - 0;
                    var maxWidthPx = parseFloat(currentPage.mediabox.width - box.geometry.width).toFixed(2) - 0;
                    if (keyCode == 38) {
                        if (vm.unit == 'px') {
                            if (box.geometry.y >= 1) {
                                box.geometry.y--;
                            }
                        } else if (vm.unit == 'mm') {
                            var y = PxTurnMm(box.geometry.y);
                            if (y >= 1) {
                                y -= 0.2;
                                box.geometry.y = MmTurnPx(y);
                            }
                        } else if (vm.unit == 'in') {
                            var y = PxTurnIn(box.geometry.y);
                            if (y >= 1) {
                                y -= 0.2;
                                box.geometry.y = InTurnPx(y);
                            }
                        }
                    }
                    // 下
                    if (keyCode == 40) {
                        if (vm.unit == 'px') {
                            if (box.geometry.y < maxHeightPx) {
                                box.geometry.y++;
                            }
                        } else if (vm.unit == 'mm') {
                            var y = PxTurnMm(box.geometry.y);
                            if (y < PxTurnMm(maxHeightPx)) {
                                y += 0.2;
                                box.geometry.y = MmTurnPx(y);
                            }
                        }
                    }
                    // 左
                    if (keyCode == 37) {
                        if (vm.unit == 'px') {
                            if (box.geometry.x >= 1) {
                                box.geometry.x--;
                            }
                        } else if (vm.unit == 'mm') {
                            var x = PxTurnMm(box.geometry.x)
                            if (x >= 1) {
                                x -= 0.2;
                                box.geometry.x = MmTurnPx(x);
                            }
                        } else if (vm.unit == 'in') {
                            var x = PxTurnIn(box.geometry.x)
                            if (x >= 1) {
                                x -= 0.2;
                                box.geometry.x = InTurnPx(x);
                            }
                        }
                    }
                    // 右
                    if (keyCode == 39) {
                        if (vm.unit == 'px') {
                            if (box.geometry.x < maxWidthPx) {
                                box.geometry.x++;
                            }
                        } else if (vm.unit == 'mm') {
                            var x = PxTurnMm(box.geometry.x);
                            if (x < PxTurnMm(maxWidthPx)) {
                                x += 0.2;
                                box.geometry.x = MmTurnPx(x);
                            }
                        } else if (vm.unit == 'In') {
                            var x = PxTurnIn(box.geometry.x);
                            if (x < PxTurnIn(maxWidthPx)) {
                                x += 0.2;
                                box.geometry.x = InTurnPx(x);
                            }
                        }
                    }
                    updateUnitImagebox(all[i])
                    $scope.$apply();
                }
            }
        }

        $scope.singleBoxAlignment = function (attr) {
            var all = vm.ctrlKey;
            if(all.length >= 2){
                $scope.boxAlignment(attr)
                return
            }
            if (!$scope.selectBox.geometry) {
                MessageService.notice("请选择一个元素");
                return
            }
            if ($scope.selectBox.lock) {
                MessageService.notice("请先解锁该元素");
                return
            }
            if (attr == 'left') {
                $scope.selectBox.geometry.x = elementAlign($scope.selectBox.geometry , $scope.selectBox.rotation.angle , 'left' , vm.data.document.mediabox.width , vm.data.document.mediabox.height);
            } else if (attr == 'right') {
                $scope.selectBox.geometry.x = elementAlign($scope.selectBox.geometry , $scope.selectBox.rotation.angle , 'right' , vm.data.document.mediabox.width , vm.data.document.mediabox.height);
            } else if (attr == 'top') {
                $scope.selectBox.geometry.y = elementAlign($scope.selectBox.geometry , $scope.selectBox.rotation.angle , 'top' , vm.data.document.mediabox.width , vm.data.document.mediabox.height);
            } else if (attr == 'bottom') {
                $scope.selectBox.geometry.y = elementAlign($scope.selectBox.geometry , $scope.selectBox.rotation.angle , 'bottom' , vm.data.document.mediabox.width , vm.data.document.mediabox.height);
            } else if (attr == 'vertical') {
                $scope.selectBox.geometry.y = (vm.data.document.mediabox.height - $scope.selectBox.geometry.height) / 2;
            } else {
                $scope.selectBox.geometry.x = (vm.data.document.mediabox.width - $scope.selectBox.geometry.width) / 2;
            }
            updateUnitImagebox($scope.selectBox)
            cookie();
        }

        //元素四角拖拽
        $scope.cursor = function (angle, source, boolean) {
            var _cursor = '';
            if (angle > 0 && !boolean) {
                angle = 360 - angle;
            }
            if (source == 'left' || source == 'right') {
                if (337 <= angle || angle < 22) {
                    _cursor = 'se-resize';
                }
                if (22 <= angle && angle < 67) {
                    _cursor = 'n-resize';
                }
                if (67 <= angle && angle < 112) {
                    _cursor = 'sw-resize';
                }
                if (112 <= angle && angle < 157) {
                    _cursor = 'e-resize';
                }
                if (157 <= angle && angle < 202) {
                    _cursor = 'se-resize';
                }
                if (202 <= angle && angle < 247) {
                    _cursor = 'n-resize';
                }
                if (247 <= angle && angle < 292) {
                    _cursor = 'sw-resize';
                }
                if (292 <= angle && angle < 337) {
                    _cursor = 'e-resize';
                }
            }
            if (source == 'top' || source == 'bottom') {
                if (337 <= angle || angle < 22) {
                    _cursor = 'sw-resize';
                }
                if (22 <= angle && angle < 67) {
                    _cursor = 'e-resize';
                }
                if (67 <= angle && angle < 112) {
                    _cursor = 'se-resize';
                }
                if (112 <= angle && angle < 157) {
                    _cursor = 'n-resize';
                }
                if (157 <= angle && angle < 202) {
                    _cursor = 'sw-resize';
                }
                if (202 <= angle && angle < 247) {
                    _cursor = 'e-resize';
                }
                if (247 <= angle && angle < 292) {
                    _cursor = 'se-resize';
                }
                if (292 <= angle && angle < 337) {
                    _cursor = 'n-resize';
                }
            }
            if (source == 'left1' || source == 'right1') {
                if (337 <= angle || angle < 22) {
                    _cursor = 'e-resize';
                }
                if (22 <= angle && angle < 67) {
                    _cursor = 'se-resize';
                }
                if (67 <= angle && angle < 112) {
                    _cursor = 'n-resize';
                }
                if (112 <= angle && angle < 157) {
                    _cursor = 'sw-resize';
                }
                if (157 <= angle && angle < 202) {
                    _cursor = 'e-resize';
                }
                if (202 <= angle && angle < 247) {
                    _cursor = 'se-resize';
                }
                if (247 <= angle && angle < 292) {
                    _cursor = 'n-resize';
                }
                if (292 <= angle && angle < 337) {
                    _cursor = 'sw-resize';
                }
            }
            if (source == 'top1' || source == 'bottom1') {
                if (337 <= angle || angle < 22) {
                    _cursor = 'n-resize';
                }
                if (22 <= angle && angle < 67) {
                    _cursor = 'sw-resize';
                }
                if (67 <= angle && angle < 112) {
                    _cursor = 'e-resize';
                }
                if (112 <= angle && angle < 157) {
                    _cursor = 'se-resize';
                }
                if (157 <= angle && angle < 202) {
                    _cursor = 'n-resize';
                }
                if (202 <= angle && angle < 247) {
                    _cursor = 'sw-resize';
                }
                if (247 <= angle && angle < 292) {
                    _cursor = 'e-resize';
                }
                if (292 <= angle && angle < 337) {
                    _cursor = 'se-resize';
                }
            }
            return _cursor
        }

        $scope.resizeStartLeft = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'leftTop', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {

            });
        }
        $scope.resizeStartTop = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'rightTop', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {

            });
        }
        $scope.resizeStartBottom = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'leftBottom', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {

            });
        }
        $scope.resizeStartLeft1 = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'left', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {

            });
        }
        $scope.resizeStartTop1 = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'top', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {

            });
        }
        $scope.resizeStartRight1 = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'right', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {

            });
        }
        $scope.resizeStartBottom1 = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'bottom', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {

            });
        }
        $scope.resizeStartRigth = function ($event, item, pro, index) {
            if (item.lock) {
                return
            }
            fn($event);
            editorBoxZoom($event, item, 'rightBottom', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$apply();
            }, function (it) {

            });
        }
        $timeout(function(){
            $('#navId').on('scroll', function(event){
                if(vm.navPitch == 'imagebox'){
                    var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                    if(!vm.isBgImgData){
                        if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                            $scope.bgImgPageing();
                        }
                    }
                }
            });
        })

        $scope.fn = fn;
        $scope.rangeShadowX = function(x){
            var X = Number(x) * 0.5 + 50;
            return X +'% 100%'
        }
        $scope.rangeShadowY = function(y){
            var Y = Number(y) * 0.5 + 50;
            return Y +'% 100%'
        }
    }

})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TextXmlEditController', TextXmlEditController);

    TextXmlEditController.$inject = ['$state', '$scope', 'UidService', 'ProductXml', 'ParseLinks', 'AlertService', 'MessageService', '$uibModal', 'FontManagement', 'Qinius', '$q', '$timeout', 'FontXmls', '$stateParams', 'ImageService', 'ClipArts', 'Backgrounds', '$http', 'ThemeClassify'];

    function TextXmlEditController($state, $scope, UidService, ProductXml, ParseLinks, AlertService, MessageService, $uibModal, FontManagement, Qinius, $q, $timeout, FontXmls, $stateParams, ImageService, ClipArts, Backgrounds, $http, ThemeClassify) {
        var vm = this;
        vm.loading = true;
        vm.menuImageView = menuImageView;
        vm.materialUnfold = false;
        vm.chineseSupport = true;
        //是否是拖曳画布状态
        vm.moveCanvasView = false;
        vm.moveCanvasInfo = {
            x:0,
            y:0
        }
        //初始化画布移动信息
        vm.initMoveCanvasInfo = function(){
            vm.moveCanvasInfo = {
                x:0,
                y:0
            }
        }
        $scope.materialUnfold = function(){
            vm.materialUnfold = !vm.materialUnfold;
        }
        if($stateParams.id){
            vm.data = FontXmls.get({id : $stateParams.id});
        }else{
            vm.textPage = JSON.parse(localStorage.textPage);
            vm.data = {
                name: vm.textPage.name,
                cover: '',
                identifier: '',
                textDocument: {
                    movable: true,
                    editable: true,
                    rotation: {angle: 0},
                    background: {
                        resource: {
                            provider: '',
                            identifier: '',
                            width: '',
                            height: '',
                        },
                        offsetx: '0',
                        offsety: '0',
                        adaptation: 'Width',
                        type: 'FontXml',
                        color: '',
                        scale: '1'
                    },
                    geometry: {
                        x: '0',
                        y: '0',
                        width: vm.textPage.width,
                        height: vm.textPage.height
                    },
                    levels: {
                        level: [
                            // {
                            //     seq: '1',
                            //     textbox: {
                            //             geometry: {
                            //                 x: '0',
                            //                 y: '0',
                            //                 width: '',
                            //                 height: ''
                            //             },
                            //             rotation: {
                            //                 angle: '0'
                            //             },
                            //             text: '',
                            //             style: {
                            //                 font: '',
                            //                 size: '',
                            //                 color: '',
                            //                 bold: '',
                            //                 italic: '',
                            //                 lineheight: '',
                            //                 letterspacing: '',
                            //             },
                            //             identifier: '',
                            //             editable: false,
                            //             movable: false,
                            //             version: false,
                            //             transparency: '1',
                            //         },
                            //     imageart: {
                            //             movable: false,
                            //             editable: false,
                            //             onlyshow: false,
                            //             offsetX: '10',
                            //             offsetY: '10',
                            //             transparency: '1',
                            //             geometry: {
                            //                 x: '0',
                            //                 y: '0',
                            //                 width: '',
                            //                 height: ''
                            //             },
                            //             rotation: {
                            //                 angle: '0'
                            //             },
                            //             resource: {
                            //                 provider: '',
                            //                 identifier: '',
                            //             },
                            //         }
                            // }
                        ]
                    }
                }
            }
        }
        ThemeClassify.getAllByCondition4({type:'CLIPART'}, function (res) {
            vm.themeClassifies = res.message;
        });

        $scope.domains = Qinius.getDomain();
        vm.fileSelect = [
            {name: 'rain', id:'1'}
        ];
        vm.navList = [
            {img:'/content/images/icons/nav_font.svg', name: '字体', id:'font'},
            {img:'/content/images/icons/nav_font.svg', name: '背景', id:'background'},
            {img:'/content/images/icons/nav_font.svg', name: '素材', id:'material'}
        ]
        vm.navPitch = 'font';
        vm.units = [
            { name: 'px', id: 'px'},
            { name: 'mm', id: 'mm'}
        ];
        vm.xmlSelect = {
            type: '',
            transparency: 0,
            editable: true
        };
        vm.font = {
            geometry: {
                x: '0',
                y: '0',
                width: '',
                height: ''
            },
            rotation: {
                angle: '0'
            },
            text: '',
            style: {
                font: '',
                size: '',
                color: '',
                bold: '',
                italic: '',
                lineheight: '',
                letterspacing: '',
            },
            identifier: '',
            editable: true,
            movable: true,
            lock: false,
            version: '2.0',
            transparency: '1'
        };
        vm.sizeList = [
            {name: '6', id: '6'},
            {name: '7', id: '7'},
            {name: '8', id: '8'},
            {name: '9', id: '9'},
            {name: '10', id: '10'},
            {name: '11', id: '11'},
            {name: '12', id: '12'},
            {name: '13', id: '13'},
            {name: '14', id: '14'},
            {name: '15', id: '15'},
            {name: '16', id: '16'},
            {name: '17', id: '17'},
            {name: '18', id: '18'},
            {name: '19', id: '19'},
            {name: '20', id: '20'},
            {name: '21', id: '21'},
            {name: '22', id: '22'},
            {name: '23', id: '23'},
            {name: '24', id: '24'},
            {name: '25', id: '25'},
            {name: '26', id: '26'},
            {name: '27', id: '27'},
            {name: '28', id: '28'},
            {name: '29', id: '29'},
            {name: '30', id: '30'},
            {name: '31', id: '31'},
            {name: '32', id: '32'},
            {name: '33', id: '33'},
            {name: '34', id: '34'},
            {name: '35', id: '35'},
            {name: '36', id: '36'},
            {name: '37', id: '37'},
            {name: '38', id: '38'},
            {name: '39', id: '39'},
            {name: '40', id: '40'},
            {name: '41', id: '41'},
            {name: '42', id: '42'},
            {name: '43', id: '43'},
            {name: '44', id: '44'},
            {name: '45', id: '45'},
            {name: '46', id: '46'},
            {name: '47', id: '47'},
            {name: '48', id: '48'},
            {name: '49', id: '49'},
            {name: '50', id: '50'},
            {name: '51', id: '51'},
            {name: '52', id: '52'},
            {name: '53', id: '53'},
            {name: '54', id: '54'},
            {name: '55', id: '55'},
            {name: '56', id: '56'},
            {name: '57', id: '57'},
            {name: '58', id: '58'},
            {name: '59', id: '59'},
            {name: '60', id: '60'},
            {name: '61', id: '61'},
            {name: '62', id: '62'},
            {name: '63', id: '63'},
            {name: '64', id: '64'},
            {name: '65', id: '65'},
            {name: '66', id: '66'},
            {name: '67', id: '67'},
            {name: '68', id: '68'},
            {name: '69', id: '69'},
            {name: '70', id: '70'},
            {name: '71', id: '71'},
            {name: '72', id: '72'},
            {name: '73', id: '73'},
            {name: '74', id: '74'},
            {name: '75', id: '75'},
            {name: '76', id: '76'},
            {name: '77', id: '77'},
            {name: '78', id: '78'},
            {name: '79', id: '79'},
            {name: '80', id: '80'},
            {name: '81', id: '81'},
            {name: '82', id: '82'},
            {name: '83', id: '83'},
            {name: '84', id: '84'},
            {name: '85', id: '85'},
            {name: '86', id: '86'},
            {name: '87', id: '87'},
            {name: '88', id: '88'},
            {name: '89', id: '89'},
            {name: '89', id: '90'},
            {name: '91', id: '91'},
            {name: '92', id: '92'},
            {name: '93', id: '93'},
            {name: '94', id: '94'},
            {name: '95', id: '95'},
            {name: '96', id: '96'},
            {name: '97', id: '97'},
            {name: '98', id: '98'},
            {name: '99', id: '99'},
            {name: '100', id: '100'},
        ]
        vm.colorList = [
            {name: '#000000', id: '#000000'},
            {name: '#666666', id: '#666666'},
            {name: '#a8a8a8', id: '#a8a8a8'},
            {name: '#d9d9d9', id: '#d9d9d9'},
            {name: '#ffffff', id: '#ffffff'},
            {name: '#647c6e', id: '#647c6e'},
            {name: '#ffbd4a', id: '#ffbd4a'},
            {name: '#fef952', id: '#fef952'},
            {name: '#99e264', id: '#99e264'},
            {name: '#36b72a', id: '#36b72a'},
            {name: '#44d9e5', id: '#44d9e5'},
            {name: '#c27171', id: '#c27171'},
            {name: '#2fb3ff', id: '#2fb3ff'},
            {name: '#b760a6', id: '#b760a6'},
        ]
        vm.isFont = false;
        vm.isOperationColor = false;
        vm.cookies = [];
        vm.cookieIndex = 0;
        vm.isUnfold = false;
        vm.isUnfoldRight = false;
        vm.isXmlDblclick = false;
        vm.isImageartTrim = false;
        vm.imageartTrim = null;

        //素材
        vm.materialList = [];
        vm.materialSum = 10;
        vm.materialIndex = 0;
        vm.isMaterialData = false;
        vm.isTheme = "isTheme";
        function materialInit(){
            ClipArts.byCondition({
                items: [{key: "type", op: "=", value: "USER"}],
                page: vm.materialIndex,
                size: vm.materialSum,
                sort: ["id,desc"]
            }, function (res) {
                for(var i=0; i<res.length; i++){
                    if(!res[i].cancelled){
                        res[i].identifier = vm.url + res[i].identifier;
                        vm.materialList.push(res[i])
                    }
                }
                if(res.length < vm.materialSum){
                    vm.isMaterialData = true;
                }
            });
        }
        $scope.themeClassifiesCb1 = function(){
            vm.materialList = [];
            vm.materialSum = 10;
            vm.materialIndex = 0;
            vm.isMaterialData = false;
            vm.isTheme = "isTheme";
            materialInit()
        }
        $scope.themeClassifiesCb = function(item){
            vm.materialList = [];
            vm.materialSum = 10;
            vm.materialIndex = 0;
            vm.isMaterialData = false;
            vm.isTheme = item.id;
            materialInit1()
        }
        function materialInit1(){
            ClipArts.byCondition({
                items: [{key:"themeClassify.id", op:"=",value:vm.isTheme}],
                page: vm.materialIndex,
                size: vm.materialSum,
                sort: ["id,desc"]
            }, function (res) {
                for(var i=0; i<res.length; i++){
                    if(!res[i].cancelled){
                        res[i].identifier = vm.url + res[i].identifier;
                        vm.materialList.push(res[i])
                    }
                    if(res.length < vm.materialSum){
                        vm.isMaterialData = true;
                    }
                }
            }, function (error) {
                vm.isMaterialData = true;
            });
        }
        $scope.materialPageing = function(){
            vm.materialIndex++;
            if(vm.isTheme == 'isTheme'){
                materialInit()
            }else {
                materialInit1()
            }
        }
        //背景
        vm.bgImgList = [];
        vm.bgImgSum = 20;
        vm.bgImgIndex = 0;
        vm.isBgImgData = false;
        vm.showBackgroundsLabel = false;
        function backgroundInit(){
            ThemeClassify.getAllByCondition1({
                items: [{key: "type", op: "=", value: 'BACKGROUND'}, {
                    key: "used",
                    op: "=",
                    value: true
                }], sort: ["seq,asc"]
            }, function (res) {
                vm.backgroundLabels = angular.copy(res);
                vm.backgroundLabels.unshift({
                    id:'',
                    name:'全部'
                });
                $scope.backgroundData(vm.backgroundLabels[0].id)
            });
        }
        $scope.backgroundData = function(id){
            vm.backgroundLabel = id;
            vm.bgImgIndex = 0;
            vm.bgImgList=[];
            backgroundsByCondition();
        }
        function backgroundsByCondition(){
            Backgrounds.byCondition({
                items: [{key: "themeClassify.id", op: "=", value: vm.backgroundLabel}],
                page: vm.bgImgIndex,
                size: vm.bgImgSum,
                sort: ["id,desc"]
            }, function (res, headers) {
                for(var i=0; i<res.length; i++){
                    if(!res[i].cancelled){
                        res[i].identifier = vm.url + res[i].identifier;
                        vm.bgImgList.push(res[i])
                    }
                }
                if(res.length < vm.bgImgSum){
                    vm.isBgImgData = true;
                }
            })
        }
        $scope.bgImgPageing = function(){
            vm.bgImgIndex++;
            backgroundsByCondition()
        }
        $scope.$watch(function() {
            return vm.data.name;
        }, function(newName, oldName) {
            if (newName!==undefined) {
                document.title = newName + '-设计页'
            }
        });
        $q.all([$scope.domains.$promise, vm.data.$promise]).then(function () {
            if($stateParams.id){
                vm.data = unitPx(vm.data);
                vm.textPage = {
                    name: vm.data.name,
                    width: vm.data.textDocument.geometry.width,
                    height: vm.data.textDocument.geometry.height,
                    unit: 'px'
                }
            }
            vm.url = $scope.domains.domain;
            vm.unit = vm.textPage.unit;
            backgroundInit();
            materialInit();
            //获取字体
            vm.fontList = [];
            vm.fontList1 = [];
            FontManagement.getAll3({}, function (res) {
                vm.fontList = angular.copy(res);
                vm.fontList1 = angular.copy(res);
            });
            //获取token
            Qinius.get(function (msg) {
                vm.token = msg.token;
            });
            //初始化像素
            if(vm.unit == 'mm'){
                vm.data.textDocument.geometry.width = (vm.textPage.width/25.4 * vm.windowDpiW).toFixed(2);
                vm.data.textDocument.geometry.height = (vm.textPage.height/25.4 * vm.windowDpiH).toFixed(2);
                //属性栏
                vm.attribute = {
                    product: {
                        width: (vm.data.textDocument.geometry.width/vm.windowDpiW*25.4).toFixed(2),
                        height:(vm.data.textDocument.geometry.height/vm.windowDpiH*25.4).toFixed(2),
                    },
                    background: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    material: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    font: {
                        width: '',
                        x: '',
                        y: '',
                    }
                }
            }
            if (vm.unit == 'px'){
                vm.data.textDocument.geometry.width = vm.textPage.width;
                vm.data.textDocument.geometry.height = vm.textPage.height;
                //属性栏
                vm.attribute = {
                    product: {
                        width: vm.data.textDocument.geometry.width,
                        height:vm.data.textDocument.geometry.height,
                    },
                    background: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    material: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    font: {
                        width: '',
                        x: '',
                        y: '',
                    }
                }
            }
            //背景宽高
            $timeout(function () {
                //作品宽高
                initWindow()
                bgImgInit(true);
                vm.loading = false;
                $timeout(function () {
                    vm.dataInit = angular.copy(vm.data);
                },1000)
            }, 500)
            //下载字体
            if(vm.data.textDocument.levels.level){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var textbox = vm.data.textDocument.levels.level[i].textbox;
                        getFont(textbox)
                    }
                }
            }
        })
        $scope.navCb = function (data) {
            vm.navPitch = data;
            // if(vm.navPitch == data.id){
            //     vm.isUnfold = !vm.isUnfold;
            //     // vm.isUnfoldRight = !vm.isUnfoldRight;
            // }else {
            //     vm.navPitch = data.id;
            //     vm.isUnfold = false;
            //     // vm.isUnfoldRight = false;
            // }
        };
        $scope.unfoldLeft = function(){
            vm.isUnfold = !vm.isUnfold;
        }
        $scope.unfoldRight = function(){
            vm.isUnfoldRight = !vm.isUnfoldRight;
        }
        // 背景图和素材的上传
        var bigSizePics = [];
        window.updatePic = function ($event,item) {
            vm.uploadType = item;
            $scope.$digest();
            var files = $event.target.files;
            bigSizePics = [];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.size >= 20 * 1024 * 1024) {
                    file.imageSize = Math.ceil(file.size / 1024 / 1024);
                    bigSizePics.push(file);
                } else {
                    getBgImg(files, i, item);
                    if (bigSizePics.length == 0 && i == (files.length - 1)) {
                        setTimeout(function () {
                            $scope.$digest();
                        }, 600);
                    }
                }
            }
            if (bigSizePics.length > 0) {
                thumbnailatorPic();
            }
            $event.srcElement.value = ""
        };
        function getBgImg(files, i, item) {
            var formData = new FormData();
            var fileName = files[i].name;
            formData.append('file', files[i]);
            formData.append('unique_names', false);
            if(item == 'background'){
                formData.append('key', 'background-' + UidService.get());
            }else if(item == 'material'){
                formData.append('key', 'clipArt-' + UidService.get());
            }else {
                formData.append('key', 'file-' + UidService.get());
            }
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    if(item == 'background'){
                        Backgrounds.save({
                            identifier: data.key,
                            provider: 'qiniu',
                            name: fileName,
                            type: 'FontXml',
                            themeClassifyId:vm.backgroundLabel
                        }, function (resp) {
                            resp.identifier = vm.url + resp.identifier;
                            vm.bgImgList.unshift(resp);
                        });
                    }
                    if(item == 'material'){
                        ClipArts.createOne({
                            identifier: data.key,
                            provider: 'qiniu'
                        }, function (resp) {
                            // resp.identifier = vm.url + resp.identifier;
                            // vm.materialList.unshift(resp);
                            $scope.themeClassifiesCb1();
                        });
                    }
                    $scope.$apply();
                    i++;
                    //if (i < files.length) {
                        //getBgImg(file, i, item);
                    //}
                },
                error: function (error) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        $scope.bgImgCb = function(data){
            if(vm.textEditor){return}
            vm.data.textDocument.background.resource.identifier = data.identifier;
            vm.data.textDocument.background.offsetx = 0;
            vm.data.textDocument.background.offsety = 0;
            $timeout(function () {
                bgImgInit()
            })
        };
        $scope.bgImgDelete = function ($event, item, index) {
            Backgrounds.delete({id: item.id}, function () {
                vm.bgImgList.splice(index, 1)
            });
            var $event = $event || window.event;
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }
        $scope.materialDelete = function ($event, item, index) {
            var $event = $event || window.event;
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            ClipArts.delete({id: item.id}, function () {
                vm.materialList.splice(index, 1)
            });
        }
        //拖动背景
        var isBgImgMouseDown = false;
        var oldX = 0;
        var oldY = 0;
        //鼠标按下事件
        $scope.bgImgMouseDown = function ($event) {
            var $event = $event || window.event;
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var x = $event.clientX;
            var y = $event.clientY;
            var l = $event.target.offsetParent.offsetLeft;
            var t = $event.target.offsetParent.offsetTop;

            var x1 = $event.target.offsetLeft;
            var y1 = $event.target.offsetTop;
            oldX = $event.target.offsetLeft;
            oldY = $event.target.offsetTop;

            var l1 = -($($event.target).width() - vm.data.textDocument.geometry.width * vm.ratio);
            var t1 = -($($event.target).height() - vm.data.textDocument.geometry.height * vm.ratio);
            //开关打开
            isBgImgMouseDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isBgImgMouseDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = Number(nx - (x - l)) + Number(x1);
                var nt = Number(ny - (y - t)) + Number(y1);

                if(vm.data.textDocument.background.adaptation == 'Height'){
                    // if(nl == 0){ return;}
                    if(nl < l1){nl = Number(l1) + Number(1);}
                    if(nl > 0){nl = 0;}
                    vm.data.textDocument.background.offsetx = nl / vm.ratio;
                    $($event.target).css({
                        left: nl + 'px'
                    });
                    $("#c-xml-bgImg-trim-img").find('img').css({
                        left: nl + 'px'
                    });
                }
                if(vm.data.textDocument.background.adaptation == 'Width'){
                    // if(nt == 0){ return;}
                    if(nt <= t1){nt = Number(t1) + Number(1);}
                    if(nt > 0){nt = 0;}
                    vm.data.textDocument.background.offsety = nt / vm.ratio;
                    $($event.target).css({
                        top: nt + 'px'
                    });
                    $("#c-xml-bgImg-trim-img").find('img').css({
                        top: nt + 'px'
                    });
                }
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isBgImgMouseDown = false;
                $event.stopPropagation();
            }
            cookie()
        }
        $scope.bgImgFalseClick = function () {
            vm.isXmlDblclick = false;
            vm.data.textDocument.background.offsetx = oldX / vm.ratio;
            vm.data.textDocument.background.offsety = oldY / vm.ratio;
            $("#c-xmlbgImg").css({
                top: oldY + 'px',
                left: oldX + 'px'
            });
        }
        $scope.bgImgTrueClick = function () {
            vm.isXmlDblclick = false;
        }
        function bgImgInit(){
            vm.data.textDocument.background.scale = 1;
            getBackgroundSource($http, vm.data.textDocument.background.resource, vm.data.textDocument.geometry.width, vm.data.textDocument.geometry.height, vm.data.textDocument.background, $scope, function () {
                if(vm.unit == 'mm'){
                    vm.attribute.background = {
                        x: (vm.data.textDocument.background.offsetx/vm.windowDpiW*25.4).toFixed(2),
                        y: (vm.data.textDocument.background.offsety/vm.windowDpiH*25.4).toFixed(2)
                    }
                }
                if (vm.unit == 'px'){
                    vm.attribute.background = {
                        x: vm.data.textDocument.background.offsetx,
                        y: vm.data.textDocument.background.offsety
                    }
                }
            });
        }
        //添加素材
        $scope.materialCb = function(data){
            if(vm.textEditor){return}

            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = data.identifier + '?imageView2';
            newImg.onload = function () {
                var _material = {
                    movable: true,
                    lock: false,
                    editable: true,
                    onlyshow: false,
                    offsetx: '0',
                    offsety: '0',
                    transparency: '1',
                    geometry: {
                        x: '0',
                        y: '0',
                        width: '',
                        height: ''
                    },
                    rotation: {
                        angle: '0'
                    },
                    resource: {
                        provider: '',
                        identifier: data.identifier,
                        width:'',
                        height: ''
                    },
                    scale: 1,
                    selected: false
                };
                var bgImgW = newImg.width;
                var bgImgH = newImg.height;
                _material.resource.width = bgImgW;
                _material.resource.height = bgImgH;
                var bgImgR = bgImgW/bgImgH;
                if(bgImgR >= 1){
                    if(bgImgW > vm.data.textDocument.geometry.width){
                        _material.geometry.width = vm.data.textDocument.geometry.width;
                        _material.geometry.height = _material.geometry.width * bgImgH / bgImgW;
                        // _material.scale = vm.data.textDocument.geometry.width / bgImgW;
                    }else {
                        _material.geometry.width = bgImgW;
                        _material.geometry.height = bgImgH;
                    }
                }else {
                    if(bgImgH > vm.data.textDocument.geometry.height){
                        _material.geometry.height = vm.data.textDocument.geometry.height;
                        _material.geometry.width = _material.geometry.height * bgImgW / bgImgH;
                        // _material.scale = vm.data.textDocument.geometry.height / bgImgH;
                    }else {
                        _material.geometry.width = bgImgW;
                        _material.geometry.height = bgImgH;
                    }
                }
                addElement('imageart', _material)
                cookie()
                $scope.$apply();
            };
        }
        //点击素材
        $scope.imageartClick = function ($event, item, index) {
            if(vm.textEditor){return}
            var $event = $event || window.event;
            if ($event.ctrlKey == true || $event.metaKey == true) {
                for(var i=0 ;i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var _font = vm.data.textDocument.levels.level[i].textbox;
                        if (_font.selected) {
                            _font.ctrlKey = true;
                        }
                    }
                    if(vm.data.textDocument.levels.level[i].imageart) {
                        var _font = vm.data.textDocument.levels.level[i].imageart;
                        if (_font.selected) {
                            _font.ctrlKey = true;
                        }
                    }
                }
                vm.data.textDocument.levels.level[index].imageart.ctrlKey = true;
                vm.ctrlKeyArray.push(item)
            }else{
                if($event && $event.preventDefault){
                    $event.preventDefault();
                }
                else {
                    window.event.returnValue = false;
                    return false;
                }
                $event.stopPropagation();
                if(!vm.data.textDocument.levels.level[index].imageart.ctrlKey){
                    vm.ctrlKeyArray = [];
                    for(var i1=0 ;i1<vm.data.textDocument.levels.level.length; i1++){
                        if(vm.data.textDocument.levels.level[i1].textbox){
                            vm.data.textDocument.levels.level[i1].textbox.selected = false;
                            vm.data.textDocument.levels.level[i1].textbox.ctrlKey = false;
                        }
                        if(vm.data.textDocument.levels.level[i1].imageart){
                            vm.data.textDocument.levels.level[i1].imageart.selected = false;
                            vm.data.textDocument.levels.level[i1].imageart.ctrlKey = false;
                        }
                    }
                    for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                        if(vm.data.textDocument.levels.level[i].imageart) {
                            vm.data.textDocument.levels.level[i].imageart.selected = false;
                        }
                    }
                }
                fontHide()
                item.selected = true;
                vm.xmlSelect.type = 'imageart';
                vm.xmlSelect.transparency = item.transparency;
                vm.xmlSelect.editable = item.editable;
                vm.xmlSelect.lock = item.lock;
                if(vm.unit == 'mm'){
                    vm.attribute.material = {
                        width: (item.geometry.width/vm.windowDpiW * 25.4).toFixed(2),
                        height: (item.geometry.height/vm.windowDpiH * 25.4).toFixed(2),
                        x: (item.geometry.x/vm.windowDpiW * 25.4).toFixed(2),
                        y: (item.geometry.y/vm.windowDpiH * 25.4).toFixed(2),
                    }
                }
                if (vm.unit == 'px'){
                    vm.attribute.material = {
                        width: item.geometry.width,
                        height: item.geometry.height,
                        x: item.geometry.x,
                        y: item.geometry.y
                    }
                }
            }
            clearBubble();
        }
        //拖动素材
        var isDown = false;
        $scope.imageartdown = function ($event, item) {
            if(item.lock || vm.textEditor){return}
            var $event = $event || window.event;
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            if (vm.ctrlKeyArray.length > 0) {
                levelMove($event);
            }else{
                textMove($event, item)
            }
        }
        //素材缩放right
        var isZoomDown = false;
        $scope.imageartdownZoomRight = function($event, item){
            if(item.lock){return}
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            imageartZoom($event, item, 'right')
            // var x = $event.clientX;
            // var y = $event.clientY;
            // //获取w和h
            // var nw = angular.copy(item.geometry.width) * vm.ratio;
            // var nh = angular.copy(item.geometry.height) * vm.ratio;
            // var nw1 = angular.copy(item.resource.width) * vm.ratio;
            // var nh1 = angular.copy(item.resource.height) * vm.ratio;
            // // var x1 = $($event.target).parent().find('.c-xml-page-material-img')[0].offsetLeft;
            // var x1 = angular.copy(item.offsetx * vm.ratio);
            // // var y1 = $($event.target).parent().find('.c-xml-page-material-img')[0].offsetTop;
            // var y1 = angular.copy(item.offsety * vm.ratio);
            // var _scale = angular.copy(item.scale);
            // console.log(nw1 + '/' + nh1)
            // //开关打开
            // isZoomDown = true;
            // //鼠标移动
            // window.onmousemove = function (e) {
            //     if (isZoomDown == false) {
            //         return;
            //     }
            //     //获取x和y
            //     var nx = e.clientX;
            //     var ny = e.clientY;
            //     //计算移动后的左偏移量和顶部的偏移量
            //     var nl = nx - x;
            //     var _w = (Number(nw) + Number(nl));
            //     var _h = _w / nw * nh;
            //     if(_w > vm.data.textDocument.geometry.width * vm.ratio){
            //         _w = vm.data.textDocument.geometry.width * vm.ratio;
            //         _h = _w / nw * nh;
            //     }
            //     if(_h > vm.data.textDocument.geometry.height * vm.ratio){
            //         _h = vm.data.textDocument.geometry.height * vm.ratio;
            //         _w = _h / nh * nw;
            //     }
            //
            //
            //     if(_w > 20){
            //         item.geometry.width = _w / vm.ratio;
            //         item.geometry.height = _h / vm.ratio;
            //         item.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / vm.ratio;
            //         item.offsety = (_h / (nh / nh1)) / (nh1 / y1) / vm.ratio;
            //         $($event.target).parent().css({
            //             width: _w + 'px',
            //             height: _h + 'px'
            //         });
            //         $($event.target).parent().find('.c-xml-page-material-img').css({
            //             width: _w * _scale + 'px',
            //             left:  (_w / (nw / nw1)) / (nw1 / x1) + 'px',
            //             top:  (_h / (nh / nh1)) / (nh1 / y1) + 'px',
            //         });
            //     }
            //     // if(_w > 20){
            //     //     item.scale = _w / nw * _scale;
            //     //     item.geometry.width = _w / vm.ratio;
            //     //     item.geometry.height = _h / vm.ratio;
            //     //     item.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / vm.ratio;
            //     //     item.offsety = (_h / (nh / nh1)) / (nh1 / y1) / vm.ratio;
            //     //     $($event.target).parent().css({
            //     //         width: _w + 'px',
            //     //         height: _h + 'px'
            //     //     });
            //     //     $($event.target).parent().find('.c-xml-page-material-img').css({
            //     //         width: nw1 * item.scale + 'px',
            //     //         height: nh1 * item.scale + 'px',
            //     //         left:  (_w / (nw / nw1)) / (nw1 / x1) + 'px',
            //     //         top:  (_h / (nh / nh1)) / (nh1 / y1) + 'px',
            //     //     });
            //     // }
            //
            //
            //     // item.geometry.width = _w / vm.ratio;
            //     // item.geometry.height = _h / vm.ratio;
            //     // item.resource.width = _w / (nw / nw1) / vm.ratio;
            //     // item.resource.height = _h / (nh / nh1) / vm.ratio;
            //     // item.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / vm.ratio;
            //     // item.offsety = (_h / (nh / nh1)) / (nh1 / y1) / vm.ratio;
            //     // $($event.target).parent().css({
            //     //     width: _w + 'px',
            //     //     height: _h + 'px'
            //     // });
            //     // $($event.target).parent().find('.c-xml-page-material-img').css({
            //     //     width: _w / (nw / nw1) + 'px',
            //     //     height: _h / (nh / nh1) + 'px',
            //     //     left:  (_w / (nw / nw1)) / (nw1 / x1) + 'px',
            //     //     top:  (_h / (nh / nh1)) / (nh1 / y1) + 'px',
            //     // });
            // }
            // //鼠标抬起事件
            // window.onmouseup = function ($event) {
            //     isZoomDown = false;
            //     $event.stopPropagation();
            // }
        }
        //素材缩放left
        $scope.imageartdownZoomLeft = function ($event, item) {
            if(item.lock){return}
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            imageartZoom($event, item, 'left')
        }
        //素材缩放top
        $scope.imageartdownZoomTop = function ($event, item) {
            if(item.lock){return}
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            imageartZoom($event, item, 'top')
        }
        //素材缩放bottom
        $scope.imageartdownZoomBottom = function ($event, item) {
            if(item.lock){return}
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            imageartZoom($event, item, 'bottom')
        }
        function imageartZoom($event,item, direction) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * vm.ratio;
            var nh = angular.copy(item.geometry.height) * vm.ratio;
            var nw1 = angular.copy(item.resource.width) * vm.ratio;
            var nh1 = angular.copy(item.resource.height) * vm.ratio;
            var x1 = angular.copy(item.offsetx * vm.ratio);
            var y1 = angular.copy(item.offsety * vm.ratio);
            var x2 = item.geometry.x * vm.ratio;
            var y2 = item.geometry.y * vm.ratio;
            var _scale = angular.copy(item.scale);
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;

                if(direction == 'bottom' || direction == 'left'){
                    var _w = (Number(nw) - Number(nl));
                }
                if(direction == 'top' || direction == 'right'){
                    var _w = (Number(nw) + Number(nl));
                }
                var _h = _w / nw * nh;
                if(_w > vm.data.textDocument.geometry.width * vm.ratio){
                    _w = vm.data.textDocument.geometry.width * vm.ratio;
                    _h = _w / nw * nh;
                }
                if(_h > vm.data.textDocument.geometry.height * vm.ratio){
                    _h = vm.data.textDocument.geometry.height * vm.ratio;
                    _w = _h / nh * nw;
                }


                if(_w > 20){
                    item.geometry.width = _w / vm.ratio;
                    item.geometry.height = _h / vm.ratio;
                    item.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / vm.ratio;
                    item.offsety = (_h / (nh / nh1)) / (nh1 / y1) / vm.ratio;
                    $($event.target).parent().find('.c-xml-page-material-img').css({
                        width: _w * _scale + 'px',
                        left:  (_w / (nw / nw1)) / (nw1 / x1) + 'px',
                        top:  (_h / (nh / nh1)) / (nh1 / y1) + 'px',
                    });

                    if(direction == 'left'){
                        item.geometry.x = (Number(x2) - Number(_w - nw)) / vm.ratio;
                        item.geometry.y = (Number(y2) - Number(_h - nh)) / vm.ratio;
                        $($event.target).parent().css({
                            width: _w + 'px',
                            height: _h + 'px',
                            left: Number(x2) - Number(_w - nw) + 'px',
                            top: Number(y2) - Number(_h - nh) + 'px',
                        });
                    }
                    if(direction == 'top'){
                        item.geometry.y = (Number(y2) - Number(_h - nh)) / vm.ratio;
                        $($event.target).parent().css({
                            width: _w + 'px',
                            height: _h + 'px',
                            top: Number(y2) - Number(_h - nh) + 'px',
                        });
                    }
                    if(direction == 'right'){
                        $($event.target).parent().css({
                            width: _w + 'px',
                            height: _h + 'px'
                        });
                    }
                    if(direction == 'bottom'){
                        item.geometry.x = (Number(x2) - Number(_w - nw)) / vm.ratio;
                        $($event.target).parent().css({
                            width: _w + 'px',
                            height: _h + 'px',
                            left: Number(x2) - Number(_w - nw) + 'px',
                        });
                    }
                }
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isZoomDown = false;
                $event.stopPropagation();
            }
        }

        //素材旋转
        var isRotate = false;
        $scope.imageartdownRotate = function($event, item){
            if(item.lock){return}
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }

            $event.stopPropagation();
            var _top = $($event.target).parents('.c-xml-page-material').offset().top + (item.geometry.height * vm.ratio / 2);
            var _left = $($event.target).parents('.c-xml-page-material').offset().left + (item.geometry.width * vm.ratio / 2);

            //开关打开
            isRotate = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isRotate == false) {
                    return;
                }
                item.rotation.angle = getAngle(_left,_top, e.clientX, e.clientY);
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function (e) {
                isRotate = false;
            };
            cookie()
        }
        function getAngle(px,py,mx,my){//获得人物中心和鼠标坐标连线，与y轴正半轴之间的夹角
            var x = Math.abs(px-mx);
            var y = Math.abs(py-my);
            var z = Math.sqrt(Math.pow(x,2)+Math.pow(y,2));
            var cos = y/z;
            var radina = Math.acos(cos);//用反三角函数求弧度
            var angle = Math.floor(180/(Math.PI/radina));//将弧度转换成角度
            if(mx<px&&my>py){//鼠标在第三象限
                angle = angle;
            }
            if(mx<px&&my<py){
                angle = 180 - angle;
            }
            if(mx>px&&my<py){
                angle = 180 + angle;
            }
            if(mx>px&&my>py){
                angle = 360 - angle;
            }
            return angle;
        }
        //素材裁切
        $scope.imageartDblclick = function($event, item){
            if(item.lock || vm.textEditor){return}
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var x = $event.clientX;
            var y = $event.clientY;
            vm.isImageartTrim = true;
            vm.imageartTrim = angular.copy(item);
            vm.imageartTrim.geometry.width1 = angular.copy(item.geometry.width);
            vm.imageartTrim.geometry.height1 = angular.copy(item.geometry.height);
            vm.imageartTrim.geometry.x1 = angular.copy(item.geometry.x);
            vm.imageartTrim.geometry.y1 = angular.copy(item.geometry.y);
            if(vm.imageartTrim.geometry.width * vm.ratio == vm.imageartTrim.geometry.width1 * vm.ratio * vm.imageartTrim.scale){
                vm.imageartTrim.geometry.width = vm.imageartTrim.geometry.width * 0.8;
                vm.imageartTrim.geometry.height = vm.imageartTrim.geometry.height * 0.8;
                vm.imageartTrim.geometry.x = Number(vm.imageartTrim.geometry.x) + Number(vm.imageartTrim.geometry.width / 0.8 * 0.2 / 2);
                vm.imageartTrim.geometry.y = Number(vm.imageartTrim.geometry.y) + Number(vm.imageartTrim.geometry.height / 0.8 * 0.2 / 2);
            }
        }

        //拖动素材裁切
        var isImageartTrim = false;
        $scope.imageartTrimdown = function ($event) {
            var $event = $event || window.event;
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            // $event.stopPropagation();
            var x = $event.clientX;
            var y = $event.clientY;
            var l = vm.imageartTrim.geometry.x * vm.ratio;
            var t = vm.imageartTrim.geometry.y * vm.ratio;
            var r = (vm.imageartTrim.geometry.width1 * vm.imageartTrim.scale - vm.imageartTrim.geometry.width) * vm.ratio;
            var b = (($("#c-xml-imageart-trim-img").find('img').height()) / vm.ratio - vm.imageartTrim.geometry.height) * vm.ratio;
            console.log(l)
            console.log(t)

            var x1 = (Number(vm.imageartTrim.geometry.x1) + Number(vm.imageartTrim.offsetx)) * vm.ratio;
            var y1 = (Number(vm.imageartTrim.geometry.y1) + Number(vm.imageartTrim.offsety)) * vm.ratio;

            var x2 = vm.imageartTrim.geometry.x1 * vm.ratio;
            var y2 = vm.imageartTrim.geometry.y1 * vm.ratio;
            var x3 = vm.imageartTrim.offsetx * vm.ratio;
            var y3 = vm.imageartTrim.offsety * vm.ratio;
            //开关打开
            isImageartTrim = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageartTrim == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - (x - l);
                var nt = ny - (y - t);

                if(nl< x1){ nl = x1};
                if(nl > Number(r) + Number(x1)){ nl = Number(r) + Number(x1)};
                if(nt < y1){ nt = y1};
                if(nt > Number(b) + Number(y1)){ nt = Number(b) + Number(y1)};

                vm.imageartTrim.geometry.x = nl / vm.ratio;
                vm.imageartTrim.geometry.y = nt / vm.ratio;
                $("#c-xml-imageart-trim-frame").css({
                    left: nl + 'px',
                    top: nt + 'px',
                });
                $("#c-xml-imageart-trim-frame-img").css({
                    left: Number(x2 - nl) + Number(x3) + 'px',
                    top: Number(y2 - nt) + Number(y3) + 'px',
                });
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageartTrim = false;
                $event.stopPropagation();
            }
        }

        //素材裁切放大缩小
        var isImageartTrimRight = false;
        $scope.imageartTrimRight = function($event, item){
            var $event = $event || window.event;                  // IE、火狐获取事件对象
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = Number(vm.imageartTrim.geometry.width * vm.ratio) + Number(vm.imageartTrim.geometry.x * vm.ratio);
            var nh = Number(vm.imageartTrim.geometry.height * vm.ratio) + Number(vm.imageartTrim.geometry.y * vm.ratio);
            var _l = vm.imageartTrim.geometry.x * vm.ratio;
            var _t = vm.imageartTrim.geometry.y * vm.ratio;

            var r = Number(vm.imageartTrim.geometry.x1 * vm.ratio) + Number(vm.imageartTrim.offsetx * vm.ratio) + Number(vm.imageartTrim.geometry.width1 * vm.ratio * vm.imageartTrim.scale);
            var b = Number(vm.imageartTrim.geometry.y1 * vm.ratio) + Number(vm.imageartTrim.offsety * vm.ratio) + Number($("#imageartTrimRight1").height());
            //开关打开
            isImageartTrimRight = true;

            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageartTrimRight == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = Number(nw) + Number(nx - x);
                var nt = Number(nh) + Number(ny - y);

                if(nl >= r){nl = r}
                if(nt >= b){ nt = b}

                item.geometry.width = (nl - _l) / vm.ratio;
                item.geometry.height = (nt - _t) / vm.ratio;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageartTrimRight = false;
                $event.stopPropagation();
            }
        }
        $scope.imageartTrimRight1 = function($event, item){
            var $event = $event || window.event;                  // IE、火狐获取事件对象
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = item.geometry.width1 * vm.ratio * vm.imageartTrim.scale;
            var nh = item.geometry.height1 * vm.ratio * vm.imageartTrim.scale;

            var r = Number($("#c-xml-imageart-div").offset().left) + Number(vm.data.textDocument.geometry.width * vm.ratio);
            var x2 = (Number(item.geometry.x1 * vm.ratio) + Number($("#c-xml-imageart-trim-div").width()) - (Number(item.geometry.x * vm.ratio) + Number(item.geometry.width * vm.ratio)));
            var y2 = (Number(item.geometry.y1 * vm.ratio) + Number($("#c-xml-imageart-trim-div").height()) - (Number(item.geometry.y * vm.ratio) + Number(item.geometry.height * vm.ratio)));
            if(x2 < y2){
                var r1 = Number($("#c-xml-imageart-div").offset().left) + Number(item.geometry.x * vm.ratio) + Number(item.geometry.width * vm.ratio);
            }else {
                var r1 = Number($("#c-xml-imageart-div").offset().left) + Number(item.geometry.x * vm.ratio) + Number(item.geometry.width * vm.ratio) + (x2 - y2);
            }
            //开关打开
            isImageartTrimRight = true;

            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageartTrimRight == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                if(nx >= r){nx = r}
                if(nx <= r1){nx = r1}

                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;
                var nt = ny - y;

                item.geometry.width1 = ((Number(nw) + Number(nl))) / vm.ratio / vm.imageartTrim.scale;
                item.geometry.height1 = (Number(nh) + Number(nt)) / vm.ratio / vm.imageartTrim.scale;
                $("#imageartTrimRight1").css({
                    width: Number(nw) + Number(nl) + 'px'
                });
                $("#c-xml-imageart-trim-frame-img img").css({
                    width: Number(nw) + Number(nl) + 'px'
                });
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageartTrimRight = false;
                $event.stopPropagation();
            }
        }
        $scope.imageartTrimTrueClick = function(){
            vm.isImageartTrim = false;
            vm.imageartTrim.scale = (vm.imageartTrim.geometry.width1 * vm.ratio * vm.imageartTrim.scale)/(vm.imageartTrim.geometry.width * vm.ratio);
            vm.imageartTrim.offsetx = (Number(-(vm.imageartTrim.geometry.x - vm.imageartTrim.geometry.x1)) + Number(vm.imageartTrim.offsetx)).toFixed(2);
            vm.imageartTrim.offsety = (Number(-(vm.imageartTrim.geometry.y - vm.imageartTrim.geometry.y1)) + Number(vm.imageartTrim.offsety)).toFixed(2);
            if(vm.imageartTrim.offsetx > 0){
                vm.imageartTrim.offsetx = 0;
            }
            if(vm.imageartTrim.offsety > 0){
                vm.imageartTrim.offsety = 0;
            }
            if(vm.imageartTrim.scale < 1){
                vm.imageartTrim.scale = 1;
            }
            if(vm.imageartTrim.geometry.width > vm.imageartTrim.geometry.width1 * vm.imageartTrim.scale){
                vm.imageartTrim.geometry.width = vm.imageartTrim.geometry.width1 * vm.imageartTrim.scale;
            }
            for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                if(vm.data.textDocument.levels.level[i].imageart){
                    if(vm.data.textDocument.levels.level[i].imageart.selected){
                        vm.imageartTrim.geometry.x = vm.data.textDocument.levels.level[i].imageart.geometry.x;
                        vm.imageartTrim.geometry.y = vm.data.textDocument.levels.level[i].imageart.geometry.y;
                        vm.data.textDocument.levels.level[i].imageart = vm.imageartTrim;
                    }
                }
            }
        }
        $scope.imageartTrimFalseClick = function(){
            vm.isImageartTrim = false;
        }

        //添加文字
        $scope.fontAdd = function (data) {
            if(vm.textEditor){return}
            var _fontSize = 20;
            if(vm.ratio <= Number(0.5)){_fontSize = 40}
            if(0.5 < vm.ratio && vm.ratio <= 1){_fontSize = 26}
            if(1 < vm.ratio && vm.ratio <= 2){_fontSize = 20}
            if(vm.ratio > 2){_fontSize = 12}
            var _font = {
                geometry: {
                    x: '0',
                    y: '0',
                    width: 100 / vm.ratio,
                    height: 70
                },
                rotation: {
                    angle: '0'
                },
                text: '请输入文字',
                style: {
                    font: "",
                    size: _fontSize,
                    color: '#000000',
                    bold: false,
                    italic: false,
                    lineheight: 35,
                    letterspacing: 1,
                    align: 'left',
                },
                fontuuid: data.uuid,
                identifier: "",
                editable: true,
                movable: true,
                lock: false,
                version: '2.0',
                transparency: '1',
                selected: true
            };
            var _fontStyle = [];
            for(var i=0; i<vm.fontList.length; i++){
                if(vm.fontList[i].uuid == data.uuid){
                    for(var j=0; j<vm.fontList[i].fontStyles.length; j++){
                        _fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            if(_fontStyle.indexOf('NORMAL') != '-1'){
                _font.style.bold = false;
                _font.style.italic = false;
            }else {
                if(_fontStyle.indexOf('BOLD_ITALIC') != '-1'){
                    _font.style.bold = true;
                    _font.style.italic = true;
                }else {
                    if(_fontStyle.indexOf('ITALIC') != '-1'){
                        _font.style.italic = true;
                        _font.style.bold = false;
                    }
                    if(_fontStyle.indexOf('BOLD') != '-1'){
                        _font.style.bold = true;
                        _font.style.italic = false;
                    }
                }
            }
            vm.ctrlKeyArray = []
            for(var i=0 ;i<vm.data.textDocument.levels.level.length; i++){
                if(vm.data.textDocument.levels.level[i].textbox) {
                    var _font1 = vm.data.textDocument.levels.level[i].textbox;
                    _font1.selected = false;
                    _font1.ctrlKey = false;
                }
            }
            vm.fontHide = _font;
            $timeout(function () {
                _font.geometry.width = Number($("#c-xml-page-material-fontId").width() / vm.ratio) + Number(10);
                _font.geometry.height = Number($("#c-xml-page-material-fontId").height() / vm.ratio) +  Number(5);
                addElement('textbox', _font)
                cookie();
                fontSelect()
                getFont(_font);
            })
        }
        function fontSelect() {
            for(var i=0 ;i<vm.data.textDocument.levels.level.length; i++){
                if(vm.data.textDocument.levels.level[i].textbox) {
                    var _font2 = vm.data.textDocument.levels.level[i].textbox;
                    if(_font2.selected){
                        vm.font = _font2;
                        vm.fontHide = _font2;
                        vm.font.style.size = vm.font.style.size.toString();
                        vm.isFont = true;
                        vm.xmlSelect.type = 'textbox';
                        vm.xmlSelect.transparency = _font2.transparency;
                        vm.xmlSelect.editable = _font2.editable;
                        vm.xmlSelect.lock = _font2.lock;

                        if(vm.unit == 'mm'){
                            vm.attribute.font = {
                                width: (_font2.geometry.width/vm.windowDpiW * 25.4).toFixed(2),
                                x: (_font2.geometry.x/vm.windowDpiW * 25.4).toFixed(2),
                                y: (_font2.geometry.y/vm.windowDpiH * 25.4).toFixed(2),
                            }
                        }
                        if (vm.unit == 'px'){
                            vm.attribute.font = {
                                width: _font2.geometry.width,
                                x: _font2.geometry.x,
                                y: _font2.geometry.y
                            }
                        }
                        clearBubble()
                        fontStyle()
                        break;
                    }
                }
            }
            for(var i1=0; i1<vm.data.textDocument.levels.level.length; i1++){
                if(vm.data.textDocument.levels.level[i1].imageart) {
                    vm.data.textDocument.levels.level[i1].imageart.selected = false;
                }
            }
        }
        function fontEdit() {
            for(var i=0 ;i<vm.data.textDocument.levels.level.length; i++){
                if(vm.data.textDocument.levels.level[i].textbox) {
                    var _font = vm.data.textDocument.levels.level[i].textbox;
                    if (_font.selected) {
                        _font = vm.font;
                        break;
                    }
                }
            }
            cookie()
        }
        function fontHide() {
            vm.isFont = false;
            vm.font = {};
        }
        function fontStyle(){
            vm.font.fontStyle = [];
            for(var i=0; i<vm.fontList.length; i++){
                if(vm.fontList[i].uuid == vm.font.fontuuid){
                    for(var j=0; j<vm.fontList[i].fontStyles.length; j++){
                        vm.font.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            vm.font.style.bold1 = false;
            vm.font.style.italic1= false;
            if(vm.font.fontStyle.indexOf('NORMAL') == '-1'){
                vm.font.style.normal = false;
            }else {
                vm.font.style.normal = true;
            }
            if(vm.font.fontStyle.indexOf('BOLD_ITALIC') != '-1'){
                vm.font.style.bold1 = true;
                vm.font.style.italic1= true;
            }else {
                if(vm.font.fontStyle.indexOf('ITALIC') != '-1'){
                    vm.font.style.italic1= true;
                }
                if(vm.font.fontStyle.indexOf('BOLD') != '-1'){
                    vm.font.style.bold1 = true;
                }
            }
        }
        vm.ctrlKeyArray=[]
        //选中文字
        $scope.textboxClick = function ($event, item, index) {
            if (vm.textEditor) {
                return
            }
            var $event = $event || window.event;
            if ($event.ctrlKey == true || $event.metaKey == true) {
                for(var i=0 ;i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var _font = vm.data.textDocument.levels.level[i].textbox;
                        if (_font.selected) {
                            _font.ctrlKey = true;
                        }
                    }
                    if(vm.data.textDocument.levels.level[i].imageart) {
                        var _font = vm.data.textDocument.levels.level[i].imageart;
                        if (_font.selected) {
                            _font.ctrlKey = true;
                        }
                    }
                }
                vm.data.textDocument.levels.level[index].textbox.ctrlKey = true;
                vm.ctrlKeyArray.push(item)
            }else{
                if($event && $event.preventDefault){
                    $event.preventDefault();
                }
                else {
                    window.event.returnValue = false;
                    return false;
                }
                $event.stopPropagation();
                if(!vm.data.textDocument.levels.level[index].textbox.ctrlKey){
                    for(var i=0 ;i<vm.data.textDocument.levels.level.length; i++){
                        if(vm.data.textDocument.levels.level[i].textbox) {
                            var _font = vm.data.textDocument.levels.level[i].textbox;
                            _font.selected = false;
                            _font.ctrlKey = false;
                        }
                        if(vm.data.textDocument.levels.level[i].imageart) {
                            var _font = vm.data.textDocument.levels.level[i].imageart;
                            _font.selected = false;
                            _font.ctrlKey = false;
                        }
                    }
                    vm.ctrlKeyArray = [];
                    item.selected = true;
                }
                vm.xmlSelect.type = 'textbox';
                vm.xmlSelect.transparency = item.transparency;
                vm.xmlSelect.editable = item.editable;
                vm.xmlSelect.lock = item.lock;
                clearBubble();
                fontSelect()
            }
        }
        //双击编辑文字
        vm.textEditor = false;
        $scope.doubleClicktext = function (e, item, index) {
            fn(e);
            if (item.lock || vm.textEditor) {
                return
            }
            $timeout(function () {
                vm.textEditor = angular.copy(vm.data.textDocument.levels.level[index].textbox);
                vm.fontOn = $(e.target).parents('.c-xml-page-textboxe');
                var span = vm.fontOn.find('span');
                span[0].innerHTML = '';
                span.focus();
                span[0].innerHTML = item.text;
                $(span[0]).css({'cursor':'text'});
                span[0].setAttribute("contenteditable", "true");
                if (document.selection) {
                    var range = document.body.createTextRange();
                    range.moveToElementText(span[0]);
                    range.select();
                } else if (window.getSelection) {
                    var range = document.createRange();
                    range.selectNodeContents(span[0]);
                    window.getSelection().removeAllRanges();
                    window.getSelection().addRange(range);
                }
            })
        }
        //去除所有html标签，只保留文字
        var textReg=/<\/?.+?\/?>/g;
        // 过滤style行内标签
        var textReg1=/ style\s*?=\s*?(['"])[\s\S]*?\1/g;
        $scope.textBlur = function (e, item, i) {
            fn(e);
            $timeout(function () {
                $(e.target).css({'cursor':'pointer'});
                $(e.target).attr("contenteditable", "false");
                item.text = angular.copy($(e.target).text());
                item.text = item.text.replace(/&nbsp;/g,' ').replace(textReg1,'').replace(/<br>/g,'\n').replace(textReg,'');
                FontManagement.fontSupport({
                    uuid: item.fontuuid,
                    bold: item.style.bold,
                    italic: item.style.italic,
                    content: item.text
                }, function (res) {
                    var msg = res.message;
                    if (res.status == 200) {
                        item.success = msg.success;
                        if (!msg.success) {
                            MessageService.error("当前字体不可用，请更换字体");
                            return
                        }
                        if (msg.success && !msg.supported) {
                            var message = "【" + msg.failed + "】" + "当前字体不支持这些文字！";
                            MessageService.error(message);
                        }
                        vm.textEditor.text = msg.passed;
                        sizeHeight1(function () {
                            vm.textEditor = false;
                            cookie();
                            $scope.$apply();
                        });
                    }
                })
            })
        }
        $scope.changeHeight = function(e,item){
            vm.textEditor.text = $(e.target).text();
            sizeHeight1();
        }
        //拖动文字
        var isTextDrag = false;
        $scope.textboxdown = function ($event, item) {
            if(item.lock || vm.textEditor){return}
            var $event = $event || window.event;
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            if (vm.ctrlKeyArray.length > 0) {
                levelMove($event);
            }else{
                textMove($event, item)
            }
        }
        function levelMove(event) {
            angular.forEach(vm.data.textDocument.levels.level, function (level, i) {
                if(level.textbox && level.textbox.ctrlKey){
                    if(!level.textbox.lock){
                        (function(textbox){
                            textMove(event, textbox)
                        })(level.textbox)
                    }
                }
                if(level.imageart && level.imageart.ctrlKey){
                    if(!level.imageart.lock){
                        (function(imageart){
                            textMove(event, imageart)
                        })(level.imageart)
                    }
                }
            })
        }
        var moveItem = [];
        function textMove($event, item){
            $event.stopPropagation();
            var x = $event.clientX;
            var y = $event.clientY;
            item.l = angular.copy(item.geometry.x * vm.ratio);
            item.t = angular.copy(item.geometry.y * vm.ratio);
            moveItem.push(item)
            //开关打开
            isDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isDown == false) {
                    return;
                }
                moveItem.forEach(function (item1) {
                    var nx = e.clientX;
                    var ny = e.clientY;
                    //计算移动后的左偏移量和顶部的偏移量
                    var nl = nx - (x - item1.l);
                    var nt = ny - (y - item1.t);
                    item1.geometry.x = nl / vm.ratio;
                    item1.geometry.y = nt / vm.ratio;
                })
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                console.log('11111111')
                if (isDown) {
                    cookie();
                }
                isDown = false;
                moveItem = [];
                $event.stopPropagation();
            }
        }
        // function textMove(){
        //     $event.stopPropagation();
        //     var x = $event.clientX;
        //     var y = $event.clientY;
        //     var l = item.geometry.x * vm.ratio;
        //     var t = item.geometry.y * vm.ratio;
        //     var r = (vm.data.textDocument.geometry.width - item.geometry.width) * vm.ratio;
        //     var b = (vm.data.textDocument.geometry.height - item.geometry.height) * vm.ratio;
        //     //开关打开
        //     isTextDrag = true;
        //     //鼠标移动
        //     window.onmousemove = function (e) {
        //         if (isTextDrag == false) {
        //             return;
        //         }
        //         //获取x和y
        //         var nx = e.clientX;
        //         var ny = e.clientY;
        //         //计算移动后的左偏移量和顶部的偏移量
        //         var nl = nx - (x - l);
        //         var nt = ny - (y - t);

        //         if(nl < 0){nl = 0;}
        //         if(nl > r){nl = r;}
        //         if(nt < 0){nt = 0;}
        //         if(nt > b){nt = b;}
        //         item.geometry.x = nl / vm.ratio;
        //         item.geometry.y = nt / vm.ratio;
        //         $($event.target).parents('.c-xml-page-textboxe').css({
        //             left: nl + 'px',
        //             top: nt + 'px',
        //         });
        //     }
        //     //鼠标抬起事件
        //     window.onmouseup = function ($event) {
        //         isTextDrag = false;
        //         $event.stopPropagation();
        //     }
        // }
        //文字旋转
        var isTextRotate = false;
        $scope.textboxRotate = function($event, item){
            if(item.lock){return}
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var _top = $($event.target).parents('.c-xml-page-textboxe').offset().top + (item.geometry.height * vm.ratio / 2);
            var _left = $($event.target).parents('.c-xml-page-textboxe').offset().left + (item.geometry.width * vm.ratio / 2);
            //开关打开
            isTextRotate = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isTextRotate == false) {
                    return;
                }
                item.rotation.angle = getAngle(_left,_top, e.clientX, e.clientY);
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function (e) {
                isTextRotate = false;
            };
            cookie()
        }

        //文字放大缩小
        var isTextDown = false;
        $scope.textboxZoomRight = function($event, item){
            textboxZoom($event, item, 'right')
        }
        $scope.textboxZoomLeft = function($event, item){
            textboxZoom($event, item, 'left')
        }
        function textboxZoom($event, item, element){
            if(item.lock){return}
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var x = $event.clientX;

            var nw = angular.copy(item.geometry.width) * vm.ratio;
            var left = angular.copy(item.geometry.x) * vm.ratio;
            //开关打开
            isTextDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isTextDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;

                var h = Number($($event.target).parent().find('.c-xml-page-textboxe-text').height()) + Number(5);
                if(element == 'left'){
                    item.geometry.x = (left + nl) / vm.ratio;
                    item.geometry.width = (Number(nw) - Number(nl)) / vm.ratio;
                    if(item.geometry.width < (Number(parseInt(item.style.size)) + Number(10))){
                        item.geometry.width = (Number(parseInt(item.style.size)) + Number(20));
                        item.geometry.x = (left + nl - Number(5)) / vm.ratio;
                        isTextDown = false;
                        return;
                    }
                    if((Number(nw) - Number(nl)) > vm.data.textDocument.geometry.width * vm.ratio){
                        item.geometry.width = vm.data.textDocument.geometry.width;
                        item.geometry.x = 0;
                    }
                }
                if(element == 'right'){
                    item.geometry.width = (Number(nw) + Number(nl)) / vm.ratio;
                    if(item.geometry.width < (Number(parseInt(item.style.size)) + Number(10))){
                        item.geometry.width = (Number(parseInt(item.style.size)) + Number(20));
                        isTextDown = false;
                        return;
                    }
                    if((Number(nw) + Number(nl)) > vm.data.textDocument.geometry.width * vm.ratio){
                        item.geometry.width = vm.data.textDocument.geometry.width;
                        item.geometry.x = 0;
                    }
                }
                item.geometry.height = h / vm.ratio;
                sizeHeight();
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isTextDown = false;
                $event.stopPropagation();
            }
        };
        $scope.textboxZoomLeft1 = function($event, item){
            textboxZoom1($event, item, 'left')
        };
        $scope.textboxZoomTop1 = function($event, item){
            textboxZoom1($event, item, 'top')
        };
        $scope.textboxZoomRight1 = function($event, item){
            textboxZoom1($event, item, 'right')
        };
        $scope.textboxZoomBottom1 = function($event, item){
            textboxZoom1($event, item, 'bottom')
        };
        function textboxZoom1($event, item, element){
            if(item.lock){return}
            var $event = $event || window.event;                  // IE、火狐获取事件对象
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width);
            var nh = angular.copy(item.geometry.height);
            var _left = angular.copy(item.geometry.x);
            var _top = angular.copy(item.geometry.y);
            var _size = angular.copy(parseInt(item.style.size));
            var _lineheight = angular.copy(parseInt(item.style.lineheight));
            //开关打开
            isTextDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isTextDown == false) {return;}
                if(item.style.size < 6){
                    item.style.size = 6;
                    item.style.lineheight = 6;
                    isTextDown = false;
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = (nx - x) / vm.ratio;
                var nt = (ny - y) / vm.ratio;

                if(element == 'left'){
                    if((Number(nw) - Number(nl)) < 20){
                        isTextDown = false;
                        return;
                    }
                    item.geometry.width = (Number(nw) - Number(nl));
                    item.geometry.x = (Number(_left) + Number(nl));
                    item.style.size = Math.ceil(((Number(nw) - Number(nl)) / nw * _size));
                    item.style.lineheight = Math.ceil(((Number(nw) - Number(nl)) / nw * _lineheight));
                    item.geometry.y = (Number(_top) - Number(item.geometry.height - nh));
                }
                if(element == 'top'){
                    if((Number(nw) + Number(nl)) < 20){
                        isTextDown = false;
                        return;
                    }
                    item.geometry.width = (Number(nw) + Number(nl));
                    item.style.size = Math.ceil(((Number(nw) + Number(nl)) / nw * _size));
                    item.style.lineheight = Math.ceil(((Number(nw) + Number(nl)) / nw * _lineheight));
                    item.geometry.y = (Number(_top) - Number(item.geometry.height - nh));
                }
                if(element == 'right'){
                    if((Number(nw) + Number(nl)) < 20){
                        isTextDown = false;
                        return;
                    }
                    item.geometry.width = (Number(nw) + Number(nl) + Number(5));
                    item.style.size = Math.ceil(((Number(nw) + Number(nl)) / nw * _size));
                    item.style.lineheight = Math.ceil(((Number(nw) + Number(nl)) / nw * _lineheight));
                }
                if(element == 'bottom'){
                    if((Number(nw) - Number(nl)) < 20){
                        isTextDown = false;
                        return;
                    }
                    item.geometry.width = (Number(nw) - Number(nl));
                    item.geometry.x = (Number(_left) + Number(nl));
                    item.style.size = Math.ceil(((Number(nw) - Number(nl)) / nw * _size));
                    item.style.lineheight = Math.ceil(((Number(nw) - Number(nl)) / nw * _lineheight));
                    // item.geometry.y = (Number(_top) + Number(item.geometry.height - nh));
                }
                sizeHeight();
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isTextDown = false;
                $event.stopPropagation();
            }
        }

        //点击作品框
        $scope.xmlClick = function(event){
            // fn(event);
            if(vm.textEditor){return}

            // var $event = $event || window.event;                  // IE、火狐获取事件对象
            // //防止浏览器默认行为(W3C)
            // if($event && $event.preventDefault){
            //     $event.preventDefault();
            // }
            // //IE中组织浏览器行为
            // else {
            //     window.event.returnValue = false;
            //     return false;
            // }
            // $event.stopPropagation();
            for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                if(vm.data.textDocument.levels.level[i].imageart) {
                    vm.data.textDocument.levels.level[i].imageart.selected = false;
                }
            }
            for(var i1=0 ;i1<vm.data.textDocument.levels.level.length; i1++){
                if(vm.data.textDocument.levels.level[i1].textbox) {
                    var _font = vm.data.textDocument.levels.level[i1].textbox;
                    _font.selected = false;
                }
            }
            fontHide()
            vm.xmlSelect.type = "";
            vm.xmlSelect.transparency = "";
            vm.xmlSelect.editable = vm.data.textDocument.editable;
            vm.xmlSelect.lock = null;
            clearBubble();
        }
        //双击作品框
        $scope.xmlDblclick = function($event){
            var $event = $event || window.event;                  // IE、火狐获取事件对象
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            if(vm.data.textDocument.background.resource.identifier){
                vm.isXmlDblclick = true;
            }
        }




        //单位换算
        $scope.unitConversion = function () {
            unit()
        }
        function unit(){
            if(vm.unit == 'mm'){
                vm.attribute = {
                    product: {
                        width: (vm.data.textDocument.geometry.width/vm.windowDpiW*25.4).toFixed(2),
                        height:(vm.data.textDocument.geometry.height/vm.windowDpiH*25.4).toFixed(2),
                    },
                    background: {
                        x: (vm.data.textDocument.background.offsetx/vm.windowDpiW*25.4).toFixed(2),
                        y: (vm.data.textDocument.background.offsety/vm.windowDpiH*25.4).toFixed(2)
                    },
                    material: {
                        width: (vm.attribute.material.width/vm.windowDpiW*25.4).toFixed(2),
                        height: (vm.attribute.material.height/vm.windowDpiH*25.4).toFixed(2),
                        x: (vm.attribute.material.x/vm.windowDpiW*25.4).toFixed(2),
                        y: (vm.attribute.material.y/vm.windowDpiH*25.4).toFixed(2),
                    },
                    font: {
                        width: (vm.attribute.font.width/vm.windowDpiW*25.4).toFixed(2),
                        x: (vm.attribute.font.x/vm.windowDpiW*25.4).toFixed(2),
                        y: (vm.attribute.font.y/vm.windowDpiH*25.4).toFixed(2),
                    }
                }
            }
            if (vm.unit == 'px'){
                vm.attribute = {
                    product: {
                        width: vm.data.textDocument.geometry.width,
                        height:vm.data.textDocument.geometry.height,
                    },
                    background: {
                        x: vm.data.textDocument.background.offsetx,
                        y: vm.data.textDocument.background.offsety
                    },
                    material: {
                        width: (vm.attribute.material.width/25.4 * vm.windowDpiW).toFixed(2),
                        height: (vm.attribute.material.height/25.4 * vm.windowDpiH).toFixed(2),
                        x: (vm.attribute.material.x/25.4 * vm.windowDpiW).toFixed(2),
                        y: (vm.attribute.material.y/25.4 * vm.windowDpiH).toFixed(2),
                    },
                    font: {
                        width: (vm.attribute.font.width/25.4 * vm.windowDpiW).toFixed(2),
                        x: (vm.attribute.font.x/25.4 * vm.windowDpiW).toFixed(2),
                        y: (vm.attribute.font.y/25.4 * vm.windowDpiH).toFixed(2),
                    }
                }
            }
        }
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpiW = arrDPI[0];
            vm.windowDpiH = arrDPI[1];
            // console.log(arrDPI)
            // vm.windowDpiW = 103;
            // vm.windowDpiH = 103;
            return arrDPI;
        }
        //作品框宽高优化
        function initWindow(){
            vm.windowH = window.innerHeight - 90;
            vm.windowW = window.innerWidth - 500;
            if(vm.windowH / vm.data.textDocument.geometry.height >= vm.windowW / vm.data.textDocument.geometry.width ){
                vm.ratio = vm.windowW / vm.data.textDocument.geometry.width * 0.8;
            }else {
                vm.ratio = vm.windowH / vm.data.textDocument.geometry.height * 0.8;
            }
            //记录初始缩放比
            vm.initRatio = vm.ratio;
            vm.windowH1 = 200;
            vm.windowW1 = 200;
            if(vm.windowH1 / vm.data.textDocument.geometry.height >= vm.windowW1 / vm.data.textDocument.geometry.width ){
                vm.ratio1 = vm.windowW1 / vm.data.textDocument.geometry.width * 0.8;
            }else {
                vm.ratio1 = vm.windowH1 / vm.data.textDocument.geometry.height * 0.8;
            }

        }
        window.onresize = function(){
            initWindow()
            $scope.$apply();
            getFullState();
        }
        //属性栏
        $scope.$watch("vm.attribute.product.width",function(newValue,oldValue){
            if(oldValue){
                if(vm.unit == 'mm'){vm.data.textDocument.geometry.width = (vm.attribute.product.width/25.4 * vm.windowDpiW).toFixed(2);}
                if (vm.unit == 'px'){vm.data.textDocument.geometry.width = vm.attribute.product.width;}
                vm.data.textDocument.background.offsetx = 0;
                vm.data.textDocument.background.offsety = 0;
                initWindow();
                bgImgInit();
            }
        })
        $scope.$watch("vm.attribute.product.height",function(newValue,oldValue){
            if(oldValue){
                if(vm.unit == 'mm'){vm.data.textDocument.geometry.height = (vm.attribute.product.height/25.4 * vm.windowDpiH).toFixed(2);}
                if (vm.unit == 'px'){vm.data.textDocument.geometry.height = vm.attribute.product.height;}
                vm.data.textDocument.background.offsetx = 0;
                vm.data.textDocument.background.offsety = 0;
                initWindow();
                bgImgInit()
            }
        })
        $scope.$watch("vm.attribute.background.x",function(newValue,oldValue){
            if(oldValue){
                if(vm.unit == 'mm'){vm.data.textDocument.background.offsetx = (vm.attribute.background.x/25.4 * vm.windowDpiW).toFixed(2);}
                if (vm.unit == 'px'){vm.data.textDocument.background.offsetx = vm.attribute.background.x;}
                bgImgInit()
            }
        })
        $scope.$watch("vm.attribute.background.y",function(newValue,oldValue){
            if(oldValue){
                if(vm.unit == 'mm'){vm.data.textDocument.background.offsety = (vm.attribute.background.y/25.4 * vm.windowDpiH).toFixed(2);}
                if (vm.unit == 'px'){vm.data.textDocument.background.offsety = vm.attribute.background.y;}
                bgImgInit()
            }
        })
        $scope.attributeMaterialWidth = function(){
            var _org = angular.copy(vm.attribute.material.width);
            $timeout(function () {
                var _w = 1;
                if(vm.unit == 'mm'){
                    _w = (vm.attribute.material.width/25.4 * vm.windowDpiW);
                }
                if (vm.unit == 'px'){
                    _w = vm.attribute.material.width;
                }
                if(_w <= 0){
                    MessageService.error("宽度不能小于等于0");
                    $timeout(function () {
                        vm.attribute.material.width = _org;
                    })
                    return
                }
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].imageart){
                        var imageart = vm.data.textDocument.levels.level[i].imageart;
                        if(imageart.selected){
                            var _scale = _w / imageart.geometry.width;
                            imageart.geometry.height = imageart.geometry.height * _scale;
                            imageart.geometry.width = imageart.geometry.width * _scale;
                            imageart.scale = _scale * imageart.scale;
                            if(vm.unit == 'mm'){
                                vm.attribute.material.height = (imageart.geometry.height/vm.windowDpiH * 25.4).toFixed(2);
                            }
                            if (vm.unit == 'px'){
                                vm.attribute.material.height = imageart.geometry.height;
                            }
                            break;
                        }
                    }
                }
            }, 500)
        }
        $scope.attributeMaterialHeight = function(){
            var _org = angular.copy(vm.attribute.material.height);
            $timeout(function () {
                var _h = 1;
                if(vm.unit == 'mm'){
                    _h = (vm.attribute.material.height/25.4 * vm.windowDpiW);
                }
                if (vm.unit == 'px'){
                    _h = vm.attribute.material.height;
                }
                if(_h <= 0){
                    MessageService.error("宽度不能小于等于0");
                    $timeout(function () {
                        vm.attribute.material.height = _org;
                    })
                    return
                }
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].imageart){
                        var imageart = vm.data.textDocument.levels.level[i].imageart;
                        if(imageart.selected){
                            var _scale = _h / imageart.geometry.height;
                            imageart.geometry.height = imageart.geometry.height * _scale;
                            imageart.geometry.width = imageart.geometry.width * _scale;
                            imageart.scale = _scale * imageart.scale;
                            if(vm.unit == 'mm'){
                                vm.attribute.material.width = (imageart.geometry.width/vm.windowDpiW * 25.4).toFixed(2);
                            }
                            if (vm.unit == 'px'){
                                vm.attribute.material.width = imageart.geometry.width;
                            }
                            break;
                        }
                    }
                }
            }, 500)
        }

        $scope.$watch("vm.attribute.material.width",function(newValue,oldValue){
            // if(oldValue){cookie()}
        })
        $scope.$watch("vm.attribute.material.x",function(newValue,oldValue){
            if(oldValue){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].imageart) {
                        var imageart = vm.data.textDocument.levels.level[i].imageart;
                        if (imageart.selected) {
                            if (vm.unit == 'mm') {
                                imageart.geometry.x = (vm.attribute.material.x / 25.4 * vm.windowDpiW).toFixed(2);
                            }
                            if (vm.unit == 'px') {
                                imageart.geometry.x = vm.attribute.material.x;
                            }
                            break;
                        }
                    }
                }
            }
        })
        $scope.$watch("vm.attribute.material.y",function(newValue,oldValue){
            if(oldValue){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].imageart) {
                        var imageart = vm.data.textDocument.levels.level[i].imageart;
                        if (imageart.selected) {
                            if (vm.unit == 'mm') {
                                imageart.geometry.y = (vm.attribute.material.y / 25.4 * vm.windowDpiH).toFixed(2);
                            }
                            if (vm.unit == 'px') {
                                imageart.geometry.y = vm.attribute.material.y;
                            }
                            break;
                        }
                    }
                }
            }
        })

        $scope.$watch("vm.font.text",function(newValue,oldValue){
            if(newValue){
                sizeHeight();
            }
        })
        $scope.verifyText = function(){
            FontManagement.fontSupport({uuid:vm.font.fontuuid,bold:vm.font.style.bold,italic:vm.font.style.italic,content:vm.font.text},function (res) {
                var msg = res.message;
                if(res.status==200){
                    if(msg.success&&!msg.supported){
                        MessageService.error("【"+ msg.failed +"】" + "当前字体不支持这些文字！");
                    }
                    vm.font.text = msg.passed;
                }
            })
        }
        $scope.$watch("vm.attribute.font.width",function(newValue,oldValue){
            if(oldValue){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var textbox = vm.data.textDocument.levels.level[i].textbox;
                        if (textbox.selected) {
                            if (vm.unit == 'mm') {
                                textbox.geometry.width = (vm.attribute.font.width / 25.4 * vm.windowDpiW).toFixed(2);
                            }
                            if (vm.unit == 'px') {
                                textbox.geometry.width = vm.attribute.font.width;
                            }
                            break;
                        }
                    }
                }
                sizeHeight();
            }
        })
        $scope.$watch("vm.attribute.font.x",function(newValue,oldValue){
            if(oldValue){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var textbox = vm.data.textDocument.levels.level[i].textbox;
                        if (textbox.selected) {
                            if (vm.unit == 'mm') {
                                textbox.geometry.x = (vm.attribute.font.x / 25.4 * vm.windowDpiW).toFixed(2);
                            }
                            if (vm.unit == 'px') {
                                textbox.geometry.x = vm.attribute.font.x;
                            }
                            break;
                        }
                    }
                }
            }
        })
        $scope.$watch("vm.attribute.font.y",function(newValue,oldValue){
            if(oldValue){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var textbox = vm.data.textDocument.levels.level[i].textbox;
                        if (textbox.selected) {
                            if (vm.unit == 'mm') {
                                textbox.geometry.y = (vm.attribute.font.y / 25.4 * vm.windowDpiW).toFixed(2);
                            }
                            if (vm.unit == 'px') {
                                textbox.geometry.y = vm.attribute.font.y;
                            }
                            break;
                        }
                    }
                }
            }
        })

        //锁定
        $scope.xmlLock = function(){
            if (!vm.xmlSelect.type) {
                MessageService.notice('请选择一个元素');
                return
            }
            if(vm.xmlSelect.type == 'imageart'){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].imageart){
                        var _imageart = vm.data.textDocument.levels.level[i].imageart;
                        if(_imageart.selected){
                            _imageart.lock = !_imageart.lock;
                            vm.xmlSelect.lock = _imageart.lock;
                            break
                        }
                    }
                }
            }
            if(vm.xmlSelect.type == 'textbox'){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox){
                        var _textbox = vm.data.textDocument.levels.level[i].textbox;
                        if(_textbox.selected){
                            _textbox.lock = !_textbox.lock;
                            vm.xmlSelect.lock = _textbox.lock;
                            break
                        }
                    }
                }
            }
            cookie()
        }
        //复制
        $scope.xmlCopy = function(){
            if (!vm.xmlSelect.type) {
                MessageService.notice('请选择一个元素');
                return
            }
            xmlCopy()
        }
        var keyDownTimer = null;
        //键盘事件
        document.onkeydown = function(event){
            //ctrl + c
            if (event.ctrlKey && window.event.keyCode==67){
                if(event.target.nodeName=='TEXTAREA'){
                    return
                }
                return false;
            }
            //ctrl + v
            if (event.ctrlKey && window.event.keyCode==86){
                if(event.target.nodeName=='TEXTAREA'){
                    return
                }
                xmlCopy();
                $scope.$digest();
                return false;
            }
            // 删除
            if (event.keyCode == 46) {
                xmlDelete();
                $scope.$digest();
            }
            //空格
            if(event.keyCode === 32){
                if(vm.moveCanvasView) return false;
                $timeout(function(){
                    vm.moveCanvasView = true;
                })
                return false;
            }
            //F11
            if (event.keyCode===122 && !vm.isFullScreen) {
                event.preventDefault();
                $scope.fullScreen();
            }
            // ctrl+z
            if (event.keyCode == 90 && event.ctrlKey && !vm.textEditor) {
                $scope.xmlRevocation(event);
                $scope.$digest();
            }
        }
        document.onkeyup = function(){
            if(event.keyCode === 32){
                $timeout(function(){
                    vm.moveCanvasView = false;
                })
            }

        }
        //移动画布
        $scope.moveCanvas = function(e){
            if(!vm.moveCanvasView) return;
            var moveCanvasInfo = angular.copy(vm.moveCanvasInfo);
            moveCanvas(e,function(x,y){
                $timeout(function(){
                    vm.moveCanvasInfo.x = moveCanvasInfo.x + x;
                    vm.moveCanvasInfo.y = moveCanvasInfo.y + y;
                })

            })
        }
        //画布位置x
        $scope.canvasCoordX = function(width){
            if(!width) return 0;
            var canvasWidth = $("#canvas").width();
            var x = (canvasWidth - width) / 2 + vm.moveCanvasInfo.x;

            return x + "px";
        }
        //画布位置Y
        $scope.canvasCoordY = function(height){
            if(!height) return 0;
            var canvasHeight = $("#canvas").height();
            var y = (canvasHeight - height) / 2 + vm.moveCanvasInfo.y;
            return y + "px";
        }
        /**
         * 画布放大、缩小、还原
         * @param {*} type  0 还原 1缩小 2放大  3原尺寸
         */
        $scope.changeCanvasSize = function(type){
            $timeout(function(){
                //步长
                var stepSize = 0.02;
                //还原
                if(type === 0){
                    initWindow();
                    vm.initMoveCanvasInfo();
                }
                //1:1 原尺寸
                if(type === 3){
                    vm.ratio = 1;
                    vm.initMoveCanvasInfo();
                }
                // 缩小
                if(type === 1){
                    vm.ratio = vm.ratio - stepSize;
                    //限制最小缩放比不能小于0.5倍的初始尺寸
                    if(vm.ratio < (0.5 * vm.initRatio)) vm.ratio = 0.5 * vm.initRatio;
                }
                //放大
                if(type === 2){
                    vm.ratio = vm.ratio + stepSize;
                    //限制放大倍数不能大于1.5倍的初始尺寸
                    if(vm.ratio > (1.5 * vm.initRatio)) vm.ratio = 1.5 * vm.initRatio;
                }
            })
        }
        function originalEventRatio(){
            if (event.ctrlKey === true || event.metaKey) {
                var wheelDelta = event.wheelDelta ? (event.wheelDelta / 120) : (-event.detail / 3);
                if (wheelDelta > 0) {
                    $scope.changeCanvasSize(2);
                } else {
                    $scope.changeCanvasSize(1);
                }
                $scope.$apply();
                event.preventDefault();
            }
        }
        //鼠标滚轮事件
        window.addEventListener('mousewheel',originalEventRatio,{passive: false});
        $scope.$on('$destroy',function () {
            //取消监听事件
            window.removeEventListener('mousewheel',originalEventRatio)
        });
        function xmlCopy(){
            var _level = "";
            if(vm.xmlSelect.type == 'imageart'){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].imageart) {
                        var _imageart = vm.data.textDocument.levels.level[i].imageart;
                        if (_imageart.selected) {
                            _level = angular.copy(vm.data.textDocument.levels.level[i]);
                            break
                        }
                    }
                }
                if(_level){
                    _level.imageart.selected = false;
                    _level.imageart.geometry.x = Number(_level.imageart.geometry.x) + Number(10);
                    _level.imageart.geometry.y = Number(_level.imageart.geometry.y) + Number(10);
                    _level.seq = Number(vm.data.textDocument.levels.level.length) + Number(1);
                    vm.data.textDocument.levels.level.push(_level)
                }
                cookie()
            }
            if(vm.xmlSelect.type == 'textbox'){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var _textbox = vm.data.textDocument.levels.level[i].textbox;
                        if (_textbox.selected) {
                            _level = angular.copy(vm.data.textDocument.levels.level[i]);
                            break
                        }
                    }
                }
                if(_level){
                    vm.ctrlKeyArray = [];
                    _level.textbox.selected = false;
                    _level.textbox.ctrlKey = false;
                    _level.textbox.geometry.x = Number(_level.textbox.geometry.x) + Number(10);
                    _level.textbox.geometry.y = Number(_level.textbox.geometry.y) + Number(10);
                    _level.seq = Number(vm.data.textDocument.levels.level.length) + Number(1);
                    vm.data.textDocument.levels.level.push(_level)
                }
                cookie()
            }
        }
        //上移一层
        $scope.seqUp = function(event){
            seqTier('seqUp');
            fn(event);
        }
        //下移一层
        $scope.seqDown = function(event){
            seqTier('seqDown');
            fn(event);
        }
        //置顶
        $scope.seqStick = function(event){
            seqTier('seqStick');
            fn(event);
        }
        //置底
        $scope.seqBottom = function(event){
            seqTier('seqBottom');
            fn(event);
        }
        function seqTier(data){
            if(vm.xmlSelect.type == 'imageart'){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].imageart) {
                        var _imageart = vm.data.textDocument.levels.level[i].imageart;
                        if (_imageart.selected) {
                            var _seq = Number(vm.data.textDocument.levels.level.length) + Number(1);
                            if(data == 'seqUp'){
                                if(vm.data.textDocument.levels.level[i].seq >= _seq){
                                    MessageService.error("已在最顶层");
                                    return
                                }
                                vm.data.textDocument.levels.level[i].seq > _seq ? _seq : vm.data.textDocument.levels.level[i].seq++;
                            }
                            if(data == 'seqDown'){
                                if(vm.data.textDocument.levels.level[i].seq == 0){
                                    MessageService.error("已在最底层");
                                    return
                                }
                                vm.data.textDocument.levels.level[i].seq < 1 ? 0 : vm.data.textDocument.levels.level[i].seq--
                            }
                            if(data == 'seqStick'){
                                if(vm.data.textDocument.levels.level[i].seq >= _seq){
                                    MessageService.error("已在最顶层");
                                    return
                                }
                                vm.data.textDocument.levels.level[i].seq = _seq
                            }
                            if(data == 'seqBottom'){
                                if(vm.data.textDocument.levels.level[i].seq == 0){
                                    MessageService.error("已在最底层");
                                    return
                                }
                                vm.data.textDocument.levels.level[i].seq = 0
                            }
                            break
                        }
                    }
                }
            }
            if(vm.xmlSelect.type == 'textbox'){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var _textbox = vm.data.textDocument.levels.level[i].textbox;
                        if (_textbox.selected) {
                            if(data == 'seqUp'){
                                if(vm.data.textDocument.levels.level[i].seq == 99){
                                    MessageService.error("已在最顶层");
                                    return
                                }
                                vm.data.textDocument.levels.level[i].seq > 99 ? 99 : vm.data.textDocument.levels.level[i].seq++;
                            }
                            if(data == 'seqDown'){
                                if(vm.data.textDocument.levels.level[i].seq == 0){
                                    MessageService.error("已在最底层");
                                    return
                                }
                                vm.data.textDocument.levels.level[i].seq < 1 ? 0 : vm.data.textDocument.levels.level[i].seq--;
                            }
                            if(data == 'seqStick'){
                                if(vm.data.textDocument.levels.level[i].seq == 99){
                                    MessageService.error("已在最顶层");
                                    return
                                }
                                vm.data.textDocument.levels.level[i].seq = 99;
                            }
                            if(data == 'seqBottom'){
                                if(vm.data.textDocument.levels.level[i].seq == 0){
                                    MessageService.error("已在最底层");
                                    return
                                }
                                vm.data.textDocument.levels.level[i].seq = 0;
                            }
                            break
                        }
                    }
                }
            }
            cookie()
        }

        //删除
        $scope.xmlDelete = function(){
            if (!vm.xmlSelect.type) {
                MessageService.notice('请选择一个元素');
                return
            }
            xmlDelete()
        }
        function xmlDelete(){
            var level = angular.copy(vm.data.textDocument.levels.level);
            if(vm.xmlSelect.type == 'imageart'){
                for(var i=0; i<level.length; i++){
                    if(level[i].imageart){
                        var _imageart = level[i].imageart;
                        if(_imageart.selected){
                            level.splice(i, 1)
                            break
                        }
                    }
                }
                vm.data.textDocument.levels.level = [];
                $timeout(function () {
                    vm.data.textDocument.levels.level = level;
                    cookie()
                })
            }
            if(vm.xmlSelect.type == 'textbox'){
                for(var i=0; i<level.length; i++){
                    if(level[i].textbox){
                        var _textbox = level[i].textbox;
                        if(_textbox.selected){
                            level.splice(i, 1)
                            break
                        }
                    }
                }
                vm.data.textDocument.levels.level = [];
                $timeout(function () {
                    vm.data.textDocument.levels.level = level;
                    cookie()
                })
            }
        }
        //上一步
        $scope.xmlRevocation = function(){
            vm.data.textDocument.levels.level = [];
            $timeout(function () {
                if(vm.cookies.length<2){
                    vm.data = angular.copy(vm.dataInit);
                    vm.cookies=[];
                    return
                }
                vm.data = vm.cookies[vm.cookies.length-2];
                vm.cookies.splice(1)
            })
            clear()
        };
        //恢复
        $scope.xmlNextStep = function(){
            $timeout(function () {
                vm.data = angular.copy(vm.dataInit);
                vm.cookies = [];
            })
            clear()
        };
        vm.isFullScreen = false;
        function getFullState(){
            $timeout(function () {
                vm.isFullScreen = Math.abs(window.screen.height-window.document.documentElement.clientHeight) <= 17;
            })
        }
        getFullState();
        //全屏
        $scope.fullScreen = function(){
            var el = document.documentElement
            var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen
            if (rfs) { // typeof rfs != "undefined" && rfs
                rfs.call(el)
            }else if (typeof window.ActiveXObject !== 'undefined') {
                // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
                var wscript = new ActiveXObject('WScript.Shell')
                if (wscript != null) {
                    wscript.SendKeys('{F11}')
                }
            }
        }
        $scope.exitScreen = function(){
            var el = document
            var cfs = el.cancelFullScreen || el.mozCancelFullScreen || el.msExitFullscreen || el.webkitExitFullscreen || el.exitFullscreen
            if (cfs) { // typeof cfs != "undefined" && cfs
                cfs.call(el)
            } else if (typeof window.ActiveXObject !== 'undefined') {
                // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
                var wscript = new ActiveXObject('WScript.Shell')
                if (wscript != null) {
                    wscript.SendKeys('{F11}')
                }
            }
        }
        //透明度
        vm.isTransparency = false;
        $scope.transparencyCb = function ($event) {
            if (!vm.xmlSelect.type) {
                MessageService.notice('请选择一个元素');
                return
            }
            var arrt = vm.isTransparency;
            clearBubble();
            vm.isTransparency = !arrt;
            if(vm.isTransparency){
                vm.xmlSelect.transparency1 = Math.round(vm.xmlSelect.transparency * 100);
            }
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }
        $scope.transparencyChange1 = function(){
            $timeout(function () {
                if(vm.xmlSelect.transparency1 === '' || vm.xmlSelect.transparency1 === null || vm.xmlSelect.transparency1<0){
                    vm.xmlSelect.transparency1 = 0
                }
                if(vm.xmlSelect.transparency1 > 100){
                    vm.xmlSelect.transparency1 = 100
                }
                vm.xmlSelect.transparency = vm.xmlSelect.transparency1 / 100;
                if(vm.xmlSelect.type == 'imageart'){
                    for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                        if(vm.data.textDocument.levels.level[i].imageart) {
                            var _imageart = vm.data.textDocument.levels.level[i].imageart;
                            if (_imageart.selected) {
                                _imageart.transparency = vm.xmlSelect.transparency;
                                break
                            }
                        }
                    }
                }
                if(vm.xmlSelect.type == 'textbox'){
                    for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                        if(vm.data.textDocument.levels.level[i].textbox) {
                            var _textbox = vm.data.textDocument.levels.level[i].textbox;
                            if (_textbox.selected) {
                                _textbox.transparency = vm.xmlSelect.transparency;
                                break
                            }
                        }
                    }
                }
            })
        }
        window.onclick = function (e) {
            $timeout(function () {
                clearBubble();
            })
        }
        var isTransparency = false;
        $scope.transparencydown = function($event){
            fn($event)
            var x = $event.clientX;
            //获取透明度
            var transparency = vm.xmlSelect.transparency;

            //开关打开
            isTransparency = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isTransparency == false) {
                    return;
                }
                var e = e || window.event;
                //防止浏览器默认行为(W3C)
                if(e && e.preventDefault){
                    e.preventDefault();
                }
                //IE中组织浏览器行为
                else {
                    window.event.returnValue = false;
                    return false;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;
                vm.xmlSelect.transparency = Math.ceil(Number(transparency) + Number(nl));
                if(vm.xmlSelect.transparency<0){
                    vm.xmlSelect.transparency = 0;
                }
                if(vm.xmlSelect.transparency > 100){
                    vm.xmlSelect.transparency = 100;
                }
                $("#c-xml-action-div-transparency-bar").css({
                    'left': vm.xmlSelect.transparency
                });
                if(vm.xmlSelect.type == 'imageart'){
                    for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                        if(vm.data.textDocument.levels.level[i].imageart) {
                            var _imageart = vm.data.textDocument.levels.level[i].imageart;
                            if (_imageart.selected) {
                                _imageart.transparency = vm.xmlSelect.transparency / 100;
                                break
                            }
                        }
                    }
                }
                if(vm.xmlSelect.type == 'textbox'){
                    for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                        if(vm.data.textDocument.levels.level[i].textbox) {
                            var _textbox = vm.data.textDocument.levels.level[i].textbox;
                            if (_textbox.selected) {
                                _textbox.transparency = vm.xmlSelect.transparency / 100;
                                break
                            }
                        }
                    }
                }
            }
            //鼠标抬起事件
            window.onmouseup = function (e) {
                isTransparency = false;
            };
            cookie()
        }

        $scope.changeTransparency = function($event){
            fn($event)
            if(vm.xmlSelect.type == 'imageart'){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].imageart) {
                        var _imageart = vm.data.textDocument.levels.level[i].imageart;
                        if (_imageart.selected) {
                            _imageart.transparency = vm.xmlSelect.transparency;
                            break
                        }
                    }
                }
            }
            if(vm.xmlSelect.type == 'textbox'){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var _textbox = vm.data.textDocument.levels.level[i].textbox;
                        if (_textbox.selected) {
                            _textbox.transparency = vm.xmlSelect.transparency;
                            break
                        }
                    }
                }
            }
            vm.xmlSelect.transparency1 = Math.round(vm.xmlSelect.transparency * 100);
        }

        //字体family
        vm.selectFont = '';
        vm.selectFontBtn = fontInit;
        vm.selectFontName = selectFontName;
        function fontInit(){
            vm.fontList1 = [];
            FontManagement.getAll3({family: vm.selectFont}, function (res) {
                vm.fontList1 = res;
            });
        }
        function selectFontName(item){
            if(item){
                for(var i=0;i<vm.fontList.length; i++){
                    if(vm.fontList[i].uuid == item){
                        return vm.fontList[i].family
                    }
                }
            }
        }
        $scope.operationFontFamily = function (item) {
            vm.font.fontuuid = item.uuid;
            var _fontStyle = [];
            for(var i=0; i<vm.fontList.length; i++){
                if(vm.fontList[i].uuid == item.uuid){
                    for(var j=0; j<vm.fontList[i].fontStyles.length; j++){
                        _fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            if(_fontStyle.indexOf('NORMAL') != '-1'){
                vm.font.style.bold = false;
                vm.font.style.italic = false;
            }else {
                if(_fontStyle.indexOf('BOLD_ITALIC') != '-1'){
                    vm.font.style.bold = true;
                    vm.font.style.italic = true;
                }else {
                    if(_fontStyle.indexOf('ITALIC') != '-1'){
                        vm.font.style.italic = true;
                        vm.font.style.bold = false;
                    }
                    if(_fontStyle.indexOf('BOLD') != '-1'){
                        vm.font.style.bold = true;
                        vm.font.style.italic = false;
                    }
                }
            }
            getFont(vm.font)
            fontEdit();
            sizeHeight();
            fontStyle();
        }
        //字体
        $scope.operationFont = function () {
            vm.font.style.lineheight = parseInt(vm.font.style.size);
            fontEdit();
            sizeHeight();
        }
        //斜体
        $scope.operationItalic = function () {
            if(vm.font.fontStyle.indexOf('NORMAL') != '-1'){
                vm.font.style.italic = !vm.font.style.italic;
                fontEdit();
                sizeHeight();
            }else {
                if(vm.font.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.font.fontStyle.indexOf('BOLD') != '-1'){
                    if(vm.font.style.bold){
                        vm.font.style.italic = !vm.font.style.italic;
                        fontEdit();
                        sizeHeight();
                    }
                }
            }
        }
        //粗体
        $scope.operationBold = function () {
            if(vm.font.fontStyle.indexOf('NORMAL') != '-1'){
                vm.font.style.bold = !vm.font.style.bold;
                fontEdit();
                sizeHeight();
            }else {
                if(vm.font.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.font.fontStyle.indexOf('ITALIC') != '-1'){
                    if(vm.font.style.italic){
                        vm.font.style.bold = !vm.font.style.bold;
                        fontEdit();
                        sizeHeight();
                    }
                }
            }
        }
        //间距
        vm.isOperationSpacing = false
        $scope.operationSpacing = function ($event) {
            var arrt = vm.isOperationSpacing;
            clearBubble();
            vm.isOperationSpacing = !arrt;
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }
        //字间距
        var isWordSpace = false;
        $scope.wordSpacedown = function($event){
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var x = $event.clientX;
            //获取字间距
            var letterspacing = parseInt(vm.font.style.letterspacing);

            //开关打开
            isWordSpace = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isWordSpace == false) {
                    return;
                }
                var e = e || window.event;
                //防止浏览器默认行为(W3C)
                if(e && e.preventDefault){
                    e.preventDefault();
                }
                //IE中组织浏览器行为
                else {
                    window.event.returnValue = false;
                    return false;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;
                vm.font.style.letterspacing = Math.ceil(Number(letterspacing) + Number(nl));
                if(vm.font.style.letterspacing<0){
                    vm.font.style.letterspacing = 0;
                }
                if(vm.font.style.letterspacing > 100){
                    vm.font.style.letterspacing = 100;
                }
                $("#c-xml-wordSpace").css({
                    'left': vm.font.style.letterspacing
                });
                sizeHeight();
            }
            //鼠标抬起事件
            window.onmouseup = function (e) {
                isWordSpace = false;
            };
        }
        $scope.wordSpacedown1 = function ($event) {
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var x = $event.clientX;
            // var x1 = $event.originalEvent.changedTouches[0].clientX;
            // console.log(x)
            // console.log(x1)
        }
        //行间距
        var isLineSpace = false;
        $scope.lineSpacedown = function($event){
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var x = $event.clientX;
            //获取字间距
            var lineheight = parseInt(vm.font.style.lineheight);

            //开关打开
            isLineSpace = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isLineSpace == false) {
                    return;
                }
                var e = e || window.event;
                //防止浏览器默认行为(W3C)
                if(e && e.preventDefault){
                    e.preventDefault();
                }
                //IE中组织浏览器行为
                else {
                    window.event.returnValue = false;
                    return false;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;
                vm.font.style.lineheight = Math.ceil(Number(lineheight) + Number(nl));
                if(vm.font.style.lineheight<0){
                    vm.font.style.lineheight = 0;
                }
                if(vm.font.style.lineheight > 100){
                    vm.font.style.lineheight = 100;
                }
                $("#c-xml-lineSpace").css({
                    'left': vm.font.style.lineheight
                });
                sizeHeight();
            }
            //鼠标抬起事件
            window.onmouseup = function (e) {
                isLineSpace = false;
            };
        }

        $scope.changeLetterspacing = function(){
            vm.font.style.letterspacing = Number(vm.font.style.letterspacing)
            sizeHeight();
        }
        $scope.changeLineheight = function() {
            vm.font.style.lineheight = Number(vm.font.style.lineheight)
            sizeHeight();
        }

        $scope.$watch("vm.font.style.lineheight",function(newValue,oldValue){
            if(oldValue && vm.font){
                if(vm.font.style && vm.font.style.size && newValue < parseInt(vm.font.style.size)){
                    vm.font.style.lineheight = parseInt(vm.font.style.size);
                }
                sizeHeight();
            }
        })

        function sizeHeight(){
            $timeout(function () {
                var _h = Number($(".c-xml-page-textboxe-pitch").find('.c-xml-page-textboxe-text span').height()) + Number(5);
                // var _h = Number($("#c-xml-page-material-fontId").height()) + Number(5);
                // var _w = Number($(".c-xml-page-textboxe-pitch").find('.c-xml-page-textboxe-text').width()) + Number(5);
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var _textbox = vm.data.textDocument.levels.level[i].textbox;
                        if (_textbox.selected) {
                            _textbox.geometry.height = _h / vm.ratio;
                            // _textbox.geometry.width = _w / vm.ratio;
                            if(_textbox.geometry.height > vm.data.textDocument.geometry.height){
                                _textbox.geometry.height = vm.data.textDocument.geometry.height;
                                // _textbox.style.size = parseInt(_textbox.style.size * (_textbox.geometry.height / _h * vm.ratio));
                            }
                            if(Number(_textbox.geometry.height) + Number(_textbox.geometry.y) > vm.data.textDocument.geometry.height){
                                _textbox.geometry.y = (vm.data.textDocument.geometry.height - _textbox.geometry.height);
                            }
                            break
                        }
                    }
                }
            })
        }
        function sizeHeight1(callback) {
            if (vm.textEditor) {
                $timeout(function () {
                    var _h = Number($("#c-xml-page-material-fontId2").height()) + Number(10);
                    var _w = Number($("#c-xml-page-material-fontId2").width()) + Number(10);
                    if(!writingmodeInit(vm.textEditor.style.writingmode)){
                        vm.textEditor.geometry.height = _h;
                    }
                    if(writingmodeInit(vm.textEditor.style.writingmode)){
                        vm.textEditor.geometry.width = _w;
                    }
                    for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                        if(vm.data.textDocument.levels.level[i].textbox) {
                            var _textbox = vm.data.textDocument.levels.level[i].textbox;
                            if (_textbox.selected) {
                                _textbox = vm.textEditor;
                                console.log(_textbox)
                                break
                            }
                        }
                    }
                    if(callback){callback()};
                })
            }
        }
        $scope.writingmodeInit = writingmodeInit;
        function writingmodeInit(item) {
            if (item == 'vertical-lr' || item == 'vertical-rl') {
                return true
            } else {
                return false
            }
        }

        //左对齐
        $scope.operationBarLeft = function ($event) {
            vm.font.style.align = 'left';
            // vm.font.geometry.x = 10;
            fontEdit()
        }
        //居中对齐
        $scope.operationBarCenter = function ($event) {
            vm.font.style.align = 'center';
            // vm.font.geometry.x = (vm.data.textDocument.geometry.width - vm.font.geometry.width) / 2;
            fontEdit()
        }
        //右对齐
        $scope.operationBarRight = function ($event) {
            vm.font.style.align = 'right';
            // vm.font.geometry.x = (vm.data.textDocument.geometry.width - vm.font.geometry.width) - 10;
            fontEdit()
        }
        //两端对齐
        $scope.operationBarJustify = function ($event) {
            vm.font.style.align = 'justified';
            fontEdit()
        }
        //颜色
        $scope.operationColorOpen = function($event){
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            vm.isOperationColor = true;
        }
        $scope.operationColor = function(item){
            vm.font.style.color = item.id;
            fontEdit()
        }
        $scope.chooseColor = function () {
            fontEdit();
        }


        //缓存每一步
        vm.operation = false;
        function cookie(){
            var _data = angular.copy(vm.data);
            if(_data.textDocument.levels.level){
                for(var i=0; i<_data.textDocument.levels.level.length; i++){
                    var _levels = _data.textDocument.levels.level[i];
                    if(_levels.imageart){
                        _levels.imageart.selected = false;
                    }
                    if(_levels.textbox){
                        _levels.textbox.selected = false;
                        _levels.textbox.ctrlKey = false;
                        _levels.textbox.loading = false;
                    }
                }
                vm.cookies.push(_data);
                vm.cookieIndex = vm.cookies.length - 1;
                vm.operation = true;
            }
        }

        function addElement(type, data) {
            var _seq = Number(vm.data.textDocument.levels.level.length) + Number(1);
            if(type == 'imageart'){
                var _level = {
                    seq: _seq,
                    imageart: null
                }
                _level.imageart = data;
                vm.data.textDocument.levels.level.push(_level);
            }
            if(type == 'textbox'){
                var _level = {
                    seq: _seq,
                    textbox: null
                }
                _level.textbox = data;
                vm.data.textDocument.levels.level.push(_level);
            }
        }

        //可编辑
        $scope.editableTrue = function(){
            editableInit(true)
        }
        //不可编辑
        $scope.editableFalse = function(){
            editableInit(false)
        }
        function editableInit(data) {
            vm.xmlSelect.editable = data;
            if(vm.xmlSelect.type == ''){
                vm.data.textDocument.editable = data;
            }
            if(vm.xmlSelect.type == 'imageart'){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].imageart) {
                        var _imageart = vm.data.textDocument.levels.level[i].imageart;
                        if (_imageart.selected) {
                            _imageart.editable = data;
                            break
                        }
                    }
                }
            }
            if(vm.xmlSelect.type == 'textbox'){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].textbox) {
                        var _textbox = vm.data.textDocument.levels.level[i].textbox;
                        if (_textbox.selected) {
                            _textbox.editable = data;
                            break
                        }
                    }
                }
            }
        }

        var fontuuids = [];
        function getFont(item){
            if(!fontuuids){
                fontuuids = [];
            }
            if(fontuuids.indexOf(item.fontuuid) == -1) {
                fontuuids.push(item.fontuuid);
                item.loading = true;
                FontManagement.getCss({uuid: item.fontuuid}, function (res) {
                    var style=document.createElement("style");
                    style.type='text/css';
                    try{
                        style.appendChild(document.createTextNode(res.message));
                    }catch(ex){
                        style.stylesheet.cssText=res.message;
                    }
                    var Head=document.getElementsByTagName("head")[0];
                    Head.appendChild(style);
                    document.fonts.ready.then(function() {
                        item.loading = false;
                        $scope.$digest();
                    });
                });
            }
        }

        //保存至作品
        $scope.save = function () {
            selectCancel();
            vm.loading = true;
            setTimeout(function () {
                var _data = unitMm();
                var nodes = document.getElementById('c-xml');
                html2canvas_adapt(nodes, {
                    onrendered: function(canvas) {
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);

                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', 'font-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                _data.cover = vm.url + data.key;
                                if(_data.id){
                                    FontXmls.update(_data, function (res) {
                                        MessageService.success("保存成功");
                                        // $state.go('textList')
                                        history.go(-1)
                                    })
                                }else{
                                    FontXmls.save(_data, function (res) {
                                        MessageService.success("保存成功");
                                        $state.go('text')
                                        // history.go(-1)
                                    })
                                }
                                vm.loading = false;
                                $scope.$digest();
                            },
                            error: function (request) {
                                MessageService.error("文件上传失败");
                            }
                        });
                    },
                    letterRendering: true,
                    useCORS: true,
                    logging: false
                });
            })
        }
        //保存并发布
        $scope.publish = function () {
            selectCancel()
            setTimeout(function () {
                var _data = unitMm();
                var nodes = document.getElementById('c-xml');
                html2canvas_adapt(nodes, {
                    onrendered: function(canvas) {
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);

                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', 'font-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                _data.cover = vm.url + data.key;
                                FontXmls.release(_data, function (res) {
                                    MessageService.success("保存发布成功");
                                    // $state.go('textList')
                                    history.go(-1)
                                })
                                $scope.$digest();
                            },
                            error: function (request) {
                                MessageService.error("文件上传失败");
                            }
                        });
                    },
                    letterRendering: true,
                    useCORS: true,
                    logging: false
                });
            })
        }

        $scope.out = function () {
            if(!vm.operation){
                history.go(-1)
            }else{
                MessageService.confirm({
                    title:'温馨提示',
                    goback:'取消',
                    cancel:'不保存',
                    confirm:'保存',
                    msg:'退出前是否需要保存已变更的内容?',
                },function(){
                    $scope.save();
                },function (flag) {
                    if(flag){
                        history.go(-1)
                    }
                })
            }
        }

        function clear() {
            vm.isFont = false;
            vm.xmlSelect = {
                type: '',
                transparency: 0,
                editable: false
            };
        }

        $scope.selectCancel = selectCancel;
        function selectCancel() {
            if (vm.textEditor) {
                return
            }
            if(vm.data.textDocument.levels.level){
                for(var i=0; i<vm.data.textDocument.levels.level.length; i++){
                    if(vm.data.textDocument.levels.level[i].imageart) {
                        vm.data.textDocument.levels.level[i].imageart.selected = false;
                    }
                }
                for(var i1=0 ;i1<vm.data.textDocument.levels.level.length; i1++){
                    if(vm.data.textDocument.levels.level[i1].textbox) {
                        var _font = vm.data.textDocument.levels.level[i1].textbox;
                        _font.selected = false;
                        _font.ctrlKey = false;
                    }
                }
            }
            clear();
            vm.ctrlKeyArray = [];
        }

        function dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }

        function unitMm() {
            var _w = 25.4 / vm.windowDpiW;
            var _h = 25.4 / vm.windowDpiH;
            var _data = angular.copy(vm.data);
            _data.textDocument.geometry = {
                x: (_data.textDocument.geometry.x * _w).toFixed(2),
                y: (_data.textDocument.geometry.y * _h).toFixed(2),
                width: (_data.textDocument.geometry.width * _w).toFixed(2),
                height: (_data.textDocument.geometry.height * _h).toFixed(2)
            }
            _data.textDocument.background.offsetx= (_data.textDocument.background.offsetx * _w).toFixed(2);
            _data.textDocument.background.offsety= (_data.textDocument.background.offsety * _h).toFixed(2);
            if(_data.textDocument.levels.level){
                for(var i=0; i<_data.textDocument.levels.level.length; i++){
                    var _level = _data.textDocument.levels.level[i];
                    if(_level.textbox){
                        _level.textbox.geometry = {
                            x: (_level.textbox.geometry.x * _w).toFixed(2),
                            y: (_level.textbox.geometry.y * _h).toFixed(2),
                            width: (_level.textbox.geometry.width * _w).toFixed(2),
                            height: (_level.textbox.geometry.height * _h).toFixed(2)
                        }
                        delete _level.textbox.style.bold1;
                        delete _level.textbox.style.italic1;
                    }
                    if(_level.imageart){
                        _level.imageart.geometry = {
                            x: (_level.imageart.geometry.x * _w).toFixed(2),
                            y: (_level.imageart.geometry.y * _h).toFixed(2),
                            width: (_level.imageart.geometry.width * _w).toFixed(2),
                            height: (_level.imageart.geometry.height * _h).toFixed(2)
                        }
                        _level.imageart.offsetx = (_level.imageart.offsetx * _w).toFixed(2);
                        _level.imageart.offsety = (_level.imageart.offsety * _h).toFixed(2);
                        // _level.imageart.resource.width = (_level.imageart.resource.width * _w).toFixed(2);
                        // _level.imageart.resource.height = (_level.imageart.resource.height * _h).toFixed(2);
                    }
                }
            }
            return _data
        }
        function unitPx(item) {
            var _w = vm.windowDpiW / 25.4;
            var _h = vm.windowDpiH / 25.4;
            var _data = angular.copy(item);
            _data.textDocument.geometry = {
                x: (_data.textDocument.geometry.x * _w).toFixed(2),
                y: (_data.textDocument.geometry.y * _h).toFixed(2),
                width: (_data.textDocument.geometry.width * _w).toFixed(2),
                height: (_data.textDocument.geometry.height * _h).toFixed(2)
            }
            _data.textDocument.background.offsetx= (_data.textDocument.background.offsetx * _w).toFixed(2);
            _data.textDocument.background.offsety= (_data.textDocument.background.offsety * _h).toFixed(2);
            if(_data.textDocument.levels.level){
                for(var i=0; i<_data.textDocument.levels.level.length; i++){
                    var _level = _data.textDocument.levels.level[i];
                    if(_level.textbox){
                        _level.textbox.geometry = {
                            x: (_level.textbox.geometry.x * _w).toFixed(2),
                            y: (_level.textbox.geometry.y * _h).toFixed(2),
                            width: (_level.textbox.geometry.width * _w).toFixed(2),
                            height: (_level.textbox.geometry.height * _h).toFixed(2)
                        }
                    }
                    if(_level.imageart){
                        _level.imageart.geometry = {
                            x: (_level.imageart.geometry.x * _w).toFixed(2),
                            y: (_level.imageart.geometry.y * _h).toFixed(2),
                            width: (_level.imageart.geometry.width * _w).toFixed(2),
                            height: (_level.imageart.geometry.height * _h).toFixed(2)
                        }
                        _level.imageart.offsetx = (_level.imageart.offsetx * _w).toFixed(2);
                        _level.imageart.offsety = (_level.imageart.offsety * _h).toFixed(2);
                        imgWH(_level.imageart)
                    }
                }
            }else {
                _data.textDocument.levels.level = [];
            }
            return _data
        }

        function imgWH(data) {
            $http({url: data.resource.identifier + '?imageInfo'}).success(function (res) {
                data.resource.width = res.width;
                data.resource.height = res.height;
            }).error(function (error) {});
        }


        // 监听顶部操作栏
        vm.moreDisplay = false;
        vm.moreIcon = false;
        vm.moreDisplayFont = false;
        $(window).resize(function(){
            if ($('#nav-header').outerWidth() <= 1200) {
                vm.moreIcon = true;
            }else{
                vm.moreIcon = false;
                vm.moreDisplay = false;
                vm.moreDisplayFont = false;
            }
        })

        $scope.moreDisplay = function(){
            vm.moreDisplay = !vm.moreDisplay;
        }

        $scope.moreDisplayFont = function(){
            vm.moreDisplayFont = !vm.moreDisplayFont;
        }


        //对齐点击
        vm.isOperationDir = false
        $scope.operationDir = function (attr) {
            vm.isOperationDir = attr;
            vm.isOperationSpacing = false;
            vm.isAlignAt = false;
        }
        vm.isAlignAt = false;
        $scope.operationAlignAt = function(event){
            fn(event)
            var arrt = vm.isAlignAt;
            clearBubble();
            vm.isAlignAt = !arrt;
        }

        vm.isCoverage = false;
        $scope.coverageSelect = function(event){
            if (!vm.xmlSelect.type) {
                MessageService.notice('请选择一个元素');
                return
            }
            fn(event)
            var arrt = vm.isCoverage;
            clearBubble();
            vm.isCoverage = !arrt;
        }


        vm.uploadType = "";
        function thumbnailatorPic() {
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics = [];
                if (values) {
                    //for (var j = 0; j < values.length; j++) {
                    //var value = values[j];
                    //var data = {key: value.key};
                    console.log(vm.uploadType)
                    for (var i = 0; i < values.length; i++) {
                        getBgImg(values, i, vm.uploadType);
                    }
                    //}
                }
                vm.uploadType = "";
            }, function () {
            });false
        }

        $timeout(function(){
            $('#navId').on('scroll', function(event){
                if(vm.navPitch == 'background'){
                    var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                    if(!vm.isBgImgData){
                        if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                            $scope.bgImgPageing();
                        }
                    }
                }
                if(vm.navPitch == 'material'){
                    var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                    if(!vm.isMaterialData){
                        if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                            $scope.materialPageing();
                        }
                    }
                }
            });
        })

        $scope.fn = fn;
        function fn($event){
            var $event = $event || window.event;
            $event.stopPropagation();
        }


        function clearBubble(){
            vm.isTransparency = false;
            vm.isOperationSpacing = false;
            vm.isOperationColor = false;
            vm.isAlignAt = false;
            vm.isCoverage = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('xml-center', {
                parent: 'app',
                url: '/xmlCenter',
                data: {
                    authorities: [],
                    pageTitle: 'platformAppTitle.xmlCenter'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/page/xml-center.html',
                        controller: 'XmlCenterController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('title');
                        return $translate.refresh();
                    }]
                }
            })
            .state('purchased-template', {
                parent: 'app',
                url: '/purchased-template',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/page/purchased-template.html',
                        controller: 'PurchasedTemplateController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('page', {
                parent: 'app',
                url: '/page',
                data: {
                    authorities: [],
                    pageTitle: 'platformAppTitle.page'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/page/page.html',
                        controller: 'PageController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('title');
                        return $translate.refresh();
                    }]
                }
            })
            .state('page-my-suffix', {
                url: '/page-my-suffix',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/entities/page/pagesmySuffix.html',
                        controller: 'PageMySuffixController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('page-my-suffix-edit', {
                url: '/page-my-suffix/{id}?new',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/entities/page/pagesmySuffix.html',
                        controller: 'PageMySuffixController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            //先弹窗
            .state('xml-center-copy', {
                parent: 'xml-center',
                url: '/xml-center/{copy}/copy',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                onEnter:['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                    $uibModal.open({
                        templateUrl: 'app/entities/page/page-create.html',
                        controller: 'PageCreateController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            items:null,
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('page');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        // $state.go('page-my-suffix-copy', null, { reload: true });
                    }, function() {
                        $state.go('xml-center');
                    });
                }]
            })
            .state('page-copy', {
                parent: 'templateList',
                url: '/page-copy/{copy}',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                onEnter:['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                    $uibModal.open({
                        templateUrl: 'app/entities/page/page-create.html',
                        controller: 'PageCreateController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            items: null,
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('page');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        // $state.go('templateList', null, { reload: true });
                    }, function() {
                        $state.go('templateList');
                    });
                }]
            })
            .state('template-share', {
                parent: 'templateList',
                url: '/template-share/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                onEnter:['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                    $uibModal.open({
                        templateUrl: 'app/entities/page/template-share.html',
                        controller: 'PageShareController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProductXml',   function (ProductXml) {
                                return ProductXml.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('templateList', null, { reload: true });
                    }, function() {
                        $state.go('templateList');
                    });
                }]
            })

            .state('template-opened-result', {
                parent: 'templateList',
                url: '/template-opened-result/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                onEnter:['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                    $uibModal.open({
                        templateUrl: 'app/entities/page/template-opened-result.html',
                        controller: 'PageShareController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProductXml',   function (ProductXml) {
                                return ProductXml.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('templateList', null, { reload: true });
                    }, function() {
                        $state.go('templateList');
                    });
                }]
            })

            .state('page-edit', {
                parent: 'templateList',
                url: '/page/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                onEnter:['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                    $uibModal.open({
                        templateUrl: 'app/entities/page/page-create.html',
                        controller: 'PageCreateController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            items: null,
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('page');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        // $state.go('templateList', null, { reload: true });
                    }, function() {
                        $state.go('templateList');
                    });
                }]
            })

            .state('page-edit3', {
                parent: 'page',
                url: '/page/{copy}/copy',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                onEnter:['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                    $uibModal.open({
                        templateUrl: 'app/entities/page/page-create.html',
                        controller: 'PageCreateController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            items:null,
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('page');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        // $state.go('page-my-suffix-copy', null, { reload: true });
                    }, function() {
                        $state.go('xml-center');
                    });
                }]
            })


            .state('page-edit2', {
                parent: 'page',
                url: '/page/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                onEnter:['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                    $uibModal.open({
                        templateUrl: 'app/entities/page/page-create.html',
                        controller: 'PageCreateController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            items: null,
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('page');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        // $state.go('templateList', null, { reload: true });
                    }, function() {
                        $state.go('page');
                    });
                }]
            })
        // vm.productXml.modelType+'/'+vm.productXml.modelData+'/'+vm.productXml.modelPath
            .state('page-my-suffix-check', {
                url: '/page-my-suffix/{identifier}/{key}/{xmlId}/{pattern}/{replace}/{pageIndex}/{modelType}/{modelData}?modelPath&xmlName',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/entities/page/pagesmySuffix.html',
                        controller: 'PageMySuffixController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('page-my-suffix-check2', {
                url: '/page-my-suffix/{identifier}/{key}/{xmlId}/{pattern}/{replace}/{pageIndex}?xmlName',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/entities/page/pagesmySuffix.html',
                        controller: 'PageMySuffixController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('page-my-suffix-check3', {
                url: '/page-my-suffix/{identifier}/{key}/{xmlId}/{replace}/{pageIndex}?xmlName',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/entities/page/pagesmySuffix.html',
                        controller: 'PageMySuffixController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('catalog-management', {
                parent: 'app',
                url: '/catalog-management?catalogType',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'platformApp.page.home.title'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/page/catalog-management.html',
                        controller: 'CatalogManagementController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('catalog-management.new', {
                parent: 'catalog-management',
                url: '/new',
                data: {
                    // authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/page/catalog-dialog.html',
                        controller: 'CatalogDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    id: null,
                                    catalogType:$stateParams.catalogType
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('catalog-management', null, { reload: 'catalog-management' });
                    }, function() {
                        $state.go('catalog-management');
                    });
                }]
            })
            .state('catalog-management.edit', {
                parent: 'catalog-management',
                url: '/edit/{id}',
                data: {
                    // authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/page/catalog-dialog.html',
                        controller: 'CatalogDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Catalog', function(Catalog) {
                                return Catalog.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('catalog-management', null, { reload: 'catalog-management' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })

            .state('catalog-management.edit2', {
                parent: 'catalog-management',
                url: '/edit2/{id}',
                data: {
                    // authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/page/catalog-dialog.html',
                        controller: 'CatalogDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ThemeClassify', function(ThemeClassify) {
                                return ThemeClassify.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('catalog-management', null, { reload: 'catalog-management' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('selfTemplateList', {
                parent: 'app',
                url: '/selfTemplateList?selectN&classify&shareStatus&source&product&upgradeStatus',
                data: {
                    authorities: [],
                    pageTitle: 'platformAppTitle.selfTemplateList'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/page/templateList.html',
                        controller: 'TemplateListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fontManagement');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('frontStatus');
                        $translatePartialLoader.addPart('title');
                        return $translate.refresh();
                    }]
                }
            })


            .state('templateList', {
                parent: 'app',
                url: '/templateList',
                data: {
                    authorities: [],
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/page/templateList.html',
                        controller: 'TemplateListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fontManagement');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('frontStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('templateList.new', {
                parent: 'templateList',
                url: '/{id}/new',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/page/template-dialog.html',
                        controller: 'TemplateDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    seq: null,
                                    type: null,
                                    usable: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('templateList', null, { reload: 'templateList' });
                    }, function() {
                        $state.go('templateList');
                    });
                }]
            })
            .state('template', {
                parent: 'app',
                url: '/template/{id}/{self}',
                data: {
                    authorities: [],
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/page/templateList.html',
                        controller: 'TemplateListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fontManagement');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('frontStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('alipayXml', {
                parent: 'app',
                url: '/alipayXml?item',
                data: {
                    authorities: [],
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/entities/page/alipay-xml.html',
                        controller: 'AlipayXMLController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            item:$stateParams.item,
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fontManagement');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('frontStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('recommendTemplate', {
                parent: 'app',
                url: '/recommendTemplate?catalogId',
                data: {
                    authorities: [],
                    pageTitle: 'platformAppTitle.recommendTemplate'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/page/recommendTemplate.html',
                        controller: 'RecommendTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fontManagement');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('frontStatus');
                        $translatePartialLoader.addPart('title');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }

})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TemplateDialogController', TemplateDialogController);

    TemplateDialogController.$inject = ['$timeout', '$q', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Hashtag','ProductXml','MessageService', 'Qinius', 'UidService'];

    function TemplateDialogController ($timeout, $q, $scope, $stateParams, $uibModalInstance, entity, Hashtag,ProductXml,MessageService, Qinius, UidService) {
        var vm = this;

        vm.hashtags = entity;
        vm.clear = clear;
        vm.save = save;
        vm.totalTags=[];
        var total = 3;
        if(JSON.parse(localStorage.getItem('label')) == null || JSON.parse(localStorage.getItem('label')).hashtags == undefined){
            vm.labels=[];
        }else{
            vm.labels = JSON.parse(localStorage.getItem('label')).hashtags.split(',');
        }
        //vm.labels = JSON.parse(localStorage.getItem('label'));
        //console.log(vm.labels.hashtags);
        //console.log(JSON.parse(localStorage.getItem('label')).hashtags);

        Hashtag.byCondition2({
            items:[{key:"usable",value:true, op:"="}]
        }, onSuccess, onError);

        function onSuccess(data) {
            vm.tempHashtags = data;
            angular.forEach(vm.tempHashtags,function(item){
                item.selected = false;
            });
            if(vm.labels == null){
                vm.hashtags =vm.tempHashtags;
            }else{
                repeat(vm.tempHashtags,vm.labels);
            }
        }
        function onError(error) {
            MessageService.error("获取主题标签数据失败");
        }

        //    这里做比较  已有的显示已经启用  未有的未启用
        function repeat(items,list){
            for(var i=0;i<items.length;i++){
                var item=items[i].name;
                for(var j=0;j<list.length;j++){
                    if(item== list[j]){
                        items[i].selected=true;
                    }
                }
            }
            vm.hashtags =vm.tempHashtags;
        }

        $scope.useHashtag = function(item){
            var count= vm.hashtags.filter(function(item){
                return item.selected;
            }).length;
            if(item.selected == false){
                if(count>=total){
                    MessageService.error('最多只能选择3个标签');
                }else{
                    item.selected = !item.selected;
                }
            }else{
                item.selected = !item.selected;
            }

        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            var temp=vm.hashtags.filter(function(item,index){
                return item.selected;
            });
            var hashTags =temp.map(function(item){
                return item.name;
            }).join(',');
            vm.isSaving = true;
            ProductXml.updateHashtags({
                id:entity.templateId,
                hashtags:hashTags
            },onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:bannerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('添加标签成功!')
        }

        function onSaveError () {
            MessageService.error('添加标签失败!');
            vm.isSaving = false;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TemplateModalController', TemplateModalController);

    TemplateModalController.$inject = ['$timeout', '$scope', 'UidService', '$stateParams', '$uibModalInstance', 'entity', '$state', 'Moulds', 'MessageService', 'Qinius', 'ProductTemplate', 'Catalog', '$uibModal','ProductXml'];

    function TemplateModalController ($timeout, $scope, UidService, $stateParams, $uibModalInstance, entity, $state, Moulds, MessageService, Qinius, ProductTemplate, Catalog, $uibModal,ProductXml) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.type = entity.type;
        vm.id = entity.id;
        vm.data = entity.data;
        vm.statusCheck = statusCheck;
        vm.reuseCheck = reuseCheck;
        vm.selectModel = selectModel;
        vm.cutPage = cutPage;
        $scope.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.page = 0;
        vm.pageList = [{index:0, name:'第1页'}];
        vm.units = [
            {name:'px', id:'px'},
            {name:'mm', id:'mm'},
            {name:'英寸', id:'in'},
        ]
        vm.replaces = [
            {id: true, value: '可替换'},
            {id: false, value: '不可替换'}
        ];
        vm.modelTypeList=[
            {name:'2D模型', value:'TWO'},
            {name:'3D模型', value:'THREE'},
        ];
        $scope.template = {
            transparency: 1,
            cover: null,
            scene: null,
            pageI: 0,
            angle: 0,
            backup: false,
            spread: false,
            unreplace: false,
            letterspacing: 0,
            lineheight: 0,
            text: '',
            bgimgs: {bgimg: []},
            bgimgs2: {bgimg: []},
            masks: {mask: []},
            cliparts: {clipart: []},
            frameConfigs: {frameConfig: []},
            documentpics: {documentpic: []},
            pages: {
                page: []
            },
            showPic: false,
            psdFiles: {psdFile: []}
        };
        vm.isEdit = true;
        if(vm.type == 'look'){
            vm.isEdit = false;
        }
        if(vm.type == 'add'){
            vm.data = {
                name: '',
                seq: 1,
                modelPath: '',
                modelType: 'TWO',
                pageSizeH: '',
                pageSizeW: '',
                pageBloodT: '',
                pageBloodB: '',
                pageBloodL: '',
                pageBloodR: '',
                pageEdits:[{
                    pageEditX:'',
                    pageEditY:'',
                    pageEditW:'',
                    pageEditH:'',
                }],
                pageEditUnit: 'mm',
                pageSizeUnit: 'mm',
                pageBloodUnit: 'mm',
                pageEditUnit1: 'mm',
                pageSizeUnit1: 'mm',
                pageBloodUnit1: 'mm',
                modelBlendent:[],
                cover: '',
                status: true,
                pageCount: 1,
                catalog: {
                    name: '',
                    type: '',
                    cancelled: false,
                    usable: true,
                    seq: 1,
                },
                reuse: true,
                hasModel: true,
                pageTemplates: [
                    {
                        id:'',
                        seq:1,
                        added:true,
                        deleted:false,
                        pageSizeH:'',
                        pageSizeW:'',
                        pageBloodT:'',
                        pageBloodB:'',
                        pageBloodL:'',
                        pageBloodR:'',
                        pageEdits:[{
                            pageEditX:'',
                            pageEditY:'',
                            pageEditW:'',
                            pageEditH:'',
                        }],
                        pageEditUnit: 'mm',
                        pageSizeUnit: 'mm',
                        pageBloodUnit: 'mm',
                        pageEditUnit1: 'mm',
                        pageSizeUnit1: 'mm',
                        pageBloodUnit1: 'mm',
                        cancelled:false,
                        productTemplateId:'',
                        mould:null,
                    }
                ],
            };
            vm.pageCount = 1;
        }else {
            vm.abolishData = [];
            vm.pageCount = 0;
            for(var i=(vm.data.pageTemplates.length - 1); i>=0; i--){
                if(vm.data.pageTemplates[i].cancelled){
                    vm.abolishData.push(vm.data.pageTemplates[i]);
                    vm.data.pageTemplates.splice(i, 1)
                }else{
                    vm.pageCount++;
                }
            }
        }
        //模板分类
        Catalog.getAll({items:[{key:"usable", op:"=", value:"true"},{key:"catalogType", op:"=", value:"XML"}]}, function (res) {
            vm.catalogs = res;
            if(vm.type != 'add'){
                for(var i=0; i<res.length; i++){
                    if(res[i].id == vm.data.catalog.id){
                        vm.data.catalog = res[i];
                    }
                }
            }
        });

        //配色管理
        $scope.modelBlendentAdd = function () {
            if(!vm.data.modelBlendent){
                vm.data.modelBlendent = [];
            }
            vm.data.modelBlendent.push({
                name:'',
                modelData:angular.copy(vm.modelData),
                uuid:getUuid(16, 16),
                cancelled: false,
            })
        }
        $scope.modelBlendentSet = function (data) {
            vm.modelData.forEach(function (modelData2){
                data.modelData.forEach(function (modelData1){
                    if(modelData1.materialName == modelData2.materialName){
                        modelData1.page = modelData2.page;
                        if(modelData1.page>0 || modelData1.page === 0){
                            modelData1.color = null;
                        }
                    }
                })
            })
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/blendent-modal.html',
                controller: 'BlendentModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            modelData: data.modelData,
                            modelPath: vm.data.modelPath
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    data.modelData = res;
                }
            });
        }
        $scope.removeModelBlendent = function (index) {
            vm.data.modelBlendent.splice(index, 1)
        }
        //改变页的总数
        $scope.pageCountTab = function(){
            if(vm.data.pageCount == vm.pageCount){
                return;
            }
            if(Number(vm.pageCount) < 1){
                MessageService.error("请输入有效的数值");
                return
            }
            if(Number(vm.pageCount) > Number(vm.data.pageCount)){
                var _pageCount = vm.pageCount - vm.data.pageCount;
                var thisPageData = {
                    id:'',
                    seq:Number(vm.page) + Number(1),
                    added:true,
                    deleted:false,
                    pageSizeH:'',
                    pageSizeW:'',
                    pageBloodT:'',
                    pageBloodB:'',
                    pageBloodL:'',
                    pageBloodR:'',
                    pageEdits:[{
                        pageEditX:'',
                        pageEditY:'',
                        pageEditW:'',
                        pageEditH:'',
                    }],
                    pageEditUnit: 'mm',
                    pageSizeUnit: 'mm',
                    pageBloodUnit: 'mm',
                    pageEditUnit1: 'mm',
                    pageSizeUnit1: 'mm',
                    pageBloodUnit1: 'mm',
                    cancelled:false,
                    productTemplateId:'',
                    mould:null,
                }
                for(var i=0; i<_pageCount; i++){
                    thisPageData.seq = Number(vm.data.pageCount) + Number(i);
                    var _thisPageData = angular.copy(thisPageData)
                    vm.data.pageTemplates.push(_thisPageData);
                }
                vm.data.pageCount = angular.copy(vm.pageCount);
            }
            if(Number(vm.pageCount) < Number(vm.data.pageCount)){
                var _pageCount = Number(vm.pageCount) - Number(1);
                for(var i=(vm.data.pageTemplates.length - 1); i > _pageCount; i--){
                    if(vm.data.pageTemplates[i].added){
                        vm.data.pageTemplates.splice(i,1);
                    }else {
                        vm.data.pageTemplates[i].deleted = true;
                        vm.data.pageTemplates[i].cancelled = true;
                        if(vm.data.pageTemplates[i].pageSizeUnit == 'px'){
                            unit1(vm.data.pageTemplates[i])
                        }
                        vm.abolishData.push(vm.data.pageTemplates[i]);
                        vm.data.pageTemplates.splice(i,1);
                    }
                }
                vm.data.pageCount = angular.copy(vm.pageCount);
            }
            pageListInfo();
        }
        function pageListInfo(){
            vm.pageList = [];
            for(var i=0; i<vm.data.pageCount; i++){
                vm.pageList.push({
                    index:i,
                    name: '第'+(i+1)+'页'
                })
            }
        };

        //选择模型类型
        $scope.modelTypeTab = function () {
            if(vm.data.modelType == 'THREE'){
                vm.data.bindingType = 'None';
                vm.data.pattern = 'OTHER';
                vm.data.replace = false;
            }else {
                vm.data.modelPath = "";
                vm.data.modelData = "";
                vm.modelData = [];
            }
        }

        //选择模型
        vm.modelPathTab = modelPathTab;
        vm.modelPathTabEdit = modelPathTabEdit;
        function modelPathTab() {
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'add',
                            data: vm.data,
                            modelType:'THREE'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    vm.data.modelData= res.modelData;
                    vm.data.modelPath= res.modelPath;
                    vm.data.mould= res;
                    modelDataInfo(vm.data.modelData);
                }
            });
        }
        function modelPathTabEdit() {
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'edit',
                            data: vm.data,
                            modelType:'THREE'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    vm.data.modelData= res.modelData;
                    vm.data.modelPath= res.modelPath;
                    vm.data.mould= res;
                    modelDataInfo(vm.data.modelData);
                }
            });
        }
        function modelDataInfo(item) {
            vm.modelData = JSON.parse(item);
        }
        function selectModel(data){
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'add',
                            data: data,
                            modelType:'TWO'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    res.modelPic = $scope.domains.domain + res.modelPic;
                    data.mould = res;
                }
            });
        }
        //编辑模型
        vm.selectModelEdit = selectModelEdit;
        function selectModelEdit(data){
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'edit',
                            data: data,
                            modelType:'TWO'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    res.modelPic = $scope.domains.domain + res.modelPic;
                    data.mould = res;
                }
            });
        }
        //切换页
        function cutPage(item){
            if(item == 'next'){
                vm.page++;
                if(vm.data.reuse && vm.isEdit){
                    vm.data.pageTemplates[vm.page].pageSizeH = angular.copy(vm.data.pageTemplates[vm.page-1].pageSizeH);
                    vm.data.pageTemplates[vm.page].pageSizeW = angular.copy(vm.data.pageTemplates[vm.page-1].pageSizeW);
                    vm.data.pageTemplates[vm.page].pageBloodT = angular.copy(vm.data.pageTemplates[vm.page-1].pageBloodT);
                    vm.data.pageTemplates[vm.page].pageBloodB = angular.copy(vm.data.pageTemplates[vm.page-1].pageBloodB);
                    vm.data.pageTemplates[vm.page].pageBloodL = angular.copy(vm.data.pageTemplates[vm.page-1].pageBloodL);
                    vm.data.pageTemplates[vm.page].pageBloodR = angular.copy(vm.data.pageTemplates[vm.page-1].pageBloodR);
                    vm.data.pageTemplates[vm.page].pageEdits = angular.copy(vm.data.pageTemplates[vm.page-1].pageEdits);
                    vm.data.pageTemplates[vm.page].pageEditUnit = angular.copy(vm.data.pageTemplates[vm.page-1].pageEditUnit);
                    vm.data.pageTemplates[vm.page].pageSizeUnit = angular.copy(vm.data.pageTemplates[vm.page-1].pageSizeUnit);
                    vm.data.pageTemplates[vm.page].pageBloodUnit = angular.copy(vm.data.pageTemplates[vm.page-1].pageBloodUnit);
                    vm.data.pageTemplates[vm.page].pageEditUnit1 = angular.copy(vm.data.pageTemplates[vm.page-1].pageEditUnit1);
                    vm.data.pageTemplates[vm.page].pageSizeUnit1 = angular.copy(vm.data.pageTemplates[vm.page-1].pageSizeUnit1);
                    vm.data.pageTemplates[vm.page].pageBloodUnit1 = angular.copy(vm.data.pageTemplates[vm.page-1].pageBloodUnit1);
                    vm.data.pageTemplates[vm.page].mould = angular.copy(vm.data.pageTemplates[vm.page-1].mould);
                }
            }
            if(item == 'up'){
                vm.page--;
            }
        }
        function homePageData(){
            vm.data.pageSizeH = vm.data.pageTemplates[0].pageSizeH;
            vm.data.pageSizeW = vm.data.pageTemplates[0].pageSizeW;
            vm.data.pageBloodT = vm.data.pageTemplates[0].pageBloodT;
            vm.data.pageBloodB = vm.data.pageTemplates[0].pageBloodB;
            vm.data.pageBloodL = vm.data.pageTemplates[0].pageBloodL;
            vm.data.pageBloodR = vm.data.pageTemplates[0].pageBloodR;
            vm.data.pageEdits = vm.data.pageTemplates[0].pageEdits;
            vm.data.pageEditUnit = vm.data.pageTemplates[0].pageEditUnit;
            vm.data.pageSizeUnit = vm.data.pageTemplates[0].pageSizeUnit;
            vm.data.pageBloodUnit = vm.data.pageTemplates[0].pageBloodUnit;
            vm.data.pageEditUnit1 = vm.data.pageTemplates[0].pageEditUnit1;
            vm.data.pageSizeUnit1 = vm.data.pageTemplates[0].pageSizeUnit1;
            vm.data.pageBloodUnit1 = vm.data.pageTemplates[0].pageBloodUnit1;
        }

        //预览图
        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }
        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'template-' + UidService.get());
            formData.append('token', vm.tokens.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.data.cover = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        $scope.removeFile = function () {
            vm.data.cover = "";
        }

        //是否启用
        function statusCheck(item) {
            vm.data.status = item;
        }
        //页面复用
        function reuseCheck(item) {
            vm.data.reuse = item;
        }

        function checkFloatNum(str){
            var reg_zs = /^[0-9]\d*(\.\d+)?$/i;
            if (!reg_zs.test(str)) {
                return false;
            }
            return true;
        }
        function checkFloatNum1(str){
            var reg_zs = /^[1-9]\d*(\.\d+)?$/i;
            if (!reg_zs.test(str)) {
                return false;
            }
            return true;
        }
        //保存
        function save() {
            if(!vm.data.name){
                MessageService.error("请输入模板名称！");
                return
            }
            if(!vm.data.cover){
                MessageService.error("请上传预览图");
                return
            }
            if(!vm.data.bindingType){
                MessageService.error("请选择预览翻页方式！");
                return
            }
            if(!vm.data.pattern){
                MessageService.error("请选择编辑模式！");
                return
            }
            if(vm.data.modelType == 'THREE' && !vm.data.modelPath){
                MessageService.error("请选择第" + _pageIndex + "页的模型");
                return false
            }
            var _pageIndex = 1;
            for(var i=0; i<vm.data.pageTemplates.length; i++){
                var _pageTemplates = vm.data.pageTemplates[i];
                if(!_pageTemplates.cancelled){
                    if(vm.data.modelType == 'TWO' && !_pageTemplates.mould){
                        MessageService.error("请选择第" + _pageIndex + "页的模型");
                        return false
                    }
                    if(!checkFloatNum(_pageTemplates.pageSizeW) || !checkFloatNum(_pageTemplates.pageSizeH)){
                        MessageService.error("第" + _pageIndex + "页的尺寸必须大于等于0");
                        return false
                    }
                    if(!checkFloatNum(_pageTemplates.pageBloodT) || !checkFloatNum(_pageTemplates.pageBloodB) || !checkFloatNum(_pageTemplates.pageBloodL) || !checkFloatNum(_pageTemplates.pageBloodR)){
                        MessageService.error("第" + _pageIndex + "页的出血尺寸必须大于等于0");
                        return false
                    }
                    if(!_pageTemplates.pageEdits || _pageTemplates.pageEdits.length == 0){
                        MessageService.error("第" + _pageIndex + "页的编辑区域不能为空");
                        return false
                    }
                    for(var k=0; k<_pageTemplates.pageEdits.length; k++){
                        var _pageEdit = _pageTemplates.pageEdits[k];
                        if(!checkFloatNum(_pageEdit.pageEditX) || !checkFloatNum(_pageEdit.pageEditY) || !checkFloatNum(_pageEdit.pageEditW) || !checkFloatNum(_pageEdit.pageEditH)){
                            MessageService.error("第" + _pageIndex + "页的编辑区域必须大于等于0");
                            return false
                        }
                    }
                    _pageIndex++
                }
            }
            if(vm.data.pageTemplates[0].pageSizeUnit == 'px'){
                unit('mm');
            }
            homePageData();
            vm.data.modelData = JSON.stringify(vm.modelData);
            vm.data.modelBlendent = JSON.stringify(vm.data.modelBlendent);
            var _item = vm.data;
            for(var j=_item.pageTemplates.length-1; j>=0; j--){
                if(_item.pageTemplates[j].cancelled){
                    _item.pageTemplates.splice(j, 1)
                }
            }
            _item.pageSizeH=parseInt(_item.pageSizeH)-0;
            _item.pageSizeW=parseInt(_item.pageSizeW)-0;
            _item.pageBloodT=parseInt(_item.pageBloodT)-0;
            _item.pageBloodB=parseInt(_item.pageBloodB)-0;
            _item.pageBloodL=parseInt(_item.pageBloodL)-0;
            _item.pageBloodR=parseInt(_item.pageBloodR)-0;
            _item.pageEdits.forEach(function (pageEdit) {
                pageEdit.pageEditX = parseInt(pageEdit.pageEditX)-0;
                pageEdit.pageEditY = parseInt(pageEdit.pageEditY)-0;
                pageEdit.pageEditW = parseInt(pageEdit.pageEditW)-0;
                pageEdit.pageEditH = parseInt(pageEdit.pageEditH)-0;
            })
            _item.custom = vm.type;
            addSave(_item)
            return
            var pageStorageName = "page-new";
            if (vm.type == 'edit') {
                pageStorageName = "page-" + vm.id;
                localStorage.setItem(pageStorageName, JSON.stringify(_item));
                $state.go('page-my-suffix-edit', {id: vm.id}, {reload: true})
            } else if (vm.type == 'copy') {
                pageStorageName = "page-" + vm.id + "-copy";
                localStorage.setItem(pageStorageName, JSON.stringify(_item));
                $state.go('page-my-suffix-copy', {copy: vm.id}, {reload: true})
                // _item.id = null;
                // localStorage.setItem('page', JSON.stringify(_item));
                // $state.go('page-my-suffix', {reload: true})
            } else {
                localStorage.setItem(pageStorageName, JSON.stringify(_item));
                $state.go('page-my-suffix', {reload: true});
            }
            cancel()
        }
        function addSave(item) {
            if(vm.isSaving){
                return
            }
            vm.isSaving = true;
            vm.hashtags = '';
            if(item.catalog.hashtags){
                for (var i = 0; i < item.catalog.hashtags.length; i++) {
                    vm.hashtags += item.catalog.hashtags[i].name + ','
                }
            }
            $scope.template.pages.page=[];
            for (var i = 0; i < item.pageTemplates.length; i++) {
                var _page = item.pageTemplates[i];
                var editSize = [];
                _page.pageEdits.forEach(function (editbox) {
                    editSize.push({
                        height: editbox.pageEditH,
                        width: editbox.pageEditW,
                        x: editbox.pageEditX,
                        y: editbox.pageEditY
                    })
                });
                var _page1 = createPage(_page, editSize, i);
                if (_page.mould) {
                    _page1.scene = {
                        background: {
                            resource: {
                                identifier: _page.mould.modelPic.indexOf('http') > -1 ? _page.mould.modelPic : $scope.domains.domain + _page.mould.modelPic,
                                provider: "qiniu"
                            }
                        },
                        geometry: {
                            width: _page.mould.gWidth,
                            height: _page.mould.gHeight,
                            x: 0,
                            y: 0
                        },
                        imagebox: {
                            geometry: {
                                width: _page.mould.width,
                                height: _page.mould.height,
                                x: _page.mould.x,
                                y: _page.mould.y
                            },
                            rotation: {angle: 0},
                            transparency: 1,
                        },
                        hierarchy: _page.mould.hierarchy,
                        isScene: true,
                        scale: 1,
                        unit: "mm",
                        resource:{
                            identifier: _page.mould.modelPic.indexOf('http') > -1 ? _page.mould.modelPic : $scope.domains.domain + _page.mould.modelPic,
                            provider: "qiniu"
                        }
                    }
                }
                $scope.template.pages.page.push(_page1);
            }
            $scope.template.bindingtype = item.bindingType;
            $scope.template.pattern = item.pattern;
            $scope.template.cover = angular.copy(item.cover);
            $scope.template.replace = item.replace;
            $scope.template.version = "2.0";
            $scope.template.addpage = item.addpage;
            $scope.template.minpage = item.minpage;
            $scope.template.maxpage = item.maxpage;
            $scope.template.addunit = item.addunit;
            ProductXml.save({
                document: $scope.template,
                bindingType: item.bindingType,
                pattern: item.pattern,
                replace: item.replace,
                name: item.name,
                catalogId: item.catalog.id,
                catalogName: item.catalog.name,
                version: '2.0',
                type: 'XML',
                productTemplateId: '',
                hashtags: vm.hashtags,
                modelBlendent: item.modelBlendent,
                modelData: item.modelData,
                modelPath: item.modelPath,
                modelType: item.modelType
            }, function (res) {
                vm.isSaving = false;
                $uibModalInstance.close();
                $state.go('page-my-suffix-edit', {id: res.id}, {reload: true})
            }, function () {
                vm.isSaving = false;
            });
        }
        function createPage(_page, editSize, i) {
            var _page1 = {
                uuid: UidService.get(),
                isScene: false,
                bgImg: '',
                background: {resource: {},color:'#ffffff', type: "Color"},
                pagecover: {},
                levels: {},
                barcode: {},
                qrcode: {},
                editbox: editSize[0],
                editboxes: {
                    editbox: editSize
                },
                mediabox: {
                    height: _page.pageSizeH,
                    width: _page.pageSizeW,
                },
                trimbox: {
                    height: _page.pageSizeH - Number(_page.pageBloodB) - Number(_page.pageBloodT),
                    width: _page.pageSizeW - Number(_page.pageBloodL) - Number(_page.pageBloodR),
                    x: _page.pageBloodL,
                    y: _page.pageBloodT
                },
                scale: 1,
                proportion: 1,
                seq: i + 1,
                spread: false,
                backup: false,
                unreplace: !vm.data.replace
            }
            return _page1;
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
        //dpi
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[0];
            return arrDPI;
        }

        $scope.addPageEdit = function () {
            vm.data.pageTemplates[vm.page].pageEdits.push({
                pageEditX:'',
                pageEditY:'',
                pageEditW:'',
                pageEditH:''
            })
        };
        $scope.removePageEdit = function(index){
            vm.data.pageTemplates[vm.page].pageEdits.splice(index, 1);
        };
        //单位换算
        $scope.unitConversion = function (item) {
            console.log(vm.data)
            unit(item)
        }
        function unit(item){
            for(var i=0; i<vm.data.pageTemplates.length; i++){
                var _data = vm.data.pageTemplates[i];
                if(_data.pageSizeUnit == 'mm'){
                    _data.pageSizeW = MmTurnPx(_data.pageSizeW).toFixed(2) - 0;
                    _data.pageSizeH = MmTurnPx(_data.pageSizeH).toFixed(2) - 0;
                    _data.pageBloodT = MmTurnPx(_data.pageBloodT).toFixed(2) - 0;
                    _data.pageBloodB = MmTurnPx(_data.pageBloodB).toFixed(2) - 0;
                    _data.pageBloodL = MmTurnPx(_data.pageBloodL).toFixed(2) - 0;
                    _data.pageBloodR = MmTurnPx(_data.pageBloodR).toFixed(2) - 0;
                    _data.pageEdits.forEach(function (pageEdit) {
                        pageEdit.pageEditX = MmTurnPx(pageEdit.pageEditX).toFixed(2) - 0;
                        pageEdit.pageEditY = MmTurnPx(pageEdit.pageEditY).toFixed(2) - 0;
                        pageEdit.pageEditW = MmTurnPx(pageEdit.pageEditW).toFixed(2) - 0;
                        pageEdit.pageEditH = MmTurnPx(pageEdit.pageEditH).toFixed(2) - 0;
                    })
                }
                if (_data.pageSizeUnit == 'in'){
                    _data.pageSizeW = InTurnPx(_data.pageSizeW).toFixed(2) - 0;
                    _data.pageSizeH = InTurnPx(_data.pageSizeH).toFixed(2) - 0;
                    _data.pageBloodT = InTurnPx(_data.pageBloodT).toFixed(2) - 0;
                    _data.pageBloodB = InTurnPx(_data.pageBloodB).toFixed(2) - 0;
                    _data.pageBloodL = InTurnPx(_data.pageBloodL).toFixed(2) - 0;
                    _data.pageBloodR = InTurnPx(_data.pageBloodR).toFixed(2) - 0;
                    _data.pageEdits.forEach(function (pageEdit) {
                        pageEdit.pageEditX = InTurnPx(pageEdit.pageEditX).toFixed(2) - 0;
                        pageEdit.pageEditY = InTurnPx(pageEdit.pageEditY).toFixed(2) - 0;
                        pageEdit.pageEditW = InTurnPx(pageEdit.pageEditW).toFixed(2) - 0;
                        pageEdit.pageEditH = InTurnPx(pageEdit.pageEditH).toFixed(2) - 0;
                    })
                }
                if(item == 'mm'){
                    _data.pageSizeW = PxTurnMm(_data.pageSizeW).toFixed(2) - 0;
                    _data.pageSizeH = PxTurnMm(_data.pageSizeH).toFixed(2) - 0;
                    _data.pageBloodT = PxTurnMm(_data.pageBloodT).toFixed(2) - 0;
                    _data.pageBloodB = PxTurnMm(_data.pageBloodB).toFixed(2) - 0;
                    _data.pageBloodL = PxTurnMm(_data.pageBloodL).toFixed(2) - 0;
                    _data.pageBloodR = PxTurnMm(_data.pageBloodR).toFixed(2) - 0;
                    _data.pageEdits.forEach(function (pageEdit) {
                        pageEdit.pageEditX = PxTurnMm(pageEdit.pageEditX).toFixed(2) - 0;
                        pageEdit.pageEditY = PxTurnMm(pageEdit.pageEditY).toFixed(2) - 0;
                        pageEdit.pageEditW = PxTurnMm(pageEdit.pageEditW).toFixed(2) - 0;
                        pageEdit.pageEditH = PxTurnMm(pageEdit.pageEditH).toFixed(2) - 0;
                    })
                }
                if(item == 'in'){
                    _data.pageSizeW = PxTurnIn(_data.pageSizeW).toFixed(2) - 0;
                    _data.pageSizeH = PxTurnIn(_data.pageSizeH).toFixed(2) - 0;
                    _data.pageBloodT = PxTurnIn(_data.pageBloodT).toFixed(2) - 0;
                    _data.pageBloodB = PxTurnIn(_data.pageBloodB).toFixed(2) - 0;
                    _data.pageBloodL = PxTurnIn(_data.pageBloodL).toFixed(2) - 0;
                    _data.pageBloodR = PxTurnIn(_data.pageBloodR).toFixed(2) - 0;
                    _data.pageEdits.forEach(function (pageEdit) {
                        pageEdit.pageEditX = PxTurnIn(pageEdit.pageEditX).toFixed(2) - 0;
                        pageEdit.pageEditY = PxTurnIn(pageEdit.pageEditY).toFixed(2) - 0;
                        pageEdit.pageEditW = PxTurnIn(pageEdit.pageEditW).toFixed(2) - 0;
                        pageEdit.pageEditH = PxTurnIn(pageEdit.pageEditH).toFixed(2) - 0;
                    })
                }
                _data.pageSizeUnit = item;
                _data.pageBloodUnit = item;
                _data.pageEditUnit = item;
                _data.pageSizeUnit1 = item;
                _data.pageBloodUnit1 = item;
                _data.pageEditUnit1 = item;
            }
        }
        function unit1(item){
            item.pageSizeUnit = 'mm';
            item.pageBloodUnit = 'mm';
            item.pageEditUnit = 'mm';
            item.pageSizeUnit1 = 'mm';
            item.pageBloodUnit1 = 'mm';
            item.pageEditUnit1 = 'mm';
            item.pageSizeW = (item.pageSizeW/vm.windowDpi*25.4).toFixed(2);
            item.pageSizeH = (item.pageSizeH/vm.windowDpi*25.4).toFixed(2);
            item.pageBloodT = (item.pageBloodT/vm.windowDpi*25.4).toFixed(2);
            item.pageBloodB = (item.pageBloodB/vm.windowDpi*25.4).toFixed(2);
            item.pageBloodL = (item.pageBloodL/vm.windowDpi*25.4).toFixed(2);
            item.pageBloodR = (item.pageBloodR/vm.windowDpi*25.4).toFixed(2);
            item.pageEdits.forEach(function (pageEdit) {
                pageEdit.pageEditX = (pageEdit.pageEditX/vm.windowDpi*25.4).toFixed(2);
                pageEdit.pageEditY = (pageEdit.pageEditY/vm.windowDpi*25.4).toFixed(2);
                pageEdit.pageEditW = (pageEdit.pageEditW/vm.windowDpi*25.4).toFixed(2);
                pageEdit.pageEditH = (pageEdit.pageEditH/vm.windowDpi*25.4).toFixed(2);
            })
        };

        $scope.bindingTypeInfo = function (item) {
            switch (item) {
                case 'Left':
                    return '左右滑动（含封面）';
                case 'Top':
                    return '上下滑动';
                case 'None':
                    return '单页展示';
                case 'Left2':
                    return '左右滑动（不含封面）';
                default:
                    return '';
            }
        }
        $scope.patternInfo = function (item) {
            switch (item) {
                case 'BOOK':
                    return '书刊模式';
                case 'OTHER':
                    return '普通模式';
                case 'BOOK2':
                    return '书刊（不含封面）';
                default:
                    return '';
            }
        }
    }
})();

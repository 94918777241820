(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FontManagementDetailController', FontManagementDetailController);

    FontManagementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'FontManagement'];

    function FontManagementDetailController($scope, $rootScope, $stateParams, $uibModalInstance, entity, FontManagement) {
        var vm = this;

        vm.fontManagement = entity;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('platformApp:fontManagementUpdate', function(event, result) {
            vm.fontManagement = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function () {
    "use strict";

    var ModeleColorPicker = {
        restrict: "EAC",
        replace: true,
        transclude: true,
        templateUrl: "app/entities/components/modele-color-picker.html",
        controllerAs: "vm",
        scope: {},
        bindings: {
            color: '=',
            onChange:'&',
            themeColors:'<',
            transparent:'<',//是否可以设置透明色，默认true

        },
        controller: [
            "$scope",
            "$timeout",
            "$element",
            function ($scope, $timeout, $element) {
                var vm = this;
                vm.$onInit = function () {
                    if (!$element.data('$isolateScope')) {
                        $element.data('$isolateScope', $scope);
                    }
                }
                //如果没设置transparent 属性，默认为true
                if(vm.transparent === undefined){
                    vm.transparent = true;
                }
                var changeTimer = null;
                var defaultColorList = ['#000000','#666666','#A8A8A8','#D9D9D9','#FFFFFF',''];
                if(!vm.transparent){//不支持透明色，删掉最后一个
                    defaultColorList.splice(defaultColorList.length-1,1);
                }
                var colorpicker =  Colorpicker.create({
                    bindElem:$element.find(".color-box")[0],
                    initColor:vm.color ? vm.color : 'rgb(255,255,255)',
                    themeColorList:vm.themeColors || [],
                    defaultColorList:defaultColorList,
                    change: function (elem,hex) {
                        if(changeTimer !== null){
                            clearTimeout(changeTimer);
                        }
                        changeTimer = setTimeout(function () {
                            $scope.onChange(angular.element(elem).scope(),hex);
                        },150)

                    },
                    show: function (_this){
                        _this.changeThemeView(vm.themeColors || []);
                    }
                })
                $scope.onChange = function (e,hex) {
                    e.vm.color = hex;
                    $timeout(function () {
                        if(e.vm.onChange){
                            e.vm.onChange();
                        }
                    },100)
                }

            }
        ]
    };
    angular.module("platformApp").component("modeleColorPicker", ModeleColorPicker);
})();

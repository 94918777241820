(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TextListController', TextListController);

    TextListController.$inject = ['$state', '$scope', 'ProductXml', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', 'FontXmls', '$localStorage', 'Qinius', '$uibModal'];

    function TextListController($state, $scope, ProductXml, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, FontXmls, $localStorage, Qinius, $uibModal) {
        var vm = this;
        //新增
        $scope.add = function () {
            $uibModal.open({
                templateUrl: 'app/entities/text/modal/text-add.html',
                controller: 'TextAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                        };
                    }]
                }
            });
        }
    }
})();

(function () {
    "use strict";

    angular.module("platformApp").controller("UpgradePromptController", UpgradePromptController);

    UpgradePromptController.$inject = ["$scope", "$timeout", "$uibModalInstance","$localStorage","FrontUser","MessageService"];

    function UpgradePromptController($scope, $timeout, $uibModalInstance,$localStorage,FrontUser,MessageService) {
        var vm = this;
        vm.loading = false;
        vm.swiperList = [
            { src: "content/images/upgrade-swiper1.png" },
            { src: "content/images/upgrade-swiper2.png" },
            { src: "content/images/upgrade-swiper3.png" }
        ];
        init();
        function init() {
            $timeout(function () {
                new Swiper(".upgrade-swiper", {
                    autoplay: true,
                    loop: true
                });
            });
        }
        vm.close = function () {
            $uibModalInstance.close();
        };
        //点击开启按钮
        vm.submitOpen = function(){
            if(vm.loading) return;
            vm.loading = true;
            FrontUser.upgrade({},function(res){
                vm.loading = false;
                $localStorage.user = res;
                vm.close();
            },function(){
                vm.loading = false;
                MessageService.error("网络错误，请重试");
            })

        }
    }
})();

(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PageEditController', PageEditController);

    PageEditController.$inject = ['$scope', '$uibModalInstance', '$state', '$stateParams','pages', 'MessageService'];

    function PageEditController($scope, $uibModalInstance, $state, $stateParams, pages, MessageService) {

        var vm = this;

        vm.clear = clear;
        vm.save = save;
        $scope.isPageSize = 1;
        var originPage = angular.copy(pages.page);
        vm.page = pages.page;
        console.log(vm.page)
        var dpId = 0;
        if(pages.sizeSet=='bloodSize'){
            $scope.isPageSize = 2;
        }else if(pages.sizeSet=='editbox'){
            $scope.isPageSize = 0;
        }

        vm.dpiChoose = [{data: 'mm', id: 0}, {data: 'px', id: 1}, {data: '英寸', id: 2}];
        if(vm.page.pageSizeUnit == 'px'){
            dpId = 1;
        }
        vm.page.dpi = vm.dpiChoose[dpId];
        vm.page.dpi1 = vm.dpiChoose[dpId];
        console.log(vm.page)

        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpiW = arrDPI[0];
            vm.windowDpiH = arrDPI[1];
            // vm.windowDpiW = 300;
            // vm.windowDpiH = 300;
            return arrDPI;
        }
        $scope.addPageEdit = function () {
            vm.page.editboxs.unshift({
                x:'',
                y:'',
                width:'',
                height:''
            })
        }
        $scope.removePageEdit = function(index){
            vm.page.editboxs.splice(index, 1);
        };
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.customStoreChange = function () {
            if (vm.customStore == null) {
                vm.page.customStore = null;
            }
            for (var i = vm.stores.length - 1; i >= 0; i--) {
                var s = vm.stores[i];
                if (s.id == vm.customStore) {
                    vm.page.customStore = s;
                }
            }
        }

        $scope.updateDpi = function (data) {
            console.log(data)
            if(vm.page.dpi.id == 1){
                vm.page.pageSize = {
                    width: PxTurnMm(vm.page.pageSize.width),
                    height: PxTurnMm(vm.page.pageSize.height),
                }
                vm.page.pageBloodT = PxTurnMm(vm.page.pageBloodT);
                vm.page.pageBloodB = PxTurnMm(vm.page.pageBloodB);
                vm.page.pageBloodL = PxTurnMm(vm.page.pageBloodL);
                vm.page.pageBloodR = PxTurnMm(vm.page.pageBloodR);

                vm.page.editboxs.forEach(function (editbox, editboxIndex) {
                    vm.page.editboxs[editboxIndex] = {
                        width: PxTurnMm(editbox.width),
                        height: PxTurnMm(editbox.height),
                        x: PxTurnMm(editbox.x),
                        y: PxTurnMm(editbox.y),
                    }
                })
            }else if(vm.page.dpi.id == 2){
                vm.page.pageSize = {
                    width: InTurnMm(vm.page.pageSize.width),
                    height: InTurnMm(vm.page.pageSize.height),
                }
                vm.page.pageBloodT = InTurnMm(vm.page.pageBloodT);
                vm.page.pageBloodB = InTurnMm(vm.page.pageBloodB);
                vm.page.pageBloodL = InTurnMm(vm.page.pageBloodL);
                vm.page.pageBloodR = InTurnMm(vm.page.pageBloodR);

                vm.page.editboxs.forEach(function (editbox, editboxIndex) {
                    vm.page.editboxs[editboxIndex] = {
                        width: InTurnMm(editbox.width),
                        height: InTurnMm(editbox.height),
                        x: InTurnMm(editbox.x),
                        y: InTurnMm(editbox.y),
                    }
                })
            }
            if (data.id == 1) {
                vm.page.pageSize = {
                    width: MmTurnPx(vm.page.pageSize.width),
                    height: MmTurnPx(vm.page.pageSize.height),
                }
                vm.page.pageBloodT = MmTurnPx(vm.page.pageBloodT);
                vm.page.pageBloodB = MmTurnPx(vm.page.pageBloodB);
                vm.page.pageBloodL = MmTurnPx(vm.page.pageBloodL);
                vm.page.pageBloodR = MmTurnPx(vm.page.pageBloodR);

                vm.page.editboxs.forEach(function (editbox, editboxIndex) {
                    vm.page.editboxs[editboxIndex] = {
                        width: MmTurnPx(editbox.width),
                        height: MmTurnPx(editbox.height),
                        x: MmTurnPx(editbox.x),
                        y: MmTurnPx(editbox.y),
                    }
                })
            } else if(data.id == 2) {
                vm.page.pageSize = {
                    width: MmTurnIn(vm.page.pageSize.width),
                    height: MmTurnIn(vm.page.pageSize.height),
                }
                vm.page.pageBloodT = MmTurnIn(vm.page.pageBloodT);
                vm.page.pageBloodB = MmTurnIn(vm.page.pageBloodB);
                vm.page.pageBloodL = MmTurnIn(vm.page.pageBloodL);
                vm.page.pageBloodR = MmTurnIn(vm.page.pageBloodR);

                vm.page.editboxs.forEach(function (editbox, editboxIndex) {
                    vm.page.editboxs[editboxIndex] = {
                        width: MmTurnIn(editbox.width),
                        height: MmTurnIn(editbox.height),
                        x: MmTurnIn(editbox.x),
                        y: MmTurnIn(editbox.y),
                    }
                })
            }
            vm.page.dpi = data;

        }

        $scope.validate = function () {
            if($scope.isPageSize==0){
                for(var i=0; i<vm.page.editboxs.length; i++){
                    var _editbox = vm.page.editboxs[i];
                    if(_editbox.width === ""){
                        MessageService.error("请输入宽度");
                        return false
                    }
                    if(_editbox.height === ""){
                        MessageService.error("请输入高度");
                        return false
                    }
                    if(_editbox.x === ""){
                        MessageService.error("请输入X");
                        return false
                    }
                    if(_editbox.y === ""){
                        MessageService.error("请输入Y");
                        return false
                    }
                }
            }

            // if (vm.page.pageSize == null || vm.page.pageSize == "") {
            //     MessageService.error("请设置页面尺寸");
            //     return false;
            // }
            // if (vm.page.bloodSize == null || vm.page.bloodSize == "") {
            //     MessageService.error("请设置出血尺寸");
            //     return false;
            // }
            // if (vm.page.name == null || vm.page.name == "") {
            //     MessageService.error("请输入作品名称");
            //     return false;
            // }
            // if (vm.page.pageNum == null || vm.page.pageNum == "") {
            //     MessageService.error("请输入页数");
            //     return false;
            // }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            onSaveSuccess(vm.page);
        }


        function onSaveSuccess(result) {
            console.log(result)
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'修改尺寸将会将会影响当前编辑内容，超出原画布尺寸的元素将会丢失，是否继续调整？'
            },function(){
                vm.isSaving = false;
                $scope.updateDpi(vm.dpiChoose[0]);
                $uibModalInstance.close(result);
            }, function () {
                vm.isSaving = false;
                $uibModalInstance.dismiss();
            });

        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();

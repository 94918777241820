(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FrameControlController', FrameControlController);

    FrameControlController.$inject = ['$scope', '$q', '$state', 'ParseLinks', 'paginationConstants', 'pagingParams', 'Qinius',
    'MessageService','FrameConfig','ThemeClassify', '$uibModal', '$timeout'];

    function FrameControlController ($scope, $q, $state, ParseLinks,  paginationConstants, pagingParams, Qinius,
     MessageService , FrameConfig, ThemeClassify, $uibModal, $timeout) {
        var vm = this;

        vm.tabs = [{key:"background", value:"背景", url:'background'},
        {key:"imageBox", value:"相框", url:'mask'},
        {key:"borderBox", value:"边框", url:'frame-control'},
        {key:"font", value:"字体", url:'font-management'},
        {key:"clipArt", value:"素材", url:'clip-art'},
        {key:"image", value:"图片", url:'pic'}];
        vm.currentName = "borderBox";

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 15;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.page = pagingParams.page;
        //标签筛选项是否展开
        vm.tagScreenIsOpen = false;

        vm.themeClassifies = ThemeClassify.getAllByCondition1({items:[
            {key:"type",value:"BORDER", op:"="},
            {key:"used",value:"true", op:"="}]});
        vm.domain = Qinius.getDomain();
        vm.themeClassifyId = pagingParams.c - 0;
        $q.all([ vm.domain.$promise, vm.themeClassifies.$promise]).then(function(){
            vm.domainQ =   vm.domain.domain;
            vm.themeClassifies.unshift({name:"全部", id:"全部", seq:-1});
            $scope.selectTag(vm.themeClassifies[0]);
        })

        $scope.delete = function(id){
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除该边框？'
            },function(){
                FrameConfig.delete({id: id},function () {
                    loadAll();
                });
            },function(){});
        };


        $scope.editTag = function(){
            $uibModal.open({
                templateUrl: 'app/entities/page/catalog-management.html',
                controller: 'CatalogManagementController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: function () {
                        return {catalogType: "BORDER"}
                    }
                }
            }).result.then(function(res) {
                ThemeClassify.getAllByCondition1({items:[
                    {key:"type",value:"BORDER", op:"="},
                    {key:"used",value:"true", op:"="}]}, function(data){
                        vm.themeClassifies = data;
                        vm.themeClassifies.unshift({name:"全部", id:"全部", seq:-1});
                        $scope.selectTag(vm.themeClassifies[0]);
                    });
            });
        }

        $scope.batchDelet = function(){
            var ids = [];
            vm.sourceMaterials.forEach(function(it){
                if (it.selected) {
                    ids.push(it.id);
                }
            });
            if (ids.length>0) {
                MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除所选边框？'
            }, function(){
                    FrameConfig.batchDelete({ids_:ids}, function(res){
                        MessageService.success("删除成功");
                        vm.batch = false;
                        vm.count = 0;
                        loadAll();
                    }, function(va){
                        MessageService.error("删除失败，请稍后再试");
                    })
                })
            }else{
                MessageService.error("未勾选任何有效的背景，请确认后再操作");
            }
        }

        $scope.itemSelect = function(item){
            if (!vm.batch) {
                return
            }
            item.selected = !item.selected;
            vm.count = 0;
            vm.sourceMaterials.forEach(function(it){
                if (it.selected) {
                    vm.count ++;
                }
            })
        }

        $scope.exitBatch = function(){
            vm.sourceMaterials.forEach(function(it){
                it.selected = false;
            })
            vm.batch = false;
            vm.count = 0;
        }

         //更改展开收起状态
        $scope.changeMoreState = function(key){
            vm[key] = !vm[key]
        }

        // 当前页变更
        vm.currentChange = function(){
            $timeout(function(){
                loadAll();
            })
        }

        $scope.selectTag = function(item){
            vm.themeClassifies.forEach(function(it){
                it.active = false;
            })
            item.active = true;
            if (item.id == '全部') {
                vm.themeClassifyId = null;
            }else{
                vm.themeClassifyId = item.id;
            }
            loadAll();
        }

        $scope.more = function(item){
            vm.sourceMaterials.forEach(function(it){
                it.more = false;
            });
            item.more = true;
        }

        function loadAll () {
            var items = []
            if (vm.themeClassifyId) {
                items.push({key:"themeClassify.id",value:vm.themeClassifyId, op:"="})
            }
            if (pagingParams.search) {
                items.push({key:"name",value:pagingParams.search, op:"like"})

            }
            FrameConfig.byCondition1({
                items:items,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["lastModifiedDate,desc"]
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.sourceMaterials = data.message;
                vm.count = 0;
            }
            function onError(error) {
                MessageService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                c: vm.themeClassifyId
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.themeClassifyId = null;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        $scope.searchClass = function(){
            vm.transition()
        }

        $scope.issue = function(item){
            $uibModal.open({
                templateUrl: 'app/entities/frame-control/frame-control-issue-dialog.html',
                controller: 'FrameControlIssueDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return JSON.parse(localStorage.getItem('platform2-user'))
                    },
                    borderId: function () {
                        return item.id
                    }
                }
            }).result.then(function() {

            }, function() {

            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PictureShareModal1Controller', PictureShareModal1Controller);

    PictureShareModal1Controller.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService','Catalog','PicLayoutXmls'];

    function PictureShareModal1Controller ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService,Catalog,PicLayoutXmls) {
        var vm = this;
        vm.save = save;
        vm.cancel = cancel;
        vm.data = entity.pictureArr;
        console.log(entity)
        vm.catalogId = "";
        Catalog.getAll({items:[{key:"usable",op:"=",value:"true"},{key:"catalogType",op:"=",value:"LAYOUT"}]}, function (data) {
            console.log(data,"data");
            vm.catalogs = data;
        })
        //返回
        function cancel() {
            $uibModalInstance.close();
        }

        function save() {
            if(entity.selectedAll){
                PicLayoutXmls.useAll({
                    backCatalogId: entity.backCatalogId,
                    catalogId: vm.catalogId
                }, function (res) {
                    MessageService.success("使用成功");
                    $uibModalInstance.close();
                })
            }else{
                var ids = [];
                vm.data.forEach(function (item) {
                    ids.push(item);
                })
                PicLayoutXmls.use({
                    ids:ids.join(","),
                    catalogId:vm.catalogId
                }, function (res) {
                    MessageService.success("使用成功");
                    $uibModalInstance.close();
                })
            }

        }
    }
})();

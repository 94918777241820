(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarXmlController', CalendarXmlController);

    CalendarXmlController.$inject = ['$state', '$scope', 'UidService', 'ProductXml', 'ParseLinks', 'AlertService', 'MessageService', '$uibModal', 'FontManagement', 'Qinius',
        '$q', '$timeout', 'MonthWidget', '$stateParams', 'CalendarService', 'ModulesService'];

    function CalendarXmlController($state, $scope, UidService, ProductXml, ParseLinks, AlertService, MessageService, $uibModal, FontManagement, Qinius,
                                   $q, $timeout, MonthWidget, $stateParams, CalendarService, ModulesService) {
        var vm = this;
        if($stateParams.id){
            vm.data = MonthWidget.get({id : $stateParams.id});
        }
        $scope.domains = Qinius.getDomain();
        vm.width = 400;
        vm.height = 400;
        vm.monthObj= null;
        vm.isLoading = true;
        //获取token
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        //初始缩放比
        vm.scale = 1;
        //是否是拖曳画布状态
        vm.moveCanvasView = false;
        vm.moveCanvasInfo = {
            x:0,
            y:0
        }
        //初始化画布移动信息
        vm.initMoveCanvasInfo = function(){
            vm.moveCanvasInfo = {
                x:0,
                y:0
            }
        }
        vm.navList = [
            {icon:'icon-rili', name: '日历', id:'calendar'},
        ]
        vm.navPitch = 'calendar';
        $scope.$watch(function() {
            return vm.data.name;
        }, function(newName, oldName) {
            if (newName!==undefined) {
                document.title = newName + '-设计页'
            }
        });
        $q.all([$scope.domains.$promise, vm.data.$promise]).then(function () {

            vm.dataInit = angular.copy(vm.data);
            vm.url = $scope.domains.domain;
            init();
            vm.isLoading = false;
        })
        $scope.navCb = function (data) {
            vm.navPitch = data.id;
        };
        $scope.unfoldRight = function(){
            vm.isUnfoldRight = !vm.isUnfoldRight;
        }
        function init() {
            if (vm.data.sampleYear) {
                vm.data.sampleYear = String(vm.data.sampleYear);
            }
            if (vm.data.sampleMonth) {
                vm.data.sampleMonth = String(vm.data.sampleMonth);
            }
            if (vm.data.width) {
                vm.height = vm.data.height * 400 / vm.data.width;
            }
        }
        window.onresize = function(){
            $scope.$apply();
            getFullState();
        }
        //计算画布距离左边距离
        $scope.canvasCoordX = function(){
            if($("#layout-wrapper") && $(".c-editor-content") && vm.moveCanvasInfo && !vm.isLoading){
                var width = $("#layout-wrapper").width();
                var contentWidth = $(".c-editor-content").width();
                var x = (contentWidth - (width / vm.scale)) / 2 + vm.moveCanvasInfo.x;
                return x;
            }
        }
        //计算画布距离顶部距离
        $scope.canvasCoordY = function(){
            if($("#layout-wrapper") && $(".c-editor-content") && vm.moveCanvasInfo && !vm.isLoading){
                var height = $("#layout-wrapper").height();
                var contentHeight = $(".c-editor-content").height();
                var y = (contentHeight - (height / vm.scale)) / 2 + vm.moveCanvasInfo.y;
                return y;
            }
        }
        $scope.onYearMonthChnage = function () {
        }
        //键盘事件
        document.onkeydown = function(event){
            if(event.keyCode === 32){
                if(vm.moveCanvasView) return false;
                $timeout(function(){
                    vm.moveCanvasView = true;
                    $scope.$apply();
                })
                return false;
            }
            //F11
            if (event.keyCode===122 && !vm.isFullScreen) {
                event.preventDefault();
                $scope.fullScreen();
            }
            // ctrl+z
            if (event.keyCode == 90 && event.ctrlKey) {
                $scope.lastStep();
                $scope.$digest();
            }
        }
        document.onkeyup = function(){
            if(event.keyCode === 32){
                $timeout(function(){
                    vm.moveCanvasView = false;
                    $scope.$apply();
                })
            }

        }
        //移动画布
        $scope.moveCanvas = function(e){
            if(!vm.moveCanvasView) return;
            var moveCanvasInfo = angular.copy(vm.moveCanvasInfo);
            moveCanvas(e,function(x,y){
                $timeout(function(){
                    vm.moveCanvasInfo.x = moveCanvasInfo.x + x;
                    vm.moveCanvasInfo.y = moveCanvasInfo.y + y;
                })

            })
        }
        /**
         * 画布放大、缩小、还原
         * @param {*} type  0 还原 1缩小 2放大  3原尺寸
         */
        $scope.changeCanvasSize = function(type){
            $timeout(function(){
                //步长
                var stepSize = 0.02;
                //还原 或原尺寸
                if(type === 0 || type === 3){
                    vm.scale = 1;
                    vm.initMoveCanvasInfo();
                }
                // 缩小
                if(type === 1){
                    vm.scale = vm.scale - stepSize;
                    //限制最小缩放比不能小于0.5倍的初始尺寸
                    if(vm.scale < 0.5) vm.scale = 0.5;
                }
                //放大
                if(type === 2){
                    vm.scale = vm.scale + stepSize;
                    //限制放大倍数不能大于1.5倍的初始尺寸
                    // if(vm.scale > 1.5) vm.scale = 1.5;
                }
            })
        }
        //鼠标滚轮事件
        function originalEventRatio(){
            if (event.ctrlKey === true || event.metaKey) {
                var wheelDelta = event.wheelDelta ? (event.wheelDelta / 120) : (-event.detail / 3);
                if (wheelDelta > 0) {
                    $scope.changeCanvasSize(2);
                } else {
                    $scope.changeCanvasSize(1);
                }
                $scope.$apply();
                event.preventDefault();
            }
        }
        //鼠标滚轮事件
        window.addEventListener('mousewheel',originalEventRatio,{passive: false});
        $scope.$on('$destroy',function () {
            //取消监听事件
            window.removeEventListener('mousewheel',originalEventRatio)
        });
        $scope.widgetUpdate = function(item){
            vm.isLoading = true;
            $timeout(function () {
                if(!item){
                    lastData();
                }
                vm.isLoading = false;
            })
        }
        $scope.onSettingsChange = function (text) {
            if (text) {
                var data = JSON.parse(text);
                vm.data = data;
                $scope.widgetUpdate();
            }
        }

        //保存
        $scope.save = function () {
            for(var i=0; i<vm.data.dateStyles.length; i++){
                var _dateStyle = vm.data.dateStyles[i];
                for(var i1 = 0; i1<_dateStyle.items.length; i1++){
                    var _item = _dateStyle.items[i1];
                    if(!_item.textFontUuid){
                        MessageService.error('日单元格的' + _dateStyle.name + "文字字体不能为空");
                        return false
                    }
                }
            }
            for(var j=0; j<vm.data.weekStyles.length; j++){
                var _weekStyle = vm.data.weekStyles[j];
                for(var j1 = 0; j1<_weekStyle.items.length; j1++){
                    var _item = _weekStyle.items[j1];
                    if(!_item.textFontUuid){
                        MessageService.error('周单元格的' + _weekStyle.name + "文字字体不能为空");
                        return false
                    }
                }
            }
            var loading = MessageService.loading();
            $(".layout-wrapper-view").css({'background':'#fff'});
            $timeout(function () {
                var nodes = document.getElementById('page-item2');
                html2canvas_adapt(nodes, {
                    onrendered: function(canvas) {
                        $(".layout-wrapper-view").css({'background':'none'});
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);

                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', 'cover-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                vm.data.previewUrl = vm.url + data.key;
                                if(vm.data.id){
                                    MonthWidget.update(vm.data, function (res) {
                                        loading.close();
                                        MessageService.success("保存成功");
                                        history.go(-1)
                                    })
                                }else{
                                    MonthWidget.create(vm.data, function (res) {
                                        loading.close();
                                        MessageService.success("保存成功");
                                        history.go(-1)
                                    })
                                }
                                $scope.$digest();
                            },
                            error: function (request) {
                                loading.close();
                                MessageService.error("文件上传失败");
                            }
                        });
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: $("#page-item2").width(),
                    height: $("#page-item2").height(),
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: nodes.getBoundingClientRect().left,
                    y: nodes.getBoundingClientRect().top,
                    ratio:1,
                    backgroundColor: "rgba(0,0,0,0)"
                });
            })
        }
        //退出
        $scope.out = function () {
            if(!vm.operation){
                history.go(-1)
            }else{
                MessageService.confirm({
                    title:'温馨提示',
                    goback:'取消',
                    cancel:'不保存',
                    confirm:'保存',
                    msg:'退出前是否需要保存已变更的内容?',
                },function(){
                    $scope.save();
                },function (flag) {
                    if(flag){
                        history.go(-1)
                    }
                })
            }
        }
        //上一步
        vm.datas = [];
        vm.operation = false;
        function lastData(){
            vm.operation = true;
            vm.datas.push(angular.copy(vm.data));
        }
        $scope.lastStep = function(){
            if(vm.datas.length > 0){
                if(vm.datas.length > 1){
                    var _data = angular.copy(vm.datas[vm.datas.length-2 > 0 ? vm.datas.length-2 : 0]);
                    vm.data = _data;
                }else{
                    vm.data = angular.copy(vm.dataInit);
                }
                vm.datas.splice(vm.datas.length-1, 1);
                $scope.widgetUpdate(true);
            }
        }
        //还原
        $scope.initData = function () {
            if(vm.datas.length > 0){
                vm.data = angular.copy(vm.dataInit);
                $scope.widgetUpdate();
            }
        }
        //全屏
        vm.isFullScreen = false;
        function getFullState(){
            $timeout(function () {
                vm.isFullScreen = Math.abs(window.screen.height-window.document.documentElement.clientHeight) <= 17;
            })
        }
        getFullState();
        $scope.fullScreen = function () {
            var el = document.documentElement
            var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen
            if (rfs) { // typeof rfs != "undefined" && rfs
                rfs.call(el)
            }else if (typeof window.ActiveXObject !== 'undefined') {
                // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
                var wscript = new ActiveXObject('WScript.Shell')
                if (wscript != null) {
                    wscript.SendKeys('{F11}')
                }
            }
        }
        $scope.exitScreen = function(){
            var el = document
            var cfs = el.cancelFullScreen || el.mozCancelFullScreen || el.msExitFullscreen || el.webkitExitFullscreen || el.exitFullscreen
            if (cfs) { // typeof cfs != "undefined" && cfs
                cfs.call(el)
            } else if (typeof window.ActiveXObject !== 'undefined') {
                // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
                var wscript = new ActiveXObject('WScript.Shell')
                if (wscript != null) {
                    wscript.SendKeys('{F11}')
                }
            }
        }
    }
})();

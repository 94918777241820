(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('BackGroundController', BackGroundController)
        .filter('sizeTransition', function () {
            return function(bytes){
                if (bytes === 0) return '0 B';
                var k = 1024, // or 1024
                    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                    i = Math.floor(Math.log(bytes) / Math.log(k));
                return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];

            }
        });

    BackGroundController.$inject = ['$scope', '$state', 'Backgrounds', 'ParseLinks', 'paginationConstants',
     'pagingParams', 'MessageService', 'Qinius', '$q', '$uibModal', 'UidService', '$timeout','ThemeClassify'];

    function BackGroundController ($scope, $state, Backgrounds, ParseLinks, paginationConstants,
     pagingParams, MessageService, Qinius, $q, $uibModal, UidService, $timeout,ThemeClassify) {
        var vm = this;

        vm.tabs = [{key:"background", value:"背景", url:'background'},
        {key:"imageBox", value:"相框", url:'mask'},
        {key:"borderBox", value:"边框", url:'frame-control'},
        {key:"font", value:"字体", url:'font-management'},
        {key:"clipArt", value:"素材", url:'clip-art'},
        {key:"image", value:"图片", url:'pic'}];
        vm.currentName = "background";
        vm.count = 0;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.exitSure = false;
        vm.bgimgs = [];
        vm.size = 15;
        vm.page = 1;
        vm.totalItems = 0;
        var bigSizePics= [];
        vm.tagScreenIsOpen = false;


        vm.tokenTime = 360000;

        vm.themeClassifies = ThemeClassify.getAllByCondition1({items:[
            {key:"type",value:"BACKGROUND", op:"="},
            {key:"used",value:"true", op:"="}]});
        vm.domain = Qinius.getDomain();
        vm.themeClassifyId = pagingParams.c - 0;
        // Qinius.get(function (msg) {
        //     vm.token = msg.token;
        //     vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        // });
        $scope.domains = Qinius.getDomain();
        $q.all([$scope.domains.$promise]).then(function () {
            // token过期
            setTimeout(function fn() {
                Qinius.get(function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            vm.url = $scope.domains.domain;
            // loadAll();
            vm.themeClassifies.unshift({name:"全部", id:"全部", seq:-1});
            $scope.selectTag(vm.themeClassifies[0]);
        });


        $scope.view = function(data){
            vm.bgimgs.forEach(function(it){
                it.more = false;
            });
            $uibModal.open({
                templateUrl: 'app/entities/my-upload/background-view.html',
                controller: 'BackGroundViewController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: data,
                }
            }).result.then(function(res) {
            });
        }

        $scope.delete = function(item){
             MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除该背景？'
            },function(){
                Backgrounds.delete({id:item.id}, function(res){
                    MessageService.success("删除成功");
                    loadAll();
                }, function(va){
                    MessageService.error("删除失败，请稍后再试");
                })
            },function(){});
        }

        $scope.more = function(item){
            vm.bgimgs.forEach(function(it){
                it.more = false;
            });
            item.more = true;
        }

        $scope.batchDelet = function(){
            var ids = [];
            vm.bgimgs.forEach(function(it){
                if (it.selected) {
                    ids.push(it.id);
                }
            });
            if (ids.length>0) {
                MessageService.confirm(
                    {
                        title:'提示',
                        cancel:'取消',
                        confirm:'确认',
                        msg:'确定要删除所选背景？'
                    }, function(){
                    Backgrounds.batchDelete({ids_:ids}, function(res){
                        MessageService.success("删除成功");
                        vm.batch = false;
                        vm.count = 0;
                        loadAll();
                    }, function(va){
                        MessageService.error("删除失败，请稍后再试");
                    })
                })
            }else{
                MessageService.error("未勾选任何有效的背景，请确认后再操作");
            }
        }


        $scope.exitBatch = function(){
            vm.bgimgs.forEach(function(it){
                it.selected = false;
            })
            vm.batch = false;
            vm.count = 0;
        }

        $scope.itemSelect = function(item){
            if (!vm.batch) {
                return
            }
            item.selected = !item.selected;
            vm.count = 0;
            vm.bgimgs.forEach(function(it){
                if (it.selected) {
                    vm.count ++;
                }
            })
        }

        function thumbnailatorPic() {
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics = [];
                if (values) {
                    var len = 0;
                    if (vm.showBackgrounds && vm.showBackgrounds.length) {
                        len = vm.showBackgrounds.length;
                    }
                    for (var i = 0; i < values.length; i++) {
                        getBackground(values, i, len);
                    }
                }
            }, function () {
            });
        }
        var addTimer = null;
        function getBackground(files, i, len) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(files[i]);//创建Image的对象的url
            img.crossOrigin = "Anonymous";
            var fileName = files[i].name;
            var formData = new FormData();
            formData.append('file', files[i]);
            formData.append('key', 'background-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    Backgrounds.save({
                        identifier: data.key,
                        provider: 'qiniu',
                        name: fileName,
                        type: 'ProductXml'
                    }, function (resp) {
                        resp.identifier = vm.url + resp.identifier;
                        if(addTimer){
                            clearTimeout(addTimer);
                            addTimer = null;
                        }
                        addTimer = setTimeout(function () {
                            vm.page = 1;
                            loadAll();
                        },200)

                    });
                },
                error: function (request) {
                    console.log("error");
                }
            });
        }

        // 当前页变更
        vm.currentChange = function(){
            $timeout(function(){
                loadAll();
            })
        }

        function loadAll () {
            var items = [];
            vm.bgimgs = [];
            if (vm.themeClassifyId) {
                items.push({key:"themeClassify.id",value:vm.themeClassifyId, op:"="})
            }
            if (pagingParams.search) {
                items.push({key:"name",value:pagingParams.search, op:"like"})

            }
            Backgrounds.byCondition({
                items: items,
                page: vm.page-1,
                size: vm.size,
                sort: ["id,desc"]
            }, function (res, headers) {
                vm.totalItems = headers('X-Total-Count');
                res.some(function (item, i) {
                    item.identifier = vm.url + item.identifier
                    vm.bgimgs.push(item);
                })
            })
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            vm.transition();
        }

        $scope.selectTag = function(item){
            vm.themeClassifies.forEach(function(it){
                it.active = false;
            })
            item.active = true;
            if (item.id == '全部') {
                vm.themeClassifyId = null;
            }else{
                vm.themeClassifyId = item.id;
            }
            loadAll();
        }
        $scope.editTag = function(){
            $uibModal.open({
                templateUrl: 'app/entities/page/catalog-management.html',
                controller: 'CatalogManagementController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: function () {
                        return {catalogType: "BACKGROUND"}
                    }
                }
            }).result.then(function(res) {
                ThemeClassify.getAllByCondition1({items:[
                    {key:"type",value:"BACKGROUND", op:"="},
                    {key:"used",value:"true", op:"="}]}, function(data){
                        vm.themeClassifies = data;
                        vm.themeClassifies.unshift({name:"全部", id:"全部" , seq:-1});
                        $scope.selectTag(vm.themeClassifies[0]);
                    });
            });
        }

        $scope.addBackground = function () {
            $uibModal.open({
                templateUrl: 'app/entities/my-upload/modal/background-add.html',
                controller: 'BackgroundAddController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: [function () {
                        return {};
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(state) {
                if(state){
                    vm.page = 1;
                    vm.loadAll();
                }
            });
        }
    }
})();

(function () {
    "use strict";

    angular.module("platformApp").controller("HomeFirstController", HomeFirstController);

    HomeFirstController.$inject = [
        "$scope",
        "$timeout",
        "MessageService",
        "$state",
        "Auth",
        "$rootScope",
        "$stateParams",
        "$localStorage",
        "FrontUser",
        "Verify",
        "Account"
    ];

    function HomeFirstController(
        $scope,
        $timeout,
        MessageService,
        $state,
        Auth,
        $rootScope,
        $stateParams,
        $localStorage,
        FrontUser,
        Verify,
        Account
    ) {
        var vm = this;
        $(".navbar").hide();
        $(".wrap").css({ margin: 0 });
        vm.key = $stateParams.key;
        vm.uuid = $stateParams.uuid;
        vm.phone = $stateParams.phone;
        vm.loading = true;
        vm.codeDisable = false;
        //密码框类型
        vm.passType = "password";
        vm.confirmPassType = "password";
        //手机号校验错误状态
        vm.checkPhoneErr = false;
        init();
        function init() {
            FrontUser.authByThird(
                {
                    key: vm.key,
                    uuid: vm.uuid,
                    phone: vm.phone
                },
                function (res) {
                    if (res.status == 200) {
                        goPage(res.message);
                    }
                    vm.loading = false;
                }
            );
        }

        vm.page1 = true;
        vm.page2 = false;
        vm.data = {
            isAccount: false
        };
        vm.data1 = {
            key: vm.key,
            uuid: vm.uuid
        };
        vm.codeName = "获取验证码";
        vm.code = "";
        $scope.accountSelect = function (item) {
            vm.data.isAccount = item;
            vm.codeName = "获取验证码";
        };
        //更改密码框类型
        $scope.changePassType = function (passType, type) {
            vm[passType] = type;
        };
        $scope.changePhone = function () {
            vm.checkPhoneErr = false;
        };
        $scope.getCode = function () {
            if (vm.codeDisable) return;
            if (vm.phone == null || vm.phone == "") {
                MessageService.error("请先输入手机号码");
                return;
            }
            //手机号不合规
            if (!checkPhone(vm.phone)) {
                vm.checkPhoneErr = true;
                return MessageService.error("您的手机号不合规，请检查重新输入");
            }
            if (vm.codeName != "获取验证码") {
                return;
            }
            vm.codeDisable = true;
            // 发送验证码到手机
            Verify.getVerifyCode(
                {
                    tel: vm.phone,
                    incident: "用户注册"
                },
                function (msg) {
                    codeInit(60);
                },
                function () {
                    vm.codeDisable = false;
                }
            );
        };

        function codeInit(time) {
            if (time == 0) {
                vm.codeName = "获取验证码";
                vm.codeDisable = false;
                return;
            }
            vm.codeName = time + "s";
            time--;
            $timeout(function () {
                codeInit(time);
            }, 1000);
        }

        $scope.save1 = function () {
            if (!vm.data.isAccount) {
                vm.loading = true;
                FrontUser.checkUser(
                    {
                        items: [
                            { key: "phone", op: "=", value: vm.phone },
                            { key: "cancelled", op: "=", value: "false" },
                            { key: "status", op: "!=", value: "Rejected" }
                        ]
                    },
                    function (data) {
                        vm.loading = false;
                        if (data.length > 0) {
                            MessageService.error("系统检测到手机号已注册，请点击‘是’进行数据同步");
                        } else {
                            vm.page1 = false;
                            vm.page2 = true;
                        }
                    },
                    function () {
                        MessageService.error("检测手机号失败");
                        vm.loading = false;
                    }
                );
            } else {
                if (!vm.phone) {
                    MessageService.error("手机号不能为空");
                    return;
                }
                if (!vm.code) {
                    MessageService.error("验证码不能为空");
                    return;
                }
                vm.loading = true;
                Verify.checkVerifyCode(
                    {
                        tel: vm.phone,
                        code: vm.code,
                        incident: "用户注册"
                    },
                    function (msg) {
                        if (msg.status == 200 && msg.message == true) {
                            FrontUser.bindByThird(
                                {
                                    key: vm.key,
                                    uuid: vm.uuid,
                                    phone: vm.phone
                                },
                                function (res) {
                                    if (res.status == 200) {
                                        goPage(res.message);
                                    } else {
                                        MessageService.error(res.message || "数据校验失败");
                                    }
                                    vm.loading = false;
                                },
                                function (error) {
                                    MessageService.error(error.data.message || "数据校验失败");
                                    vm.loading = false;
                                }
                            );
                        } else {
                            vm.loading = false;
                            MessageService.error("手机号验证失败");
                        }
                    },
                    function () {
                        vm.loading = false;
                        MessageService.error("手机号验证失败");
                    }
                );
            }
        };

        $scope.registerByThird = function () {
            if (!vm.data1.name) {
                MessageService.error("账号不能为空");
                return;
            }
            if (!vm.phone) {
                MessageService.error("手机号不能为空");
                return;
            }
            if (!vm.code) {
                MessageService.error("验证码不能为空");
                return;
            }
            if (!vm.data1.password) {
                MessageService.error("密码不能为空");
                return;
            }
            if (vm.data1.password1 != vm.data1.password) {
                MessageService.error("确认密码不一致");
                return;
            }
            vm.loading = true;
            Verify.checkVerifyCode(
                {
                    tel: vm.phone,
                    code: vm.code,
                    incident: "用户注册"
                },
                function (msg) {
                    if (msg.status == 200 && msg.message == true) {
                        vm.data1.key = vm.key;
                        vm.data1.phone = vm.phone;
                        FrontUser.registerByThird(
                            vm.data1,
                            function (res) {
                                if (res.status == 200) {
                                    goPage(res.message);
                                } else {
                                    MessageService.error(res.message);
                                }
                                vm.loading = false;
                            },
                            function (error) {
                                MessageService.error(error.data.message || "数据校验失败");
                                vm.loading = false;
                            }
                        );
                    } else {
                        MessageService.error("手机号验证失败");
                        vm.loading = false;
                    }
                },
                function () {
                    MessageService.error("手机号验证失败");
                    vm.loading = false;
                }
            );
        };

        function goPage(token) {
            $localStorage.authenticationToken = token;

            Account.get(
                {},
                function (data) {
                    if (data.status == 200) {
                        $localStorage.user = data.data;
                        $(".navbar").show();
                        vm.account = $localStorage.user;
                        $(".users-a").html(vm.account.name);
                        $state.go("page");
                        $(".wrap").css({ margin: "48px 0 10px 254px" });
                        delete $localStorage.menu1;
                        delete $localStorage.menu2;
                        delete $localStorage.pageName;
                        $rootScope.$broadcast("authenticationSuccess");
                    }
                },
                function () {}
            );
        }
    }
})();

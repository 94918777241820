(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarTitleXmlController', CalendarTitleXmlController);

    CalendarTitleXmlController.$inject = ['$state', '$scope', 'UidService', 'ProductXml', 'ParseLinks', 'AlertService', 'MessageService', '$uibModal', 'FontManagement', 'Qinius',
        '$q', '$timeout', 'TitleWidget', '$stateParams', 'CalendarService','ModulesService'];

    function CalendarTitleXmlController($state, $scope, UidService, ProductXml, ParseLinks, AlertService, MessageService, $uibModal, FontManagement, Qinius,
                                   $q, $timeout, TitleWidget, $stateParams, CalendarService,ModulesService) {
        var vm = this;
        if($stateParams.id){
            vm.widget = TitleWidget.get({id : $stateParams.id});

        }
        $scope.domains = Qinius.getDomain();
        vm.themeColors = [];//当前组件用到的所有颜色
        vm.width = 400;
        vm.height = 400;
        vm.monthObj= null;
        vm.isLoading = true;
        //获取token
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        //初始缩放比
        vm.scale = 1;
        //是否是拖曳画布状态
        vm.moveCanvasView = false;
        vm.moveCanvasInfo = {
            x:0,
            y:0
        }
        //初始化画布移动信息
        vm.initMoveCanvasInfo = function(){
            vm.moveCanvasInfo = {
                x:0,
                y:0
            }
        }
        vm.fontList = [];
        vm.isFontList = false;
        FontManagement.getAll3({items: [], sort: ["id,asc"]}, function (resp) {
            for (var i = 0; i < resp.length; i++) {
                if(resp[i].calendarSupport){
                    vm.fontList.push(resp[i]);
                }

            }
            vm.isFontList = true;
        })
        vm.navList = [
            {icon:'icon-rili', name: '日历', id:'calendar'},
        ]
        vm.navPitch = 'calendar';

        vm.sampleYears = [
            {id:'2020',value:'2020'},
            {id:'2021',value:'2021'},
            {id:'2022',value:'2022'},
            {id:'2023',value:'2023'},
            {id:'2024',value:'2024'},
            {id:'2025',value:'2025'},
            {id:'2026',value:'2026'},
            {id:'2027',value:'2027'},
            {id:'2028',value:'2028'},
            {id:'2029',value:'2029'},
            {id:'2030',value:'2030'},
        ];
        vm.sampleMonths = [
            {id:'1',value:'1'},
            {id:'2',value:'2'},
            {id:'3',value:'3'},
            {id:'4',value:'4'},
            {id:'5',value:'5'},
            {id:'6',value:'6'},
            {id:'7',value:'7'},
            {id:'8',value:'8'},
            {id:'9',value:'9'},
            {id:'10',value:'10'},
            {id:'11',value:'11'},
            {id:'12',value:'12'},
        ];
        $scope.$watch(function() {
            return vm.widget.name;
        }, function(newName, oldName) {
            if (newName!==undefined) {
                document.title = newName + '-设计页'
            }
        });
        $q.all([$scope.domains.$promise, vm.widget.$promise]).then(function () {
            vm.dataInit = angular.copy(vm.widget);
            vm.url = $scope.domains.domain;
            init();
        })
        $scope.navCb = function (data) {
            vm.navPitch = data.id;
        };
        $scope.unfoldRight = function(){
            vm.isUnfoldRight = !vm.isUnfoldRight;
        }
        function init() {
            if (vm.widget.sampleYear) {
                vm.widget.sampleYear = String(vm.widget.sampleYear);
            }
            if (vm.widget.sampleMonth) {
                vm.widget.sampleMonth = String(vm.widget.sampleMonth);
            }
            if (!vm.widget.width) {
                vm.widget.width = vm.widget.layout.width;
            }
            if (!vm.widget.height) {
                vm.widget.height = vm.widget.layout.height;
            }
            getPageColors();
        }
        window.onresize = function(){
            $scope.$apply();
            getFullState();
        }
        //计算画布距离左边距离
        $scope.canvasCoordX = function(){
            var width = $("#layout-wrapper").width();
            var contentWidth = $(".c-editor-content").width();
            var x = (contentWidth - (width / vm.scale)) / 2 + vm.moveCanvasInfo.x;
            return x;
        }
        //计算画布距离顶部距离
        $scope.canvasCoordY = function(){
            var height = $("#layout-wrapper").height();
            var contentHeight = $(".c-editor-content").height();
            var y = (contentHeight - (height / vm.scale)) / 2 + vm.moveCanvasInfo.y;

            return y;
        }
        //键盘事件
        document.onkeydown = function(){
            if(event.keyCode === 32){
                if(vm.moveCanvasView) return false;
                $timeout(function(){
                    vm.moveCanvasView = true;
                    $scope.$apply();
                })
                return false;
            }
            //F11
            if (event.keyCode===122 && !vm.isFullScreen) {
                event.preventDefault();
                $scope.fullScreen();
            }
            // ctrl+z
            if (event.keyCode == 90 && event.ctrlKey) {
                $scope.lastStep();
                $scope.$digest();
            }
        }
        document.onkeyup = function(){
            if(event.keyCode === 32){
                $timeout(function(){
                    vm.moveCanvasView = false;
                    $scope.$apply();
                })
            }

        }
        //移动画布
        $scope.moveCanvas = function(e){
            if(!vm.moveCanvasView) return;
            var moveCanvasInfo = angular.copy(vm.moveCanvasInfo);
            moveCanvas(e,function(x,y){
                $timeout(function(){
                    vm.moveCanvasInfo.x = moveCanvasInfo.x + x;
                    vm.moveCanvasInfo.y = moveCanvasInfo.y + y;
                })

            })
        }
        /**
         * 画布放大、缩小、还原
         * @param {*} type  0 还原 1缩小 2放大  3原尺寸
         */
        $scope.changeCanvasSize = function(type){
            $timeout(function(){
                //步长
                var stepSize = 0.02;
                //还原 或原尺寸
                if(type === 0 || type === 3){
                    vm.scale = 1;
                    vm.initMoveCanvasInfo();
                }
                // 缩小
                if(type === 1){
                    vm.scale = vm.scale - stepSize;
                    //限制最小缩放比不能小于0.5倍的初始尺寸
                    if(vm.scale < 0.5) vm.scale = 0.5;
                }
                //放大
                if(type === 2){
                    vm.scale = vm.scale + stepSize;
                    //限制放大倍数不能大于1.5倍的初始尺寸
                    if(vm.scale > 1.5) vm.scale = 1.5;
                }
            })
        }
        //鼠标滚轮事件
        function originalEventRatio(){
            if (event.ctrlKey === true || event.metaKey) {
                var wheelDelta = event.wheelDelta ? (event.wheelDelta / 120) : (-event.detail / 3);
                if (wheelDelta > 0) {
                    $scope.changeCanvasSize(2);
                } else {
                    $scope.changeCanvasSize(1);
                }
                $scope.$apply();
                event.preventDefault();
            }
        }
        //鼠标滚轮事件
        window.addEventListener('mousewheel',originalEventRatio,{passive: false});
        $scope.$on('$destroy',function () {
            //取消监听事件
            window.removeEventListener('mousewheel',originalEventRatio)
        });

        $scope.onSettingsChange = function (text) {
            if (text) {
                vm.loading1 = true;
                var data = JSON.parse(text);
                vm.widget.settings = data;
                $timeout(function() {
                    lastData();
                    vm.loading1 = false;
                    getPageColors();
                });
            }
        }

        $scope.onYearMonthChnage = function () {
            lastData()
        }
        //保存
        $scope.save = function () {
            for(var i=0; i<vm.widget.settings.items.length; i++){
                var _item = vm.widget.settings.items[i];
                if(!_item.textFontUuid){
                    MessageService.error(_item.name + "的文字字体不能为空");
                    return false
                }
            }
            vm.loading = true;
            $timeout(function () {
                var nodes = document.getElementById('page-item2');
                html2canvas_adapt(nodes, {
                    onrendered: function(canvas) {
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);

                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', 'cover-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                vm.loading = false;
                                vm.widget.previewUrl = vm.url + data.key;
                                if(vm.widget.id){
                                    TitleWidget.update(vm.widget, function (res) {
                                        MessageService.success("保存成功");
                                        history.go(-1)
                                    })
                                }else{
                                    TitleWidget.create(vm.widget, function (res) {
                                        MessageService.success("保存成功");
                                        history.go(-1)
                                    })
                                }
                                $scope.$digest();
                            },
                            error: function (request) {
                                vm.loading = false;
                                MessageService.error("文件上传失败");
                            }
                        });
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: $("#page-item2").width(),
                    height: $("#page-item2").height(),
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: nodes.getBoundingClientRect().left,
                    y: nodes.getBoundingClientRect().top,
                    ratio:1,
                    backgroundColor: "rgba(0,0,0,0)"
                });
            })
        }
        //退出
        $scope.out = function () {
            if(!vm.operation){
                history.go(-1)
            }else{
                MessageService.confirm({
                    title:'温馨提示',
                    goback:'取消',
                    cancel:'不保存',
                    confirm:'保存',
                    msg:'退出前是否需要保存已变更的内容?',
                },function(){
                    $scope.save();
                },function (flag) {
                    if(flag){
                        history.go(-1)
                    }
                })
            }
        }
        //上一步
        vm.datas = [];
        vm.operation = false;
        function lastData(){
            vm.operation = true;
            vm.datas.push(angular.copy(vm.widget));
        }
        $scope.lastStep = function(){
            if(vm.datas.length > 0){
                if(vm.datas.length > 1){
                    var _data = angular.copy(vm.datas[vm.datas.length-2 > 0 ? vm.datas.length-2 : 0]);
                    vm.widget = _data;
                }else{
                    vm.widget = angular.copy(vm.dataInit);
                }
                init();
                vm.datas.splice(vm.datas.length-1, 1);
                vm.loading1 = true;
                $timeout(function() {
                    vm.loading1 = false;
                });
            }
        }
        //还原
        $scope.initData = function () {
            if(vm.datas.length > 0){
                vm.widget = angular.copy(vm.dataInit);
                init();
                vm.loading1 = true;
                $timeout(function() {
                    vm.loading1 = false;
                });
            }
        }
        //全屏

        vm.isFullScreen = false;
        function getFullState(){
            $timeout(function () {
                vm.isFullScreen = Math.abs(window.screen.height-window.document.documentElement.clientHeight) <= 17;
            })
        }
        getFullState();
        $scope.fullScreen = function () {
            var el = document.documentElement
            var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen
            if (rfs) { // typeof rfs != "undefined" && rfs
                rfs.call(el)
            }else if (typeof window.ActiveXObject !== 'undefined') {
                // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
                var wscript = new ActiveXObject('WScript.Shell')
                if (wscript != null) {
                    wscript.SendKeys('{F11}')
                }
            }
        }
        $scope.exitScreen = function(){
            var el = document
            var cfs = el.cancelFullScreen || el.mozCancelFullScreen || el.msExitFullscreen || el.webkitExitFullscreen || el.exitFullscreen
            if (cfs) { // typeof cfs != "undefined" && cfs
                cfs.call(el)
            } else if (typeof window.ActiveXObject !== 'undefined') {
                // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
                var wscript = new ActiveXObject('WScript.Shell')
                if (wscript != null) {
                    wscript.SendKeys('{F11}')
                }
            }
        }
        //获取当前组件上所有用到的颜色
        function getPageColors() {
            var colors = [];
            if(!vm.widget.settings) return [];
            vm.widget.settings.items.forEach(function (item) {
                colors.push(item.backgroundColor);
                colors.push(item.textColor);
            })
            $timeout(function () {
                vm.themeColors = colorsRemoveDuplicates(colors);
                $scope.$apply();
            })
        }
        function colorsRemoveDuplicates(colors) {
            var list = [];
            colors.forEach(function (item) {
                if(item && list.indexOf(item.toLowerCase()) < 0){
                    list.push(item.toLowerCase())
                }
            });
            return list;
        }

    }
})();

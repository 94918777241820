(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('AlipayXMLController', AlipayXMLController);

    AlipayXMLController.$inject = ['$timeout', '$scope', '$stateParams', 'ProductXmlPurchase', 'MessageService',
    '$http', '$sce', '$state'];

    function AlipayXMLController ($timeout, $scope, $stateParams, ProductXmlPurchase, MessageService,
        $http, $sce, $state) {
        var vm = this;

        if($stateParams.item){
            vm.prepare = false;
            vm.xml = JSON.parse($stateParams.item);
        }else {
            window.close();
            vm.prepare = true;
        }
        vm.payMethod = "Alipay";
        init();
        function init(){
            var data = {
                productXmlId:vm.xml.id,
                productXmlName:vm.xml.name,
                type: vm.payMethod,
                amount: vm.xml.price,
                name: "模板购买"
            }
            ProductXmlPurchase.create(data ,function(result){
                vm.purchaseId = result.id;
                ProductXmlPurchase.get({
                    id: vm.purchaseId
                }, function(val) {
                    if (!!val && val.paid) {
                        vm.prepare = true;
                        MessageService.success("支付完成");
                        window.close()
                    }else {
                        ProductXmlPurchase.aliPay({
                            id:vm.purchaseId,
                            returnUrl: window.location.href.substring(0,window.location.href.indexOf("?"))
                        },function(data){
                            if(!!data && data.status == 200){
                                $scope.trustHtml = $sce.trustAsHtml(data.message);
                            }
                        },function(){
                        });
                    }
                }, function() {});
            },function(){
                MessageService.error("创建充值订单失败，请稍后再试");
            })
        }
    }
})();

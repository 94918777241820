(function () {
    'use strict';

    var homeNav = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/layouts/navbar/home-nav.html',
        controllerAs: 'vm',
        bindings: {
            current: '<',
        },
        controller: ['$state', 'Auth', 'Principal',
            function ($state, Auth, Principal) {
                var vm = this;
                vm.isNavbarCollapsed = true;
                vm.isAuthenticated = Principal.isAuthenticated;
            }]
    };

    angular
        .module('platformApp')
        .component('homeNav', homeNav);
})();

(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('ReferenceController', ReferenceController);

    ReferenceController.$inject = ['$scope', '$uibModalInstance', '$state', '$stateParams','pages', 'MessageService'];

    function ReferenceController($scope, $uibModalInstance, $state, $stateParams, pages, MessageService) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.dpiChoose = [{data: 'mm', id: 0}, {data: 'px', id: 1}, {data: '英寸', id: 2}];
        vm.dpi = vm.dpiChoose[0];
        vm.dpi1 = vm.dpiChoose[0];
        vm.direction = 'y';
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.updateDpi = function (data) {
            if(vm.dpi.id == 1){
                vm.place = PxTurnMm(vm.place);
            }else if(vm.dpi.id == 2){
                vm.place = InTurnMm(vm.place);
            }
            if (data.id == 1) {
                vm.place = MmTurnPx(vm.place);
            } else if(data.id == 2) {
                vm.place = MmTurnIn(vm.place);
            }
            vm.dpi = data;
        }

        function save() {
            if (!vm.place) {
                MessageService.error("请输入位置");
                return;
            }
            $scope.updateDpi(vm.dpiChoose[1]);
            $uibModalInstance.close({
                direction:vm.direction,
                place:vm.place
            });
        }

    }

})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PartEditController', PartEditController);

    PartEditController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$state', 'MessageService', 'Catalog','Qinius', '$q', 'UidService',
        '$uibModal','TitleWidget','MonthWidget','ProductXml','entity','Moulds'];

    function PartEditController ($timeout, $scope, $stateParams, $uibModalInstance, $state, MessageService, Catalog,Qinius, $q, UidService,
                                    $uibModal,TitleWidget,MonthWidget,ProductXml,entity,Moulds) {
        var vm = this;
        $scope.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.step = 1;
        vm.data = {
            unit:'mm',
            unit1:'mm',
            productType: entity.productType,
            modelData: entity.modelData&&entity.modelData!='undefined'?JSON.parse(entity.modelData):null,
            modelType: entity.modelType,
            part:angular.copy(entity.part),
            pageIndex:entity.pageIndex,
            modelName:entity.modelName,
            modelPic:entity.modelPic,
            parts:angular.copy(entity.parts)
        }
        vm.data.parts.forEach(function (part) {
            if(part.id == vm.data.part.id){
                part.selected = true;
            }
        })
        console.log(vm.data)
        vm.modelData = [];
        if(vm.data.modelData){
            vm.data.modelData.forEach(function (item) {
                if(item.page === "" || item.page === null || vm.data.pageIndex === item.page){
                    vm.modelData.push(item);
                    if(vm.data.pageIndex === item.page){
                        vm.materialName = item.materialName;
                        item.page="";
                    }
                }
            })
        }
        $q.all([$scope.domains.$promise, vm.tokens.$promise]).then(function () {
            vm.url = $scope.domains.domain;
            vm.token = vm.tokens.token;
            if (entity.mouldid){
                Moulds.getAllByCondition2({
                    items: [{key: "id", op: "=", value: entity.mouldid},
                        {key: "modelType", op: "=", value: "TWO"}],
                }, function (res) {
                    if(res.length>0){
                        res[0].modelPic = vm.url + res[0].modelPic;
                        vm.data.mould = res[0];
                    }
                }, function (error) {
                    MessageService.error("获取列表失败！");
                });
            }
        })
        vm.equalRatio = false;
        vm.isEqualRatio = false;
        $scope.$watch("vm.data.part.mediabox.width", function (newValue, oldValue) {
            if(vm.equalRatio && !vm.isEqualRatio){
                vm.isEqualRatio = true;
                vm.data.part.mediabox.height = newValue/oldValue * vm.data.part.mediabox.height || 0;
            }else{
                vm.isEqualRatio = false;
            }
        })
        $scope.$watch("vm.data.part.mediabox.height", function (newValue, oldValue) {
            if(vm.equalRatio && !vm.isEqualRatio){
                vm.isEqualRatio = true;
                vm.data.part.mediabox.width = newValue/oldValue * vm.data.part.mediabox.width || 0;
            }else{
                vm.isEqualRatio = false;
            }
        })
        //单位换算
        vm.units = [
            {name:'px', id:'px'},
            {name:'mm', id:'mm'},
            {name:'英寸', id:'in'},
        ]
        $scope.unitConversion = unit;
        function unit(item){
            if(vm.data.unit == 'mm'){
                vm.data.part.mediabox.width = MmTurnPx(vm.data.part.mediabox.width).toFixed(2) - 0;
                vm.data.part.mediabox.height = MmTurnPx(vm.data.part.mediabox.height).toFixed(2) - 0;
                vm.data.part.bleed.top = MmTurnPx(vm.data.part.bleed.top).toFixed(2) - 0;
                vm.data.part.bleed.bottom = MmTurnPx(vm.data.part.bleed.bottom).toFixed(2) - 0;
                vm.data.part.bleed.left = MmTurnPx(vm.data.part.bleed.left).toFixed(2) - 0;
                vm.data.part.bleed.right = MmTurnPx(vm.data.part.bleed.right).toFixed(2) - 0;
                vm.data.part.editboxes.editbox.forEach(function (pageEdit) {
                    pageEdit.x = MmTurnPx(pageEdit.x).toFixed(2) - 0;
                    pageEdit.y = MmTurnPx(pageEdit.y).toFixed(2) - 0;
                    pageEdit.width = MmTurnPx(pageEdit.width).toFixed(2) - 0;
                    pageEdit.height = MmTurnPx(pageEdit.height).toFixed(2) - 0;
                })
            }
            if (vm.data.unit == 'in'){
                vm.data.part.mediabox.width = InTurnPx(vm.data.part.mediabox.width).toFixed(2) - 0;
                vm.data.part.mediabox.height = InTurnPx(vm.data.part.mediabox.height).toFixed(2) - 0;
                vm.data.part.bleed.top = InTurnPx(vm.data.part.bleed.top).toFixed(2) - 0;
                vm.data.part.bleed.bottom = InTurnPx(vm.data.part.bleed.bottom).toFixed(2) - 0;
                vm.data.part.bleed.left = InTurnPx(vm.data.part.bleed.left).toFixed(2) - 0;
                vm.data.part.bleed.right = InTurnPx(vm.data.part.bleed.right).toFixed(2) - 0;
                vm.data.part.editboxes.editbox.forEach(function (pageEdit) {
                    pageEdit.x = InTurnPx(pageEdit.x).toFixed(2) - 0;
                    pageEdit.y = InTurnPx(pageEdit.y).toFixed(2) - 0;
                    pageEdit.width = InTurnPx(pageEdit.width).toFixed(2) - 0;
                    pageEdit.height = InTurnPx(pageEdit.height).toFixed(2) - 0;
                })
            }
            if(item == 'mm'){
                vm.data.part.mediabox.width = Math.round(PxTurnMm(vm.data.part.mediabox.width) - 0);
                vm.data.part.mediabox.height = Math.round(PxTurnMm(vm.data.part.mediabox.height) - 0);
                vm.data.part.bleed.top = Math.round(PxTurnMm(vm.data.part.bleed.top) - 0);
                vm.data.part.bleed.bottom = Math.round(PxTurnMm(vm.data.part.bleed.bottom) - 0);
                vm.data.part.bleed.left = Math.round(PxTurnMm(vm.data.part.bleed.left) - 0);
                vm.data.part.bleed.right = Math.round(PxTurnMm(vm.data.part.bleed.right) - 0);
                vm.data.part.editboxes.editbox.forEach(function (pageEdit) {
                    pageEdit.x = Math.round(PxTurnMm(pageEdit.x) - 0);
                    pageEdit.y = Math.round(PxTurnMm(pageEdit.y) - 0);
                    pageEdit.width = Math.round(PxTurnMm(pageEdit.width) - 0);
                    pageEdit.height = Math.round(PxTurnMm(pageEdit.height) - 0);
                })
            }
            if(item == 'in'){
                vm.data.part.mediabox.width = PxTurnIn(vm.data.part.mediabox.width).toFixed(2) - 0;
                vm.data.part.mediabox.height = PxTurnIn(vm.data.part.mediabox.height).toFixed(2) - 0;
                vm.data.part.bleed.top = PxTurnIn(vm.data.part.bleed.top).toFixed(2) - 0;
                vm.data.part.bleed.bottom = PxTurnIn(vm.data.part.bleed.bottom).toFixed(2) - 0;
                vm.data.part.bleed.left = PxTurnIn(vm.data.part.bleed.left).toFixed(2) - 0;
                vm.data.part.bleed.right = PxTurnIn(vm.data.part.bleed.right).toFixed(2) - 0;
                vm.data.part.editboxes.editbox.forEach(function (pageEdit) {
                    pageEdit.x = PxTurnIn(pageEdit.x).toFixed(2) - 0;
                    pageEdit.y = PxTurnIn(pageEdit.y).toFixed(2) - 0;
                    pageEdit.width = PxTurnIn(pageEdit.width).toFixed(2) - 0;
                    pageEdit.height = PxTurnIn(pageEdit.height).toFixed(2) - 0;
                })
            }
            vm.data.unit = item;
            vm.data.unit1 = item;
        }
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[0];
            return arrDPI;
        };

        $scope.addPageEdit = function () {
            vm.data.part.editboxes.editbox.push({
                x:'',
                y:'',
                width:'',
                height:''
            })
        };
        $scope.removePageEdit = function(index){
            vm.data.part.editboxes.editbox.splice(index, 1);
        };
        //关联模型
        vm.selectModel = selectModel;
        function selectModel(data){
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: !data.mould ? 'add' : 'edit',
                            data: data,
                            modelType:'TWO'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    res.modelPic = vm.url + res.modelPic;
                    data.mould = res;
                }
            });
        }
        $scope.removeMould = function(e){
            vm.data.mould = null;
        }
        //编辑模型
        // vm.selectModelEdit = selectModelEdit;
        // function selectModelEdit(data){
        //     $uibModal.open({
        //         templateUrl: 'app/entities/page/modal/model-select.html',
        //         controller: 'ModelSelectController',
        //         controllerAs: 'vm',
        //         backdrop: 'static',
        //         size: 'lg',
        //         resolve: {
        //             entity: [function() {
        //                 return {
        //                     type: 'edit',
        //                     data: data,
        //                     modelType:'TWO'
        //                 };
        //             }]
        //         }
        //     }).result.then(function(res) {
        //         if(res){
        //             res.modelPic = vm.url + res.modelPic;
        //             data.mould = res;
        //         }
        //     });
        // }
        vm.cancel = function () {
            $uibModalInstance.close();
        }
        vm.next = function () {
            if(!vm.data.part.id){
                MessageService.error("请选择部件");
                return
            }
            vm.step = 2;
        };
        // vm.hint = '当前部件下所有页面都将应用新尺寸，相关部件备用页将被清空，是否确认修改?';
        vm.hint = '';
        vm.isEditbox = false;
        $scope.updateEditbox = function(){
            vm.isEditbox = true;
        }
        vm.isMediabox = false;
        $scope.updateMediabox = function(){
            vm.isMediabox = true;
        }
        vm.save = function () {
            if (!vm.data.part.name) {
                MessageService.error('部件名称不能为空');
                return
            }
            if(vm.isEditbox){
                vm.hint = '当前部件下所有页面都将应用新编辑区域，相关部件备用页将被清空，是否确认修改?';
            }
            if(vm.isMediabox){
                vm.hint = '当前部件下所有页面都将应用新尺寸，相关部件备用页将被清空，是否确认修改?';
            }
            var _data = vm.data;
            if(!checkFloatNum(_data.part.mediabox.width) || !checkFloatNum(_data.part.mediabox.height)){
                MessageService.error("请填写正确的画布尺寸");
                return
            }
            if(!checkFloatNum(_data.part.bleed.top) || !checkFloatNum(_data.part.bleed.bottom) || !checkFloatNum(_data.part.bleed.left) || !checkFloatNum(_data.part.bleed.right)){
                MessageService.error("请填写正确的出血尺寸");
                return
            }
            for(var i=0; i<_data.part.editboxes.editbox.length; i++){
                var pageEdit = _data.part.editboxes.editbox[i];
                if(!checkFloatNum(pageEdit.x) || !checkFloatNum(pageEdit.y) || !checkFloatNum(pageEdit.width) || !checkFloatNum(pageEdit.height)){
                    MessageService.error("请填写正确的编辑区域");
                    return false
                }
            }
            if(_data.modelType == 'TWO' && !_data.mould){
                MessageService.error("请选择模型");
                return false
            }
            if(_data.modelType == 'THREE'){
                if(!vm.materialName){
                    MessageService.error("请关联模型页");
                    return
                }
                var _modelData = angular.copy(_data.modelData);
                _modelData.forEach(function (modelItem) {
                    if(modelItem.materialName == vm.materialName){
                        modelItem.page = _data.pageIndex;
                    }
                })
                vm.modelData1 = JSON.stringify(_modelData);
            }
            if(_data.unit1 == 'px' || _data.unit1 == 'in'){
                unit('mm');
            }
            if (_data.mould) {
                _data.part.scene = {
                    background: {
                        resource: {
                            identifier: _data.mould.modelPic.indexOf('http') > -1 ? _data.mould.modelPic : vm.url + _data.mould.modelPic,
                            provider: "qiniu"
                        }
                    },
                    geometry: {
                        width: _data.mould.gWidth,
                        height: _data.mould.gHeight,
                        x: 0,
                        y: 0
                    },
                    imagebox: {
                        geometry: {
                            width: _data.mould.width,
                            height: _data.mould.height,
                            x: _data.mould.x,
                            y: _data.mould.y
                        },
                        rotation: {angle: 0},
                        transparency: 1,
                    },
                    hierarchy: _data.mould.hierarchy,
                    mouldid: _data.mould.id,
                    isScene: true,
                    scale: 1,
                    unit: "mm",
                    resource:{
                        identifier: _data.mould.modelPic.indexOf('http') > -1 ? _data.mould.modelPic : vm.url + _data.mould.modelPic,
                        provider: "qiniu"
                    }
                }
            }

            if(vm.isEditbox || vm.isMediabox){
                MessageService.confirm({
                    title:'温馨提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:vm.hint,
                },function(){
                    $uibModalInstance.close({data:_data.part,isDeleteBackupPages:true,modelData:vm.modelData1,parts:vm.data.parts});
                },function () {
                    $uibModalInstance.close();
                })
            }else{
                $uibModalInstance.close({data:_data.part,isDeleteBackupPages:false,modelData:vm.modelData1,parts:vm.data.parts});
            }
        }
        function checkFloatNum(str){
            str = Number(str);
            var reg_zs = /^[0-9]\d*(\.\d+)?$/i;
            if (!reg_zs.test(str)) {
                return false;
            }
            return true;
        }
        vm.partsListShow = false;
        vm.partsListX = 0;
        vm.partsListY = 0;
        var timer = null;
        $scope.mouseenter = function(){
            var view = document.getElementById("c-model-body-view");
            var div = document.getElementById("select-div");
            var x = div.offsetLeft;
            var y = view.offsetTop + div.offsetTop + 40;
            vm.partsListX = x;
            vm.partsListY = y;
            vm.partsListShow = true;
            if (timer !== null) {
                clearTimeout(timer)
            }
        }

        $scope.mouseleave = function(){
            if (timer !== null) {
                clearTimeout(timer)
            }
            timer = setTimeout(function () {
                $timeout(function () {
                    vm.partsListShow = false;
                })
            },100)
        }

        $scope.delete = function(item){
            item.selected = false;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PatternsModalThreeController', PatternsModalThreeController);

    PatternsModalThreeController.$inject = ['$timeout', '$scope', 'UidService', '$stateParams', '$uibModalInstance', 'entity', '$state', 'Moulds', 'MessageService', 'Qinius','$q'];

    function PatternsModalThreeController ($timeout, $scope, UidService, $stateParams, $uibModalInstance, entity, $state, Moulds, MessageService, Qinius,$q) {
        var vm = this;
        vm.oldView = false;
        vm.cancel = cancel;
        vm.save = save;
        vm.type = entity.type;
        vm.id = entity.id;
        vm.openedCheck = openedCheck;
        $scope.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.modelData = [];
        vm.units = [
            {name:'px', id:'px'},
            {name:'mm', id:'mm'},
        ]
        if(vm.type == 'add'){
            vm.data = {
                name: '',
                gWidth: '',
                gHeight: '',
                sizeUnit: 'mm',
                x: '',
                y: '',
                width: '',
                height: '',
                modelPic: '',
                hierarchy: 'BOTTOM',
                modelType:'THREE',
                modelData:[],
                modelPath:""
            }
        }else {
            Moulds.get({id: vm.id},function (res) {
                vm.data = res;
                if(vm.data.modelData){
                    vm.modelData = JSON.parse(vm.data.modelData);
                }
            })
        }
        //模型图片
        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }
        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'pic-' + UidService.get());
            formData.append('token', vm.tokens.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.data.modelPic = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        $scope.removeFile = function () {
            vm.data.modelPic = "";
        }

        //层级
        function openedCheck(item) {
            vm.data.hierarchy = item ? 'TOP' : 'BOTTOM';
            console.log(vm.data.hierarchy)
        }

        function checkFloatNum(str){
            var reg_zs = /^[0-9]\d*(\.\d+)?$/i;
            if (!reg_zs.test(str)) {
                return false;
            }
            return true;
        }
        //保存
        function save() {
            vm.data.modelData = JSON.stringify(vm.modelData);
            vm.data.modelType = 'THREE';
            if(vm.data.name.length > 20 || vm.data.name.length < 1){
                MessageService.error("模型名称不能为空且不大于20个字符");
                return
            }
            if(!vm.data.modelPic){
                MessageService.error("请上传模型图片");
                return
            }
            if(!vm.data.modelPath){
                MessageService.error("请上传3D模型");
                return
            }
            vm.data.sourceType = 'front';
            if(vm.type == 'add'){
                Moulds.save(vm.data, function (res) {
                    MessageService.success("新增成功");
                    $uibModalInstance.close(true);
                },function (error) {
                    MessageService.error("保存失败，请稍后重试！");
                })
            }
            if(vm.type == 'edit'){
                Moulds.update(vm.data, function (res) {
                    MessageService.success("编辑成功");
                    $uibModalInstance.close(true);
                },function (error) {
                    MessageService.error("保存失败，请稍后重试！");
                })
            }
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
        //dpi
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[0];
            return arrDPI;
        }


        //上传3D模型
        window.uploadPic1 = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload1(files[0]);
            }
        }

        function qiniuUpload1(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'pic-' + UidService.get());
            formData.append('token', vm.tokens.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.data.modeTFileName = file.name;
                    vm.data.modelPath = $scope.domains.domain + data.key;
                    gltfInfo(vm.data.modelPath)
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        };

        function gltfInfo(path) {
            // 加载glTF格式的模型
            vm.modelData = [];
            vm.materialMap = [];
            var loader = new THREE.GLTFLoader();
            if (THREE.DRACOLoader) {
                var dracoLoader = new THREE.DRACOLoader();
                if (dracoLoader) {
                    loader.setDRACOLoader( dracoLoader );
                }
            }
            vm.modelLoad = true;
            loader.load(path, function (gltf) {
                gltf.scene.traverse(function(child){
                    if (child.isMesh) {
                        traverseMaterials(child, function(node,i){
                            if(vm.materialMap.indexOf(node.name) == -1){
                                var _modelData = {
                                    materialName:node.name,
                                    page:''
                                };
                                vm.modelData.push(_modelData);
                                vm.materialMap.push(node.name);
                            }
                        });
                    }
                });
                vm.modelLoad = false;
                $scope.$apply();
            }, function (xhr) {
                vm.schedule = (xhr.loaded / xhr.total * 100);
            }, function (error) {
                MessageService.error("3D模型上传失败，请稍后重试！")
                vm.modelLoad = false;
            })
        }

        function traverseMaterials(object, callback) {
            object.traverse(function(node){
                if (!node.isMesh) return;
                var materials = Array.isArray(node.material)
                    ? node.material
                    : [node.material];
                materials.forEach(callback);
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PurchasedTemplateController', PurchasedTemplateController);

    PurchasedTemplateController.$inject = ['$state', '$scope', 'Qinius', '$uibModal', '$http', '$q', 'pagingParams', 'paginationConstants', '$timeout', '$stateParams', 'MessageService',
        'ProductXml', "Catalog", 'Hashtag', 'ParseLinks', '$localStorage', 'Account', 'ProductXmlPurchase','$window'];

    function PurchasedTemplateController($state, $scope, Qinius, $uibModal, $http, $q, pagingParams, paginationConstants, $timeout, $stateParams,  MessageService,
        ProductXml, Catalog , Hashtag, ParseLinks, $localStorage, Account, ProductXmlPurchase,$window) {

        var vm = this;
        vm.user = $localStorage.user;
        vm.productList = [];
        //分页信息
        vm.pageInfo = {
            page: 1,
            size: 20,
            total: 0
        }
        vm.pruchases = ProductXmlPurchase.getAllByCondition({items:[{key:"paid", op:"=", value:"true"} ,{key:"userId", op:"=", value:vm.user.id}]});
        vm.domain = Qinius.getDomain();
        $q.all([vm.domain.$promise]).then(function () {
            vm.domainQ =vm.domain.domain;
        })
        vm.getList = function () {
            ProductXml.getPurchasedList({page: vm.pageInfo.page - 1, size: vm.pageInfo.size}, function (data, headers) {
                vm.pageInfo.total = headers('X-Total-Count');
                vm.productList = data;
            })
        }
        vm.getList();
        //分页点击回调
        vm.currentChange = function(){
            $timeout(function (){
                vm.getList();
            })
        }
        //模板预览
        $scope.previewTemplate = function(template){
            console.log(template);
            if (template.version != "4.0") {
                MessageService.error("当前模板不可用");
                return;
            }
            ProductXml.get({id:template.id},function(data){
                createModal2('app/entities/page/template-view.html', 'TemplateViewController',data.id).result.then(
                    function (data) {
                    });
            });
            $(".modal-content").hide();
        };
        var createModal2 = function (url, controller,id) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: ['ProductXml', '$stateParams', function (ProductXml, $stateParams) {
                        return ProductXml.get({id: id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }]
                }
            });
        };
        //使用模板
        $scope.useTemp = function(temp){
            if(temp.version != '4.0'){
                MessageService.error("当前模板不可用");
                return
            }
            if (temp.userId != vm.user.id && temp.price>0) {
                var items =[{key:"paid", op:"=", value:"true"},{key:"productXml.id", op:"=", value:temp.id},{key:"userId", op:"=", value:vm.user.id}];
                ProductXmlPurchase.getAllByCondition({items:items} , function(data){
                    if (data && data.length>=1) {
                        $scope.employ(temp);
                    }else{
                        MessageService.error("请先购买模板，再使用。");
                    }
                }, function(){
                    MessageService.error("查询购买记录失败，请稍后再试。");
                })
            }else{
                $scope.employ(temp);
            }
        }
        $scope.employ = function (temp) {
            ProductXml.get({id: temp.id}, function (res) {
                $uibModal.open({
                    templateUrl: 'app/entities/page/modal/page-standard.html',
                    controller: 'PageStandardController',
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                    resolve: {
                        items: function(){
                            return {
                                type: 'copy',
                                id: temp.id,
                                data: null
                            }
                        },
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page)
                            };
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('page');
                            return $translate.refresh();
                        }]
                    }
                });
            })
        }

        //返回模板中心
        $scope.backToTemp = function () {
            history.back();
        }

    }

})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('LayoutSelectController', LayoutSelectController);

    LayoutSelectController.$inject = ['$timeout', '$scope', 'MessageService', '$uibModalInstance', 'msg', 'CalendarLayout', 'Qinius', 'UidService', '$q'];

    function LayoutSelectController ($timeout, $scope, MessageService, $uibModalInstance, msg, CalendarLayout, Qinius, UidService, $q) {
        var vm = this;

        vm.type = msg.type || 'DATE';
        vm.layouts = [];
        vm.selectedId = null;
        vm.selectedLayout = null;

        vm.clear = clear;
        vm.save = save;
        vm.onLayoutSelect = onLayoutSelect;

        init();

        function init() {
        	CalendarLayout.getLayouts({type: vm.type, available: true}, function (res) {
                if (res.status == 200 && res.message && res.message.length) {
                    vm.layouts = res.message || [];
                }
            });
        }

        function onLayoutSelect(item) {
        	if (item) {
        		vm.selectedId = item.id;
        		vm.selectedLayout = item;
        	} else {
        		vm.selectedId = null;
        		vm.selectedLayout = null;
        	}
        }

        function clear() {
        	$uibModalInstance.dismiss();
        }

        function save() {
        	if (!vm.selectedLayout) {
        		MessageService.error("请选择一个布局");
        		return;
        	}
            MessageService.confirm({
                title:'温馨提示',
                cancel:'取消',
                confirm:'确认',
                msg:'切换单元格，布局样式将重置，是否确认切换?'
            },function(){
                $uibModalInstance.close(vm.selectedLayout);
            })
        }

    }
})();

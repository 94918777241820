(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('FrameConfig', FrameConfig);

        FrameConfig.$inject = ['$resource'];

    function FrameConfig ($resource) {
        var resourceUrl =  'bunny/' + 'api/frame-config/:path/:id';

        return $resource(resourceUrl, {}, {
            
            "forUser": {
                method: "POST",
                params: {
                    path: "forUser"
                }
            },
            'put': {
                method:'PUT' ,
                params: {
                    path: "forUser"
                }
            },
            'delete': {
                method:'DELETE' 
            },
            'get': {
                method:'GET' 
            },
            'onlyRelease': {
                method: 'GET',
                params:{
                    path:"onlyRelease"
                },
            },
            "byCondition1": {
                method: 'POST',
                params: {
                    path: "byCondition1"
                }
            },
            "byCondition": {
                method: 'POST',
                params: {
                    path: "byCondition"
                }
            },
            "batchDelete":{
                method: 'delete',
                params:{
                    path:"batchDelete"
                },
            },
            "getAllByCondition": {
                method: 'POST',
                params: {
                    path: "getAllByCondition"
                }
            } 
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TextDetectionController', TextDetectionController);

    TextDetectionController.$inject = ['$timeout', '$scope', 'GetSetDpi', '$stateParams', '$uibModalInstance', 'entity', '$state', '$q', 'MessageService', 'Qinius',
     '$http', 'Catalog', '$uibModal','FontXmls', 'FontManagement'];

    function TextDetectionController ($timeout, $scope, GetSetDpi, $stateParams, $uibModalInstance, entity, $state, $q, MessageService, Qinius,
        $http, Catalog, $uibModal, FontXmls, FontManagement) {
        var vm = this;
        vm.loading=true;
        vm.platform2User = JSON.parse(localStorage.getItem('platform2-user'));
        vm.saveData = entity;
        vm.source = vm.saveData.source;
        vm.textDocument = vm.saveData.textDocument;
        vm.gitDpi = GetSetDpi.getCurrent();
        vm.backup = false;
        vm.data=[];
        vm.tapIndex = 1;
        vm.checkAll = false;
        $q.all([vm.gitDpi.$promise, vm.textDocument.$promise]).then(function () {
            vm.thirdPartySettings = vm.platform2User.thirdPartySettings || [];
            console.log(vm.thirdPartySettings,"ssss")
            vm.gitDpi = vm.gitDpi.message;
            vm.pages=[];
            vm.backupPages=[];
            pageDetection(vm.textDocument);
            $timeout(function () {
                pageInit();
            },1000)
        })

        $scope.backupFn = function (item) {
            vm.backup = item;
            pageInit();
        }
        function verifyTextfont(textbox, failCallback) {
            if (!textbox) {
                return;
            }
            textbox.characterWarning = false;
            textbox.characterMessage = "";
            FontManagement.fontSupport({
                uuid: textbox.fontuuid,
                bold: textbox.style.bold,
                italic: textbox.style.italic,
                content: textbox.text
            }, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    if (msg.success && !msg.supported) {
                        var message = "【" + msg.failed + "】" + "当前字体不支持这些文字！";
                        if (failCallback) {
                            failCallback(message);
                        }
                    }
                }
            })
        }
        function pageDetection(page){
            page.dpiResult = [];
            page.fontResult = [];
            if(page.levels && page.levels.level && page.levels.level.length>0){
                page.levels.level.forEach(function (level, i) {
                    var index = i+1;
                    if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                        if(level.imagebox.image.resource.width && level.imagebox.image.resource.height){
                            var _data = pxInsufficient(level.imagebox.image.resource.width, level.imagebox.image.resource.height, level.imagebox.image.scale, level.imagebox.geometry.width, level.imagebox.geometry.height);
                            if(_data < vm.gitDpi.imgDpi - 0.01){
                                page.dpiResult.push({
                                    type: 'imagebox',
                                    img: level.imagebox.image.resource.identifier,
                                    dpi: _data,
                                    expectDpi: vm.gitDpi.imgDpi,
                                    name: '图片' + index
                                })
                            }
                        }else{
                            imageInfo($http, level.imagebox.image.resource, function () {
                                var _data = pxInsufficient(level.imagebox.image.resource.width, level.imagebox.image.resource.height, level.imagebox.image.scale, level.imagebox.geometry.width, level.imagebox.geometry.height);
                                if(_data < vm.gitDpi.imgDpi - 0.01){
                                    page.dpiResult.push({
                                        type: 'imagebox',
                                        img: level.imagebox.image.resource.identifier,
                                        dpi: _data,
                                        expectDpi: vm.gitDpi.imgDpi,
                                        name: '图片' + index
                                    })
                                }
                            })
                        }
                    }
                    if(level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier){
                        if(level.imagebox.mask.resource.width && level.imagebox.mask.resource.height){
                            var _data = pxInsufficient(level.imagebox.mask.resource.width, level.imagebox.mask.resource.height, level.imagebox.mask.scale, level.imagebox.geometry.width, level.imagebox.geometry.height);
                            if(_data < vm.gitDpi.maskDpi - 0.01){
                                page.dpiResult.push({
                                    type: 'imageboxMask',
                                    img: level.imagebox.mask.resource.identifier,
                                    dpi: _data,
                                    expectDpi: vm.gitDpi.maskDpi,
                                    name: '相框' + index
                                })
                            }
                        }else{
                            imageInfo($http, level.imagebox.mask.resource, function () {
                                var _data = pxInsufficient(level.imagebox.mask.resource.width, level.imagebox.mask.resource.height, level.imagebox.mask.scale, level.imagebox.geometry.width, level.imagebox.geometry.height);
                                if(_data < vm.gitDpi.maskDpi - 0.01){
                                    page.dpiResult.push({
                                        type: 'imageboxMask',
                                        img: level.imagebox.mask.resource.identifier,
                                        dpi: _data,
                                        expectDpi: vm.gitDpi.maskDpi,
                                        name: '相框' + index
                                    })
                                }
                            })
                        }
                    }
                    if(level.imageart && level.imageart.resource && level.imageart.resource.identifier){
                        if(level.imageart.resource.width && level.imageart.resource.height){
                            var _data = pxInsufficient(level.imageart.resource.width, level.imageart.resource.height, level.imageart.scale, level.imageart.geometry.width, level.imageart.geometry.height);
                            if(_data < vm.gitDpi.clipArtDpi - 0.01){
                                page.dpiResult.push({
                                    type: 'imageart',
                                    img: level.imageart.resource.identifier,
                                    dpi: _data,
                                    expectDpi: vm.gitDpi.clipArtDpi,
                                    name: '素材' + index
                                })
                            }
                        }else{
                            imageInfo($http, level.imageart.resource, function () {
                                var _data = pxInsufficient(level.imageart.resource.width, level.imageart.resource.height, level.imageart.scale, level.imageart.geometry.width, level.imageart.geometry.height);
                                if(_data < vm.gitDpi.clipArtDpi - 0.01){
                                    page.dpiResult.push({
                                        type: 'imageart',
                                        img: level.imageart.resource.identifier,
                                        dpi: _data,
                                        expectDpi: vm.gitDpi.clipArtDpi,
                                        name: '素材' + index
                                    })
                                }
                            })
                        }
                    }
                    if (level.textbox && level.textbox.text && level.textbox.fontuuid) {
                        verifyTextfont(level.textbox, function (message) {
                            page.fontResult.push({
                                type: 'textbox',
                                message: message,
                                name: '文字' + index
                            })
                        });

                    }
                })
            }
        }


        function pageInit(){
            vm.disqualification = false;
            if(vm.textDocument.dpiResult && vm.textDocument.dpiResult.length>0){
                vm.disqualification = true;
            }
            if (vm.textDocument.fontResult && vm.textDocument.fontResult.length > 0) {
                vm.disqualification = true;
            }
            console.log(typeof vm.textDocument.fontResult,vm.textDocument.fontResult);
            vm.loading=false;
        }

        vm.checkAllFn = function(){
            vm.checkAll = !vm.checkAll;
            vm.thirdPartySettings.forEach(function (item) {
                item.selected = vm.checkAll;
            })
        };
        vm.checkFn = function(item){
            item.selected = !item.selected;
            vm.checkAll = true;
            vm.thirdPartySettings.forEach(function (item) {
                if(!item.selected){
                    vm.checkAll = false;
                }
            })
        };
        //返回
        vm.cancel = function() {
            $uibModalInstance.close(false);
        };
        function checkPageFonts() {
            if (vm.pages.length > 0) {
                for (var i = 0; i < vm.pages.length; i++) {
                    var page = vm.pages[i];
                    if (page.fontResult && page.fontResult.length > 0) {
                        if (!page.backup) {
                            MessageService.error("检测到页面文字中包含字体不支持的字符！");
                        } else {
                            MessageService.error("检测到备用页面文字中包含字体不支持的字符！");
                        }
                        return false;
                    }
                }
            }

            return true;
        }
        $scope.save1 = function(){
            if (!checkPageFonts()) {
                // 文字检查不通过
                return;
            }
            if(vm.thirdPartySettings.length>1){
                vm.tapIndex = 2;
            }else{
                vm.thirdPartySettings.forEach(function (item) {
                    item.selected = true;
                })
                save()
            }
        };
        $scope.cancel2 = function(){
            vm.tapIndex = 1;
        };
        $scope.save2 = function () {
            save()
        };
        $scope.save3 = function () {
            $uibModalInstance.close(true);
        }

        function save() {
            vm.storeIds = [];
            vm.thirdPartySettings.forEach(function (item) {
                if(item.selected){
                    vm.storeIds.push(item.id);
                }
            })
            if(vm.storeIds.length == 0){
                MessageService.error("请选择发布的渠道");
                return
            }
            vm.tapIndex = 3;
            vm.saveData.storeIds = vm.storeIds.toString();
                vm.saveLoading = true;
                FontXmls.onlyRelease(
                    {
                        id: vm.saveData.id,
                        storeIds: vm.saveData.storeIds,
                    }, function (res) {
                    vm.releaseData = res;
                    vm.saveLoading = false;
                }, function () {
                    MessageService.error("发布失败");
                    vm.saveLoading = false;
                });

            // if(vm.source == 'editor'){
            //     vm.saveData.storeIds = vm.storeIds;
            //     vm.saveLoading = true;
            //     ProductXml.release(vm.saveData, function (res) {
            //         vm.releaseData = res;
            //         vm.saveLoading = false;
            //     }, function () {
            //         MessageService.error("发布失败");
            //         vm.saveLoading = false;
            //     });
            // }
            // if(vm.source == 'list'){
            //     vm.saveData.storeIds = vm.storeIds.toString();
            //     vm.saveLoading = true;
            //     ProductXml.onlyRelease(
            //         {
            //             id: vm.saveData.id,
            //             storeIds: vm.saveData.storeIds,
            //             type: vm.saveData.type,
            //         }, function (res) {
            //         vm.releaseData = res;
            //         vm.saveLoading = false;
            //     }, function () {
            //         MessageService.error("发布失败");
            //         vm.saveLoading = false;
            //     });
            // }
        }
    }
})();

(function () {
    'use strict';

    var colorPickControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/calendar/controls/color-pick.control.html',
        controllerAs: 'vm',
        bindings: {
            color: '=',
            colors:"<",
            transparent: '<',
            onUpdate:'&'
        },

        controller: ['$scope', '$timeout', function ($scope, $timeout) {
            var vm = this;
            vm.colorOptions = [
                {value: "#FFFFFF"},
                {value: "#FFECBF"},
                {value: "#F5D351"},
                {value: "#F58B48"},
                {value: "#F55B1D"},
                {value: "#9ED969"},

                {value: "#819E22"},

                {value: "#6BCBA4"},
                {value: "#2C7FD3"},
                {value: "#272792"},
                {value: "#F2A5E1"},
                {value: "#AF6DF3"},
            ];
            vm.data = {
                color: null
            };

            vm.$onInit = function() {
                vm.data.color = vm.color;
                vm.data.transparent = !!vm.transparent;

            }
            // 每當 one-way binding 更新時，changesObj 就是改變前後的差異
            vm.$onChanges = function(data) {

            }
            vm.$postLink = function () {

            }
            vm.$doCheck = function() {
                // deep watch
            }
            vm.$onDestroy = function () {

            }

            $scope.onClick = function (color) {
                vm.data.color = color.value;
                vm.color = vm.data.color;
                $timeout(function() {
                    if (vm.onUpdate) {
                        vm.onUpdate({text: vm.data.color});
                    }
                }, 100);
            }
            $scope.onClean = function () {
                vm.data.color = null;
                vm.color = null;
                $timeout(function() {
                    if (vm.onUpdate) {
                        vm.onUpdate({text: null});
                    }
                }, 100);
            }
            var a = null;
            $scope.onInputChange = function() {
                if (a !== null) {
                    clearTimeout(a)
                }
                a = setTimeout(function () {
                    vm.color = vm.data.color;
                    $timeout(function() {
                        if (vm.onUpdate) {
                            vm.onUpdate({text: vm.data.color});
                        }
                    }, 100);
                    $scope.$apply();
                },500)
            }

        }]
    };
    angular.module('platformApp')
        .component('colorPick', colorPickControl);

})();

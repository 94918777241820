(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PageShareController', PageShareController);

    PageShareController.$inject = ['$scope', '$uibModalInstance', '$state', '$stateParams', 'MessageService', 'Catalog', 'entity',
    'ProductXml'];

    function PageShareController($scope, $uibModalInstance, $state, $stateParams, MessageService, Catalog, entity,
        ProductXml) {

        var vm = this;
        vm.productXml = entity;

        vm.clear = clear;
        vm.save = save;
        vm.label = ''

        vm.catalogs = Catalog.getAll2({items:[{key:"usable",op:"=",value:"true"},{key:"catalogType",op:"=",value:"XML"}]})


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }


        $scope.validate = function () {
            if (vm.backCatalog == null || vm.backCatalog == "") {
                MessageService.error("请选择专题");
                return false;
            }
            return true;
        };

        function save() {
            // $uibModalInstance.dismiss('cancel');
            if (!$scope.validate()) {
                return;
            }
            ProductXml.share({id:vm.productXml.id, catalogId:vm.backCatalog.id},function(data){
                MessageService.success("分享成功,等待后台审核");
                $uibModalInstance.close(data);
            },function(){

                MessageService.error("分享失败");
            });
        }

    }

})();

(function () {
    "use strict";

    var InputNumber = {
        restrict: "EAC",
        replace: true,
        transclude: true,
        templateUrl: "app/entities/components/input-number.html",
        controllerAs: "vm",
        scope: {},
        bindings: {
            ngModel: "=",
            onChange:"&",
            step:"<",
            typeStyle:"<",
            min:"<",
            max:"<"
        },
        controller: ["$scope","$timeout","$element",
            function ($scope, $timeout, $element) {
                var vm = this;
                vm.$onInit = function() {
                    if (!$element.data('$isolateScope')) {
                        $element.data('$isolateScope', $scope);
                    }
                }
                $scope.ngKeypress = function (that) {
                    var _vm = angular.element(that)[0].vm;
                    if (_vm.min != null && _vm.ngModel < _vm.min) {
                        _vm.ngModel = _vm.min
                    }
                    if (_vm.max != null && _vm.ngModel > _vm.max) {
                        _vm.ngModel = _vm.max
                    }
                    if (_vm.onChange) {
                        $timeout(function(){
                            _vm.onChange();
                            $element.find('.focusInput')[0].focus();
                        })
                    }
                }
                $scope.ngBlur = function(that){
                    var _vm = angular.element(that)[0].vm;
                    if (_vm.min != null && _vm.ngModel < _vm.min) {
                        _vm.ngModel = _vm.min
                    }
                    if (_vm.max != null && _vm.ngModel > _vm.max) {
                        _vm.ngModel = _vm.max
                    }
                    if (_vm.onChange) {
                        $timeout(function(){
                            _vm.onChange();
                        })
                    }
                }
                $scope.up = function(that){
                    var _vm = angular.element(that)[0].vm;
                    _vm.ngModel = Number(_vm.ngModel);
                    if (_vm.step) {
                        _vm.ngModel+=Number(_vm.step);
                    }else{
                        _vm.ngModel++;
                    }
                    if (_vm.max != null && _vm.ngModel > _vm.max) {
                        _vm.ngModel = _vm.max
                    }
                    $timeout(function(){
                        _vm.onChange();
                    })
                }
                $scope.down = function(that){
                    var _vm = angular.element(that)[0].vm;
                    _vm.ngModel = Number(vm.ngModel);
                    if (_vm.step) {
                        _vm.ngModel-=Number(_vm.step);
                    }else{
                        _vm.ngModel--;
                    }
                    if (_vm.min != null && _vm.ngModel < _vm.min) {
                        _vm.ngModel = _vm.min
                    }
                    $timeout(function(){
                        _vm.onChange();
                    })
                }
            }
        ]
    };
    angular.module("platformApp").component("inputNumber", InputNumber);
})();

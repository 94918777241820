(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TemplateRenameController', TemplateRenameController);

    TemplateRenameController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService','ProductXml'];

    function TemplateRenameController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService,ProductXml) {
        var vm = this;
        vm.save = save;
        vm.cancel = cancel;
        vm.data = entity || {
            id: null,
            name: '错误'
        }
        
        //返回
        function cancel() {
            $uibModalInstance.close();
        }

        function save() {
            if (!vm.data.name) {
                MessageService.error("请输入模版名称！");
                return ;
            }
            ProductXml.rename(vm.data, function(res) {
                MessageService.success("更新数据成功！");
                $uibModalInstance.close(res);
            }, function(res) {
                MessageService.error("保存数据失败！")
            });
        }
    }
})();

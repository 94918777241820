(function () {
    "use strict";

    var homeHead = {
        restrict: "EAC",
        replace: true,
        transclude: true,
        templateUrl: "app/layouts/navbar/home-head.html",
        controllerAs: "vm",
        bindings: {},

        controller: [
            "$state",
            "Auth",
            "Principal",
            "ProfileService",
            "LoginService",
            "$scope",
            "$localStorage",
            "$uibModal",
            "Catalog",
            "Notice",
            "$rootScope",
            function (
                $state,
                Auth,
                Principal,
                ProfileService,
                LoginService,
                $scope,
                $localStorage,
                $uibModal,
                Catalog,
                Notice,
                $rootScope
            ) {
                var vm = this;

                vm.isNavbarCollapsed = true;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.login = login;
                vm.logout = logout;
                vm.menus = [];
                vm.menusSelf = [];
                vm.account = $localStorage.user;

                var unsubscribe = $rootScope.$on("platformApp:noticeUpdate", function (event) {
                    $scope.getNotice();
                });

                Catalog.findCatalogByProductXml({ custom: true, type: "LAYOUT" }, function (data) {
                    if (data.status == 200) {
                        vm.catalogs = data.message;
                    }
                });

                vm.categoryList = [
                    { name: "模板分类", value: "module", path: "catalog-management", catalogType: "XML" },
                    { name: "布局分类", value: "layout", path: "catalog-management", catalogType: "LAYOUT" },
                    { name: "素材分类", value: "material", path: "catalog-management", catalogType: "CLIPART" },
                    { name: "边框分类", value: "border", path: "catalog-management", catalogType: "BORDER" }
                ];

                vm.items = ["设计工具", "分享中心", "消息中心"];
                $scope.selectedEvent = vm.items[0];
                if (
                    $state.current.name == "textShare" ||
                    $state.current.name == "picture-share" ||
                    $state.current.name == "calendar-center-body" ||
                    $state.current.name == "calendar-center-title"
                ) {
                    $scope.selectedEvent = "分享中心";
                }
                if ($state.current.name == "notice") {
                    $scope.selectedEvent = "消息中心";
                }

                vm.current = $state.current;
                $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
                    vm.current = toState;
                });

                $scope.templateList = function () {
                    $scope.getCatalogs();
                    $scope.getSelfCatalogs();
                    //$state.go("templateList");
                };
                $scope.chooseItem = function (item) {
                    $scope.selectedEvent = item;
                    if (item == "设计工具") {
                        $(".sidebar1").show();
                        $state.go("page");
                    }
                    if (item == "分享中心") {
                        $state.go("textShare");
                    }
                    if (item == "消息中心") {
                        $state.go("notice");
                    }
                };
                function login() {
                    LoginService.open();
                }

                function logout() {
                    vm.account = null;
                    Auth.logout();
                    $state.go("home");
                }

                var createModal = function (url, controller) {
                    return $uibModal.open({
                        templateUrl: url,
                        controller: controller,
                        backdrop: "static",
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: "vm",
                        resolve: {
                            pagingParams: [
                                "$stateParams",
                                "PaginationUtil",
                                function ($stateParams, PaginationUtil) {
                                    return {
                                        page: PaginationUtil.parsePage($stateParams.page)
                                    };
                                }
                            ],
                            translatePartialLoader: [
                                "$translate",
                                "$translatePartialLoader",
                                function ($translate, $translatePartialLoader) {
                                    $translatePartialLoader.addPart("password");
                                    return $translate.refresh();
                                }
                            ]
                        }
                    });
                };

                $scope.resetPassword = function () {
                    createModal("app/account/password/password.html", "PasswordController").result.then(function (
                        data
                    ) {});
                };
                vm.remind = 0;
                $scope.getNotice = function () {
                    //需要接口调用
                    Notice.getAllByCondition1({ items: [] }, function (data) {
                        if (data && data.message && data.message.length > 0) {
                            for (var i = data.message.length - 1; i >= 0; i--) {
                                var notice = data.message[i];
                                if (!notice.read) {
                                    vm.remind = vm.remind + 1;
                                }
                            }
                        }
                    });
                };
                $scope.getNotice();
                $scope.readBatch = function () {
                    Notice.readAll({}, function () {
                        vm.remind = 0;
                        messageInit();
                    });
                };
                $scope.jzMessageOver = function () {
                    messageInit();
                };
                function messageInit() {
                    vm.jzMessage = [];
                    Notice.getAllByCondition1({ items: [] }, function (data) {
                        if (data && data.message && data.message.length > 0) {
                            vm.jzMessage = data.message;
                        }
                    });
                }

                $scope.read = function (data) {
                    if (!data.read) {
                        Notice.read({ id: data.id });
                    }
                    $uibModal
                        .open({
                            templateUrl: "app/entities/notice/notice-detail.html",
                            controller: "NoticeDetailController",
                            backdrop: "static",
                            keyboard: true,
                            windowClass: "jh-modal-md",
                            controllerAs: "vm",
                            size: "lg",
                            resolve: {
                                entity: data
                            }
                        })
                        .result.then(function (res) {
                            data.read = true;
                        });
                };
            }
        ]
    };

    angular.module("platformApp").component("homeHead", homeHead);
})();

(function () {
    "use strict";

    angular.module("platformApp").controller("OriginalAgreementController", OriginalAgreementController);

    OriginalAgreementController.$inject = ["$scope","$uibModalInstance","FrontUser","$localStorage","MessageService"];

    function OriginalAgreementController($scope,$uibModalInstance,FrontUser,$localStorage,MessageService) {
        var vm = this;
        vm.loading = false;
        //同意协议
        $scope.submitAgree = function(){
            if(vm.loading) return;
            vm.loading = true;
            FrontUser.original({},function(res){
                vm.loading = false;
                $localStorage.user = res;
                $uibModalInstance.close(true);
            },function(){
                vm.loading = false;
                MessageService.error("网络错误，请重试");
            });
        };
        $scope.close = function (isAgree) {
            $uibModalInstance.close(isAgree);
        };
    }
})();

(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('TemplateViewController', TemplateViewController);

    TemplateViewController.$inject = ['$scope', 'entity', 'Qinius', '$http', '$q', 'ImageService', '$uibModalInstance', 'FontManagement'];

    function TemplateViewController($scope, entity, Qinius, $http, $q, ImageService, $uibModalInstance, FontManagement) {

        var vm = this;
        vm.article = entity;
        $scope.domains = Qinius.getDomain();

        $q.all([$scope.domains.$promise]).then(function () {
            vm.url = $scope.domains.domain;
            var artDoc = angular.copy(vm.article.document);
            //3d
            if(vm.article.modelType && vm.article.modelType === 'THREE'){
                vm.threeView = true;
                vm.threeTemplate = artDoc;
                vm.threeProductXmlData = {
                    modelBlendent: vm.article.modelBlendent,
                    modelData:vm.article.modelData,
                    modelPath:vm.article.modelPath,
                };
            }else{
                vm.watchSure = true
            }

            if (artDoc.bindingtype == 'Left2') {
                $scope.isPull = false;
                vm.bindingtype = 'Left';
            } else {
                vm.bindingtype = artDoc.bindingtype;
            }
        })


        $scope.backTo = function () {
            $uibModalInstance.dismiss('cancel');

            $(".modal-content").show().removeClass('opacity0');
        };
    }
})();

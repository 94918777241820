(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TextXmlOutController', TextXmlOutController);

    TextXmlOutController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService'];

    function TextXmlOutController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;

        function save() {
            $uibModalInstance.close(true);
        }
        function cancel() {
            $uibModalInstance.close(false);
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('Moulds', Moulds);

    Moulds.$inject = ['$resource'];

    function Moulds ($resource) {
        var resourceUrl =  'bunny/' + 'api/moulds/:path/:id';

        return $resource(resourceUrl, {}, {
            "save": {
                method: 'POST'
            },
            "get": {
                method: 'GET'
            },
            "update": {
                method: 'PUT'
            },
            "delete": {
                method: 'DELETE'
            },
            "batchDelete": {
                method: 'POST',
                params:{
                    path:"batchDelete"
                },
            },
            "getAllUsable": {
                method: 'GET',
                params:{
                    path:"getAllUsable"
                },
                isArray:true
            },
            "getAllByCondition": {
                method: 'POST',
                params:{
                    path:"getAllByCondition"
                },
                isArray:true
            },
            "getAllByCondition2": {
                method: 'POST',
                params:{
                    path:"getAllByCondition2"
                },
                isArray:true
            },
            "byCondition": {
                method: 'POST',
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition2": {
                method: 'POST',
                params:{
                    path:"byCondition2"
                },
                isArray:true
            },
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('NoticeDetailController', NoticeDetailController);

    NoticeDetailController.$inject = ['$state', '$scope', 'Notice', 'ParseLinks', 'paginationConstants',  'entity', '$uibModalInstance'];

    function NoticeDetailController($state, $scope, Notice, ParseLinks, paginationConstants, entity, $uibModalInstance) {

        var vm = this;
        vm.notice = entity; 
        $scope.$emit('platformApp:noticeUpdate');

        $scope.cancel = function(){
            $uibModalInstance.close(vm.notice);
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PartAddController', PartAddController);

    PartAddController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$state', 'MessageService', 'Catalog','Qinius', '$q', 'UidService',
        '$uibModal','TitleWidget','MonthWidget','ProductXml','entity'];

    function PartAddController ($timeout, $scope, $stateParams, $uibModalInstance, $state, MessageService, Catalog,Qinius, $q, UidService,
                                    $uibModal,TitleWidget,MonthWidget,ProductXml,entity) {
        var vm = this;
        $scope.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        $q.all([$scope.domains.$promise, vm.tokens.$promise]).then(function () {
            vm.url = $scope.domains.domain;
            vm.token = vm.tokens.token;
        })
        vm.data = {
            unit:'mm',
            unit1:'mm',
            productType: entity.productType,
            modelData: entity.modelData?angular.copy(JSON.parse(entity.modelData)):[],
            modelType: entity.modelType,
            modelName: entity.modelName,
            modelPic: entity.modelPic,
            parts:entity.parts,
            part:{
                counter:1,
                mediabox:{},
                bleed:{},
                editboxes:{
                    editbox:[
                        {x:"",y:"",width:"",height:"",}
                    ]
                },
            },
        }
        console.log(vm.data)
        vm.modelData = [];
        if(vm.data.modelData){
            vm.data.modelData.forEach(function (item) {
                if(item.page === "" || item.page === null){
                    vm.modelData.push(item);
                }
            })
        }
        vm.parts=[];
        if(vm.data.productType == "BOOK"){
            vm.parts=[
                {name:'腰封',id:'B4',seq:0,exist:false,custom:false,},
                {name:'护封',id:'B3',seq:1,exist:false,custom:false,},
                {name:'封面',id:'B1',seq:2,exist:false,custom:false,},
                {name:'内页',id:'P',seq:3,exist:false,custom:false,},
                {name:'封底',id:'B2',seq:4,exist:false,custom:false,}];
        }
        if(vm.data.productType == "PHOTO"){
            vm.parts=[
                {name:'正面',id:'P0',seq:0,exist:false,custom:false},
                {name:'反面',id:'P1',seq:1,exist:false,custom:false}];
        }
        if(vm.data.productType == "CALENDAR"){
            vm.parts=[
                {name:'封面',id:'B1',seq:2,exist:false,custom:false,},
                {name:'内页',id:'P',seq:3,exist:false,custom:false,},];
        }
        vm.data.parts.forEach(function (part) {
            var _exist = false;
            vm.parts.forEach(function (part1) {
                if(part.id == part1.id){
                    part1.exist = true;
                    _exist = true;
                    if((part.counter<1 || !part.counter)){
                        part1.exist = false;
                    }
                }
            })
            if(!_exist){
                vm.parts.push({
                    name:part.name,
                    id:part.id,
                    seq:part.seq,
                    exist:true,
                    custom:part.custom
                })
            }
        })
        $scope.partCb = function(item){
            if(item.exist){
                return
            }
            vm.data.part.custom = item.custom;
            vm.data.part.id = item.id;
            vm.data.part.name = item.name;
            vm.data.part.seq = item.seq;
            vm.data.part.required = false;
        }

        vm.isPart = false;
        $scope.addPart = function(){
            vm.part = "";
            vm.isPart = true;
            $timeout(function () {
                $("#partInput").focus();
            })
        }
        $scope.partNameChange = function(){
            var _seq = vm.parts[vm.parts.length-1] ? angular.copy(vm.parts[vm.parts.length-1].seq) : 10;
            vm.data.part.seq=angular.copy(_seq<9 ? 10 : (Number(_seq)+1));
            vm.data.part.id=UidService.get();
            vm.data.part.pageCount=1;
            vm.data.part.exist=false;
            vm.data.part.required= false;// 是否必需部件
            vm.data.part.custom= true; // 是否自定义部件
        }
        $scope.partBlur = function(){
            if(vm.part){
                var _seq = vm.parts[vm.parts.length-1] ? angular.copy(vm.parts[vm.parts.length-1].seq) : 10;
                vm.parts.push({
                    seq:angular.copy(_seq<9 ? 10 : (Number(_seq)+1)),
                    name: vm.part,
                    id:UidService.get(),
                    pageCount:1,
                    exist:false,
                    required: false,// 是否必需部件
                    custom: true, // 是否自定义部件
                })
                $scope.partCb(vm.parts[vm.parts.length-1])
            }
            vm.part = "";
            vm.isPart = false;
        }
        $scope.deletePart = function(index){
            vm.parts.splice(index,1);
        }

        vm.equalRatio = false;
        vm.isEqualRatio = false;
        $scope.$watch("vm.data.part.mediabox.width", function (newValue, oldValue) {
            newValue = newValue || 0;
            oldValue = oldValue || 0;
            if(vm.equalRatio && !vm.isEqualRatio){
                vm.isEqualRatio = true;
                vm.data.part.mediabox.height = (newValue/oldValue||0) * vm.data.part.mediabox.height || 0;
            }else{
                vm.isEqualRatio = false;
            }
        })
        $scope.$watch("vm.data.part.mediabox.height", function (newValue, oldValue) {
            newValue = newValue || 0;
            oldValue = oldValue || 0;
            if(vm.equalRatio && !vm.isEqualRatio){
                vm.isEqualRatio = true;
                vm.data.part.mediabox.width = (newValue/oldValue||0) * vm.data.part.mediabox.width || 0;
            }else{
                vm.isEqualRatio = false;
            }
        })
        //单位换算
        vm.units = [
            {name:'px', id:'px'},
            {name:'mm', id:'mm'},
            {name:'英寸', id:'in'},
        ]
        $scope.unitConversion = unit;
        function unit(item){
            if(vm.data.unit == 'mm'){
                vm.data.part.mediabox.width = MmTurnPx(vm.data.part.mediabox.width).toFixed(2) - 0;
                vm.data.part.mediabox.height = MmTurnPx(vm.data.part.mediabox.height).toFixed(2) - 0;
                vm.data.part.bleed.top = MmTurnPx(vm.data.part.bleed.top).toFixed(2) - 0;
                vm.data.part.bleed.bottom = MmTurnPx(vm.data.part.bleed.bottom).toFixed(2) - 0;
                vm.data.part.bleed.left = MmTurnPx(vm.data.part.bleed.left).toFixed(2) - 0;
                vm.data.part.bleed.right = MmTurnPx(vm.data.part.bleed.right).toFixed(2) - 0;
                vm.data.part.editboxes.editbox.forEach(function (pageEdit) {
                    pageEdit.x = MmTurnPx(pageEdit.x).toFixed(2) - 0;
                    pageEdit.y = MmTurnPx(pageEdit.y).toFixed(2) - 0;
                    pageEdit.width = MmTurnPx(pageEdit.width).toFixed(2) - 0;
                    pageEdit.height = MmTurnPx(pageEdit.height).toFixed(2) - 0;
                })
            }
            if (vm.data.unit == 'in'){
                vm.data.part.mediabox.width = InTurnPx(vm.data.part.mediabox.width).toFixed(2) - 0;
                vm.data.part.mediabox.height = InTurnPx(vm.data.part.mediabox.height).toFixed(2) - 0;
                vm.data.part.bleed.top = InTurnPx(vm.data.part.bleed.top).toFixed(2) - 0;
                vm.data.part.bleed.bottom = InTurnPx(vm.data.part.bleed.bottom).toFixed(2) - 0;
                vm.data.part.bleed.left = InTurnPx(vm.data.part.bleed.left).toFixed(2) - 0;
                vm.data.part.bleed.right = InTurnPx(vm.data.part.bleed.right).toFixed(2) - 0;
                vm.data.part.editboxes.editbox.forEach(function (pageEdit) {
                    pageEdit.x = InTurnPx(pageEdit.x).toFixed(2) - 0;
                    pageEdit.y = InTurnPx(pageEdit.y).toFixed(2) - 0;
                    pageEdit.width = InTurnPx(pageEdit.width).toFixed(2) - 0;
                    pageEdit.height = InTurnPx(pageEdit.height).toFixed(2) - 0;
                })
            }
            if(item == 'mm'){
                vm.data.part.mediabox.width = Math.round(PxTurnMm(vm.data.part.mediabox.width) - 0);
                vm.data.part.mediabox.height = Math.round(PxTurnMm(vm.data.part.mediabox.height) - 0);
                vm.data.part.bleed.top = Math.round(PxTurnMm(vm.data.part.bleed.top) - 0);
                vm.data.part.bleed.bottom = Math.round(PxTurnMm(vm.data.part.bleed.bottom) - 0);
                vm.data.part.bleed.left = Math.round(PxTurnMm(vm.data.part.bleed.left) - 0);
                vm.data.part.bleed.right = Math.round(PxTurnMm(vm.data.part.bleed.right) - 0);
                vm.data.part.editboxes.editbox.forEach(function (pageEdit) {
                    pageEdit.x = Math.round(PxTurnMm(pageEdit.x) - 0);
                    pageEdit.y = Math.round(PxTurnMm(pageEdit.y) - 0);
                    pageEdit.width = Math.round(PxTurnMm(pageEdit.width) - 0);
                    pageEdit.height = Math.round(PxTurnMm(pageEdit.height) - 0);
                })
            }
            if(item == 'in'){
                vm.data.part.mediabox.width = PxTurnIn(vm.data.part.mediabox.width).toFixed(2) - 0;
                vm.data.part.mediabox.height = PxTurnIn(vm.data.part.mediabox.height).toFixed(2) - 0;
                vm.data.part.bleed.top = PxTurnIn(vm.data.part.bleed.top).toFixed(2) - 0;
                vm.data.part.bleed.bottom = PxTurnIn(vm.data.part.bleed.bottom).toFixed(2) - 0;
                vm.data.part.bleed.left = PxTurnIn(vm.data.part.bleed.left).toFixed(2) - 0;
                vm.data.part.bleed.right = PxTurnIn(vm.data.part.bleed.right).toFixed(2) - 0;
                vm.data.part.editboxes.editbox.forEach(function (pageEdit) {
                    pageEdit.x = PxTurnIn(pageEdit.x).toFixed(2) - 0;
                    pageEdit.y = PxTurnIn(pageEdit.y).toFixed(2) - 0;
                    pageEdit.width = PxTurnIn(pageEdit.width).toFixed(2) - 0;
                    pageEdit.height = PxTurnIn(pageEdit.height).toFixed(2) - 0;
                })
            }
            vm.data.unit = item;
            vm.data.unit1 = item;
        }
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[0];
            return arrDPI;
        };

        $scope.addPageEdit = function () {
            vm.data.part.editboxes.editbox.push({
                x:'',
                y:'',
                width:'',
                height:''
            })
        };
        $scope.removePageEdit = function(index){
            vm.data.part.editboxes.editbox.splice(index, 1);
        };
        //关联模型
        vm.selectModel = selectModel;
        function selectModel(data){
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'add',
                            data: data,
                            modelType:'TWO'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    res.modelPic = vm.url + res.modelPic;
                    data.mould = res;
                }
            });
        }
        //编辑模型
        vm.selectModelEdit = selectModelEdit;
        function selectModelEdit(data){
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/model-select.html',
                controller: 'ModelSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'edit',
                            data: data,
                            modelType:'TWO'
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    res.modelPic = vm.url + res.modelPic;
                    data.mould = res;
                }
            });
        }
        vm.cancel = function () {
            $uibModalInstance.close();
        }
        vm.next = function () {
            if(!vm.data.part.id){
                MessageService.error("请选择部件");
                return
            }
        };
        vm.save = function () {
            var _data = angular.copy(vm.data);
            if(!checkFloatNum(_data.part.mediabox.width) || !checkFloatNum(_data.part.mediabox.height)){
                MessageService.error("请填写正确的画布尺寸");
                return
            }
            if(!checkFloatNum(_data.part.bleed.top) || !checkFloatNum(_data.part.bleed.bottom) || !checkFloatNum(_data.part.bleed.left) || !checkFloatNum(_data.part.bleed.right)){
                MessageService.error("请填写正确的出血尺寸");
                return
            }
            // for(var i=0; i<_data.part.editboxes.editbox.length; i++){
            //     var pageEdit = _data.part.editboxes.editbox[i];
            //     if(!checkFloatNum(pageEdit.x) || !checkFloatNum(pageEdit.y) || !checkFloatNum(pageEdit.width) || !checkFloatNum(pageEdit.height)){
            //         MessageService.error("请填写正确的编辑区域");
            //         return false
            //     }
            // }
            if(_data.modelType == 'TWO' && !_data.mould){
                MessageService.error("请选择模型");
                return false
            }
            if(_data.modelType == 'THREE' && !vm.materialName){
                MessageService.error("请关联模型页");
                return false
            }
            if(_data.unit1 == 'px'){
                unit('mm');
            }
            var page = createPage();
            if (_data.mould) {
                page.scene = {
                    background: {
                        resource: {
                            identifier: _data.mould.modelPic.indexOf('http') > -1 ? _data.mould.modelPic : vm.url + _data.mould.modelPic,
                            provider: "qiniu"
                        }
                    },
                    geometry: {
                        width: _data.mould.gWidth,
                        height: _data.mould.gHeight,
                        x: 0,
                        y: 0
                    },
                    imagebox: {
                        geometry: {
                            width: _data.mould.width,
                            height: _data.mould.height,
                            x: _data.mould.x,
                            y: _data.mould.y
                        },
                        rotation: {angle: 0},
                        transparency: 1,
                    },
                    hierarchy: _data.mould.hierarchy,
                    mouldid: _data.mould.id,
                    isScene: true,
                    scale: 1,
                    unit: "mm",
                    resource:{
                        identifier: _data.mould.modelPic.indexOf('http') > -1 ? _data.mould.modelPic : vm.url + _data.mould.modelPic,
                        provider: "qiniu"
                    }
                }
            }
            _data.part.editboxes = vm.data.part.editboxes;
            page.seq = 1;
            page.uuid= UidService.get();
            vm.data.part.mediabox.x=0;
            vm.data.part.mediabox.y=0;
            page.config={
                "dynamic":false,
                "monthIndex":0,
                "monthMultiple":false,
                "partid":_data.part.id,
                "parts":{
                    list:[_data.part]
                }
            }
            console.log(page)
            $uibModalInstance.close({
                page:page,
                part:_data.part,
                materialName:vm.materialName,
            });
        }
        function createPage() {
            vm.data.part.editboxes = {
                editbox:[{
                    x:0,
                    y:0,
                    width:vm.data.part.mediabox.width,
                    height:vm.data.part.mediabox.height,
                }]
            }
            var _page1 = {
                uuid: UidService.get(),
                isScene: false,
                bgImg: '',
                background: {resource: {},color:'#ffffff', type: "Color"},
                pagecover: {},
                levels: {},
                barcode: {},
                qrcode: {},
                editbox: vm.data.part.editboxes.editbox[0],
                editboxes: {
                    editbox: vm.data.part.editboxes.editbox
                },
                mediabox: vm.data.part.mediabox,
                trimbox: {
                    height: vm.data.part.mediabox.height - Number(vm.data.part.bleed.bottom) - Number(vm.data.part.bleed.top),
                    width: vm.data.part.mediabox.width - Number(vm.data.part.bleed.left) - Number(vm.data.part.bleed.right),
                    x: vm.data.part.bleed.left,
                    y: vm.data.part.bleed.top
                },
                scale: 1,
                proportion: 1,
                seq: 0,
                spread: false,
                backup: false,
                unreplace: !vm.data.replace
            }
            return _page1;
        }
        function checkFloatNum(str){
            str = Number(str);
            var reg_zs = /^[0-9]\d*(\.\d+)?$/i;
            if (!reg_zs.test(str)) {
                return false;
            }
            return true;
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('platformApp')
        .factory('Qinius', Qinius)
        .factory('ClipArts', ClipArts)
        .factory('ThemeClassify', ThemeClassify)
        .factory('Backgrounds', Backgrounds)
        .factory('SourceMaterial', SourceMaterial)
        .factory('ProductTemplate', ProductTemplate)
        .factory('Catalog', Catalog)
        .factory('Hashtag', Hashtag)
        .factory('Category', Category)
        .factory('Galleries', Galleries)
        .factory('XmlToImg', XmlToImg)
        .factory('FrameConfig', FrameConfig);

    Qinius.$inject = ['$resource'];
    ThemeClassify.$inject = ['$resource'];
    ClipArts.$inject = ['$resource'];
    Backgrounds.$inject = ['$resource'];
    SourceMaterial.$inject = ['$resource'];
    Hashtag.$inject = ['$resource'];
    ProductTemplate.$inject = ['$resource'];
    Category.$inject = ['$resource'];
    Catalog.$inject = ['$resource'];
    Galleries.$inject = ['$resource'];
    XmlToImg.$inject = ['$resource'];
    FrameConfig.$inject = ['$resource'];

    function FrameConfig($resource) {
        var service = $resource('bunny/api/frame-config/:path/:id', {}, {
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
        });
        return service;
    }
    function Qinius($resource) {
        var service = $resource('bunny/api/qiniu/:path/:id', {}, {
            'get': {
                method: 'GET',
                params: {
                    path: 'upload-token'
                }
            },
            'getDomain': {
                method: 'GET',
                params: {
                    path: 'bucket-domain'
                }
            }
        });
        return service;
    }

    function Backgrounds($resource) {
        var service = $resource('bunny/api/backgrounds/:path/:id', {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAll'
                },
                isArray: true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "batchDelete":{
                method: 'delete',
                params:{
                    path:"batchDelete"
                },
            },
            "changeStatue":{
                method:"GET",
                params:{
                    path:"changeStatue"
                }
            },
            'update': { method:'PUT' },
            'updateImg': { method:'POST' },
            "batchList":{
                method:"POST",
                params:{
                    path:"batchList"
                },
                isArray:true
            },
        });
        return service;
    }

    function ThemeClassify ($resource) {
        var resourceUrl =  'bunny/' + 'api/theme-classify/:path/:id';
        return $resource(resourceUrl, {}, {
            "saveForUser":{
                method:"POST",
                params:{
                    path:"saveForUser"
                }
            },
            "byCondition1":{
                method:"POST",
                params:{
                    path:"byCondition1"
                },
                isArray:true
            },
            "getAllByCondition1":{
                method:"POST",
                params:{
                    path:"getAllByCondition1"
                },
                isArray:true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                },
                isArray:true
            },
            "byCondition4":{
                method:"GET",
                params:{
                    path:"byCondition4"
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray:true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray:true
            },
            "getAllByCondition3":{
                method:"POST",
                params:{
                    path:"getAllByCondition3"
                },
                isArray:true
            },
            "getAllByCondition4":{
                method:"GET",
                params:{
                    path:"getAllByCondition4"
                }
            },
            "del":{
                method:"DELETE"
            },
            "changeStatue":{
                method:"GET",
                params:{
                    path:"changeStatue"
                }
            },
            "get":{
                method:"GET"
            },
            "save":{
                method:"PUT"
            },
            'updateSeqByList':{
                method: 'POST',
                params:{
                    path:"updateSeqByList"
                },
            }
        });
    }

    function ClipArts($resource) {
        var service = $resource('bunny/api/clip-arts/:path/:id', {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAll'
                },
                isArray: true
            },
            'getAll2': {
                method: 'POST',
                params: {
                    path: 'getAll2'
                },
                isArray: true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byConditionNew":{
                method:"POST",
                params:{
                    path:"byCondition-new"
                },
                isArray:true
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray:true
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                },
                isArray:true
            },
            "batchDelete":{
                method:"get",
                params:{
                    path:"batchDelete"
                },
            },
            'batchCreate': {
                method: 'POST',
                params:{
                    path:'batchCreate'
                },
                isArray:true
            },
            'createOne': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            }
        });
        return service;
    }

    function SourceMaterial($resource) {
        var service = $resource('bunny/api/source-material/:path/:id', {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAll'
                },
                isArray: true
            },
            'getAll2': {
                method: 'POST',
                params: {
                    path: 'getAll2'
                },
                isArray: true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                },
                isArray:true
            },
            'createOne': {
                method: 'PUT'
            },
            'batchCreate': {
                method: 'POST',
                params:{
                    path:'batchCreate'
                },
                isArray:true
            },
            "batchDelete":{
                method:"GET",
                params:{
                    path:"batchDelete"
                },
            },
            'update': {
                method: 'PUT'
            }
        });
        return service;
    }

    function ProductTemplate($resource) {
        return $resource('bunny/api/product-templates/:path/:id', {}, {
            'updateVersion': {
                method: 'GET',
                params: {
                    path: 'updateVersion'
                }
            },
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "groupByCatalogAndOrderBySeq":{
                method:"GET",
                params:{
                    path:"groupByCatalogAndOrderBySeq"
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }

    function Catalog($resource) {
        return $resource('bunny/api/catalogs/:path/:id' , {}, {
            'quickLinks': {
                method: 'GET',
                params: {
                    path: 'quickLinks'
                },
                isArray: true
            },
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAll'
                },
                isArray: true
            },
            'getAll2': {
                method: 'POST',
                params: {
                    path: 'getAll2'
                },
                isArray: true
            },
            "getAll3": {
                method: 'POST',
                params:{
                    path:"getAll3"
                },
                isArray:true
            },
            'findCatalogByProductXml': {
                method: 'GET',
                params: {
                    path: 'findCatalogByProductXml'
                },
                isArray: false
            },
            'usable': {
                method: 'GET',
                params: {
                    path: 'usable'
                }
            },
            'deleteCatalog': {
                method: 'DELETE'
            },
            'get': {
                method: 'GET'
            },
            'createCatalog': {
                method: 'POST'
            },
            'updateCatalog': {
                method: 'PUT'
            },
            'updateSeqByList':{
                method: 'POST',
                params:{
                    path:"updateSeqByList"
                },
            }
        });
    }

    function Hashtag($resource) {
        return $resource('bunny/api/hashtags/:path/:id', {}, {
            "byCondition2": {
                method: "POST",
                params: {
                    path: "byCondition2"
                },
                isArray: true
            },
            "getAll2": {
                method: "POST",
                params: {
                    path: "getAll2"
                },
                isArray: true
            },
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': {method: 'PUT'}
        });
    }

    function Category($resource) {
        return $resource('manager/api/categories/:path/:id', {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': {method: 'PUT'}
        });
    }
    function Galleries($resource) {
        return $resource('bunny/api/galleries/:path/:id', {}, {
            'byConditionNew': {
                method: 'POST',
                params: {
                    path: 'byConditionNew'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'query': {method: 'GET', isArray: true},
            'delete': {method: 'DELETE'},
            'get': {
                method: 'GET'
            },
            'upload': {
                params: {
                    path: "upload"
                },
                method: 'GET'
            },
            "batchDelete":{
                method: 'GET',
                params:{
                    path:"batchDelete"
                },
            },
            'update': {method: 'PUT'},
            'uploadBatch':{
                method: 'POST',
                params:{
                    path:"uploadBatch"
                },
                isArray:true
            }
        });
    }

    function XmlToImg ($resource) {
        var resourceUrl = 'fileserves/' + 'api/convert/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params: {
                    path: 'xmlToImg'
                }
            },
            'documentToImg': {
                method: 'POST',
                params: {
                    path: 'documentToImg'
                }
            }
        });
    }
})();

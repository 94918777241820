(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarCenterTitlePreviewModalController', CalendarCenterTitlePreviewModalController);

        CalendarCenterTitlePreviewModalController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService','CalendarService'];

    function CalendarCenterTitlePreviewModalController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService,CalendarService) {
        var vm = this;
        vm.cancel = cancel;
        vm.widget = entity.data;
        vm.titleObj = {};
        init();

        function init() {
            if (vm.widget.sampleYear) {
                vm.widget.sampleYear = String(vm.widget.sampleYear);
            }
            if (vm.widget.sampleMonth) {
                vm.widget.sampleMonth = String(vm.widget.sampleMonth);
            }
            if (!vm.widget.width) {
                vm.widget.width = vm.widget.layout.width;
            }
            if (!vm.widget.height) {
                vm.widget.height = vm.widget.layout.height;
            }
            queryYearMonthDataset();
        }

        function queryYearMonthDataset() {
            var req = {
                year: vm.widget.sampleYear || 2023,
                month: vm.widget.sampleMonth || 1
            };
            var formats = [];
            var layout = vm.widget.layout || {};
            if (layout.items && layout.items.length) {
                for (var i = 0; i < layout.items.length; i++) {
                    var item = layout.items[i];
                    if (item.type == 'year' || item.type == 'month') {
                        formats.push({type: item.type, format: item.format});
                    }
                }
            }
            req.formats = formats;
            CalendarService.queryYearMonthSet(req, function (res) {
                vm.titleObj = res.data || {};
            });

        }
        //返回
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();

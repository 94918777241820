(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PageMySuffix2Controller', PageMySuffix2Controller);

    PageMySuffix2Controller.$inject = ['$state', '$scope', '$uibModal', '$http', '$q', '$timeout', '$stateParams', 'ImageService', 'MessageService',
        'ProductXml', 'UidService', 'ClipArts', 'SourceMaterial', 'ThirdPartySetting', 'FontManagement'];

    function PageMySuffix2Controller($state, $scope, $uibModal, $http, $q, $timeout, $stateParams, ImageService, MessageService,
                                     ProductXml, UidService, ClipArts, SourceMaterial, ThirdPartySetting, FontManagement) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").css({margin: 0});
        var timer = null;
        localStorage.removeItem('ctrlKeyArray');
        $scope.thumbnailPic = false;
        vm.exitSure = false;
        var productXmlPage = $stateParams.pageIndex;
        var modelType = $stateParams.modelType;
        var modelData = $stateParams.modelData;
        var modelPath = unescape($stateParams.modelPath)
        // console.log(unescape($stateParams.modelPath))
        console.log(modelType)

        // 图片大小，增加七牛参数
        vm.menuImageView = menuImageView; //菜单栏
        vm.pageImageView = pageImageView; //画布内
        vm.pageImageViewOrient = pageImageViewOrient; //画布内加旋转参数

        ThirdPartySetting.getByKey({key: $stateParams.key}, function (data) {
            vm.thirds = data;
        }, function () {
            MessageService.error("配置信息 获取失败");
        });

        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpiW = arrDPI[0];
            vm.windowDpiH = arrDPI[1];
            // vm.windowDpiW = 300;
            // vm.windowDpiH = 300;
            return arrDPI;
        }

        //键盘操作array
        vm.CtrlKeyArray = {imageboxes: [], textboxes: [], imagearts: [], barcode: [], qrcode: [], textdocuments: []};
        vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, textdocument: {}};

        var unitTransform = function (mm) {
            // mm换算成px  Math.ceil(mm / 25.4 * 300);
            // return (mm * 3) - 0;
            return (mm / 25.4 * vm.windowDpiW).toFixed(2) - 0;
        };
        var unitTransformPx = function (px) {
            // px换算成mm  Math.floor(px / 300 * 25.4)
            // return (px * 1 / 3).toFixed(2) - 0;
            return (px / vm.windowDpiW * 25.4).toFixed(2) - 0;
        };

        vm.windowH = window.innerHeight - 90;

        vm.colorArr1 = ['#ffffff', '#666666', '#7babaf', '#e69594', '#93a9da', '#eac8fb'];
        vm.colorArr2 = ['#000000', '#666666', '#a8a8a8', '#d9d9d9', '#ffffff', '#647c6e', '#ff5b5c', '#ffbd4a', '#fef952', '#99e264', '#36b72a', '#44d9e5', '#c27171', '#2fb3ff', '#b760a6'];

        vm.sizeList = [
            {name: '12', id: '12'},
            {name: '13', id: '13'},
            {name: '14', id: '14'},
            {name: '15', id: '15'},
            {name: '16', id: '16'},
            {name: '17', id: '17'},
            {name: '18', id: '18'},
            {name: '19', id: '19'},
            {name: '20', id: '20'},
            {name: '22', id: '22'},
            {name: '24', id: '24'},
            {name: '26', id: '26'},
            {name: '28', id: '28'},
            {name: '30', id: '30'},
        ]
        vm.colorList = [
            {name: '#000000', id: '#000000'},
            {name: '#666666', id: '#666666'},
            {name: '#a8a8a8', id: '#a8a8a8'},
            {name: '#d9d9d9', id: '#d9d9d9'},
            {name: '#ffffff', id: '#ffffff'},
            {name: '#647c6e', id: '#647c6e'},
            {name: '#ffbd4a', id: '#ffbd4a'},
            {name: '#fef952', id: '#fef952'},
            {name: '#99e264', id: '#99e264'},
            {name: '#36b72a', id: '#36b72a'},
            {name: '#44d9e5', id: '#44d9e5'},
            {name: '#c27171', id: '#c27171'},
            {name: '#2fb3ff', id: '#2fb3ff'},
            {name: '#b760a6', id: '#b760a6'},
        ]

        /* ===============================================
         页面
         ================================================= */
        vm.units = [
            {name: 'px', id: 'px'},
            {name: 'mm', id: 'mm'}
        ];
        vm.unit = 'mm';
        var oldPage = null;

        var dpId = 0;
        vm.dpiChoose = [{data: 'mm', id: 0}, {data: 'px', id: 1}];
        $scope.dpi = vm.dpiChoose[dpId];
        $scope.dWidth = document.documentElement.clientWidth || document.body.clientWidth;
        $scope.dHeight = document.documentElement.clientHeight || document.body.clientHeight;
        // 左边操作栏 显示规则
        $scope.selPanel = 'page';
        // 右侧边栏 显示规则
        $scope.RightPanel = 'page';
        $scope.pageValue = {
            px: {width: 0, height: 0, x: 0, y: 0},
            mm: {width: 0, height: 0, x: 0, y: 0}
        }
        $scope.template = {
            transparency: 1,
            cover: {},
            scene: null,
            pageI: 0,
            angle: 0,
            backup: false,
            unreplace: false,
            spread: false,
            letterspacing: 0,
            lineheight: 0,
            text: '',
            bgimgs: {bgimg: []},
            bgimgs2: {bgimg: []},
            masks: {mask: []},
            cliparts: {clipart: []},
            documentpics: {documentpic: []},
            pages: {
                page: []
            }
        };
        $scope.selectBox = {
            current: 'page'
        }
        vm.backupPageSize = 0;

        /* ==========================================================================
         出血值
         ========================================================================== */
        var oldSizePx = 0, oldSizeMm = 0;

        function getBloodSize(page,i) {
            if (vm.unit == 'mm') {
                // mm
                page.blood = {
                    bottom: vm.page.pageBloodB,
                    left: vm.page.pageBloodL,
                    right: vm.page.pageBloodR,
                    top: vm.page.pageBloodT
                };
                page.bloodPx = {
                    top: unitTransform(vm.page.pageBloodT),
                    right: unitTransform(vm.page.pageBloodR),
                    bottom: unitTransform(vm.page.pageBloodB),
                    left: unitTransform(vm.page.pageBloodL),
                };
            } else {
                // px
                var tab = ThreeHundredDpiToMm(vm.page.pageBloodT, vm.page.pageBloodB);
                var lar = ThreeHundredDpiToMm(vm.page.pageBloodL, vm.page.pageBloodR);
                page.blood = {
                    top: tab.mm1,
                    bottom: tab.mm2,
                    left: lar.mm1,
                    right: lar.mm2,
                }
                page.bloodPx = {
                    top: unitTransform(tab.mm1),
                    bottom: unitTransform(tab.mm2),
                    left: unitTransform(lar.mm1),
                    right: unitTransform(lar.mm2),
                }
            }

            page.xayBorder = page.bloodPx.top + page.bloodPx.bottom;
            // page.mediabox.height = page.bloodPx.top + page.bloodPx.bottom + page.trimbox.height;
            // page.mediabox.width = page.bloodPx.left + page.bloodPx.right + page.trimbox.width;
            page.trimbox.height = page.mediabox.height - page.bloodPx.top - page.bloodPx.bottom;
            page.trimbox.width = page.mediabox.width - page.bloodPx.left - page.bloodPx.right;

            var mmL = page.blood.left - oldSizeMm.left;
            var mmT = page.blood.top - oldSizeMm.top;
            if (page.barcode && page.barcode.geometry) {
                page.barcode.geometry.x += unitTransform(mmL);
                page.barcode.geometry.y += unitTransform(mmT);
            }
            if (page.qrcode && page.qrcode.geometry) {
                page.qrcode.geometry.x += unitTransform(mmL);
                page.qrcode.geometry.y += unitTransform(mmT);
            }

            angular.forEach(page.levels.level, function (level) {
                if (level.imageart && level.imageart.current) {
                    level.imageart.geometry.x += unitTransform(mmL);
                    level.imageart.geometry.y += unitTransform(mmT);
                }
                if (level.imagebox && level.imagebox.current) {
                    level.imagebox.geometry.x += unitTransform(mmL);
                    level.imagebox.geometry.y += unitTransform(mmT);
                }
                if (level.textbox && level.textbox.current) {
                    level.textbox.geometry.x += unitTransform(mmL);
                    level.textbox.geometry.y += unitTransform(mmT);
                }
            })
            setOriginalWaH(page.trimbox.width, page.trimbox.height, i);
            // console.log(page)
            // console.log(page.mediabox)
        }

        /* ===============================================
         文字
         ================================================= */
        vm.fontNames = [];
        $scope.fontSizes = [];
        for (var f = 6; f < 101; f++) {
            $scope.fontSizes.push(f);
        }
        $scope.size = $scope.fontSizes[10];

        function myBrowser() {
            var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
            var isOpera = userAgent.indexOf("Opera") > -1;
            if (isOpera) {
                return "Opera"
            }
            ; //判断是否Opera浏览器
            if (userAgent.indexOf("Firefox") > -1) {
                return "FF";
            } //判断是否Firefox浏览器
            if (userAgent.indexOf("Chrome") > -1) {
                return "Chrome";
            }
            if (userAgent.indexOf("Safari") > -1) {
                return "Safari";
            } //判断是否Safari浏览器
            if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
                return "IE";
            }
            ; //判断是否IE浏览器
        }


        var mb = myBrowser();

        //得到原始模板框中最大宽高比例
        var $con = $(".content-scroll");
        var $contain = $(".content-page-main");
        var originalScale = $con.width() * 0.8 / ($con.height() * 0.8);
        vm.windowH = window.innerHeight - 50 - 42 -110;
        vm.windowW = window.innerWidth - 500;
        vm.windowH1 = 100;
        vm.windowW1 = 100;

        ProductXml.getThirdData({key: $stateParams.key}, function (res) {
            ProductXml.getDocumentByKey({
                key: $stateParams.identifier,
                domain: res.domain
            }, function (resp) {
                vm.token = res.token;
                vm.url = res.domain + '/';
                if (resp && resp.pages && resp.pages.page) {
                    resp.pages.page.forEach(function (page) {
                        if(page.levels && page.levels.level && page.levels.level.length>0){
                            page.levels.level.sort(compare('seq', true));
                        }
                    })
                    console.log(resp);
                    var pages = resp.pages.page;
                    var page = pages[0];
                    vm.top = page.trimbox.y;
                    vm.bottom = page.mediabox.height - page.trimbox.height - page.trimbox.y;
                    vm.left = page.trimbox.x;
                    vm.right = page.mediabox.width - page.trimbox.width - page.trimbox.x;

                    vm.page = {
                        name: $stateParams.xmlName,
                        pageSize: {width: page.mediabox.width, height: page.mediabox.height},
                        bloodSize: {top: vm.top, bottom: vm.bottom, left: vm.left, right: vm.right},
                        pageBloodT:vm.top,
                        pageBloodB:vm.bottom,
                        pageBloodL:vm.left,
                        pageBloodR:vm.right,
                        pageNum: pages.length,
                        dpi: $scope.dpi,
                        bindingtype: resp.bindingtype,
                        replace: $stateParams.replace
                    };
                    if (page.editbox) {
                        vm.page.editbox = {
                            x: page.editbox.x, y: page.editbox.y, width: page.editbox.width, height: page.editbox.height
                        }
                    }
                    dpId = 0;
                    vm.page.provider = 'qiniu';
                    vm.page.identifier = $stateParams.identifier;

                    if (vm.page.pageNum > 1) {
                        $scope.visibleRight = true;
                    }

                    $scope.template = angular.copy(resp);
                    $scope.template.bgimgs = angular.copy(resp.bgimgs);
                    $scope.template.bgimgs2 = angular.copy(resp.bgimgs2);
                    $scope.template.masks = angular.copy(resp.masks);
                    $scope.template.cliparts = angular.copy(resp.cliparts);
                    $scope.template.pages = angular.copy(resp.pages);
                    $scope.template.documentpics = angular.copy(resp.documentpics);
                    if ($scope.template.documentpics == null) {
                        $scope.template.documentpics = {
                            documentpic: []
                        }
                    }
                    if ($scope.template.documentpics.documentpic == null) {
                        $scope.template.documentpics.documentpic = [];
                    }
                    var trimbox = resp.pages.page[0].trimbox;
                    // console.log($scope.template)
                    // console.log(vm.page)

                    pagesToPx(resp.pages.page,$scope.template.pages,'pages')
                    if(resp.deletedPages&&resp.deletedPages.page&&resp.deletedPages.page.length){
                        pagesToPx(resp.deletedPages.page,$scope.template.deletedPages)
                    }
                    $scope.template.width = unitTransform(trimbox.width);
                    $scope.template.height = unitTransform(trimbox.height);
                    var pageSize = vm.page.pageSize;
                    var pages = $scope.template.pages.page;
                    $scope.currentPage = pages[0];
                    $scope.lastPage = pages[0];
                    setUlWidth();
                    getImageboxLen();
                    loadAttribute();

                    oldSizePx = angular.copy(pages[0].bloodPx);
                    oldSizeMm = angular.copy(pages[0].blood);
                    oldSizeMm.left = trimbox.x;
                    oldSizeMm.top = trimbox.y;

                    $scope.template.pageI = 0;
                    $scope.template.levelI = 0;
                    setTimeout(function () {
                        oldPage = angular.copy(pages[0]);
                        // console.log(oldPage);
                    },2000)
                    // console.log($scope.template.pages.page)
                    setMargin();
                }
                $scope.history = [angular.copy($scope.template)];
                $scope.currentHistoryIndex = 0;
                $scope.pageHistory = [{
                    history: [angular.copy($scope.template.pages.page[0])],
                    currentHistoryIndex: 0
                }];
                // console.log($scope.template)
                if($scope.template.pages.page[0].scene){
                    vm.isCustom = false;
                }else {
                    vm.isCustom = true;
                }

            });
        })
        function getImageSource(item) {
            var width = item.image.resource.width;
            var height = item.image.resource.height;

            var _w = item.geometry.width;
            var _h = item.geometry.height;
            var bgImgR = width/height;
            var bgImgR1 = _w/_h;
            item.image.offsetx = 0;
            item.image.offsety = 0;
            if(bgImgR >= bgImgR1){
                item.image.adaptation = 'Height';
                item.image.height = _h;
                item.image.width = _h/height*width;
            }else {
                item.image.adaptation = 'Width';
                item.image.width = _w;
                item.image.height = _w/width*height;
            }
        }


        $scope.$watch('toggle.now', function () {
            if ($scope.toggle.now) {
                // console.log($scope.template.bgimgs2.bgimg)
                setTimeout(function () {
                    waterFall('main0');
                    $scope.$digest();
                }, 600);
            }
        });

        $scope.toggle = {
            now: false
        };
        /**
         * 宽高坐标从 mm 换算成 px
         * @param obj
         * @param index
         * @returns {{height: number, width: number, x: number, y: number}}
         */
        function getGeometry(obj) {
            return {
                height: unitTransform(obj.height),
                width: unitTransform(obj.width),
                x: unitTransform(obj.x),
                y: unitTransform(obj.y)
            }
        }

        var elem = document.querySelector('input[type="range"]');

        var rangeValue = function () {
            $(".transparency-num").val(elem.value);
            $scope.selectBox.transparency = parseFloat(elem.value);
            $scope.template.transparency = elem.value * 100;

            $scope.$digest();
        };

        elem.addEventListener("input", rangeValue);

        $scope.transparencyChange = function (attr) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if ($scope.template.transparency > 100) {
                MessageService.error("透明度有效范围：0~100");
                $scope.template.transparency = 100;
            }
            $(elem).val($scope.template.transparency / 100);
            level[$scope.selectBox.current].transparency = $scope.template.transparency / 100;
            updateHistorys();
        }

        // 左边侧边栏点击切换
        $scope.selectPanel = function (attr) {
            if ($scope.selPanel == attr) {
                $scope.hidePanel(1);
                return
            }
            vm.leftShow = true;
            $(".leftPanel-mainContent").removeClass('hide1');
            $(".rightPanel").removeClass('rightPanel-big rightPanel-big1 rightPanel-big2 rightPanel-big3');
            if (attr == 'page') {
                if (vm.unit == 'mm') {
                    $scope.pageValue.mediabox.width = unitTransformPx($scope.currentPage.mediabox.width);
                    $scope.pageValue.mediabox.height = unitTransformPx($scope.currentPage.mediabox.height);
                } else {
                    $scope.pageValue.mediabox.width = $scope.currentPage.mediabox.width;
                    $scope.pageValue.mediabox.height = $scope.currentPage.mediabox.height;
                }

                $scope.RightPanel = 'page';
                $scope.selPanel = attr;
                setTimeout(function () {
                    waterFall('main0');
                }, 200);
            }

            if (attr == 'design') {
                if (vm.unit == 'mm') {
                    $scope.pageValue.mediabox.width = unitTransformPx($scope.currentPage.mediabox.width);
                    $scope.pageValue.mediabox.height = unitTransformPx($scope.currentPage.mediabox.height);
                } else {
                    $scope.pageValue.mediabox.width = $scope.currentPage.mediabox.width;
                    $scope.pageValue.mediabox.height = $scope.currentPage.mediabox.height;
                }
                $scope.RightPanel = 'page';
                $scope.selPanel = attr;
                setTimeout(function () {
                    waterFall('main0');
                }, 200);
            }

            if (attr == 'placement') {
                if ($scope.currentPage.scene) {
                    $scope.pageValue.mm = {
                        width: 170,
                        height: 120,
                        x: 12,
                        y: 12
                    }
                    $(".temp-px").addClass('hide1');
                    $(".temp-mm").removeClass('hide1');

                    $scope.currentPage.imagebox = {
                        geometry: {
                            width: unitTransform($scope.pageValue.mm.width),
                            height: unitTransform($scope.pageValue.mm.height),
                            x: unitTransform($scope.pageValue.mm.x),
                            y: unitTransform($scope.pageValue.mm.y)
                        }, rotation: {
                            angle: 0
                        },
                        widthM: $scope.pageValue.mm.width,
                        heightM: $scope.pageValue.mm.height,
                        offsetXM: $scope.pageValue.mm.x,
                        offsetYM: $scope.pageValue.mm.y,
                    }
                    $scope.selectScene();
                } else {
                    addPlacement();
                }
                $scope.RightPanel = 'placement';
            }
            if (attr == 'barcode') {
                $scope.selPanel = 'barcode';
                $scope.RightPanel = 'barcode';
            }
            if (attr == 'qrcode') {
                $scope.selPanel = 'qrcode';
                $scope.RightPanel = 'qrcode';
            }

            if (attr == 'text') {
                $scope.selPanel = 'text';
            }

            if (attr == 'mask') {
                $scope.RightPanel = $scope.selPanel;
                $scope.selPanel = attr;
                setTimeout(function () {
                    waterFall('main2');
                }, 200);

                $scope.selPanel = attr;
            }

            if (attr == 'material') {
                $scope.RightPanel = $scope.selPanel;
                $scope.selPanel = attr;
                setTimeout(function () {
                    var h = $(".leftPanel-head").height() + 50;
                    $(".clipart-con").css({height: 'calc(100vh - ' + h + 'px)'});
                    if(mb=='FF'){
                        $(".leftPanel-con li").addClass('mar-left-6');
                    }
                }, 200);

                $scope.selPanel = attr;
            }

            if (attr == 'backup') {
                $scope.selPanel = attr;
            }
            if (attr == 'backup' && 0 == vm.backupPageSize) {
                vm.backupPageSize = 20;
            }
        }

        $scope.shezhi = false;
        $scope.darkTheme = false;
        $scope.shezhiClick = function () {
            $scope.shezhi = !$scope.shezhi;
        }

        /* ==========================================================================
         模板处理
         ========================================================================== */
        //删除模板背景图片
        $scope.deleteBgImg = function () {
            if ($scope.template.pages.page[$scope.template.pageI].bgImg == '') {
                return;
            }
            $scope.template.pages.page[$scope.template.pageI].bgImg = '';
            $scope.template.pages.page[$scope.template.pageI].background.resource.identifier = '';
            $scope.template.pages.page[$scope.template.pageI].hasBgImg = false;
            updateHistorys();
        };

        //模板预览点击时
        $scope.selectPages = function (index, e) {
            fn(e)
            if (index == null) {
                return;
            }
            $scope.lastPage = $scope.template.pages.page[$scope.template.pageI];
            // console.log($scope.template.pageI)
            setTimeout(function () {
                checkPageCover();
            })
            // var nodes = document.getElementsByClassName('pageNode');
            // setThumbnailPic(nodes[$scope.template.pageI], $scope.currentPage);
            // if (index !== $scope.template.pageI) {
            //     $(".bg-image").attr('src', '');
            // }
            $scope.template.pageI = index;
            $scope.RightPanel = 'page';
            isCanLeftAndRightPage();
            getPagePx();
            canvasInfo();
        };

        var setUlWidth = function () {
            var w = 30 + 50, w1 = 44;
            var seq = 1;
            angular.forEach($scope.template.pages.page, function (page) {
                if (!page.scene&&!page.backup) {
                    w += page.mediabox.width * 98 / page.mediabox.height + 16;
                }
                if(!page.editboxes || !page.editboxes.editbox){
                    page.editboxes={
                        editbox: [page.editbox]
                    }
                }
                if(!page.backup){
                    page.seq = seq++;
                }
                if (page.scene) {
                    var scene = page.scene;
                    w += scene.geometry.width * 98 / scene.geometry.height + 16;
                }
            });
            $(".sort-ul").width(w + 'px');
            if (mb == 'FF') {
                $(".leftPanel-con li").addClass('mar-left-6');
            }
        };

        $scope.sortableOptions = {
            // 数据有变化
            update: function (e, ui) {
                $timeout(function () {
                    var resArr = [];
                    angular.forEach($scope.template.pages.page, function (page, i) {
                        var x = 0;
                        angular.forEach(page.levels.level, function (level, l) {
                            if (level.imagebox && level.imagebox.geometry) {
                                x++;
                            }
                        })
                        resArr.push(x)
                    })
                })
            },

            // 完成动作
            stop: function (e, ui) {
                //do nothing
                isCanLeftAndRightPage();
            },
            items: "li:not(.not-sortable)"
        };

        //模板宽高onchange
        $scope.updateTemplateHeightAndWidth = function () {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if(!currentPage){
                return
            }
            if (vm.unit == 'px') {
                // px---mm
                vm.page.pageSize.width = unitTransformPx($scope.pageValue.mediabox.width);
                vm.page.pageSize.height = unitTransformPx($scope.pageValue.mediabox.height);
                currentPage.mediabox.width = $scope.pageValue.mediabox.width;
                currentPage.mediabox.height = $scope.pageValue.mediabox.height;

            } else {

                vm.page.pageSize.width = $scope.pageValue.mediabox.width;
                vm.page.pageSize.height = $scope.pageValue.mediabox.height;
                currentPage.mediabox.width = unitTransform($scope.pageValue.mediabox.width);
                currentPage.mediabox.height = unitTransform($scope.pageValue.mediabox.height);
            }
            currentPage.trimbox.width = currentPage.mediabox.width - currentPage.bloodPx.left - currentPage.bloodPx.right;
            currentPage.trimbox.height = currentPage.mediabox.height - currentPage.bloodPx.top - currentPage.bloodPx.bottom;
            setOriginalWaH(currentPage.trimbox.width, currentPage.trimbox.height, i);
            setUlWidth();
            resetBackground();
        };

        //初始化像素
        function loadAttribute() {
            if (vm.unit == 'mm') {
                //属性栏
                $scope.pageValue = {
                    mediabox: {
                        width: unitTransformPx($scope.currentPage.mediabox.width),
                        height: unitTransformPx($scope.currentPage.mediabox.height)
                    },
                    editbox: {
                        width: unitTransformPx($scope.currentPage.editbox.width),
                        height: unitTransformPx($scope.currentPage.editbox.height),
                        x: unitTransformPx($scope.currentPage.editbox.x),
                        y: unitTransformPx($scope.currentPage.editbox.y)
                    },
                    barcode: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    qrcode: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    imagebox: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    imageart: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    textbox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    textdocument: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    }
                }
            }
            if (vm.unit == 'px') {
                //属性栏
                $scope.pageValue = {
                    mediabox: {
                        width: $scope.currentPage.mediabox.width,
                        height: $scope.currentPage.mediabox.height
                    },
                    editbox: {
                        width: $scope.currentPage.editbox.width,
                        height: $scope.currentPage.editbox.height,
                        x: $scope.currentPage.editbox.x,
                        y: $scope.currentPage.editbox.y
                    },
                    barcode: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    qrcode: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    imagebox: {
                        width: '',
                        height: '',
                        x: '',
                        y: ''
                    },
                    imageart: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    textbox: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    },
                    textdocument: {
                        width: '',
                        height: '',
                        x: '',
                        y: '',
                    }
                }
            }
            // console.log($scope.pageValue)
            // console.log($scope.currentPage)
        }

        //单位换算
        $scope.unitConversion = function () {
            unit()
        }

        function unit() {
            if (vm.unit == 'mm') {
                $scope.pageValue = {
                    mediabox: {
                        width: unitTransformPx($scope.currentPage.mediabox.width),
                        height: unitTransformPx($scope.currentPage.mediabox.height)
                    },
                    editbox: {
                        width: unitTransformPx($scope.currentPage.editbox.width),
                        height: unitTransformPx($scope.currentPage.editbox.height),
                        x: unitTransformPx($scope.currentPage.editbox.x),
                        y: unitTransformPx($scope.currentPage.editbox.y)
                    },
                    barcode: {
                        width: unitTransformPx($scope.pageValue.barcode.width),
                        height: unitTransformPx($scope.pageValue.barcode.height),
                        x: unitTransformPx($scope.pageValue.barcode.x),
                        y: unitTransformPx($scope.pageValue.barcode.y)
                    },
                    qrcode: {
                        width: unitTransformPx($scope.pageValue.qrcode.width),
                        height: unitTransformPx($scope.pageValue.qrcode.height),
                        x: unitTransformPx($scope.pageValue.qrcode.x),
                        y: unitTransformPx($scope.pageValue.qrcode.y)
                    },
                    imagebox: {
                        width: unitTransformPx($scope.pageValue.imagebox.width),
                        height: unitTransformPx($scope.pageValue.imagebox.height),
                        x: unitTransformPx($scope.pageValue.imagebox.x),
                        y: unitTransformPx($scope.pageValue.imagebox.y)
                    },
                    imageart: {
                        width: unitTransformPx($scope.pageValue.imageart.width),
                        height: unitTransformPx($scope.pageValue.imageart.height),
                        x: unitTransformPx($scope.pageValue.imageart.x),
                        y: unitTransformPx($scope.pageValue.imageart.y)
                    },
                    textbox: {
                        width: unitTransformPx($scope.pageValue.textbox.width),
                        height: unitTransformPx($scope.pageValue.textbox.height),
                        x: unitTransformPx($scope.pageValue.textbox.x),
                        y: unitTransformPx($scope.pageValue.textbox.y)
                    },
                    textdocument: {
                        width: unitTransformPx($scope.pageValue.textdocument.width),
                        height: unitTransformPx($scope.pageValue.textdocument.height),
                        x: unitTransformPx($scope.pageValue.textdocument.x),
                        y: unitTransformPx($scope.pageValue.textdocument.y)
                    },
                }
            }
            if (vm.unit == 'px') {
                $scope.pageValue = {
                    mediabox: {
                        width: $scope.currentPage.mediabox.width,
                        height: $scope.currentPage.mediabox.height
                    },
                    editbox: {
                        width: $scope.currentPage.editbox.width,
                        height: $scope.currentPage.editbox.height,
                        x: $scope.currentPage.editbox.x,
                        y: $scope.currentPage.editbox.y
                    },
                    barcode: {
                        width: unitTransform($scope.pageValue.barcode.width),
                        height: unitTransform($scope.pageValue.barcode.height),
                        x: unitTransform($scope.pageValue.barcode.x),
                        y: unitTransform($scope.pageValue.barcode.y)
                    },
                    qrcode: {
                        width: unitTransform($scope.pageValue.qrcode.width),
                        height: unitTransform($scope.pageValue.qrcode.height),
                        x: unitTransform($scope.pageValue.qrcode.x),
                        y: unitTransform($scope.pageValue.qrcode.y)
                    },
                    imagebox: {
                        width: unitTransform($scope.pageValue.imagebox.width),
                        height: unitTransform($scope.pageValue.imagebox.height),
                        x: unitTransform($scope.pageValue.imagebox.x),
                        y: unitTransform($scope.pageValue.imagebox.y)
                    },
                    imageart: {
                        width: unitTransform($scope.pageValue.imageart.width),
                        height: unitTransform($scope.pageValue.imageart.height),
                        x: unitTransform($scope.pageValue.imageart.x),
                        y: unitTransform($scope.pageValue.imageart.y)
                    },
                    textbox: {
                        width: unitTransform($scope.pageValue.textbox.width),
                        height: unitTransform($scope.pageValue.textbox.height),
                        x: unitTransform($scope.pageValue.textbox.x),
                        y: unitTransform($scope.pageValue.textbox.y)
                    },
                    textdocument: {
                        width: unitTransform($scope.pageValue.textdocument.width),
                        height: unitTransform($scope.pageValue.textdocument.height),
                        x: unitTransform($scope.pageValue.textdocument.x),
                        y: unitTransform($scope.pageValue.textdocument.y)
                    }
                }
            }
            // console.log($scope.pageValue)
        }

        function resetBackground() {
            var background = $scope.currentPage.background;
            if (background.resource && background.resource.identifier) {
                var pageW = $scope.currentPage.mediabox.width / $scope.currentPage.proportion;
                var pageH = $scope.currentPage.mediabox.height / $scope.currentPage.proportion;

                var bgW = background.resource.width;
                var bgH = background.resource.height;
                // 高适配
                var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                // 宽适配
                if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                    w = pageW;
                    h = pageW * bgH / bgW;
                    adaptation = 'Width';
                }
                var pro = $scope.currentPage.scale / $scope.currentPage.proportion
                background.offsetx = 0;
                background.offsety = 0;
                background.widthM = unitTransformPx(w / pro);
                background.heightM = unitTransformPx(h / pro);
                background.width = w / pro;
                background.height = h / pro;
                background.resource.width = bgW;
                background.resource.height = bgH;
                background.adaptation = adaptation;
                background.scale = 1;
            }
        }

        //模板宽高onchange
        $scope.updateEditbox = function () {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if (vm.unit == 'mm') {
                currentPage.editbox = {
                    width: unitTransform($scope.pageValue.editbox.width),
                    height: unitTransform($scope.pageValue.editbox.height),
                    x: unitTransform($scope.pageValue.editbox.x),
                    y: unitTransform($scope.pageValue.editbox.y)
                }
            }
            if (vm.unit == 'px') {
                currentPage.editbox = {
                    width: $scope.pageValue.editbox.width,
                    height: $scope.pageValue.editbox.height,
                    x: $scope.pageValue.editbox.x,
                    y: $scope.pageValue.editbox.y
                }
            }
            $scope.currentPage = currentPage

        };

        function waterFall(main) {
            var lis = $('#' + main).children('li');
            var liw = 100;
            var counts = 2;
            var arr = [],arr2=[],arr3 = [];
            lis.each(function (index, value) {//index索引值和当前的值
                if (index < counts) {
                    arr.push(lis.eq(index).innerHeight())
                    arr2.push(lis.eq(index).innerHeight());
                } else {
                    var minH = Math.min.apply(null, arr);
                    var minIndex = $.inArray(minH, arr);
                    arr2.push(minH + 30);
                    lis.eq(index).css({
                        'position': 'absolute',
                        'top': (minH + 30) + 'px',
                        'left': minIndex == 0 ? (minIndex * liw + 5) + 'px' : (minIndex * liw + 15) + 'px'
                    });
                    arr[minIndex] += lis.eq(index).innerHeight() + 10;
                    // console.log(arr)
                }
            });

            for(var i = 0;i<arr2.length;i++){
                var a1 = arr2[i];
                var a2 = arr2[i+2];
                arr3.push(a2-a1);
            }
            // console.log(arr3)

            if(arr3.length<4){
                return
            }
            var t = 1;
            for(var i = 1; i < arr3.length; i++) {
                if(arr3[2]==arr3[i]){
                    t++;
                }
            }
            if(t+4==arr3.length){
                setTimeout(function () {
                    waterFall(main);
                    $scope.$digest();
                }, 600);
            }
            // console.log(arr3.length)
            // console.log(t)

        }

        $scope.deleteLeftPanelPic = function (index, e) {
            fn(e);
            $(".art-click").hide();
            if ($scope.selPanel == 'page') {
                $scope.template.bgimgs2.bgimg.splice(index, 1);
                setTimeout(function () {
                    $scope.$digest();
                    waterFall('main0');
                }, 100);
            } else if ($scope.selPanel == 'design') {
                $scope.template.bgimgs.bgimg.splice(index, 1);
                setTimeout(function () {
                    $scope.$digest();
                    waterFall('main3');
                }, 100);

            } else if ($scope.selPanel == 'mask') {
                $scope.template.masks.mask.splice(index, 1);
                setTimeout(function () {
                    $scope.$digest();
                    waterFall('main2');
                }, 100);
            } else {
                $scope.template.cliparts.clipart.splice(index, 1);
            }
        };

        window.doubleClickbgImg = function (e,item) {
            fn(e);
            var currentPage = $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.background && currentPage.background.resource && currentPage.background.resource.identifier) {
                if(item){
                    $scope.cutImage1(currentPage, $scope.template.pageI);
                }else {
                    $scope.cutImage(currentPage, $scope.template.pageI);
                }
            }
            return
        }

        /**
         * 进入图片编辑模式
         */
        $scope.cutImage = function (page, pageI) {
            $scope.w = $('.bg-image' + pageI).width();
            $scope.h = $('.bg-image' + pageI).height();
            var pro = $scope.currentPage.scale / $scope.currentPage.proportion;

            $scope.pageW = $scope.currentPage.mediabox.width / $scope.currentPage.proportion * $scope.currentPage.scale;
            $scope.pageH = $scope.currentPage.mediabox.height / $scope.currentPage.proportion * $scope.currentPage.scale;
            $scope.showCut = true;
            $scope.showImageMenu = false;
            $("#cut-mask").attr('src', '');

            var background = $scope.currentPage.background;
            background.width = $scope.w / pro;
            background.height = $scope.h / pro;

            // 显示区域（包含上/下页按钮）
            var bodyW = $(".body-content").width();
            var bodyH = $(".body-content").height();

            // 裁切框显示位置
            var left = $scope.l = parseFloat($(".page-item").css('marginLeft'));
            var top = $scope.t = parseFloat($(".page-item").css('marginTop'));
            $("#cut-img").css({left: left + background.offsetx * pro, top: top + background.offsety * pro});

            ImageService.convertImgToBase64('content/images/white.png', function (s) {
                var imagecanvas = $("#mask-canvas")[0];
                var imagecontext = imagecanvas.getContext('2d');
                var newImg = document.createElement('img');
                // newImg.crossOrigin = "Anonymous";
                newImg.src = 'content/images/zhezhao1.png';
                newImg.onload = function () {
                    newImg.onload = null;
                    var masks = document.createElement('img');
                    masks.src = 'content/images/white.png';
                    // masks.crossOrigin = "Anonymous";
                    masks.onload = function () {
                        masks.onload = null;
                        imagecanvas.width = bodyW;
                        imagecanvas.height = bodyH;
                        imagecontext.drawImage(masks, left, top, $scope.pageW, $scope.pageH);
                        imagecontext.globalCompositeOperation = 'source-out';
                        imagecontext.drawImage(newImg, 0, 0, bodyW, bodyH);

                        $("#cut-mask").attr('src', ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                        $scope.$digest();
                    };
                    // masks.src = box.maskUrl;
                };
                newImg.src = 'content/images/zhezhao1.png';
            });
        };
        $scope.cutImage1 = function (page, pageI) {
            $scope.w = $('.bg-image1' + pageI).width();
            $scope.h = $('.bg-image1' + pageI).height();
            var pro = $scope.currentPage.scene.ratio;

            $scope.pageW = $scope.currentPage.mediabox.width*$scope.currentPage.scene.ratio;
            $scope.pageH = $scope.currentPage.mediabox.height*$scope.currentPage.scene.ratio;
            $scope.showCut = true;
            $scope.showImageMenu = false;
            $("#cut-mask").attr('src', '');

            var background = $scope.currentPage.background;
            background.width = $scope.w / pro;
            background.height = $scope.h / pro;

            // 显示区域（包含上/下页按钮）
            var bodyW = $(".body-content").width();
            var bodyH = $(".body-content").height();

            // 裁切框显示位置
            var left = $scope.l = parseFloat($(".page-item1").css('marginLeft')) + $scope.currentPage.scene.imagebox.geometry.x * pro;
            var top = $scope.t = parseFloat($(".page-item1").css('marginTop')) + $scope.currentPage.scene.imagebox.geometry.y * pro;
            $("#cut-img").css({left: left + background.offsetx * pro, top: top + background.offsety * pro});

            ImageService.convertImgToBase64('content/images/white.png', function (s) {
                var imagecanvas = $("#mask-canvas")[0];
                var imagecontext = imagecanvas.getContext('2d');
                var newImg = document.createElement('img');
                // newImg.crossOrigin = "Anonymous";
                newImg.src = 'content/images/zhezhao1.png';
                newImg.onload = function () {
                    newImg.onload = null;
                    var masks = document.createElement('img');
                    masks.src = 'content/images/white.png';
                    // masks.crossOrigin = "Anonymous";
                    masks.onload = function () {
                        masks.onload = null;
                        imagecanvas.width = bodyW;
                        imagecanvas.height = bodyH;
                        imagecontext.drawImage(masks, left, top, $scope.pageW, $scope.pageH);
                        imagecontext.globalCompositeOperation = 'source-out';
                        imagecontext.drawImage(newImg, 0, 0, bodyW, bodyH);

                        $("#cut-mask").attr('src', ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                        $scope.$digest();
                    };
                    // masks.src = box.maskUrl;
                };
                newImg.src = 'content/images/zhezhao1.png';
            });
        };

        var drag = false;
        var dragBox = {};
        /**
         * 拖拽编辑的图片
         */
        window.startEdit = function (e) {
            fn(e);

            dragBox.clientX = e.clientX ? e.clientX : 0;
            dragBox.clientY = e.clientY ? e.clientY : 0;
            dragBox.picW = $("#cut-img").width();
            dragBox.picH = $("#cut-img").height();
            var background = $scope.currentPage.background;
            var pro = $scope.currentPage.scale / $scope.currentPage.proportion
            dragBox.coordX = background.offsetx * pro;
            dragBox.coordY = background.offsety * pro;

            drag = true;
            document.addEventListener("mousemove", dragEdit, true);
            document.addEventListener("mouseup", stopEdit, true);
        };

        function dragEdit(e) {

            if (drag) {
                var background = $scope.currentPage.background;
                var x = 0, y = 0;

                //手指离开屏幕
                if (background.adaptation == 'Height') {    //宽图
                    x = (dragBox.coordX + (e.clientX - dragBox.clientX));
                    //向左滑动;        <-----
                    if (-x >= ($scope.w - $scope.pageW)) {
                        x = -($scope.w - $scope.pageW);
                    }
                    // 向右滑动;    ---->
                    if (x >= 0) {
                        x = 0;
                    }
                } else {     //高图
                    y = (dragBox.coordY + (e.clientY - dragBox.clientY));
                    //向上滑动;
                    if (-y >= ($scope.h - $scope.pageH)) {
                        y = -($scope.h - $scope.pageH);
                    }
                    //向下滑动;
                    if (y >= 0) {
                        y = 0;
                    }
                }

                $("#cut-img").css({left: x + $scope.l, top: y + $scope.t});

                var prp = $scope.currentPage.scale / $scope.currentPage.proportion
                background.offsetx = x / prp;
                background.offsety = y / prp;

                $scope.$digest();
                e.stopPropagation();
                e.preventDefault();
                return false;

            }

        };

        function stopEdit() {
            drag = false;
            dragBox = {};
            document.removeEventListener("mousemove", dragEdit, true);
            document.removeEventListener("mouseup", stopEdit, true);
        };

        $scope.chooseColor = function () {
            $scope.chooseBgColor(vm.bgColor);
        }

        $scope.chooseBgColor = function (color) {
            var currentPage = $scope.template.pages.page[$scope.template.pageI]
            currentPage.background = {
                resource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                offsetx: 0,
                offsety: 0,
                type: 'Color',
                color: color,
                width: 0,
                height: 0,
                adaptation: null
            }
            $scope.currentPage = currentPage;
            updateHistorys();
        }


        window.contextMenuLeftArt = function (e) {
            e.preventDefault();
            //显示自定义菜单
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            var clipart = $scope.template.cliparts.clipart[index];
            if (clipart.type == 'SYSTEM') {
                return
            }
            var top = $(".leftPanel-head").height() + 50 + 20 + parseInt(index / 2) * 100 + 10;
            var left = 54 + 15 + (index % 2) * 100 + 10;
            for (var i = 0; i < $scope.template.cliparts.clipart.length; i++) {
                if (i == index) {
                    $('.clipart' + i).find('.art-click').show();
                } else {
                    $('.clipart' + i).find('.art-click').hide();
                }
            }
            $scope.artI = index;
        };

        function setOriginalWaH(width, height, i) {
            var currentPage = $scope.template.pages.page[i];
            var w = width;
            var h = height;

            originalScale = $con.width() * 0.8 / ($con.height() * 0.8);

            if (originalScale < width / height) {
                //宽定，高不定
                width = $con.width() * 0.8;
                height = $con.width() * 0.8 * h / w;
            } else {
                //高定，宽不动
                height = $con.height() * 0.8;
                width = $con.height() * 0.8 * w / h;
            }
            currentPage.width = width;
            currentPage.height = height;
            currentPage.proportion = (w / width);
            currentPage.proportionText = width / (unitTransformPx(w) / 25.4 * vm.windowDpiW);

            var pageNodeWidth = currentPage.mediabox.width / currentPage.proportion * 98 / (currentPage.mediabox.height / currentPage.proportion)

            currentPage.proportionTextSmalll = pageNodeWidth / (unitTransformPx(w) / 25.4 * vm.windowDpiW);
            $scope.currentPage = currentPage;
        }

        // 跨页
        $scope.pageIsSpread = function (flag) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if (flag) {
                currentPage.spread = true;
                if (currentPage.type == 'Cover') {
                    currentPage.type = 'FrontBack';
                }
                if (currentPage.type == 'BackCover') {
                    currentPage.type = undefined;
                }
            } else {
                currentPage.spread = false;
                if (currentPage.type == 'FrontBack') {
                    currentPage.type = 'Cover';
                }

            }
            updateHistorys();
        };

        // 设置封面封底
        $scope.pageIsCover = function (attr) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if (currentPage.type && currentPage.type == attr) {
                currentPage.type = null;
            } else {
                angular.forEach($scope.template.pages.page, function (p) {
                    if (attr == 'Cover') {
                        if (p.type == 'Cover' || p.type == 'FrontBack') {
                            p.type = null;
                        }
                    }
                    if (attr == 'BackCover') {
                        if (p.type == 'BackCover' || p.type == 'FrontBack') {
                            p.type = null;
                        }
                    }
                    if (attr == 'FrontBack') {
                        p.type = null;
                    }
                })
                if (attr == 'Cover') {
                    currentPage.type = attr;
                    var page = $scope.template.pages.page.splice(i, 1);
                    $scope.template.pages.page.unshift(page[0]);
                    $scope.template.pageI = 0;
                } else if (attr == 'BackCover') {
                    currentPage.type = attr;
                    var page = $scope.template.pages.page.splice(i, 1);
                    $scope.template.pages.page.push(page[0]);
                    $scope.template.pageI = $scope.template.pages.page.length - 1;
                } else if (attr == 'FrontBack') {
                    currentPage.type = attr;
                    var page = $scope.template.pages.page.splice(i, 1);
                    $scope.template.pages.page.unshift(page[0]);
                    $scope.template.pageI = 0;
                    $scope.pageIsSpread(1)
                }
            }

            $scope.template.pages.page.forEach(function (page, index) {
                page.seq = index + 1
            });
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];

            updateHistorys();
        }

        // 可变数据
        $scope.setVariable = function (flag) {
            var i = $scope.template.pageI;
            var level = $scope.template.pages.page[i].levels.level[$scope.template.levelI]
            if (flag) {
                level.variable = true;
            } else {
                level.variable = false;
            }
        };

        $scope.pageIsBackup = function () {
            // var i = $scope.template.pageI;
            // var currentPage = $scope.template.pages.page[i];
            // if (flag) {
            //     currentPage.backup = true;
            // } else {
            //     currentPage.backup = false;
            // }
            // updateHistorys();

            var i = $scope.template.pageI;
            $scope.lastPage = $scope.template.pages.page[i];
            $scope.backupPage = angular.copy($scope.template.pages.page[i]);
            $scope.backupPage.backup = true;
            $scope.backupPage.uuid = UidService.get();
            $scope.template.pages.page.push($scope.backupPage);

            setTimeout(function () {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.backupPage,'cover');
                updateHistorys();
            })
        };

        function pageAnimate(){
            $(".page-item-cover").show()
            setTimeout(function () {
                $(".page-item-cover").addClass('cover-move2')
                $(".content-page-cover").addClass('cover-scale')
            })

            setTimeout(function () {
                $(".page-item-cover").hide().removeClass('cover-move2')
                $(".content-page-cover").removeClass('cover-scale')
            },1200)
        }

        $scope.deleteBackup = function(index){
            $scope.template.pages.page.splice(index,1);
        }

        // 备用页替换
        $scope.replaceBackupPage = function (e, page) {
            fn(e);
            var i = $scope.template.pageI;
            var seq = $scope.currentPage.seq;
            if (!page.loaded) {
                loadPageToPx(page, i, function(list) {
                    var p = angular.copy(page);
                    p.seq = seq
                    p.backup = false;
                    $scope.currentPage = $scope.template.pages.page[i] = p;
                    setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, i);
                    updateHistorys();
                    pageSeq($scope.template.pages.page, UidService, function (page) {
                        $scope.template.pages.page = page;
                    });
                    $timeout(function() {
                        $scope.$digest();
                    }, 100);
                });
            } else {
                var p = angular.copy(page);
                p.seq = seq
                p.backup = false;
                $scope.currentPage = $scope.template.pages.page[i] = p;
                setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, i);
                updateHistorys();
                pageSeq($scope.template.pages.page, UidService, function (page) {
                    $scope.template.pages.page = page;
                });
                $timeout(function() {
                    $scope.$digest();
                }, 100);
            }
        };

        $scope.pageIsReplace = function (flag) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if (flag) {
                currentPage.unreplace = false;
            } else {
                currentPage.unreplace = true;
            }
            updateHistorys();
        };

        // 删除场景
        $scope.deleteScene = function () {
            $scope.template.scene = null;
            var len = $scope.template.pages.page.length - 1;
            $scope.currentPage = $scope.template.pages.page[len];
        }

        $scope.selectSceneImg = function (e) {
            $scope.RightPanel = 'mask';
            $scope.template.angle = $scope.currentPage.imagebox.rotation.angle;

            $("#menus").fadeOut();
            // setZindex($index);
            $("#maskSelect").val("");
            e.stopPropagation();
        };

        // 删除场景内占位框
        $scope.deleteSceneimagebox = function () {
            $scope.currentPage.imagebox = {};
        }

        // 选择场景
        $scope.selectScene = function () {
            var _page = $scope.template.pages.page[$scope.template.pageI];
            $scope.currentPage = _page;

        }

        function setMargin() {
            var page0 = angular.copy($scope.template.pages.page[0]);
            if ($scope.template.scene&&$scope.template.scene.imagebox) {
                var w0 = page0.mediabox.width / page0.proportion * page0.scale,
                    h0 = page0.mediabox.height / page0.proportion * page0.scale,
                    w1 = $scope.currentPage.scene.imagebox.geometry.width * $scope.currentPage.scene.scale / $scope.currentPage.scene.proportion,
                    h1 = $scope.currentPage.scene.imagebox.geometry.height * $scope.currentPage.scene.scale / $scope.currentPage.scene.proportion;

                if ((w0 <= h0 || w1 <= h1) && (w0 / h0 < w1 / h1) || w0 >= h0 && w1 >= h1 && (w0 / h0 < w1 / h1)) {
                    $scope.imageboxConH = h1;
                    $scope.imageboxConW = h1 * w0 / h0;
                    $scope.proportion = $scope.imageboxConW / w0;
                } else {
                    $scope.imageboxConW = w1;
                    $scope.imageboxConH = w1 * h0 / w0;
                    $scope.proportion = $scope.imageboxConH / h0;
                }

                $scope.marginLeft = (w1 - $scope.imageboxConW) / 2;
                $scope.marginTop = (h1 - $scope.imageboxConH) / 2;
            }
        }


        /* ==========================================================================
         左边侧边栏拖动事件
         ========================================================================== */
        var content = $(".content-container")[0];
        content.ondragenter = ignoreDrag;
        // 拖动文件的鼠标指针位置放置区之上时发生
        content.ondragover = ignoreDrag;
        content.ondrop = drop;

        var content2 = $(".editing1")[0];
        content2.ondragenter = ignoreDrag;
        // 拖动文件的鼠标指针位置放置区之上时发生
        content2.ondragover = ignoreDrag;
        content2.ondrop = drop;

        function ignoreDrag(e) {
            // 确保其他元素不会取得该事件
            e.stopPropagation();
            e.preventDefault();
        }

        function drop(e) {
            ignoreDrag(e);
            var currentPage = $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            var data = e.dataTransfer.getData("Text");
            var s = $('#' + data)[0].src;
            var mask = $('#' + data)[0].getAttribute('data-mask');
            var dataIndex = $('#' + data)[0].getAttribute('data-index');
            var s1 = s.split(/\//)[3];


            var offsetX = e.clientX - 300;
            var offsetY = e.clientY - 100;
            // 场景
            if (currentPage.scene) {
                offsetX -= parseFloat($($(".page-item")[1]).css('margin-left'));
                offsetY -= parseFloat($($(".page-item")[1]).css('margin-top'))
            } else {
                offsetX -= parseFloat($($(".page-item")[0]).css('margin-left'));
                offsetY -= parseFloat($($(".page-item")[0]).css('margin-top'))
            }
            if (data.indexOf('dragtargetPhoto') != '-1') {
                // console.log(e.target.dataset)
                var index = null;
                if (e.target.dataset.index) {
                    index = e.target.dataset.index;
                } else {
                    // 判读是否是照片
                    var mask = {
                        identifier: null,
                        provider: 'qiniu',
                        resource: {
                            identifier: '',
                            provider: 'qiniu'
                        }
                    }
                    addPlacement(e, mask, false, offsetX, offsetY);
                    index = $scope.template.levelI;
                }
                var imagebox = currentPage.levels.level[index].imagebox;
                s1 = $('#' + data).attr('data-mask');
                if (imagebox.current) {
                    var img = imagebox.imgUrl = s1 + '?imageMogr2/auto-orient/thumbnail/!40p';
                    var newImg = document.createElement('img');
                    newImg.crossOrigin = "Anonymous";
                    newImg.src = img;
                    newImg.onload = function () {
                        newImg.onload = null;
                        var _image = getImageSource(newImg, imagebox, s1, currentPage);
                        imagebox.image = _image;
                        imagebox.image.offsetx = 0;
                        imagebox.image.offsety = 0;
                        imageboxResourceCanvas(imagebox);
                        $scope.selectImg(e, index, imagebox);
                        $scope.$digest();
                    };
                }
            } else if (data.indexOf('dragtargetMask') != '-1') {
                // 判读是否是占位框
                s1 = $('#' + data).attr('data-mask');
                var mask = {
                    identifier: s1,
                    provider: 'qiniu',
                    resource: {
                        identifier: s1,
                        provider: 'qiniu'
                    }
                }
                addPlacement(e, mask, false, offsetX, offsetY);
                var index = $scope.template.levelI;
                var imagebox = currentPage.levels.level[index].imagebox;
                if (imagebox.current) {
                    imagebox.mask.resource.identifier = s1;
                    imagebox.mask.resource.provider = 'qiniu';
                    maskScale(e, imagebox, index, s1);
                    $scope.$digest();
                }
            } else if (data.indexOf('dragtargetClipArt') != '-1') {
                //判读是否是素材
                var identifier = $('#' + data).attr('data-art');
                $scope.addArt(identifier);

            } else {
                // var bgimg2Arr = vm.showBackgrounds.bgimg[dataIndex];
                var bgimg2Arr = $scope.template.bgimgs2.bgimg[dataIndex];

                var pageW = currentPage.mediabox.width / currentPage.proportion;
                var pageH = currentPage.mediabox.height / currentPage.proportion;
                currentPage.hasBgImg = true;

                $http({url: bgimg2Arr.identifier + '?imageInfo'}).success(function (data) {
                    var bgW = data.width;
                    var bgH = data.height;
                    if (data.orientation == "Left-bottom" || data.orientation == "Right-top" || data.orientation == "Left-top" || data.orientation == "Right-bottom") {
                        bgW = data.height;
                        bgH = data.width;
                    }

                    // 高适配
                    var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                    // 宽适配
                    if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                        w = pageW;
                        h = pageW * bgH / bgW;
                        adaptation = 'Width';
                    }
                    var pro = $scope.currentPage.scale / $scope.currentPage.proportion
                    $scope.currentPage.background = {
                        resource: {
                            identifier: bgimg2Arr.identifier,
                            provider: 'qiniu',
                            width: bgW,
                            height: bgH
                        },
                        offsetx: 0,
                        offsety: 0,
                        type: 'Pic',
                        color: '',
                        width: w / pro,
                        height: h / pro,
                        adaptation: adaptation,
                        scale: 1
                    }
                    // console.log($scope.currentPage.background)
                    updateHistorys();
                }).error(function (error) {
                    MessageService.error("获取图片信息失败");
                });
            }
        }

        function getBackgroundSource(res, pageW, pageH, arr) {
            var deferred = $q.defer();
            $http({url: res.identifier + '?imageInfo'}).success(function (data) {
                var bgW = data.width,
                    bgH = data.height;

                // 高适配
                var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                // 宽适配
                if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                    w = pageW;
                    h = pageW * bgH / bgW;
                    adaptation = 'Width';
                }
                arr.resource.width = bgW;
                arr.resource.height = bgH;
                arr.width = w;
                arr.height = h;
                arr.type = arr.type;
                arr.offsetXM = angular.copy(arr.offsetx);
                arr.offsetYM = angular.copy(arr.offsety);
                arr.offsetx = arr.offsetx;
                arr.offsety = arr.offsety;
                arr.adaptation = adaptation;
                deferred.resolve("ready");
            }).error(function (error) {
                MessageService.error("获取图片信息失败");
                deferred.resolve("error");
            });
            return deferred.promise;
        }
        function imageboxResourceCanvas(item) {
            var image = angular.copy(item)
            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = image.imgUrl;
            newImg.onload = function () {
                newImg.onload = null;
                var offsetx = image.image.offsetx;
                var offsety = image.image.offsety;
                var _image = getImageSource(newImg,image,image.image.resource.identifier)
                image.image = _image;
                var scaleW = _image.width / newImg.width;
                var scaleH = _image.height / newImg.height;
                image.image.scaleW = scaleW;
                image.image.scaleH = scaleH;
                image.image.offsetx = offsetx / scaleW;
                image.image.offsety = offsety / scaleH;
                // img.setAttribute('data-mask', s1);
                var masks = document.createElement('img');
                var maskUrl =  'content/images/mask_souce.png';
                if(image.mask&&image.mask.resource&&image.mask.resource.identifier){
                    maskUrl = image.mask.resource.identifier
                }
                image.maskUrl = maskUrl;
                masks.src = maskUrl;
                masks.crossOrigin = "anonymous";
                masks.onload = function () {
                    masks.onload = null;
                    masks.width = image.geometry.width;
                    masks.height = image.geometry.height;
                    imagecanvas.width = masks.width;
                    imagecanvas.height = masks.height;
                    imagecontext.drawImage(masks, 0, 0, masks.width, masks.height);
                    imagecontext.globalCompositeOperation = 'source-in';

                    imagecontext.scale(scaleW, scaleH);
                    imagecontext.drawImage(newImg, image.image.offsetx, image.image.offsety);
                    var pic = imagecanvas.toDataURL();
                    image.bgImg = ImageService.dataURLtoBlob(pic);
                    item.bgImg = ImageService.dataURLtoBlob(pic);
                    updateHistorys();
                    $scope.$digest();
                };
                masks.src = maskUrl;
            };
            newImg.src = image.imgUrl;
        }

        function maskScale(e, imageboxs, index, identifier, callback) {
            var imagebox = angular.copy(imageboxs);
            imagebox.mask.resource.identifier = identifier;

            var img = document.getElementsByClassName('page-item')[0].getElementsByClassName('mask')[index];
            var imgUrl =  'content/images/mask_souce.png';
            if(imagebox.image&&imagebox.image.resource&&imagebox.image.resource.identifier){
                imgUrl = imagebox.image.resource.identifier
            }

            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = imgUrl;
            newImg.onload = function () {
                newImg.onload = null;
                var width = newImg.width;
                var height = newImg.height;
                var scaleW = 1,scaleH = 1;
                if(imagebox.image&&imagebox.image.resource&&imagebox.image.resource.identifier){
                    var _image = getImageSource(newImg,imagebox,identifier);
                    width = imagebox.geometry.width;
                    height = imagebox.geometry.height;

                    scaleW = _image.width / newImg.width;
                    scaleH = _image.height / newImg.height;
                    imagebox.image.offsetx = 0;
                    imagebox.image.offsety = 0;
                }
                if(img){
                    img.setAttribute('data-mask', identifier);
                }

                var masks = document.createElement('img');
                masks.src = identifier;
                masks.crossOrigin = "anonymous";
                masks.onload = function () {
                    masks.onload = null;
                    imagecanvas.width = width;
                    imagecanvas.height = height;
                    imagecontext.drawImage(masks, 0, 0, width, height);
                    imagecontext.globalCompositeOperation = 'source-in';
                    imagecontext.scale(scaleW, scaleH);
                    imagecontext.drawImage(newImg, 0, 0);
                    var pic = imagecanvas.toDataURL();
                    imagebox.bgImg = ImageService.dataURLtoBlob(pic);
                    imageboxs.bgImg = ImageService.dataURLtoBlob(pic);
                    imagebox.mask.resource.identifier = identifier;
                    imagebox.mask.resource.provider = "qiniu";
                    imagebox.maskUrl = identifier;
                    $scope.selectImg(e, index, imagebox);
                    updateHistorys();
                    $scope.$digest();
                };
                if (callback) {
                    callback();
                }
                masks.src = identifier;
            };

        }
        function  getImageSource(newImg,imagebox,s1,currentPage) {
            var width = newImg.width;
            var height = newImg.height;

            var _w = imagebox.geometry.width;
            var _h = imagebox.geometry.height;
            var bgImgR = width/height;
            var bgImgR1 = _w/_h;
            var _image = {
                resource: {
                    provider: 'qiniu',
                    identifier: s1,
                },
                angle: 0,
                scale: 1
            }
            if(bgImgR >= bgImgR1){
                _image.adaptation = 'Height';
                _image.height = _h;
                _image.width = _h/height*width;
            }else {
                _image.adaptation = 'Width';
                _image.width = _w;
                _image.height = _w/width*height;
            }
            _image.imgWidth = width;
            _image.imgHeight = height;

            return _image
        }
        function getBackgroundSource1(res, pageW, pageH, arr) {
            var deferred = $q.defer();
            var img = new Image();
            img.src = res.identifier + '?imageMogr2/auto-orient';
            img.onload =function(){
                var bgW = img.width,
                    bgH = img.height;

                // 高适配
                var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                // 宽适配
                if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                    w = pageW;
                    h = pageW * bgH / bgW;
                    adaptation = 'Width';
                }
                arr.resource.width = bgW;
                arr.resource.height = bgH;
                arr.width = w;
                arr.height = h;
                arr.type = 'Pic';
                arr.adaptation = adaptation;
                $scope.$digest();
                deferred.resolve("ready");
            }

            return deferred.promise;
            // $http({url: res.identifier + '?imageInfo/imageMogr2/auto-orient'}).success(function (data) {
            //     // var bgW = data.width,
            //     //     bgH = data.height;
            //     var bgW = data.height,
            //         bgH = data.width;
            //
            //     // 高适配
            //     var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
            //     // 宽适配
            //     if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
            //         w = pageW;
            //         h = pageW * bgH / bgW;
            //         adaptation = 'Width';
            //     }
            //     arr.resource.width = bgW;
            //     arr.resource.height = bgH;
            //     arr.width = w;
            //     arr.height = h;
            //     arr.type = 'Pic';
            //     arr.adaptation = adaptation;
            // }).error(function (error) {
            //     MessageService.error("获取图片信息失败");
            // });
        }

        window.dragStart = function (event) {
            var target = event.target;
            event.dataTransfer.setData("Text", target.id);
            if (target.id.indexOf('dragtargetClipArt') != '-1') {
                event.dataTransfer.setData("Art", target.dataset.clipart);
            }
        };

        $scope.addArt = function (identifier) {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var currentLevel = addLevel(currentPage);

            vm.unit = 'mm';
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = identifier + '?imageView2';
            newImg.onload = function () {
                newImg.onload = null;
                var width = newImg.width;
                var height = newImg.height;
                var arr = ThreeHundredDpiToMm(width, height);

                var bgImgR = arr.mm1 / arr.mm2;
                if (bgImgR >= 1) {
                    if (arr.mm1 > currentPage.editboxM.width) {
                        $scope.pageValue.imageart = {
                            width: currentPage.editboxM.width,
                            height: currentPage.editboxM.width / arr.mm1 * arr.mm2,
                            x: currentPage.editboxM.x,
                            y: currentPage.editboxM.y
                        }
                    } else {
                        $scope.pageValue.imageart = {
                            height: arr.mm2,
                            width: arr.mm1,
                            x: currentPage.editboxM.x,
                            y: currentPage.editboxM.y
                        }
                    }
                } else {
                    if (arr.mm2 > currentPage.editboxM.height) {
                        $scope.pageValue.imageart = {
                            height: currentPage.editboxM.height,
                            width: currentPage.editboxM.height / arr.mm2 * arr.mm1,
                            x: currentPage.editboxM.x,
                            y: currentPage.editboxM.y
                        }
                    } else {
                        $scope.pageValue.imageart = {
                            height: arr.mm2,
                            width: arr.mm1,
                            x: currentPage.editboxM.x,
                            y: currentPage.editboxM.y
                        }
                    }
                }

                currentLevel.imageart = ({
                    geometry: {
                        width: unitTransform($scope.pageValue.imageart.width),
                        height: unitTransform($scope.pageValue.imageart.height),
                        x: unitTransform($scope.pageValue.imageart.x),
                        y: unitTransform($scope.pageValue.imageart.y)
                    },
                    rotation: {
                        angle: 0
                    },
                    resource: {
                        identifier: identifier,
                        provider: 'qiniu',
                        width: width,
                        height: height,
                    },
                    scale: 1,
                    transparency: 1,
                    onlyshow: false,
                    movable: true,
                    editable: true,
                    bgImg: '',
                    offsetx: 0,
                    offsety: 0,
                    unit: 'mm',
                    // index: currentPage.imagearts.imageart.length,
                    levelI: $scope.template.levelI,
                    pageI: $scope.template.pageI,
                    current: 'imageart',
                    lock: false
                });

                updateHistorys();
                $scope.currentPage = currentPage;
                $scope.RightPanel = 'material';
                $scope.selectBox = currentLevel.imageart;
                $scope.template.transparency = 100,
                    $scope.$digest();
            };
        }

        /* ==========================================================================
         占位框处理
         ========================================================================== */

        function addLevel(currentPage) {
            var arr = {
                seq: 1,
                imagebox: null,
                textbox: null,
                imageart: null,
                textdocument: null,
                variable:false
            };

            if (!currentPage.levels.level) {
                currentPage.levels.level = [arr];
            } else {
                currentPage.levels.level.push(arr);
            }

            $scope.template.levelI = currentPage.levels.level.length - 1;
            var currentLevel = currentPage.levels.level[$scope.template.levelI];

            currentLevel.seq = $scope.template.levelI + 1;
            vm.unit = 'mm';
            $scope.template.angle = 0;
            return currentLevel;
        }

        //增加图片占位框
        var addPlacement = function (e, mask, attr, offsetX, offsetY) {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var currentLevel = addLevel(currentPage);

            offsetX = offsetX / currentPage.scale * currentPage.proportion;
            offsetY = offsetY / currentPage.scale * currentPage.proportion;


            currentLevel.imagebox = ({
                geometry: {
                    width: unitTransform(50),
                    height: unitTransform(45),
                    x: unitTransform(unitTransformPx(offsetX) - 25),
                    y: unitTransform(unitTransformPx(offsetY) - 25)
                },
                rotation: {
                    angle: 0
                },
                mask: {
                    resource: {
                        identifier: mask.identifier,
                        provider: 'qiniu'
                    }
                },
                imgUrl: 'content/images/mask_souce.png',
                maskUrl: mask.identifier || 'content/images/mask_souce.png',
                bgImg: '',
                unit: 'mm',
                levelI: $scope.template.levelI,
                pageI: $scope.template.pageI,
                current: 'imagebox',
                lock: false
            });

            updateHistorys();

            $scope.currentPage = currentPage;
            $scope.selectBox = currentLevel.imagebox;
            getImageboxLen();
            if (vm.unit == 'mm') {
                $scope.pageValue.imagebox = {
                    width: 50,
                    height: 45,
                    x: unitTransformPx(offsetX) - 25,
                    y: unitTransformPx(offsetY) - 23
                }
            } else {
                $scope.pageValue.imagebox = currentPage.imagebox.geometry;
            }
            return
        };

        function getImageboxLen() {
            vm.count = 0;
            angular.forEach($scope.currentPage.levels.level, function (level) {
                if (level.imagebox && level.imagebox.geometry) {
                    vm.count++;
                }
            })
        }

        //图片占位框点击时
        $scope.selectImg = function (e, $index, img) {
            if (!e) {
                return
            }

            if (vm.unit == 'mm') {
                $scope.pageValue.imagebox = {
                    width: unitTransformPx(img.geometry.width).toFixed(2) - 0,
                    height: unitTransformPx(img.geometry.height).toFixed(2) - 0,
                    x: unitTransformPx(img.geometry.x).toFixed(2) - 0,
                    y: unitTransformPx(img.geometry.y).toFixed(2) - 0
                }
            } else {
                $scope.pageValue.imagebox = {
                    width: img.geometry.width,
                    height: img.geometry.height,
                    x: img.geometry.x,
                    y: img.geometry.y
                }
            }

            $scope.template.levelI = $index;
            $scope.RightPanel = 'mask';
            $scope.template.angle = img.rotation.angle;
            img.current = 'imagebox';
            $scope.selectBox = img;
            vm.selectItem = angular.copy(img);

            //如果是按住ctrl键 实现多选复制
            // var mm = $scope.currentPage.imageboxes.imagebox[$index];
            if (e.ctrlKey == true || e.metaKey == true) {
                if (img.lock) {
                    return
                }
                img.ctrlKey = true;
                vm.CtrlKeyArray.imageboxes[$index] = angular.copy(img);
                ctrlAddSingleArray();
            } else {
                $scope.clearCtrlKey();
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
                if (img.lock) {
                    return
                }
                vm.singleArray.imagebox = angular.copy(img);
            }
            $("#menus").fadeOut();
            // setZindex($index);
            $("#maskSelect").val("");
            e.stopPropagation();
        };
        //双击占位框
        $scope.doubleClickImagebox = function (e, index, item) {
            if(!item.image && !item.image.resource.identifier){
                return
            }
            vm.imageboxDouble1 = index;
            vm.imageboxDouble = angular.copy(item);
            vm.isImageboxTrim = true;
            $scope.scale = vm.imageboxDouble.image.scale;
        }
        $scope.imageboxFalseClick = function () {
            vm.isImageboxTrim = false;
        };
        $scope.imageboxTrueClick = function () {
            $scope.currentPage.levels.level[vm.imageboxDouble1].imagebox = vm.imageboxDouble;
            vm.isImageboxTrim = false;
            $scope.currentPage.levels.level[vm.imageboxDouble1].imagebox = vm.imageboxDouble;
            if ($scope.currentPage.levels.level[vm.imageboxDouble1].imagebox.mask && $scope.currentPage.levels.level[vm.imageboxDouble1].imagebox.mask.resource.identifier) {
                imageboxResourceCanvas($scope.currentPage.levels.level[vm.imageboxDouble1].imagebox);
            }
        };
        var isImageboxMouseDown = false;
        $scope.imageboxMouseDown = function ($event) {
            fn($event)
            var ratio = $scope.currentPage.scale / $scope.currentPage.proportion;
            var x = $event.clientX;
            var y = $event.clientY;
            var l = vm.imageboxDouble.image.offsetx * ratio * vm.imageboxDouble.image.scale;
            var t = vm.imageboxDouble.image.offsety * ratio * vm.imageboxDouble.image.scale;
            var r = -(vm.imageboxDouble.image.width * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.width) * ratio;
            var b = -(vm.imageboxDouble.image.height * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.height) * ratio;
            var angle = Number(vm.imageboxDouble.rotation.angle);
            //开关打开
            isImageboxMouseDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageboxMouseDown == false) {
                    return;
                }
                fn(e);
                var cx = (l + r) / 2;
                var cy = (t + b) / 2;
                var pt0 = rotatePoint({x: x, y: y}, {x: cx, y: cy}, angle);
                var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, angle);
                var deltaX = pt1.x - pt0.x;
                var deltaY = pt1.y - pt0.y;
                var nl = l + deltaX;
                var nt = t + deltaY;
                if (nl < r) {
                    nl = r
                };
                if (nl > 0) {
                    nl = 0
                };
                if (nt < b) {
                    nt = b
                };
                if (nt > 0) {
                    nt = 0
                };

                vm.imageboxDouble.image.offsetx = nl / ratio / vm.imageboxDouble.image.scale;
                vm.imageboxDouble.image.offsety = nt / ratio / vm.imageboxDouble.image.scale;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageboxMouseDown = false;
                $scope.$digest();
            }
        }
        $scope.imageboxMouseDown1 = function ($event) {
            fn($event)
            var ratio = $scope.currentPage.scale / $scope.currentPage.proportion;
            var x = $event.clientX;
            var y = $event.clientY;
            var l = vm.imageboxDouble.image.offsetx * ratio * vm.imageboxDouble.image.scale;
            var t = vm.imageboxDouble.image.offsety * ratio * vm.imageboxDouble.image.scale;
            var r = -(vm.imageboxDouble.image.width * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.width) * ratio;
            var b = -(vm.imageboxDouble.image.height * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.height) * ratio;
            var angle = Number(vm.imageboxDouble.rotation.angle);
            //开关打开
            isImageboxMouseDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageboxMouseDown == false) {
                    return;
                }
                fn(e);
                var cx = (l + r) / 2;
                var cy = (t + b) / 2;
                var pt0 = rotatePoint({x: x, y: y}, {x: cx, y: cy}, angle);
                var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, angle);
                var deltaX = pt1.x - pt0.x;
                var deltaY = pt1.y - pt0.y;
                var nl = l + deltaX;
                var nt = t + deltaY;
                if (nl < r) {
                    nl = r
                };
                if (nl > 0) {
                    nl = 0
                };
                if (nt < b) {
                    nt = b
                };
                if (nt > 0) {
                    nt = 0
                };

                vm.imageboxDouble.image.offsetx = nl / ratio / vm.imageboxDouble.image.scale;
                vm.imageboxDouble.image.offsety = nt / ratio / vm.imageboxDouble.image.scale;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageboxMouseDown = false;
                $scope.$digest();
            }
        }

        function ctrlAddSingleArray() {
            if (vm.singleArray.imagebox.index != undefined) {
                var index = vm.singleArray.imagebox.index;
                $scope.currentPage.imageboxes.imagebox[index].ctrlKey = true;
                vm.CtrlKeyArray.imageboxes[index] = vm.singleArray.imagebox;
            }
            if (vm.singleArray.textbox.index != undefined) {
                var index = vm.singleArray.textbox.index;
                $scope.currentPage.textboxes.textbox[index].ctrlKey = true;
                vm.CtrlKeyArray.textboxes[index] = vm.singleArray.textbox;
            }
            if (vm.singleArray.imageart.index != undefined) {
                var index = vm.singleArray.imageart.index;
                $scope.currentPage.imagearts.imageart[index].ctrlKey = true;
                vm.CtrlKeyArray.imagearts[index] = vm.singleArray.imageart;
            }
            if (vm.singleArray.barcode.index != undefined) {
                vm.singleArray.barcode.ctrlKey = true;
                vm.CtrlKeyArray.barcode[0] = vm.singleArray.barcode;
            }
            if (vm.singleArray.qrcode.index != undefined) {
                vm.singleArray.qrcode.ctrlKey = true;
                vm.CtrlKeyArray.qrcode[0] = vm.singleArray.qrcode;
            }
            vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
        }

        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        var dragMask = {};
        $scope.buttonShows = false;
        $scope.moveBoxStart = function (e,item,index) {
            fn(e);
            $scope.buttonShows = true;
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = index;
            $scope.template.angle = item.rotation.angle;
            if (item.lock) {
                return
            }

            dragBox.clientX = e.clientX || 0;
            dragBox.clientY = e.clientY || 0;
            if(item.current=='page'){
                return
            }else if(item.current=='imagebox'){
                dragBox.coordX = item.geometry.x*$scope.currentPage.scale/$scope.currentPage.proportion;
                dragBox.coordY = item.geometry.y*$scope.currentPage.scale/$scope.currentPage.proportion;
            }else if(item.current=='textdocument'){
                dragBox.coordX = e.currentTarget.offsetLeft;
                dragBox.coordY = e.currentTarget.offsetTop;
            }else{
                dragBox.coordX = item.geometry.x * $scope.currentPage.scale / $scope.currentPage.proportion;
                dragBox.coordY = item.geometry.y * $scope.currentPage.scale / $scope.currentPage.proportion;
            }
            drag = true;
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragBox.ctrlKey = true;
            } else {
                dragBox.ctrlKey = false;
            }
            $scope.selectBox = item;
            document.addEventListener("mousemove", moveBoxOn, true);
            document.addEventListener("mouseup", moveBoxEnd, true);

        };
        //move 事件
        function moveBoxOn(e) {
            fn(e);
            $scope.$digest();
            if (e.target.nodeName == 'SPAN'&&$scope.selectBox.current!='textbox') {
                return;
            }
            if (drag) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var angle = ($scope.selectBox.rotation.angle) * Math.PI / 180;

                var clientX = e.clientX - dragBox.clientX;
                var clientY = e.clientY - dragBox.clientY;

                var imgOffsetX = (dragBox.coordX + e.clientX - dragBox.clientX);
                var imgOffsetY = (dragBox.coordY + e.clientY - dragBox.clientY);
                var imgWidth = $scope.selectBox.geometry.width * currentPage.scale / currentPage.proportion;
                var imgHeight = $scope.selectBox.geometry.height * currentPage.scale / currentPage.proportion;

                if($scope.selectBox.current=='textbox'){
                    imgWidth = $(".textbox" + $scope.template.pageI + $scope.template.levelI).width();
                    imgHeight = $(".textbox" + $scope.template.pageI + $scope.template.levelI).height();
                }
                var height = currentPage.height * currentPage.scale;
                var width = currentPage.width * currentPage.scale;
                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                // if (imgOffsetX <= 0) {
                //     imgOffsetX = 0;
                // }
                //
                // if (imgOffsetX >= (width + lar - imgWidth)) {
                //     imgOffsetX = width + lar - imgWidth;
                // }
                // if (imgOffsetY <= 0) {
                //     imgOffsetY = 0;
                // }
                //
                // if (imgOffsetY >= (height + tab - imgHeight)) {
                //     imgOffsetY = height + tab - imgHeight;
                // }

                if (dragBox.ctrlKey) {
                    moveTogether(currentPage, clientX, clientY);
                } else {
                    $scope.selectBox.geometry.x = imgOffsetX / currentPage.scale * currentPage.proportion;
                    $scope.selectBox.geometry.y = imgOffsetY / currentPage.scale * currentPage.proportion;

                    var value = {};
                    if ($scope.selectBox.current == 'textbox') {
                        value = $scope.pageValue.textbox;
                    } else if ($scope.selectBox.current == 'imagebox') {
                        value = $scope.pageValue.imagebox;
                    } else if ($scope.selectBox.current == 'imageart') {
                        value = $scope.pageValue.imageart;
                    } else if ($scope.selectBox.current == 'textdocument') {
                        value = $scope.pageValue.textdocument;
                    }
                    if (vm.unit == 'mm') {
                        value.x = unitTransformPx($scope.selectBox.geometry.x).toFixed(2) - 0;//px-->mm
                        value.y = unitTransformPx($scope.selectBox.geometry.y).toFixed(2) - 0;//px-->mm
                    } else {
                        value.x = unitTransformPx($scope.selectBox.geometry.x / 25.4 * vm.windowDpiW);
                        value.y = unitTransformPx($scope.selectBox.geometry.y / 25.4 * vm.windowDpiW);
                    }
                }
                $scope.currentPage = currentPage;

            }
            return false;
        }

        //鼠标松开事件
        function moveBoxEnd() {
            if (dragBox.ctrlKey) {
                moveTogetherEnd();
            }
            drag = false;
            dragBox = {};
            document.removeEventListener("mousemove", moveBoxOn, true);
            document.removeEventListener("mouseup", moveBoxEnd, true);
            updateHistorys();
            $scope.$digest();
        }

        $scope.moveBoxStart1 = function (e,item,index) {
            fn(e);
            $scope.buttonShows = true;
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = index;
            $scope.template.angle = item.rotation.angle;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (item.lock) {
                return
            }

            dragBox.clientX = e.clientX || 0;
            dragBox.clientY = e.clientY || 0;
            if(item.current=='page'){
                return
            }else if(item.current=='imagebox'){
                dragBox.coordX = item.geometry.x*currentPage.scene.ratio;
                dragBox.coordY = item.geometry.y*currentPage.scene.ratio;
            }else if(item.current=='textdocument'){
                dragBox.coordX = item.geometry.x*currentPage.scene.ratio;
                dragBox.coordY = item.geometry.y*currentPage.scene.ratio;
            }else{
                dragBox.coordX = item.geometry.x*currentPage.scene.ratio;
                dragBox.coordY = item.geometry.y*currentPage.scene.ratio;
            }
            drag = true;
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragBox.ctrlKey = true;
            } else {
                dragBox.ctrlKey = false;
            }
            $scope.selectBox = item;
            document.addEventListener("mousemove", moveBoxOn1, true);
            document.addEventListener("mouseup", moveBoxEnd1, true);

        };
        //move 事件
        function moveBoxOn1(e) {
            fn(e);
            $scope.$digest();
            if (e.target.nodeName == 'SPAN'&&$scope.selectBox.current!='textbox') {
                return;
            }
            if (drag) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var angle = ($scope.selectBox.rotation.angle) * Math.PI / 180;

                var clientX = e.clientX - dragBox.clientX;
                var clientY = e.clientY - dragBox.clientY;

                var imgOffsetX = (dragBox.coordX + e.clientX - dragBox.clientX);
                var imgOffsetY = (dragBox.coordY + e.clientY - dragBox.clientY);
                var imgWidth = $scope.selectBox.geometry.width * currentPage.scene.ratio;
                var imgHeight = $scope.selectBox.geometry.height * currentPage.scene.ratio;

                if($scope.selectBox.current=='textbox'){
                    imgWidth = $(".textbox1" + $scope.template.pageI + $scope.template.levelI).width();
                    imgHeight = $(".textbox1" + $scope.template.pageI + $scope.template.levelI).height();
                }
                var height = currentPage.mediabox.height * currentPage.scene.ratio;
                var width = currentPage.mediabox.width * currentPage.scene.ratio;
                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scene.ratio;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scene.ratio;

                // if (angle == 0) {
                //     if (imgOffsetX <= 0) {
                //         imgOffsetX = 0;
                //     }
                //
                //     if (imgOffsetX >= (width - imgWidth)) {
                //         imgOffsetX = width - imgWidth;
                //     }
                //     if (imgOffsetY <= 0) {
                //         imgOffsetY = 0;
                //     }
                //
                //     if (imgOffsetY >= (height - imgHeight)) {
                //         imgOffsetY = height - imgHeight;
                //     }
                // } else {
                //     if (imgOffsetX <= 0) {
                //         imgOffsetX = 0;
                //     }
                //
                //     if (imgOffsetX >= (width + lar)) {
                //         imgOffsetX = width + lar;
                //     }
                //     if (imgOffsetY <= 0) {
                //         imgOffsetY = 0;
                //     }
                //
                //     if (imgOffsetY >= (height + tab)) {
                //         imgOffsetY = height + tab;
                //     }
                //
                // }

                if (dragBox.ctrlKey) {
                    moveTogether(currentPage, clientX, clientY);
                } else {
                    $scope.selectBox.geometry.x = imgOffsetX / currentPage.scene.ratio;
                    $scope.selectBox.geometry.y = imgOffsetY / currentPage.scene.ratio;

                    var value = {};
                    if ($scope.selectBox.current == 'textbox') {
                        value = $scope.pageValue.textbox;
                    } else if ($scope.selectBox.current == 'imagebox') {
                        value = $scope.pageValue.imagebox;
                    } else if ($scope.selectBox.current == 'imageart') {
                        value = $scope.pageValue.imageart;
                    } else if ($scope.selectBox.current == 'textdocument') {
                        value = $scope.pageValue.textdocument;
                    }
                    if (vm.unit == 'mm') {
                        value.x = unitTransformPx($scope.selectBox.geometry.x).toFixed(2) - 0;//px-->mm
                        value.y = unitTransformPx($scope.selectBox.geometry.y).toFixed(2) - 0;//px-->mm
                    } else {
                        value.x = unitTransformPx($scope.selectBox.geometry.x / 25.4 * vm.windowDpiW);
                        value.y = unitTransformPx($scope.selectBox.geometry.y / 25.4 * vm.windowDpiW);
                    }
                }
                $scope.currentPage = currentPage;

            }
            return false;
        }

        //鼠标松开事件
        function moveBoxEnd1() {
            if (dragBox.ctrlKey) {
                moveTogetherEnd();
            }
            drag = false;
            dragBox = {};
            document.removeEventListener("mousemove", moveBoxOn1, true);
            document.removeEventListener("mouseup", moveBoxEnd1, true);
            updateHistorys();
            $scope.$digest();
        }
        $scope.rotateBoxStart = function (e,item,index) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = index;
            var currentLevel = $scope.currentPage.levels.level[$scope.template.levelI];
            if (item.lock) {
                return
            }
            drag = true;
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragBox.ctrlKey = true;
            } else {
                dragBox.ctrlKey = false;
            }

            dragBox.clientX = e.clientX || 0;
            dragBox.clientY = e.clientY || 0;



            if(item.current=='imagebox'){
                dragBox._top = $(e.target).parents('.imgBox').offset().top + (item.geometry.height * $scope.currentPage.scale/$scope.currentPage.proportion / 2);
                dragBox._left = $(e.target).parents('.imgBox').offset().left + (item.geometry.width * $scope.currentPage.scale/$scope.currentPage.proportion / 2);
                if ($scope.RightPanel == 'mask') {
                    $scope.RightPanel = 'mask';
                } else {
                    $scope.RightPanel = 'placement';
                }
            }else if(item.current=='imageart'){
                dragBox._top = $(e.target).parents('.img-art').offset().top + (item.geometry.height * $scope.currentPage.scale/$scope.currentPage.proportion / 2);
                dragBox._left = $(e.target).parents('.img-art').offset().left + (item.geometry.width * $scope.currentPage.scale/$scope.currentPage.proportion / 2);
                $scope.RightPanel = 'material';
            }else if(item.current=='textbox'){
                dragBox._top = $(e.target).parents('.c-xml-page-textboxe').offset().top + (item.geometry.height * $scope.currentPage.scale/$scope.currentPage.proportion / 2);
                dragBox._left = $(e.target).parents('.c-xml-page-textboxe').offset().left + (item.geometry.width * $scope.currentPage.scale/$scope.currentPage.proportion / 2);
                $scope.RightPanel = 'text';
            }else if(item.current=='textdocument'){
                dragBox._top = $(e.target).parents('#textdocument').offset().top + (item.geometry.height * $scope.currentPage.scale/$scope.currentPage.proportion / 2);
                dragBox._left = $(e.target).parents('#textdocument').offset().left + (item.geometry.width * $scope.currentPage.scale/$scope.currentPage.proportion / 2);
                $scope.RightPanel = 'textDoc';
            }

            document.addEventListener("mousemove", rotateBoxOn, true);
            document.addEventListener("mouseup", rotateBoxEnd, true);
        }

        function rotateBoxOn(e) {
            fn(e);
            if (drag) {
                var angle = getAngle(dragBox._left,dragBox._top, e.clientX, e.clientY);
                if($scope.selectBox.current == 'imagebox'||$scope.selectBox.current == 'textdocument'){
                    $scope.selectBox.rotation.angle = 360 - angle;
                }else{
                    $scope.selectBox.rotation.angle = angle;
                }
                $scope.template.angle = $scope.selectBox.rotation.angle;
            }
            $scope.$digest();
            // e.stopPropagation();
            // e.preventDefault();
            return false;
        }

        function rotateBoxEnd() {
            drag = false;
            dragBox = {};
            $(".rotate-icon" + $scope.template.levelI).removeClass('rotate-icon-active');
            document.removeEventListener("mousemove", rotateBoxOn, true);
            document.removeEventListener("mouseup", rotateBoxEnd, true);
            updateHistorys();
            $scope.$digest();
        }
        function getAngle(px,py,mx,my){//获得人物中心和鼠标坐标连线，与y轴正半轴之间的夹角
            var x = Math.abs(px-mx);
            var y = Math.abs(py-my);
            var z = Math.sqrt(Math.pow(x,2)+Math.pow(y,2));
            var cos = y/z;
            var radina = Math.acos(cos);//用反三角函数求弧度
            var angle = Math.floor(180/(Math.PI/radina));//将弧度转换成角度
            if(mx<px&&my>py){//鼠标在第三象限
                angle = angle;
            }
            if(mx<px&&my<py){
                angle = 180 - angle;
            }
            if(mx>px&&my<py){
                angle = 180 + angle;
            }
            if(mx>px&&my>py){
                angle = 360 - angle;
            }
            // console.log(angle)
            return angle;
        }

        function moveTogether(currentPage, clientX, clientY) {
            angular.forEach(vm.CtrlKeyArray.imageboxes, function (imagebox, i) {
                // 当前页所有占位框
                var box = currentPage.levels.level[imagebox.levelI].imagebox;
                boxSetting(box, imagebox);
            })

            angular.forEach(vm.CtrlKeyArray.imagearts, function (imageart, i) {
                // 当前页所有素材
                var box = currentPage.levels.level[imageart.levelI].imageart;
                boxSetting(box, imageart);
            })

            angular.forEach(vm.CtrlKeyArray.textboxes, function (textbox, i) {
                // 当前页所有文字
                var box = currentPage.levels.level[textbox.levelI].textbox;
                boxSetting(box, textbox);
            })

            angular.forEach(vm.CtrlKeyArray.textdocuments, function (textdocument, i) {
                // 当前页所有文字
                var box = currentPage.levels.level[textdocument.levelI].textdocument;
                boxSetting(box, textdocument);
            })

            var imgWidth = 0, imgHeight = 0, height = 0, width = 0, x = 0, y = 0;

            function boxSetting(box, CtrlKeyBox) {
                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;
                imgWidth = box.geometry.width * currentPage.scale / currentPage.proportion;
                imgHeight = box.geometry.height * currentPage.scale / currentPage.proportion;
                height = currentPage.height * currentPage.scale;
                width = currentPage.width * currentPage.scale;
                x = CtrlKeyBox.geometry.x / currentPage.proportion * currentPage.scale + clientX;
                y = CtrlKeyBox.geometry.y / currentPage.proportion * currentPage.scale + clientY;
                // if (x <= 0) {
                //     x = 0;
                // }
                // if (x >= (width + lar - imgWidth)) {
                //     x = width + lar - imgWidth;
                // }
                // if (y <= 0) {
                //     y = 0;
                // }
                //
                // if (y >= (height + tab - imgHeight)) {
                //     y = height + tab - imgHeight;
                // }
                box.geometry.x = x / currentPage.scale * currentPage.proportion
                box.geometry.y = y / currentPage.scale * currentPage.proportion
                box.offsetXM = unitTransformPx(box.geometry.x).toFixed(2) - 0;//px-->mm
                box.offsetYM = unitTransformPx(box.geometry.y).toFixed(2) - 0;//px-->mm
            }
        }


        function moveTogetherEnd() {
            angular.forEach(vm.CtrlKeyArray.imageboxes, function (imagebox, i) {
                // 当前页所有占位框
                var box = $scope.currentPage.levels.level[imagebox.levelI].imagebox;
                ctrlKeySetting(box, imagebox);
            })

            angular.forEach(vm.CtrlKeyArray.imagearts, function (imageart, i) {
                // 当前页所有素材
                var box = $scope.currentPage.levels.level[imageart.levelI].imageart;
                ctrlKeySetting(box, imageart);
            })

            angular.forEach(vm.CtrlKeyArray.textboxes, function (textbox, i) {
                // 当前页所有文字
                var box = $scope.currentPage.levels.level[textbox.levelI].textbox;
                ctrlKeySetting(box, textbox);
            })

            angular.forEach(vm.CtrlKeyArray.textdocuments, function (textdocument, i) {
                // 当前页所有文字
                var box = $scope.currentPage.levels.level[textdocument.levelI].textdocument;
                ctrlKeySetting(box, textdocument);
            })

            function ctrlKeySetting(box, CtrlKeyBox) {
                CtrlKeyBox.geometry.x = box.geometry.x;
                CtrlKeyBox.geometry.y = box.geometry.y;
                CtrlKeyBox.offsetXM = box.offsetXM;
                CtrlKeyBox.offsetYM = box.offsetYM;
            }
        }

        /**
         *放大占位框
         * @param e
         */
        var dragFlag = false;
        var isZoomDown = false;
        $scope.resizeStartRigth = function($event, item,pro,index){
            if(item.lock){return}
            fn($event);
            imageboxZoom($event, item, 'right',pro,index)
        }
        $scope.resizeStartLeft = function ($event, item,pro,index) {
            if(item.lock){return}
            fn($event);
            imageboxZoom($event, item, 'left',pro,index)
        }
        $scope.resizeStartTop = function ($event, item,pro,index) {
            if(item.lock){return}
            fn($event);
            imageboxZoom($event, item, 'top',pro,index)
        }
        $scope.resizeStartBottom = function ($event, item,pro,index) {
            if(item.lock){return}
            fn($event);
            imageboxZoom($event, item, 'bottom',pro,index)
        }
        $scope.resizeStartLeft1 = function ($event, item,pro,index) {
            if(item.lock){return}
            fn($event);
            imageboxZoom1($event, item, 'left',pro,index)
        }
        $scope.resizeStartTop1 = function ($event, item,pro,index) {
            if(item.lock){return}
            fn($event);
            imageboxZoom1($event, item, 'top',pro,index)
        }
        $scope.resizeStartRight1 = function ($event, item,pro,index) {
            if(item.lock){return}
            fn($event);
            imageboxZoom1($event, item, 'right',pro,index)
        }
        $scope.resizeStartBottom1 = function ($event, item,pro,index) {
            if(item.lock){return}
            fn($event);
            imageboxZoom1($event, item, 'bottom',pro,index)
        }
        function imageboxZoom($event, item, direction, pro, index) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
            var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
            var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
            var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
            var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
            var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var _scale = item.image ? angular.copy(item.image.scale) : 1;
            if ($scope.currentPage.scene) {
                var _right = $($event.target).parents('.editing1').offset().left + ($scope.currentPage.mediabox.width * pro);
                var _bottom = $($event.target).parents('.editing1').offset().top + ($scope.currentPage.mediabox.height * pro);
            } else {
                var _right = $($event.target).parents('.page-item').offset().left + ($scope.currentPage.mediabox.width * pro);
                var _bottom = $($event.target).parents('.page-item').offset().top + ($scope.currentPage.mediabox.height * pro);
            }
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                if (nx > _right) {
                    nx = _right
                }
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;

                if (direction == 'bottom' || direction == 'left') {
                    var _w = (Number(nw) - Number(nl));
                }
                if (direction == 'top' || direction == 'right') {
                    var _w = (Number(nw) + Number(nl));
                }
                var _h = _w / nw * nh;
                if ($scope.currentPage.scene) {
                    if (_w > $scope.currentPage.mediabox.width * pro) {
                        _w = $scope.currentPage.mediabox.width * pro;
                        _h = _w / nw * nh;
                    }
                    if (_h > $scope.currentPage.mediabox.height * pro) {
                        _h = $scope.currentPage.mediabox.height * pro;
                        _w = _h / nh * nw;
                    }
                } else {
                    if (_w > ($scope.currentPage.mediabox.width / $scope.currentPage.proportion) * $scope.currentPage.scale) {
                        _w = ($scope.currentPage.mediabox.width / $scope.currentPage.proportion) * $scope.currentPage.scale;
                        _h = _w / nw * nh;
                    }
                    if (_h > ($scope.currentPage.mediabox.height / $scope.currentPage.proportion) * $scope.currentPage.scale) {
                        _h = ($scope.currentPage.mediabox.height / $scope.currentPage.proportion) * $scope.currentPage.scale;
                        _w = _h / nh * nw;
                    }
                }
                item.geometry.width = _w / pro;
                item.geometry.height = _h / pro;
                if (item.image) {
                    item.image.width = _w / nw * nw2 / pro;
                    item.image.height = _w / nw * nh2 / pro;
                    item.image.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / pro;
                    item.image.offsety = (_h / (nh / nh1)) / (nh1 / y1) / pro;
                }
                if (direction == 'left') {
                    item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                    item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                    if (item.geometry.x < 0) {
                        item.geometry.x = 0;
                    }
                    if (item.geometry.y < 0) {
                        item.geometry.y = 0;
                    }
                }
                if (direction == 'top') {
                    item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                    if (item.geometry.y < 0) {
                        item.geometry.y = 0;
                    }
                }
                if (direction == 'right') {
                    if ($scope.currentPage.scene) {
                        if (Number(item.geometry.height * pro) + Number(item.geometry.y * pro) > $scope.currentPage.mediabox.height * pro) {
                            item.geometry.height = ($scope.currentPage.mediabox.height * pro - Number(item.geometry.y * pro)) / pro;
                            item.geometry.width = item.geometry.height / nh * nw;
                            return;
                        }
                    } else {
                        if (Number(item.geometry.height * pro) + Number(item.geometry.y * pro) > ($scope.currentPage.height + $scope.currentPage.xayBorder / $scope.currentPage.proportion) * $scope.currentPage.scale) {
                            item.geometry.height = (($scope.currentPage.height + $scope.currentPage.xayBorder / $scope.currentPage.proportion) * $scope.currentPage.scale - Number(item.geometry.y * pro)) / pro;
                            item.geometry.width = item.geometry.height / nh * nw;
                            return;
                        }
                    }
                }
                if (direction == 'bottom') {
                    if ($scope.currentPage.scene) {
                        if (Number(item.geometry.height * pro) + Number(item.geometry.y * pro) > $scope.currentPage.mediabox.height * pro) {
                            item.geometry.height = ($scope.currentPage.mediabox.height * pro - Number(item.geometry.y * pro)) / pro;
                            item.geometry.width = item.geometry.height / nh * nw;
                            return;
                        }
                    } else {
                        if (Number(item.geometry.height * pro) + Number(item.geometry.y * pro) > ($scope.currentPage.height + $scope.currentPage.xayBorder / $scope.currentPage.proportion) * $scope.currentPage.scale) {
                            item.geometry.height = (($scope.currentPage.height + $scope.currentPage.xayBorder / $scope.currentPage.proportion) * $scope.currentPage.scale - Number(item.geometry.y * pro)) / pro;
                            item.geometry.width = item.geometry.height / nh * nw;
                            return;
                        }
                    }
                    item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                    if (item.geometry.x < 0) {
                        item.geometry.x = 0;
                    }
                }
                $scope.template.levelI = index || $scope.template.levelI;
                $scope.currentPage.levels.level[$scope.template.levelI].imagebox = item;
                if (vm.unit == 'mm') {
                    $scope.pageValue.imagebox = {
                        width: unitTransformPx(item.geometry.width).toFixed(2) - 0,
                        height: unitTransformPx(item.geometry.height).toFixed(2) - 0,
                        x: unitTransformPx(item.geometry.x).toFixed(2) - 0,
                        y: unitTransformPx(item.geometry.y).toFixed(2) - 0,
                    }
                } else {
                    $scope.pageValue.imagebox = {
                        width: unitTransformPx(item.geometry.width / 25.4 * vm.windowDpiW),
                        height: unitTransformPx(item.geometry.height / 25.4 * vm.windowDpiW),
                        x: unitTransformPx(item.geometry.x / 25.4 * vm.windowDpiW),
                        y: unitTransformPx(item.geometry.y / 25.4 * vm.windowDpiW),
                    }
                }
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                $event.stopPropagation();
                isZoomDown = false;
                if($scope.currentPage.levels.level&&$scope.currentPage.levels.level[$scope.template.levelI]){
                    var level = $scope.currentPage.levels.level[$scope.template.levelI];
                    if (level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier) {
                        if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                            imageboxResourceCanvas(level.imagebox);
                        } else {
                            maskScale(null, level.imagebox, $scope.template.levelI, level.imagebox.mask.resource.identifier)
                        }
                    }
                }

            }
        }

        function imageboxZoom1($event, item, direction, pro, index) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
            var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
            var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
            var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
            var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
            var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var _scale = item.image ? angular.copy(item.image.scale) : 1;

            if ($scope.currentPage.scene) {
                var _right = $($event.target).parents('.editing1').offset().left + ($scope.currentPage.mediabox.width * pro);
                var _bottom = $($event.target).parents('.editing1').offset().top + ($scope.currentPage.mediabox.height * pro);
            } else {
                var _right = $($event.target).parents('.page-item').offset().left + ($scope.currentPage.mediabox.width * pro);
                var _bottom = $($event.target).parents('.page-item').offset().top + ($scope.currentPage.mediabox.height * pro);
            }
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                if (nx > _right) {
                    nx = _right
                }
                if (ny > _bottom) {
                    ny = _bottom
                }
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;
                var nt = ny - y;

                if (direction == 'left') {
                    var _w = (Number(nw) - Number(nl));
                }
                if (direction == 'right') {
                    var _w = (Number(nw) + Number(nl));
                }
                if (direction == 'top') {
                    var _h = (Number(nh) - Number(nt));
                }
                if (direction == 'bottom') {
                    var _h = (Number(nh) + Number(nt));
                }
                if (_w > ($scope.currentPage.mediabox.width / $scope.currentPage.proportion) * $scope.currentPage.scale) {
                    _w = ($scope.currentPage.mediabox.width / $scope.currentPage.proportion) * $scope.currentPage.scale;
                }
                if (_h > ($scope.currentPage.mediabox.height / $scope.currentPage.proportion) * $scope.currentPage.scale) {
                    _h = ($scope.currentPage.mediabox.height / $scope.currentPage.proportion) * $scope.currentPage.scale;
                }


                if (item.image) {
                    item.image.offsetx = 0;
                    item.image.offsety = 0;
                }
                if (direction == 'left') {
                    if ((Number(x2) - Number(_w - nw)) / pro < 0) {
                        item.geometry.x = 0;
                    } else {
                        item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                        item.geometry.width = _w / pro;
                    }
                }
                if (direction == 'top') {
                    if ((Number(y2) - Number(_h - nh)) / pro < 0) {
                        item.geometry.y = 0;
                    } else {
                        item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                        item.geometry.height = _h / pro;
                    }
                }
                if (direction == 'right') {
                    item.geometry.width = _w / pro;
                }
                if (direction == 'bottom') {
                    item.geometry.height = _h / pro;
                }
                $scope.template.levelI = index || $scope.template.levelI;
                $scope.currentPage.levels.level[$scope.template.levelI].imagebox = item;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                $event.stopPropagation();
                if (isZoomDown) {
                    vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
                    vm.singleArray.imagebox = angular.copy(item);
                    dragMask = {};
                    updateHistorys();
                    var pressImg = item;
                    if (pressImg.image && pressImg.image.resource.identifier) {
                        pressImg.image.offsetx = 0;
                        pressImg.image.offsety = 0;
                        getBackgroundSource1(pressImg.image.resource, pressImg.geometry.width, pressImg.geometry.height, pressImg.image);
                    }

                    if (pressImg.mask && pressImg.mask.resource && pressImg.mask.resource.identifier) {
                        if (pressImg.image && pressImg.image.resource.identifier) {
                            imageboxResourceCanvas(pressImg);
                        } else {
                            maskScale($event, pressImg, $scope.template.levelI, pressImg.mask.resource.identifier)
                        }
                    }
                    isZoomDown = false;
                    $scope.$digest();
                }
            }
        }

        $scope.updateBoxParameter = function (p1, p2) {
            var box = {}, boxW = null, boxH = null, value = {};
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if ($scope.RightPanel == 'placement' || $scope.RightPanel == 'mask') {
                box = level.imagebox;
                value = $scope.pageValue.imagebox;
            } else if ($scope.RightPanel == 'material') {
                box = level.imageart;
                value = $scope.pageValue.imageart;
                boxW = angular.copy(box.resource.width);
                boxH = angular.copy(box.resource.height);
            } else if ($scope.RightPanel == 'text') {
                box = level.textbox;
                value = $scope.pageValue.textbox;
            } else if ($scope.RightPanel == 'textDoc') {
                box = level.textdocument;
                value = $scope.pageValue.textdocument;
            }
            if (box.lock) {
                return
            }

            if (vm.unit == 'mm') {
                box.geometry = {
                    width: unitTransform(value.width),
                    height: unitTransform(value.height),
                    x: unitTransform(value.x),
                    y: unitTransform(value.y)
                }
                if (p1 == 'width' && $scope.RightPanel == 'material') {
                    box.geometry.height = box.geometry.width * boxH / boxW;
                    value.height = unitTransformPx(box.geometry.height);
                    box.offsetx = 0;
                    box.offsety = 0;
                    box.scale = 1;
                } else if (p1 == 'height' && $scope.RightPanel == 'material') {
                    box.geometry.width = box.geometry.height * boxW / boxH;
                    value.width = unitTransformPx(box.geometry.width);
                    box.offsetx = 0;
                    box.offsety = 0;
                    box.scale = 1;
                }
            }
            if (vm.unit == 'px') {
                box.geometry = value;
                if (p1 == 'width' && $scope.RightPanel == 'material') {
                    box.geometry.height = box.geometry.width * boxH / boxW;
                    value.height = box.geometry.height;
                    box.offsetx = 0;
                    box.offsety = 0;
                    box.scale = 1;
                } else if (p1 == 'height' && $scope.RightPanel == 'material') {
                    box.geometry.width = box.geometry.height * boxW / boxH;
                    value.width = box.geometry.width;
                    box.offsetx = 0;
                    box.offsety = 0;
                    box.scale = 1;
                }
            }

            if ($scope.currentPage.scene) {
                $scope.selectScene();
            } else {
                updateHistorys();
            }
            // console.log($scope.currentPage.levels.level)
        };

        $scope.updatetextDocParameter = function (p1, p2) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];

            var box = level.textdocument;
            textdocW = angular.copy(box.geometry.width);
            textdocH = angular.copy(box.geometry.height);

            if (box.lock) {
                return
            }

            if (vm.unit == 'mm') {
                box.geometry = {
                    width: unitTransform($scope.pageValue.textdocument.width),
                    height: unitTransform($scope.pageValue.textdocument.height),
                    x: unitTransform($scope.pageValue.textdocument.x),
                    y: unitTransform($scope.pageValue.textdocument.y)
                }
                if (p1 == 'width') {
                    box.geometry.height = box.geometry.width * textdocH / textdocW;
                    $scope.pageValue.textdocument.height = unitTransformPx(box.geometry.height);
                } else {
                    box.geometry.width = box.geometry.height * textdocW / textdocH;
                    $scope.pageValue.textdocument.width = unitTransformPx(box.geometry.width);
                }
            }
            if (vm.unit == 'px') {
                box.geometry = {
                    width: $scope.pageValue.textdocument.width,
                    height: $scope.pageValue.textdocument.height,
                    x: $scope.pageValue.textdocument.x,
                    y: $scope.pageValue.textdocument.y
                }
                if (p1 == 'width') {
                    box.geometry.height = box.geometry.width * textdocH / textdocW;
                    $scope.pageValue.textdocument.height = box.geometry.height;
                } else {
                    box.geometry.width = box.geometry.height * textdocW / textdocH;
                    $scope.pageValue.textdocument.width = box.geometry.width;
                }
            }

            textdocument = angular.copy(box);
            resizeLevelWaH();

            if ($scope.currentPage.scene) {
                $scope.selectScene();
            } else {
                updateHistorys();
            }

        };

        //图片占位框旋转角度
        $scope.updateRotate = function () {
            var box = {};
            if (!$scope.currentPage.scene) {
                if ($scope.RightPanel == 'placement' || $scope.RightPanel == 'mask') {
                    box = $scope.currentPage.levels.level[$scope.template.levelI].imagebox.rotation;
                } else if ($scope.RightPanel == 'material') {
                    box = $scope.currentPage.levels.level[$scope.template.levelI].imageart.rotation;
                } else if ($scope.RightPanel == 'text') {
                    box = $scope.currentPage.levels.level[$scope.template.levelI].textbox.rotation;
                } else if ($scope.RightPanel == 'barcode') {
                    box = $scope.currentPage.barcode.rotation;
                } else if ($scope.RightPanel == 'qrcode') {
                    box = $scope.currentPage.qrcode.rotation;
                }

                if (box.lock) {
                    return
                }

                box.angle = $scope.template.angle;
                updateHistorys();
            } else {
                $scope.currentPage.imagebox.rotation.angle = $scope.template.angle;
            }
        };

        $scope.delPic = function(){
            // debugger
            // if (!$scope.currentPage.isScene) {
                var box = null;
                if ($scope.RightPanel == 'placement' || $scope.RightPanel == 'mask') {
                    box = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;
                    if(box.image&&box.image.resource&&box.image.resource.identifier){
                        box.image = null;
                    }
                }
            // }
        }

        /* ==========================================================================
         素材处理
         ========================================================================== */
        // 素材点击时
        $scope.selectArt = function (e, $index, art) {
            fn(e);
            if (vm.unit == 'mm') {
                $scope.pageValue.imageart = {
                    width: unitTransformPx(art.geometry.width).toFixed(2) - 0,
                    height: unitTransformPx(art.geometry.height).toFixed(2) - 0,
                    x: unitTransformPx(art.geometry.x).toFixed(2) - 0,
                    y: unitTransformPx(art.geometry.y).toFixed(2) - 0
                }
            } else {
                $scope.pageValue.imageart = {
                    width: art.geometry.width,
                    height: art.geometry.height,
                    x: art.geometry.x,
                    y: art.geometry.y
                }
            }
            $scope.template.angle = art.rotation.angle;
            $scope.template.transparency = art.transparency * 100;
            $(elem).val(art.transparency);

            art.current = 'imageart';
            $scope.selectBox = art;

            $("#menus").fadeOut();
            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                if (art.lock) {
                    return
                }
                art.ctrlKey = true;
                vm.CtrlKeyArray.imagearts[$index] = angular.copy(art);
                ctrlAddSingleArray();
            } else {
                $scope.clearCtrlKey();
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
                if (art.lock) {
                    return
                }
                vm.singleArray.imageart = angular.copy(art);
            }
            $scope.template.levelI = $index;
            $scope.RightPanel = 'material';
            e.stopPropagation();
        };

        window.doubleClickClipart = function (e, item) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            var imageart = $scope.currentPage.levels.level[$scope.template.levelI].imageart;
            $scope.template.angle = imageart.rotation.angle;

            if (imageart.lock) {
                return
            }
            vm.pageMarginTop = 0;
            if($scope.currentPage.scene){
                vm.pageMarginTop = parseFloat($(".page-item1").css('margin-top'));
                vm.imageartTrimRatio = $scope.currentPage.scene.ratio;
            }else{
                vm.pageMarginTop = parseFloat($(".page-item").css('margin-top'));
                vm.imageartTrimRatio = $scope.currentPage.scale/$scope.currentPage.proportion;
            }

            // console.log(vm.pageMarginTop)
            vm.isImageartTrim = true;
            vm.imageartTrim = angular.copy(imageart);
            vm.imageartTrim.geometry.width1 = angular.copy(imageart.geometry.width);
            vm.imageartTrim.geometry.height1 = angular.copy(imageart.geometry.height);
            vm.imageartTrim.geometry.x1 = angular.copy(imageart.geometry.x);
            vm.imageartTrim.geometry.y1 = angular.copy(imageart.geometry.y);

            var pro = vm.imageartTrimRatio;
            if (vm.imageartTrim.geometry.width * pro == vm.imageartTrim.geometry.width1 * pro * vm.imageartTrim.scale) {
                vm.imageartTrim.geometry.width = vm.imageartTrim.geometry.width * 0.8;
                vm.imageartTrim.geometry.height = vm.imageartTrim.geometry.height * 0.8;
                vm.imageartTrim.geometry.x = Number(vm.imageartTrim.geometry.x) + Number(vm.imageartTrim.geometry.width / 0.8 * 0.2 / 2);
                vm.imageartTrim.geometry.y = Number(vm.imageartTrim.geometry.y) + Number(vm.imageartTrim.geometry.height / 0.8 * 0.2 / 2);
            }
            $scope.$digest();
        }

        //拖动素材裁切
        var isImageartTrim = false;
        $scope.imageartTrimdown = function ($event) {
            fn($event)
            var x = $event.clientX;
            var y = $event.clientY;
            var pro = vm.imageartTrimRatio;
            var l = vm.imageartTrim.geometry.x * pro;
            var t = vm.imageartTrim.geometry.y * pro;
            var r = (vm.imageartTrim.geometry.width1 * vm.imageartTrim.scale - vm.imageartTrim.geometry.width) * pro;
            var b = (($("#c-xml-imageart-trim-img").find('img').height()) / pro - vm.imageartTrim.geometry.height) * pro;

            var x1 = (Number(vm.imageartTrim.geometry.x1) + Number(vm.imageartTrim.offsetx)) * pro;
            var y1 = (Number(vm.imageartTrim.geometry.y1) + Number(vm.imageartTrim.offsety)) * pro;
            var x2 = vm.imageartTrim.geometry.x1 * pro;
            var y2 = vm.imageartTrim.geometry.y1 * pro;
            var x3 = vm.imageartTrim.offsetx * pro;
            var y3 = vm.imageartTrim.offsety * pro;
            //开关打开
            isImageartTrim = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageartTrim == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - (x - l);
                var nt = ny - (y - t);


                if (nl < x1) {
                    nl = x1
                }
                ;
                if (nl > Number(r) + Number(x1)) {
                    nl = Number(r) + Number(x1)
                }
                ;
                if (nt < y1) {
                    nt = y1
                }
                ;
                if (nt > Number(b) + Number(y1)) {
                    nt = Number(b) + Number(y1)
                }
                ;

                vm.imageartTrim.geometry.x = nl / pro;
                vm.imageartTrim.geometry.y = nt / pro;

                $("#c-xml-imageart-trim-frame").css({
                    left: nl + 'px',
                    top: nt + 'px',
                });
                $("#c-xml-imageart-trim-frame-img").css({
                    left: Number(x2 - nl) + Number(x3) + 'px',
                    top: Number(y2 - nt) + Number(y3) + 'px',
                });
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageartTrim = false;
                $event.stopPropagation();
            }
        }

        //素材裁切放大缩小
        var isImageartTrimRight = false;
        $scope.imageartTrimRight = function ($event, item) {
            fn($event)
            var x = $event.clientX;
            var y = $event.clientY;
            var pro = vm.imageartTrimRatio;
            //获取w和h
            // var nw = $($event.target).parent().width();
            // var nh = $($event.target).parent().height();
            var nw = Number(item.geometry.width * pro) + Number(item.geometry.x * pro);
            var nh = Number(item.geometry.height * pro) + Number(item.geometry.y * pro);
            var _l = item.geometry.x * pro;
            var _t = item.geometry.y * pro;


            // var r = Number($("#c-xml-imageart-trim-div").offset().left) + Number(vm.imageartTrim.geometry.width1 * pro * vm.imageartTrim.scale);
            // var b = Number($("#c-xml-imageart-trim-div").offset().top) + Number($("#c-xml-imageart-trim-img").find('img').height());

            var r = Number(item.geometry.x1 * pro) + Number(item.offsetx * pro) + Number(item.geometry.width1 * pro * item.scale);
            var b = Number(item.geometry.y1 * pro) + Number(item.offsety * pro) + Number($("#imageartTrimRight1").height());
            //开关打开
            isImageartTrimRight = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageartTrimRight == false) {
                    return;
                }

                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = Number(nw) + Number(nx - x);
                var nt = Number(nh) + Number(ny - y);

                if (nl >= r) {
                    nl = r
                }
                if (nt >= b) {
                    nt = b
                }

                item.geometry.width = (nl - _l) / pro;
                item.geometry.height = (nt - _t) / pro;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageartTrimRight = false;
                $event.stopPropagation();
            }
        }
        $scope.imageartTrimTrueClick = function () {
            vm.isImageartTrim = false;
            var pro = vm.imageartTrimRatio
            vm.imageartTrim.scale = (vm.imageartTrim.geometry.width1 * pro * vm.imageartTrim.scale) / (vm.imageartTrim.geometry.width * pro);
            vm.imageartTrim.offsetx = (Number(-(vm.imageartTrim.geometry.x - vm.imageartTrim.geometry.x1)) + Number(vm.imageartTrim.offsetx)).toFixed(2) - 0;
            vm.imageartTrim.offsety = (Number(-(vm.imageartTrim.geometry.y - vm.imageartTrim.geometry.y1)) + Number(vm.imageartTrim.offsety)).toFixed(2) - 0;
            vm.imageartTrim.geometry.x = $scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.x;
            vm.imageartTrim.geometry.y = $scope.currentPage.levels.level[$scope.template.levelI].imageart.geometry.y;
            if (vm.imageartTrim.offsetx > 0) {
                vm.imageartTrim.offsetx = 0;
            }
            if (vm.imageartTrim.offsety > 0) {
                vm.imageartTrim.offsety = 0;
            }
            if (vm.imageartTrim.scale < 1) {
                vm.imageartTrim.scale = 1;
            }
            $scope.currentPage.levels.level[$scope.template.levelI].imageart = vm.imageartTrim;
        }
        $scope.imageartTrimFalseClick = function () {
            vm.isImageartTrim = false;
        }

        //素材缩放right
        var isZoomDown = false;
        $scope.imageartdownZoomRight = function ($event, item, boole) {
            // if(item.movable){return}
            fn($event)
            if (boole) {
                imageartZoom1($event, item, 'right')
            } else {
                imageartZoom($event, item, 'right')
            }
        }
        //素材缩放left
        $scope.imageartdownZoomLeft = function ($event, item, boole) {
            // if(item.movable){return}
            fn($event)
            if (boole) {
                imageartZoom1($event, item, 'left')
            } else {
                imageartZoom($event, item, 'left')
            }
        }
        //素材缩放top
        $scope.imageartdownZoomTop = function ($event, item, boole) {
            // if(item.movable){return}
            fn($event)
            if (boole) {
                imageartZoom1($event, item, 'top')
            } else {
                imageartZoom($event, item, 'top')
            }
        }
        //素材缩放bottom
        $scope.imageartdownZoomBottom = function ($event, item, boole) {
            // if(!item.movable){return}
            fn($event)
            if (boole) {
                imageartZoom1($event, item, 'bottom')
            } else {
                imageartZoom($event, item, 'bottom')
            }
        }

        function imageartZoom($event, item, direction) {
            var pageIndex = $scope.template.pages.page[$scope.template.pageI];
            if (pageIndex == null) {
                return;
            }
            $scope.RightPanel = 'material';
            var pro = pageIndex.scale / pageIndex.proportion;

            var maxX = $contain.width();
            var maxY = $contain.height();
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = angular.copy(item.resource.width) * pro;
            var nh1 = angular.copy(item.resource.height) * pro;
            var x1 = angular.copy(item.offsetx * pro);
            var y1 = angular.copy(item.offsety * pro);
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var _scale = angular.copy(item.scale);
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;

                if (direction == 'bottom' || direction == 'left') {
                    var _w = (Number(nw) - Number(nl));
                }
                if (direction == 'top' || direction == 'right') {
                    var _w = (Number(nw) + Number(nl));
                }
                var _h = _w / nw * nh;
                // if(item.geometry.x* pro<=0){
                //     item.geometry.x = 0;
                // }
                // if(item.geometry.y* pro<=0){
                //     item.geometry.y = 0;
                // }

                if (_w - 0 + x2 > maxX) {
                    _w = maxX - x2;
                    _h = _w / nw * nh;
                }
                if (_h - 0 + y2 > maxY) {
                    _h = maxY - y2;
                    _w = _h / nh * nw;
                }

                if (_w > 20) {
                    item.geometry.width = _w / pro;
                    item.geometry.height = _h / pro;
                    item.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / pro;
                    item.offsety = (_h / (nh / nh1)) / (nh1 / y1) / pro;
                    $($event.target).parent().parent().find('.c-xml-page-material-img').css({
                        width: _w * _scale + 'px',
                        left: (_w / (nw / nw1)) / (nw1 / x1) + 'px',
                        top: (_h / (nh / nh1)) / (nh1 / y1) + 'px',
                    });

                    if (direction == 'left') {
                        item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                        item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                        $($event.target).parent().parent().css({
                            width: _w + 'px',
                            height: _h + 'px',
                            left: Number(x2) - Number(_w - nw) + 'px',
                            top: Number(y2) - Number(_h - nh) + 'px',
                        });
                    }
                    if (direction == 'top') {
                        item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                        $($event.target).parent().parent().css({
                            width: _w + 'px',
                            height: _h + 'px',
                            top: Number(y2) - Number(_h - nh) + 'px',
                        });
                    }
                    if (direction == 'right') {
                        $($event.target).parent().parent().css({
                            width: _w + 'px',
                            height: _h + 'px'
                        });
                    }
                    if (direction == 'bottom') {
                        item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                        $($event.target).parent().parent().css({
                            width: _w + 'px',
                            height: _h + 'px',
                            left: Number(x2) - Number(_w - nw) + 'px',
                        });
                    }
                    if (vm.unit == 'mm') {
                        $scope.pageValue.imageart = {
                            width: unitTransformPx(item.geometry.width).toFixed(2) - 0,
                            height: unitTransformPx(item.geometry.height).toFixed(2) - 0,
                            x: unitTransformPx(item.geometry.x).toFixed(2) - 0,
                            y: unitTransformPx(item.geometry.y).toFixed(2) - 0,
                        }
                    } else {
                        $scope.pageValue.imageart = {
                            width: unitTransformPx(item.geometry.width / 25.4 * vm.windowDpiW),
                            height: unitTransformPx(item.geometry.height / 25.4 * vm.windowDpiW),
                            x: unitTransformPx(item.geometry.x / 25.4 * vm.windowDpiW),
                            y: unitTransformPx(item.geometry.y / 25.4 * vm.windowDpiW),
                        }
                    }
                    $scope.$digest();
                }
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
                vm.singleArray.imageart = angular.copy(item);
                isZoomDown = false;
                $event.stopPropagation();
            }
        }

        function imageartZoom1($event, item, direction) {
            var pageIndex = $scope.template.pages.page[$scope.template.pageI];
            if (pageIndex == null) {
                return;
            }
            $scope.RightPanel = 'material';
            // var pro = pageIndex.scene.scale / pageIndex.proportion;
            // var pro = pageIndex.scale / pageIndex.scene.proportion;
            var pro = pageIndex.scene.ratio;

            var maxX = $(".editing1").width();
            var maxY = $(".editing1").height();
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = angular.copy(item.resource.width) * pro;
            var nh1 = angular.copy(item.resource.height) * pro;
            var x1 = angular.copy(item.offsetx * pro);
            var y1 = angular.copy(item.offsety * pro);
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var _scale = angular.copy(item.scale);
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;

                if (direction == 'bottom' || direction == 'left') {
                    var _w = (Number(nw) - Number(nl));
                }
                if (direction == 'top' || direction == 'right') {
                    var _w = (Number(nw) + Number(nl));
                }
                var _h = _w / nw * nh;
                // if(item.geometry.x* pro<=0){
                //     item.geometry.x = 0;
                // }
                // if(item.geometry.y* pro<=0){
                //     item.geometry.y = 0;
                // }

                if (_w - 0 + x2 > maxX) {
                    _w = maxX - x2;
                    _h = _w / nw * nh;
                }
                if (_h - 0 + y2 > maxY) {
                    _h = maxY - y2;
                    _w = _h / nh * nw;
                }

                if (_w > 20) {
                    item.geometry.width = _w / pro;
                    item.geometry.height = _h / pro;
                    item.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / pro;
                    item.offsety = (_h / (nh / nh1)) / (nh1 / y1) / pro;
                    $($event.target).parent().parent().find('.c-xml-page-material-img').css({
                        width: _w * _scale + 'px',
                        left: (_w / (nw / nw1)) / (nw1 / x1) + 'px',
                        top: (_h / (nh / nh1)) / (nh1 / y1) + 'px',
                    });

                    if (direction == 'left') {
                        item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                        item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                        $($event.target).parent().parent().css({
                            width: _w + 'px',
                            height: _h + 'px',
                            left: Number(x2) - Number(_w - nw) + 'px',
                            top: Number(y2) - Number(_h - nh) + 'px',
                        });
                    }
                    if (direction == 'top') {
                        item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                        $($event.target).parent().parent().css({
                            width: _w + 'px',
                            height: _h + 'px',
                            top: Number(y2) - Number(_h - nh) + 'px',
                        });
                    }
                    if (direction == 'right') {
                        $($event.target).parent().parent().css({
                            width: _w + 'px',
                            height: _h + 'px'
                        });
                    }
                    if (direction == 'bottom') {
                        item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                        $($event.target).parent().parent().css({
                            width: _w + 'px',
                            height: _h + 'px',
                            left: Number(x2) - Number(_w - nw) + 'px',
                        });
                    }
                    if (vm.unit == 'mm') {
                        $scope.pageValue.imageart = {
                            width: unitTransformPx(item.geometry.width).toFixed(2) - 0,
                            height: unitTransformPx(item.geometry.height).toFixed(2) - 0,
                            x: unitTransformPx(item.geometry.x).toFixed(2) - 0,
                            y: unitTransformPx(item.geometry.y).toFixed(2) - 0,
                        }
                    } else {
                        $scope.pageValue.imageart = {
                            width: unitTransformPx(item.geometry.width / 25.4 * vm.windowDpiW),
                            height: unitTransformPx(item.geometry.height / 25.4 * vm.windowDpiW),
                            x: unitTransformPx(item.geometry.x / 25.4 * vm.windowDpiW),
                            y: unitTransformPx(item.geometry.y / 25.4 * vm.windowDpiW),
                        }
                    }
                    $scope.$digest();
                }
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isZoomDown = false;
                $event.stopPropagation();
            }
        }

        window.resizeArtStart1 = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            dragMask.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragMask.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragMask.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft;        //鼠标到模板左边的距离
            dragMask.coordY = targ.offsetParent.offsetTop + targ.offsetTop;         //鼠标到模板顶部的距离
            dragMask.maxX = $contain.width();
            dragMask.maxY = $contain.height();

            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            var imageart = $scope.currentPage.levels.level[$scope.template.levelI].imageart;
            if (imageart.lock) {
                return
            }
            dragFlag = true;
            dragMask.orignW = imageart.geometry.width;
            dragMask.orignH = imageart.geometry.height;
            dragMask.orignOffsetx = imageart.offsetx;
            dragMask.orignOffsety = imageart.offsety;
            document.addEventListener("mousemove", resizeArtOn1, true);
            document.addEventListener("mouseup", resizeArtEnd1, true);
            // setZindex(e.target.dataset.index);
        };
        function resizeArtOn1(e) {
            if (dragFlag) {
                fn(e);
                // 是否按住shift键
                dragMask.shiftKey = e.shiftKey;
                $scope.RightPanel = 'material';
                var targ = e.target ? e.target : e.srcElement;
                if (targ == null) {
                    return;
                }
                var imgIndex = targ.dataset.index || $scope.template.levelI;
                if (imgIndex == null) {
                    return;
                }
                var pageIndex = $scope.template.pages.page[$scope.template.pageI];
                if (pageIndex == null) {
                    return;
                }
                var pressArt = pageIndex.levels.level[$scope.template.levelI].imageart;
                if (pressArt == null) {
                    return;
                }
                var pro = $scope.currentPage.scene.ratio;
                var x = pressArt.geometry.x* pro;
                var y = pressArt.geometry.y* pro;
                //20 小图标的宽高
                var w = (dragMask.coordX + 10 + e.clientX - dragMask.clientX - x);
                var h = w / pressArt.geometry.width * pressArt.geometry.height;

                if (w < 20 || h < 20) {
                    return
                }

                if(x+w>dragMask.maxX){
                    var maxW = dragMask.maxX-x;
                    pressArt.geometry.width = maxW/pro;
                    pressArt.geometry.height = (maxW / pressArt.geometry.width * pressArt.geometry.height)/pro;
                }else if(y+h>dragMask.maxY){
                    var maxH = dragMask.maxY-y;
                    pressArt.geometry.height = maxH/pro;
                    pressArt.geometry.width = (maxH/pressArt.geometry.height*pressArt.geometry.width)/pro;
                }else{
                    pressArt.geometry.width = w/pro;
                    pressArt.geometry.height = h/pro;
                }

                var scaleW = pressArt.geometry.width / dragMask.orignW;
                var scaleH = pressArt.geometry.height / dragMask.orignH
                pressArt.offsetx = scaleW * dragMask.orignOffsetx;
                pressArt.offsety = scaleH * dragMask.orignOffsety;
            }
            $scope.$digest();
            return false;
        }
        function resizeArtEnd1() {
            dragFlag = false;
            document.removeEventListener("mousemove", resizeArtOn1, true);
            document.removeEventListener("mouseup", resizeArtEnd1, true);
            updateHistorys();
            $scope.$digest();
        }

        /* ==========================================================================
         文字处理
         ========================================================================== */

        //编辑状态
        $scope.editableSel = function (box, attr) {
            $scope.currentPage.levels.level[$scope.template.levelI][box].editable = attr;
        }
        //不可编辑
        $scope.movableSel = function (box, attr) {
            $scope.currentPage.levels.level[$scope.template.levelI][box].movable = attr;
        }

        //文本框点击时

        $scope.selectDoc = function (e, $index, textdocument) {
            fn(e)
            $scope.template.levelI = $index;
            $scope.RightPanel = 'textDoc';
            if (vm.unit == 'mm') {
                $scope.pageValue.textdocument = {
                    width: unitTransformPx(textdocument.geometry.width).toFixed(2) - 0,
                    height: unitTransformPx(textdocument.geometry.height).toFixed(2) - 0,
                    x: unitTransformPx(textdocument.geometry.x).toFixed(2) - 0,
                    y: unitTransformPx(textdocument.geometry.y).toFixed(2) - 0
                }
            } else {
                $scope.pageValue.textdocument = {
                    width: textdocument.geometry.width,
                    height: textdocument.geometry.height,
                    x: textdocument.geometry.x,
                    y: textdocument.geometry.y
                }
            }

            $scope.template.angle = textdocument.rotation.angle;
            textdocument.current = 'textdocument';
            $scope.selectBox = textdocument;

            //如果是按住ctrl键 实现多选复制
            // var mm = $scope.currentPage.imageboxes.imagebox[$index];
            if (e.ctrlKey == true || e.metaKey == true) {
                if (textdocument.lock) {
                    return
                }
                textdocument.ctrlKey = true;
                vm.CtrlKeyArray.textdocuments[$index] = angular.copy(textdocument);
                ctrlAddSingleArray();
            } else {
                $scope.clearCtrlKey();
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, textdocument: {}};
                if (textdocument.lock) {
                    return
                }
                vm.singleArray.textdocument = angular.copy(textdocument);
            }
            $("#menus").fadeOut();
            // setZindex($index);
            $("#maskSelect").val("");
            e.stopPropagation();
        };

        $scope.selectTextDocFont = function (e, levelIndex,docIndex, text) {
            fn(e);
            $scope.template.levelI = levelIndex;
            $scope.template.docI = docIndex;
            vm.textDocCon = text.text;

            $("#menus").fadeOut();
        };

        var dragTextDoc = {};
        window.moveTextDocStart = function (e) {
            var $event = e || window.event;
            if ($event && $event.preventDefault) {
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            $scope.buttonShows = true;
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = targ.dataset.index;
            var currentLevel = $scope.currentPage.levels.level[$scope.template.levelI];
            var currentImg = currentLevel.textdocument;
            $scope.template.angle = currentImg.rotation.angle;
            if (!currentImg.movable) {
                return
            }

            dragTextDoc.clientX = e.clientX || 0;
            dragTextDoc.clientY = e.clientY || 0;
            dragTextDoc.coordX = targ.offsetLeft;
            dragTextDoc.coordY = targ.offsetTop;
            drag = true;
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragTextDoc.ctrlKey = true;
            } else {
                dragTextDoc.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveTextDocOn, true);
            document.addEventListener("mouseup", moveTextDocEnd, true);

        };
        //move 事件
        function moveTextDocOn(e) {
            fn(e);
            $scope.$digest();
            if (e.target.nodeName == 'SPAN') {
                return;
            }
            if (drag) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var currentLevel = currentPage.levels.level[$scope.template.levelI];
                var pressImg = currentLevel.textdocument;
                var angle = (pressImg.rotation.angle) * Math.PI / 180;

                var clientX = e.clientX - dragTextDoc.clientX;
                var clientY = e.clientY - dragTextDoc.clientY;

                var imgOffsetX = (dragTextDoc.coordX + e.clientX - dragTextDoc.clientX);
                var imgOffsetY = (dragTextDoc.coordY + e.clientY - dragTextDoc.clientY);
                var imgWidth = pressImg.geometry.width * currentPage.scale / currentPage.proportion;
                var imgHeight = pressImg.geometry.height * currentPage.scale / currentPage.proportion;
                var height = currentPage.height * currentPage.scale;
                var width = currentPage.width * currentPage.scale;
                var left = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var top = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                if (angle == 0) {
                    if (imgOffsetX <= left) {
                        imgOffsetX = left;
                    }

                    if (imgOffsetX >= (width + left - imgWidth)) {
                        imgOffsetX = width + left - imgWidth;
                    }
                    if (imgOffsetY <= top) {
                        imgOffsetY = top;
                    }

                    if (imgOffsetY >= (height + top - imgHeight)) {
                        imgOffsetY = height + top - imgHeight;
                    }
                } else {
                    if (imgOffsetX <= left) {
                        imgOffsetX = left;
                    }

                    if (imgOffsetX >= (width + left)) {
                        imgOffsetX = width + left;
                    }
                    if (imgOffsetY <= top) {
                        imgOffsetY = top;
                    }

                    if (imgOffsetY >= (height + top)) {
                        imgOffsetY = height + top;
                    }

                }

                if (dragTextDoc.ctrlKey) {
                    moveTogether(currentPage, clientX, clientY);
                } else {
                    pressImg.geometry.x = imgOffsetX / currentPage.scale * currentPage.proportion;
                    pressImg.geometry.y = imgOffsetY / currentPage.scale * currentPage.proportion;
                    $scope.pageValue.px.x = unitTransformPx(pressImg.geometry.x / 25.4 * vm.windowDpiW);
                    $scope.pageValue.px.y = unitTransformPx(pressImg.geometry.y / 25.4 * vm.windowDpiH);
                    $scope.pageValue.mm.x = pressImg.offsetXM = unitTransformPx(pressImg.geometry.x).toFixed(2) - 0;//px-->mm
                    $scope.pageValue.mm.y = pressImg.offsetYM = unitTransformPx(pressImg.geometry.y).toFixed(2) - 0;//px-->mm
                }
                $scope.currentPage = currentPage;
            }
            return false;
        }

        //鼠标松开事件
        function moveTextDocEnd() {
            if (dragTextDoc.ctrlKey) {
                moveTogetherEnd();
            }
            drag = false;
            dragTextDoc = {};
            document.removeEventListener("mousemove", moveTextDocOn, true);
            document.removeEventListener("mouseup", moveTextDocEnd, true);
            updateHistorys();
            $scope.$digest();
        }

        /**
         *放大占位框
         * @param e
         */
        var dragDocFlag = false, dragDoc = {}, textdocW = 1, textdocH = 1, textdocument = null;
        window.resizeDocStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;

            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            textdocument = angular.copy($scope.currentPage.levels.level[$scope.template.levelI].textdocument);

            if (textdocument.lock) {
                return
            }
            dragDoc.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragDoc.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragDoc.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft;        //鼠标到模板左边的距离
            dragDoc.coordY = targ.offsetParent.offsetTop + targ.offsetTop;         //鼠标到模板顶部的距离
            dragDoc.maxX = $contain.width();
            dragDoc.maxY = $contain.height();
            dragDocFlag = true;

            $scope.RightPanel = 'textDoc';

            textdocW = angular.copy(textdocument.geometry.width);
            textdocH = angular.copy(textdocument.geometry.height);

            document.addEventListener("mousemove", resizeDocOn, true);
            document.addEventListener("mouseup", resizeDocEnd, true);
            // setZindex(e.target.dataset.index);
        };
        function resizeDocOn(e) {
            if (dragDocFlag) {
                fn(e);
                // 是否按住shift键
                dragDoc.shiftKey = e.shiftKey;
                var targ = e.target ? e.target : e.srcElement;
                if (targ == null) {
                    return;
                }
                var imgIndex = $scope.template.levelI;
                if (imgIndex == null) {
                    return;
                }
                var pageIndex = $scope.template.pages.page[$scope.template.pageI];
                if (pageIndex == null) {
                    return;
                }
                var item = pageIndex.levels.level[$scope.template.levelI].textdocument;
                if (item == null) {
                    return;
                }

                var pro = pageIndex.scale / pageIndex.proportion
                var x = item.geometry.x* pro;
                var y = item.geometry.y* pro;
                //20 小图标的宽高
                var w = (dragDoc.coordX + 10 + e.clientX - dragDoc.clientX - x);
                // var w = (dragDoc.coordX + 10 + e.clientX - dragDoc.clientX - item.geometry.x *pro) /pro;
                var h = w / item.geometry.width * item.geometry.height

                if (w < 40 || h < 40) {
                    return
                }

                if (x + w > dragDoc.maxX) {
                    var maxW = dragDoc.maxX - x;
                    item.geometry.width = maxW / pro;
                    item.geometry.height = (maxW / item.geometry.width * item.geometry.height) / pro;
                } else if (y + h > dragDoc.maxY) {
                    var maxH = dragDoc.maxY - y;
                    item.geometry.height = maxH / pro;
                    item.geometry.width = (maxH / item.geometry.height * item.geometry.width) / pro;
                } else {
                    item.geometry.width = w / pro;
                    item.geometry.height = h / pro;
                }

                if (vm.unit == 'mm') {
                    $scope.pageValue.textdocument.width = unitTransformPx(item.geometry.width);
                    $scope.pageValue.textdocument.height = unitTransformPx(item.geometry.height);
                } else {
                    $scope.pageValue.textdocument.width = item.geometry.width;
                    $scope.pageValue.textdocument.height = item.geometry.height;
                }


                resizeLevelWaH();

            }
            $scope.$digest();
            return false;
        }
        function resizeDocEnd() {
            dragDocFlag = false;
            dragDoc = {};

            document.removeEventListener("mousemove", resizeDocOn, true);
            document.removeEventListener("mouseup", resizeDocEnd, true);
            updateHistorys();
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            $scope.$digest();
        }
        window.resizeDocStart1 = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;

            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            textdocument = angular.copy($scope.currentPage.levels.level[$scope.template.levelI].textdocument);

            if (textdocument.lock) {
                return
            }
            dragDoc.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragDoc.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragDoc.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft;        //鼠标到模板左边的距离
            dragDoc.coordY = targ.offsetParent.offsetTop + targ.offsetTop;         //鼠标到模板顶部的距离
            dragDoc.maxX = $contain.width();
            dragDoc.maxY = $contain.height();
            dragDocFlag = true;

            $scope.RightPanel = 'textDoc';

            textdocW = angular.copy(textdocument.geometry.width);
            textdocH = angular.copy(textdocument.geometry.height);

            document.addEventListener("mousemove", resizeDocOn1, true);
            document.addEventListener("mouseup", resizeDocEnd1, true);
            // setZindex(e.target.dataset.index);
        };
        function resizeDocOn1(e) {
            if (dragDocFlag) {
                fn(e);
                // 是否按住shift键
                dragDoc.shiftKey = e.shiftKey;
                var targ = e.target ? e.target : e.srcElement;
                if (targ == null) {
                    return;
                }
                var imgIndex = $scope.template.levelI;
                if (imgIndex == null) {
                    return;
                }
                var pageIndex = $scope.template.pages.page[$scope.template.pageI];
                if (pageIndex == null) {
                    return;
                }
                var item = pageIndex.levels.level[$scope.template.levelI].textdocument;
                if (item == null) {
                    return;
                }

                var pro = $scope.currentPage.scene.ratio;
                var x = item.geometry.x* pro;
                var y = item.geometry.y* pro;
                //20 小图标的宽高
                var w = (dragDoc.coordX + 10 + e.clientX - dragDoc.clientX - x);
                // var w = (dragDoc.coordX + 10 + e.clientX - dragDoc.clientX - item.geometry.x *pro) /pro;
                var h = w / item.geometry.width * item.geometry.height

                if (w < 40 || h < 40) {
                    return
                }

                if (x + w > dragDoc.maxX) {
                    var maxW = dragDoc.maxX - x;
                    item.geometry.width = maxW / pro;
                    item.geometry.height = (maxW / item.geometry.width * item.geometry.height) / pro;
                } else if (y + h > dragDoc.maxY) {
                    var maxH = dragDoc.maxY - y;
                    item.geometry.height = maxH / pro;
                    item.geometry.width = (maxH / item.geometry.height * item.geometry.width) / pro;
                } else {
                    item.geometry.width = w / pro;
                    item.geometry.height = h / pro;
                }

                if (vm.unit == 'mm') {
                    $scope.pageValue.textdocument.width = unitTransformPx(item.geometry.width);
                    $scope.pageValue.textdocument.height = unitTransformPx(item.geometry.height);
                } else {
                    $scope.pageValue.textdocument.width = item.geometry.width;
                    $scope.pageValue.textdocument.height = item.geometry.height;
                }

                resizeLevelWaH();

            }
            $scope.$digest();
            return false;
        }
        function resizeDocEnd1() {
            dragDocFlag = false;
            dragDoc = {};

            document.removeEventListener("mousemove", resizeDocOn, true);
            document.removeEventListener("mouseup", resizeDocEnd, true);
            updateHistorys();
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            $scope.$digest();
        }

        function resizeLevelWaH() {
            var doc = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textdocument;
            doc.background.offsetx = textdocument.background.offsetx * doc.geometry.width / textdocW;
            doc.background.offsety = textdocument.background.offsety * doc.geometry.height / textdocH;
            doc.background.width = textdocument.background.width * doc.geometry.width / textdocW;
            doc.background.height = textdocument.background.height * doc.geometry.height / textdocH;
            doc.background.offsetXM = unitTransformPx(doc.background.offsetx);
            doc.background.offsetYM = unitTransformPx(doc.background.offsety);

            angular.forEach(doc.levels.level, function (level,l) {
                var textdoc = textdocument.levels.level[l];
                if (level.imageart) {
                    var geo = {
                        width: textdoc.imageart.geometry.width * doc.geometry.width / textdocW,
                        height: textdoc.imageart.geometry.height * doc.geometry.height / textdocH,
                        x: textdoc.imageart.geometry.x * doc.geometry.width / textdocW,
                        y: textdoc.imageart.geometry.y * doc.geometry.height / textdocH,
                    }
                    level.imageart.geometry = geo;
                    level.imageart.widthM = unitTransformPx(geo.width);
                    level.imageart.heightM = unitTransformPx(geo.height);
                    level.imageart.offsetXM = unitTransformPx(geo.x);
                    level.imageart.offsetYM = unitTransformPx(geo.y);

                    level.imageart.offsetx = textdoc.imageart.offsetx * doc.geometry.width / textdocW;
                    level.imageart.offsety = textdoc.imageart.offsety * doc.geometry.height / textdocH;
                }
                if (level.textbox) {
                    var textGeo = {
                        width: textdoc.textbox.geometry.width * doc.geometry.width / textdocW,
                        height: textdoc.textbox.geometry.height * doc.geometry.height / textdocH,
                        x: textdoc.textbox.geometry.x * doc.geometry.width / textdocW,
                        y: textdoc.textbox.geometry.y * doc.geometry.height / textdocH,
                    }
                    level.textbox.geometry = textGeo;
                    level.textbox.widthM = unitTransformPx(textGeo.width);
                    level.textbox.heightM = unitTransformPx(textGeo.height);
                    level.textbox.offsetXM = unitTransformPx(textGeo.x);
                    level.textbox.offsetYM = unitTransformPx(textGeo.y);
                    level.textbox.style.size = textdoc.textbox.style.size*doc.geometry.width / textdocW;
                    level.textbox.style.lineheight = textdoc.textbox.style.lineheight*doc.geometry.width / textdocW;
                }
            })
        }

        $scope.slipGroup = function () {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var textdocument = angular.copy(currentPage.levels.level[$scope.template.levelI].textdocument);
            $scope.deleteBox();
            var textGeo = textdocument.geometry;
            vm.unit = 'mm';
            if (textdocument.background && textdocument.background.resource && textdocument.background.resource.identifier) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var currentLevel = addLevel(currentPage);
                $scope.pageValue.textdocument = {
                    width: unitTransformPx(textGeo.width),
                    height: unitTransformPx(textGeo.height),
                    x: unitTransformPx(textGeo.x),
                    y: unitTransformPx(textGeo.y)
                }

                var angle = 0;
                if (textdocument.rotation.angle) {
                    angle = textdocument.rotation.angle;
                }
                var resource = textdocument.background.resource;
                var scale = 1;
                if (textdocument.background.adaptation == "Height") {
                    scale = textdocument.background.width / textdocument.geometry.width;
                }
                currentLevel.imageart = ({
                    geometry: {
                        width: textdocument.geometry.width,
                        height: textdocument.geometry.height,
                        x: textdocument.geometry.x,
                        y: textdocument.geometry.y
                    },
                    rotation: {
                        angle: angle
                    },
                    // 模板使用，微信端不可进行操作
                    // type: 'static',
                    resource: {
                        identifier: resource.identifier,
                        provider: 'qiniu',
                        width: resource.width,
                        height: resource.height
                    },
                    scale: scale,
                    offsetx: textdocument.background.offsetx,
                    offsety: textdocument.background.offsety,
                    transparency: 1,
                    onlyshow: false,
                    movable: textdocument.movable,
                    editable: textdocument.editable,
                    bgImg: '',
                    unit: 'mm',
                    // index: currentPage.imagearts.imageart.length,
                    levelI: $scope.template.levelI,
                    pageI: $scope.template.pageI,
                    current: 'imageart',
                    lock: false
                });

                $scope.selectBox = currentLevel.imageart;
            }
            angular.forEach(textdocument.levels.level, function (level, i) {
                if (level.imageart) {
                    var currentPage = $scope.template.pages.page[$scope.template.pageI];
                    var currentLevel = addLevel(currentPage);
                    var resource = level.imageart.resource;
                    currentLevel.imageart = ({
                        geometry: {
                            width: level.imageart.geometry.width,
                            height: level.imageart.geometry.height,
                            x: level.imageart.geometry.x + textGeo.x,
                            y: level.imageart.geometry.y + textGeo.y
                        },
                        rotation: {
                            angle: 0
                        },
                        // 模板使用，微信端不可进行操作
                        type: 'static',
                        resource: {
                            identifier: resource.identifier,
                            provider: 'qiniu',
                            width: resource.width,
                            height: resource.height
                        },
                        scale: level.imageart.scale,
                        offsetx: level.imageart.offsetx,
                        offsety: level.imageart.offsety,
                        transparency: level.imageart.transparency,
                        onlyshow: false,
                        movable: textdocument.movable,
                        editable: textdocument.editable,
                        bgImg: '',
                        unit: 'mm',
                        // index: currentPage.imagearts.imageart.length,
                        levelI: $scope.template.levelI,
                        pageI: $scope.template.pageI,
                        current: 'imageart',
                        lock: false
                    });
                }
                if (level.textbox) {
                    var currentPage = $scope.template.pages.page[$scope.template.pageI];
                    var currentLevel = addLevel(currentPage);
                    currentLevel.textbox = angular.copy(level.textbox);
                    currentLevel.textbox.movable = textdocument.movable;
                    currentLevel.textbox.editable = textdocument.editable;
                    currentLevel.textbox.unit = 'mm';
                    currentLevel.textbox.levelI = $scope.template.levelI;
                    currentLevel.textbox.pageI = $scope.template.pageI;
                    currentLevel.textbox.current = 'textbox';
                    currentLevel.textbox.lock = false;
                    currentLevel.textbox.geometry.x = level.textbox.geometry.x + textGeo.x;
                    currentLevel.textbox.geometry.y = level.textbox.geometry.y + textGeo.y;
                    fontStyle(currentLevel.textbox);
                }
            })
            updateHistorys();
            $scope.currentPage = currentPage;
            $scope.RightPanel = 'material';
            $scope.template.transparency = 100;
            // console.log($scope.currentPage)

        }

        //文本框点击时
        $scope.selectText = function (e, $index, text) {
            fn(e);

            fontStyle(text);

            $scope.template.text = text.text;
            $scope.size = text.style.size;
            $scope.template.angle = text.rotation.angle;
            // chargeFontPath(text.identifier);

            if (vm.unit == 'mm') {
                $scope.pageValue.textbox = {
                    width: unitTransformPx(text.geometry.width).toFixed(2) - 0,
                    height: unitTransformPx(text.geometry.height).toFixed(2) - 0,
                    x: unitTransformPx(text.geometry.x).toFixed(2) - 0,
                    y: unitTransformPx(text.geometry.y).toFixed(2) - 0
                }
            } else {
                $scope.pageValue.textbox = {
                    width: text.geometry.width,
                    height: text.geometry.height,
                    x: text.geometry.x,
                    y: text.geometry.y
                }
            }

            $scope.template.angle = text.rotation.angle;
            vm.text = text.text;

            $scope.template.letterspacing = text.style.letterspacing;
            $(".block-width").css({left: text.style.letterspacing});
            $scope.template.lineheight = text.style.lineheight;
            $(".block-height").css({left: text.style.lineheight});
            // console.log($scope.currentPage)

            text.current = 'textbox';
            $scope.selectBox = text;

            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                if (text.lock) {
                    return
                }
                text.ctrlKey = true;
                vm.CtrlKeyArray.textboxes[$index] = angular.copy(text);
                ctrlAddSingleArray();
                $scope.RightPanel = '';
            } else {
                $scope.clearCtrlKey();
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
                if (text.lock) {
                    return
                }
                vm.singleArray.textbox = angular.copy(text);
                $scope.RightPanel = 'text';
            }
            $("#menus").fadeOut();
            $scope.template.levelI = $index;
            $scope.selPanel = 'text';
        };

        $scope.$watch("vm.text",function(newValue,oldValue){
            // if(newValue){
                sizeHeight();
            // }
        })

        $scope.$watch("vm.textDocCon",function(newValue,oldValue){
            if(oldValue){
                sizeTextDocHeight();
            }
        })

        function sizeTextDocHeight() {
            var _textbox = $scope.currentPage.levels.level[$scope.template.levelI].textdocument.levels.level[$scope.template.docI].textbox;
            _textbox.text = vm.textDocCon;
            var maxY = $contain.height();
            $timeout(function () {
                var _h = Number($(".textbox" + $scope.template.pageI + $scope.template.levelI+$scope.template.docI).find('.c-xml-page-textboxe-text').height()) + Number(5);
                var _top = Number($(".textbox" + $scope.template.pageI + $scope.template.levelI+$scope.template.docI)[0].offsetTop);
                if(_h+_top>maxY){
                    _textbox.offsetXM = _textbox.geometry.x = 0;
                    _textbox.offsetYM = _textbox.geometry.y = 0;;
                }
                _textbox.geometry.height = _h / ($scope.currentPage.scale / $scope.currentPage.proportion)
                _textbox.heightM = unitTransformPx(_textbox.geometry.height);
            })
        }

        var a = null;
        $scope.updateTextSize = function (value) {
            if (a !== null) {
                clearTimeout(a)
            }
            a = setTimeout(function () {
                $timeout(function () {
                    var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
                    if (text.lock) {
                        return
                    }
                    var sizeValue = value < 6 ? 6 : value;
                    text.style.size = sizeValue;
                    text.style.lineheight = $scope.template.lineheight = sizeValue;
                    sizeHeight();
                    updateHistorys();
                })
            },500)
        };

        function fontStyle(font) {
            return
            var _fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == font.fontuuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        _fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            font.style.bold1 = false;
            font.style.italic1 = false;
            if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                font.style.bold1 = true;
                font.style.italic1 = true;
            } else {
                if (_fontStyle.indexOf('ITALIC') != '-1') {
                    font.style.italic1 = true;
                }
                if (_fontStyle.indexOf('BOLD') != '-1') {
                    font.style.bold1 = true;
                }
            }
        }

        //文字放大缩小
        var isTextDown = false;
        $scope.textboxZoomRight = function($event, item, pro, index){
            textboxZoom($event, item, pro, index, 'right')
        }
        $scope.textboxZoomLeft = function($event, item, pro, index){
            textboxZoom($event, item, pro, index,'left')
        }
        function textboxZoom($event, item, pro, index, element){
            if(item.lock){return}
            fn($event);
            $scope.template.levelI = index || $scope.template.levelI;
            var x = $event.clientX;
            var nw = angular.copy(item.geometry.width) * pro;
            var left = angular.copy(item.geometry.x) * pro;
            //开关打开
            isTextDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isTextDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;
                var h = Number($($event.target).parent().find('.c-xml-page-textboxe-text').height()) + Number(5);
                if(element == 'left'){
                    item.geometry.x = (left + nl) / pro;
                    item.geometry.width = (Number(nw) - Number(nl)) / pro;
                    // if((Number(nw) - Number(nl)) < parseInt(item.style.size)){
                    //     item.geometry.width = (Number(parseInt(item.style.size)) + Number(5)) / pro;
                    //     item.geometry.x = (left + nl - Number(5)) / pro;
                    //     isTextDown = false;
                    //     return;
                    // }
                    // if((Number(nw) - Number(nl)) > $scope.currentPage.mediabox.width * pro){
                    //     item.geometry.width = $scope.currentPage.mediabox.width;
                    //     item.geometry.x = 0;
                    // }
                }
                if(element == 'right'){
                    item.geometry.width = (Number(nw) + Number(nl)) / pro;
                    // if((Number(nw) + Number(nl)) < parseInt(item.style.size)){
                    //     item.geometry.width = (Number(parseInt(item.style.size)) + Number(5)) / pro;
                    //     isTextDown = false;
                    //     return;
                    // }
                    // if((Number(nw) + Number(nl)) > $scope.currentPage.mediabox.width * pro){
                    //     item.geometry.width = $scope.currentPage.mediabox.width;
                    //     item.geometry.x = 0;
                    // }
                }
                item.geometry.height = h / pro;
                sizeHeight();
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                if(isTextDown){
                    $scope.currentPage.levels.level[$scope.template.levelI].textbox = item;
                    isTextDown = false;
                }
                $event.stopPropagation();
            }
        };
        $scope.textboxZoomLeft1 = function($event, item, pro, index){
            textboxZoom1($event, item, pro, index, 'left')
        };
        $scope.textboxZoomTop1 = function($event, item, pro, index){
            textboxZoom1($event, item, pro, index, 'top')
        };
        $scope.textboxZoomRight1 = function($event, item, pro, index){
            textboxZoom1($event, item, pro, index, 'right')
        };
        $scope.textboxZoomBottom1 = function($event, item, pro, index){
            textboxZoom1($event, item, pro, index, 'bottom')
        };
        function textboxZoom1($event, item, pro, index, element){
            if(item.lock){return}
            var $event = $event || window.event;                  // IE、火狐获取事件对象
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width);
            var nh = angular.copy(item.geometry.height);
            var _left = angular.copy(item.geometry.x);
            var _top = angular.copy(item.geometry.y);
            var _size = angular.copy(parseInt(item.style.size));
            var _lineheight = angular.copy(parseInt(item.style.lineheight));
            //开关打开
            isTextDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isTextDown == false) {return;}
                if(item.style.size < 6){
                    item.style.size = 6;
                    item.style.lineheight = 6;
                    isTextDown = false;
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = (nx - x) / pro;
                var nt = (ny - y) / pro;

                if(element == 'left'){
                    item.geometry.width = (Number(nw) - Number(nl));
                    item.geometry.x = (Number(_left) + Number(nl));
                    item.style.size = Math.ceil(((Number(nw) - Number(nl)) / nw * _size));
                    item.style.lineheight = Math.ceil(((Number(nw) - Number(nl)) / nw * _lineheight));
                    item.geometry.y = (Number(_top) - Number(item.geometry.height - nh));
                }
                if(element == 'top'){
                    item.geometry.width = (Number(nw) + Number(nl));
                    item.style.size = Math.ceil(((Number(nw) + Number(nl)) / nw * _size));
                    item.style.lineheight = Math.ceil(((Number(nw) + Number(nl)) / nw * _lineheight));
                    item.geometry.y = (Number(_top) - Number(item.geometry.height - nh));
                }
                if(element == 'right'){
                    item.geometry.width = (Number(nw) + Number(nl) + Number(5));
                    item.style.size = Math.ceil(((Number(nw) + Number(nl)) / nw * _size));
                    item.style.lineheight = Math.ceil(((Number(nw) + Number(nl)) / nw * _lineheight));
                }
                if(element == 'bottom'){
                    item.geometry.width = (Number(nw) - Number(nl));
                    item.geometry.x = (Number(_left) + Number(nl));
                    item.style.size = Math.ceil(((Number(nw) - Number(nl)) / nw * _size));
                    item.style.lineheight = Math.ceil(((Number(nw) - Number(nl)) / nw * _lineheight));
                }
                sizeHeight();
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isTextDown = false;
                $event.stopPropagation();
            }
        }



        function sizeHeight() {
            if ($scope.currentPage) {
                var _textbox = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
                _textbox.text = vm.text;
                var maxY = $contain.height();
                vm.unit = 'mm';
                $timeout(function () {
                    var $boxDiv = $(".textbox" + $scope.template.pageI + $scope.template.levelI);
                    var pro = $scope.currentPage.scale / $scope.currentPage.proportion;
                    if ($scope.currentPage.scene) {
                        $boxDiv = $(".textbox1" + $scope.template.pageI + $scope.template.levelI);
                        pro = $scope.currentPage.scene.ratio;
                    }
                    var _h = Number($boxDiv.find('.c-xml-page-textboxe-span').height()) + Number(5);
                    // var _top = Number($boxDiv[0].offsetTop);
                    // if (_h + _top > maxY) {
                    //     $scope.pageValue.textbox.x = _textbox.geometry.x = 0;
                    //     $scope.pageValue.textbox.y = _textbox.geometry.y = 0;
                    // }
                    _textbox.geometry.height = _h / pro;
                })
            }
        }

        $scope.updateTextFont = function (data) {
            if (data == undefined) {
                MessageService.error("文字添加失败，请上传字体!");
                return
            }
            var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
            if (text.lock) {
                return
            }

            FontManagement.fontSupport({
                uuid: data.uuid,
                bold: text.style.bold,
                italic: text.style.italic,
                content: text.text
            }, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    if (msg.success && !msg.supported) {
                        var message = "【" + msg.failed + "】" + "当前字体不支持这些文字！";
                        MessageService.error(message);
                    } else {
                        text.style.font = data.family;
                        text.fontuuid = data.uuid;
                        text.identifier = vm.url + data.fontStyles[0].identifier;
                        $scope.item = data;
                        fontStyle(text);
                        getFont(text,FontManagement);
                        sizeHeight()
                        text.characterWarning = false;
                        text.characterMessage = "";
                        text.loading = false;
                        updateHistorys();
                    }
                } else {
                    MessageService.error("检查字体字符异常");
                }
            });
        };

        $scope.showTextSpacing = function () {
            $(".space-slider").toggleClass('hide1');
            $(".nav_spacing").toggleClass('nav-spacing-active');
            $scope.isAlignHover = false;
            $scope.isDistriHover = false;
            toobarFlag1 = true;
            toobarFlag2 = true;
        }

        $scope.spacingClick = function (e) {
            fn(e);
            var text = $scope.template.pages.page[$scope.template.pageI].levels.level[[$scope.template.levelI]].textbox;
            var targ = e.target ? e.target : e.srcElement;
            var value = targ.dataset.value;
            var l = 120 - Math.abs(e.offsetX);
            if (value == 'width') {
                text.style.letterspacing = $scope.template.letterspacing = l;
                $(".block-width").css({left: l});

            } else {
                if (l < text.style.size) {
                    MessageService.error("行间距有效范围：" + text.style.size + "~110");
                    $scope.template.lineheight = text.style.size;
                    return
                }
                text.style.lineheight = $scope.template.lineheight = l;
                $(".block-height").css({left: l});
            }
        }

        $scope.spacingChange = function (attr) {
            var text = $scope.template.pages.page[$scope.template.pageI].levels.level[[$scope.template.levelI]].textbox;
            if (text.lock) {
                return
            }
            if (attr) {
                if ($scope.template.letterspacing > 110) {
                    MessageService.error("字间距有效范围：0~110");
                    $scope.template.letterspacing = 110;
                }
                text.style.letterspacing = $scope.template.letterspacing;
                $(".block-width").css({left: text.style.letterspacing});
            } else {
                if ($scope.template.lineheight > 110) {
                    MessageService.error("行间距有效范围：" + text.style.size + "~110");
                    $scope.template.lineheight = 110;
                }
                if ($scope.template.lineheight < 0.000001) {
                    MessageService.error("行间距有效范围：" + text.style.size + "~110");
                    $scope.template.lineheight = text.style.size;
                }
                text.style.lineheight = $scope.template.lineheight;
                $(".block-height").css({left: text.style.lineheight});
            }
            sizeHeight();
            updateHistorys();
        }

        // 字/行间距鼠标点击移动
        var spacingW = {};
        var flagSpacing = false
        window.resizeSpacingStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            spacingW.clientX = e.clientX || 0;
            spacingW.originLeft = targ.offsetLeft;
            spacingW.value = targ.dataset.value;
            var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
            if (text.lock) {
                return
            }
            flagSpacing = true;
            document.addEventListener("mousemove", resizeSpacingOn, true);
            document.addEventListener("mouseup", resizeSpacingEnd, true);
        }

        function resizeSpacingOn(e) {
            if (flagSpacing) {
                var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
                var l = spacingW.originLeft + e.clientX - spacingW.clientX;
                if (spacingW.value == 'height' && l < text.style.size) {
                    l = text.style.size;
                } else if (spacingW.value == 'width' && l < 0) {
                    l = 0;
                }
                if (l > 110) {
                    l = 110
                }
                if (spacingW.value == 'width') {
                    $(".block-width").css({left: l});
                    text.style.letterspacing = $scope.template.letterspacing = l;
                } else {
                    $(".block-height").css({left: l});
                    text.style.lineheight = $scope.template.lineheight = l;
                }

                sizeHeight();
                $scope.$digest();
            }
        }

        function resizeSpacingEnd() {
            spacingW = {}
            flagSpacing = false;
            updateHistorys();
            document.removeEventListener("mousemove", resizeSpacingOn, true);
            document.removeEventListener("mouseup", resizeSpacingEnd, true);
        }

        function fontEdit() {
            for (var i = 0; i < vm.data.textDocument.levels.level.length; i++) {
                var _font = vm.data.textDocument.levels.level[i].textbox;
                if (_font.selected) {
                    _font = vm.font;
                    break;
                }
            }
            cookie()
        }

        //字体family
        $scope.operationFontFamily = function () {
            getFont(vm.font,FontManagement)
            fontEdit();
        }

        //字体
        $scope.operationFont = function () {
            fontEdit()
        }
        //斜体
        $scope.operationItalic = function () {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.italic = !level.textbox.style.italic;
            }
            updateHistorys();
        }
        //粗体
        $scope.operationBold = function () {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.bold = !level.textbox.style.bold;
            }
            updateHistorys();
        }
        //间距
        vm.isOperationSpacing = false
        $scope.operationSpacing = function (attr) {
            toolbarStatus();
            vm.isOperationSpacing = attr;

            var text = $scope.currentPage.levels.level[[$scope.template.levelI]].textbox;
            $scope.template.letterspacing = text.style.letterspacing;
            $scope.template.lineheight = text.style.lineheight;
            setTimeout(function () {
                $(".block-width").css({left: text.style.letterspacing});
                $(".block-height").css({left: text.style.lineheight});
            }, 100)
            sizeHeight();
        }

        //对齐点击
        vm.isOperationDir = false
        $scope.operationDir = function (attr) {
            toolbarStatus();
            vm.isOperationDir = attr;
        }

        //左对齐
        $scope.operationBarLeft = function ($event) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.align = 'left';
            }
            updateHistorys();
        }
        //居中对齐
        $scope.operationBarCenter = function ($event) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.align = 'center';
            }
            updateHistorys();
        }
        //右对齐
        $scope.operationBarRight = function ($event) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.align = 'right';
            }
            updateHistorys();
        }
        //两端对齐
        $scope.operationBarJustify = function ($event) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.align = 'justify';
            }
            updateHistorys();
        }
        //颜色
        $scope.operationColorOpen = function ($event) {
            fn($event)
            vm.isOperationColor = !vm.isOperationColor;
        }

        $scope.operationColor = function (item) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.color = item.id;
            }
            vm.textColor = null;
            updateHistorys();
        }

        $scope.chooseTextColor = function () {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            level.textbox.style.color = vm.textColor;
            vm.textColor = null;
        }

        /* ==========================================================================
         条形码处理
         ========================================================================== */
        // 增加条形码
        $scope.addBarCode = function () {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.barcode && currentPage.barcode.geometry) {
                return
            }
            vm.unit = 'mm';

            currentPage.barcode = {
                rotation: {angle: 0},
                geometry: {
                    width: unitTransform(60),
                    height: unitTransform(30),
                    x: unitTransform(16),
                    y: unitTransform(16)
                },
                zoom: 1,
                unit: 'mm',
                lock: false,
                current: 'barcode'
            };
            $scope.currentPage = currentPage;

            $scope.selectBox = currentPage.barcode;
            if (vm.unit == 'mm') {
                $scope.pageValue.barcode = {
                    width: 60,
                    height: 30,
                    x: 16,
                    y: 16
                }
            } else {
                $scope.pageValue.barcode = currentPage.barcode.geometry;
            }

            updateHistorys();
        };
        // 增加二维码
        $scope.addQrCode = function () {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.qrcode && currentPage.qrcode.geometry) {
                return
            }
            vm.unit = 'mm';

            currentPage.qrcode = {
                rotation: {angle: 0},
                geometry: {
                    width: MmTurnPx(60),
                    height: MmTurnPx(60),
                    x: MmTurnPx(16),
                    y: MmTurnPx(16)
                },
                zoom: 1,
                unit: 'mm',
                lock: false,
                current: 'qrcode'
            };
            $scope.currentPage = currentPage;

            $scope.selectBox = currentPage.qrcode;
            if (vm.unit == 'mm') {
                $scope.pageValue.qrcode = {
                    width: 60,
                    height: 30,
                    x: 16,
                    y: 16
                }
            } else if(vm.unit == 'px') {
                $scope.pageValue.qrcode = currentPage.qrcode.geometry;
            } else if(vm.unit == 'in') {
                $scope.pageValue.qrcode = {
                    width: PxTurnIn(currentPage.qrcode.geometry.width),
                    height: PxTurnIn(currentPage.qrcode.geometry.height),
                    x: PxTurnIn(currentPage.qrcode.geometry.x),
                    y: PxTurnIn(currentPage.qrcode.geometry.y)
                };
            }

            updateHistorys();
        };

        $scope.updateBarParameter = function () {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.barcode.lock) {
                return
            }
            if(type == 'width'){
                if($scope.pageValue.barcode.width < 10){
                    $scope.pageValue.barcode.width = 10;
                }
            }
            if(type == 'height'){
                if($scope.pageValue.barcode.height < 5){
                    $scope.pageValue.barcode.height = 5;
                }
            }

            if (vm.unit == 'mm') {
                currentPage.barcode.geometry = {
                    width: unitTransform($scope.pageValue.barcode.width),
                    height: unitTransform($scope.pageValue.barcode.height),
                    x: unitTransform($scope.pageValue.barcode.x),
                    y: unitTransform($scope.pageValue.barcode.y)
                }
            }
            if (vm.unit == 'px') {
                currentPage.barcode.geometry = {
                    width: $scope.pageValue.barcode.width,
                    height: $scope.pageValue.barcode.height,
                    x: $scope.pageValue.barcode.x,
                    y: $scope.pageValue.barcode.y
                }
            }
            updateHistorys();
        };
        $scope.updateQrcodeParameter = function (item) {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.qrcode.lock) {
                return
            }
            if(item == 'width'){
                $scope.pageValue.qrcode.height = $scope.pageValue.qrcode.width;
            }
            if(item == 'height'){
                $scope.pageValue.qrcode.width = $scope.pageValue.qrcode.height;
            }

            if (vm.unit == 'mm') {
                currentPage.qrcode.geometry = {
                    width: MmTurnPx($scope.pageValue.qrcode.width),
                    height: MmTurnPx($scope.pageValue.qrcode.height),
                    x: MmTurnPx($scope.pageValue.qrcode.x),
                    y: MmTurnPx($scope.pageValue.qrcode.y)
                }
            }
            if (vm.unit == 'px') {
                currentPage.qrcode.geometry = {
                    width: $scope.pageValue.qrcode.width,
                    height: $scope.pageValue.qrcode.height,
                    x: $scope.pageValue.qrcode.x,
                    y: $scope.pageValue.qrcode.y
                }
            }
            updateHistorys();
        };

        //条形码点击时
        $scope.selectBar = function (e, currentPage, item) {
            fn(e);
            // $scope.selPanel = 'barcode';
            $scope.RightPanel = item;
            var barcode = currentPage[item];

            if (vm.unit == 'mm') {
                $scope.pageValue[item] = {
                    width: unitTransformPx(barcode.geometry.width).toFixed(2) - 0,
                    height: unitTransformPx(barcode.geometry.height).toFixed(2) - 0,
                    x: unitTransformPx(barcode.geometry.x).toFixed(2) - 0,
                    y: unitTransformPx(barcode.geometry.y).toFixed(2) - 0
                }
            } else {
                $scope.pageValue[item] = {
                    width: barcode.geometry.width,
                    height: barcode.geometry.height,
                    x: barcode.geometry.x,
                    y: barcode.geometry.y
                }
            }

            $scope.template.angle = currentPage[item].rotation.angle;
            barcode.current = item;
            $scope.selectBox = barcode;


            var mm = $scope.currentPage[item];
            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                if (barcode.lock) {
                    return
                }
                mm.ctrlKey = true;
                vm.CtrlKeyArray[item][0] = angular.copy(mm);
                ctrlAddSingleArray();
            } else {
                mm.ctrlKey = false;
                $scope.clearCtrlKey();
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
                if (barcode.lock) {
                    return
                }
                vm.singleArray[item] = angular.copy(mm);
            }
            $("#menus").fadeOut();

        };

        //条形码移动事件
        var dragBar;
        var dragBarBox = {};
        window.moveBarcodeStart = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            dragBarBox.clientX = e.clientX || 0;
            dragBarBox.clientY = e.clientY || 0;
            dragBarBox.coordX = targ.offsetLeft;
            dragBarBox.coordY = targ.offsetTop;
            dragBar = true;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.barcode.lock) {
                return
            }
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragBarBox.ctrlKey = true;
            } else {
                dragBarBox.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveBarcodeOn, true);
            document.addEventListener("mouseup", moveBarcodeOnEnd, true);
        };
        //条形码move事件
        function moveBarcodeOn(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();

            if (dragBar) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];

                var clientX = e.clientX - dragBarBox.clientX;
                var clientY = e.clientY - dragBarBox.clientY;

                var barOffsetX = (dragBarBox.coordX + clientX);
                var barOffsetY = (dragBarBox.coordY + clientY);
                var barWidth = currentPage.barcode.geometry.width * currentPage.scale / currentPage.proportion;
                var barHeight = currentPage.barcode.geometry.height * currentPage.scale / currentPage.proportion;
                var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scale;
                var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scale;
                var barcodeAngle = currentPage.barcode.rotation.angle;

                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                var barx = 0, bary = 0;

                //中心点
                var xcenter = barWidth / 2 + barOffsetX;
                var ycenter = barHeight / 2 + barOffsetY;


                if (barcodeAngle == 0) {
                    if (barOffsetX <= 0) {
                        barOffsetX = 0;
                    }

                    if (barOffsetX >= (width + lar - barWidth)) {
                        barOffsetX = width + lar - barWidth;
                    }
                    if (barOffsetY <= 0) {
                        barOffsetY = 0;
                    }

                    if (barOffsetY >= (height + tab - barHeight)) {
                        barOffsetY = height + tab - barHeight;
                    }

                    barx = barOffsetX / currentPage.scale * currentPage.proportion;
                    bary = barOffsetY / currentPage.scale * currentPage.proportion;
                } else {

                    if (xcenter <= 0) {
                        xcenter = 0;
                    }

                    if (xcenter >= (width + lar)) {
                        xcenter = width + lar;
                    }
                    if (ycenter <= 0) {
                        ycenter = 0;
                    }

                    if (ycenter >= (height + tab)) {
                        ycenter = height + tab;
                    }
                    barx = (xcenter - barWidth / 2) / currentPage.scale * currentPage.proportion;
                    bary = (ycenter - barHeight / 2) / currentPage.scale * currentPage.proportion;
                }

                currentPage.barcode.geometry.x = barx;
                currentPage.barcode.geometry.y = bary;

                if (vm.unit == 'mm') {
                    $scope.pageValue.barcode.x = unitTransformPx(barx).toFixed(2) - 0;//px-->mm
                    $scope.pageValue.barcode.y = unitTransformPx(bary).toFixed(2) - 0;//px-->mm
                } else {
                    $scope.pageValue.barcode.x = unitTransformPx(barx / 25.4 * vm.windowDpiW);
                    $scope.pageValue.barcode.y = unitTransformPx(barx / 25.4 * vm.windowDpiW);
                }
                // }
            }
            return false;
        }
        //条形码鼠标松开
        function moveBarcodeOnEnd() {
            $scope.$digest();
            dragBar = false;
            document.removeEventListener("mousemove", moveBarcodeOn, true);
            document.removeEventListener("mouseup", moveBarcodeOnEnd, true);
            dragBarBox = {};
            updateHistorys();
        }
        window.moveBarcodeStart1 = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            dragBarBox.clientX = e.clientX || 0;
            dragBarBox.clientY = e.clientY || 0;
            dragBarBox.coordX = targ.offsetLeft;
            dragBarBox.coordY = targ.offsetTop;
            dragBar = true;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.barcode.lock) {
                return
            }
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragBarBox.ctrlKey = true;
            } else {
                dragBarBox.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveBarcodeOn1, true);
            document.addEventListener("mouseup", moveBarcodeOnEnd1, true);
        };
        //条形码move事件
        function moveBarcodeOn1(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();

            if (dragBar) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];

                var clientX = e.clientX - dragBarBox.clientX;
                var clientY = e.clientY - dragBarBox.clientY;

                var barOffsetX = (dragBarBox.coordX + clientX);
                var barOffsetY = (dragBarBox.coordY + clientY);
                var barWidth = currentPage.barcode.geometry.width * currentPage.scene.ratio;
                var barHeight = currentPage.barcode.geometry.height * currentPage.scene.ratio;
                // var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scene.ratio;
                // var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scene.ratio;
                var height = currentPage.mediabox.height * currentPage.scene.ratio;
                var width = currentPage.mediabox.width * currentPage.scene.ratio;
                var barcodeAngle = currentPage.barcode.rotation.angle;

                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                var barx = 0, bary = 0;

                //中心点
                var xcenter = barWidth / 2 + barOffsetX;
                var ycenter = barHeight / 2 + barOffsetY;


                if (barcodeAngle == 0) {
                    if (barOffsetX <= 0) {
                        barOffsetX = 0;
                    }

                    if (barOffsetX >= (width - barWidth)) {
                        barOffsetX = width - barWidth;
                    }
                    if (barOffsetY <= 0) {
                        barOffsetY = 0;
                    }

                    if (barOffsetY >= (height - barHeight)) {
                        barOffsetY = height - barHeight;
                    }

                    barx = barOffsetX / currentPage.scene.ratio;
                    bary = barOffsetY / currentPage.scene.ratio;
                } else {

                    if (xcenter <= 0) {
                        xcenter = 0;
                    }

                    if (xcenter >= (width + lar)) {
                        xcenter = width + lar;
                    }
                    if (ycenter <= 0) {
                        ycenter = 0;
                    }

                    if (ycenter >= (height + tab)) {
                        ycenter = height + tab;
                    }
                    barx = (xcenter - barWidth / 2) / currentPage.scene.ratio;
                    bary = (ycenter - barHeight / 2) / currentPage.scene.ratio;
                }

                currentPage.barcode.geometry.x = barx;
                currentPage.barcode.geometry.y = bary;

                if (vm.unit == 'mm') {
                    $scope.pageValue.barcode.x = unitTransformPx(barx).toFixed(2) - 0;//px-->mm
                    $scope.pageValue.barcode.y = unitTransformPx(bary).toFixed(2) - 0;//px-->mm
                } else {
                    $scope.pageValue.barcode.x = unitTransformPx(barx / 25.4 * vm.windowDpiW);
                    $scope.pageValue.barcode.y = unitTransformPx(barx / 25.4 * vm.windowDpiW);
                }
                // }
            }
            return false;
        }
        //条形码鼠标松开
        function moveBarcodeOnEnd1() {
            $scope.$digest();
            dragBar = false;
            document.removeEventListener("mousemove", moveBarcodeOn1, true);
            document.removeEventListener("mouseup", moveBarcodeOnEnd1, true);
            dragBarBox = {};
            updateHistorys();
        }

        //二维码移动事件
        var dragQrcodeTrue;
        var dragQrcode = {};
        window.moveQrcodeStart = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            dragQrcode.clientX = e.clientX || 0;
            dragQrcode.clientY = e.clientY || 0;
            dragQrcode.coordX = targ.offsetLeft;
            dragQrcode.coordY = targ.offsetTop;
            dragQrcodeTrue = true;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.qrcode.lock) {
                return
            }
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragQrcode.ctrlKey = true;
            } else {
                dragQrcode.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveQrcodeOn, true);
            document.addEventListener("mouseup", moveQrcodeOnEnd, true);
        };

        //条形码move事件
        function moveQrcodeOn(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();

            if (dragQrcodeTrue) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];

                var clientX = e.clientX - dragQrcode.clientX;
                var clientY = e.clientY - dragQrcode.clientY;

                var barOffsetX = (dragQrcode.coordX + clientX);
                var barOffsetY = (dragQrcode.coordY + clientY);
                var barWidth = currentPage.qrcode.geometry.width * currentPage.scale / currentPage.proportion;
                var barHeight = currentPage.qrcode.geometry.height * currentPage.scale / currentPage.proportion;
                var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scale;
                var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scale;
                var qrcodeAngle = currentPage.qrcode.rotation.angle;

                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                var barx = 0, bary = 0;

                //中心点
                var xcenter = barWidth / 2 + barOffsetX;
                var ycenter = barHeight / 2 + barOffsetY;


                if (qrcodeAngle == 0) {
                    if (barOffsetX <= 0) {
                        barOffsetX = 0;
                    }

                    if (barOffsetX >= (width + lar - barWidth)) {
                        barOffsetX = width + lar - barWidth;
                    }
                    if (barOffsetY <= 0) {
                        barOffsetY = 0;
                    }

                    if (barOffsetY >= (height + tab - barHeight)) {
                        barOffsetY = height + tab - barHeight;
                    }

                    barx = barOffsetX / currentPage.scale * currentPage.proportion;
                    bary = barOffsetY / currentPage.scale * currentPage.proportion;
                } else {

                    if (xcenter <= 0) {
                        xcenter = 0;
                    }

                    if (xcenter >= (width + lar)) {
                        xcenter = width + lar;
                    }
                    if (ycenter <= 0) {
                        ycenter = 0;
                    }

                    if (ycenter >= (height + tab)) {
                        ycenter = height + tab;
                    }
                    barx = (xcenter - barWidth / 2) / currentPage.scale * currentPage.proportion;
                    bary = (ycenter - barHeight / 2) / currentPage.scale * currentPage.proportion;
                }

                currentPage.qrcode.geometry.x = barx;
                currentPage.qrcode.geometry.y = bary;

                if (vm.unit == 'mm') {
                    $scope.pageValue.qrcode.x = PxTurnMm(barx).toFixed(2) - 0;//px-->mm
                    $scope.pageValue.qrcode.y = PxTurnMm(bary).toFixed(2) - 0;//px-->mm
                } else if(vm.unit == 'px') {
                    $scope.pageValue.qrcode.x = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                    $scope.pageValue.qrcode.y = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                } else if(vm.unit == 'in') {
                    $scope.pageValue.qrcode.x = PxTurnIn(barx).toFixed(2) - 0;
                    $scope.pageValue.qrcode.y = PxTurnIn(bary).toFixed(2) - 0;
                }
            }
            return false;
        }

        //条形码鼠标松开
        function moveQrcodeOnEnd() {
            $scope.$digest();
            dragQrcode = false;
            document.removeEventListener("mousemove", moveQrcodeOn, true);
            document.removeEventListener("mouseup", moveQrcodeOnEnd, true);
            dragQrcode = {};
            updateHistorys();
        }

        window.moveQrcodeStart1 = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            dragQrcode.clientX = e.clientX || 0;
            dragQrcode.clientY = e.clientY || 0;
            dragQrcode.coordX = targ.offsetLeft;
            dragQrcode.coordY = targ.offsetTop;
            dragQrcodeTrue = true;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (currentPage.qrcode.lock) {
                return
            }
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragQrcode.ctrlKey = true;
            } else {
                dragQrcode.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveQrcodeOn1, true);
            document.addEventListener("mouseup", moveQrcodeOnEnd1, true);
        };

        //条形码move事件
        function moveQrcodeOn1(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();

            if (dragQrcodeTrue) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];

                var clientX = e.clientX - dragQrcode.clientX;
                var clientY = e.clientY - dragQrcode.clientY;

                var barOffsetX = (dragQrcode.coordX + clientX);
                var barOffsetY = (dragQrcode.coordY + clientY);
                var barWidth = currentPage.qrcode.geometry.width * currentPage.scale / currentPage.proportion;
                var barHeight = currentPage.qrcode.geometry.height * currentPage.scale / currentPage.proportion;
                var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scale;
                var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scale;
                var qrcodeAngle = currentPage.qrcode.rotation.angle;

                var lar = (currentPage.bloodPx.left + currentPage.bloodPx.right) * currentPage.scale / currentPage.proportion;
                var tab = (currentPage.bloodPx.top + currentPage.bloodPx.bottom) * currentPage.scale / currentPage.proportion;

                var barx = 0, bary = 0;

                //中心点
                var xcenter = barWidth / 2 + barOffsetX;
                var ycenter = barHeight / 2 + barOffsetY;


                if (qrcodeAngle == 0) {
                    if (barOffsetX <= 0) {
                        barOffsetX = 0;
                    }

                    if (barOffsetX >= (width + lar - barWidth)) {
                        barOffsetX = width + lar - barWidth;
                    }
                    if (barOffsetY <= 0) {
                        barOffsetY = 0;
                    }

                    if (barOffsetY >= (height + tab - barHeight)) {
                        barOffsetY = height + tab - barHeight;
                    }

                    barx = barOffsetX / currentPage.scale * currentPage.proportion / $scope.proportion;
                    bary = barOffsetY / currentPage.scale * currentPage.proportion / $scope.proportion;
                } else {

                    if (xcenter <= 0) {
                        xcenter = 0;
                    }

                    if (xcenter >= (width + lar)) {
                        xcenter = width + lar;
                    }
                    if (ycenter <= 0) {
                        ycenter = 0;
                    }

                    if (ycenter >= (height + tab)) {
                        ycenter = height + tab;
                    }
                    barx = (xcenter - barWidth / 2) / currentPage.scale * currentPage.proportion / $scope.proportion;
                    bary = (ycenter - barHeight / 2) / currentPage.scale * currentPage.proportion / $scope.proportion;
                }

                currentPage.qrcode.geometry.x = barx;
                currentPage.qrcode.geometry.y = bary;

                if (vm.unit == 'mm') {
                    $scope.pageValue.qrcode.x = PxTurnMm(barx).toFixed(2) - 0;//px-->mm
                    $scope.pageValue.qrcode.y = PxTurnMm(bary).toFixed(2) - 0;//px-->mm
                } else if(vm.unit == 'px') {
                    $scope.pageValue.qrcode.x = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                    $scope.pageValue.qrcode.y = PxTurnMm(barx / 25.4 * vm.windowDpiW);
                } else if(vm.unit == 'in') {
                    $scope.pageValue.qrcode.x = PxTurnIn(barx).toFixed(2) - 0;
                    $scope.pageValue.qrcode.y = PxTurnIn(bary).toFixed(2) - 0;
                }
                // }
            }
            return false;
        }

        //条形码鼠标松开
        function moveQrcodeOnEnd1() {
            $scope.$digest();
            dragQrcode = false;
            document.removeEventListener("mousemove", moveQrcodeOn1, true);
            document.removeEventListener("mouseup", moveQrcodeOnEnd1, true);
            dragQrcode = {};
            updateHistorys();
        }

        /* ==========================================================================
         单位切换
         ========================================================================== */

        // mm---px
        function ThreeHundredDpi(attr1, attr2) {
            // Math.floor(px / 300 * 25.4)   (px--mm)
            // Math.ceil(mm / 25.4 * 300)   (mm---px)
            var px1 = parseFloat((attr1 / 25.4 * vm.windowDpiW - 0)).toFixed(2) - 0;
            var px2 = parseFloat((attr2 / 25.4 * vm.windowDpiH - 0)).toFixed(2) - 0;
            return {
                px1: px1,
                px2: px2
            }
        }

        // px---mm
        function ThreeHundredDpiToMm(attr1, attr2) {
            var mm1 = parseFloat((attr1 / vm.windowDpiW * 25.4 - 0)).toFixed(2) - 0;
            var mm2 = parseFloat((attr2 / vm.windowDpiH * 25.4 - 0)).toFixed(2) - 0;
            return {
                mm1: mm1,
                mm2: mm2
            }
        }

        $scope.selectPageo = function (e, $index, page) {
            if (page.unit == 'mm') {
                $scope.dpi = vm.dpiChoose[0];
            } else {
                $scope.dpi = vm.dpiChoose[1];
            }
        };


        /* ==========================================================================
         鼠标滑过事件
         ========================================================================== */

        $scope.isAlignHover = false;
        $scope.isDistriHover = false;
        var toobarFlag1 = true;
        var toobarFlag2 = true;
        $scope.toobarAlignment = function () {
            $(".space-slider").addClass('hide1');
            if (toobarFlag1) {
                $scope.isAlignHover = true;
                $scope.isDistriHover = false;
                toobarFlag1 = false;
                toobarFlag2 = true;
            } else {
                $scope.isAlignHover = false;
                toobarFlag1 = true;
            }
        }

        $scope.toolbarDistribution = function () {
            $(".space-slider").addClass('hide1');
            if (toobarFlag2) {
                $scope.isAlignHover = false;
                $scope.isDistriHover = true;
                toobarFlag2 = false;
                toobarFlag1 = true;
            } else {
                $scope.isDistriHover = false;
                toobarFlag2 = true;
            }
        }

        $scope.boxAlignment = function (attr) {
            var levels = $scope.currentPage.levels;
            var all = getAllCtrlKeyArray();

            if (attr == 'left') {
                all.sort(compare('offsetXM', true));
            } else if (attr == 'right') {
                all.sort(compare('offsetXM', false));
                all.sort(compare('widthM', false));
            } else if (attr == 'top') {
                all.sort(compare('offsetYM', true));
            } else if (attr == 'bottom') {
                all.sort(compare('offsetYM', false));
                all.sort(compare('heightM', false));
            }
            if(attr == 'vertical' && all.length>0){
                var verticalY = horizontalAndVerticalCenter(attr, all);
            }
            if(attr == 'standard' && all.length>0){
                var standardX = horizontalAndVerticalCenter(attr, all);
            }
            for (var i = 0; i < all.length; i++) {
                if (attr == 'left') {
                    all[i].geometry.x = all[0].geometry.x;

                } else if (attr == 'right') {
                    all[i].geometry.x = all[0].geometry.x + all[0].geometry.width - all[i].geometry.width;

                } else if (attr == 'top') {
                    all[i].geometry.y = all[0].geometry.y;

                } else if (attr == 'bottom') {
                    all[i].geometry.y = all[0].geometry.y + all[0].geometry.height - all[i].geometry.height;

                } else if (attr == 'vertical') {
                    all[i].geometry.y = verticalY - all[i].geometry.height / 2;
                } else {
                    all[i].geometry.x = standardX - all[i].geometry.width / 2;
                }


                if (all[i].current == 'imagebox') {
                    levels.level[all[i].levelI].imagebox = all[i];
                } else if (all[i].current == 'textbox') {
                    levels.level[all[i].levelI].textbox = all[i];
                } else {
                    levels.level[all[i].levelI].imageart = all[i];
                }
            }
            // moveTogetherEnd();
            updateHistorys();
            resetCtrlKeyArray();

        }

        $scope.boxDistribution = function (attr) {
            var all = getAllCtrlKeyArray();

            if (all.length < 3) {
                return
            }

            // 水平分布
            if (attr == 'standard') {
                setAllCtrlKeyArray(all, 'x', 'offsetXM');
            } else {
                // 垂直分布
                setAllCtrlKeyArray(all, 'y', 'offsetYM');

            }
            resetCtrlKeyArray();
            updateHistorys();
        }

        function setAllCtrlKeyArray(all, attr1, attr2) {
            var levels = $scope.currentPage.levels;

            var len = all.length - 1;
            all.sort(compare(attr2, true));
            var spacingPx = (all[len].geometry[attr1] - all[0].geometry[attr1]) / len;
            var spacingMm = (all[len][attr2] - all[0][attr2]) / len;
            for (var i = 1; i < len; i++) {
                all[i].geometry[attr1] = all[0].geometry[attr1] + spacingPx * i;
                all[i][attr2] = all[0][attr2] + spacingMm * i;

                if (all[i].current == 'imagebox') {
                    levels.level[all[i].levelI].imagebox = all[i];
                } else if (all[i].current == 'textbox') {
                    levels.level[all[i].levelI].textbox = all[i];
                } else {
                    levels.level[all[i].levelI].imageart = all[i];
                }
            }
        }
        //移动画布
        vm.moveCanvasView = false;
        canvasInfo();
        function canvasInfo(){
            vm.moveCanvasObj = {
                x:0,
                y:0
            };
        }
        $scope.moveCanvas = function(e){
            if(!vm.moveCanvasView){
                return
            }
            var _moveCanvasObj = angular.copy(vm.moveCanvasObj);
            moveCanvas(e, function (x, y) {
                vm.moveCanvasObj.x = _moveCanvasObj.x + x;
                vm.moveCanvasObj.y = _moveCanvasObj.y + y;
                $scope.$apply();
            })
        }

        /**
         *
         * 自定义元素右键菜单样式  复制、剪切、删除
         *
         */
        document.onkeyup = function (event) {
            if(event.keyCode == 32){
                $timeout(function () {
                    vm.moveCanvasView = false;
                })
                // console.log('空格键抬起');
            }
        }
        document.onkeydown = function (event) {
            if(event.keyCode == 32){
                $timeout(function () {
                    vm.moveCanvasView = true;
                })
                // console.log('空格键按下');
                return false
            }
            if (event.keyCode == 27) {
                //ESC
            }
            // 删除
            if (event.keyCode == 46) {
                $scope.deleteBox();
                $scope.$digest();
            }
            // ctrl+c
            if ((event.ctrlKey == true || event.metaKey == true) && event.keyCode == 67) {
                if(event.target.nodeName=='TEXTAREA'){
                    return
                }
                // localStorage.setItem('singleArray', JSON.stringify(vm.singleArray));

                // var levels = $scope.currentPage.levels;

                // for (var x = 0; x < levels.level.length; x++) {
                //     var l = levels.level[x];
                //     if (l.imagebox && l.imagebox.current) {
                //         l.imagebox.ctrlKey = false;
                //     }
                //     if (l.imageart && l.imageart.current) {
                //         l.imageart.ctrlKey = false;
                //     }
                //     if (l.textbox && l.textbox.current) {
                //         l.textbox.ctrlKey = false;
                //     }
                //     if (l.textdocument && l.textdocument.current) {
                //         l.textdocument.ctrlKey = false;
                //     }
                // }
                if(vm.CtrlKeyArray.imageboxes.length||vm.CtrlKeyArray.imagearts.length||vm.CtrlKeyArray.textboxes.length){
                    vm.copyArray = angular.copy(vm.CtrlKeyArray);
                }else{
                    var box = angular.copy($scope.selectBox)
                    if (box.current == 'imagebox') {
                        vm.CtrlKeyArray.imageboxes.push(box)
                    }
                    if (box.current == 'textbox') {
                        vm.CtrlKeyArray.textboxes.push(box)
                    }
                    if (box.current == 'imageart') {
                        vm.CtrlKeyArray.imagearts.push(box)
                    }
                    vm.copyArray = angular.copy(vm.CtrlKeyArray);
                }
            }
            //ctrl+v
            if ((event.ctrlKey == true || event.metaKey == true) && event.keyCode == 86) {
                if(event.target.nodeName=='TEXTAREA'){
                    return
                }
                var currentPage = $scope.currentPage;
                if(!vm.copyArray){
                    return
                }
                if(vm.copyArray.imageboxes.length||vm.copyArray.imagearts.length||vm.copyArray.textboxes.length){
                    copyTogether(currentPage);
                }
                // console.log($scope.currentPage.levels.level);
                $scope.$digest();
            }
            // ctrl+x
            if ((event.ctrlKey == true || event.metaKey == true) && event.keyCode == 88) {
                $scope.deleteBox();
                $scope.$digest();
            }

            if (event.target.tagName == 'INPUT') {
                return
            }
            // 上
            if (event.keyCode == 38) {
                keycodeArrow(38);
            }
            // 下
            if (event.keyCode == 40) {
                keycodeArrow(40);

            }
            // 左
            if (event.keyCode == 37) {
                keycodeArrow(37);
            }
            // 右
            if (event.keyCode == 39) {
                keycodeArrow(39);
            }
            event.returnvalue = false;
        };

        function copyTogether(currentPage){
            angular.forEach(vm.copyArray.imageboxes, function (imagebox, i) {
                // console.log("i: "+i)
                if(imagebox&&imagebox.geometry){
                    // 当前页所有占位框
                    var box = angular.copy(imagebox);
                    var currentLevel = addLevel(currentPage);
                    currentLevel.imagebox = copyDetail2(box);
                    updateHistorys();
                }

            })

            angular.forEach(vm.copyArray.imagearts, function (imageart, i) {
                if(imageart&&imageart.geometry){
                    // 当前页所有素材
                    var box = angular.copy(imageart);
                    var currentLevel = addLevel(currentPage);
                    currentLevel.imageart = copyDetail2(box);
                    updateHistorys();
                }

            })

            angular.forEach(vm.copyArray.textboxes, function (textbox, i) {
                if(textbox&&textbox.geometry){
                    // 当前页所有文字
                    var box = angular.copy(textbox);
                    var currentLevel = addLevel(currentPage);
                    currentLevel.textbox = copyDetail2(box);
                    updateHistorys();
                }

            })
        }

        function copyDetail2(box) {
            box.levelI = $scope.currentPage.levels.level.length-1;
            box.lock = false;
            var repeatEle = false;
            $scope.currentPage.levels.level.forEach(function(item){
                if(item.imagebox && item.imagebox.geometry.x == box.geometry.x && item.imagebox.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.imageart && item.imageart.geometry.x == box.geometry.x && item.imageart.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.textbox && item.textbox.geometry.x == box.geometry.x && item.textbox.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.code && item.code.geometry.x == box.geometry.x && item.code.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
            })
            if(!repeatEle){
                return box
            }
            if (box.current == 'imagebox') {
                vm.copyArray.imageboxes.forEach(function(imagebox){
                    if(imagebox && imagebox.geometry.x == box.geometry.x && imagebox.geometry.y == box.geometry.y){
                        var x = PxTurnMm(imagebox.geometry.x) + 1;
                        var y = PxTurnMm(imagebox.geometry.y) + 1;
                        imagebox.geometry.x = MmTurnPx(x);
                        imagebox.geometry.y = MmTurnPx(y);
                    }
                })
            }
            if (box.current == 'imageart') {
                vm.copyArray.imagearts.forEach(function(imageart){
                    if(imageart && imageart.geometry.x == box.geometry.x && imageart.geometry.y == box.geometry.y){
                        var x = PxTurnMm(imageart.geometry.x) + 1;
                        var y = PxTurnMm(imageart.geometry.y) + 1;
                        imageart.geometry.x = MmTurnPx(x);
                        imageart.geometry.y = MmTurnPx(y);
                    }
                })
            }
            if(box.current == 'textbox'){
                vm.copyArray.textboxes.forEach(function(textbox){
                    if(textbox && textbox.geometry.x == box.geometry.x && textbox.geometry.y == box.geometry.y){
                        var x = PxTurnMm(textbox.geometry.x) + 1;
                        var y = PxTurnMm(textbox.geometry.y) + 1;
                        textbox.geometry.x = MmTurnPx(x);
                        textbox.geometry.y = MmTurnPx(y);
                    }
                })
            }
            var x = PxTurnMm(box.geometry.x) + 1;
            var y = PxTurnMm(box.geometry.y) + 1;
            box.geometry.x = MmTurnPx(x);
            box.geometry.y = MmTurnPx(y);
            // 判断边界
            if (box.geometry.y < 0) {
                box.geometry.y = 0;
            }
            if (box.geometry.y > $scope.currentPage.mediabox.height - box.geometry.height) {
                box.geometry.y = $scope.currentPage.mediabox.height - box.geometry.height;
            }
            if (box.geometry.x < 0) {
                box.geometry.x = 0;
            }
            if (box.geometry.x > $scope.currentPage.mediabox.width - box.geometry.width) {
                box.geometry.x = $scope.currentPage.mediabox.width - box.geometry.width;
            }
            return box
        }

        function keycodeArrow(keyCode) {
            var temp = JSON.parse(localStorage.getItem('singleArray'));
            var levels = $scope.currentPage.levels;
            var level = levels.level[$scope.template.levelI];
            var currentPage = $scope.template.pages.page[$scope.template.pageI];

            var all = getAllCtrlKeyArray(1);

            var len = all.length;
            var box = [], value = {};
            if (!len) {
                if ($scope.selectBox.current == 'imagebox') {
                    box = level.imagebox;
                    value = $scope.pageValue.imagebox;
                }
                if ($scope.selectBox.current == 'textbox') {
                    box = level.textbox;
                    value = $scope.pageValue.textbox;
                }
                if ($scope.selectBox.current == 'imageart') {
                    box = level.imageart;
                    value = $scope.pageValue.imageart;
                }
                if ($scope.selectBox.current == 'textdocument') {
                    box = level.textdocument;
                    value = $scope.pageValue.textdocument;
                }
                if (box.lock) {
                    return
                }

                var maxHeightPx = parseFloat(currentPage.mediabox.height - box.geometry.height).toFixed(2) - 0;
                var maxWidthPx = parseFloat(currentPage.mediabox.width - box.geometry.width).toFixed(2) - 0;
                // 上
                if (keyCode == 38) {
                    if (vm.unit == 'px') {
                        if (value.y >= 1) {
                            value.y--;
                            box.geometry.y = unitTransform(value.y / vm.windowDpiH * 25.4);
                        }
                    } else {
                        if (value.y >= 1) {
                            value.y -= 0.2;
                            box.geometry.y = unitTransform(value.y);
                        }
                    }
                }
                // 下
                if (keyCode == 40) {
                    if (vm.unit == 'px') {
                        if (value.y < maxHeightPx) {
                            value.y++;
                            box.geometry.y = unitTransform(value.y / vm.windowDpiH * 25.4);
                        }
                    } else {
                        if (value.y < unitTransformPx(currentPage.mediabox.height - box.geometry.height)) {
                            value.y += 0.2;
                            box.geometry.y = unitTransform(value.y);
                        }
                    }
                }
                // 左
                if (keyCode == 37) {
                    if (vm.unit == 'px') {
                        if (value.x >= 1) {
                            value.x--;
                            box.geometry.x = unitTransform(value.x / vm.windowDpiW * 25.4);
                        }
                    } else {
                        if (value.x >= 1) {
                            value.x -= 0.2;
                            box.geometry.x = unitTransform(value.x);
                        }
                    }
                }
                // 右
                if (keyCode == 39) {
                    if (vm.unit == 'px') {
                        if (value.x >= maxWidthPx) {
                            value.x++;
                            box.geometry.x = unitTransform(value.x / vm.windowDpiW * 25.4);
                        }
                    } else {
                        if (value.x < unitTransformPx(maxWidthPx)) {
                            value.x += 0.2;
                            box.geometry.x = unitTransform(value.x);
                        }
                    }
                }
                updateHistorys();
                $scope.$apply();
            } else {
                for (var i = len - 1; i >= 0; i--) {
                    if (!all[i]) {
                        continue;
                    }
                    var box = [];
                    if (all[i].current == 'imagebox') {
                        box = levels.level[all[i].levelI].imagebox;
                    } else if (all[i].current == 'textbox') {
                        box = levels.level[all[i].levelI].textbox;
                    } else if (all[i].current == 'imageart') {
                        box = levels.level[all[i].levelI].imageart;
                    }
                    if (box.lock) {
                        return
                    }
                    var maxHeightPx = parseFloat(currentPage.mediabox.height - box.geometry.height).toFixed(2) - 0;
                    var maxWidthPx = parseFloat(currentPage.mediabox.width - box.geometry.width).toFixed(2) - 0;
                    if (keyCode == 38) {
                        if (vm.unit == 'px') {
                            if (box.geometry.y >= 1) {
                                box.geometry.y--;
                            }
                        } else {
                            var y = unitTransformPx(box.geometry.y);
                            if (y >= 1) {
                                y -= 0.2;
                                box.geometry.y = unitTransform(y);
                            }
                        }
                    }
                    // 下
                    if (keyCode == 40) {
                        if (vm.unit == 'px') {
                            if (box.geometry.y < maxHeightPx) {
                                box.geometry.y++;
                            }
                        } else {
                            var y = unitTransformPx(box.geometry.y);
                            if (y < unitTransformPx(maxHeightPx)) {
                                y += 0.2;
                                box.geometry.y = unitTransform(y);
                            }
                        }
                    }
                    // 左
                    if (keyCode == 37) {
                        if (vm.unit == 'px') {
                            if (box.geometry.x >= 1) {
                                box.geometry.x--;
                            }
                        } else {
                            var x = unitTransformPx(box.geometry.x)
                            if (x >= 1) {
                                x -= 0.2;
                                box.geometry.x = unitTransform(x);
                            }
                        }
                    }
                    // 右
                    if (keyCode == 39) {
                        if (vm.unit == 'px') {
                            if (box.geometry.x < maxWidthPx) {
                                box.geometry.x++;
                            }
                        } else {
                            var x = unitTransformPx(box.geometry.x);
                            if (x < unitTransformPx(maxWidthPx)) {
                                x += 0.2;
                                box.geometry.x = unitTransform(x);
                            }
                        }
                    }
                    updateHistorys();
                    $scope.$apply();
                }
            }


            $scope.currentPage = currentPage;
        }

        //复制
        $scope.copying = function () {
            var temp = JSON.parse(localStorage.getItem('singleArray'));
            if (vm.singleArray) {
                temp = vm.singleArray;
            }
            var levels = $scope.currentPage.levels;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var all = getAllCtrlKeyArray(1);
            var len = all.length;
            // 单选状态
            if (!len) {
                var currentLevel = addLevel(currentPage);
                if ($scope.selectBox.current == 'imagebox') {
                    var box = angular.copy(temp.imagebox);
                    currentLevel.imagebox = copyDetail(box, temp.imagebox);
                    vm.singleArray.imagebox = currentLevel.imagebox;
                }
                if ($scope.selectBox.current == 'textbox') {
                    var box = angular.copy(temp.textbox);
                    currentLevel.textbox = copyDetail(box, temp.textbox);
                    vm.singleArray.textbox = currentLevel.textbox;

                }
                if ($scope.selectBox.current == 'imageart') {
                    var box = angular.copy(temp.imageart);
                    currentLevel.imageart = copyDetail(box, temp.imageart);
                    vm.singleArray.imageart = currentLevel.imageart;

                }
                if ($scope.selectBox.current == 'textdocument') {
                    var box = angular.copy(temp.textdocument);
                    currentLevel.textdocument = copyDetail(box, temp.textdocument);
                    vm.singleArray.textdocument = currentLevel.textdocument;

                }
                $scope.template.levelI = levels.level.length - 1;
            } else {
                // ctrl+点击
                for (var i = len - 1; i >= 0; i--) {
                    if (!all[i]) {
                        continue;
                    }
                    var box = angular.copy(all[i]);
                    var currentLevel = addLevel(currentPage);
                    if (all[i].current == 'imagebox') {
                        if (levels.level[box.levelI]) {
                            currentLevel.imagebox = copyDetail(box, all[i]);
                        } else {
                            box.levelI = levels.length;
                            currentLevel.imagebox = box;
                        }
                    } else if (all[i].current == 'textbox') {
                        if (levels.level[box.levelI]) {
                            currentLevel.textbox = copyDetail(box, all[i]);
                        } else {
                            box.index = levels.length;
                            currentLevel.textbox = box;
                        }
                    } else if (all[i].current == 'imageart') {
                        if (levels.level[box.levelI]) {
                            currentLevel.imageart = copyDetail(box, all[i]);
                        } else {
                            box.index = levels.length;
                            currentLevel.imageart = box;
                        }
                    } else if (all[i].current == 'textdocument') {
                        // console.log("???")
                        if (levels.level[box.levelI]) {
                            currentLevel.textdocument = copyDetail(box, all[i]);
                        } else {
                            box.index = levels.length;
                            currentLevel.textdocument = box;
                        }
                    }
                }
                $scope.clearCtrlKey();
                localStorage.removeItem('singleArray');
                $scope.selectBox = {}
            }
            getImageboxLen();
            getPagePx();
            updateHistorys();
        };

        // 剪切
        $scope.cuting = function () {
            var temp = JSON.parse(localStorage.getItem('singleArray'));

            var levels = $scope.currentPage.levels;
            var level = levels.level[$scope.template.levelI];
            var currentPage = $scope.template.pages.page[$scope.template.pageI]
            // 当前页所有占位框
            var currentImages = currentPage.imageboxes.imagebox;
            // 当前页所有文本
            var currentTexts = currentPage.textboxes.textbox;
            // 当前页所有素材
            var currentArts = currentPage.imagearts.imageart;
            var all = getAllCtrlKeyArray(1);

            var len = all.length;
            // 单选状态
            if (!len) {
                if ($scope.selectBox.current == 'imagebox') {
                    currentImages.splice($scope.template.imgI, 1);
                    // $scope.template.imgI = currentImages.length - 1;
                }
                if ($scope.selectBox.current == 'textbox') {
                    currentTexts.splice($scope.template.textI, 1);
                    // $scope.template.textI = currentTexts.length - 1;
                }
                if ($scope.selectBox.current == 'imageart') {
                    currentArts.splice($scope.template.artI, 1);
                    // $scope.template.artI = currentArts.length - 1;
                }
                localStorage.setItem('singleArray', JSON.stringify(vm.singleArray));
            } else {
                // ctrl+点击
                for (var i = len - 1; i >= 0; i--) {
                    if (!all[i]) {
                        continue;
                    }
                    var box = angular.copy(all[i]);
                    if (all[i].current == 'imagebox') {
                        currentImages.splice(box.index, 1);
                    } else if (all[i].current == 'textbox') {
                        currentTexts.splice(box.index, 1);
                    } else if (all[i].current == 'imageart') {
                        currentArts.splice(box.index, 1);
                    }
                }
            }
        }

        function copyDetail(box, currentbox) {
            box.geometry.x = unitTransform(12) + currentbox.geometry.x;
            box.geometry.y = unitTransform(12) + currentbox.geometry.y;
            box.levelI = $scope.currentPage.levels.level.length - 1;
            // 判断边界
            if (box.geometry.y < 0) {
                box.geometry.y = 0;
            }
            if (box.geometry.y > $scope.currentPage.mediabox.height - box.geometry.height) {
                box.geometry.y = $scope.currentPage.mediabox.height - box.geometry.height;
            }
            if (box.geometry.x < 0) {
                box.geometry.x = 0;
            }
            if (box.geometry.x > $scope.currentPage.mediabox.width - box.geometry.width) {
                box.geometry.x = $scope.currentPage.mediabox.width - box.geometry.width;
            }
            return box;
        }

        /**
         * 上一张，下一张
         */
        $scope.pageDirection = function (flag) {
            $scope.lastPage = $scope.template.pages.page[$scope.template.pageI];
            setTimeout(function () {
                checkPageCover();
            })
            // 上一张
            if (flag) {
                $scope.template.pageI = $scope.template.pageI - 1;
            } else {
                $scope.template.pageI = $scope.template.pageI + 1;
            }
            // $(".bg-image").attr('src', '');
            $scope.RightPanel = 'page';
            isCanLeftAndRightPage();
            loadAttribute();

            var w = 0;
            for (var i = 0; i < $scope.template.pageI - 3; i++) {
                var page = $scope.template.pages.page[i];
                w += page.width * 98 / page.height + 16;
            }
            $(".nav-ul").scrollLeft(w);
        };

        //点击页面显示左右方向
        function isCanLeftAndRightPage() {
            $scope.template.levelI = 0;
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];

            setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, $scope.template.pageI);

            if ($scope.template.pages.page.length == 1) {
                $scope.visibleLeft = false;
                $scope.visibleRight = false;
            } else if ($scope.template.pageI == 0) {
                $scope.visibleLeft = false;
                $scope.visibleRight = true;
            } else if ($scope.template.pageI == $scope.template.pages.page.length - 1) {
                $scope.visibleLeft = true;
                $scope.visibleRight = false;
            } else {
                $scope.visibleLeft = true;
                $scope.visibleRight = true;
            }
            getImageboxLen();
        }

        /**
         * 1.无封面则直接生成
         * 2.点击事件中先判断一下开始时间是否为空，为空时就把当前时间赋给开始时间（说明是第一次点击）
         * 否则就是第二次点击，把当前时间赋值给结束时间。
         * 第二次点击时就可以用结束时间减去开始时间，得到两次点击的时间间隔。
         */
        var first = 0;
        var second = 0;

        function checkPageCover() {
            if (!$scope.lastPage.pagecover || $scope.lastPage.pagecover && $scope.lastPage.pagecover.identifier == null) {
                // var nodes = document.getElementsByClassName('pageNode');
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage);
                oldPage = angular.copy($scope.template.pages.page[$scope.template.pageI])
                return
            }

            var a = JSON.stringify(oldPage);
            var b = JSON.stringify($scope.lastPage);
            // console.log("是否相等："+(a==b))
            // console.log(oldPage)
            // console.log($scope.lastPage)
            if(a!=b){
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage);
            }

            oldPage = angular.copy($scope.template.pages.page[$scope.template.pageI])
        }

        /**
         * 放大缩小及还原
         */
        $scope.changeShirnk = function (zoomIn) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            var number = 0.02;
            var marTop = 0;

            // 还原
            if (zoomIn == 0) {
                currentPage.scale = 1;
                // $(".page-item").css({'margin': 'auto'});
            }

            // 缩小
            if (zoomIn == 1) {
                if (currentPage.scale <= 0.5) {
                    return;
                }
                currentPage.scale = Math.abs(currentPage.scale - number).toFixed(2);
                // setTimeout(function () {
                //     marTop = parseInt($(".page-item").css('margin-top'));
                //     if (marTop >= 0) {
                //         $(".page-item").css({'margin': 'auto'});
                //     } else {
                //         $(".page-item").css({'margin': '0 auto'});
                //     }
                // }, 10);
            }

            // 放大
            if (zoomIn == 2) {
                var scale = Math.abs(currentPage.scale - 0 + number).toFixed(2);
                //限制放大倍数
                if (scale > 1.5) {
                    return
                }
                currentPage.scale = scale;
                // setTimeout(function () {
                //     marTop = parseInt($(".page-item").css('margin-top'));
                //     if (marTop < 0) {
                //         $(".page-item").css({'margin': '0 auto'});
                //     }
                // }, 10);
            }
            // updateHistorys();
            $scope.currentPage = currentPage;
        }

        //删除模板
        $scope.deletePage = function (index, e) {
            fn(e)
            if ($scope.template.pages.page.length == 1 || $scope.template.pageI == null || $scope.template.pageI < 0) {
                return;
            }
            if (index == null && ($scope.template.pageI == null || $scope.template.pageI < 0)) {
                return;
            }


            $scope.template.pages.page.splice(index, 1);
            var len = $scope.template.pages.page.length;
            if (len > 1) {
                $scope.template.pages.page[len - 1].spread = false;
            }
            if (index == 0) {
                $scope.template.pageI = 0;
            }

            if (index == len && $scope.template.pageI == index) {
                $scope.template.pageI = index - 1;
            }
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];

            setUlWidth();
        };

        // 窗口宽高变化
        window.onresize = function () {
            if ($scope.currentPage && $scope.currentPage.trimbox) {
                setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, $scope.template.pageI);
                $scope.$digest();
            }
        };

        function compare(property, attr) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                if (attr) {
                    return value1 - value2;
                }
                else {
                    return value2 - value1;
                }
            }
        }

        function getAllCtrlKeyArray() {
            var imageboxes = vm.CtrlKeyArray.imageboxes,
                textboxes = vm.CtrlKeyArray.textboxes,
                imagearts = vm.CtrlKeyArray.imagearts,
                all = [];
            var barcode = vm.CtrlKeyArray.barcode;
            var qrcode = vm.CtrlKeyArray.qrcode;
            //选中的占位框
            for (var i = 0; i < imageboxes.length; i++) {
                if (imageboxes[i]) {
                    all.push(imageboxes[i]);
                }
            }
            //选中的文本框
            for (var i = 0; i < textboxes.length; i++) {
                if (textboxes[i]) {
                    all.push(textboxes[i]);
                }
            }
            //选中的素材
            for (var i = 0; i < imagearts.length; i++) {
                if (imagearts[i]) {
                    all.push(imagearts[i]);
                }
            }
            //选中的条码
            for (var i = 0; i < barcode.length; i++) {
                all.push(barcode[i]);
            }
            //选中的二维码
            for (var i = 0; i < qrcode.length; i++) {
                all.push(qrcode[i]);
            }
            return all;
        }

        function resetCtrlKeyArray() {
            var levels = $scope.currentPage.levels;
            if(!levels.level){
                return
            }
            for (var x = 0; x < levels.level.length; x++) {
                var l = levels.level[x];
                if (l.imagebox && l.imagebox.current) {
                    l.imagebox.ctrlKey = false;
                }
                if (l.imageart && l.imageart.current) {
                    l.imageart.ctrlKey = false;
                }
                if (l.textbox && l.textbox.current) {
                    l.textbox.ctrlKey = false;
                }
                if (l.textdocument && l.textdocument.current) {
                    l.textdocument.ctrlKey = false;
                }
            }

            vm.CtrlKeyArray.imageboxes = [];
            vm.CtrlKeyArray.textboxes = [];
            vm.CtrlKeyArray.imagearts = [];
            vm.CtrlKeyArray.barcode = [];
            vm.CtrlKeyArray.qrcode = [];
        }

        $scope.clearCtrlKey = function (flag) {
            // if ($scope.currentPage.scene) {
            //     getPagePx();
            //     return
            // }
            // 点击页面内容
            if (flag) {
                getPagePx();
                $scope.shezhi = false;
                // 清空选中
                $scope.selectBox.current = 'page';
            }
            $scope.isAlignHover = false;
            $scope.isDistriHover = false;
            toobarFlag1 = true;
            toobarFlag2 = true;

            toolbarStatus();

            var levels = $scope.currentPage.levels;

            angular.forEach(levels.level, function (l, x) {
                if (l.imagebox && l.imagebox.current) {
                    l.imagebox.ctrlKey = false;
                }
                if (l.imageart && l.imageart.current) {
                    l.imageart.ctrlKey = false;
                }
                if (l.textbox && l.textbox.current) {
                    l.textbox.ctrlKey = false;
                }
                if (l.textdocument && l.textdocument.current) {
                    l.textdocument.ctrlKey = false;
                }
            })

            vm.CtrlKeyArray.imageboxes = [];
            vm.CtrlKeyArray.textboxes = [];
            vm.CtrlKeyArray.textdocument = [];
            vm.CtrlKeyArray.imagearts = [];
            vm.CtrlKeyArray.barcode = [];
            vm.CtrlKeyArray.qrcode = [];
        }

        function getPagePx() {
            $scope.RightPanel = 'page';
            loadAttribute();
        }

        $scope.editSize = function (attr) {
            var temp = $scope.template.pages.page[$scope.template.pageI];
            vm.unit = 'mm';
            unit();
            vm.page.editbox = angular.copy(temp.editbox);
            vm.page.editbox = {
                x: PxTurnMm(vm.page.editbox.x),
                y: PxTurnMm(vm.page.editbox.y),
                width: PxTurnMm(vm.page.editbox.width),
                height: PxTurnMm(vm.page.editbox.height),
            };
            vm.page.editboxs = angular.copy(temp.editboxes.editbox);
            vm.page.editboxs.forEach(function (editbox) {
                editbox.x= PxTurnMm(editbox.x);
                editbox.y= PxTurnMm(editbox.y);
                editbox.width= PxTurnMm(editbox.width);
                editbox.height= PxTurnMm(editbox.height);
            })
            vm.page.pageSize = angular.copy($scope.pageValue.mediabox);
            oldSizePx = angular.copy($scope.currentPage.bloodPx);
            oldSizeMm = angular.copy($scope.currentPage.blood);
            vm.page.pageBloodB = oldSizeMm.bottom - 0;
            vm.page.pageBloodL = oldSizeMm.left - 0;
            vm.page.pageBloodR = oldSizeMm.right - 0;
            vm.page.pageBloodT = oldSizeMm.top - 0;
            vm.page.pageSizeUnit = "mm";

            createModal('app/entities/page/page-edit.html', 'PageEditController', attr).result.then(
                function (data) {
                    // console.log(data)
                    vm.page = data;
                    vm.unit = vm.page.dpi.data;
                    var temp = $scope.template.pages.page;
                    var i = $scope.template.pageI;

                    if (vm.unit == 'px') {
                        $scope.pageValue.mediabox = data.pageSize;
                        temp[i].editbox = data.editbox;
                        temp[i].editboxes = {
                            editbox:data.editboxs
                        };
                        temp[i].mediabox.width = data.pageSize.width;
                        temp[i].mediabox.height = data.pageSize.height;
                        temp[i].trimbox.x = data.pageBloodL;
                        temp[i].trimbox.y = data.pageBloodT;
                    }
                    if (vm.unit == 'mm') {
                        $scope.pageValue.mediabox = data.pageSize;
                        temp[i].mediabox.width = MmTurnPx(data.pageSize.width);
                        temp[i].mediabox.height = MmTurnPx(data.pageSize.height);
                        temp[i].trimbox.x = MmTurnPx(data.pageBloodL);
                        temp[i].trimbox.y = MmTurnPx(data.pageBloodT);
                        temp[i].editbox = getGeometry(data.editbox);
                        temp[i].editboxes = {
                            editbox:data.editboxs
                        };
                        temp[i].editboxes.editbox.forEach(function (editbox) {
                            editbox.width = MmTurnPx(editbox.width);
                            editbox.height = MmTurnPx(editbox.height);
                            editbox.x = MmTurnPx(editbox.x);
                            editbox.y = MmTurnPx(editbox.y);
                        })
                    }
                    if (vm.unit == 'in') {
                        $scope.pageValue.mediabox = data.pageSize;
                        temp[i].mediabox.width = InTurnPx(data.pageSize.width);
                        temp[i].mediabox.height = InTurnPx(data.pageSize.height);
                        temp[i].trimbox.x = InTurnPx(data.pageBloodL);
                        temp[i].trimbox.y = InTurnPx(data.pageBloodT);
                        temp[i].editbox = {
                            height: InTurnPx(temp[i].editbox.height),
                            width: InTurnPx(temp[i].editbox.width),
                            x: InTurnPx(temp[i].editbox.x),
                            y: InTurnPx(temp[i].editbox.y)
                        };
                        temp[i].editboxes = {
                            editbox:data.editboxs
                        };
                        temp[i].editboxes.editbox.forEach(function (editbox) {
                            editbox.width = InTurnPx(editbox.width);
                            editbox.height = InTurnPx(editbox.height);
                            editbox.x = InTurnPx(editbox.x);
                            editbox.y = InTurnPx(editbox.y);
                        })
                    }
                    getBloodSize(temp[i],i);
                    setUlWidth();
                    resetBackground();
                    $scope.currentPage = temp[i];
                    // console.log(temp)
                });
        }

        var createModal = function (url, controller, attr) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    pages: {page: vm.page, sizeSet: attr, token: vm.token, domain: vm.url},
                    documentpics: {arr: $scope.template.documentpics.documentpic, showPic: vm.showPic},
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            });
        };


        //撤销，重做按钮事件
        $scope.rollback = function (forward) {
            var i = $scope.template.pageI;
            if ((!forward && !$scope.pageHistory[i].currentHistoryIndex)) {
                return;
            }

            if (forward) {
                $scope.template.pages.page[i] = {
                    bgImg: '',
                    background: {resource: {}},
                    barcode: {},
                    qrcode: {},
                    scale: 1,
                    levels: {},
                    width: $scope.template.pages.page[i].width,
                    height: $scope.template.pages.page[i].height,
                    spread: $scope.template.pages.page[i].spread,
                    backup: $scope.template.pages.page[i].backup,
                    unreplace: $scope.template.pages.page[i].unreplace,
                    editbox: getGeometry($scope.template.pages.page[i].editbox),
                    editboxM: angular.copy($scope.template.pages.page[i].editboxM),
                    blood: angular.copy($scope.template.pages.page[i].blood),
                    bloodPx: angular.copy($scope.template.pages.page[i].bloodPx),
                    isScene: $scope.template.pages.page[i].isScene,
                    seq: $scope.template.pages.page[i].seq,
                    trimbox: $scope.template.pages.page[i].trimbox,
                    mediabox: $scope.template.pages.page[i].mediabox,
                    uuid: $scope.template.pages.page[i].uuid,
                    pagecover: $scope.template.pages.page[i].pagecover,
                    hasBgImg: false,
                    unit: 'mm'
                };
            } else {
                $scope.pageHistory[i].currentHistoryIndex = $scope.pageHistory[i].currentHistoryIndex - 1;
                $scope.pageHistory[i].history.pop();
                $scope.template.pages.page[i] = angular.copy($scope.pageHistory[i].history[$scope.pageHistory[i].currentHistoryIndex]);
            }
            $scope.RightPanel = 'page';
            $scope.currentPage = $scope.template.pages.page[i];
            vm.unit = 'mm';
            $scope.pageValue.mediabox = {
                width: unitTransformPx($scope.currentPage.mediabox.width),
                height: unitTransformPx($scope.currentPage.mediabox.height)
            }
            setOriginalWaH($scope.currentPage.trimbox.width, $scope.currentPage.trimbox.height, i);
            setUlWidth();
            angular.forEach($scope.template.pages.page, function (item, index) {
                if (!item.bgImg) {
                    $('#bgImg' + index).attr('src', '');            //添加src
                }
            });

            $scope.clearCtrlKey();
        };
        // 阴影设置
        vm.isShadowSet = false;
        $scope.shadowSet = function(attr){
            toolbarStatus();
            vm.isShadowSet = attr;
        }
        // 透明度
        $scope.transparencySelect = function (attr) {
            toolbarStatus();
            var current = $scope.selectBox.current;
            if (current == 'imageart' || current == 'textbox' || current == 'imagebox') {
                vm.isTransp = attr;
            }
            $scope.template.transparency = $scope.selectBox.transparency * 100;
            $(elem).val($scope.selectBox.transparency);
        }

        $scope.coverageSelect = function (attr) {
            toolbarStatus();
            var current = $scope.selectBox.current;
            if (current != 'page') {
                vm.isCoverage = attr;
            }
        }

        /**
         * 图层
         * @param attr----up,down,top,bottom
         */
        $scope.coverageMove = function (attr) {
            var levels = $scope.currentPage.levels.level;
            if (levels.length == 1) {
                return
            }

            var levelI = $scope.template.levelI;
            // 上移一层
            if (attr == 'up') {
                if (levelI == levels.length - 1) {
                    MessageService.error("已在最上层")
                    return
                }
                var seq = levels[levelI].seq;
                levels[levelI].seq = levels[levelI + 1].seq;
                levels[levelI + 1].seq = seq;
            }

            // 下移一层
            if (attr == 'down') {
                if (levelI == 0) {
                    MessageService.error("已在最下层");
                    return
                }
                var seq = levels[levelI].seq;
                levels[levelI].seq = levels[levelI - 1].seq;
                levels[levelI - 1].seq = seq;
            }

            // 置顶
            if (attr == 'top') {
                if (levelI == levels.length - 1) {
                    MessageService.error("已在最上层")
                    return
                }
                var seq = levels[levelI].seq;
                levels[levelI].seq = levels[levels.length - 1].seq;

                for (var i = levelI + 1; i < levels.length; i++) {
                    var item = levels[i];
                    item.seq = i;
                }
            }

            // 置底
            if (attr == 'bottom') {
                if (levelI == 0) {
                    MessageService.error("已在最下层");
                    return
                }
                var seq = levels[levelI].seq;
                levels[levelI].seq = levels[0].seq;

                for (var i = 0; i < levelI; i++) {
                    var item = levels[i];
                    item.seq += 1;
                }
            }

            $scope.template.levelI = levels[levelI].seq - 1;
            levels.sort(compare('seq', true));
        }

        // 锁定
        $scope.locking = function () {
            // 当前页所有占位框
            var currentImages = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;
            // 当前页所有文本
            var currentTexts = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
            // 当前页所有素材
            var currentArts = $scope.currentPage.levels.level[$scope.template.levelI].imageart;
            var all = getAllCtrlKeyArray(1);

            var len = all.length;
            // 单选状态
            if (!len) {
                $scope.selectBox.lock = !$scope.selectBox.lock;
            } else {
                // ctrl+点击
                for (var i = 0; i < len; i++) {
                    if (all[i].current == 'imagebox') {
                        // currentImages[all[i].index] = all[i];
                        currentImages[all[i].index].lock = !currentImages[all[i].index].lock;
                    } else if (all[i].current == 'textbox') {
                        // currentTexts[all[i].index] = all[i];
                        currentTexts[all[i].index].lock = !currentTexts[all[i].index].lock;
                    } else {
                        // currentArts[all[i].index] = all[i];
                        currentArts[all[i].index].lock = !currentArts[all[i].index].lock;
                    }
                }
            }
            updateHistorys();
        }

        // 删除
        $scope.deleteBox = function () {
            var all = getAllCtrlKeyArray(1);
            var len = all.length;
            if ($scope.selectBox.current == 'barcode') {
                if ($scope.currentPage.barcode.lock) {
                    return
                }
                $scope.currentPage.barcode = {};
                return
            }
            if ($scope.selectBox.current == 'qrcode') {
                if ($scope.currentPage.qrcode.lock) {
                    return
                }
                $scope.currentPage.qrcode = {};
                return
            }
            var levels = $scope.currentPage.levels;
            var level = levels.level[$scope.template.levelI];
            // 单选状态
            if (!len) {
                if ($scope.selectBox == undefined || $scope.selectBox.current == 'page') {
                    $scope.deleteBgImg();
                    return
                }
                if ($scope.selectBox.current == 'imagebox') {
                    if (level.imagebox.lock) {
                        return
                    }
                }
                if ($scope.selectBox.current == 'textbox') {
                    if (level.textbox.lock) {
                        return
                    }
                }
                if ($scope.selectBox.current == 'imageart') {
                    if (level.imageart.lock) {
                        return
                    }
                }
                if ($scope.selectBox.current == 'textdocument') {
                    if (level.textdocument.lock) {
                        return
                    }
                }
                levels.level.splice($scope.template.levelI, 1);

            } else {
                // ctrl+点击
                for (var i = len - 1; i >= 0; i--) {
                    if (!all[i]) {
                        continue;
                    }
                    if (all[i].current == 'barcode') {
                        $scope.currentPage.barcode = {};

                    } else if (all[i].current == 'qrcode') {
                        $scope.currentPage.qrcode = {};

                    } else {
                        levels.level.splice(all[i].levelI, 1);
                    }
                }
                $scope.clearCtrlKey();
            }

            for (var x = 0; x < levels.level.length; x++) {
                var l = levels.level[x];
                if (l.imagebox && l.imagebox.current) {
                    l.imagebox.levelI = x;
                }
                if (l.imageart && l.imageart.current) {
                    l.imageart.levelI = x;
                }
                if (l.textbox && l.textbox.current) {
                    l.textbox.levelI = x;
                }
                if (l.textdocument && l.textdocument.current) {
                    l.textdocument.levelI = x;
                }
                l.seq = x + 1;
            }

            $scope.RightPanel = 'page';
            $scope.selectBox = {};
            getPagePx();
            getImageboxLen();
            updateHistorys();
        };


        vm.watchSure = false;
        $scope.watchArticle = function (){

            var template = angular.copy($scope.template);
            var arr = getTemplate(template);
            // console.log(vm.previewArticle)
            // console.log(vm.page)
            if(modelType && modelType === 'THREE'){
                vm.threeView = true;
                vm.threeTemplate = arr.template;
                // vm.threeXmlId = xml.data.id;
                vm.threeProductXmlData = {
                    modelData:modelData,
                    modelPath:modelPath,
                };
            }else{
                vm.previewArticle = arr.template;
                vm.watchSure = true;
            }
            // console.log(vm.previewArticle)

            // var template = angular.copy($scope.template);
            // var arr = getTemplate(template);
            // // console.log(vm.previewArticle)
            // console.log(vm.page)
            // if(vm.page.modelType && vm.page.modelType === 'THREE'){
            //     vm.threeView = true;
            //     vm.threeTemplate = arr.template;
            //     vm.threeProductXmlData = {
            //         modelData:vm.page.modelData,
            //         modelPath:vm.page.modelPath,
            //     };
            // }else{
            //     vm.previewArticle = arr.template;
            //     vm.watchSure = true;
            // }
        }

        $scope.savePage = function (attr) {
            vm.isSaving = true;
            $scope.isHasGeometry = true;
            var template = angular.copy($scope.template);

            var arr = getTemplate(template);
            template = arr.template;
            var tipArr = arr.tipArr;
            // console.log($scope.template)
            // console.log(tipArr)
            // console.log(template)
            if(tipArr.length){
                var str = tipArr.join(',')
                MessageService.error("第"+str+"页需要设置为不可替换！");
                vm.isSaving = false;
                return
            }

            var _variableRepeat = isRepeat(arr.variableData)
            if(_variableRepeat){
                MessageService.error("可变数据不可重复");
                vm.isSaving = false;
                return
            }

            var i = $scope.template.pageI;
            var nodes = document.getElementsByClassName('page-item2');
            html2canvas_adapt(nodes[0], {
                onrendered: function (canvas) {
                    var url = canvas.toDataURL("image/png");
                    var blob = dataURLtoBlob(url);
                    var formData = new FormData();
                    formData.append('file', blob);
                    formData.append('key', 'cover-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            template.pages.page[i].pagecover = {
                                identifier: vm.url + data.key,
                                provider: 'qiniu'
                            }
                            // console.log(template);
                            if (!$scope.isHasGeometry) {
                                MessageService.error("请输入正确的占位框宽高！");
                                return
                            }
                            ProductXml.thirdXmlSave({
                                document: template,
                                key: $stateParams.key,
                                xmlId: $stateParams.xmlId,
                                version: '2.0'
                            }, success, error);

                            $scope.$digest();
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });
                },
                useCORS: true,
                logging: false
            });
        }

        function isRepeat(arr) {
            var hash = {};
            for (var i in arr) {
                if (hash[arr[i]]){
                    return true;
                }
                hash[arr[i]] = true;
            }
            return false;
        }

        function getTemplate(template, attr) {
            var isSave = true;
            template.bindingtype = vm.page.bindingtype;
            // template.pattern = vm.page.pattern;
            template.cover = angular.copy(vm.page.cover);
            template.replace = vm.page.replace;
            template.version = "2.0";
            template.addpage = vm.page.addpage;
            template.minpage = vm.page.minpage;
            template.maxpage = vm.page.maxpage;
            template.addunit = vm.page.addunit;
            var tipArr = [],variableData=[];
            angular.forEach(template.pages.page, function (item, i) {
                item.centerrotation = true;
                if (item.background) {
                    item.background.offsetx = unitTransformPx($scope.template.pages.page[i].background.offsetx);
                    item.background.offsety = unitTransformPx($scope.template.pages.page[i].background.offsety);
                }
                item.mediabox = {
                    width: unitTransformPx(item.mediabox.width),
                    height: unitTransformPx(item.mediabox.height),
                    x: 0,
                    y: 0
                };
                item.trimbox = {
                    width: unitTransformPx(item.trimbox.width),
                    height: unitTransformPx(item.trimbox.height),
                    x: unitTransformPx(item.trimbox.x),
                    y: unitTransformPx(item.trimbox.y)
                };

                item.editbox = {
                    width: unitTransformPx(item.editbox.width),
                    height: unitTransformPx(item.editbox.height),
                    x: unitTransformPx(item.editbox.x),
                    y: unitTransformPx(item.editbox.y)
                };
                if(item.editboxes && item.editboxes.editbox){
                    item.editboxes.editbox.forEach(function (editbox) {
                        editbox.width= PxTurnMm(editbox.width);
                        editbox.height= PxTurnMm(editbox.height);
                        editbox.x= PxTurnMm(editbox.x);
                        editbox.y= PxTurnMm(editbox.y);
                    })
                }
                if (item.scene) {
                    item.scene.resource = {
                        provider: 'qiniu',
                        identifier: item.scene.background.resource.identifier
                    }
                    item.scene.geometry = {
                        width: unitTransformPx(item.scene.geometry.width),
                        height: unitTransformPx(item.scene.geometry.height),
                        x: unitTransformPx(item.scene.geometry.x),
                        y: unitTransformPx(item.scene.geometry.y)
                    };
                    if (item.scene.imagebox) {
                        item.scene.imagebox.geometry = {
                            width: unitTransformPx(item.scene.imagebox.geometry.width),
                            height: unitTransformPx(item.scene.imagebox.geometry.height),
                            x: unitTransformPx(item.scene.imagebox.geometry.x),
                            y: unitTransformPx(item.scene.imagebox.geometry.y)
                        };
                        item.scene.imagebox.rotation = {
                            angle: item.scene.imagebox.rotation.angle
                        };
                    }
                    item.scene.hierarchy = item.scene.hierarchy;
                }

                if (item.barcode && item.barcode.geometry
                    && item.barcode.geometry.width > 0.01 && item.barcode.geometry.height > 0.01) {
                    item.barcode = {
                        geometry: {
                            width: unitTransformPx(item.barcode.geometry.width),
                            height: unitTransformPx(item.barcode.geometry.height),
                            x: unitTransformPx(item.barcode.geometry.x),
                            y: unitTransformPx(item.barcode.geometry.y)
                        },
                        rotation: angular.copy(item.barcode.rotation),
                    };
                    if (!item.unreplace) {
                        tipArr.push(i + 1);
                    }
                } else {
                    item.barcode = null;
                }
                if (item.qrcode && item.qrcode.geometry
                    && item.qrcode.geometry.width > 0.01 && item.qrcode.geometry.height > 0.01) {
                    item.qrcode = {
                        geometry: {
                            width: unitTransformPx(item.qrcode.geometry.width),
                            height: unitTransformPx(item.qrcode.geometry.height),
                            x: unitTransformPx(item.qrcode.geometry.x),
                            y: unitTransformPx(item.qrcode.geometry.y)
                        },
                        rotation: angular.copy(item.qrcode.rotation),
                    };
                    if (!item.unreplace) {
                        tipArr.push(i + 1);
                    }
                } else {
                    item.qrcode = null;
                }

                angular.forEach(item.levels.level, function (level, l) {
                    if(level.variable){
                        variableData.push(level.name)
                    }
                    if (level.imageart && level.imageart.current) {
                        var art = level.imageart;
                        art.geometry = {
                            width: unitTransformPx(art.geometry.width),
                            height: unitTransformPx(art.geometry.height),
                            x: unitTransformPx(art.geometry.x),
                            y: unitTransformPx(art.geometry.y)
                        };
                        art.offsetx = unitTransformPx(art.offsetx);
                        art.offsety = unitTransformPx(art.offsety);
                        art.rotation.angle = -art.rotation.angle;
                    }
                    if (level.imagebox && level.imagebox.current) {
                        var img = level.imagebox;
                        if (img.geometry.width <= 1 || img.geometry.height <= 1) {
                            $scope.isHasGeometry = false;
                        }
                        ;
                        img.geometry = {
                            width: unitTransformPx(img.geometry.width),
                            height: unitTransformPx(img.geometry.height),
                            x: unitTransformPx(img.geometry.x),
                            y: unitTransformPx(img.geometry.y)
                        };

                        if (img.image) {
                            img.image = {
                                offsetx: unitTransformPx(img.image.offsetx),
                                offsety: unitTransformPx(img.image.offsety),
                                scale: img.image.scale,
                                angle: img.image.angle,
                                resource: {
                                    identifier: img.image.resource ? img.image.resource.identifier : '',
                                    provider: img.image.resource ? img.image.resource.provider : ''
                                },
                                resourceid: img.image.resourceid
                            }
                        }
                        if (attr == 2) {
                            if (!img.image || img.image.resource.identifier == "")
                                isSave = false;
                        }
                    }
                    if (level.textbox && level.textbox.current) {
                        var text = level.textbox;
                        if (text.text == '') {
                            level.textbox = null;
                        } else {
                            text.geometry = {
                                width: unitTransformPx(text.geometry.width),
                                height: unitTransformPx(text.geometry.height),
                                x: unitTransformPx(text.geometry.x),
                                y: unitTransformPx(text.geometry.y)
                            }
                            text.rotation.angle = -text.rotation.angle;
                        }
                    }
                    if (level.textdocument && level.textdocument.current) {
                        var textdocument = level.textdocument;
                        textdocument.background.offsetx = unitTransformPx(textdocument.background.offsetx);
                        textdocument.background.offsety = unitTransformPx(textdocument.background.offsety);
                        textdocument.geometry = {
                            width: unitTransformPx(textdocument.geometry.width),
                            height: unitTransformPx(textdocument.geometry.height),
                            x: unitTransformPx(textdocument.geometry.x),
                            y: unitTransformPx(textdocument.geometry.y)
                        }
                        angular.forEach(textdocument.levels.level, function (l) {
                            if (l.imageart) {
                                var imageart = l.imageart;
                                imageart.geometry = {
                                    width: unitTransformPx(imageart.geometry.width),
                                    height: unitTransformPx(imageart.geometry.height),
                                    x: unitTransformPx(imageart.geometry.x),
                                    y: unitTransformPx(imageart.geometry.y)
                                };
                                imageart.offsetx = unitTransformPx(imageart.offsetx);
                                imageart.offsety = unitTransformPx(imageart.offsety);
                                imageart.rotation.angle = -imageart.rotation.angle;
                            }
                            if (l.textbox) {
                                if (l.textbox.text == '') {
                                    l.textbox = null;
                                } else {
                                    l.textbox.geometry = {
                                        width: unitTransformPx(l.textbox.geometry.width),
                                        height: unitTransformPx(l.textbox.geometry.height),
                                        x: unitTransformPx(l.textbox.geometry.x),
                                        y: unitTransformPx(l.textbox.geometry.y)
                                    }
                                    l.textbox.rotation.angle = -l.textbox.rotation.angle;
                                }
                            }
                        })
                    }
                })
            });

            if($scope.template.deletedPages&&$scope.template.deletedPages.page&&$scope.template.deletedPages.page.length){
                pageToMM(template.deletedPages.page,$scope.template.deletedPages);
            }
            return {
                tipArr: tipArr,
                template: template,
                isSave: isSave,
                variableData: variableData
            }
        }

        function pageToMM(pages,tempPages) {
            angular.forEach(pages, function (item, i) {
                item.centerrotation = true;
                if (item.background) {
                    item.background.offsetx = unitTransformPx(tempPages.page[i].background.offsetx);
                    item.background.offsety = unitTransformPx(tempPages.page[i].background.offsety);
                }
                item.mediabox = {
                    width: unitTransformPx(item.mediabox.width),
                    height: unitTransformPx(item.mediabox.height),
                    x: 0,
                    y: 0
                };
                item.trimbox = {
                    width: unitTransformPx(item.trimbox.width),
                    height: unitTransformPx(item.trimbox.height),
                    x: unitTransformPx(item.trimbox.x),
                    y: unitTransformPx(item.trimbox.y)
                };

                item.editbox = {
                    width: unitTransformPx(item.editbox.width),
                    height: unitTransformPx(item.editbox.height),
                    x: unitTransformPx(item.editbox.x),
                    y: unitTransformPx(item.editbox.y)
                };
                if (item.scene) {
                    item.scene.resource = {
                        provider: 'qiniu',
                        identifier: item.scene.background.resource.identifier
                    }
                    item.scene.geometry = {
                        width: unitTransformPx(item.scene.geometry.width),
                        height: unitTransformPx(item.scene.geometry.height),
                        x: unitTransformPx(item.scene.geometry.x),
                        y: unitTransformPx(item.scene.geometry.y)
                    };
                    if (item.scene.imagebox) {
                        item.scene.imagebox.geometry = {
                            width: unitTransformPx(item.scene.imagebox.geometry.width),
                            height: unitTransformPx(item.scene.imagebox.geometry.height),
                            x: unitTransformPx(item.scene.imagebox.geometry.x),
                            y: unitTransformPx(item.scene.imagebox.geometry.y)
                        };
                        item.scene.imagebox.rotation = {
                            angle: item.scene.imagebox.rotation.angle
                        };
                    }
                    item.scene.hierarchy = item.scene.hierarchy;
                }

                if (item.barcode && item.barcode.geometry) {
                    item.barcode = {
                        geometry: {
                            width: unitTransformPx(item.barcode.geometry.width),
                            height: unitTransformPx(item.barcode.geometry.height),
                            x: unitTransformPx(item.barcode.geometry.x),
                            y: unitTransformPx(item.barcode.geometry.y)
                        },
                        rotation: angular.copy(item.barcode.rotation),
                    };
                } else {
                    item.barcode = null;
                }
                if (item.qrcode && item.qrcode.geometry) {
                    item.qrcode = {
                        geometry: {
                            width: unitTransformPx(item.qrcode.geometry.width),
                            height: unitTransformPx(item.qrcode.geometry.height),
                            x: unitTransformPx(item.qrcode.geometry.x),
                            y: unitTransformPx(item.qrcode.geometry.y)
                        },
                        rotation: angular.copy(item.qrcode.rotation),
                    };
                } else {
                    item.qrcode = null;
                }

                angular.forEach(item.levels.level, function (level, l) {
                    if (level.imageart && level.imageart.current) {
                        var art = level.imageart;
                        art.geometry = {
                            width: unitTransformPx(art.geometry.width),
                            height: unitTransformPx(art.geometry.height),
                            x: unitTransformPx(art.geometry.x),
                            y: unitTransformPx(art.geometry.y)
                        };
                        art.offsetx = unitTransformPx(art.offsetx);
                        art.offsety = unitTransformPx(art.offsety);
                        art.rotation.angle = -art.rotation.angle;
                    }
                    if (level.imagebox && level.imagebox.current) {
                        var img = level.imagebox;
                        if (img.geometry.width <= 1 || img.geometry.height <= 1) {
                            $scope.isHasGeometry = false;
                        }
                        ;
                        img.geometry = {
                            width: unitTransformPx(img.geometry.width),
                            height: unitTransformPx(img.geometry.height),
                            x: unitTransformPx(img.geometry.x),
                            y: unitTransformPx(img.geometry.y)
                        };

                        if (img.image) {
                            img.image = {
                                offsetx: unitTransformPx(img.image.offsetx),
                                offsety: unitTransformPx(img.image.offsety),
                                scale: img.image.scale,
                                angle: img.image.angle,
                                resource: {
                                    identifier: img.image.resource ? img.image.resource.identifier : '',
                                    provider: img.image.resource ? img.image.resource.provider : ''
                                },
                                resourceid: img.image.resourceid
                            }
                        }
                    }
                    if (level.textbox && level.textbox.current) {
                        var text = level.textbox;
                        if (text.text == '') {
                            level.textbox = null;
                        } else {
                            text.geometry = {
                                width: unitTransformPx(text.geometry.width),
                                height: unitTransformPx(text.geometry.height),
                                x: unitTransformPx(text.geometry.x),
                                y: unitTransformPx(text.geometry.y)
                            }
                            text.rotation.angle = -text.rotation.angle;
                        }
                    }
                    if (level.textdocument && level.textdocument.current) {
                        var textdocument = level.textdocument;
                        textdocument.background.offsetx = unitTransformPx(textdocument.background.offsetx);
                        textdocument.background.offsety = unitTransformPx(textdocument.background.offsety);
                        textdocument.geometry = {
                            width: unitTransformPx(textdocument.geometry.width),
                            height: unitTransformPx(textdocument.geometry.height),
                            x: unitTransformPx(textdocument.geometry.x),
                            y: unitTransformPx(textdocument.geometry.y)
                        }
                        angular.forEach(textdocument.levels.level, function (l) {
                            if (l.imageart) {
                                var imageart = l.imageart;
                                imageart.geometry = {
                                    width: unitTransformPx(imageart.geometry.width),
                                    height: unitTransformPx(imageart.geometry.height),
                                    x: unitTransformPx(imageart.geometry.x),
                                    y: unitTransformPx(imageart.geometry.y)
                                };
                                imageart.offsetx = unitTransformPx(imageart.offsetx);
                                imageart.offsety = unitTransformPx(imageart.offsety);
                                imageart.rotation.angle = -imageart.rotation.angle;
                            }
                            if (l.textbox) {
                                if (l.textbox.text == '') {
                                    l.textbox = null;
                                } else {
                                    l.textbox.geometry = {
                                        width: unitTransformPx(l.textbox.geometry.width),
                                        height: unitTransformPx(l.textbox.geometry.height),
                                        x: unitTransformPx(l.textbox.geometry.x),
                                        y: unitTransformPx(l.textbox.geometry.y)
                                    }
                                    l.textbox.rotation.angle = -l.textbox.rotation.angle;
                                }
                            }
                        })
                    }
                })
            });
        }

        function loadPageToPx(templatePage, pageIndex, callback) {
            if (templatePage.loaded) {
                return;
            }
            if (templatePage.background && templatePage.background.resource && templatePage.background.resource.identifier) {
                var background = templatePage.background;
                var pageW = templatePage.mediabox.width,
                    pageH = templatePage.mediabox.height;
                if (background.resource && background.resource.identifier) {
                    getBackgroundSource(background.resource, pageW, pageH, background)
                }
            }
            var promise_list = [];
            if (templatePage.levels && templatePage.levels.level) {
                angular.forEach(templatePage.levels.level, function (level, n) {
                    if (level.imageart && level.imageart.geometry) {
                        var deferred = $q.defer();
                        var art = level.imageart;
                        $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                            templatePage.levels.level[n].imageart = {
                                geometry: art.geometry,
                                rotation: {
                                    angle: art.rotation.angle
                                },
                                type: art.type,
                                resource: {
                                    identifier: art.resource.identifier,
                                    provider: art.resource.provider,
                                    width: data.width,
                                    height: data.height
                                },
                                scale: art.scale,
                                offsetx: art.offsetx,
                                offsety: art.offsety,
                                unit: 'mm',
                                current: 'imageart',
                                levelI: n,
                                pageI: pageIndex,
                                lock: art.lock,
                                ctrlKey: false,
                                transparency: art.transparency,
                                onlyshow: art.onlyshow,
                                movable: art.movable,
                                editable: art.editable,
                            }
                            deferred.resolve("ok");
                        }).error(function (error) {
                            MessageService.error("获取图片信息失败");
                            deferred.resolve("error");
                        });
                        promise_list.push(deferred.promise);
                    }
                    if (level.imagebox && level.imagebox.geometry) {
                        var image = level.imagebox;
                        if (image.shadow == null) {
                            image.shadow = {
                                color:'#777777',
                                x:0,
                                y:0,
                                blur:0
                            }
                        }
                        if (image.border == null) {
                            image.border = {
                                shape: 'Rectangle',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0,
                                    all:0
                                },
                                "edge": {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                                lineWidth: 0,
                                type: "Color",
                                lineType:"solid",
                                resource: {
                                    "identifier": ''
                                },
                                color: '#000000',
                            }
                        }
                        templatePage.levels.level[n].imagebox = {
                            geometry: image.geometry,
                            rotation: angular.copy(image.rotation),
                            mask: angular.copy(image.mask),
                            image: angular.copy(image.image),
                            maskUrl: 'content/images/mask_souce.png',
                            imgUrl: 'content/images/mask_souce.png',
                            unit: 'mm',
                            current: 'imagebox',
                            levelI: n,
                            pageI: pageIndex,
                            lock: image.lock,
                            border: image.border,
                            shadow: image.shadow,
                            ctrlKey: false,
                            transparency: isNaN(image.transparency) ? 1 : image.transparency
                        }


                        if (image.image && image.image.resource && image.image.resource.identifier) {       //qiniu
                            templatePage.levels.level[n].imagebox.image = {
                                resource: {
                                    identifier: image.image ? image.image.resource.identifier : '',
                                    provider: image.image ? image.image.resource.provider : 'qiniu'
                                },
                                imgSize: (!image.image) || image.image.imgSize == null ? '' : image.image.imgSize,
                                parameter: (!image.image) || image.image.parameter == null ? [] : angular.fromJson(image.image.parameter),
                                filter: (!image.image) || image.image.filter == null ? '' : image.image.filter,
                                offsetx: (!image.image) || image.image.offsetx == null ? 0 : image.image.offsetx,
                                offsety: (!image.image) || image.image.offsety == null ? 0 : image.image.offsety,
                                angle: image.image && image.image.angle ? image.image.angle : 0,
                                scale: (!image.image) || image.image.scale == 1 ? 1 : image.image.scale,
                                index: (!image.image) ? '' : image.image.index,
                                resourceid: (!image.image) ? '' : image.image.resourceid,
                            }
                            templatePage.levels.level[n].imagebox.imgUrl = image.image.resource.identifier + '?imageMogr2/auto-orient/thumbnail/!40p';
                            if (image.image.angle != 0) {
                                templatePage.levels.level[n].imagebox.imgUrl += '/rotate/' + image.image.angle
                            }
                            var promise = getBackgroundSource1(templatePage.levels.level[n].imagebox.image.resource, templatePage.levels.level[n].imagebox.geometry.width, templatePage.levels.level[n].imagebox.geometry.height, templatePage.levels.level[n].imagebox.image)
                            if (promise) {
                                promise_list.push(promise);
                            }
                        } else {
                            var deferred = $q.defer();
                            var imagecanvas = document.createElement('canvas');
                            var imagecontext = imagecanvas.getContext('2d');
                            var newImg = document.createElement('img');
                            newImg.crossOrigin = "Anonymous";
                            newImg.src = 'content/images/mask_souce.png';
                            newImg.onload = function () {
                                newImg.onload = null;
                                var width = newImg.width;
                                var height = newImg.height;
                                if (image.mask && image.mask.resource && image.mask.resource.identifier) {
                                    var masks = document.createElement('img');
                                    masks.crossOrigin = "anonymous";
                                    masks.onload = function () {
                                        masks.onload = null;
                                        imagecanvas.width = width;
                                        imagecanvas.height = height;
                                        imagecontext.drawImage(masks, 0, 0, width, height);
                                        imagecontext.globalCompositeOperation = 'source-in';
                                        imagecontext.drawImage(newImg, 0, 0);
                                        templatePage.levels.level[n].imagebox.bgImg = ImageService.dataURLtoBlob(imagecanvas.toDataURL());
                                        $scope.$apply();
                                    };
                                    masks.src = image.mask.resource.identifier;
                                }
                                deferred.resolve("ready");
                            };
                            newImg.src = 'content/images/mask_souce.png';
                            promise_list.push(deferred.promise);
                        }
                        if (templatePage.levels.level[n].imagebox.mask && templatePage.levels.level[n].imagebox.mask.resource && templatePage.levels.level[n].imagebox.mask.resource.identifier) {
                            // imageboxResourceCanvas(templatePage.levels.level[n].imagebox);
                            var deferred = $q.defer();
                            if (templatePage.levels.level[n].imagebox.image && templatePage.levels.level[n].imagebox.image.resource.identifier) {
                                imageboxResourceCanvas(templatePage.levels.level[n].imagebox, function() {
                                    deferred.resolve("ready");
                                });
                            } else {
                                maskScale(null, templatePage.levels.level[n].imagebox, n, templatePage.levels.level[n].imagebox.mask.resource.identifier, function() {
                                    deferred.resolve("ready");
                                });
                            }
                            promise_list.push(deferred.promise);
                        }
                    }
                    if (level.textbox && level.textbox.geometry) {
                        var text = level.textbox;
                        templatePage.levels.level[n].textbox = {
                            geometry: text.geometry,
                            identifier: text.identifier,
                            rotation: {
                                angle: text.rotation.angle
                            },
                            angle: angular.copy(text.angle),
                            status: angular.copy(text.status),
                            style: angular.copy(text.style),
                            type: angular.copy(text.type),
                            text: angular.copy(text.text),
                            version: text.version,
                            unit: 'mm',
                            current: 'textbox',
                            levelI: n,
                            pageI: pageIndex,
                            lock: text.lock,
                            ctrlKey: false,
                            transparency: text.transparency,
                            lineheight: text.style.lineheight,
                            letterspacing: text.style.letterspacing,
                            scale: 1,
                            movable: text.movable,
                            editable: text.editable,
                            fontuuid: text.fontuuid
                        }
                        if (text.fontuuid) {
                            //下载字体
                            var deferred = $q.defer();
                            getFont(templatePage.levels.level[n].textbox,FontManagement, function() {
                                deferred.resolve("ready");
                            });
                            promise_list.push(deferred.promise);
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        var textdocument = level.textdocument;
                        templatePage.levels.level[n].textdocument = {
                            background: textdocument.background,
                            geometry: textdocument.geometry,
                            rotation: {
                                angle: textdocument.rotation.angle || 0
                            },
                            unit: 'mm',
                            current: 'textdocument',
                            levels: angular.copy(textdocument.levels),
                            levelI: n,
                            pageI: pageIndex,
                            lock: textdocument.lock,
                            ctrlKey: false,
                            transparency: textdocument.transparency,
                            movable: textdocument.movable,
                            editable: textdocument.editable,
                            scale: textdocument.geometry.width / templatePage.mediabox.width
                        }

                        var backg = templatePage.levels.level[n].textdocument.background;
                        var pageW = templatePage.levels.level[n].textdocument.geometry.width,
                            pageH = templatePage.levels.level[n].textdocument.geometry.height;
                        if (backg.resource && backg.resource.identifier) {
                            var promise = getBackgroundSource(backg.resource, pageW, pageH, backg);
                            if (promise) {
                                promise_list.push(promise);
                            }
                        }

                        angular.forEach(textdocument.levels.level, function (lev, l) {
                            if (lev.imageart && lev.imageart.geometry) {
                                var deferred = $q.defer();
                                var art = lev.imageart;
                                $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                    templatePage.levels.level[n].textdocument.levels.level[l].imageart = {
                                        geometry: art.geometry,
                                        rotation: {
                                            angle: art.rotation.angle
                                        },
                                        type: art.type,
                                        resource: {
                                            identifier: art.resource.identifier,
                                            provider: art.resource.provider,
                                            width: data.width,
                                            height: data.height
                                        },
                                        scale: art.scale,
                                        offsetx: art.offsetx,
                                        offsety: art.offsety,
                                        unit: 'mm',
                                        current: 'imageart',
                                        levelI: n,
                                        pageI: pageIndex,
                                        lock: false,
                                        transparency: art.transparency,
                                        onlyshow: art.onlyshow,
                                        movable: art.movable,
                                        editable: art.editable,
                                    }
                                    deferred.resolve("ready");
                                }).error(function (error) {
                                    MessageService.error("获取图片信息失败");
                                    deferred.resolve("error");
                                });
                                promise_list.push(deferred.promise);
                            }
                            if (lev.textbox && lev.textbox.geometry) {
                                var text = lev.textbox;
                                templatePage.levels.level[n].textdocument.levels.level[l].textbox = {
                                    geometry: text.geometry,
                                    identifier: text.identifier,
                                    rotation: {
                                        angle: text.rotation.angle
                                    },
                                    angle: angular.copy(text.angle),
                                    status: angular.copy(text.status),
                                    style: angular.copy(text.style),
                                    type: angular.copy(text.type),
                                    text: angular.copy(text.text),
                                    unit: 'mm',
                                    current: 'textbox',
                                    levelI: n,
                                    pageI: pageIndex,
                                    lock: false,
                                    transparency: text.transparency,
                                    lineheight: text.style.lineheight,
                                    letterspacing: text.style.letterspacing,
                                    fontuuid: text.fontuuid
                                }
                                //下载字体
                                if (text.fontuuid) {
                                    var deferred = $q.defer();
                                    getFont(text,FontManagement, function() {
                                        deferred.resolve("ready");
                                    });
                                    fontStyle(text);
                                    promise_list.push(deferred);
                                }
                            }
                        })
                    }
                });
            }

            if (callback) {
                if (promise_list.length == 0) {
                    callback();
                } else {
                    $q.all(promise_list).then(function(res) {
                        callback(res);
                    });
                }
            }

            templatePage.loaded = true;
        }

        function pagesToPx(pages,tempPages,flag) {
            var seq = 0;
            angular.forEach(pages, function (page, i) {
                if (!page.editbox) {
                    page.editbox = angular.copy(page.trimbox);
                }
                if(!page.backup){
                    seq++ ;
                }
                tempPages.page[i] = {
                    isScene: false,
                    seq: seq,
                    width: unitTransform(page.trimbox.width),
                    height: unitTransform(page.trimbox.height),
                    trimbox: getGeometry(page.trimbox),
                    mediabox: getGeometry(page.mediabox),
                    editbox: getGeometry(page.editbox),
                    editboxM: angular.copy(page.editbox),
                    editboxes: angular.copy(page.editboxes),
                    blood: {
                        top: page.trimbox.y,
                        bottom: page.mediabox.height - page.trimbox.y - page.trimbox.height,
                        left: page.trimbox.x,
                        right: page.mediabox.width - page.trimbox.x - page.trimbox.width,
                    },
                    bloodPx: {
                        top: unitTransform(page.trimbox.y),
                        bottom: unitTransform(page.mediabox.height - page.trimbox.y - page.trimbox.height),
                        left: unitTransform(page.trimbox.x),
                        right: unitTransform(page.mediabox.width - page.trimbox.x - page.trimbox.width),
                    },
                    spread: page.spread,
                    backup: page.backup,
                    type: page.type,
                    unreplace: page.unreplace || false,
                    scale: 1,
                    barcode: {},
                    qrcode: {},
                    uuid: page.uuid,
                    background: angular.copy(page.background),
                    pagecover: {
                        identifier: page.pagecover && page.pagecover.identifier ? page.pagecover.identifier : '',
                        provider: page.pagecover && page.pagecover.provider ? page.pagecover.provider : '',
                    },
                    hasBgImg: page.background && page.background.resource.identifier ? true : false,
                    bgImg: page.background.resource.identifier ? vm.url + page.background.resource.identifier + '?imageMogr2/interlace/1' : '',
                    levels: angular.copy(page.levels),
                    unit: 'mm'
                };
                tempPages.page[i].editboxes.editbox.forEach(function (editbox, index) {
                    tempPages.page[i].editboxes.editbox[index] = getGeometry(editbox);
                })
                if (page.scene) {
                    tempPages.page[i].scene = {
                        background: {
                            resource: {
                                provider: page.scene.resource.provider,
                                identifier: page.scene.resource.identifier
                            }
                        },
                        geometry: getGeometry(page.scene.geometry),
                        imagebox: {
                            geometry: getGeometry(page.scene.imagebox.geometry),
                            rotation: {angle: page.scene.imagebox.rotation.angle}
                        },
                        isScene: true,
                        scale: 1,
                        unit: 'mm',
                        hierarchy: page.scene.hierarchy,
                    }
                    tempPages.page[i].scene.isScene = true;
                    var w = tempPages.page[i].scene.geometry.width;
                    var h = tempPages.page[i].scene.geometry.height;
                    var originalScale = $con.width() * 0.8 / ($con.height() * 0.8);
                    if (originalScale < page.scene.geometry.width / page.scene.geometry.height) {
                        //宽定，高不定
                        tempPages.page[i].scene.width = $con.width() * 0.8;
                        tempPages.page[i].scene.height = $con.width() * 0.8 * h / w;
                    } else {

                        //高定，宽不动
                        tempPages.page[i].scene.height = $con.height() * 0.8;
                        tempPages.page[i].scene.width = $con.height() * 0.8 * w / h
                    }
                    tempPages.page[i].scene.proportion = (w / tempPages.page[i].scene.width);
                    var wah = ThreeHundredDpi(page.scene.geometry.width, page.scene.geometry.height);
                    tempPages.page[i].scene.widthPx = wah.px1;
                    tempPages.page[i].scene.heightPx = wah.px2;
                    if (vm.windowH / h >= vm.windowW / w) {
                        tempPages.page[i].scene.ratio = vm.windowW / w * 0.8;
                    } else {
                        tempPages.page[i].scene.ratio = vm.windowH / h * 0.8;
                    }
                    if (vm.windowH1 / h >= vm.windowW1 / w) {
                        tempPages.page[i].scene.ratio1 = vm.windowW1 / w;
                    } else {
                        tempPages.page[i].scene.ratio1 = vm.windowH1 / h;
                    }
                }
                if (tempPages.page[i].uuid == null) {
                    tempPages.page[i].uuid = UidService.get();
                }

                if (page.barcode&&page.barcode.geometry) {
                    var barcode = page.barcode.geometry;
                    var angle = 0;
                    if (page.barcode.rotation && page.barcode.rotation.angle) {
                        angle = page.barcode.rotation.angle
                    }
                    tempPages.page[i].barcode = {
                        current: 'barcode',
                        geometry: {
                            width: unitTransform(barcode.width),
                            height: unitTransform(barcode.height),
                            x: unitTransform(barcode.x),
                            y: unitTransform(barcode.y),
                        },
                        rotation: {
                            angle: angle
                        },
                        lock: false
                    }
                }
                if (page.qrcode&&page.qrcode.geometry) {
                    var qrcode = page.qrcode.geometry;
                    var angle = 0;
                    if (page.qrcode.rotation && page.qrcode.rotation.angle) {
                        angle = page.qrcode.rotation.angle
                    }
                    tempPages.page[i].qrcode = {
                        current: 'qrcode',
                        geometry: {
                            width: unitTransform(qrcode.width),
                            height: unitTransform(qrcode.height),
                            x: unitTransform(qrcode.x),
                            y: unitTransform(qrcode.y),
                        },
                        rotation: {
                            angle: angle
                        },
                        lock: false
                    }
                }
                angular.forEach(tempPages.page[i].levels.level, function (level, l) {
                    if (level.imageart && level.imageart.geometry) {
                        var art = level.imageart;
                        art.current = 'imageart';
                        art.geometry = {
                            width: MmTurnPx(art.geometry.width),
                            height: MmTurnPx(art.geometry.height),
                            x: MmTurnPx(art.geometry.x),
                            y: MmTurnPx(art.geometry.y)
                        };
                        art.offsetx = MmTurnPx(art.offsetx);
                        art.offsety = MmTurnPx(art.offsety);
                        art.rotation.angle = -art.rotation.angle;
                    }
                    if (level.imagebox && level.imagebox.geometry) {
                        var img = level.imagebox;
                        if (img.geometry.width <= 1 || img.geometry.height <= 1) {
                            $scope.isHasGeometry = false;
                        }
                        img.current = 'imagebox';
                        img.geometry = {
                            width: MmTurnPx(img.geometry.width),
                            height: MmTurnPx(img.geometry.height),
                            x: MmTurnPx(img.geometry.x),
                            y: MmTurnPx(img.geometry.y)
                        };

                        if (img.image) {
                            img.image = {
                                offsetx: MmTurnPx(img.image.offsetx),
                                offsety: MmTurnPx(img.image.offsety),
                                scale: img.image.scale,
                                angle: img.image.angle,
                                resource: {
                                    identifier: img.image.resource ? img.image.resource.identifier : '',
                                    provider: img.image.resource ? img.image.resource.provider : ''
                                },
                                resourceid: img.image.resourceid
                            }
                        }
                    }
                    if (level.textbox && level.textbox.geometry) {
                        var text = level.textbox;
                        if (text.text == '') {
                            level.textbox = null;
                        } else {
                            text.current = 'textbox';
                            text.geometry = {
                                width: MmTurnPx(text.geometry.width),
                                height: MmTurnPx(text.geometry.height),
                                x: MmTurnPx(text.geometry.x),
                                y: MmTurnPx(text.geometry.y)
                            }
                            text.rotation.angle = -text.rotation.angle;
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        var textdocument = level.textdocument;
                        textdocument.current = 'textdocument';
                        textdocument.background.offsetx = MmTurnPx(textdocument.background.offsetx);
                        textdocument.background.offsety = MmTurnPx(textdocument.background.offsety);
                        textdocument.geometry = {
                            width: MmTurnPx(textdocument.geometry.width),
                            height: MmTurnPx(textdocument.geometry.height),
                            x: MmTurnPx(textdocument.geometry.x),
                            y: MmTurnPx(textdocument.geometry.y)
                        }
                        angular.forEach(textdocument.levels.level, function (l) {
                            if (l.imageart && l.imageart.geometry) {
                                var imageart = l.imageart;
                                imageart.current = 'imageart';
                                imageart.geometry = {
                                    width: MmTurnPx(imageart.geometry.width),
                                    height: MmTurnPx(imageart.geometry.height),
                                    x: MmTurnPx(imageart.geometry.x),
                                    y: MmTurnPx(imageart.geometry.y)
                                };
                                imageart.offsetx = MmTurnPx(imageart.offsetx);
                                imageart.offsety = MmTurnPx(imageart.offsety);
                                imageart.rotation.angle = -imageart.rotation.angle;
                            }
                            if (l.textbox && l.textbox.geometry) {
                                if (l.textbox.text == '') {
                                    l.textbox = null;
                                } else {
                                    l.textbox.current = 'textbox';
                                    l.textbox.geometry = {
                                        width: MmTurnPx(l.textbox.geometry.width),
                                        height: MmTurnPx(l.textbox.geometry.height),
                                        x: MmTurnPx(l.textbox.geometry.x),
                                        y: MmTurnPx(l.textbox.geometry.y)
                                    }
                                    l.textbox.rotation.angle = -l.textbox.rotation.angle;
                                }
                            }
                        })
                    }
                });

                if (!page.backup) {
                    loadPageToPx(tempPages.page[i], i);
                }

                if(flag&&flag=='pages'){
                    setOriginalWaH(unitTransform(page.trimbox.width), unitTransform(page.trimbox.height), i);
                }
            });
        }

        function success(data) {
            vm.isSaving = false;
            MessageService.success("保存成功！");
            $scope.exitPage();
        }

        function error() {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }


        function success2(data) {
            vm.isSaving = false;
            MessageService.success("发布成功！");
            $state.go('product-xml', {reload: true})
        }

        function error2() {
            vm.isSaving = false;
            MessageService.error("发布失败！");
        }

        $scope.exitPage = function () {
            var url = vm.thirds.thirdUrl + '/#/product-xml';
            if(productXmlPage!=1){
                url+='?page='+productXmlPage
            }
            window.location.href = url;
        };

        vm.leftShow = true;
        vm.rightShow = true;
        $scope.hidePanel = function (attr) {
            $(".rightPanel").removeClass('rightPanel-big rightPanel-big1 rightPanel-big2 rightPanel-big3');
            $(".page-scale").removeClass('page-scale-39 page-scale-46 page-scale-52');
            if (attr == 1) {
                // 左边菜单栏
                $(".leftPanel-mainContent").toggleClass('hide1');
                vm.leftShow = !vm.leftShow;

            } else {
                // 右菜单栏
                $(".rightPanel-2").toggleClass('hide1');
                vm.rightShow = !vm.rightShow;
                $(".rightPanel-mainContent-toggle").toggleClass('rightPanel-toggle');
                $(".rightPanel-bottom").toggleClass('bottom-100');
            }

            if (vm.leftShow) {
                if (!vm.rightShow) {
                    $(".rightPanel").addClass('rightPanel-big2');
                    $(".page-scale").addClass('page-scale-52');
                }
            } else {
                if (vm.rightShow) {
                    $(".rightPanel").addClass('rightPanel-big3');
                    $(".page-scale").addClass('page-scale-39');
                } else {
                    $(".rightPanel").addClass('rightPanel-big1');
                    $(".page-scale").addClass('page-scale-46');
                }
            }
        }


        //历史纪录
        var updateHistorys = function () {
            $scope.history.push(angular.copy($scope.template));
            if ($scope.history.length > 50) {
                $scope.history.splice($scope.history.length - 4, $scope.history.length - 5)
            }
            $scope.currentHistoryIndex = $scope.history.length - 1;
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];

            //每一页的历史记录
            if (!$scope.pageHistory[$scope.template.pageI]) {
                $scope.pageHistory[$scope.template.pageI] = {
                    history: []
                }
            }
            $scope.pageHistory[$scope.template.pageI].history.push(angular.copy($scope.template.pages.page[$scope.template.pageI]));
            if ($scope.pageHistory[$scope.template.pageI].history.length > 50) {
                $scope.pageHistory[$scope.template.pageI].history.splice($scope.pageHistory[$scope.template.pageI].history.length - 4, $scope.pageHistory[$scope.template.pageI].history.length - 5)
            }
            $scope.pageHistory[$scope.template.pageI].currentHistoryIndex = $scope.pageHistory[$scope.template.pageI].history.length - 1;

        };

        //防止事件冒泡，默认事件
        function fn(e) {
            // e = e || window.event;
            // e.cancelBubble = true;
            // e.stopPropagation();
            // e.returnValue = false;
            // e.preventDefault();
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        $scope.showThumbnailPic = function () {
            $scope.thumbnailPic = true;
            $scope.thumbnailType = 'one';
            var i = $scope.template.pageI;
            $scope.currentPage = $scope.template.pages.page[i];
            $scope.lastPage = $scope.template.pages.page[i];

            setTimeout(function () {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage)
            })
        }

        $scope.showAllThumbnailPic = function () {
            $scope.thumbnailPic = true;
            $scope.thumbnailType = 'all';
            var i = $scope.template.pageI;
            $scope.currentPage = $scope.template.pages.page[i];
            $scope.lastPage = $scope.template.pages.page[i];

            setTimeout(function () {
                var nodes = document.getElementsByClassName('page-item2');
                setThumbnailPic(nodes[0], $scope.lastPage)
            })
            // var nodes = document.getElementsByClassName('pageNode');
            // Qinius.get(function (msg) {
            //     vm.token = msg.token;
            //     angular.forEach($scope.template.pages.page, function (page, i) {
            //         setThumbnailPic(nodes[i], page)
            //     })
            setTimeout(function () {
                swiper();
            }, 1000);
            // });

        }

        function setThumbnailPic(node, page, flag) {
            page.pagecover = null;
            // domtoimage.toBlob(node).then(function (blob) {
            //     var formData = new FormData();
            //     formData.append('file', blob);
            //     formData.append('token', vm.token);
            //     $.ajax({
            //         cache: true,
            //         type: "POST",
            //         url: 'https://upload.qiniup.com/',
            //         data: formData,
            //         async: false,
            //         contentType: false,
            //         processData: false,
            //         success: function (data) {
            //             page.pagecover = {
            //                 identifier: vm.url + data.key,
            //                 provider: 'qiniu'
            //             }
            //             $scope.$digest();
            //         },
            //         error: function (request) {
            //             MessageService.error("文件上传失败");
            //         }
            //     });
            // });

            html2canvas_adapt(node, {
                onrendered: function (canvas) {
                    var url = canvas.toDataURL("image/png");
                    var blob = dataURLtoBlob(url);

                    var formData = new FormData();
                    formData.append('file', blob);
                    formData.append('key', 'cover-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            page.pagecover = {
                                identifier: vm.url + data.key,
                                provider: 'qiniu'
                            }
                            if(flag&&flag=='cover'){
                                pageAnimate();
                            }

                            if(flag&&flag=='all'){
                                for (var i = 1; i < $scope.template.pages.page.length; i++) {
                                    var page1 = $scope.template.pages.page[i];
                                    page1.pagecover = page.pagecover
                                }
                            }
                            $scope.$digest();
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });
                },
                useCORS: true,
                logging: false
            });
        }

        function dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type: mime});
        }

        $scope.modalHide = function () {
            $scope.thumbnailPic = false;
        }

        //swiper
        function swiper() {
            var swiper = new Swiper('.swiper-container', {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }

        vm.fullFlag = false;
        $scope.toggleFullScreen = function () {
            if (!vm.fullFlag) {
                //进入全屏
                var de = document.documentElement;
                if (de.requestFullscreen) {
                    de.requestFullscreen();
                } else if (de.mozRequestFullScreen) {
                    de.mozRequestFullScreen();
                } else if (de.webkitRequestFullScreen) {
                    de.webkitRequestFullScreen();
                }
                vm.fullFlag = true;
            } else {
                //退出全屏
                var de = document;
                if (de.exitFullscreen) {
                    de.exitFullscreen();
                } else if (de.mozCancelFullScreen) {
                    de.mozCancelFullScreen();
                } else if (de.webkitCancelFullScreen) {
                    de.webkitCancelFullScreen();
                }
                vm.fullFlag = false;
            }

        }

        function toolbarStatus() {
            vm.isOperationDir = false;
            vm.isOperationSpacing = false;
            vm.isTransp = false;
            vm.isCoverage = false;
            // $scope.isDistriHover = false;
            // $scope.isAlignHover = false;
            vm.isOperationColor = false;
            vm.isShadowSet = false;
        }

        $scope.backupPages = function() {
            var pages = [];
            var index = 0;
            for (var i = 0; i < $scope.template.pages.page.length; i++) {
                if (index >= vm.backupPageSize) {
                    break;
                }
                var p = $scope.template.pages.page[i];
                if (!p.backup) {
                    continue;
                }
                p.index = i;
                pages.push(p);
                index++;
            }

            return pages;
        }

        $scope.loadMoreBackupPages = function() {
            if (vm.backupPageSize < $scope.template.pages.page.length) {
                vm.backupPageSize += 20;
            }
            if (vm.backupPageSize >= $scope.template.pages.page.length) {
                $timeout(function(){
                    $scope.$digest();
                }, 100);
            }
        }

        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                radiusW = radiusW + Number(imagebox.border.lineWidth);
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                } else {
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                } else {
                    return 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius2 = function (imagebox, radius) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.shape == 'Ellipse') {
                    return '200%'
                } else {
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? _r + Number(imagebox.border.lineWidth)/2 : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius3 = function (imagebox, radius) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.shape == 'Ellipse') {
                    return '200%'
                } else {
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? _r - Number(imagebox.border.lineWidth)/2 : 0
                }
            } else {
                return 0
            }
        }
        $scope.borderRadius = function () {
            $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imagebox.border.radius = $scope.selectBox.border.radius;
        }
        $scope.lineTypeChenge = function(lineType){
            $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].imagebox.border.lineType = lineType;
        }
        $scope.dropShadow = function(imagebox,radius){
            if (imagebox == null || imagebox.shadow == null) {
                return
            }
            var width = $scope.imageboxW(imagebox) * radius;
            var height =$scope.imageboxH(imagebox) * radius;
            return dropShadow(imagebox,width,height)
        }

        // 画布位置X
        $scope.canvasCoordX = function (width){
            vm.pageCenterWidth = $('#pageCenterSize').width();
            return (vm.pageCenterWidth-width)/2 + vm.moveCanvasObj.x;
            // +
        }
        // 画布位置Y
        $scope.canvasCoordY = function (height){
            vm.pageCenterHeight = $('#pageCenterSize').height();
            return (vm.pageCenterHeight-height)/2 + vm.moveCanvasObj.y ;
        }
    }

})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ProductXmlController', ProductXmlController);

    ProductXmlController.$inject = ['$state', '$scope', 'ProductXml', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService'];

    function ProductXmlController($state, $scope, ProductXml, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        $(".navbar").show();
        $(".wrap").css({margin: '48px 0 10px 254px'});

        loadAll();

        $scope.delete = function (id) {
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确认要删除该模板文件么？'
            },function(){
                ProductXml.delete({id: id}, function () {
                    loadAll();
                });
            }, function () {
            });
        };

        function loadAll () {
            if (pagingParams.search) {
                ProductXml.byCondition({
                    items: [{key: "name", op: "like", value: pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ProductXml.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productXmls = data;
                vm.page = pagingParams.page;
                console.log(data);
            }
            function onError(error) {
                MessageService.error("数据加载失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MaskController', MaskController);

    MaskController.$inject = ['$scope', '$state', 'SourceMaterial', 'ParseLinks', 'paginationConstants',
     'pagingParams', 'MessageService', 'Qinius', '$q', '$uibModal', 'UidService', '$timeout', 'ThemeClassify'];

    function MaskController ($scope, $state, SourceMaterial, ParseLinks, paginationConstants,
     pagingParams, MessageService, Qinius, $q, $uibModal, UidService, $timeout, ThemeClassify) {
        var vm = this;

        vm.tabs = [{key:"background", value:"背景", url:'background'},
        {key:"imageBox", value:"相框", url:'mask'},
        {key:"borderBox", value:"边框", url:'frame-control'},
        {key:"font", value:"字体", url:'font-management'},
        {key:"clipArt", value:"素材", url:'clip-art'},
        {key:"image", value:"图片", url:'pic'}];
        vm.currentName = "imageBox";

        vm.count = 0;
        vm.totalItems = 0;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.size = 15;
        vm.page = 1;
        var bigSizePics= [];

        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        //标签筛选项是否展开
        vm.tagScreenIsOpen = false;
        vm.tokenTime = 360000;
        Qinius.get(function (msg) {
            vm.token = msg.token;
            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        });

        vm.themeClassifies = ThemeClassify.getAllByCondition1({items:[
            {key:"type",value:"MASK", op:"="},
            {key:"used",value:"true", op:"="}]});
        vm.domain = Qinius.getDomain();
        vm.themeClassifyId = pagingParams.c - 0;

        $q.all([ vm.domain.$promise, vm.themeClassifies.$promise]).then(function(){
           setTimeout(function fn() {
                Qinius.get(function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            vm.url = vm.domain.domain;

            vm.themeClassifies.unshift({name:"全部", id:"全部",seq:-1});
            $scope.selectTag(vm.themeClassifies[0]);
        })

        $scope.delete = function(id){
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确定要删除该相框？'
            },function(){
                SourceMaterial.delete({id: id},function () {
                    loadAll();
                });
            },function(){});
        };


        $scope.editTag = function(){
            $uibModal.open({
                templateUrl: 'app/entities/page/catalog-management.html',
                controller: 'CatalogManagementController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: function () {
                        return {catalogType: "MASK"}
                    }
                }
            }).result.then(function(res) {
                ThemeClassify.getAllByCondition1({items:[
                    {key:"type",value:"MASK", op:"="},
                    {key:"used",value:"true", op:"="}]}, function(data){
                        vm.themeClassifies = data;
                        vm.themeClassifies.unshift({name:"全部", id:"全部",seq:-1});
                        $scope.selectTag(vm.themeClassifies[0]);
                    });
            });
        }

        $scope.batchDelet = function(){
            var ids = [];
            vm.resources.forEach(function(it){
                if (it.selected) {
                    ids.push(it.id);
                }
            });
            if (ids.length>0) {
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'确定要删除所选相框？'
                }, function(){
                    SourceMaterial.batchDelete({ids_:ids}, function(res){
                        MessageService.success("删除成功");
                        loadAll();
                        vm.batch = false;
                        vm.count = 0;
                    }, function(va){
                        MessageService.error("删除失败，请稍后再试");
                    })
                })
            }else{
                MessageService.error("未勾选任何有效的背景，请确认后再操作");
            }
        }

        $scope.itemSelect = function(item){
            if (!vm.batch) {
                return
            }
            item.selected = !item.selected;
            vm.count = 0;
            vm.resources.forEach(function(it){
                if (it.selected) {
                    vm.count ++;
                }
            })
        }

        $scope.exitBatch = function(){
            vm.resources.forEach(function(it){
                it.selected = false;
            })
            vm.batch = false;
            vm.count = 0;
        }

         //更改展开收起状态
        $scope.changeMoreState = function(key){
            vm[key] = !vm[key]
        }

        // 当前页变更
        vm.currentChange = function(){
            $timeout(function(){
                loadAll();
            })
        }

        $scope.selectTag = function(item){
            vm.themeClassifies.forEach(function(it){
                it.active = false;
            })
            item.active = true;
            if (item.id == '全部') {
                vm.themeClassifyId = null;
            }else{
                vm.themeClassifyId = item.id;
            }
            loadAll();
        }

        $scope.more = function(item){
            vm.resources.forEach(function(it){
                it.more = false;
            });
            item.more = true;
        }

        $scope.view = function(data){
            vm.resources.forEach(function(it){
                it.more = false;
            });
            $uibModal.open({
                templateUrl: 'app/entities/my-upload/background-view.html',
                controller: 'BackGroundViewController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: data,
                }
            }).result.then(function(res) {
            });
        }



        function loadAll () {
            var items = []
            vm.resources = [];
            if (vm.themeClassifyId) {
                items.push({key:"themeClassify.id",value:vm.themeClassifyId, op:"="})
            }
            if (pagingParams.search) {
                items.push({key:"name",value:pagingParams.search, op:"like"})

            }
            SourceMaterial.byCondition3({
                items:items,
                page: vm.page - 1,
                size: vm.size,
                sort: ["id,desc"]
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                data.some(function (item, i) {
                    item.identifier = vm.url + item.identifier
                    vm.resources.push(item);
                })
            }
            function onError(error) {
                MessageService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                c: vm.themeClassifyId
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.themeClassifyId = null;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        $scope.searchClass = function(){
            vm.transition()
        }
        $scope.addMask = function () {
            $uibModal.open({
                templateUrl: 'app/entities/my-upload/modal/mask-add.html',
                controller: 'MaskAddController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: [function () {
                        return {};
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(state) {
                console.log(state)
                if(state){
                    vm.page = 1;
                    vm.loadAll();
                }
            });
        }

    }
})();


(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('DocumentDetectionController', DocumentDetectionController);

    DocumentDetectionController.$inject = ['$timeout', '$scope', 'GetSetDpi', '$stateParams', '$uibModalInstance', 'entity', '$state', '$q', 'MessageService', 'Qinius', '$http', 'Catalog', '$uibModal','ProductXml', 'FontManagement'];

    function DocumentDetectionController ($timeout, $scope, GetSetDpi, $stateParams, $uibModalInstance, entity, $state, $q, MessageService, Qinius, $http, Catalog, $uibModal, ProductXml, FontManagement) {
        var vm = this;
        vm.loading=true;
        vm.platform2User = JSON.parse(localStorage.getItem('platform2-user'));
        vm.saveData = angular.copy(entity);
        vm.source = angular.copy(vm.saveData.source);
        vm.document = angular.copy(vm.saveData.document);
        vm.gitDpi = GetSetDpi.getCurrent();
        vm.backup = false;
        vm.data=[];
        vm.tapIndex = 1;
        vm.checkAll = false;
        vm.isDocumentDetection=false;
        vm.documentDetection = {
            pass: true
        };
        vm.documentDetectionTab="text";
        $q.all([vm.gitDpi.$promise, vm.document.$promise]).then(function () {
            vm.documentDetection = documentTextDetection(vm.document);
            if(!vm.documentDetection.pass){
                if(vm.documentDetection.isText){
                    vm.documentDetectionTab="text";
                }else{
                    vm.documentDetectionTab="repetition";
                }
                return;
            }
            vm.isDocumentDetection=true;

            vm.thirdPartySettings = vm.platform2User.thirdPartySettings || [];
            vm.gitDpi = vm.gitDpi.message;
            vm.pages=[];
            vm.backupPages=[];
            vm.document.pages.page.forEach(function (page) {
                pageDetection(page)
            });
            if(vm.document.backupPages&&vm.document.backupPages.list){
                vm.document.backupPages.list.forEach(function (page) {
                    pageDetection(page)
                });
            }
            $timeout(function () {
                pageInit();
            },1000)
        })

        $scope.backupFn = function (item) {
            vm.backup = item;
            pageInit();
        }
        function verifyTextfont(textbox, failCallback) {
            if (!textbox) {
                return;
            }
            textbox.characterWarning = false;
            textbox.characterMessage = "";
            FontManagement.fontSupport({
                uuid: textbox.fontuuid,
                bold: textbox.style.bold,
                italic: textbox.style.italic,
                content: textbox.text
            }, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    if (msg.success && !msg.supported) {
                        var message = "【" + msg.failed + "】" + "当前字体不支持这些文字！";
                        if (failCallback) {
                            failCallback(message);
                        }
                    }
                }
            })
        }
        function pageDetection(page){
            page.dpiResult = [];
            page.fontResult = [];
            if(page.background.type == 'Pic' && page.background.resource && page.background.resource.identifier){
                var _data = pxInsufficient(page.background.resource.width, page.background.resource.height, page.background.scale, page.mediabox.width, page.mediabox.height);
                if(_data < vm.gitDpi.bgDpi - 0.01){
                    page.dpiResult.push({
                        type: 'background',
                        img: page.background.resource.identifier,
                        dpi: _data,
                        expectDpi: vm.gitDpi.bgDpi,
                        name: '背景'
                    })
                }
            }
            if(page.levels && page.levels.level && page.levels.level.length>0){
                page.levels.level.forEach(function (level, i) {
                    var index = i+1;
                    if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                        if(level.imagebox.image.resource.width && level.imagebox.image.resource.height){
                            var _data = pxInsufficient(level.imagebox.image.resource.width, level.imagebox.image.resource.height, level.imagebox.image.scale, level.imagebox.geometry.width, level.imagebox.geometry.height);
                            if(_data < vm.gitDpi.imgDpi - 0.01){
                                page.dpiResult.push({
                                    type: 'imagebox',
                                    img: level.imagebox.image.resource.identifier,
                                    dpi: _data,
                                    expectDpi: vm.gitDpi.imgDpi,
                                    name: '图片' + index
                                })
                            }
                        }else{
                            imageInfo($http, level.imagebox.image.resource, function () {
                                var _data = pxInsufficient(level.imagebox.image.resource.width, level.imagebox.image.resource.height, level.imagebox.image.scale, level.imagebox.geometry.width, level.imagebox.geometry.height);
                                if(_data < vm.gitDpi.imgDpi - 0.01){
                                    page.dpiResult.push({
                                        type: 'imagebox',
                                        img: level.imagebox.image.resource.identifier,
                                        dpi: _data,
                                        expectDpi: vm.gitDpi.imgDpi,
                                        name: '图片' + index
                                    })
                                }
                            })
                        }
                    }
                    if(level.imagebox && level.imagebox.mask && level.imagebox.mask.resource && level.imagebox.mask.resource.identifier){
                        if(level.imagebox.mask.resource.width && level.imagebox.mask.resource.height){
                            var _data = pxInsufficient(level.imagebox.mask.resource.width, level.imagebox.mask.resource.height, level.imagebox.mask.scale, level.imagebox.geometry.width, level.imagebox.geometry.height);
                            if(_data < vm.gitDpi.maskDpi - 0.01){
                                page.dpiResult.push({
                                    type: 'imageboxMask',
                                    img: level.imagebox.mask.resource.identifier,
                                    dpi: _data,
                                    expectDpi: vm.gitDpi.maskDpi,
                                    name: '相框' + index
                                })
                            }
                        }else{
                            imageInfo($http, level.imagebox.mask.resource, function () {
                                var _data = pxInsufficient(level.imagebox.mask.resource.width, level.imagebox.mask.resource.height, level.imagebox.mask.scale, level.imagebox.geometry.width, level.imagebox.geometry.height);
                                if(_data < vm.gitDpi.maskDpi - 0.01){
                                    page.dpiResult.push({
                                        type: 'imageboxMask',
                                        img: level.imagebox.mask.resource.identifier,
                                        dpi: _data,
                                        expectDpi: vm.gitDpi.maskDpi,
                                        name: '相框' + index
                                    })
                                }
                            })
                        }
                    }
                    if(level.imageart && level.imageart.resource && level.imageart.resource.identifier){
                        if(level.imageart.resource.width && level.imageart.resource.height){
                            var _data = pxInsufficient(level.imageart.resource.width, level.imageart.resource.height, level.imageart.scale, level.imageart.geometry.width, level.imageart.geometry.height);
                            if(_data < vm.gitDpi.clipArtDpi - 0.01){
                                page.dpiResult.push({
                                    type: 'imageart',
                                    img: level.imageart.resource.identifier,
                                    dpi: _data,
                                    expectDpi: vm.gitDpi.clipArtDpi,
                                    name: '素材' + index
                                })
                            }
                        }else{
                            imageInfo($http, level.imageart.resource, function () {
                                var _data = pxInsufficient(level.imageart.resource.width, level.imageart.resource.height, level.imageart.scale, level.imageart.geometry.width, level.imageart.geometry.height);
                                if(_data < vm.gitDpi.clipArtDpi - 0.01){
                                    page.dpiResult.push({
                                        type: 'imageart',
                                        img: level.imageart.resource.identifier,
                                        dpi: _data,
                                        expectDpi: vm.gitDpi.clipArtDpi,
                                        name: '素材' + index
                                    })
                                }
                            })
                        }
                    }
                    if (level.textbox && level.textbox.text && level.textbox.fontuuid) {
                        verifyTextfont(level.textbox, function (message) {
                            page.fontResult.push({
                                type: 'textbox',
                                message: message,
                                name: '文字' + index
                            })
                        });

                    }
                })
            }
        }


        function pageInit(){
            vm.pages=[];
            vm.disqualification = false;
            if(!vm.backup){
                vm.document.pages.page.forEach(function (page) {
                    vm.pages.push(page);
                })
            }else{
                if(vm.document.backupPages&&vm.document.backupPages.list){
                    vm.document.backupPages.list.forEach(function (page) {
                        if(page.backup == vm.backup){
                            vm.pages.push(page);
                        }
                    })
                }
            }
            vm.pages.forEach(function (item) {
                if(item.dpiResult && item.dpiResult.length>0){
                    vm.disqualification = true;
                }
                if (item.fontResult && item.fontResult.length > 0) {
                    vm.disqualification = true;
                }
            })
            vm.loading=false;
        }

        vm.checkAllFn = function(){
            vm.checkAll = !vm.checkAll;
            vm.thirdPartySettings.forEach(function (item) {
                item.selected = vm.checkAll;
            })
        };
        vm.checkFn = function(item){
            item.selected = !item.selected;
            vm.checkAll = true;
            vm.thirdPartySettings.forEach(function (item) {
                if(!item.selected){
                    vm.checkAll = false;
                }
            })
        };
        //返回
        vm.cancel = function() {
            $uibModalInstance.close(false);
        };
        function checkPageFonts() {
            if (vm.pages.length > 0) {
                for (var i = 0; i < vm.pages.length; i++) {
                    var page = vm.pages[i];
                    if (page.fontResult && page.fontResult.length > 0) {
                        if (!page.backup) {
                            MessageService.error("检测到页面文字中包含字体不支持的字符！");
                        } else {
                            MessageService.error("检测到备用页面文字中包含字体不支持的字符！");
                        }
                        return false;
                    }
                }
            }

            return true;
        }
        $scope.save1 = function(){
            if (!checkPageFonts()) {
                // 文字检查不通过
                return;
            }
            if(vm.thirdPartySettings.length>1){
                vm.tapIndex = 2;
            }else{
                vm.thirdPartySettings.forEach(function (item) {
                    item.selected = true;
                })
                save()
            }
        };
        $scope.cancel2 = function(){
            vm.tapIndex = 1;
        };
        $scope.save2 = function () {
            save()
        };
        $scope.save3 = function () {
            $uibModalInstance.close(true);
        }

        function save() {
            vm.storeIds = [];
            vm.thirdPartySettings.forEach(function (item) {
                if(item.selected){
                    vm.storeIds.push(item.id);
                }
            })
            if(vm.storeIds.length == 0){
                MessageService.error("请选择发布的渠道");
                return
            }
            vm.tapIndex = 3;
            if(vm.source == 'editor'){
                vm.saveData.storeIds = vm.storeIds;
                vm.saveLoading = true;
                ProductXml.release(vm.saveData, function (res) {
                    vm.releaseData = res;
                    vm.saveLoading = false;
                }, function () {
                    MessageService.error("发布失败");
                    vm.saveLoading = false;
                });
            }
            if(vm.source == 'list'){
                vm.saveData.storeIds = vm.storeIds.toString();
                vm.saveLoading = true;
                ProductXml.onlyRelease(
                    {
                        id: vm.saveData.id,
                        storeIds: vm.saveData.storeIds,
                        type: vm.saveData.type,
                    }, function (res) {
                    vm.releaseData = res;
                    vm.saveLoading = false;
                }, function () {
                    MessageService.error("发布失败");
                    vm.saveLoading = false;
                });
            }
        }
        $scope.levelInfo = function (level) {
            var item = "";
            if (level.imagebox) {
                item += "相框"
            }
            if (level.imageart) {
                item += "素材"
            }
            if (level.textbox) {
                item += level.textbox.text || ""
            }
            if (level.textdocument) {
                item += "文本模板"
            }
            return item
        };

        $scope.getIndex = function(index){
            if(vm.pageData && vm.pageData.length>0){
                var _index = 0;
                for(var i=0; i<vm.pageData.length; i++){
                    var _page = vm.pageData[i];
                    if(_page && _page.config && _page.config.partid == 'P'){
                        _index+=1;
                        if(index == i){
                            break
                        }
                    }
                }
                if (_index < 10) {
                    return "0"+ _index;
                }
                return _index;
            }
        }

        $scope.pageSeq = function(page){
            if(page && page.config){
                if(page.config.partid != 'P'){
                    return $scope.partNamt(page.config.partid)
                }else{
                    var _seq = 0;
                    for(var i=0; i<vm.pages.length; i++){
                        var _page = vm.pages[i];
                        if(_page && _page.config &&_page.config.partid == page.config.partid){
                            _seq += 1;
                            if(_page.uuid == page.uuid){
                                break
                            }
                        }
                    }
                    return '内页第'+ _seq + '页';
                }
            }
        }
        //部件名
        $scope.partNamt = function (partid) {
            var partName = "";
            for(var i=0; i<vm.document.config.parts.list.length; i++){
                if(vm.document.config.parts.list[i].id == partid){
                    partName = vm.document.config.parts.list[i].name;
                }
            }
            return partName
        }
    }
})();

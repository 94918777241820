(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('platformApp')
        .factory('PsdTemplateLoader', PsdTemplateLoader);

    PsdTemplateLoader.$inject = ['$q', 'TxMatrix', 'Base64Image', 'TextUtilService'];

    function PsdTemplateLoader($q, TxMatrix, Base64Image, TextUtilService) {

    	var service = {
    		loadPsdFile: loadPsdFile
    	};
    	return service;

        var dpi = 72;

    	function px2mm(px) {
    		return px * 25.4 / 96;
    	}

    	function pt2px(pt) {
    		return pt * 96 / 72;
    	}


        function dpi2px(pt) {
            return pt * 96 / dpi;
        }

        function dpi2pt(p) {
        	return p * 72 / dpi;
        }
		function loadPsdFile(file) {


			var deferred = $q.defer();

			PSD.fromDroppedFile(file).then(function(psd){
				// console.log('psd', psd);
				var header = psd.header || {};
				if (header.mode != 3 && header.mode != 4) {
					console.log("PSD无法处理: color_mode=", header.mode);
					deferred.reject({error: 'PSD无法处理, color_mode=' + header.mode});
					return;
				}
				if (8 != header.depth) {
					console.log("PSD无法处理, 目前仅支持8位通道. depth=" + header.depth);
					deferred.reject({error: 'PPSD无法处理, 目前仅支持8位通道'});
					return;
				}


				// check the PSD
				var plat;
				try {
                	plat = psd.tree().export();
                }
				catch (e) {
					console.log("PSD解析失败: ", e.message);
					deferred.reject({error: 'PSD解析失败'});
					return;
				}

				var thumbnailUrl = null;

                var dpiResources = psd.resources.obj.resources;
                Object.keys(dpiResources).forEach(function (key) {
                    if(key == 1005){
                        dpi = dpiResources[key].v_res
                    }
                    else if (key == 1036) {
                    	thumbnailUrl = dpiResources[key].url;
                    }
                    // console.log(dpiResources[key])
                })
                console.log('dpi', dpi);

                // 排序
                psd.layers.sort(function(l1, l2) {
                	l1.layerId().get();
                	l2.layerId().get();
                	return l1.layerId().obj.id > l2.layerId().obj.id;
                });

                var template = initPage(psd, thumbnailUrl);
                template.layers = [];

                var layer_promises = [];
                var clipping_layers = [];
                // for (var i = psd.layers.length - 1; i >= 0; i--) {
                for (var i = 0; i < psd.layers.length; i++) {
                	var layer = psd.layers[i];

                	if (!layer.layerId().obj) {
                		layer.layerId().get();
                	}
                	var layerId = layer.layerId().obj.id;
                	// console.log("layer id: " + layerId.id + ", layerName:" + layer.name);

                	var layerNode = layer.node;
                	if (!layerNode) {
                		console.log("discard: ", layerNode);
                		continue;
                	}
                	if (layerNode.parent && layerNode.root && layerNode.parent != layerNode.root()) {
                		// parent不是root, 可能属于某个group
                		continue;
                	}
                	try {
	                	if (layerNode.type === 'group') {
	                		var children = layerNode.children();
	                		for (var k = 0; k < children.length; k++) {
	                			var cl = children[k].layer;
	                			if (!cl.layerId().obj) {
	                				cl.layerId().get();
	                			}
	                			var clId = cl.layerId().obj.id;

	                			var cnode = cl.node;
	                			if (cnode.type !== 'layer') {
	                				console.log("layer discard, type=" + cnode.type, cl);
	                				continue;
	                			}
	                			try {
	                				if (cl.rows <= 0 || cl.cols <= 0) {
	                					continue;
	                				}
		                			var child = cnode.export();
		                			var data = parseLayer(clId, cl, child, header, clipping_layers);
		                			if (data) {
		                				layer_promises.push(data.promise);
		                				template.layers.unshift(data);
		                			}
		                		} catch (e) {
		                			console.log(e);
		                		}
	                		}

	                	}
	                	else {
	                		try {
	                			if (layer.rows <= 0 || layer.cols <= 0) {
	                				continue;
	                			}
		                		var child = layerNode.export();
		                		// console.log("layer export: " , child);
		                		var data = parseLayer(layerId, layer, child, header, clipping_layers);
		                		if (data) {
		                			layer_promises.push(data.promise);
		                			template.layers.unshift(data);
		                		}
		                	} catch (e) {
		                		console.log(e);
		                	}
	                	}
	                } catch (e) {
	                	console.log(e);
	                }
                }
                $q.all(layer_promises).then(function() {
                	deferred.resolve(template);
                	// console.log(template);
                });
            },function(err){
                console.error('Error in PSD.fromDroppedFile:', err);
                deferred.reject({ error: 'Error in PSD.fromDroppedFile' });
            });

			return deferred.promise;
		}

		function initPage(psd, thumbnailData) {
			var header = psd.header;
			var page = {};
			page.unit = 'px';
			page.box = {
				x: 0,
				y: 0,
				width: header.width, // px2mm(header.width),
				height: header.height // px2mm(header.height)
			};
			// signature, always '8BPS'
			page.signature = header.sig;
			// version, always 1
			page.version = header.version;
			page.channels = header.channels;
			page.depth = header.depth;
			// color mode: Bitmap = 0; Grayscale = 1; Indexed = 2; RGB = 3; CMYK = 4; Multichannel = 7; Duotone = 8; Lab = 9.
			page.mode = header.mode;
			if (thumbnailData) {
				page.imageData = thumbnailData;
			} else {
				page.imageData = psd.image.toPng()['src'];
			}
			page.imageUrl = null;

			return page;
		}

		function parseLayer(layerId, layer, child, header, clipping_layers) {
			// console.log(index, layer, child);
			if (child.text) {
                		// console.log(i, '->', 'Text');
        		return parseText(layerId, layer, child, header);

        	} else {
        		if (layer.infoKeys.indexOf('vmsk') != -1) {
        			// console.log("vmsk", layer);
        			for (var i = 0; i < clipping_layers.length; i++) {
        				var cl_id = clipping_layers[i];
        				if (layerId == cl_id) {
        					return null;
        				}
        			}
        			// return null;
        		}
        		// console.log(i, '->', 'Image');
        		return parseImage(layerId, layer, child, header, clipping_layers);
        	}
		}

		function parseImage(layerId, layer, c, h, clipping_layers) {
			var deferred = $q.defer();
			// console.log(layer);

			var image = { id: layerId, type: 'Image', compressed: false };
			image.cols = layer.cols;
			image.rows = layer.rows;
			image.geometry = {
				x: dpi2px(layer.left), // px2mm(layer.left),
				y: dpi2px(layer.top),  // px2mm(layer.top),
				width: dpi2px(layer.width), // px2mm(layer.width),
				height: dpi2px(layer.height) // px2mm(layer.height)
			};
			image.rotation = {
				angle: 0
			};
			var opacity = layer.image.opacity;
			image.opacity = opacity;

			var imageData = layer.image.toBase64();
			var imageSize = Base64Image.getSize(imageData);
			var mimeType = Base64Image.getMimeType(imageData);
			// console.log("id: " + layerId + ", mimeType: " + mimeType + ", size:" + imageSize + ", opacity:" + opacity);
			if (imageSize > 1048576 * 19.9) {
				Base64Image.compress(imageData, 5000, 0.8, function(newBase64) {
					image.imageData = newBase64;
					image.compressed = true;
					var compressedSize = Base64Image.getSize(newBase64);
					console.log("compress: " + imageSize + " ==> " + compressedSize);
					deferred.resolve('ok');
				});
			} else {
				image.imageData = imageData;
				deferred.resolve('ok');
			}

			image.imageUrl = null;
			if (layer.node && layer.node.clippingMaskCached
				&& layer.node.clippingMaskCached.layer
				&& layer.node.clippingMaskCached.layer.image) {
				var maskLayer = layer.node.clippingMaskCached.layer;
				if (!maskLayer.layerId().obj) {
					maskLayer.layerId().get();
				}
				var maskLayerId = maskLayer.layerId().obj.id;
				clipping_layers.push(maskLayerId);

				var mask = { type: 'Mask' };
				mask.cols = maskLayer.cols;
				mask.rows = maskLayer.rows;
				mask.geometry = {
					x: dpi2px(maskLayer.left), // px2mm(layer.left),
					y: dpi2px(maskLayer.top),  // px2mm(layer.top),
					width: dpi2px(maskLayer.width), // px2mm(layer.width),
					height: dpi2px(maskLayer.height) // px2mm(layer.height)
				}
				mask.rotation = {
					angle: 0
				};
				mask.imageData = maskLayer.image.toBase64();
				mask.imageUrl = null;
				image.mask = mask;
				image.hasMask = true;
			} else {
				image.hasMask = false;
			}
			image.promise = deferred.promise;
			setTimeout(function() {
				deferred.resolve('ok');
			}, 100);
			return image;
		}

		function parseText(layerId, layer, child, header) {
			var textType = layer.typeTool(); // {_style: {...}, textData: {...}, transform: {...}}
			var textFont = textType.export().font; // {alignment:['left'],colors:[],leading:[],names:[],sizes:[],styles:['normal'],weights:['bold']}
			var textStyle = textType._styles || {}; // {FontSize: [n,...], Leading: [n,...], FillColor:[...], StrokeColor:[...]}
			var textData = textType.textData || {}; // {boundingBox:{...}, bounds:{...}}
			var textTransform = textType.transform;
			var textValue = textType.textValue;
			var boundingBox = textData.boundingBox || {
				Left: {value: 0},
				"Top ": {value: 0},
				Rght: {value: 0},
				Btom: { value: 0}
			};
			var bounds = textData.bounds || {
				Left: {value: 0},
				"Top ": {value: 0},
				Rght: {value: 0},
				Btom: { value: 0}
			};
			// console.log(textType, textFont);

			var tm = [textTransform.xx, textTransform.yx, textTransform.xy, textTransform.yy, textTransform.tx, textTransform.ty];
			var scale = TxMatrix.getScaleValue(tm) || 1;

			var fontSize = getArrayValue(textFont.sizes, textStyle.FontSize[0], "max");
			var leading = 0;
			if (textFont.leading) {
				leading = getArrayValue(textFont.leading, textFont.leading[0], "max");
				if (leading !== leading || leading == "auto" || leading == "unset") {
					// NaN
					leading = 0;
				}
			}
			var tracking = 0;
			if (textFont.Tracking) {
				tracking = textStyle.Tracking ? getArrayValue(textStyle.Tracking) / 1000 : 0;
				if (tracking !== tracking || tracking == "auto" || tracking == "unset") {
					// NaN
					tracking = 0;
				}
			}
			var minFontSize = getArrayValue(textStyle.FontSize, fontSize, "min");
			var letterspace = minFontSize * tracking;
			fontSize = (fontSize * scale);
			leading = (leading * scale);
			letterspace = (letterspace * scale);
			var lineheight = leading;
			if (lineheight < fontSize) {
				lineheight = fontSize;
			}
			var writingMode = "unset";
			if (textData.Ornt && "Vrtc" === textData.Ornt.value) {
				writingMode = "vertical-rl";
			}

			var text = { id: layerId,  type: 'Text', compressed: false};

			var alignment = 'left';
			if (textFont.alignment && textFont.alignment.length > 0) {
				alignment = textFont.alignment[0] || 'left';
			}
			text.style = {
				font: textFont.names[0],
				size: parseInt(dpi2pt(fontSize).toFixed(0)),
				color: parseColor(textFont.colors[0], header.mode),
				align: alignment,
				lineheight: parseFloat(dpi2pt(lineheight).toFixed(1)),
				letterspace: parseFloat(dpi2pt(letterspace).toFixed(1)),
				writemode: writingMode,
				italic: textStyle.FauxItalic ? (textStyle.FauxItalic[0] === true) : false,
				bold: textStyle.FauxBold ? (textStyle.FauxBold[0] === true) : false
			};
			text.opacity = child.opacity || 1.0;

			text.rotation = {
				angle: parseRotateAngle(tm)
			};

			// var lines = getTextLines(textValue);
			text.value = TextUtilService.escapeNoPrintables(textValue.replaceAll('\r', '\n'));

			var width = layer.width;
			var height = layer.height;
			var left = layer.left;
			var top = layer.top;
			var offsetX = (bounds.Left.value - boundingBox.Left.value) * scale;
			var offsetY = (bounds["Top "].value - boundingBox["Top "].value) * scale;
			if (writingMode === "vertical-rl") {
				height += (Math.max(0, letterspace) + fontSize + fontSize / 2);
				width += (leading > fontSize ? leading - fontSize / 2 : fontSize / 2);
				left += offsetX;
			}
			else {
				width += (Math.max(0, letterspace) + fontSize / 2);
				left += offsetX;
				height += (leading > fontSize ? leading - fontSize / 2 : fontSize / 2)
			}
			var line_height_ratio = leading / fontSize;
			if (line_height_ratio < 1 || line_height_ratio !== line_height_ratio) {
				line_height_ratio = 1;
			}
			var topOffset = 0.5 * (1.15 - line_height_ratio) * fontSize;
			top += topOffset;

			// var height = lines * leading *
			text.geometry = {
				x: dpi2px(left), // px2mm(layer.left),
				y: dpi2px(top),  // px2mm(layer.top),
				width: dpi2px(width), // px2mm(layer.width),
				height: dpi2px(height) // px2mm(layer.height)
			};

			var deferred = $q.defer();
			text.promise = deferred.promise;
			setTimeout(function() {
				deferred.resolve("ok");
			}, 100);

			return text;
		}

		function getTextLines(text) {
		  if (!text) {
		    return 1;
		  }
		  var num = 1;
		  var index = text.indexOf('\n');
		  while (index !== -1) {
		    // console.log(index);
		    num++;
		    index = text.indexOf('\n', index + 1);
		  }
		  return num;
		}

		function getArrayValue(values, default_value, flag) {
			var result;
			if (values) {
				if (Array.isArray(values)) {
					if (flag == "max" && "number" == typeof values[0] && "number" == typeof values[1]) {
						result = Math.max(values[0], values[1]);
					}
					else if (flag == "min") {
						if (values.length == 1) {
							result = values[0];
						}
						else if (values.length == 2) {
							result = Math.min(values[0], values[1]);
						}
						else if (values.length == 3) {
							result = Math.min(values[0], values[1], values[2]);
						}
						else if (values.length >= 4) {
							result = Math.min(values[0], values[1], values[2], values[3]);
						}
					}
					else {
						result = values[1] || values[0];
					}
				}
				else {
					result = values;
				}
			}
			return !result && result != '0' ? default_value : result;
		}

		function parseColor(colors, color_mode) {
			if (!colors
				|| !(colors instanceof Array)
				|| colors.length < 3) {
				return '0x000000'; // default black
			}
			// console.log(colors);
			if (color_mode == 4) {
				return cmyk2rgba(colors);
			}
			else {
				try {
					if (colors.length == 3) {
						return 'rgba(' + colors[0] + ',' + colors[1] + ',' + colors[2] + ',' + 255 + ')';
					}
					return 'rgba(' + colors[0] + ',' + colors[1] + ',' + colors[2] + ',' + colors[3] + ')';
				}
				catch(e){
					return 'rgba(0, 0, 0, 255)';
				}
			}
		}

		function parseRotateAngle(tm) {
			return TxMatrix.getRotateAngle(tm);
		}

		function parseTextGeometry(tm, cx, cy, textWidth, textHeight) {
			var tx = tm[4];
			var ty = tm[5];

			return {
				x: dpi2px(cx - tx / 2), // px2mm(cx - tx/2),
				y: dpi2px(cy - textHeight / 2), // px2mm(cy - ty/2),
				width: dpi2px(tx), // px2mm(tx),
				height: dpi2px(textHeight) // px2mm(ty)
			};
		}

		function parseTextGeometryLeft(tm, lx, ty, textWidth, textHeight) {
			var tx = tm[4];
			return {
				x: dpi2px(lx),
				y: dpi2px(ty),
				width: dpi2px(tx),
				height: dpi2px(textHeight)
			};
		}

		function parseTextGeometryRight(tm, rx, by, textWidth, textHeight) {
			var tx = tm[4];
			var lx = rx - tx;
			var ty = by - textHeight;

			return {
				x: dpi2px(lx),
				y: dpi2px(ty),
				width: dpi2px(tx),
				height: dpi2px(textHeight)
			};
		}

		function cmyk2rgba(colors) {
			try {
				var c = colors[0] / 255;
				var m = colors[1] / 255;
				var y = colors[2] / 255;
				var k = colors[3] / 255;
				var alpha = 255;
				if (colors.length == 5) {
					alpha = colors[4];
				}
				var r = 255 * (1 - c) * (1 - k);
				var g = 255 * (1 - m) * (1 - k);
				var b = 255 * (1 - y) * (1 - k);
				return 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';
			}
			catch (e) {
				return 'rgba(0, 0, 0, 255)';
			}
		}
    }

})();

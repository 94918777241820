(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarTitleAddController', CalendarTitleAddController);

    CalendarTitleAddController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService','$uibModal','TitleWidget','FontManagement'];

    function CalendarTitleAddController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService,$uibModal,TitleWidget,FontManagement) {
        var vm = this;
        vm.save = save;
        vm.cancel = cancel;
        vm.data = {
            name: "",
            layout:{},
            layoutId:""
        };
        vm.isCopy = false;
        if(entity){
            vm.data = entity;
            vm.isCopy = true;
        }else{
            var date = new Date();
            vm.data = {
                name: "",
                layout:{},
                layoutId:"",
                sampleYear: date.getFullYear(),
                sampleMonth: date.getMonth() + 1,
            };
            console.log(vm.data)
        }

        $scope.selectLayout = function() {
            $uibModal.open({
                templateUrl: 'app/entities/calendar/modal/calendar-title-data.html',
                controller: 'CalendarTitleDataController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        return vm.data.layout;
                    }
                }
            }).result.then(function(res) {
                if (res.id != vm.data.dateLayoutId) {
                    vm.data.layout = res;
                    vm.data.layoutId = res.id;
                    vm.data.previewUrl = res.previewUrl;
                }
            });
        }
        FontManagement.getAll2({items: [{key: "defaultShow", op: "=", value: true}], sort: ["id,asc"]}, function (res) {
            vm.data.defaultFontUuid = res[0].uuid;
        });
        //返回
        function cancel() {
            $uibModalInstance.close();
        }
        function save() {
            if(!vm.data.name){
                MessageService.error("标题组件名称不能为空");
                return
            }
            if (!vm.data.layoutId) {
                MessageService.error("请选择类型");
                return;
            }
            vm.isSaving = true;
            if (vm.isCopy) {
                TitleWidget.copy({id:vm.data.id, name:vm.data.name}, function (res) {
                    if (res && res.status == "200") {
                        $state.go('calendar-title-xml',{id:res.message.id});
                        $uibModalInstance.close(res);
                    } else {
                        MessageService.error("复制失败");
                    }
                    vm.isSaving = false;
                }, function (res) {
                    vm.isSaving = false;
                    MessageService.error(res.data.message || "复制失败")
                });
            }else{
                vm.data.width = vm.data.layout.width;
                vm.data.height = vm.data.layout.height;
                TitleWidget.create(vm.data, function (res) {
                    if (res && res.status == "200") {
                        $state.go('calendar-title-xml',{id:res.message.id});
                        $uibModalInstance.close(res);
                    } else {
                        MessageService.error("创建失败");
                    }
                    vm.isSaving = false;
                }, function (res) {
                    vm.isSaving = false;
                    MessageService.error(res.data.message || "提交失败")
                });
            }
        }
    }
})();
